<form class="theme-form" [formGroup]="partyForm" [ngClass]="{'overlay': party?.locked}">
    <div class="form-group mb-0">
        <!-- <span class="material-icons mr-2">edit</span> -->
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'people.party.name' | translate }}</mat-label>
            <input required matInput formControlName="name">
            <mat-error *ngIf="ifControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group adress-field">
        <div class="w-100">
            <mat-label class="col-form-label" [ngClass]="fullAddress ? 'float' : ''">{{ 'user.profile.address' | translate }}</mat-label>
            <input matInput formControlName="address_id" style="display: none;">
            <div [ngClass]="{'red': showAddressError}" class="full_address" (click)="editAddress(fullAddress)">
              {{fullAddress?.number ? fullAddress?.number+',' : ''}} {{fullAddress?.street ? fullAddress?.street+',' : ''}} {{fullAddress?.city ? fullAddress?.city+',' : ''}} {{fullAddress?.postal_code ? fullAddress?.postal_code+'' : ''}} {{fullAddress?.country ? fullAddress?.country : ''}}
            </div> 
            <mat-error *ngIf="showAddressError">
                <strong class="custom-error">{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>                          
          </div>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{"people.party.accounts" | translate}}</mat-label>
            <mat-select multiple formControlName="accounts" (click)="getAllAccounts()">
                <div class="d-flex" style="padding:0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                    <input matInput #account placeholder="Search"/>
                    </mat-form-field>
                    <!-- <mat-label style="position: absolute; right: 65px; cursor: pointer;" (click)="filterAccounts(account.value)">
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <div class="input-group-append pl-1" (click)="showAccountsModal()">
                        <span class="input-group-text">
                            <i class="icofont icofont icofont-plus"></i>
                        </span>
                    </div>
                </div>
                <div class="scroll_wrap">
                        <div class="slct">
                          <mat-option style="height:0;"></mat-option>
                            <mat-option *ngFor="let account of allAccounts | filter : account.value" [value]="account?.id" style="text-transform: capitalize">
                                <div class="type-name ddd">
                                    <span>{{account?.name}}</span>
                                </div>
                                <div class="actions">
                                    <i class="fa fa-edit" (click)="editAccount(account) ;$event.stopPropagation()"></i>
                                    <i class="fa fa-trash" (click)="deleteAccount(account?.id); $event.stopPropagation()"></i>
                                </div>
                            </mat-option>
                        </div>
                </div>
            </mat-select>
          </mat-form-field>
  </div>
    <!-- <div class="form-group">
        <span class="material-icons mr-2 right">dvr</span>
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.bankAcc' | translate }}</mat-label>
            <input matInput formControlName="bank_acc">
        </mat-form-field>
    </div>
    <div class="form-group ">
        <span class="material-icons mr-2">info_outline</span>
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.bankId' | translate }}</mat-label>
            <input matInput formControlName="bank_id">
        </mat-form-field>
    </div> -->
    <div class="form-group ">
        <!-- <span class="material-icons mr-2">account_balance</span> -->
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.cifId' | translate }}</mat-label>
            <input matInput required formControlName="cif_id">
        </mat-form-field>
    </div>
    <div class="form-group ">
        <!-- <span class="material-icons mr-2">phone</span> -->
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'party.vatId' | translate }}</mat-label>
            <input matInput required formControlName="vat_id">
        </mat-form-field>
    </div>
    <div class="form-group ">
        <div class="form-group">
            <mat-checkbox formControlName="own">{{ 'party.own' | translate }}</mat-checkbox>
        </div>
    </div>
    <div class=" mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="submit" (click)="submit()">{{ 'translations.save' | translate }}</button>
        <button class="btn" type="submit" (click)="cancel.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>
<!-- <fap-attribute-relations
    *ngIf="party"
    [objectId]="party?.id"
    [relation]="'party'">
</fap-attribute-relations> -->
<fap-modal #addEditAddressModal
    inputTitle="{{ 'party.address.addEditAddressModal' | translate }}"
    [inputIsLarge]="true"
    >
    <fap-add-edit-address [address]="selectedAddress" [type]="'party'" (cancel)="addEditAddressModal.hideModal()"
    (addedAddress)="addressAdd($event);partyForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()"
    (updatedAddress)="addressUpdate($event);partyForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()">
    </fap-add-edit-address>
</fap-modal>

<fap-modal #addEditBankAddressModal
    inputTitle="{{ 'party.address.addEditAddressModal' | translate }}"
    [inputIsLarge]="true"
    >
    <fap-add-edit-address [address]="selectedBankAddress" [type]="'party'" (cancel)="addEditBankAddressModal.hideModal()"
    (addedAddress)="addBankAddress($event);bankForm.controls['address_id'].setValue($event.id);addEditBankAddressModal.hideModal()"
    (updatedAddress)="updateBankAddress($event);bankForm.controls['address_id'].setValue($event.id);addEditBankAddressModal.hideModal()">
    </fap-add-edit-address>
</fap-modal>

<fap-modal 
    #addAccountsModal 
    [inputPrimaryButtonInterface]="addAccountsModalButtonPrimaryInterface" 
    [inputSecondaryButtonInterface]="addAccountsModalButtonSecondaryInterface" 
    [inputTitle]="'party.addEditBankAccount' | translate"
>
        <form [formGroup]="accountsForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "party.accountId" | translate
                    }}</mat-label>
                    <input matInput formControlName="account_id" />
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "party.name" | translate
                    }}</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{"party.bank" | translate}}</mat-label>
                    <mat-select formControlName="bank" (click)="getAllBanks()">
                        <div class="d-flex" style="padding:0 10px; align-items: center;">
                            <mat-form-field appearance="fill" class="w-100">
                            <input matInput #bank placeholder="Search"/>
                            </mat-form-field>
                            <!-- <mat-label style="position: absolute; right: 65px; cursor: pointer;" (click)="filterAccounts(account.value)">
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <div class="input-group-append pl-1" (click)="showBankModal()">
                                <span class="input-group-text">
                                    <!-- <i class="icofont icofont icofont-plus"></i> -->
                                    <fap-round-button></fap-round-button>
                                </span>
                            </div>
                        </div>
                        <div class="scroll_wrap">
                                <div class="slct">
                                  <mat-option style="height:0;"></mat-option>
                                    <mat-option *ngFor="let bank of banks | filter : bank.value" [value]="bank?.id" style="text-transform: capitalize">
                                        <div class="type-name ddd">
                                            <span>{{bank?.name}}</span>
                                        </div>
                                        <div class="actions">
                                            <i class="fa fa-edit" (click)="editBank(bank) ;$event.stopPropagation()"></i>
                                            <i class="fa fa-trash" (click)="deleteBank(bank?.id); $event.stopPropagation()"></i>
                                        </div>
                                    </mat-option>
                                </div>
                        </div>
                    </mat-select>
                </mat-form-field>
          </div>
          <div class="form-group">
            <div class="col-12">
                <div class="input-group justify-content-center">
                    <mat-checkbox formControlName="iban">{{ 'IBAN' }}</mat-checkbox>
                </div>
            </div>
          </div>
        </form>
    </fap-modal>

    <fap-modal 
        #addBankModal 
        [inputPrimaryButtonInterface]="addBankModalButtonPrimaryInterface" 
        [inputSecondaryButtonInterface]="addBankModalButtonSecondaryInterface" 
        [inputTitle]="'party.addEditBank' | translate"
    >
        <form [formGroup]="bankForm" class="col-12 p-0">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "party.name" | translate
                    }}</mat-label>
                    <input matInput formControlName="name" />
                </mat-form-field>
            </div>
            <div class="form-group adress-field">
                <div class="w-100">
                    <mat-label class="col-form-label" [ngClass]="fullBankAddress ? 'float' : ''">{{ 'user.profile.address' | translate }}</mat-label>
                    <input matInput formControlName="address_id" style="display: none;">
                    <div [ngClass]="{'red': showBankAddressError}" class="full_address" (click)="editBankAddress(fullBankAddress)">
                      {{fullBankAddress?.number ? fullBankAddress?.number+',' : ''}} {{fullBankAddress?.street ? fullBankAddress?.street+',' : ''}} {{fullBankAddress?.city ? fullBankAddress?.city+',' : ''}} {{fullBankAddress?.postal_code ? fullBankAddress?.postal_code+'' : ''}} {{fullBankAddress?.country ? fullBankAddress?.country : ''}}
                    </div>  
                    <mat-error *ngIf="showBankAddressError">
                        <strong class="custom-error">{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>                        
                  </div>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "party.swift" | translate
                    }}</mat-label>
                    <input matInput formControlName="swift" />
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{
                        "party.info" | translate
                    }}</mat-label>
                    <input matInput formControlName="info" />
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="col-12">
                    <div class="input-group justify-content-center">
                        <mat-checkbox formControlName="locked">{{ 'Locked' }}</mat-checkbox>
                    </div>
                </div>
              </div>
        </form>
    </fap-modal>