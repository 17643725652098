<div class="widget-info-type">
    <div class="d-flex">
        <mat-form-field class="w-100">
            <mat-label>{{ "widget.wizard.widgetTypeLabel" |translate }}</mat-label>
            <mat-select [(ngModel)]="selectedWidgetType" (ngModelChange)="changedWidgetType()">
                <mat-option value="user">{{ "widget.wizard.generalWidgetType" |translate }}</mat-option>
                <mat-option value="farm">{{ "widget.wizard.farmWidgetType" |translate }}</mat-option>
                <mat-option value="lot">{{ "widget.wizard.lotWidgetType" |translate }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedWidgetType==='farm'" class="w-100 ml-2">
            <mat-label>{{ "widget.wizard.farmWidgetType" |translate }}</mat-label>
            <mat-select [(ngModel)]="selectedFarm" (ngModelChange)="changedWidgetType()">
                <mat-option *ngFor="let farm of farms" [value]="farm.id">{{ farm.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="selectedWidgetType==='lot'" class="w-100 ml-2">
            <mat-label>{{ "widget.wizard.lotWidgetType" |translate }}</mat-label>
            <mat-select [(ngModel)]="selectedLot" (ngModelChange)="changedWidgetType()">
                <mat-option *ngFor="let lot of lots" [value]="lot.id">{{ lot.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngFor="let widgetInfoType of widgetInfoTypes"
        class="info-type"
        [ngClass]="{'selected': selectedWidgetInfoType === widgetInfoType }"
        (click)="clickWidgetInfoType(widgetInfoType)">
        <i class="material-icons justify-content-center d-flex mr-2">{{ widgetInfoTypeInfo[widgetInfoType]?.icon }}</i>
        <div class="info-type-description justify-content-center d-flex">{{ widgetInfoTypeInfo[widgetInfoType]?.description | translate }}</div>
    </div>
</div>
