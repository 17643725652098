
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>
                    {{ 'attributes.label' | translate }}
                    <i class="material-icons f-right" (click)="newAttribute()">add</i>
                </h5>
            </div>
            <div *ngFor="let attribute of attributes" class="card-body px-3 py-2">
                <div class="row body">
                    <div class="col-md-3 center">
                        <i class="material-icons">
                            layers
                        </i>
                        <mat-form-field id="name">
                            <mat-label>{{ 'attributes.name' | translate }}</mat-label>
                            <mat-select [(ngModel)]="attribute.name">
                                <mat-option  value="{{attribute.name}}">
                                    {{attribute.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <i class="material-icons f-right" (click)="openLg(content)">
                            add
                        </i>
                    </div>
                    <div class="col-md-3 center">
                        <mat-form-field>
                            <mat-label>{{ 'attributes.valueTypes' | translate }}</mat-label>
                            <mat-select [(ngModel)]="selectedValues[attribute.id]">
                                <mat-form-field appearance="fill" class="w-100">
                                    <!-- <mat-label>
                                        <i class="fa fa-search f-20"></i>
                                    </mat-label> -->
                                    <input matInput #valueTypeFilter placeholder="Search">
                                </mat-form-field>
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option *ngFor="let vt of valueTypes | filter : valueTypeFilter.value" value="{{vt}}">
                                    {{vt}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 center">
                        <mat-form-field *ngIf="selectedValues[attribute.id] === 'Text'">
                            <input matInput placeholder="Text" type="text" value="{{attribute.value}}">
                        </mat-form-field>
                        <mat-form-field *ngIf="selectedValues[attribute.id] === 'Number'">
                            <input matInput placeholder="Number" type="number" value="{{attribute.value}}">
                        </mat-form-field>
                        <mat-form-field *ngIf="selectedValues[attribute.id] === 'Date'">
                            <input matInput [matDatepicker]="picker" placeholder="Choose a date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 center">
                        <mat-form-field>
                            <input matInput placeholder="Info" value="{{attribute.info}}">
                        </mat-form-field>
                        <i class="fa fa-trash-o f-20 p-l-5" (click)="deleteAttribute(attribute.id)">
                        </i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'attributes.edtiName' | translate }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group m-form__group">
                            <div class="input-group">
                                <div class="input-group-prepend center p-r-10">
                                    <i class="fa fa-pencil f-20"></i>
                                </div>
                                <mat-form-field>
                                    <input matInput placeholder="English">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group m-form__group">
                            <div class="input-group">
                                <div class="input-group-prepend center p-r-10">
                                    <i class="fa fa-pencil f-20"></i>
                                </div>
                                <mat-form-field>
                                    <input matInput placeholder="Romanian">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer center">
            <button type="button" class="btn" (click)="modal.close('Close click')">{{ 'save' | translate }}
                <i class="material-icons f-18 pl-2">send</i>
            </button>
        </div>
    </ng-template>
</div>
