<div class="dashboard-card dash_cont">
    <div class="d-flex">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="lotName !== ''">
            <h2>{{ lotName }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-4 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="dataSource.length == 0" style="display.: flex; align-items:center; justify-content: center; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 70%; width: 100%;">
        <h3 style="text-align: center;">
          <span class="loader">
            <i></i>
          </span>
      </h3>
      </div> -->
    <div class="card-content" *ngIf="dataSource.length != 0">
        <table>
            <tr *ngFor="let item of dataSource">
                <td class="icon">
                    <span *ngIf="item?.currentSensor?.icon">
                        <img [src]="mediaUrl + item.currentSensor?.icon?.file" alt="">
                    </span>
                    <span *ngIf="!item?.currentSensor?.icon && item?.unitTypes[0].icon">
                        <img [src]="mediaUrl + item?.unitTypes[0].icon?.file" alt="default placeholder">
                    </span>
                    <span *ngIf="!item?.currentSensor?.icon && !item?.unitTypes[0].icon">
                        <img src="../../../../assets/icons/sensor_microchip.svg" alt="default placeholder">
                    </span>
                </td>
                <td class="name overviewName">
                    <div class="main_text">
                        {{item?.name}}
                        <span>{{item?.devices[0]?.product?.label?.name}} {{item?.devices[0]?.id}}</span>
                    </div>
                    <div class="tooltip">{{item?.name}} <span>{{item?.devices[0]?.product?.label?.name}} {{item?.devices[0]?.id}}</span></div>
                </td>
                <td class="action_btn">
                    <button *ngIf="item?.currentSensor?.type && item?.currentSensor?.type?.groups" (click)="getFormTypes(item)">
                        <ng-container *ngFor="let group of item.currentSensor?.type?.groups">
                            <img src="../../../../assets/images/icons/link.png" alt="icon" *ngIf="group === 'actuator'">
                        </ng-container>
                    </button>
                    
                </td>
                <td class="dateTime">
                    <span *ngIf="item?.date">
                        {{item?.date}}
                    </span>
                    <span *ngIf="!item?.date">
                        --
                    </span>
                </td>
                <td class="value">
                    <span *ngIf="item?.currentSensor?.lastValue && item?.currentSensor?.lastValue?.value_coord">
                        <button (click)="displayCoord(item?.currentSensor?.lastValue?.value_coord)"><img src="../../../../assets/icons/marker-white.png" style="max-width: 12px" alt="marker"></button>
                    </span>
                    <span *ngIf="item?.value_float">
                        {{item?.value_float | number:'1.'+globalRegistry.systemData.company.digits}}
                    </span>
                    <span *ngIf="!item?.value_float && !item?.currentSensor?.lastValue?.value_coord">
                        0
                    </span>
                </td>
                <td class="unit">
                    <span>{{item.unit && item.unit !== 'null' ? item.unit : ''}}</span>
                </td>
            </tr>
        </table>
      </div>
</div>


<fap-modal #automationPopup [inputTitle]="'widget.automation' | translate">
    <form [formGroup]="addEditAutomationForm" class="col-12 p-0">
        <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput1', automation, addEditAutomationTranslationPopup, 'addEditAutomationForm')">
            <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
            <input matInput formControlName="name" readonly #nameInput1>
            <mat-error *ngIf="addEditAutomationForm.controls['name_t'].errors?.required">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
        <fap-add-edit-delete-select 
        [inputName]="'scheduler'"
        style="max-width: 100%; padding: 0;"
        class="nhalf_width col-4"
        [formControl]="addEditAutomationForm.controls['scheduler']"
        name="scheduler"
        [inputIsRequired]="true"
        [apiSearchEnabled]="true"
        [inputNameFieldName]="'name'"
        [inputNameTFieldName]="'name_t'"
        [inputValueFieldName]="'id'"
        [inputSelectValues]="schedulers"
        [inputConfirmDeleteText]="'widget.confirmDeleteScheduler' | translate"
        (outputOnDelete)="deleteScheduler($event)"
        (outputOnCreate)="schedulerPopup.showModal()"
        (outputOnEdit)="editScheduler($event)"
        (selectionChange)="addEditAutomationForm.get('scheduler').setValue($event)"
        >
    </fap-add-edit-delete-select>
        <!-- <mat-form-field class="w-100">
            <mat-label>{{ 'widget.scheduler' | translate }}</mat-label>
            <mat-select #mys formControlName="scheduler" required>
                <div class="d-flex" style="padding: 0 10px; position: relative; align-items: center;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #typeFilter placeholder="Search">
                </mat-form-field>
                <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <div class="input-group-append pl-1" (click)="schedulerPopup.showModal()">
                    <span class="input-group-text">
                        <i class="icofont icofont icofont-plus"></i>
                    </span>
                </div>
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let scheduler of schedulers" [value]="scheduler?.id">
                    <span class="mat-option-text">
                    <div class="name">{{ scheduler?.name_t ? getTranslation(scheduler?.name_t) : '' }}</div>
                    <div class="actions">
                        <i class="fa fa-edit" (click)="editScheduler(scheduler); $event.stopPropagation()"></i>
                        <i class="fa fa-trash" (click)="deleteScheduler(scheduler?.id); $event.stopPropagation()"></i>
                    </div>
                    </span>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field> -->

        <ng-container *ngFor="let autoForm of automationForm">
            <ng-container *ngFor="let formField of autoFormFields">
                <ng-container *ngIf="autoForm.type === formField.id">
                    <ng-container *ngIf="formField.valueType === 'boolean'">
                        <label>{{ formField.name }}</label>
                        <div class="custom_toggle mb-4">
                            <div class="on">
                                <input type="radio" (change)="toggleValue(1)" [formControlName]="autoForm?.id" value="1">
                                <span>On</span>
                            </div>
                            <div class="off">
                                <input type="radio" (change)="toggleValue(0)" [formControlName]="autoForm?.id" value="0">
                                <span>Off</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        
        <div class="btns">
            <div class="delt_btn_container">
                <button class="delt_btn" *ngIf="automation">
                    <i class="fa fa-trash" (click)="deleteAutomation(automation?.id)"></i>
                </button>
            </div>
            <div class="action_btns d-flex justify-content-end">
                <button class="btn mr-1" (click)="submitAutomation()">{{ 'translations.save' | translate }}</button>
                <button class="btn" (click)="automationPopup.hideModal(); addEditAutomationForm.reset()">{{ 'translations.cancel' | translate }}</button>
            </div>
        </div>
    </form>
</fap-modal>

<fap-modal #schedulerTriggerModal [inputTitle]="'scheduler.command' | translate" [showFooter]="false" [inputIsMedium]="true">
    <fap-scheduler-trigger 
        [formTypeId]="sensorData?.currentSensor?.type?.entity_form"
        [sensorId]="sensorData?.currentSensor?.id"
    ></fap-scheduler-trigger>
</fap-modal>

<fap-modal #addEditTranslationsPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="SENSOR_" 
        [label]="label"
        [translation]="translation"
        [group]="'sensors'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditAutomationTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.currentSensor)" 
        prefix="TRIGGER_" 
        [label]="label"
        [translation]="automationTranslation"
        [group]="'triggers'"
        (submitTranslation)="onAddUpdateAutomationTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #mapModal [showFooter]="false" [inputTitle]="'widget.location' | translate" [inputIsMediumLarge]="true">
    <div class="form-group map-container">
        <div
          class="search"
          [ngClass]="{ opened: mapSearchToggle, 'd-flex': mapTypeControl }"
        >
          <label for="search" class="fa fa-search" (click)="mapSearchToggle = true"> </label>
            <input
              matInput
              (focus)="mapSearchToggle = true"
              (focusout)="mapSearchToggle = false"
              (keydown.enter)="$event.preventDefault()"
              placeholder="search for location"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              #search
              [formControl]="searchControl"
            />
          
        </div>
        <agm-map
          [latitude]="currentCenter?.lat"
          [longitude]="currentCenter?.lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          [scaleControl]="false"
          [streetViewControl]="false"
          [mapTypeControl]="false"
          [mapTypeId]="'hybrid'"
          (mapClick)="mapClicked($event)"
          (mapReady)="mapReady($event)"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [iconUrl]="m.iconUrl"
            [animation]="'DROP'"
            (dragEnd)="markerDragEnd(m, $event)"
          >
            <!-- [animation]="'DROP'"  -->
      
            <agm-info-window>
              <strong>{{ m.content }}</strong>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
</fap-modal>