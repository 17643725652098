import { Routes } from '@angular/router';

import { EquipmentsContainerComponent } from './containers/equipments-container/equipments-container.component';
import { EquipmentAddEditContainerComponent } from './containers/equipment-add-edit-container/equipment-add-edit-container.component';

export const equipmentsRoutes: Routes = [
    {
        path: '',
        component:  EquipmentsContainerComponent
    },
    {
        path: 'edit/:equipmentId',
        component: EquipmentAddEditContainerComponent
    },
    {
        path: 'add',
        component: EquipmentAddEditContainerComponent
    }
];
