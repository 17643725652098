
<div *ngIf="activityGroup && activities">
    <div class="activity-group align-items-center" (click)="expanded = !expanded">
        <i *ngIf="expanded; else notExpanded" class="material-icons">keyboard_arrow_down</i>
        <ng-template #notExpanded>
            <i class="material-icons">keyboard_arrow_right</i>
        </ng-template>
        {{ activityGroup.name }}
        <i class="material-icons edit" [routerLink]="'/pages/activity/groups/edit/' + activityGroup.id">edit</i>
        <i class="material-icons delete ml-3" (click)="deleteActivityGroup.emit(activityGroup.id);$event.stopPropagation()">delete_outline</i>
    </div>
    <ng-container *ngIf="expanded">
        <fap-activity-row
            *ngFor="let activity of groupActivities"
            [activity]="activity"
            [hideButtons]="true">
        </fap-activity-row>
    </ng-container>
</div>
