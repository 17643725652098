import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpErrorResponse,
    HttpParams
} from '@angular/common/http';

import {
    Observable,
    throwError
} from 'rxjs';
import {
    map,
    catchError,
    share
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ResponseModel } from '../models/response.model';
import { FapBaseParameterInterface } from './data/fap-base-parameter.interface';
import { FapBaseModel } from './fap-base-model';
import { FapConfig } from '../config/fap.config';
import { environment } from '../../../environments/environment.prod';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export enum ErrorCodes {
    BLOCKED = -102,
    TOKEN_EXPIRED = -120,
    TOKEN_NOT_FOUND = -125
}

@Injectable()
export class FapRestBaseService {
    public headers: HttpHeaders;
    public url: string;

    private fapConfig: FapConfig;

    constructor(private tosterService: ToastrService,
        private _baseHttp: HttpClient,
        private _baseUrl: string,
        private router?: Router,
        private translateService?: TranslateService,
        ) {
        this.fapConfig = FapConfig.getInstance();
        this.headers = new HttpHeaders({'Content-Type': 'application/json'});
        // this.url = this.fapConfig.API_URL + this.fapConfig.API_VERSION + _baseUrl;
        this.url = this.fapConfig.API_URL + _baseUrl;
    }

    public get http(): HttpClient {
        return this._baseHttp;
    }


    // protected mapRequest<T extends FapBaseModel>(request: Observable<any>,
    //                                                   model?: any,
    //                                                   manyModels: boolean = false,
    //                                                   customResponse: boolean = false,
    //                                                   err?: (error: HttpErrorResponse) => void,
    //                                                   isRaw?: boolean): Observable<ResponseModel<T>> {

    //     const responseModel: ResponseModel<T | T[]> = new ResponseModel();

    //     const req: Observable <any> = request.pipe(
    //         map((response: HttpResponse<T>) => {
    //             if (response.status === 204) {
    //                 console.warn(response);
    //             } else if (isRaw) {
    //                 return response;
    //             } else {

    //                 responseModel.nativeResponse = response;
    //                 // responseModel.body = response.text() === '' ? {} : response.json();

    //                 let results: any;

    //                 if (customResponse) {
    //                     results = responseModel;
    //                 } else {
    //                     results = responseModel.body.results;
    //                 }
    //                 if (model) {
    //                     if (this.validateData(model, results, manyModels)) {
    //                         if (manyModels) {
    //                             responseModel.model = [];
    //                             responseModel.model.push();
    //                             for (const key in results) {
    //                                 if (results.hasOwnProperty(key)) {
    //                                     responseModel.model.push(model.parseJson(model, results[key]));
    //                                 }
    //                             }
    //                         } else {
    //                             responseModel.model = model.parseJson(model, results.nativeResponse);
    //                         }
    //                     } else {
    //                         responseModel.errorMessage = 'Validation error';
    //                         this.tosterService.error('Validation error');
    //                         return throwError(responseModel);
    //                     }
    //                 }

    //                 return responseModel;
    //             }
    //         }),
    //         catchError((error: Response) => {
    //             console.warn(error);
    //             // let apiResponse: ApiErrorResponseModel = error.text() === '' ? {} : error.json();
    //             // this.tosterService.error('Oops. Error', apiResponse.error_string);
    //             // this.tosterService.error('Oops. Error', error['error'].results.error);
    //             return throwError(error);
    //         }),
    //         share()
    //     );

    //     return req;
    // }

        protected mapRequest<T extends FapBaseModel>(request: Observable<any>,
                                                          model?: any,
                                                          manyModels = false,
                                                          customResponse = false,
                                                          err?: (error: HttpErrorResponse) => void,
                                                          isRaw?: boolean): Observable<ResponseModel<T>> {

        const responseModel: ResponseModel<T | T[]> = new ResponseModel();

        const req: Observable<any> = request.pipe(
            map((response: HttpResponse<T>) => {
                if (response === null) {
                    return response;
                }
                if (response.status === 204) {
                    console.warn(response);
                } else if (isRaw) {
                    return response;
                } else {

                    responseModel.body = response;

                    // if (response.hasOwnProperty('count')) {
                    //     responseModel.count = response['count'];
                    // }

                    let results: any;

                    if (customResponse) {
                        results = responseModel.body;
                    } else {
                        results = responseModel.body.results;
                    }

                    if (model) {
                        if (this.validateData(model, results, manyModels)) {
                            if (manyModels) {
                                responseModel.model = [];
                                responseModel.model.push();
                                for (const key in results) {
                                    if (Object.prototype.hasOwnProperty.call(results, key)) {
                                        responseModel.model.push(model.parseJson(model, results[key]));
                                    }
                                }
                            } else {
                                responseModel.model = model.parseJson(model, results);
                            }
                        } else {
                            responseModel.errorMessage = 'Validation error';
                            if (environment.production) {
                                this.tosterService.error('Validation error');
                                console.error(responseModel);
                            }
                            return throwError(responseModel);
                        }
                    }

                    return responseModel;
                }
            }),
            catchError((error: HttpErrorResponse) => {
                // console.warn(error);

                // let apiResponse: ApiErrorResponseModel = error.text() === '' ? {} : error.json();
                // this.alertService.showError('Oops. Error', apiResponse.error_string);

                // this.alertService.showError('Oops. Error', error.message);
                if(error.error) {
                    if(error.error.results.code && (error.error.results.code == ErrorCodes.BLOCKED || error.error.results.code == ErrorCodes.TOKEN_EXPIRED || error.error.results.code == ErrorCodes.TOKEN_NOT_FOUND)) {
                        if(error.error.results.code == ErrorCodes.BLOCKED) {
                            this.tosterService.error('Your account is blocked (Not active)!')
                        } else {
                            this.tosterService.error('Token is expired')
                        }
                        setTimeout(()=> {
                            localStorage.removeItem('auth_token')
                            const language = localStorage.getItem('language');
                            localStorage.clear();
                            localStorage.setItem('language', language);
                            location.reload();
                        },2500)
                    }
                }
                return throwError(error);
            }),
            share()
        );

        return req;
    }

    protected camelCaseModelToSnakeCaseJson<T>(model: T): T {
        let json: any = JSON.stringify(model);
        for (const key in model) {
            if (Object.prototype.hasOwnProperty.call(model, key)) {
                const snakeCasedKey: any = key.replace(
                    /\.?([A-Z]+)/g, (x, y) => {
                        return '_' + y.toLowerCase();
                    }).replace(/^_/, '');
                json = json.replaceAll('' + key + '', '' + snakeCasedKey + '');
            }
        }        
        return JSON.parse(json);
    }

    protected validateData(model: any, results: any, manyModels: boolean): boolean {
        if (manyModels) {
            for (const key in results) {
                if (Object.prototype.hasOwnProperty.call(results, key)) {
                    if (!model.validateModel(model, results[key])) {
                        return false;
                    }
                }
            }            
        } else {
            if (!model.validateModel(model, results)) {
                return false;
            }
        }

        return true;
    }

    protected createUrlSearchParams(params: object): HttpParams {
        const paramsClone: FapBaseParameterInterface = Object.assign({}, params);

        const paramsObject: {
            [param: string]: string | ReadonlyArray<string>;
        } = {};

        if(paramsClone !== undefined && paramsClone !== null) {
            Object.keys(paramsClone).map((key: string) => {
                if(paramsClone[key] !== null && paramsClone[key] !== undefined && paramsClone[key] !== '') {
                    paramsObject[key] = String(paramsClone[key]);
                }
            });
        }

        return new HttpParams({
            fromObject: paramsObject
        });
    }
}
