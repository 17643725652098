import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { NavService } from '../../services/nav.service';

@Component({
    selector: 'fap-satellite-widget',
    templateUrl: './fap-satellite-widget.component.html',
    styleUrls: ['./fap-satellite-widget.component.scss'],
})
export class FapSatelliteWidgetComponent implements OnInit, OnDestroy {
    public selectedWidget;
    @Input()
    public widget: WidgetModel;

    public toggleMain = false;
    public toggleSub = false;

    public showMenu = true;
    public apiUrl = environment.baseBackendUrl;
    public size = [];
    public imageUrl = null;
    public infoText = null;
    public status: number = null;
    public dates = [];
    public selectedDate = null;
    public selectedRecord = null;
    public logic = true;
    public isLoading = false;
    private subscriptions: Array<Subscription> = [];
    public satelliteId: number;
    public fId: number;
    public waterObject;
    public sensorId: number;
    public translatedNames: any = [];
    @Input() public langString: string;

    constructor(
        private globalRegistry: GlobalRegistryService,
        private sensorService: SensorService,
        private toastr: ToastrService,
        public navService: NavService
    ) {}

    public toggleImageMenu() {
        this.showMenu = !this.showMenu;
    }

    ngOnInit(): void {
        this.getInfo();
        this.translatedNames = this.globalRegistry.systemData.translations;
    }

    getTranslation(translation) {
        const t =this.globalRegistry.systemData.translations.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
          }
      }
    
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
          sub.unsubscribe();
        });
    }

    public getRecord(fid, id, time) {
        this.isLoading = true;
        this.sensorService
            .getAcquisition({
                cmd: 301,
                lot: this.widget.objectId,
                params: {
                    time: time,
                    id: id,
                    fid: fid,
                },
            })
            .subscribe((data) => {
                console.log(data);
                this.selectedRecord = data.id;
                this.imageUrl = data.image;
                this.infoText = data.info;
                this.status = data.status;
                if (
                    data.info != null &&
                    data.status != null &&
                    data.id != null &&
                    data.image != null
                ) {
                    this.logic = false;
                }
                this.isLoading = false;
            });
    }

    public getInfo() {
        console.log(this.widget);
        this.sensorId = this.widget.config['settings']['options'].sensor.id;
        function toDateTime(secs) {
            const t = new Date(1970, 0, 1); // Epoch
            t.setSeconds(secs).toLocaleString('en-US');
            return t;
        }
        this.sensorService
            .getAcquisition({
                cmd: 300,
                lot: this.widget.objectId,
                params: {},
            })
            .subscribe((data) => {
                this.satelliteId = data.id;
                    this.fId = data.fid;
                    if(data.length > 0) {
                    data.forEach((element) => {
                        this.dates.push({
                            id: element.id,
                            fid: element.fid,
                            time: element.time,
                            date: toDateTime(element.time)
                        });
                    });
                
                console.log(data);
                this.waterObject = data;
                this.selectedDate = this.dates[0].time;
                this.fId = this.dates[0].fid;
                this.satelliteId = this.dates[0].id;
                this.getRecord(this.fId, this.satelliteId, this.selectedDate);
                    }
            });
    }

    public changeDropdown(value) {
        this.dates.forEach((element) => {
            if (element.id == value) {
                console.log(element);
                this.logic = true;
                this.getRecord(element.fid, element.id, element.time);
            }
        });
    }

    public getAcquisition() {
      this.isLoading = true;
        this.subscriptions.push(this.sensorService
            .getAcquisition({
                cmd: 302,
                lot: this.widget.objectId,
                params: { time:  Math.round((new Date()).getTime() / 1000)},
            })
            .subscribe((data) => {
                console.log(data);
                this.toastr.success("Satellite blockchain request "+data.reqId, 'Satellite', {
                    timeOut: 6000,
                  });
                this.selectedRecord = data.id;
                this.imageUrl = data.image;
                this.infoText = data.info;
                this.status = data.status;
                if (data.id != null && data.image != null) {
                    this.logic = false;
                }
                this.isLoading = false;
            }));
    }

    public getLotName(lotId: number = this.widget.objectId): string {
        const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
        return lot ? lot.name : '';
      }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }
}
