<div *ngIf="bill" class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="invoice">
                        <div id="print-section">
                            <div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="media">
                                            <div class="media-body m-l-20">
                                                <h4 class="media-heading">{{ bill.createdBy }}</h4>
                                                <p>
                                                    {{ 'stock.billType' | translate }}: {{ 'stock.' + bill.billType | translate }}<br>
                                                    <ng-container *ngIf="bill.internalBill">{{ 'stock.internalBill' | translate }}</ng-container>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- End Info-->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-md-right">
                                            <h3>{{ 'stock.bill' | translate }} #<span class="digits counter">{{ bill.id }}</span></h3>
                                            <p>{{ 'createdAt' | translate }}: {{ bill.createdAt | date: 'short' }}<br>
                                                {{ 'updatedAt' | translate }}: {{ bill.updatedAt | date: 'short' }}<br />
                                                <ng-container *ngIf="bill.receiveDate"> Receive date:
                                                    {{ bill.receiveDate | date: 'short' }}</ng-container>
                                            </p>
                                        </div>
                                        <!-- End Title-->
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <!-- End InvoiceTop-->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="media">
                                        <div class="media-left"><img class="media-object rounded-circle img-60"
                                                src="assets/images/user/1.jpg" alt=""></div>
                                        <div class="media-body m-l-20">
                                            <h4 class="media-heading">{{ bill.partyFrom.name }}</h4>
                                            <p> Email: {{ bill.partyFrom.email }}<br>
                                                {{ 'address' | translate }}: {{ bill.partyFrom.address }}<br>
                                                {{ 'party.vatPayer' | translate }}:
                                                {{ (bill.partyFrom.vatPayer ? 'yes' : 'no') | translate }}<br>
                                                <ng-container *ngIf="bill.partyFrom.website"> Website:
                                                    {{ bill.partyFrom.website }}</ng-container>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-8">
                    <div class="text-md-right" id="project">
                      <h6>Project Description</h6>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.It is a long
                        established fact that a reader will be distracted by the readable content of a page when looking
                        at its layout.</p>
                    </div>
                  </div> -->
                            </div>
                            <!-- End Invoice Mid-->
                            <div>
                                <div class="table-responsive invoice-table" id="table">
                                    <table class="table table-bordered table-striped">
                                        <tbody>
                                            <tr>
                                                <td class="item">
                                                    <h6 class="p-2 mb-0">No.</h6>
                                                </td>
                                                <td class="item">
                                                    <h6 class="p-2 mb-0">{{ 'date' | translate }}</h6>
                                                </td>
                                                <td class="item">
                                                    <h6 class="p-2 mb-0">{{ 'stock.resource' | translate }}</h6>
                                                </td>
                                                <td class="item">
                                                    <h6 class="p-2 mb-0">{{ 'stock.quantity' | translate }}</h6>
                                                </td>
                                                <td class="item">
                                                    <h6 class="p-2 mb-0">{{ 'stock.price' | translate }}</h6>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let transaction of bill.transactions; let idx=index">
                                                <td>
                                                    <label>{{ idx + 1 }}.</label>
                                                </td>
                                                <td>
                                                    <label>{{ transaction.createdAt | date:'short' }}</label>
                                                </td>
                                                <td>
                                                    <label>{{ transaction.resourceEntry.stockItem.type.name }}</label>
                                                </td>
                                                <td>
                                                    <label>{{ transaction.quantity }} {{ transaction.unit.shortName }}</label>
                                                </td>
                                                <td>
                                                    <label>{{ transaction.cost | currency:'RON' }}</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td class="Rate">
                                                    <h6 class="mb-0 p-2">Total</h6>
                                                </td>
                                                <td class="payment digits">
                                                    <h6 class="mb-0 p-2">
                                                        {{ totalPrice | currency:'RON' }}
                                                    </h6>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- End Table-->
                            </div>
                            <!-- End InvoiceBot-->
                        </div>
                        <!-- End Invoice-->
                        <!-- End Invoice Holder-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
