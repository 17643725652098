import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
  selector: 'reports-container',
  templateUrl: './reports-container.component.html',
  styleUrls: ['./reports-container.component.scss']
})
export class ReportsContainerComponent implements OnInit, OnDestroy {

  constructor(public mapService: MapService, public widgetService: WidgetsService) { }

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetService.setSize(12);
  }

  ngOnDestroy(): void {
    this.widgetService.setSize(6);
  }

}
