import { Routes } from '@angular/router';
import { PrecisionContainerComponent } from './containers/precision-container/precision-container.component';


export const precisionRoutes: Routes = [
    {
        path:       '',
        component:  PrecisionContainerComponent
    }
];
