import { LotModel } from '../../../../core/models/lot/lot.model';
import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { WidgetModel } from '../../../../core/models/widget/widget.model';
import { WidgetInterface } from '../../../../core/interfaces/widget/widget.interface';
import { SensorModel } from '../../../../core/models/sensor/sensor.model';
import { FarmModel } from '../../../../core/models/farm/farm.model';

@Component({
    selector: 'fap-widget-board',
    templateUrl: './fap_widget-board.component.html',
    styleUrls: ['./fap_widget-board.component.scss']
})
export class FapWidgetBoardComponent {

    @Input()
    public widgets: Array<WidgetModel> = [];

    @Input()
    public sensors: Array<SensorModel> = [];

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Output()
    public addWidget: EventEmitter<WidgetInterface> = new EventEmitter();

    @Output()
    public deleteWidget: EventEmitter<number> = new EventEmitter();

    public isEditMode = false;

}
