import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { ReportsService } from '../../../../../core/services/api/reports/reports.service';
import { FapModalComponent } from '../../../../../shared/partials';
import moment from 'moment';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LT',
  },
  display: {
    dateInput: 'l, LT',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
  selector: 'reports-layout',
  templateUrl: './reports-layout.component.html',
  styleUrls: ['./reports-layout.component.scss'],
  providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}]
})
export class ReportsLayoutComponent implements OnInit, OnDestroy {

  public reports = [];
  public subscriptions: Array<Subscription> = [];
  public templates = [];
  public checkIds = [];
  public reportForm: UntypedFormGroup;
  public minDate;
  public persons: Array<PersonModel> = [];
  public maxDate;
  public mediaUrl = environment.mediaUrl;
  public lots: Array<LotModel> = [];
  public filteredLots = [];
  public isSelectDialogOpen: boolean = false;
  @ViewChild('requestReportModal')
  public requestReportModal: FapModalComponent;

  constructor(public reportsService: ReportsService, public activatedRoute: ActivatedRoute, public router: Router, public formBuilder: UntypedFormBuilder, public globalRegistry: GlobalRegistryService, public toastrService: ToastrService, public companyService: CompanyService) { 
    this.initForm();
  }

  ngOnInit(): void {
    this.lots = this.globalRegistry.systemData.lots;
    this.filteredLots = this.lots;
    // this.subscriptions.push(
    //   this.companyService.getPersons({limit: 50}).subscribe(data => {
    //     console.log(data);
    //     this.persons = data.body.results;
    //   })
    // )
    this.getReports();
    this.getTemplates();
  }

  public initForm() {
    const formBody = {
      type: [null, Validators.compose([Validators.required])],
      farm: [null, Validators.compose([Validators.required])],
      lot: [null, Validators.compose([Validators.required])],
      start: [null],
      end: [null],
    }
    this.reportForm = this.formBuilder.group(formBody);
  }

  showModal() {
    this.reportForm.markAsUntouched();
    this.requestReportModal.showModal();
  }

  hideModal() {
    const elem = document.getElementById('reports_modal');
    const parent = document.getElementById('parent90');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
    this.router.navigate(['pages/reports'])
  }

  public deleteMarkedRecords() {
    console.log(this.checkIds);
  }

  public filterLots(ev) {
    const id = ev.value;
    this.filteredLots = [];
    this.lots.forEach(lot => {
      if(lot.farm === id) {
        this.filteredLots.push(lot);
      }
    });    
  }

  public ifControlHasError(controlName: string): boolean {
    const control: any = this.reportForm.controls[controlName];
    if (!control) {
        return false;
    }
  }

  public deleteReport(id) {
    console.log(id);
    this.reportsService.deleteReport(id).subscribe(data => {
      console.log(data);
      this.getReports();
    })
  }

  public addReport() {
    console.log(this.reportForm.value);
    if(this.reportForm.invalid) {
      this.reportForm.markAllAsTouched();
      this.toastrService.error('Please fill in all required fields')
      return;
    } else {
      
            const s = this.reportForm.value['start']
                ? moment(this.reportForm.value['start']).format('YYYY-MM-DD')
                : null;
          //  const start = s;
        
            const dateTime = this.reportForm.value['end']
                ? moment(this.reportForm.value['end']).format('YYYY-MM-DD')
                : null;
          //  const end = dateTime;
        

        const report = {
          type: this.reportForm.get('type').value,
          start: s,
          end: dateTime,
          farm: this.reportForm.get('farm').value,
          lot: this.reportForm.get('lot').value
        }
        console.log(report);
        
      this.reportsService.addReport(report).subscribe(data => {
        console.log(data);
        this.getReports();
        this.requestReportModal.hideModal();
      });
    }
  }

  public getReports() {
    this.subscriptions.push(
      this.reportsService.getReports().subscribe(data => {
        this.reports = data.body.results;
      })
    )
  }

  public getTemplates() {
    this.subscriptions.push(this.reportsService.getTemplates().subscribe(data => {
      console.log(data);
      this.templates = data.body.results;
    }));
  }

  public minValueChanged(ev) {
    this.minDate = ev.target.value;
}

public maxValueChanged(ev) {
    this.maxDate = ev.target.value;
}

  selectEntry(event, item) {
    let checkIds;
    if (event.target.checked === true) {
        this.checkIds.push(item.id);
        checkIds = this.checkIds.filter(
            (item, index, self) => self.indexOf(item) == index
        );
    } else {
        checkIds = this.checkIds;
        const index = checkIds.indexOf(item.id);
        checkIds.splice(index, 1);
    }
    this.checkIds = checkIds;
    console.log(checkIds);
}

  CheckAllOptions() {
      
    if (this.reports.every(val => {
      this.checkIds.push(val.id);
      return val.checked == true
    }))
      this.reports.forEach(val => {
        this.checkIds = [];
        return val.checked = false 
      });
    else
      this.reports.forEach(val => {
        this.checkIds.push(val.id);
        return val.checked = true
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();      
    });
  }

}
