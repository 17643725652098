<fap-header>
     <div class="d-flex filters align-items-center">
          <div class="tree_combo mr-3">
               <fap-tree-combo></fap-tree-combo>
             </div>
         <div class="add_btn mr-3">
           <fap-round-button [backgroundColor]="'tertiary'" [routerLink]="'/pages/equipments/add'"></fap-round-button>
         </div>
          <fap-search-filter
               [isSearchEnabled]="true"
      >   </fap-search-filter>
       </div>
  </fap-header>
<div class="container-fluid ter-container nc">
     <fap-equipments-layout
          [farms]="filteredFarms"
          [equipments]="filteredEquipments"
          [farmForecastMapping]='farmForecastMapping'
          [equipmentTypes]="globalRegistry.systemData.equipmentTypes"
          [equipmentPoweredTypes]="equipmentPoweredTypes"
          [parties]="globalRegistry.systemData.parties"
          (deleteEquipment)="deleteEquipment($event)">
     </fap-equipments-layout>
</div>
