import { Component } from '@angular/core';


@Component({
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss']
})
export class NotesComponent {

}
