import { BaseTypeModel } from '../base/base-type.model';


export class ResourceTypeModel extends BaseTypeModel {
    public static fields: Array<string> = BaseTypeModel.fields.concat([
        'children',
        'parent'
    ]);

    public static requiredFields: Array<string> = BaseTypeModel.fields.concat([
        'children',
    ]);

    public static extendedFields: {} = {
        children: ResourceTypeModel
    };

    public children: Array<ResourceTypeModel> = [];
    public parent: string;

    public get fullPathName(): string {
        if(this.parent) {
            return this.parent + ' - ' + this.name;
        }

        return this.name;
    }
}
