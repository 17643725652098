import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
    selector: 'fap-ingredient-table',
    templateUrl: './fap_ingredient-table.component.html',
    styleUrls: ['./fap_ingredient-table.component.scss']
})
export class FapIngredientTableComponent implements OnChanges, OnInit, OnDestroy {

    @Input()
    public headerTitle: string;

    @Input()
    public farmId: number;

    @Input()
    public ingredients: Array<IngredientModel>;

    @Input()
    public ingredientTypes: Array<IngredientTypeModel>;

    @ViewChild('addEditIngredientModal')
    public addEditIngredientModal: FapModalComponent;

    @Output()
    public createdIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public updatedIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public deleteIngredient: EventEmitter<number> = new EventEmitter();

    @Output()
    public deleteLocalIngredient: EventEmitter<number> = new EventEmitter();

    @Output()
    public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public selectedIngredient: IngredientModel = null;
    public ingredientTypeMap: { [key: number]: IngredientTypeModel } = {};
    public langString: string = null;
    public subscription: Subscription = new Subscription();

    constructor(public globalRegistry: GlobalRegistryService, private navService: NavService) {}

    ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    }

    public addIngredient(): void {
        this.selectedIngredient = null;
        this.addEditIngredientModal.showModal();
    }

    getTranslation(translation) {
        const t = this.globalRegistry.systemData.translations.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
          if(t[0][this.langString] === null || t[0][this.langString] === '') {
            return translation
          } else {
            return t[0][this.langString];
          }
        } else {
            return translation
          }
      }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'ingredientTypes') && this.ingredientTypes) {
            this.ingredientTypeMap = {};
            this.ingredientTypes.forEach((ingredientType: IngredientTypeModel): void => {
                this.ingredientTypeMap[ingredientType.id] = ingredientType;
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
