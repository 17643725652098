import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { UserService } from '../api/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
    private renderer: Renderer2;
    private styleEl: any;
    public theme;

  constructor(rendererFactory: RendererFactory2, public userService: UserService) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.styleEl = this.renderer.createElement('style');
    this.renderer.appendChild(document.head, this.styleEl);
    this.userService.getTheme.subscribe((user) => {
        if (Object.keys(user).length != 0) {
            console.log(user);
            this.theme = user;
            const css = `
            :root {
                --primary-color: ${this.theme['primary-color'] ? this.theme['primary-color'] : 'rgb(43, 103, 76)'}!important;
                --secondary-color: ${this.theme['secondary-color'] ? this.theme['secondary-color'] : 'rgba(169, 209, 142, 1)'}!important;
                --primary-color-light: ${this.theme['primary-color-light'] ? this.theme['primary-color-light'] : 'rgba(169, 209, 142, 0.5)'}!important;
                --primary-text-color: ${this.theme['primary-text-color'] ? this.theme['primary-text-color'] : 'rgb(255, 255, 255)'}!important;
                --secondary-text-color: ${this.theme['secondary-text-color'] ? this.theme['secondary-text-color'] : 'rgb(0, 0 ,0)'}!important;
                --tertiary-color: ${this.theme['tertiary-color'] ? this.theme['tertiary-color'] : 'rgb(235, 255, 0)'}!important;
                --tertiary-text-color: ${this.theme['tertiary-text-color'] ? this.theme['tertiary-text-color'] : 'rgba(43, 103, 76, 0.8)'}!important;
                --text-color: ${this.theme['text-color'] ? this.theme['text-color'] : 'rgb(0, 0 ,0)'}!important;
                --text-color-light: ${this.theme['text-color-light'] ? this.theme['text-color-light'] : 'rgb(128, 128, 128)'}!important;
                --bar-color: ${this.theme['bar-color'] ? this.theme['bar-color'] : 'rgb(64, 53, 59)'}!important;
                --dark-bg: ${this.theme['dark-bg'] ? this.theme['dark-bg'] : 'rgb(255, 255, 255)'}!important;
                --dark-text: ${this.theme['dark-text'] ? this.theme['dark-text'] : 'rgb(0,0,0)'}!important;
              }
            `;
            const text = this.renderer.createText(css);
            this.renderer.appendChild(this.styleEl, text);
        }
    })
  }
}