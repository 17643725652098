import { Component } from '@angular/core';


@Component({
    templateUrl: './pathogens.component.html',
    styleUrls: ['./pathogens.component.scss']
})
export class PathogensComponent {

}
