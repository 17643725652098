<div class="wrapper">
	<div class="content">
	  <ng-content></ng-content>
	</div>
	<div class="bar" 
		 resizable 
		 (widthChange)="onResize($event)" 
		 (resizeEnd)="onResizeEnd()">
	</div>
  </div>  
  