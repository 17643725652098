import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../services/nav.service';
import { Subscription } from 'rxjs';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fap-scheduler-row',
  templateUrl: './fap-scheduler-row.component.html',
  styleUrls: ['./fap-scheduler-row.component.scss']
})
export class FapSchedulerRowComponent implements OnInit, OnChanges {

  @Input() public scheduler: any = null;
  @Input() public trigger: any = null;
  @Input() public triggerId: number = null;
  @Input() public showHeader: boolean = true;
  @Input() public showActions: boolean = true;
  @Input() public currentFormType = null;
  @Input() public entityInfo = null;
  public formType;

  public langString: string;
  public subscriptions: Array<Subscription> = [];

  constructor(public globalRegistry: GlobalRegistryService, private navService: NavService, private attributeService: AttributeService, private toastrService: ToastrService, public cacheService: CacheResolverService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
  }

  getMonths(month: string): string {
    if(!month) {
      return
    }
    if (month === "*") {
        return "All months";
    } else {
        const monthNumbers: number[] = month.split(",").map(Number);
        const monthNames: string[] = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const selectedMonthNames: string[] = monthNumbers.map(num => monthNames[num - 1]);
        return selectedMonthNames.join(", ");
    }
  }

  getWeekdays(weekdays: string): string {
    if(!weekdays) {
      return
    }
    if (weekdays === "*") {
        return "All weekdays";
    } else {
        const weekdayNumbers: number[] = weekdays.split(",").map(Number);
        const weekdayNames: string[] = [
            "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
        ];
        const selectedWeekdayNames: string[] = weekdayNumbers.map(num => weekdayNames[num - 1]);
        return selectedWeekdayNames.join(", ");
    }
  }

  getDaysOfMonth(days: string): string {
    if(!days) {
      return
    }
    if (days === "*") {
        return "All days of the month";
    } else {
        const dayNumbers: number[] = days.split(",").map(Number);
        const availableDays: number = dayNumbers.length;
        const dayNames: string[] = [];
        for (let i = 0; i < availableDays; i++) {
            dayNames.push(dayNumbers[i].toString());
        }
        return dayNames.join(", ");
    }
  }

  getHours(hours: string): string {
    if(!hours) {
      return
    }
    if (hours === "*") {
        return "All hours";
    } else {
        const hourNumbers: number[] = hours.split(",").map(Number);
        const formattedHours: string[] = hourNumbers.map(num => {
            if (num < 10) {
                return num.toString();
            } else {
                return num.toString();
            }
        });
        return formattedHours.join(", ");
    }
  }

  getMinutes(minutes: string): string {
    if(!minutes) {
      return
    }
    if (minutes === "*") {
        return "Every minute";
    } else {
        const minuteNumbers: number[] = minutes.split(",").map(Number);
        const minuteNames: string[] = minuteNumbers.map(num => {
            if (num < 10) {
                return num.toString();
            } else {
                return num.toString();
            }
        });
        return minuteNames.join(", ");
    }
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation
    }
  }

  public getForm(formId: number) {
    let url = this.attributeService.getUrl('form/' + formId);
    this.cacheService.delete(url);
    this.attributeService.getForm(formId).subscribe({
      next: (response) => {
        console.log('responses', response);
        this.formType = response.body.results;
      },
      error: (error) => {
        this.toastrService.error(error.error.results.error);
      }
    }); 
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.hasOwnProperty('trigger') && this.trigger) {
        console.log(this.trigger);
        this.getForm(this.trigger.form);
      }
    }
}
