import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { AddressModel } from '../../../../core/models/company/address.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { AddressInterface } from '../../../../core/services/api/company/data/address.interface';

@Component({
  selector: 'fap-add-edit-address',
  templateUrl: './fap-add-edit-address.component.html',
  styleUrls: ['./fap-add-edit-address.component.scss']
})
export class FapAddEditAddressComponent implements OnChanges, OnInit, OnDestroy {

  @Input()
  public address: AddressModel = null;

  @Input()
  public type: string;

  @Output()
  public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

  @Output()
  public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

  @Output()
  public deleteAddress: EventEmitter<AddressModel> = new EventEmitter();

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter();

  @ViewChild('mysl') public mysl;

  @Input() showExtraFields = true;

  public addressForm: UntypedFormGroup;
  public countries = [];
  public subscriptions:Array<Subscription> = [];
  public defaultCountryISO = null;
  public defaultCounty = null;
  public defaultCity = null;
  public defaultPostal = null

  constructor(private formBuilder: UntypedFormBuilder,
              private companyService: CompanyService,
              private toastr: ToastrService,
              private translate: TranslateService,
              public globalRegistry: GlobalRegistryService,
              public cacheService: CacheResolverService
              ) {
      
  }

  public ngOnInit(): void {
    console.log(this.address);
    const country = JSON.parse(localStorage.getItem('country_info'))
        // console.log(country);
        this.defaultCountryISO = country['country_code_iso3'];
        this.defaultCounty = country['region'];
        this.defaultCity = country['city'];
        this.defaultPostal = country['postal']; 
        this.initAddressForm();
  }

  public selectCountry(event) {
    console.log(event);
  }

  public ngOnChanges(changes?: SimpleChanges): void {
      if(changes.hasOwnProperty('address') && this.address) {
        this.addressForm.reset();
        this.initAddressForm();
        console.log(this.address);
      }
  }

  public resetForm(): void {
      this.initAddressForm();
  }

  public submit(): void {
    console.log(this.addressForm.value);
      if (this.addressForm.invalid) {
          Object.keys(this.addressForm.controls).forEach((controlName: string): void =>
              this.addressForm.controls[controlName].markAsTouched()
          );
          this.toastr.error(this.translate.instant('equipment.pleaseFillInMandatoryFields'));
          return;
      }
      const address: AddressInterface = {
          type: this.type,
          street: this.addressForm.controls['street'].value,
          number: this.addressForm.controls['number'].value,
          building: this.addressForm.controls['building'].value,
          appt: this.addressForm.controls['appt'].value,
          city: this.addressForm.controls['city'].value,
          county: this.addressForm.controls['county'].value,
          postal_code: this.addressForm.controls['postalCode'].value,
          country: this.addressForm.controls['country'].value,
          email: this.addressForm.controls['email'].value,
          website: this.addressForm.controls['website'].value,
          phone_mobile: this.addressForm.controls['phoneMobile'].value,
          phone_fix: this.addressForm.controls['phoneFix'].value,
      };

      this.address && this.address.id ? this._updateAddress({...{id: this.address.id}, ...address}) : this._addAddress(address);
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.addressForm.controls[controlName];
      if (!control) {
          return false;
      }

      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  private initAddressForm(): void {
      this.addressForm = this.formBuilder.group({
        type: [this.type? this.type : '', Validators.compose([Validators.required])],
        street: [this.address ? this.address.street : ''],
        number: [this.address ? this.address.number : ''],
        building: [this.address ? this.address.number : ''],
        appt: [this.address ? this.address.appt : ''],
        city: [this.address ? this.address.city : '', Validators.compose([Validators.required])],
        county: [this.address ? this.address.county : ''],
        postalCode: [this.address ? this.address.postal_code : ''],
        country: [this.address ? this.address.country : '', Validators.compose([Validators.required])],
        email: [this.address ? this.address.email : '', Validators.compose([
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ])],        
        website: [this.address ? this.address.website : ''],
        phoneMobile: [this.address ? this.address.phone_mobile : ''],
        phoneFix: [this.address ? this.address.phone_fix: '']
      });
      this.addressForm.markAsUntouched();
      if(!this.address) {
        this.addressForm.get('country').patchValue(this.defaultCountryISO);
        this.addressForm.get('county').patchValue(this.defaultCounty);
        this.addressForm.get('city').patchValue(this.defaultCity);
        this.addressForm.get('postalCode').patchValue(this.defaultPostal);
      }
  }

  private _addAddress(address: AddressInterface): void {
    this.subscriptions.push(this.companyService.createAddress(address).pipe(take(1))
          .subscribe((response: ResponseModel<AddressModel>): void => {
              this.addedAddress.emit(response.body.results);
              // this.globalRegistry.reloadAddresses();
              const url = this.companyService.getUrl('address/');
              this.cacheService.delete(url);
              this.toastr.success(this.translate.instant('party.address.messages.createSuccess'));
              this.mysl.close();
          },
              (): void => {
                  this.toastr.error(this.translate.instant('party.address.messages.failCreate'));
              }));
  }

  private _updateAddress(address: AddressInterface): void {
    this.subscriptions.push(this.companyService.updateAddress(address).pipe(take(1))
          .subscribe((response: ResponseModel<AddressModel>): void => {
              this.updatedAddress.emit(response.body.results);
              // this.globalRegistry.reloadAddresses();
              const url = this.companyService.getUrl('address/'+address.id+'/');
              this.cacheService.delete(url);
              this.toastr.success(this.translate.instant('party.address.messages.updateSuccess'));
              this.mysl.close();
          },
              (): void => {
                  this.toastr.error(this.translate.instant('party.address.messages.failUpdate'));
              }));
  }
  
  ngOnDestroy(): void {
      this.subscriptions.forEach(s => {
        s.unsubscribe();
      });
      this.defaultCountryISO = null;
      this.defaultCounty = null;
      this.defaultCity = null;
      this.defaultPostal = null;
      this.address = null;
      this.addressForm.reset();
      this.addressForm.clearValidators();
  }
}
