import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../../models/response.model';

@Injectable()
export class AlergotelService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/alergotel/');
    }

    getUrl(slug) {
        return this.url+slug
      }
    
      decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);

          const data = JSON.parse(decrypted)

          const toCamel = (s) => {
            return s.replace(/([-_][a-z])/ig, ($1) => {
              return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
            });
          };
          
          const isArray = function (a) {
            return Array.isArray(a);
          };
          
          const isObject = function (o) {
            return o === Object(o) && !isArray(o) && typeof o !== 'function';
          };
          
          const keysToCamel = function (o) {
            if (isObject(o)) {
              const n = {};
          
              Object.keys(o)
                .forEach((k) => {
                  n[toCamel(k)] = keysToCamel(o[k]);
                });
          
              return n;
            } else if (isArray(o)) {
              return o.map((i) => {
                return keysToCamel(i);
              });
            }
          
            return o;
          };
          return keysToCamel(data);
        }

    public getBioParticles(params?: {}): Observable<ResponseModel<any[]>> {
      return this.mapRequest<any[]>(
          this.http.get(this.url + 'bio-particles/', {
              headers: FapAPIRequestOptions.withTokenHeaders,
              params
          }),
          false,
          false
      ).pipe(take(1));
  }

  public getRecommendations(params?: {}): Observable<ResponseModel<any[]>> {
    return this.mapRequest<any[]>(
        this.http.get(this.url + 'recommendations/', {
            headers: FapAPIRequestOptions.withTokenHeaders,
            params
        }),
        false,
        false
    ).pipe(take(1));
}

    public getReports(params?: {}): Observable<ResponseModel<any[]>> {
      return this.mapRequest<any[]>(
          this.http.get(this.url + 'reports/', {
              headers: FapAPIRequestOptions.withTokenHeaders,
              params
          }),
          false,
          false
      ).pipe(take(1));
  }

    public getReportDetail(reportId:number, params?: {}): Observable<ResponseModel<any[]>> {
        return this.mapRequest<any>(
            this._http.get(this.url + 'reports/' + reportId + '/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                    params
            }),
            false,
            false
        ).pipe(take(1))
    }

    public validateReport(historyId:number, history:any): Observable<ResponseModel<any>> {
      return this.mapRequest(
        this._http.patch(this.url + 'reports/' + historyId + '/confirm/',
        history,
        FapAPIRequestOptions.onlyTokenRequestOptions
      ),
      false
      ).pipe(take(1));
    }

    public closeReport(reportId:number, data:any): Observable<ResponseModel<any>> {
      return this.mapRequest(
        this._http.patch(this.url + 'reports/' + reportId + '/close/',
        data,
        FapAPIRequestOptions.onlyTokenRequestOptions
      ),
      false
      ).pipe(take(1));
    }

    public createReport(report): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.post(this.url + 'reports/',
            report,
            FapAPIRequestOptions.onlyTokenRequestOptions
        ),
        false
        ).pipe(take(1));
    }

    public updateReport(reportId: number, report: any): Observable<ResponseModel<any>> {
      return this.mapRequest(
          this._http.patch(this.url + 'reports/' + reportId + '/',
          report,
          FapAPIRequestOptions.onlyTokenRequestOptions
      ),
      false
      ).pipe(take(1));
  }

    public deleteReport(reportId: number): Observable<{}> {
      return this.mapRequest(
          this._http.delete(this.url + 'reports/' + reportId + '/',
              FapAPIRequestOptions.withTokenRequestOptions));
  }
    
}