import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavService } from '../../../../services/nav.service';

@Component({
  selector: 'fap-header-actions',
  templateUrl: './fap-header-actions.component.html',
  styleUrls: ['./fap-header-actions.component.scss']
})
export class FapHeaderActionsComponent {

  @Input() title: string = '';
  @Input() isSubmitEnabled: boolean = true;
  @Input() isDeleteEnabled: boolean = true;

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(public navServices: NavService) {}
}
