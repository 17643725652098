<fap-header>
    <fap-header-actions [title]="'people.person' | translate" [isDeleteEnabled]="personId ? true : false"></fap-header-actions>
</fap-header>
<div class="container-fluid p-0">
     <fap-add-edit-person [person]="person"
                               [personId]='personId'
                               [groups]="globalRegistry.systemData.peopleGroups"
                               [farms]="globalRegistry.systemData.farms"
                               (deleteParty)="deleteParty($event)"
                               (deleteUser)="deleteUser($event)"
                               (deletePerson)="deletePerson($event)"
                               (deleteGroup)="deleteGroup($event)"
                               (addUpdatePersonUser)="addUpdatePersonUser($event)">
     </fap-add-edit-person>
 </div>
