import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { FarmInterface } from '../../data/farm.interface';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { take } from 'rxjs/operators';
import { UtilsHelper } from '../../../../../core/heplers/utils.helper';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../../shared/services/nav.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';

@Component({
    templateUrl: './farm-edit-container.component.html'
})
export class FarmEditContainerComponent implements OnInit, OnDestroy {
    public farmId: number;
    public farmParty: number;
    public farmName = '';
    public farmPolygon: MapPolygonInterface = null;
    public initialFarmPolygon: MapPolygonInterface = null;

    public isEditMode = false;

    private subscriptions: Array<Subscription> = [];

    @ViewChild('deleteFarmConfirmationModal')
    public deleteFarmConfirmationModal: FapModalComponent;

    public deleteFarmModalPrimaryButton: FapModalButtonInterface;
    public deleteFarmModalSecondaryButton: FapModalButtonInterface;

    constructor(public mapService: MapService,
                public navService: NavService,
                public farmService: FarmService,
                public toastr: ToastrService,
                public activatedRoute: ActivatedRoute,
                public router: Router,
                public translateService: TranslateService,
                public globalRegistry: GlobalRegistryService) {
        this.activatedRoute.params.subscribe((params: Params): void => {
            // if we have the farmId parameter, we are in edit mode
            if (params['farmId'] !== undefined) {
                this.navService.createMod.next(false);
                this.navService.editMod.next(true);
                this.isEditMode = true;
                this.getFarmData(parseInt(params['farmId'], 10));
            } else {
                this.navService.createMod.next(true);
                this.isEditMode = false;
                this.farmPolygon = null;
                this.farmName = '';
                this.mapService.centerMapOnUserLocation();
            }
        });
    }

    public ngOnInit(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        // this.mapService.resetMap();
        this.mapService.isEditMode = true;
        this.initMenuOptions();
        this.mapService.onLastPolyPathChange.subscribe((polygon: MapPolygonInterface): void => {
            this.farmPolygon = polygon;
            this.farmPolygon.strokeColor = '#a31f1f';
            this.farmPolygon.fillColor = '#de3333';
            this.farmPolygon.isEditable = true;
        });

        this.subscriptions.push(this.mapService.clickedRevertBtn.subscribe((): void => {
            if (this.isEditMode) {
                this.mapService.resetMap();
                this.farmPolygon = UtilsHelper.getDeepCopy(this.initialFarmPolygon);
                this.mapService.mapPolygons.push(this.farmPolygon);
            } else {
                this.mapService.resetMap();
                this.farmPolygon = null;
            }
            this.mapService.onPalygonDrawed.next();
        }));

    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    public checkSubmitEnable(): boolean {
        if (!this.farmPolygon || (this.farmPolygon?.points.length < 3)) {
            return false;
        } else {
            return true;
        }
    }

    public submitFarm(farmData: { name: string, party: number }): void {
        this.farmName = farmData.name;
        this.farmParty = farmData.party;
        const farmForm: FarmInterface = {
            name: this.farmName,
            coords: {
                coordinates: [MapHelper.convertToAPIPolygon(this.farmPolygon.points)],
                type: 'Polygon'
            },
            party: this.farmParty
        };
        if (this.isEditMode) {
            this.farmService.updateFarm(this.farmId, farmForm).pipe(take(1)).subscribe((): void => {
                this.toastr.success(this.translateService.instant('farm.messages.updateSuccess'));
                this.createdOrUpdatedFarm();
            }, (): void => {
                this.toastr.error(this.translateService.instant('farm.messages.failUpdate'));
            });
        } else {
            this.farmService.createFarm(farmForm).pipe(take(1)).subscribe((farm:ResponseModel<FarmModel>): void => {
                this.farmId = farm.model.id;
                this.globalRegistry.addLocalRelationsToService.next(this.farmId);
                this.toastr.success(this.translateService.instant('farm.messages.createSuccess'));
                this.createdOrUpdatedFarm();
            }, (): void => {
                this.toastr.error(this.translateService.instant('farm.messages.failCreate'));
            });
        }
    }

    public showDeleteFarmModal(farmId: number): void {
        const _this: FarmEditContainerComponent = this;
        this.deleteFarmModalPrimaryButton = {
            clickFunction(): void { _this.deleteFarmConfirmationModal.hideModal(); },
            text: this.translateService.instant('cancel')
        };
        this.deleteFarmModalSecondaryButton = {
            clickFunction(): void {
                _this.deleteFarm(farmId);
                _this.deleteFarmConfirmationModal.hideModal();
            },
            text: this.translateService.instant('delete')
        };
        this.deleteFarmConfirmationModal.showModal();
    }

    private deleteFarm(farm: number): void {
        console.log(farm)
        this.farmService.deleteFarm(farm).pipe(take(1)).subscribe((): void => {
            this.toastr.success(this.translateService.instant('farm.farmDeletedSuccessfully'));
            this.globalRegistry.reloadFarms();
            setTimeout(() => {
                this.router.navigate(['/pages/lots']);
            }, 1000);
        });
    }

    // clean up map, update global registry and navigate to home
    private createdOrUpdatedFarm(): void {
        this.mapService.resetMap();
        this.mapService.mapMenuOptions = [];
        const reloadFarmCallback: () => void = (): void => {
            this.router.navigate(['/pages/lots']);
            this.globalRegistry.newFarmOrLotAdded.next(true);
        };
        this.globalRegistry.reloadFarms(reloadFarmCallback);
    }

    private initMenuOptions(): void {
        this.mapService.mapHasContextMenu = true;
        const mapServiceContext: MapService = this.mapService;
        const farmContainerContext: FarmEditContainerComponent = this;
        this.mapService.mapHasContextMenu = true;
        this.mapService.mapMenuOptions = [
            {
                header: this.translateService.instant('map.actions.polygonHeader')
            },
            {
                text: this.translateService.instant('map.actions.drawPolygon'),
                clickFunction(): void {
                    // if (mapServiceContext.mapPolygons.length === 0) {
                        mapServiceContext.mapPolygons = [];
                        mapServiceContext.drawNewPolygon();
                    // }
                }
            },
            {
                text: this.translateService.instant('map.actions.clearPolygons'),
                clickFunction(): void {
                    mapServiceContext.resetMap();
                    farmContainerContext.farmPolygon = null;
                }
            },
            {
                text: this.translateService.instant('revertChanges'),
                clickFunction(): void {
                    mapServiceContext.clickedRevertBtn.emit();
                }
            }
        ];
    }

    private getFarmData(farmId: number): void {
        this.farmService.getFarm(farmId).pipe(take(1)).subscribe((farm: ResponseModel<FarmModel>): void => {
            this.farmId = farmId;
            this.farmName = farm.model.name;
            this.farmParty = farm.model.party;
            this.farmPolygon = {
                points: MapHelper.convertToAGMPolygon(farm.model.coords.coordinates[0]),
                strokeColor: '#a31f1f',
                fillColor: '#de3333'
            };
            this.mapService.mapPolygons = [this.farmPolygon];
            this.initialFarmPolygon = UtilsHelper.getDeepCopy(this.farmPolygon);
            this.mapService.centerMapOnPolygons();
            this.mapService.onPalygonDrawed.next();
            // get polygon length
            this.mapService.lastPoligonTouchedSelectedLenght.next(this.mapService.getLengthOfPolygon(this.initialFarmPolygon));
        });
    }

}
