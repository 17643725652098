import { LotModel } from './../../../../../core/models/lot/lot.model';
import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UserModel } from '../../../../../core/models/user/user.model';
import { WidgetInterface } from '../../../../../core/interfaces/widget/widget.interface';
import { WidgetModel } from '../../../../../core/models/widget/widget.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';

@Component({
    selector: 'fap-home-layout',
    templateUrl: './fap-home-layout.component.html',
    styleUrls: ['./fap-home-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeLayoutComponent {

    @Input()
    public userData: UserModel;

    @Input()
    public widgets: Array<WidgetModel> = [];

    @Input()
    public sensors: Array<SensorModel> = [];

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Output()
    public addWidget: EventEmitter<WidgetInterface> = new EventEmitter();

    @Output()
    public deleteWidget: EventEmitter<number> = new EventEmitter();
}
