<div class="image_list">
    <div class="row maxov">
        <div class="col col-12 d-flex">
            <div class="d-flex w-100" style="overflow-y: hidden;">
                <div class="img_container">
                    <div class="images" style="display: flex;">
                        <div class="nimg" *ngFor="let imageId of imageIds">
                        <ng-container *ngFor="let image of images">
                            <ng-container *ngIf="image?.id == imageId">
                                <ng-container *ngIf="height">
                                    <fap-image [src]="mediaUrl+image?.file" (deleteImage)="deleteImage.emit(image?.id)" [label]="label" [containerHeight]="rowHeight"></fap-image>
                                </ng-container>
                                <ng-container *ngIf="!height">
                                    <fap-image [src]="mediaUrl+image?.file" (deleteImage)="deleteImage.emit(image?.id)" [label]="label" [containerHeight]="185"></fap-image>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        </div>
                    </div>
                </div>
                <div class="bx" *ngIf="type === 'single' && images.length < 1 && !images[0]?.file" [ngStyle]="{'height': rowHeight + 'px'}">
                    <div class="act_btn">
                        <fap-round-button [backgroundColor]="'tertiary'"></fap-round-button>
                    </div>
                    <div class="input-group align-items-baseline w-100 img_bx">
                        <fap-image-combo [group]="group" [hideInput]="true" (addImage)="addImage.emit($event)" (addNewImage)="addNewImage($event)"></fap-image-combo>
                    </div>
                </div>
                <div class="bx" *ngIf="type !== 'single'" [ngStyle]="{'height': rowHeight}">
                    <div class="act_btn">
                        <fap-round-button [backgroundColor]="'tertiary'"></fap-round-button>
                    </div>
                    <div class="input-group align-items-baseline w-100 img_bx">
                        <fap-image-combo [group]="group" [hideInput]="true" (addImage)="addImage.emit($event)" (addNewImage)="addNewImage($event)"></fap-image-combo>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>