<div id="parent">
<span *ngIf="isLoading" class="loader">
    <i></i>
  </span>
  <ng-container *ngFor="let post of posts">
    <div class="cards-grid">
      <posts-card
      [post]="post"
      [crops]="crops"
      [images]="images"
      [genericTypes]="genericTypes"
      [expanded]="false"
      [persons]="persons"
      (deletePost)="deleteThisPost($event)"
      ></posts-card>
    </div>
  </ng-container>
  
  <div class="custom_modal" id="custom_modal">
    <div class="modal_header">
        <h2>{{"posts.newPost" | translate}}</h2>
        <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
    </div>
        <ul>
            <li
                *ngFor="let item of newPosts; let i = index"
                class="tmb"
                [ngClass]="i === counter ? 'active' : 'inactive'"
                (click)="markAsRead(item)"
            >
            <posts-card
              [post]="item"
              [persons]="persons"
              [crops]="crops"
              [images]="images"
              [genericTypes]="genericTypes"
              [expanded]="true"
              (deletePost)="deleteThisPost($event)">
            </posts-card>
            </li>
        </ul>
      <div class="nav_btns">
        <button (click)="showPreviousImage()" class="cbtn prev"><< {{"posts.previous" | translate}}</button>
      <button (click)="showNextImage()" class="cbtn next">{{"posts.next" | translate}} >></button>
    </div>
  </div>
</div>