import {
    Component,
} from '@angular/core';

@Component({
    templateUrl: './weeds-edit-container.component.html',
    styleUrls: ['./weeds-edit-container.component.scss']
})
export class WeedsEditContainerComponent {
}
