<div class="container-fluid">
    <div class="row weed">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5> {{ 'farm.crop.disease.weedInfo' | translate }}
                        <i class="material-icons f-right">save</i>
                    </h5>
                </div>
                <div class="card-body">
                    <fap-edit
                        [context]="'weed'"
                        [showSubmit]="false">
                    </fap-edit>
                </div>
            </div>
        </div>
    </div>
    <fap-edit-treatments></fap-edit-treatments>
    <fap-edit-attributes></fap-edit-attributes>
</div>