import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { NavService } from '../../../../../shared/services/nav.service';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'MMM DD YYYY',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

@Component({
  selector: 'simulations-add-edit-layout',
  templateUrl: './simulations-add-edit-layout.component.html',
  styleUrls: ['./simulations-add-edit-layout.component.scss'],
  providers: [{provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT}, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SimulationsAddEditLayoutComponent),
    multi: true
  }]
})
export class SimulationsAddEditLayoutComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() public simulationId:number = null;
  public summary = [];

  public simulation = {
    name: "Corn on Pensacola",
    lot: 110,
    exchange: 1,
    start: "2022-07-11T22:33:00",
    end: "2022-07-12T22:33:00",
    duration: "1",
    columns: [
      {
        id: "cost_0",
        name_t: null
      },
      {
        id: "produced_1",
        name_t: null
      },
      {
        id: "co2_2",
        name_t: null
      },
      {
        id: "fuel_3",
        name_t: null
      },
      {
        id: "qty_4",
        name_t: null
      }
    ],
    summary: [
      {
        name: "cost_0",
        min: 10,
        max: 10.5,
        unit: 11
      },
      {
        name: "produced_1",
        min: 10,
        max: 10.5,
        unit: 11
      },
      {
        name: "co2_2",
        min: 10,
        max: 10.5,
        unit: 11
      }
    ],
    items: [
      {
        name_t: 12,
        activity_type: 1,
        columns: [
          {
            id: "qty_4",
            min: null,
            max: null,
            unit: null
          },
          {
            id: "cost_0",
            min: 10,
            max: 10.5,
            unit: 11
          },
          {
            id: "co2_2",
            min: 10,
            max: 10.5,
            unit: 12
          }
        ],
        resources: [
          {
            type: "product",
            id: 1,
            columns: [
              {
                id: "qty_4",
                min: 10,
                max: 10.5,
                unit: 10
              },
              {
                id: "cost_0",
                min: 15,
                max: 16.5,
                unit: 11
              },
              {
                id: "co2_2",
                min: 10,
                max: 10.5,
                unit: 12
              }
            ],
            ingredients: []
          }
        ]
      }
    ]
};
  @Input() public farms: FarmModel[] = [];
  @Input() public lots: LotModel[] = [];

  @Output()
  public deleteSimulation: EventEmitter<any> = new EventEmitter();
  @Output()
  public submitSimulation: EventEmitter<any> = new EventEmitter();
  @Output()
  public updateSimulation: EventEmitter<any> = new EventEmitter();

  @ViewChild('addItemPopup')
  public addItemPopup: FapModalComponent;
  @ViewChild('addItemProductPopup')
  public addItemProductPopup: FapModalComponent;
  @ViewChild('addActivityTypeModal')
  public addActivityTypeModal: FapModalComponent;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;

  public addItemPopupButtonPrimaryInterface: FapModalButtonInterface ;
  public addItemPopupButtonSecondaryInterface: FapModalButtonInterface;
  public addItemProductPopupButtonPrimaryInterface: FapModalButtonInterface ;
  public addItemProductPopupButtonSecondaryInterface: FapModalButtonInterface;
  public addActivityTypeModalButtonPrimaryInterface: FapModalButtonInterface;
  public addActivityTypeModalButtonSecondaryInterface: FapModalButtonInterface;

  public simulationForm: UntypedFormGroup;
  public itemForm: UntypedFormGroup;
  public itemProductForm: UntypedFormGroup;
  public activityTypeForm: UntypedFormGroup;

  public subscriptions:Subscription[] = [];
  public minDate = new Date();
  public activityTypes: Array<ActivityTypeModel>;
  public translatedNames:any = [];
  public langString: string;
  public isEdit = false;
  public activityTypeId: any;
  public translation = null;
  public label: string;
  public closeCombo: boolean = true;

  dropdownList = [];
  selectedItems: any;
  dropdownSettings = {};
  value: any[] = [];
  public prefix = 'ACTIVITY_'
  public activityType = null;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

  formGroup: UntypedFormGroup;
  public formFields = {"fields": [
    {
      "id": "name",
      "type": "edit",
      "name": "Name",
      "name_t": 5,
      "class": "String",
      "style": {
        "class": "col-6"
      },
      "value": "Test name"
    },
    {
      "id": "lot",
      "type": "combo",
      "name": "Lot",
      "name_t": 12,
      "class": "Lot",
      "value": 4,
      "style": {
        "class": "col-6"
      },
      "options": [
        {
          "id": 1,
          "text": "Lot"
        },
        {
          "id": 2,
          "text": "Another lot"
        },
        {
          "id": 3,
          "text": "Different lot"
        },
        {
          "id": 4,
          "text": "New lot"
        }
      ]
    },
    {
      "id": "crop_type",
      "type": "multi_select",
      "name": "Crop type",
      "name_t": 13,
      "class": "Resource",
      "value": [1, 2],
      "style": {
        "class": "col-12"
      },
      "options": [
        {
          "id": 1,
          "text": "Corn"
        },
        {
          "id": 2,
          "text": "Wheat"
        },
        {
          "id": 3,
          "text": "Maize"
        }
      ]
    },
    {
      "id": "row_dist",
      "type": "number",
      "name": "Row distance",
      "name_t": 14,
      "class": "Float",
      "value": 1.5,
      "unit": 10,
      "style": {
        "class": "col-4"
      },
      "range": [
        1.0,
        2.0
      ]
    },
    {
      "id": "plant_dist",
      "type": "number",
      "name": "Plant distance",
      "name_t": 14,
      "class": "Float",
      "value": 20,
      "unit": 10,
      "style": {
        "class": "col-4"
      },
      "range": [
        1.0,
        2.0
      ]
    },
    {
      "id": "ph",
      "type": "number",
      "name": "ph",
      "name_t": 14,
      "class": "Integer",
      "value": 20,
      "unit": null,
      "style": {
        "class": "col-4"
      },
      "range": [
        1,
        7
      ]
    }
  ]}
  
  constructor(private formBuilder: UntypedFormBuilder, public activatedRoute: ActivatedRoute, public globalRegistry: GlobalRegistryService, public navService: NavService, public router: Router, public translateService: TranslateService, public typesService: TypesService, public activityService: ActivityService, public toastr: ToastrService, public cacheService: CacheResolverService, public confirmModalService: ConfirmModalService) {
    this.initSimulationForm();
    this.initItemForm();
    this.initItemProductForm();
    this.initAddItemPopupButtons();
    this.initAddItemProductPopupButtons();
    this.initAddActivityTypeModalButtons();
    this.initActivityTypeForm();
   }

   initActivityTypeForm() {
    this.activityTypeForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      name_t: new UntypedFormControl(''),
      color: new UntypedFormControl('#ff0000'),
    });
   }

   writeValue(value: any[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChange: (value: any) => void = () => {console.log('value changed')};
  onTouched: () => void = () => {console.log('on touched')};

   get f() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});
    const fields = this.formFields.fields;
    fields.forEach(field => {
      const control = new UntypedFormControl(field.value, Validators.required);
      this.formGroup.addControl(field.id, control);
      if (field.type === 'multi_select') {
        const values = [];
        if (typeof field.value === 'object') {
          field.value.forEach(option => {
            field.options.forEach(element => {
              if (element.id === option) {
                values.push(element);
              }
            });
          });
        }
        this.formGroup.get(field.id).patchValue(values);
      }
    });
    console.log(this.formGroup.value);
    this.subscriptions.push(
      this.navService.emitSubmit.subscribe((value: boolean) => {
          if (value) {
              this.submitForm();
          }
      })
    );
    this.subscriptions.push(
      this.navService.emitDelete.subscribe((value: boolean) => {
          value && this.deleteCurrentSimulation();
      })
    );
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3000,
      allowSearchFilter: true
    };
    this.simulation.summary.map((element) => {
      const name = element.name.split('_')[0];
      let label = '';
      if(name === 'cost') {
        label = 'Total costs'
      } else if(name === 'produced') {
        label = 'Total income'
      } else if(name === 'fuel') {
        label = 'Fuel'
      } else if(name === 'co2') {
        label = 'CO2'
      } else {
        label = 'Salaries'
      }
      const obj = { laebl: label };
      Object.assign(element, obj);
  });
      this.summary = this.simulation.summary;

      this.typesService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
        this.activityTypes = types.model;
    });
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));    
    this.translatedNames = this.globalRegistry.systemData.translations;
  }

  activityTypeEdit(activityType: ActivityTypeModel) {
    this.activityTypeId = activityType.id;
    this.activityType = activityType;
    console.log(activityType)
    this.activityTypeForm.controls['name'].setValue(activityType.name);
    this.activityTypeForm.controls['name_t'].setValue(activityType.nameT);
    this.activityTypeForm.controls['color'].setValue(activityType.color);
    this.showActivityTypeModal();
}

  updateItems(lots, formControlName: string) {
    // this.formGroup.get('lots').setValue(lots);
    this.formGroup.get(formControlName).setValue(lots)
  }

  activityTypeDelete(activity) {
    this.deleteActivityType(activity.id);
  }

  public initAddActivityTypeModalButtons(): void {
    const _this: SimulationsAddEditLayoutComponent = this;

    this.addActivityTypeModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.activityTypeSubmit();
        },
        text: this.translateService.instant('save'),
    };

    this.addActivityTypeModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addActivityTypeModal.hideModal();
        },
        text: this.translateService.instant('cancel'),
    };
}

  public activityTypeSubmit() {
    if (this.activityTypeForm.invalid) {
        Object.keys(this.activityTypeForm.controls).forEach(
            (controlName: string) =>
                this.activityTypeForm.controls[controlName].markAsTouched()
        );
        return;
    }
    console.log(this.activityTypeForm.value);
    // return
    if (this.activityTypeId) {
        this.editActivityType(
            this.activityTypeForm.value,
            this.activityTypeId
        );
        this.addActivityTypeModal.hideModal();
    } else {
        this.addActivityType(this.activityTypeForm.value);
        this.addActivityTypeModal.hideModal();
    }
    this.activityType = null;
    // this.activityTypeForm.reset();
    this.initActivityTypeForm();
    this.closeCombo = true;
  }

  public deleteActivityType(activityTypeId: number): void {
    this.typesService
                    .deleteActivityType(activityTypeId)
                    .subscribe(() => {
                        this.activityService.getActivitiesList.next();
                        this.toastr.success(
                            this.translateService.instant(
                                'activities.deleteActivityTypeSuccessfully'
                            )
                        );
                        this.reloadActivityTypes();
                    });
  }

  public editActivityType(activity: FormData, activityTypeId: number): void {
    this.typesService
        .updateActivityType(activity, activityTypeId)
        .subscribe(() => {
            this.activityService.getActivitiesList.next();
            this.toastr.success(
                this.translateService.instant('activities.updateActivityType')
            );
            this.reloadActivityTypes()
        });
  }

  public initAddItemPopupButtons(): void {
    const _this: SimulationsAddEditLayoutComponent = this;

    this.addItemPopupButtonPrimaryInterface = {
        clickFunction(): void {
            _this.addItem();
        },
        text: this.translateService.instant('save'),
    };

    this.addItemPopupButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addItemPopup.hideModal();
        },
        text: this.translateService.instant('cancel'),
    };
}
  
public initAddItemProductPopupButtons(): void {
  const _this: SimulationsAddEditLayoutComponent = this;

  this.addItemProductPopupButtonPrimaryInterface = {
      clickFunction(): void {
          _this.addItemProduct();
      },
      text: this.translateService.instant('save'),
  };

  this.addItemProductPopupButtonSecondaryInterface = {
      clickFunction(): void {
          _this.addItemProductPopup.hideModal();
      },
      text: this.translateService.instant('cancel'),
  };
  }

  public initSimulationForm() {
    const formBody: {} = {
      name: [
          this.simulation ? this.simulation.name : '',
          Validators.compose([Validators.required]),
      ],
      lot: [
        this.simulation ? this.simulation.lot : null,
        Validators.compose([Validators.required]),
      ],
      start: [
        this.simulation ? this.simulation.start : new Date(),
        Validators.compose([Validators.required]),
      ],
      duration: [
      this.simulation ? this.simulation.duration : 0,
      Validators.compose([Validators.required]),
      ],
      exchange: [
        this.simulation ? this.simulation.exchange : null,
        Validators.compose([Validators.required]),
      ],
    }
    this.simulationForm = this.formBuilder.group(formBody);
  }

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  public initItemForm() {
    const formBody: {} = {
      name: ['', Validators.required],
      activityType: [null, Validators.required],
      start: [new Date(), Validators.required],
      duration: [0, Validators.required]
    }
    this.itemForm = this.formBuilder.group(formBody);
  }

  public initItemProductForm() {
    const formBody: {} = {
      name: ['', Validators.required],
    }
    this.itemProductForm = this.formBuilder.group(formBody);
  }

  public addActivityType(activity: FormData): void {
    this.typesService.addActivityType(activity).subscribe((data) => {
        this.toastr.success(
            this.translateService.instant('activities.createActivityType')
        );
        this.activityTypes.unshift(data.model);
        this.itemForm.get('activityType').setValue(data.model.id)
        this.closeCombo = true;
    });
}

public reloadActivityTypes() {
  const url = this.typesService.getUrl('activity/');
  this.cacheService.delete(url);
  this.typesService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
      this.activityTypes = types.model;
  })
}

  public ngOnChanges(): void {
    console.log(this.simulation)
    this.initSimulationForm();
  }

  public showAddItemPopup() {
    this.addItemPopup.showModal()
  }

  public showAddItemProductPopup() {
    this.addItemProductPopup.showModal()
  }

  public addItem() {
    console.log(this.itemForm.value);
  }

  public addItemProduct() {
    console.log(this.itemProductForm.value)
  }

  public submitForm() {
    console.log(this.simulationForm.value)
  }

  public deleteCurrentSimulation() {
    console.log(this.simulation);
    this.deleteSimulation.emit(this.simulationId);
    setTimeout(() => {
        this.router.navigate(['/pages/simulations']);
    }, 1000);
  }

convertKeysToCamelCase(obj) {
  const camelCaseObj = {};
  for (const key in obj) {
    const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
    camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
  }
  return camelCaseObj;
}

addEditTranslation(label) {
  this.label = label;
  const name = this.nameInput.nativeElement.value;
  const type: any = this.convertKeysToCamelCase(this.activityType);

  if (name === '' || type.nameT === null) {
      this.translation = null;
      this.addEditTranslationsModal.showModal();
      return;
  }

  for (const translation of this.globalRegistry.systemData.translations) {
      if (type && translation.id === type.nameT) {
          this.translation = translation;
          console.log(this.translation);
          this.addEditTranslationsModal.showModal();
          return;
      }
  }

  if (Object.keys(type).length === 0 && name) {
      const newTranslation = this.activityTypeForm.controls['name_t'].value;
      for (const translation of this.globalRegistry.systemData.translations) {
          if (translation.id === newTranslation) {
              this.translation = translation;
              console.log(this.translation);
              this.addEditTranslationsModal.showModal();
              return;
          }
      }
  }
}

  onAddUpdateTranslation(translation) {
      console.log(translation);
      this.translation = translation;
      this.activityTypeForm.controls['name_t'].setValue(translation.id);
      this.activityTypeForm.controls['name'].setValue(translation[this.langString]);
      this.addEditTranslationsModal.hideModal();
  }

public showActivityTypeModal(newCheck?: boolean): void {
  if(newCheck) {
      this.activityType = null
      this.activityTypeId = null;
      // this.activityTypeForm.reset();
      this.initActivityTypeForm();
  }
  this.addActivityTypeModal.showModal();
}

  updateSimulationOverview() {
    console.log(this.formGroup.value);
  }

}
