import { FapBaseModel } from '../../base/fap-base-model';

export class StockOverviewModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'stock',
        'name'
    ];

    public id: number;
    public stock: {resources: number, ingredients: number};
    public name: string;
}
