import { FapBaseModel } from '../../base/fap-base-model';

export class TokenModel extends FapBaseModel {
    public static fields: Array<string> = [
        'token'
    ];

    public static requiredFields: string[] = [
        'token'
    ];

    public token: string;

    constructor() {
        super();
    }

}
