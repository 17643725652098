<div class="container-fluid p-0">
     <fap-stock-overview
          [selectedFarm]="selectedFarm$ | async"
          [stockItems]="stockItems$ | async"
          [resourceTypes]="globalRegistry.systemData.resourceTypes"
          [transactions]="transactions"
          [totalNoOfTransactions]="totalNoOfTransactions"
          [bills]="bills"
          [totalNoOfBills]="totalNoOfBills"
          [parties]="globalRegistry.systemData.parties"
          (addStockIncome)="addStockIncome($event)"
          (addStockOutcome)="addStockOutcome($event)"
          (filterTransactions)="getTransactions($event)"
          (deleteParty)="deleteParty($event)">
     </fap-stock-overview>
</div>
