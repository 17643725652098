import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { PeopleGroupModel } from '../../../../core/models/groups/people-group.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { PeopleGroupService } from '../../../../core/services/api/people-group/people-group.service';
import { CompanyService } from 'src/app/core/services/api/company/company.service';

@Component({
    selector: 'fap-add-edit-group',
    templateUrl: './fap_add-edit-group.component.html',
    styleUrls: ['./fap_add-edit-group.component.scss']
})
export class FapAddEditGroupComponent implements OnChanges {

    @Input()
    public group: PeopleGroupModel;

    @Output()
    public createdGroup: EventEmitter<PeopleGroupModel> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    public groupForm: UntypedFormGroup;
    selectedItems = [];
    dropdownSettings = {};
    public persons = [];

    constructor(public formBuilder: UntypedFormBuilder,
                public peopleGroupService: PeopleGroupService,
                public globalRegistry: GlobalRegistryService,
                public toastr: ToastrService,
                public translateService: TranslateService,
                public companyService: CompanyService
                ) {
        this.groupForm = formBuilder.group({name: ['', Validators.required]});
        // companyService.getPersons({limit: 1000}).subscribe(data => {
        //     console.log(data.body.results);
        //     this.persons = data.body.results;
        // })
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'display',
            selectAllText: 'Select All',
            unSelectAllText: 'Deselect All',
            itemsShowLimit: 3000,
            allowSearchFilter: true
          };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'group')) {
            this.resetForm();
        }
    }

    get f() {
        return this.groupForm.controls;
      }

    public resetForm(): void {
        this.groupForm = this.formBuilder.group({ name:  [this.group ? this.group.name : '', Validators.required]});
    }

    public submit(): void {
        // let users = this.groupForm.value.users;
        if (this.group) {
            this.peopleGroupService.updateGroup({
                id: this.group.id,
                name: this.groupForm.value.name,
                // users: users
            })
                .subscribe((response: ResponseModel<PeopleGroupModel>): void => {
                    this.globalRegistry.reloadPeopleGroups();
                    this.createdGroup.emit(response.model);
                    this.toastr.success(this.translateService.instant('people.groups.updateSucces'));
                }, (): void => {
                    this.toastr.success(this.translateService.instant('people.groups.updateFailed'));
                });
        } else {
            this.peopleGroupService.addGroup({ name: this.groupForm.value.name })
            .subscribe((response: ResponseModel<PeopleGroupModel>): void => {
                this.globalRegistry.reloadPeopleGroups();
                this.createdGroup.emit(response.model);
                this.toastr.success(this.translateService.instant('people.groups.aditionSucces'));
            }, (): void => {
                this.toastr.success(this.translateService.instant('people.groups.aditionFailed'));
            });
        }
        this.resetForm();
    }
}
