import { ActivityModel } from '../models/activity/activity.model';

export class ActivityHelper {

    public static getUngroupedActivities(activities: Array<ActivityModel>): Array<ActivityModel> {
        return activities.filter((activity: ActivityModel) => {
            return undefined;
        });
    }

    public static getGroupedActivities(activities: Array<ActivityModel>, groupId: number): Array<ActivityModel> {
        return activities.filter((activity: ActivityModel) => {
            return undefined;
        });
    }

}
