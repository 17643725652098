<fap-header>
   <div class="d-flex filters align-items-center">
      <div class="tree_combo mr-3">
         <fap-tree-combo></fap-tree-combo>
       </div>
       <div class="add_btn mr-3">
         <fap-round-button [backgroundColor]="'tertiary'" [routerLink]="'/pages/people/add'"></fap-round-button>
       </div>
       <fap-search-filter
       [isSearchEnabled]="true"
>     </fap-search-filter>
     </div>
</fap-header>
<div class="container-fluid ter-container nc"
infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
  style="height: 100vh; flex: auto;">
     <fap-people-layout 
        key="people"
        [enableAddEdit]="enableAddEdit"
        [enableDelete]="enableDelete"
        [noFarmPersons]='noFarmPersons'
        [persons]="filteredPersons"
        [farms]='filteredFarms'
        [farmForecastMapping]="farmForecastMapping"
        (removePerson)="removePerson($event)">
     </fap-people-layout>
 </div>
