import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { CompanyInterface } from './data/company.interface';
import { CompanyModel } from '../../../models/company/company.model';
import { ResponseModel } from '../../../models/response.model';
import { PersonModel } from '../../../models/person/person.model';
import { PersonUserInterface } from './data/person-user.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ImageModel } from '../../../models/company/image.model';
import { AddressInterface } from './data/address.interface';
import { AddressModel } from '../../../models/company/address.model';
import * as CryptoJS from 'crypto-js'
import { PeopleGroupModel } from 'src/app/core/models/groups/people-group.model';
import { CacheResolverService } from '../cache/cache-resolver.service';
import { PlanInterface } from './data/plan.interface';
import { PlanModel } from '../../../models/company/plan.model';

@Injectable()
export class CompanyService extends FapRestBaseService {
    public countries = new BehaviorSubject([]);
    constructor(tosterService: ToastrService, public cacheService: CacheResolverService,
                private _http: HttpClient) {
        super(tosterService, _http, '/company/');
    }

    public setCountries(data) {
        this.countries.next(data);
    }

    getUrl(slug) {
        return this.url+slug
    }

    public createCompany(body: CompanyInterface): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'company/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public getCompany(): Observable<ResponseModel<CompanyModel>> {
        return this.mapRequest(
            this._http.get(this.url + 'company/', FapAPIRequestOptions.withTokenRequestOptions),
            CompanyModel,
            false
        );
    }

    public getPeopleGroups(): Observable<ResponseModel<PeopleGroupModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'people_group/', FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false,
        );
    }

    public updateCompany(body: CompanyInterface): Observable<any> {
        return this.mapRequest(
            this._http.put(this.url + 'company/' + body.id + '/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public getPersons(params?:{}): Observable<ResponseModel<PersonModel[]>> {
        return this.mapRequest<PersonModel[]>(
            this._http.get(this.url + 'persons/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                })
                .pipe(map((x) => {
                    return this.decryptResponse(x['raw'])
                })), false).pipe(take(1))
    }

    decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);

          const data = JSON.parse(decrypted)

          const toCamel = (s) => {
            return s.replace(/([-_][a-z])/ig, ($1) => {
              return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
            });
          };
          
          const isArray = function (a) {
            return Array.isArray(a);
          };
          
          const isObject = function (o) {
            return o === Object(o) && !isArray(o) && typeof o !== 'function';
          };
          
          const keysToCamel = function (o) {
            if (isObject(o)) {
              const n = {};
          
              Object.keys(o)
                .forEach((k) => {
                  n[toCamel(k)] = keysToCamel(o[k]);
                });
          
              return n;
            } else if (isArray(o)) {
              return o.map((i) => {
                return keysToCamel(i);
              });
            }
          
            return o;
          };
          
          return keysToCamel(data);
        }
          
    public getPerson(personId: number): Observable<ResponseModel<PersonModel>> {
        return this.mapRequest(
            this._http.get(this.url + 'persons/' + personId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
                PersonModel,
        );
    }

    public getAccounts(): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'bank_account/',
                FapAPIRequestOptions.withTokenRequestOptions),
                false
        );
    }
    
    public getAccount(accountId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'bank_account/' + accountId + '/',
            FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public createBankAccount(account): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.post(this.url + 'bank_account/',
                this.camelCaseModelToSnakeCaseJson(account),
                FapAPIRequestOptions.withTokenRequestOptions),
                false
        );
    }

    public updateBankAccount(account): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + 'bank_account/' + account.id + '/')
        return this.mapRequest(
            this._http.put(this.url + 'bank_account/' + account.id + '/',
                this.camelCaseModelToSnakeCaseJson(account),
                FapAPIRequestOptions.withTokenRequestOptions),
        );
    }

    public deleteBankAccount(bankId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'bank_account/' + bankId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        );
    }
        
    public getBanks(): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'bank/',
            FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public getBank(bankId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'bank/' + bankId + '/',
            FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public createBank(bank): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.post(this.url + 'bank/',
                this.camelCaseModelToSnakeCaseJson(bank),
                FapAPIRequestOptions.withTokenRequestOptions),
                false
        );
    }

    public updateBank(bank): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + 'bank/' + bank.id + '/')
        return this.mapRequest(
            this._http.put(this.url + 'bank/' + bank.id + '/',
                this.camelCaseModelToSnakeCaseJson(bank),
                FapAPIRequestOptions.withTokenRequestOptions),
        );
    }

    public deleteBank(bankId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'bank/' + bankId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        );
    }

    public deleteUser(userId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'users/' + userId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public deletePerson(personId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'persons/' + personId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public addPerson(data: FormData): Observable<ResponseModel<PersonModel>> {
        return this.mapRequest<PersonModel>(
            this._http.post(this.url + 'persons/', data,
                FapAPIRequestOptions.onlyTokenRequestOptions
            ),
            PersonModel
        ).pipe(take(1));
    }

    public updatePerson(data: FormData, personId: number): Observable<ResponseModel<PersonModel>> {
        return this.mapRequest<PersonModel>(
            this._http.patch(this.url + 'persons/' + personId + '/',
                data,
                FapAPIRequestOptions.onlyTokenRequestOptions
            ),
            PersonModel
        ).pipe(take(1));
    }

    public addUser(user: PersonUserInterface): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'users/',
                this.camelCaseModelToSnakeCaseJson(user),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public updateUser(user: PersonUserInterface): Observable<any> {
        return this.mapRequest(
            this._http.patch(this.url + 'users/' + user.id + '/',
                this.camelCaseModelToSnakeCaseJson(user),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public getCompanyImages(params?:any): Observable<ResponseModel<ImageModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'images/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            false
        );
    }

    public getCompanyImage(imageId:number): Observable<ResponseModel<ImageModel>> {
        return this.mapRequest(
            this._http.get(this.url + 'images/' + imageId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
                ImageModel,
            false
        );
    }

    // public patchCompanyImage(imageId: number, data: any): Observable<any> {
    //     return this.mapRequest(
    //         this._http.patch(this.url + 'images/' + imageId + '/',
    //             this.camelCaseModelToSnakeCaseJson(data),
    //             FapAPIRequestOptions.withTokenHeaders
    //         )
    //     ).pipe(take(1));
    // }

    public postCompanyImages(image: any): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'images/',
            image,
            FapAPIRequestOptions.onlyTokenRequestOptions
        ),
        false
        ).pipe(take(1));
    }

    public deleteCompanyImage(imageId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'images/' + imageId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public createAddress(body: AddressInterface): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'address/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public getAddresses(): Observable<ResponseModel<AddressModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'address/', FapAPIRequestOptions.withTokenRequestOptions),
            AddressModel,
            true
        );
    }

    public getAddress(params?:any): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'address/', {
                headers: FapAPIRequestOptions.withTokenRequestOptions,
                params
            }),
            false
        );
    }

    public getSingleAddress(addressId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'address/' + addressId + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            }),
            false
        );
    }

    public updateAddress(body: AddressInterface): Observable<any> {
        return this.mapRequest(
            this._http.put(this.url + 'address/' + body.id + '/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public deleteAddress(addressId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'address/' + addressId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public getCountries(): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.get(this.url + 'country_data/', FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public getPlans(params?: {}): Observable<ResponseModel<PlanModel[]>> {
        return this.mapRequest<PlanModel[]>(
            this._http.get(this.url + 'plan/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            PlanModel,
            true
        ).pipe(take(1));
    }
    
    public createPlan(plan: PlanInterface): Observable<ResponseModel<PlanModel>> {
        return this.mapRequest<PlanModel>(
            this._http.post(this.url + 'plan/',
                this.camelCaseModelToSnakeCaseJson(plan),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            PlanModel
        ).pipe(take(1));
    }

    public updatePlan(planId:number, plan: PlanInterface): Observable<ResponseModel<PlanModel>> {
        return this.mapRequest<PlanModel>(
            this._http.patch(this.url + 'plan/' + planId + '/',
                this.camelCaseModelToSnakeCaseJson(plan),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            PlanModel
        ).pipe(take(1));
    }

    public deleteplan(planId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + 'plan/' + planId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }
}
