import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { environment } from '../../../../../../environments/environment';
import { FapModalComponent } from '../../../../../shared/partials';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'workflows-layout',
  templateUrl: './workflows-layout.component.html',
  styleUrls: ['./workflows-layout.component.scss']
})
export class WorkflowsLayoutComponent implements OnInit {
  @Input() workflows:any[] = [];
  public subscriptions: Array<Subscription> = [];
  public workflow: any;
  public workflowId: number;
  public formTypeId: number;
  public entityInfo: any;
  public objectId: number;
  public currentStep = 0;
  public totalSteps = 0
  public nextFormClick = false;
  public previousFormClick = false;
  public workflowList = [];
  public selectedType: any;
  public personIds = [];
  public defaultImg;
  public mediaUrl = environment.mediaUrl;
  public html: any = null;
  public langString:string = null;
  public workflowInitiated: boolean = false;
  @Output() public refreshList: EventEmitter<any> = new EventEmitter();
  public errorFields:any[] = [];
  public defaultSize = 'pc';
  public isLoading: boolean = false;

  @ViewChild('workflowModal') workflowModal: FapModalComponent;

  constructor(private typesService: TypesService, private attributeService: AttributeService, private toastrService: ToastrService, public translate: TranslateService, private activatedRoute: ActivatedRoute, private router: Router, public cacheService: CacheResolverService, private companyService: CompanyService, private globalRegistry: GlobalRegistryService, private navService: NavService) {
    this.getWorkflowList();
  }

  ngOnInit(): void {
    this.defaultImg = 'assets/images/people_placeholder.svg';
      this.getWorkflowTypeId();
      this.langString = localStorage.getItem('language');
      this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
          if(lang) {
              this.langString = lang;
          }
    }));
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(data => {
        console.log(data);
        if(data && data['completed']) {
          this.workflowInitiated = true;
        } else {
          this.workflowInitiated = false;
        }
      })
    )
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateDefaultSize();
  }

  private updateDefaultSize() {
    const width = window.innerWidth;
    this.defaultSize = width >= 1025 ? 'pc' : (width >= 768 ? 'tablet' : 'mobile');
  }

  getModalTitle(): string {
    let title = '';
    if(!this.html) {
      return this.entityInfo?.name_t?.[this.langString] ? this.entityInfo?.name_t?.[this.langString] : this.entityInfo?.name
    } else {
      title = this.translate.instant("workflow.results");
    }
    return title
  }

  executeWorkflow(item) {
    console.log(item);
    this.newWorkflow(item.id);
    this.editWorkflowType(item.id);
  }

  editWorkflowType(id) {
    this.selectedType = this.workflows.find(item => item.id === id);
    console.log(this.selectedType);
    this.filterWorkflowList(id);
  }

  public getWorkflowList() {
    let url = this.attributeService.getUrl('workflow/');
    this.cacheService.delete(url);
    this.attributeService.getWorkflow().subscribe(data => {
      this.workflowList = data.body.results;
      this.getPersons();
      console.log(this.workflowList);
    })
  }

  public getWorkflowTypeId() {
    this.typesService.getHeaderWorkflowTypeId.subscribe(data => {
      console.log(data);
      if(data === 0) {
        return
      } else {
        this.newWorkflow(data);
      }
    })
}

toggleTableView(id) {
  this.workflowInitiated = true;
  this.filterWorkflowList(id);
}

  public filterWorkflowList(id) {
    this.selectedType = this.workflows.find(item => item.id === id);
    let url = this.attributeService.getUrl('workflow/type='+id);
    this.cacheService.delete(url);
    this.attributeService.getWorkflow({type:id}).subscribe(data => {
      this.workflowList = data.body.results;
      this.getPersons();
      console.log(this.workflowList);
    })
    // this.workflowList = this.workflowList.filter(workflow => workflow.type === id);
    console.log(this.workflowList);
  }

  public getFormTypes() {
    let url = this.typesService.getUrl('form/' + this.formTypeId + '/');
    this.typesService.getFormTypeById(this.formTypeId).subscribe((data) => {
      console.log(url);
      this.cacheService.delete(url);
      console.log(data);
        this.entityInfo = data.body.results;
    });
}

public newWorkflow(id) {
  this.isLoading = true;
  const param = {
    type : id
  }
  this.attributeService.postWorkflow(param).subscribe(data => {
    this.workflow = data.body.results;
    this.workflowId = data.body.results.id;
    this.objectId = this.workflow.object_id;
    this.workflowModal.showModal();

    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    this.totalSteps = this.workflow.steps_count;
    if(this.currentStep > this.totalSteps) {
      this.currentStep = 0;
    }
    if(data.body.results.object_id) {
      setTimeout(() => {
        this.isLoading = false;
      }, 300)
    }
  }, (error) => {
    this.toastrService.error(error.error.results.error);
    this.isLoading = false;
  })
}

nextButtonClick(value) {
  if(this.currentStep === this.workflow.steps_count) {
    return
  } else {
    if(value) {
      this.goToNextForm();
    }
  }
}

prevButtonClick() {
  this.goToPreviousForm();
}

deleteWorkflow(id) {
  this.attributeService.deleteWorkflow(id).subscribe(data => {
    this.refreshList.emit();
    console.log(data)
    if(this.selectedType) {
      this.filterWorkflowList(this.selectedType.id);
    } else {
      this.getWorkflowList();
    }
    this.toastrService.success('Workflow deleted successfully!');
  }, (error) => {
    this.toastrService.error(error.error.results.error);
  })
}

submitForm() {
  this.attributeService.submitWorkfow(this.workflowId).subscribe(data => {
    console.log(data);
    this.workflow = null;
    this.html = data.body.results;
    if(this.html && this.html.html) {
      const currentUrl = this.router.url;
      const updatedUrl = `${currentUrl}?completed=true`;
      this.filterWorkflowList(this.selectedType.id);
      this.router.navigateByUrl(updatedUrl);
    } else {
      this.hideModal();
    }
  }, (err) => {
    this.toastrService.error(err.error.results.error);
  });
}

clearNav() {
  this.router.navigate(['pages/workflows'])
  this.workflowInitiated = false
}

hideModal() {
  this.html = null;
  this.closeModal();
  console.log('hide modal triggered');
  if(this.selectedType) {
    this.filterWorkflowList(this.selectedType.id);
  } else {
    this.getWorkflowList();
  }
  // this.router.navigate(['pages/workflows']);
}

onUpdateAction() {
  console.log('update action triggered');
}

closeModal() {
  // const elem = document.getElementById('workflow_modal');
  // const parent = document.getElementById('parent99');
  // elem.classList.remove('active');
  // parent.classList.remove('backdrop');
  this.html = null;
  this.workflow = null;
  this.workflowModal.hideModal();
}

goToPreviousForm() {
  this.isLoading = true;
  this.attributeService.getPreviousForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    this.previousFormClick = false;
    if(data.body.results.object_id) {
      setTimeout(() => {
        this.isLoading = false;
      }, 300)
    }
  }, (error) => {
    if(error.error.results.error.form && error.error.results.error.form.error) {
    this.toastrService.error(error.error.results.error.form.error);
    this.errorFields = error.error.results.error.form.fields
    }
    if(error.error.results.error && !error.error.results.error.form) {
      this.toastrService.error(error.error.results.error);
    }
    this.isLoading = false;
  })
}

goToNextForm() {
  this.isLoading = true;
  this.attributeService.getNextForm(this.workflow.id).subscribe(data => {
    this.workflow = data.body.results;
    this.objectId = this.workflow.object_id;
    if(this.workflow.current_form) {
      this.formTypeId = this.workflow.current_form.type;
      this.getFormTypes();
    }
    this.currentStep = this.workflow.step;
    if(this.currentStep > this.totalSteps) {
      this.toastrService.success(this.translate.instant('workflow.workflowUpdatedSuccessfully'));
      this.currentStep = 0
    }
    this.nextFormClick = false;
    if(data.body.results.object_id) {
      setTimeout(() => {
        this.isLoading = false;
      }, 300)
    }
  }, (error) => {
    if(error.error.results.error.form && error.error.results.error.form.error) {
    this.toastrService.error(error.error.results.error.form.error);
    this.errorFields = error.error.results.error.form.fields
    }
    if(error.error.results.error && !error.error.results.error.form) {
      this.toastrService.error(error.error.results.error);
    }
    this.isLoading = false;
  })
}

  public getWorkflow(id: number) {
    this.workflowId = id;
    let url = this.attributeService.getUrl('workflow/' + id + '/');
    this.attributeService.getWorkflowById(id).subscribe(data => {
      this.cacheService.delete(url);
      this.workflow = data.body.results;
      this.selectedType = this.workflows.find(item => item.id === this.workflow.type);
      this.objectId = this.workflow.object_id;
    this.workflowModal.showModal();
      if(this.workflow.current_form) {
        this.formTypeId = this.workflow.current_form.type;
        this.getFormTypes();
      }
      this.currentStep = this.workflow.step;
      this.totalSteps = this.workflow.steps_count;
      if(this.currentStep > this.totalSteps) {
        this.currentStep = 0;
      }
    })
  }

  getPersons() {
    this.companyService.getPersons().subscribe(data => {
      this.globalRegistry.systemData.persons = data.body.results;
      if(this.workflowList) {
        this.personIds = [];
        this.workflowList.forEach(post => {
          
          const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === post.created_by);
          if(!personExist) {
            this.personIds.push(post.created_by);
          }
        });
        const unique = this.personIds.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
        setTimeout(()=> {
          unique.forEach(element => {
            if(element) {
              this.companyService.getPerson(element).subscribe(data => {
                if(data) {
                  this.globalRegistry.systemData.persons.push(data.body.results)
                }
                this.globalRegistry.systemData.persons = this.globalRegistry.systemData.persons.filter(function(elem, index, self) {
                  return index === self.indexOf(elem);
                });
                // console.log(this.globalRegistry.systemData.persons);
              })
            }
          });
        },1000)  
      }
    })
  }
}
