import { Pipe, PipeTransform } from "@angular/core";
import { AspectRaioEnum } from "../enums/aspect-ratio.enum";

@Pipe({
    name: 'imageAspectRatioPipe'
})

export class ImageAspectRatioClassPipe implements PipeTransform {
    transform(imageUrl: string, containerHeight: number): string {
        if(!imageUrl) {
            return
        }
        const img = new Image();
        const aspectRatio: number = AspectRaioEnum.size_4_3;
        img.src = imageUrl;
        if(!img.width || !img.height) {
            return
        }
        if(img.width > img.height) {
            return containerHeight * aspectRatio +'px';
        } else if(img.width === img.height || img.width < img.height) {
            return containerHeight * 1/aspectRatio + 'px';
        } else {
            return;
        }
    }
}