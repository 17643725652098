import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { ToastrService } from 'ngx-toastr';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { ResponseModel } from '../../../../core/models/response.model';
import { AttributeInterface } from '../../../../core/interfaces/attribute/attribute.interface';
import { take } from 'rxjs/operators';
import { AttributeModel } from '../../../../core/models/attribute/attribute.model';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';
import { AttributeEntityEnum } from '../../../../core/enums/attribute/attribute-entity.enum';
import { AttributeRelationModel } from '../../../../core/models/attribute/attribute-relation.model';
import { AttributeRelationInterface } from '../../../../core/interfaces/attribute/attribute-relation.interface';
import { AttributeRelationEnum } from '../../../../core/enums/attribute/attribute-relation.enum';
import { NavService } from '../../../../shared/services/nav.service';
import { EquipmentModel } from '../../../../core/models/equipment/equipment.model';
import { FieldModel } from '../../../../core/models/field/field.model';
import { LotModel } from '../../../../core/models/lot/lot.model';
import { EquipmentService } from '../../../../core/services/api/equipment/equipment.service';
import { FieldService } from '../../../../core/services/api/farm/field.service';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import * as uuid from "uuid";
import { NotesService } from '../../../../core/services/api/farm/notes.service';
import { NoteModel } from '../../../../core/models/notes/note.model';
import { TypesService } from '../../../../core/services/api/types/types.service';

@Component({
    selector: 'fap-add-edit-attribute',
    templateUrl: './fap_add-edit-attribute.component.html',
    styleUrls: ['./fap_add-edit-attribute.component.scss']
})
export class FapAddEditAttributeComponent implements OnChanges {
    @Input()
    public objectID: number;

    @Input()
    public attribute: AttributeModel;

    @Input()
    public relation: AttributeRelationModel;

    @Input()
    public relationType: AttributeEntityEnum;

    @Output()
    public addedAttribute: EventEmitter<AttributeModel> = new EventEmitter();

    @Output()
    public updatedAttribute: EventEmitter<AttributeModel> = new EventEmitter();

    @Output()
    public deletedAttribute: EventEmitter<AttributeModel> = new EventEmitter();

    @Output()
    public closeModal: EventEmitter<void> = new EventEmitter();

    public allEntities: {} = {};
    public attributeForm: UntypedFormGroup;
    public entityTypes: Array<string> = [];
    public defaultEntities: Array<string> = [];
    public selectedValueType: string = AttributeRelationEnum.String;
    public attributeRelations: Array<AttributeRelationModel> = [];
    public valueTemplate: {};



    constructor(public navService: NavService,
                public attributeService: AttributeService,
                public confirmModalService: ConfirmModalService,
                private formBuilder: UntypedFormBuilder,
                private toastr: ToastrService,
                private equipmentService: EquipmentService,
                private fieldService: FieldService,
                private translateService: TranslateService,
                private globalRegistry: GlobalRegistryService,
                public typesService:TypesService,
                private notesService:NotesService) {
        this.initForm();
        this.loadAttributeRelations();
        this.entityTypes = Object.values(AttributeEntityEnum);
        this.defaultEntities.push(this.entityTypes[0], this.entityTypes[1]);
        this.allEntities[AttributeEntityEnum.Person] = this.globalRegistry.systemData.persons;
        this.allEntities[AttributeEntityEnum.Farm] = this.globalRegistry.systemData.farms;
        this.allEntities[AttributeEntityEnum.Lot] = this.globalRegistry.systemData.lots;
        this.allEntities[AttributeEntityEnum.Party] = this.globalRegistry.systemData.parties;
        this.equipmentService.getEquipments().subscribe((response: ResponseModel<EquipmentModel[]>): void => {
            this.allEntities[AttributeEntityEnum.Equipment] = response.model;
        });
        this.notesService.getNotes().subscribe((response: ResponseModel<NoteModel[]>): void => {
            this.allEntities[AttributeEntityEnum.Note] = response.model;
        });
        this.fieldService.getFields().subscribe((response: ResponseModel<FieldModel[]>): void => {
            this.allEntities[AttributeEntityEnum.Field] = response.model;
        });
        this.allEntities[AttributeEntityEnum.Crop] = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
            return lot.currentCrop !== null && lot.currentCrop !== undefined; })
            .map((lot: LotModel): {name: string, id: number} => ({
                name: lot.name,
                id: lot.currentCrop.id
            }));
        this.setValueTemplate();
    }

    public ngOnChanges(changes?: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'attribute')) {
            this.initForm();
        }
    }

    public resetForm(): void {
        // this.initForm();
    }

    public initForm(): void {
        if (this.relation) {
            this.selectedValueType = this.relation.valueType;
        }
        this.attributeForm = this.formBuilder.group({
            name: [this.attribute ? this.attribute.name : '', Validators.compose([
                Validators.required,
            ])],
            mandatoryFor: [this.attribute ? this.attribute.mandatoryFor : ''],
            valueType: [this.relation ? this.relation.valueType : this.selectedValueType, Validators.required],
            unit: [this.relation ? this.relation.unit : ''],
        });
        this.defaultEntities =
        this.attribute && this.attribute.mandatoryFor.length > 0
          ? this.attribute.mandatoryFor
          : this.defaultEntities;
        //   if(this.defaultEntities){
        //     this.attributeForm.controls['mandatoryFor'].setValue(this.defaultEntities);
        //   }
        this.attributeForm.controls['name'].markAsUntouched();
        this.attributeForm.controls['mandatoryFor'].markAsUntouched();
        this.attributeForm.markAsUntouched();
    }

    public submit(): void {
        let check = true;
        Object.keys(this.attributeForm.controls).forEach(key=>{
            const checkValidation = this.attributeForm.controls[key];
            if(!checkValidation.valid){
                check=false;
                checkValidation.markAsTouched();
            }
        })
        if(!check) {
            return;
        }
        
        const attribute: AttributeInterface = {
            name: this.attributeForm.controls['name'].value,
            mandatoryFor: this.attributeForm.controls['mandatoryFor'].value
        };
        this.attribute ? this.updateAttributeInLocal(this.attribute.id||this.attribute.tempId, attribute) : this.addAttributeInLocal(attribute);
        // this.initForm();
        this.closeModal.emit();
    }

    public changedValueType(valueType: string): void {
        this.selectedValueType = valueType;
        this.setValueTemplate();
    }

    public deleteRelation(relation: AttributeRelationModel, attributeId: number): void {
        const relationModel = relation;
        if(!(relation instanceof AttributeRelationModel)){
            this.globalRegistry.deleteLocalAttributeRelation(relationModel);
            this.closeModal.emit();
            return;
        }
        this.confirmModalService.openConfirmModal(this.translateService.instant('attribute.confirmDeleteAttributeRelation'),
            this.translateService.instant('attribute.confirmDeleteAttributeRelationMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                    this.attributeService.deleteAttributeRelation(relation.id).subscribe((): void => {
                    this.deleteAttribute(attributeId);
                    this.loadAttributeRelations();
                    this.globalRegistry.reloadAttributes();
                    this.attributeService.attributeRelations.next();
                    this.closeModal.emit();
                    this.toastr.success(this.translateService.instant('attribute.attributeRelationDeletedSuccessfully'));
                }, (): void => {
                    this.toastr.error(this.translateService.instant('attribute.errorDeletingAttributeRelation'));
                });
            }
        });
    }

    public deleteAttribute(attributeId: number): void {
        this.typesService.deleteAttribute(attributeId).subscribe((): void => {
            this.globalRegistry.reloadAttributes();
            this.attributeService.attributeRelations.next();
            this.toastr.success(this.translateService.instant('attribute.attributeDeletedSuccessfully'));
        }, (): void => {
            this.toastr.error(this.translateService.instant('attribute.errorDeletingAttribute'));
        });
    }

    public updateRelation(attributeRelationId: number, relation: AttributeRelationInterface): void {
        relation.objectId = this.objectID;
        this.attributeService.updateAttributeRelation(attributeRelationId, relation).subscribe((): void => {
            this.loadAttributeRelations();
            this.globalRegistry.reloadAttributes();
            this.attributeService.attributeRelations.next();
            this.toastr.success(this.translateService.instant('attribute.attributeRelationUpdatedSuccessfully'));
        }, (): void => {
            this.toastr.error(this.translateService.instant('attribute.errorUpdatingAttributeRelation'));
        });
        // this.closeRelationsModal();
    }

    private submitNewRelation(newRelation: AttributeRelationInterface, attrID: number,object_id: number): void {
        newRelation.objectId = object_id;
        newRelation.type = attrID;
        newRelation.relation = this.relationType;
        this.attributeService.createAttributeRelation(newRelation).subscribe((): void => {
            this.loadAttributeRelations();
            this.attributeService.attributeRelations.next();
            this.globalRegistry.reloadAttributes();
            this.toastr.success(this.translateService.instant('attribute.attributeRelationCreatedSuccessfully'));
        }, (): void => {
            this.toastr.error(this.translateService.instant('attribute.errorCreatingAttributeRelation'));
        });
    }

    private addAttributeInLocal(attribute): void {
        const localAttributes = localStorage.getItem("fap-attributes")
          ? JSON.parse(localStorage.getItem("fap-attributes"))
          : [];
        attribute["tempId"] = uuid.v4();
        localAttributes.push(attribute);
        localStorage.setItem("fap-attributes", JSON.stringify(localAttributes));
        this.globalRegistry.systemData.attributes.push(attribute);
        this.addedAttribute.emit(attribute);
      }
    
      private updateAttributeInLocal(id: string | number, attribute): void {
        const localAttributes: any[] = localStorage.getItem("fap-attributes")
          ? JSON.parse(localStorage.getItem("fap-attributes"))
          : [];

        // Find the attribute to update from global registry, set updated values
        const attributeToUpdate = this.globalRegistry.systemData.attributes.find(
          (item) => item.id === id || item.tempId === id
        );
        attributeToUpdate["name"] = attribute.type.name;
        attributeToUpdate["mandatoryFor"] = attribute.mandatoryFor;

        const index = localAttributes.findIndex(
          (item) => item.id === id || item.tempId === id
        );
        index < 0
          ? localAttributes.push(attributeToUpdate)
          : localAttributes.splice(index, 1, attributeToUpdate);
        localStorage.setItem("fap-attributes", JSON.stringify(localAttributes));
        this.updatedAttribute.emit(attributeToUpdate);
      }

    
    private addAttribute(attribute: AttributeInterface): void {
        this.typesService.createAttribute(attribute).pipe(take(1))
            .subscribe((response: ResponseModel<AttributeModel>): void => {
                this.globalRegistry.reloadAttributes();
                this.attributeService.attributeRelations.next();
                this.addedAttribute.emit(response.model);
                this.toastr.success(this.translateService.instant('attribute.attributeCreatedSuccessfully'));
            }, (): void => {
                this.toastr.error(this.translateService.instant('attribute.errorCreatingAttribute'));
            });
    }

    private updateAttribute(attributeId:number, attribute: AttributeInterface): void {
        this.typesService.updateAttribute(attributeId, attribute).pipe(take(1))
            .subscribe((response: ResponseModel<AttributeModel>): void => {
                this.globalRegistry.reloadAttributes();
                this.attributeService.attributeRelations.next();
                this.updatedAttribute.emit(response.model);
                this.toastr.success(this.translateService.instant('attribute.attributeUpdatedSuccessfully'));
            }, (): void => {
                this.toastr.error(this.translateService.instant('attribute.errorUpdatingAttribute'));
            });
    }

    private loadAttributeRelations(): void {
        const filters: {} = {
            // relation__model: this.relationType,
            // object_id: this.objectID
        };
        this.attributeService.getAttributeRelations(filters).subscribe((response: ResponseModel<AttributeRelationModel[]>): void => {
            this.attributeRelations = response.model;
        });
    }

    private setValueTemplate(): void {
        switch (this.attributeForm.controls['valueType'].value) {
            case 'list-number':
                this.valueTemplate = {list: []};
                break;
            case 'list-string':
                this.valueTemplate = {list: []};
                break;
            case 'list-entity':
                this.valueTemplate = {list: []};
                break;
            default:
                this.valueTemplate = {value: ''};
                break;
        }
    }
}
