import {
  Component, Inject
} from '@angular/core';
import { FapConfirmModalDataInterface } from './data/fap-confirm-modal-data.interface';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'fap-confirm-modal',
    templateUrl: './fap-confirm-modal.component.html',
    styleUrls: ['./fap-confirm-modal.component.scss'],
})
export class FapConfirmModalComponent {

  constructor(
    public dialogRef: MatDialogRef<FapConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FapConfirmModalDataInterface,
    public translate: TranslateService) {
      //
    }
  }
