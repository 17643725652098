import { UserProfileModel } from './user-profile.model';
import { FapBaseModel } from '../../base/fap-base-model';


export class UserModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'date_joined',
        'email',
        'profile',
        'first_name',
        'last_name',
    ];

    public static requiredFields: Array<string> = [
        'id',
        'email',
        'profile'
    ];

    public static extendedFields: {} = {
        profile: UserProfileModel
    };

    public id: number;
    public userName: string;
    public dateJoined: Date;
    public email: string;
    public firstName: string;
    public lastName: string;
    public first_name: string;
    public last_name: string;
    public profile: UserProfileModel = new UserProfileModel();

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

}
