<mat-form-field class="main_combo w-100" (click)="getImages()" [ngClass]="hideInput ? 'hide_input' : ''">
    <mat-label>{{ label }}</mat-label>
    <mat-select #aeds (selectionChange)="addImage.emit($event)" name="image" (infiniteScroll)="scrolledDownImages()" msInfiniteScroll> <!--Add formcontrol name here-->
        <div class="d-flex" style="padding: 0 10px; align-items: center;">
        <mat-form-field appearance="fill" class="w-100 disbld">
            <input matInput #typeFilter disabled placeholder="Search">
        </mat-form-field>
        <div class="input-group-append pl-1" (click)="showImageModal()">
            <span class="input-group-text"><i
                    class="icofont icofont icofont-plus"></i></span>
        </div>
    </div>
    <div class="scroll_wrap" (scroll)="onScroll($event)">
        <mat-option style="height: 0!important;"></mat-option>
        <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
            <div class="name" style="margin: 5px;">
                <div style="display: none;">{{image?.file}}</div>
                <fap-image
                    [src]="mediaUrl+image.file"
                    [isDeleteable]="false"
                    [containerHeight]="100"
                >
                </fap-image>
            </div>
            <div class="actions">
                <action-buttons [isEditable]="false" [locked]="image?.locked" [isDeletable]="true" (deleteEmit)="deleteCompanyImage(image?.id)"></action-buttons>
            </div>
        </mat-option>
    </div>
    </mat-select>
</mat-form-field>

<fap-modal #addImageModal
[showFooter]="false"
inputTitle="Add Image">
<form [formGroup]="imageForm" class="col-12 p-0">
    <div class="form-group" 
    style="display: none">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
            <input matInput formControlName="group" readonly value="notes">
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
            <input matInput formControlName="tags" placeholder="Tags">
        </mat-form-field>
    </div>
    <div class="form-group">
        <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
        <input #fileUploader type="file" (change)="onFileInput($event)">
        <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
    </div>
    <div class="d-flex justify-content-end mt-3">
        <button class="btn mr-2" [disabled]="!image" (click)="imageSubmit()">{{"translations.save" | translate}}</button>
        <button class="btn" (click)="cancel()">{{"translations.cancel" | translate}}</button>
    </div>
</form>
</fap-modal>