import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DashboardContainerComponent } from './containers/dashboard-container/dashboard-container.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardLayoutComponent } from './components/dashboard-layout/dashboard-layout.component';
import { SharedModule } from '../../../shared/shared.module';
import { PartialsModule } from '../../views-partials/partials.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxMasonryModule } from 'ngx-masonry';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
    imports:[
        BrowserModule,
        CommonModule,
        RouterModule,
        FormsModule,
        SharedModule,
        PartialsModule,
        DragDropModule,
        NgxMasonryModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule
    ],
    declarations:[
        DashboardComponent,
        DashboardContainerComponent,
        DashboardLayoutComponent
    ]
})
export class DashboardModule {}