import { FapBaseModel } from '../../base/fap-base-model';


export class ImageModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'file',
        'group',
        'tags',
        'locked',
        'owner',
        'data'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public file: string;
    public group: string;
    public tags: any;
    public locked: boolean;
    public owner: number;
    public data: any;
}
