<fap-header>
    <fap-header-actions [title]="'leftMenu.activities' | translate" [isDeleteEnabled]="activityId ? true : false"></fap-header-actions>
</fap-header>
<fap-activities-add-edit-layout
    [isEditMode]="isEditMode"
    [isPreviewMode]="isPreviewMode"
    [equipments]="equipments"
    [activity]="activity"
    [activityTypes]="activityTypes"
    [planTypes]="planTypes"
    [activities]="activities"
    [farms]="globalRegistry.systemData.farms"
    [lots]="globalRegistry.systemData.lots"
    (scrolledDownActivities)="scrolledDownActivities()"
    (filterActivities)="filterActivities($event)"
    (deleteActivity)="deleteActivity($event)"
    (submitActivity)="submitActivity($event)"
    (updateActivity)="updateActivity($event)"
>
</fap-activities-add-edit-layout>
