<div class="card container-fluid p-0 mb-5">
    <fap-section-container [title]="'equipment.paths' | translate">
        <div class="paths-grid" *ngIf="paths">
            <fap-equipment-path-row [paths]="paths"></fap-equipment-path-row>
        </div>
    </fap-section-container>
    <!-- <div *ngIf="!(equipment?.paths?.length > 0)">
        {{ 'equipment.noTrackingAvailable' | translate }}
    </div> -->
</div>
