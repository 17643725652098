import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { FapModalButtonInterface } from '../fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { ToastrService } from 'ngx-toastr';
import { MatSelect } from '@angular/material/select';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'fap-image-combo',
  templateUrl: './image-combo.component.html',
  styleUrls: ['./image-combo.component.scss']
})
export class ImageComboComponent implements OnInit {

  @Input() group:string = '';
  @Input() hideInput: boolean = false;
  @Input() label: string = 'images';
  public images: any[] = []
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  public nextImgToken :{ offset: number; limit: number; group: string; } = null;
  public getMoreImg = true;
  public subscriptions: Subscription[] = [];
  public imageForm: FormGroup;
  public image: any = null;
  public imageSrc: string;
  public mediaUrl: string = environment.mediaUrl
  @ViewChild('fileUploader') fileUploader:ElementRef;
  @ViewChild('addImageModal')
  public addImageModal: FapModalComponent;
  @Output()
  public addImage: EventEmitter <any> = new EventEmitter();
  @Output()
  public addNewImage: EventEmitter <any> = new EventEmitter();
  public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
  public addImageModalButtonSecondaryInterface: FapModalButtonInterface;

  @ViewChild('aeds')
    public aeds: MatSelect;

  constructor(private cacheService: CacheResolverService, private companyService: CompanyService, private translateService: TranslateService, public globalRegistry: GlobalRegistryService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.initImageForm();
    // this.initImageModalButtons();
  }

  initImageForm() {
    this.imageForm = new FormGroup({
      file: new FormControl(''),
      group: new FormControl(this.group),
      tags: new FormControl(''),
    });
  }

  public initImageModalButtons(): void {
    const _this: ImageComboComponent = this;

    this.addImageModalButtonPrimaryInterface = {
        clickFunction(): void {
            _this.imageSubmit();
        },
        text: this.translateService.instant('save')
    };

    this.addImageModalButtonSecondaryInterface = {
        clickFunction(): void {
            _this.addImageModal.hideModal();
        },
        text: this.translateService.instant('cancel')
    };
}
    public imageSubmit() {
      console.log(this.image);
      const json = {
        "group": this.group, 
        "tags": this.imageForm.get('tags').value ? this.imageForm.get('tags').value : ''
      }
      const formData = new FormData();
      formData.append("file", this.image);
      formData.append("json", JSON.stringify(json));
      this.companyService.postCompanyImages(formData).subscribe(data => {
          console.log(data);
          if(data.body.results.id) {
              // this.getImages();
              this.images.unshift(data.body.results);
              this.addNewImage.emit({value: data.body.results});
              this.aeds.close();
              this.toastr.success(this.translateService.instant('image.imageAddedMessage'));
          }
      }, () => {
        this.toastr.error(this.translateService.instant('image.imageAddedErrorMessage'));
      })
      this.addImageModal.hideModal();
  }
  
    public getImages() {
      const url = this.companyService.getUrl('images/')
      this.cacheService.delete(url+'group='+this.group);
      this.companyService.getCompanyImages({group: this.group}).subscribe((response): void => {
          this.images = response.body["results"];
          console.log(this.images);
          this.nextImgToken = response.body.next
          ? this.globalRegistry.getQueryStringParams(response.body.next.split("?")[1])
          : null;
          if(this.nextImgToken) this.getMoreImg = true
        })
      }
    
      onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDownImages();
        }
      }
    
      scrolledDownImages() {
        const url = this.companyService.getUrl('images/');
        console.log('scrolled');
        if(this.getMoreImg) {
        this.nextImgToken &&
          this.subscriptions.push(this.companyService.getCompanyImages(this.nextImgToken).subscribe((data) => {
            this.cacheService.delete(url+'limit='+this.nextImgToken.limit+'&offset='+this.nextImgToken.offset+'&group='+this.group);
            console.log(data);
            if(this.images) {
              this.images = [...this.images, ...data.body.results];
            } else {
              this.images = data.body.results;
            }
            if(data.body.next == null) {
              this.getMoreImg = false;
              return
            } else {
              const url = data.body.next.split('?')
              const urlParams = new URLSearchParams(url[1]);
              const entries = urlParams.entries();
              const params = this.paramsToObject(entries);
              console.log(params);
              if(this.nextImgToken.offset != params['offset']) {
              this.nextImgToken = {limit: params['limit'], offset: params['offset'], group: this.group};
              } else {
                return
              }
            }
          }));
        } else {
          return
        }
      }
  
      paramsToObject(entries) {
          const result = {}
          for(const [key, value] of entries) {
            result[key] = value;
          }
          console.log(result);
          return result;
        }
  
    public deleteCompanyImage(imageId: number): void {
      console.log(imageId);
      this.companyService.deleteCompanyImage(imageId).subscribe(data => {
          console.log(data);
          this.images = this.images.filter(image => image.id !== imageId);
          // this.getImages();
          this.toastr.success(this.translateService.instant('image.imageDeletedSuccessfully'));
      }, () => {
        this.toastr.success(this.translateService.instant('image.imageDeleteFailed'));
      })
    }
  
    public showImageModal(): void {
      this.addImageModal.showModal();
    }
  
    onFileInput(event) {
      if(event.target.files && event.target.files.length) {
        if ( /\.(jpe?g|png|gif)$/i.test(event.target.files[0].name) === false ) { 
          this.toastr.error(this.translateService.instant('image.notAnImage'))
          this.image = null;
          this.fileUploader.nativeElement.value = null;
          return
        }
        const [image] = event.target.files;
        this.image = image;
        const _URL = window.URL || window.webkitURL;
        let file, img, height, width;
        if ((file = event.target.files[0])) {
            img = new Image();
            const objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                height = this.height;
                width = this.width;
                _URL.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }
      }
    }

    cancel() {
      this.image = null;
      this.imageForm.reset();
      this.imageForm.clearValidators();
      this.initImageForm();
      this.addImageModal.hideModal();
    }
}
