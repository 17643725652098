<div class="notes-view d-flex d-md-flex bd-highlight" [routerLink]="['edit/', note.id]" [ngClass]="{ 'note-overlay': !note?.active }" >
  <div class="abs_child" [style.backgroundColor]="note.type.color"></div>
  <div class="bd-highlight typebg">
    <div [style.backgroundColor]="note.type.color" class="note-type">
      <span class="vertical-label" *ngIf="!note.active">{{"notes.closed" | translate}}</span>
    </div>
  </div>
  
  <div class="bd-highlight noteimg">
    <div class="card-image valign-wrapper user_im img_place_bg">
      <ng-container *ngFor="let person of people">
        <ng-container *ngIf="person?.id == note?.created_by">
      <img *ngIf="person?.picture"
        [src]="mediaUrl + person?.picture"
        [alt]="'creator'"
      />
      <img *ngIf="!person?.picture"
        [src]="defaultImg"
        [alt]="'creator'"
      />
    </ng-container>
    </ng-container>
    </div>
    
</div>

<!-- <div class="p-2 bd-highlight noteimg" *ngIf="note?.created_by == 1">
  <img class="admin_img" [src]="defaultImg"
  width="50"
  height="55">
</div> -->
<div class="right_row">
  <div class="title">
    <h5>{{note?.name ? note?.name : 'No name'}}</h5>
    <!-- <p class="m-0">
      <span class="select-option-info lots">{{ [globalRegistry.systemData.lots, note?.lots] | getLotNames }}</span>
  </p> -->
  </div>
  <div class="sub_contents d-flex">
  <div class="p-2 bd-highlight w-50 sample info"> 
  <p class="m-0">
    <span *ngIf="note?.type?.name_t">
      <b *ngIf="getTranslation(note?.type?.name_t) === '' || getTranslation(note?.type?.name_t) == null || getTranslation(note?.type?.name_t) == undefined ">{{note?.type?.name}}</b>
      <b *ngIf="getTranslation(note?.type?.name_t) !== ''">{{getTranslation(note?.type?.name_t)}}</b>
  </span>
  <b *ngIf="!note?.type?.name_t">{{note?.type?.name}}</b>
  </p>
</div>
<div class="p-2 bd-highlight w-50 sample datetime" > 
  <div>
    <div>
      <span >{{note.timestamp | date: "MMM dd yyyy, HH:mm"}}</span>
      <span *ngIf="note?.until">
        <span style="display: block;" class="card-cell-time-color">{{(note?.until | date: "MMM dd yyyy, HH:mm")}}</span></span> 
      
      <!-- <span *ngIf="!note?.until">
        <span style="text-align:right; display: block;" class="card-cell-time-color">{{'---'}} </span>
      </span> -->
      
    </div>
    <!-- <div *ngIf="!commonYear">
      <span >{{note.timestamp | date: "MMM dd yyyy, HH:mm"}}</span> 
    <span style="text-align:right; display: block;" class="card-cell-time-color">{{(note?.until | date: "HH:mm") || '---'}} </span>
    </div> -->
  </div>
<!-- <div *ngIf="!sameDate">
  <span style="display: block;">{{note.timestamp | date: "MMM dd"}} 
    <span *ngIf="!sameYear">{{note.timestamp | date: ', y '}}</span> 
    <span>{{note.timestamp | date: 'HH:mm'}}</span>
  </span>
  <span *ngIf="note.until">
  <span class="card-cell-time-color">{{(note?.until | date: "MMM dd") || '---'}} 
    <span *ngIf="!sameYear">{{note?.until | date: ', y '}}</span>
    <span>{{note?.until | date: 'HH:mm'}}</span>
  </span>
</span>
</div> -->
  </div>
  <div class="p-2 bd-highlight w-50 sample desc pc"> 
    <span style="display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;" *ngIf="note?.info === '' || note?.info == null">No info</span>
    <span style="display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;" *ngIf="note?.info !== ''">{{note?.info}}</span>
    </div>
  </div>
    
  </div>
    <div class="final_row">
    <div style="width: 72px; min-height: 1px;" class="bd-highlight assigned_to"> 
      <ng-container *ngFor="let person of people">
        <ng-container *ngIf="note?.assigned_to">
    <span *ngIf="note?.assigned_to === person?.id">
      <div class="card-image valign-wrapper user_im img_place_bg" >
        <img 
          [src]="mediaUrl + person?.picture"
          [alt]="'creator'"
          *ngIf="person?.picture"
        />
        <img
          [src]="defaultImg"
          [alt]="'creator'"
          *ngIf="!person?.picture"
        />
      </div>
    </span>
  </ng-container>
  </ng-container>
</div>
      </div>
<!-- <div class="p-2 pt-4 ybd-highlight p-trash" style="padding-right: 25px!important;"> 
<i class="fa fa-trash c-pointer" (click)="deleteNote.emit(note.id)"></i>
</div> -->
<div class="p-2 bd-highlight w-25 sample desc mobl" style="display: -webkit-box;
  -webkit-line-clamp: 2; padding-top: 0!important; padding-bottom: 0!important;
  -webkit-box-orient: vertical; text-align: left;
  overflow: hidden;"> 
    {{ note?.info ? note?.info : "-" }}
    </div>
</div>
<!--<div
  class="row align-items-center notes-view m-3"
  [ngClass]="{ 'note-overlay': !note?.active }"
  
>
<div class="col-md-1">
  <div [style.backgroundColor]="note.type.color" class="note-type"></div>
</div>
<div class="col-md-1 text-left">
    <img
      [src]="
        note['created_by?']?.picture ? note['created_by?'].picture : defaultImg
      "
      [alt]="'creator'"
      width="50"
      height="55"
    />
  </div>
  <div class="col-md-3">
    <h6 class="m-0 text-capitalize">
      {{ note?.lot?.name }}
    </h6>
    <p class="m-0">{{ note?.type?.name }}</p>
  </div>
  <div class="col-md-3">
    <p class="m-0">{{ note?.timestamp | date: "MMM d, y, h:mm" }}</p>
    <p class="m-0">{{ note?.until | date: "MMM d, y, h:mm" }}</p>
  </div>
  <div class="col-md-2">{{ note?.info ? note?.info : "-" }}</div>
  <div class="col-md-1 mx-0">
    <img
      [src]="
        note?.assigned_to?.picture ? note?.assigned_to?.picture : defaultImg
      "
      alt="assigned-to-person"
      width="50"
      height="55"
    />
  </div>
  <div class="col-md-1 mx-0 text-right">
    <i class="fa fa-trash c-pointer" (click)="deleteNote.emit(note.id)"></i>
  </div>
</div> -->