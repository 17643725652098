<div>
    <fap-user-profile-layout
        [isProfileOpen]="isProfileOpen"
        [buttonColor]="buttonColor"
        [accentColor]="accentColor"
        (toggleProfileModal)="toggleProfile($event)"
        [userInfo]="userData"
        (updateUser)="updateUser($event)"
        (resetPassword)="resetPassword($event)"
        (updateProfilePicture)="updateProfilePicture($event)">
    </fap-user-profile-layout>
</div>
