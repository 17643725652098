<div class="critical_operation">
    <p class="m-0">All data related with <b>{{entityName}}</b> will be deleted. In order to proceed please type "delete" below and press OK</p>
    <div class="input_area d-flex">
        <mat-form-field class="w-100" cdkFocusInitial>
            <input
              type="text"
              matInput
              (input)="validateInput($event.target.value)"
              [(ngModel)]="inputValue"
              (keydown.enter)="confirmDelete()"
            >
        </mat-form-field>
        <div style="margin-left: 15px;">
            <button class="btn" [disabled]="!showOkButton" (click)="confirmDelete()">{{ 'ok' | translate }}</button>
        </div>
    </div>

</div>
