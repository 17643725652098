import { Routes } from '@angular/router';
import { FieldContainerComponent } from './containers/field-container/field-container.component';
import { FieldEditContainerComponent } from './containers/field-edit-container/field-edit-container.component';

export const fieldRoutes: Routes = [
    {
        path: 'edit/:fieldId',
        component: FieldEditContainerComponent
    },
    {
        path: 'create',
        component: FieldEditContainerComponent
    },
    {
        path: '',
        component: FieldContainerComponent
    }
];
