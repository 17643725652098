import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { NoteModel } from '../../../core/models/notes/note.model';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { DataService } from '../../../core/services/websocket/data.service';
import { FapModalComponent } from '../../partials';
import { NavService } from '../../services/nav.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';

@Component({
  selector: 'fap-sensor-value-widget',
  templateUrl: './fap-sensor-value-widget.component.html',
  styleUrls: ['./fap-sensor-value-widget.component.scss']
})
export class FapSensorValueWidgetComponent implements OnInit {

  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];

  public selectedWidget;
  public mediaUrl = environment.mediaUrl;
  @Input()
  public notes: NoteModel[];

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addEditWidgetModal') addEditWidgetModal: FapModalComponent;

  public toggleMain = false;
  public toggleSub = false;
  public personList: any;
  public showMenu = true;
  public fromDate = new Date();
  public toDate = new Date();
  public agg = 'day';
  public subscription1: Subscription;
  public subscription2: Subscription;
  public subscription3: Subscription;
  public dataSource: any[] = [];
  public finalSensorList = [];
  public sensorNames = [];
  public columns: any[] = [];
  public sensorIds = [];
  public units: [];
  public localSensorData: any = [];
  public subscription: Subscription;
  public translatedNames: any = [];
  @Input() public langString: string;

  constructor(
      private globalRegistry: GlobalRegistryService,
      public companyService: CompanyService,
      public navService: NavService,
      public sensorService: SensorService,
      public toastr: ToastrService,
      public translateService: TranslateService,
      public dataService: DataService,
      private localStorageService: LocalStorageService
  ) {}

  public toggleImageMenu() {
      this.showMenu = !this.showMenu;
  }

  getTranslation(translation) {
      const t =this.globalRegistry.systemData.translations.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
          return translation
        }
    }

  ngOnInit(): void {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.agg = '';

      setTimeout(() => {
          this.drillData();
      }, 2000);

      this.subscription = this.sensorService.getName.subscribe((res) => {
          if (res) {
              this.dataSource = [];
              this.drillData();
          }
      });
      this.translatedNames = this.globalRegistry.systemData.translations;
      this.localStorageService.watchTimezone().subscribe(data => {
        this.drillData();
      })
  }

  showModal(widget): void {
      this.selectedWidget = widget;
      this.addEditWidgetModal.showModal();
  }

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
    return lot ? lot.name : '';
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  getFromDate() {
      this.subscription1 = this.sensorService.getFromDate.subscribe((res) => {
          this.fromDate = res;
          // console.log(this.fromDate);
      });
  }

  getToDate() {
      this.subscription2 = this.sensorService.getToDate.subscribe((res) => {
          this.toDate = res;
          // console.log(this.toDate);
      });
  }

  getAgg() {
      this.subscription3 = this.sensorService.getAgg.subscribe((res) => {
          this.agg = res;
          // console.log(res);
      });
  }

  getLocalSensors(sensorData: any[]) {
    const objects = Object.values(this.objects);
    const unitTypes = Object.values(this.unitTypes);
    
    return sensorData.map(sensor => {
        const sensorId = sensor.id;
        const sensorObject = this.sensors.find(s => s.id === sensorId);
        if (!sensorObject) return null;
        const sensorUnitTypes = unitTypes.find(unitType => unitType.id === sensorObject.unitType);
        const sensorObjectData = objects.find(obj => obj.id === sensorObject.objectId);

        return {
            unitType: sensorUnitTypes,
            object: sensorObjectData,
            sensor: sensorObject,
            css: sensor.css,
        };
    }).filter(Boolean);
} 

  public async drillData() {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.agg = '';
    this.getFromDate();
    this.getToDate();
    this.getAgg();
    this.dataSource = [];
    const sensorIds = this.widget["settings"]["sensors"].map((sensor) => sensor.id);
    const css = this.widget["settings"]["sensors"].map((css: { css }) => css.css);
    console.log(sensorIds);
    // Use await to ensure getLocalSensors completes before proceeding
    await new Promise<void>((resolve) => {
        setTimeout(() => {
            this.localSensorData = this.getLocalSensors(this.widget['settings']['sensors']);
            console.log(this.localSensorData)
            this.sensorNames = this.localSensorData.map(
                (item) => `${item.object.extraText} ${item.unitType.name_t ? item.unitType.name_t : item.unitType.name}`
            );
            this.units = this.localSensorData.map((item) => `${item.unitType.unit}`);
            this.columns = [...this.sensorNames];
    
            // Now that localSensorData is fully loaded, populate sensorNames
            resolve(); // Resolve the promise once localSensorData is loaded
        }, 1000); // Wait for 1 second before loading localSensorData
    });        

    const observables = sensorIds.map((sensorId) =>
        this.sensorService.drillData({
            sensor: sensorId,
            from: this.fromDate,
            agg_by: this.agg,
            to: this.toDate,
        })
    );

    // Use await to ensure combineLatest completes before proceeding
    await new Promise<void>((resolve) => { // Include void as the type argument
        combineLatest(observables).subscribe((drilled: any[]) => {
            this.dataSource = drilled.map((element) => element[element.length - 1]);
            resolve(); // Resolve the promise once dataSource is loaded
        });
    });

    // Update dataSource with CSS, names, and units
    if (this.dataSource.length) {
        this.dataSource.forEach((e, i) => {
            if (e && e.css) e.css = css[i];
        });
        this.dataSource.forEach((e, i) => {
            if (e && e.name) e.name = this.columns[i];
        });
        this.dataSource.forEach((e, i) => {
            if (e && e.unit) e.unit = this.units[i];
        });
    }

    console.log(this.dataSource);
}
}
