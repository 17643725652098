import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { AlergotelService } from '../../../../../core/services/api/alergotel/alergotel.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss']
})
export class AddReportComponent implements OnInit {

  public showCamera = true;
  public isBaseApplication = true;
  public position = 'closeup';
  public reportForm:UntypedFormGroup;
  public coords;
  shouldShowHeader = false;

  @ViewChild('checkBox1') checkBox1: MatCheckbox;
  @ViewChild('checkBox2') checkBox2: MatCheckbox;

  latitude: number;
  longitude: number;

  constructor(public router: Router, public widgetService: WidgetsService, public mapService: MapService, public formBuilder: UntypedFormBuilder, public alergotelService: AlergotelService, public toastr: ToastrService, public translate: TranslateService) {
  }

  getCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          const coords = {
            type: "Point",
            coordinates: [this.latitude, this.longitude]
          };
          this.coords = coords;
          console.log(coords);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }

  ngOnInit(): void {
    const url = this.router.url;
      const containsPages = url.includes('pages');
      if (containsPages) {
        this.shouldShowHeader = true;
      }
    this.widgetService.setSize(12);
    this.mapService.hideMap();
    this.initReportForm();
    this.getCurrentLocation();
    const currentUrl = this.router.url;
    if (currentUrl.includes('pages')) {
      this.isBaseApplication = true;
    } else {
      this.isBaseApplication = false;
    }
  }

  private initReportForm(): void {
    this.reportForm = new UntypedFormGroup({
      infested_area: new UntypedFormControl(4, Validators.required),
      img_closeup: new UntypedFormControl('', Validators.required),
      img_left: new UntypedFormControl('', Validators.required),
      img_right: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      coords: new UntypedFormControl(null, Validators.required),
    });
}

  addImage(obj) {
    const key = Object.keys(obj)[0];
    if(key === 'img_closeup') {
      this.reportForm.get('img_closeup').setValue(obj.img_closeup);
      this.position = 'left';
    } 
    if(key === 'img_left') {
      this.reportForm.get('img_left').setValue(obj.img_left);
      this.position = 'right';
    } 
    if(key === 'img_right') {
      this.reportForm.get('img_right').setValue(obj.img_right);
      this.position = 'closeup';
      this.showCamera = false;
    }
  }

  addReport() {
    console.log(this.reportForm.value);
    const formData = new FormData();
    formData.append("img_closeup", this.reportForm.get('img_closeup').value);
    formData.append("img_left", this.reportForm.get('img_left').value);
    formData.append("img_right", this.reportForm.get('img_right').value);
    
    const object = {
      name: this.reportForm.get('name').value,
      coords: this.coords,
      infested_area: this.reportForm.get('infested_area').value
    }

    formData.append('json', JSON.stringify(object));
        
    this.alergotelService.createReport(formData).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translate.instant('alergotel.reportCreated'));
      if(this.isBaseApplication) {
        this.router.navigate(['/pages/alergotel_reports']);
      } else {
        this.router.navigate(['/alergotel/reports']);
      }
    }, (err) => {
      console.log(err)
      this.toastr.error(err.error.results.error);
    })
  }

  goBack() {
    this.router.navigate(['pages/alergotel_reports']);
  }

}
