import { FapBaseModel } from '../../base/fap-base-model';
import { ProductLabelModel } from './product-label.model';

export class ProductModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'prospect',
        'images',
        'pack_unit',
        'unit',
        'quantity',
        'quantity_pack',
        'cost',
        'alert_group',
        'alert_quantity',
        'locked',
        'label'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
        
    };

    public id: number;
    public prospect: string;
    public images: Array<any>;
    public packUnit: any;
    public quantityPack: number;
    public unit: string;
    public quantity: number;
    public cost: number;
    public alertGroup: number;
    public alertQuantity: number;
    public locked: boolean;
    public label: ProductLabelModel
}
