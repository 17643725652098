import { Injectable, EventEmitter } from '@angular/core';
import { PathEvent } from '@agm/core/directives/polyline';
import { MouseEvent, PolyMouseEvent } from '@agm/core';
import { BehaviorSubject } from 'rxjs';

import { MapPolygonInterface } from '../data/map-polygon.interface';
import { MapMarkerInterface } from '../data/map-marker.interface';
import { MapPointInterface } from '../data/map-point.interface';
import { MapCircleInterface } from '../data/map-circle.interface';
import { MapPolylineSegmentInterface } from '../data/map-polyline.interface';
import { MapMenuOptionsInterface } from '../data/map-menu-options.interface';
import { MapCirclePointInterface } from '../data/map-circle-point.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { MapEmitterInterface } from '../data/map-emirter.interface';
import { MapButtonInterface } from '../data/map-button.interface';
import { PrecisionParamsInterface } from '../../../../core/interfaces/api/precision/precision-params.interface';
import { PrecisionService } from '../../../../core/services/api/precision/precision.service';
import { MapHelper } from '../../../../core/heplers/map.helper';

/**
 * @todo to many subcribes on onMapInit
 */
@Injectable({
    providedIn: 'root'
})
export class MapService {
    // List of map markers. For new markers push in this array.
    public mapMarkers: MapMarkerInterface[] = [];

    // Center of map, map is center on this point.
    public mapFocusPoint: MapPointInterface = {};

    // List of map poligons. For new poligon push new poly in this array.
    public mapPolygons: MapPolygonInterface[] = [];

    // Poligon stroke color.
    public mapPoligonStrokeColor = '#3792c1';

    // Poligon fill color.
    public mapPoligonFillColor = 'blue';

    // Map edit mode.
    public isEditMode = true;

    // Map buttons.
    public showButtons = false;

    // List of map cicles. Doesn't work to pus new circle in array, if you want circle values should be on map init.
    public mapCircles: MapCircleInterface[] = [];

    public reports: any[] = [];

    // List of map polilines. For new poliline push in this array.
    public mapPolylines: MapPolylineSegmentInterface[] = [];

    // Here is value in ha of last clicked poligon.
    public lastPoligonTouchedSelectedArea = 0;

    public lastPoligonTouchedSelectedLenght: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private displayMap = new BehaviorSubject(true);

    // Position of last right click.
    public rightClickLastPosition: MapPointInterface = {};

    // Map right clikc menu options.
    public mapMenuOptions: MapMenuOptionsInterface[] = [];

    // Open and close main map.
    public mainMapIsOpened = false;

    // Map spinner service.
    public mapSpinnerService: NgxSpinnerService;

    // Map left buttons
    public buttons: Array<MapButtonInterface> = [];

    // Device current location
    public currentLocation: MapPointInterface = {};

    // Map has 100% width
    public isFullMap = false;

    // Map has 60% width
    public mapShiftedOnce = false;

    // Map has 80% width
    public mapShiftedTwice = false;

    public mapZoom = new BehaviorSubject(8);

    public mapHasContextMenu = false;

    // Map bounds for centering and zooming
    public bounds: google.maps.LatLngBounds;

    // Emmits when menu is initialized.
    public onMapMenuInit: EventEmitter<void> = new EventEmitter<void>();

    public onMapInit: BehaviorSubject<google.maps.Map> = new BehaviorSubject<google.maps.Map>(null);

    public onCurrentLocationLoaded: EventEmitter<MapPointInterface> = new EventEmitter<MapPointInterface>();

    public onLastPolyPathChange: EventEmitter<MapPolygonInterface> = new EventEmitter<MapPolygonInterface>();

    public onMarkerDragEnd: EventEmitter<MapMarkerInterface> = new EventEmitter<MapMarkerInterface>();

    public clickedMarker: EventEmitter<MapMarkerInterface> = new EventEmitter<MapMarkerInterface>();

    public selectImageTypeBtn: EventEmitter<string> = new EventEmitter<string>();

    public clickedRevertBtn: EventEmitter<void> = new EventEmitter<void>();

    public mapButtonClick: EventEmitter<string> = new EventEmitter<string>();

    public circleCliked: EventEmitter<MapCirclePointInterface> = new EventEmitter<MapCirclePointInterface>();

    public circleRadiusChanged: EventEmitter<number> = new EventEmitter<number>();

    public circleDragEnd: EventEmitter<MapCirclePointInterface> = new EventEmitter<MapCirclePointInterface>();

    public onPolyClick: EventEmitter<MapEmitterInterface> = new EventEmitter<MapEmitterInterface>();

    public onPolyRightClick: EventEmitter<MapEmitterInterface> = new EventEmitter<MapEmitterInterface>();

    public polyDrangEnd: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public lineClick: EventEmitter<PolyMouseEvent> = new EventEmitter<PolyMouseEvent>();

    public polyPathChange: EventEmitter<PathEvent> = new EventEmitter<PathEvent>();

    public toggledMap: EventEmitter<boolean> = new EventEmitter<boolean>();

    public shiftMap: EventEmitter<number> = new EventEmitter<number>();

    public onPolygonDelete: BehaviorSubject<void> = new BehaviorSubject<void>(null);
    public onPalygonDrawed: BehaviorSubject<void> = new BehaviorSubject<void>(null);

    getMapDisplay = this.displayMap.asObservable();

    public reportId: BehaviorSubject<any> = new BehaviorSubject<number>(null);

    public setMapDisplay(data) {
        this.displayMap.next(data);
    }

    public addMarker(markerColor = 'red'): void {
        this.mapMarkers.push({
            lat: this.rightClickLastPosition.lat,
            lng: this.rightClickLastPosition.lng,
            iconUrl: this.setMarkerIconColor(markerColor)
          });
    }

    public drawNewPolyline(polylineColor = 'red', startPointCoordinates: MapPointInterface = this.rightClickLastPosition): void {
        this.mapPolylines.push(
            {
                points: [
                    {
                        lat: startPointCoordinates.lat,
                        lng: startPointCoordinates.lng,
                    },
                    {
                        lat: startPointCoordinates.lat + 0.01,
                        lng: startPointCoordinates.lng + 0.01
                    }
                ],
                color: polylineColor
            },
        );
    }

    public getLengthOfPolygon(poligon: MapPolygonInterface): number {
        return poligon ? MapHelper.getPolygonLength(poligon.points) / 1000 : 0;
    }

    public drawNewPolygon(): void {
        const newPolygon: MapPolygonInterface = {
            points: [this.rightClickLastPosition, this.rightClickLastPosition],
            fillColor: this.mapPoligonFillColor,
            strokeColor: this.mapPoligonStrokeColor
        };
        this.mapPolygons.push(newPolygon);
    }

    public setMarkerIconColor(markerColor: string): string {
        return 'http://maps.google.com/mapfiles/ms/icons/' + markerColor + '-dot.png';
    }

    public deletePolygon(poligonIndex: number): void {
        this.mapPolygons.splice(poligonIndex, 1);
    }

    public resetMap(): void {
        this.mapPolygons = [];
        this.mapPolylines = [];
        this.mapMarkers = [];
        this.removeGroundOverlay();
        this.onPolygonDelete.next();
    }

    public clearEditablePolygons(): void {
        this.mapPolygons = this.mapPolygons.filter((poly: MapPolygonInterface): boolean => poly.isEditable !== true);
        this.onPolygonDelete.next();
    }

    public centerMapOnPolygons(): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (!map) {
                return;
            }
            if (!this.mapPolygons.length) {
                return;
            }
            this.bounds = new google.maps.LatLngBounds();
            this.mapPolygons.forEach((polygon: MapPolygonInterface): void => {
                polygon.points.forEach((point: MapPointInterface): void => {
                    this.bounds.extend(new google.maps.LatLng(point.lat, point.lng));
                });
            });
            // this.mapZoom.next(16);
        });
    }

    public centerMapOnCircles(): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
          if (!map) {
            return;
          }
          if (!this.reports.length) {
            return;
          }
          this.bounds = new google.maps.LatLngBounds();
          this.reports.forEach((report): void => {
            this.bounds.extend(new google.maps.LatLng(report.coords.coordinates[0], report.coords.coordinates[1]));
          });
          map.fitBounds(this.bounds);
        });
      }

    // public centerMapOnPolygon(polygon: MapPolygonInterface): void {
    //     this.centerMapOnPolygonIndex(this.mapPolygons.findIndex((poly: MapPolygonInterface): boolean => poly === polygon));
    // }

    // public centerMapOnPolyline(polyline: MapPolylineSegmentInterface): void {
    //     this.centerMapOnPolygonIndex(this.mapPolylines.findIndex((poly: MapPolylineSegmentInterface): boolean => poly === polyline));
    // }

    public centerMapOnPolygon(polygon: MapPolygonInterface): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (!map || !polygon || !polygon.points || polygon.points.length === 0) {
                return;
            }
            const bounds = new google.maps.LatLngBounds();
            polygon.points.forEach((point: MapPointInterface): void => {
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
            });
            map.fitBounds(bounds);
        });
    }    

    public centerMapOnPolyline(polyline: MapPolylineSegmentInterface): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (!map || !polyline || !polyline.points || polyline.points.length === 0) {
                return;
            }
            const bounds = new google.maps.LatLngBounds();
            polyline.points.forEach((point: MapPointInterface): void => {
                bounds.extend(new google.maps.LatLng(point.lat, point.lng));
            });
            map.fitBounds(bounds);
        });
    }    

    public centerMapOnPolygonIndex(polygonIndex: number): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (!map) {
                return;
            }
            this.bounds = new google.maps.LatLngBounds();
            if (polygonIndex >= 0 && polygonIndex < this.mapPolygons.length) {
               
                this.mapPolygons[polygonIndex].points.forEach((point: MapPointInterface): void => {
               
                    this.bounds.extend(new google.maps.LatLng(point.lat, point.lng));
                });
            }
        });
    }

    public centerMapOnUserLocation(setZoom:boolean = true): void {
        if (!this.currentLocation) {
            return;
        }
        if(this.mapFocusPoint.lat === this.currentLocation.lat &&
            this.mapFocusPoint.lng === this.currentLocation.lng){
            // trigger change detection
            this.mapFocusPoint.lat += 0.0000000000001;
            this.mapFocusPoint.lng += 0.0000000000001;
        } else {
            this.mapFocusPoint.lat = this.currentLocation.lat;
            this.mapFocusPoint.lng = this.currentLocation.lng;
        }
        if(setZoom) {
            this.mapZoom.next(14);
        }
    }

    public centerMapOnMarkers(): void {
        if (!this.mapMarkers.length) {
            return;
        }

        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (!map) {
                return;
            }
            this.bounds = new google.maps.LatLngBounds();
            if (this.mapMarkers.length) {
                // let latSum = 0;
                // let lngSum = 0;
                this.mapMarkers.forEach((point: MapPointInterface): void => {
                    // latSum += point.lat;
                    // lngSum += point.lng;
                    this.bounds.extend(new google.maps.LatLng(point.lat, point.lng));
                });
            }
        });
        
            this.mapFocusPoint.lat += this.mapMarkers[0].lat;
            this.mapFocusPoint.lng += this.mapMarkers[0].lng;
            this.mapFocusPoint.zoom += this.mapMarkers[0].zoom
        
        // this.mapZoom.next(20);
    }


    public showMap(fullMap?: boolean): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (map) {
                this.mainMapIsOpened = true;
                this.displayMap.next(true);
                this.toggledMap.emit(true);
            }
            this.isFullMap = fullMap || false;
        });
    }

    public hideMap(): void {
        this.mainMapIsOpened = false;
        this.displayMap.next(false);
        this.isFullMap = false;
        this.toggledMap.emit(false);
    }

    public shiftMapLeft(input: number): void {
        if (this.mainMapIsOpened) {
            if (input % 2 === 0) {
                this.mapShiftedOnce = true;
                this.shiftMap.emit(1);
            } else if (input % 3 === 0) {
                this.mapShiftedTwice = true;
                this.shiftMap.emit(2);
            } else {
                this.mapShiftedOnce = false;
                this.mapShiftedTwice = false;
                this.shiftMap.emit(0);
            }
        }
    }

    public addGroundOverlay(params: PrecisionParamsInterface, precisionService: PrecisionService, lotId: number): void {
        const overlay = (map: google.maps.Map): google.maps.ImageMapType => {
            return new google.maps.ImageMapType({
                getTileUrl: (coord: google.maps.Point, zoom: number): string => {
                    const bbox: number[] = this.tileCoordsToBBox(map, coord, zoom, 256, 256);
                    params.bbox = bbox.join(',');
                    return precisionService.getImageUrl(lotId, params);
                },
                tileSize: new google.maps.Size(256, 256),
            });
        };
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            map.overlayMapTypes.push(overlay(map));
        });
    }

    public removeGroundOverlay(): void {
        this.onMapInit.subscribe((map: google.maps.Map): void => {
            if (map) {
                map.overlayMapTypes.clear();
            }
        });
    }

    public centerOnLocation(): void {
        // const currentLocation: MapPointInterface = JSON.parse(localStorage['currentLocation']) ;
        // this.centerMapOnPolygon({points: [currentLocation]});
        this.mapZoom.next(12);
    }

    private tileCoordsToBBox(map: google.maps.Map, coord: google.maps.Point, zoom: number, tileWidth: number, tileHeight: number): number[] {
        const proj: google.maps.Projection = map.getProjection();
        const scale: number = Math.pow(2, zoom);
        const ne: google.maps.LatLng = proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileWidth / scale, coord.y * tileHeight / scale));
        const sw: google.maps.LatLng = proj.fromPointToLatLng(new google.maps.Point(coord.x * tileWidth / scale, (coord.y + 1) * tileHeight / scale));
        return [
          sw.lng(),
          sw.lat(),
          ne.lng(),
          ne.lat()
        ];
    }
}
