<div class="asset_card green">
    <div class="image">
        <div class="user_im img_place_bg">
        <img *ngIf="workflow?.icon" [src]="mediaUrl + workflow?.icon?.file" alt="icon" (click)="toggleTableView.emit(workflow?.id)">
        <img *ngIf="!workflow?.icon" src="../../../../../../assets/images/image_placeholder.png" alt="product" (click)="toggleTableView.emit(workflow?.id)">
    </div>
    </div>
    <div class="info w-100">
        <div class="name" (click)="toggleTableView.emit(workflow?.id)">{{workflow?.name ? workflow?.name : workflow?.uniqueid}}</div>
        <div class="buttons_container d-flex justify-content-between">
            <button *ngIf="!workflow?.running" class="btn" (click)="executeWorkflow.emit(workflow)">{{ 'workflow.start' | translate }}</button>
            <button *ngIf="workflow?.running" class="btn" (click)="continueWorkflow.emit(workflow?.running?.id)">{{ 'workflow.continue' | translate }}</button>
            <button *ngIf="workflow?.running" class="ricon" (click)="deleteWorkflow.emit(workflow?.running?.id)"><i class="fa fa-trash"></i></button>
        </div>
    </div>
</div>