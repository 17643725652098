import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { WidgetModel } from '../../../models/widget/widget.model';
import { take } from 'rxjs/operators';
import { ResponseModel } from '../../../models/response.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { WeatherModel } from '../../../models/widget/weather/weather.model';

@Injectable()
export class ForecastService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/forecast/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public reloadForecast(contentType: string, objectId: number): Observable<ResponseModel<{}>> {
        const body: {} = { contentType, objectId };
        return this.mapRequest<WidgetModel>(
            this._http.post(this.url + 'forecast/reload_forecast/',
                this.camelCaseModelToSnakeCaseJson(body),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            {},
            false
        ).pipe(take(1));
    }

    public getForecast(params?): Observable<ResponseModel<{}>> {
        return this.mapRequest<WeatherModel[]>(
            this._http.get(this.url + 'forecast/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            WeatherModel,
            true
        ).pipe(take(1));
    }
}
