import { Injectable } from '@angular/core';
import {
    Observable,
    Subscription,
    BehaviorSubject
} from 'rxjs';
import { FapConfig } from '../../../config/fap.config';
import { WebsocketService } from '../websocket.service';
import { map } from 'rxjs/operators';
import { WidgetsResponseInterface } from './data/widgets-response.interface';

@Injectable()
export class WidgetsWebsocketService {
    public websocketUrl: string;

    private widgetData: Observable<any>;
    private terseyaConfig: FapConfig;
    private widgetObservable: BehaviorSubject<object> = new BehaviorSubject({});

    constructor(private wsService: WebsocketService) {
        this.terseyaConfig = FapConfig.getInstance();
        this.websocketUrl = this.terseyaConfig.WS_URL + 'ws/widgets/' + localStorage.getItem('auth_token') + '/';

        this.widgetData = this.wsService.connect(this.websocketUrl).pipe(
            map(
                (response: MessageEvent): WidgetsResponseInterface => {
                    const data: WidgetsResponseInterface = JSON.parse(response.data);
                    return data;
                }
            )
        );
        this.widgetData.subscribe((response: any): void => {
            this.widgetObservable.next(response);
        });
    }

    public sendWidgetConfig(data: any): void {
        this.wsService.sendData.next(data);
    }

    public subscribe(callback: (response: any) => void): Subscription {
        return this.widgetObservable.subscribe(callback);
    }

}
