<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span> -->
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" style="height: calc(100% - 30px);">
        <div #container class="fixed_img_width" style="text-align: center; max-height: 100%;">
            <div class="position-relative crop-eye-img" style="display: flex; align-items: center; overflow: hidden; justify-content: center;">
                <img style="max-width: 100%;" *ngIf="images[0]?.image != undefined" [src]="images[0]?.image" alt="">
                <span class="loading" *ngIf="images[0]?.image == undefined || images[0]?.image === ''">
                    <span class="loader">
                        <i></i>
                      </span>
                </span>
                <div *ngIf="showMenu && images[0]?.image != undefined">
                  
                    <div class="text-white fs12 bg-blur blur1" style="padding: 5px; display: none;">
                        <span class="color-yellow-a">{{
                            zoomLevelString
                        }}</span>
                        {{ this.currentPan }}
                        <span class="color-yellow-a"
                            >{{ this.currentIteration }}º</span
                        >
                        <span class="action_btns" *ngIf="actions">
                            <div class="action" *ngFor="let action of actions">
                                <span *ngIf="action.cmd == 800 && selectedRequest?.blockchain" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.blockchain ? 'enabled' : 'disabled'">
                                </span>
                                <span *ngIf="action.cmd == 801 && selectedRequest?.satellite" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.satellite ? 'enabled' : 'disabled'">
                                </span>
                                <span *ngIf="action.cmd == 802 && selectedRequest?.list" (click)="additionalCmd(action.cmd, selectedRequest.id)">
                                    <img class="pngImg" src="{{apiUrl + action['icon:']}}" alt="" [ngClass]="selectedRequest.list ? 'enabled' : 'disabled'">
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
                
                <div class="battery color-yellow-a" *ngIf="batteryLevel && showMenu">
                    <img class="battery_icon" src="../../../../assets/images/battery.svg"> {{batteryLevel}}%
                </div>
                <select
                            (change)="changeDropdown($event.target.value)"
                            [value]="selectedDropdownValue"
                            name="id"
                            class="custom-select ce_simple bs-select border-green-select text-center m-0"
                        >
                            <option
                                *ngFor="
                                    let option of dropdownValue;
                                    let i = index
                                "
                                [value]="option.id"
                            >
                                {{ getFormattedDate(option.time) }}
                            </option>
                        </select>
                        <div class="add_circle_a ml-auto">
                            <!-- <button
                                class="rounded-circle bg-green-c text-white"
                                (click)="showModal(widget)"
                            ></button> -->
                            <fap-round-button [backgroundColor]="'tertiary'" (clicked)="showModal(widget)"></fap-round-button>
                        </div>
            </div>
        </div>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #addEditWidgetModal
    [titleColor]="'#ffffff'"
    inputTitle="Crop eye"
    class="crop_modal"
>
    <div class="md_content">
        <h4>{{"widget.acquisitionList" | translate}}</h4>
        <div class="aq_list">
            <div class="aq_li" *ngFor="let list of acquisitionList; let idx = index">
                <div class="date">
                    {{ getFormattedDate(list.time) }}
                </div>
                <div class="user">
                  
                    <ng-container *ngFor="let user of personList">
                      <span *ngIf="user?.user">
                          <span *ngIf="user?.user?.id == list?.user">
                            <span class="picture" *ngIf="user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="{{ mediaUrl + user?.picture}}" alt="profile picture" />
                            </span>
                            <span class="picture" *ngIf="!user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="../../../../assets/images/people_placeholder.png" alt="profile picture" />
                            </span>
                            <b *ngIf="list.user === 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</b>
                            <span *ngIf="list.user != 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</span>
                            </span
                        >
                        </span>
                    </ng-container>
                </div>
                <div class="status">
                    <input
                        type="checkbox"
                        (change)="statusChange(list)"
                        [value]="list.accepted"
                        [checked]="list.accepted"
                        [disabled]="
                            !globalRegistry.userData.profile.role == 1000
                        "
                    />
                    <span></span>
                </div>
                <div
                    class="delete"
                    *ngIf="
                        globalRegistry.userData.profile.role == 1000 ||
                        globalRegistry.userData.profile.id == list.user
                    "
                >
                    <span style="cursor: pointer">
                        <em class="material-icons delete-icon" (click)="removeItem(list.id)"
                            >delete</em
                        >
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex p-1 bgc row">
            <form
                class="form-group d-flex w-100 image_form"
                [formGroup]="imageForm"
            >
                <mat-form-field class="zoom">
                    <mat-label>{{"widget.zoom" | translate}}</mat-label>
                    <mat-select required formControlName="zoom">
                        <div class="slct scroll_wrap">
                            <mat-option
                                *ngFor="let zoom of zoomLevels"
                                [value]="zoom"
                                style="text-transform: capitalize"
                            >
                                <span class="small_text">{{ zoom }}</span>
                            </mat-option>
                        </div>
                    </mat-select>
                    <span class="unit small"><i class="fa fa-times"></i></span>
                </mat-form-field>
                <mat-form-field class="pan" *ngIf="this.selectedType != 30">
                    <mat-label>{{"widget.pan" | translate}}</mat-label>
                    <mat-select required formControlName="pan" [(value)]="selected2">
                        <div class="slct scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let pan of panLevels
                                "
                                [value]="pan"
                                style="text-transform: capitalize"
                            >
                                <span class="small_text">{{ pan }}</span>
                            </mat-option>
                        </div>
                    </mat-select>
                    <span class="unit">°</span>
                </mat-form-field>
                <mat-form-field class="tilt">
                    <mat-label>{{"widget.tilt" | translate}}</mat-label>
                    <input matInput placeholder="Tilt" type="number" (blur)="restrict($event)" formControlName="tilt">
                    <span class="unit">°</span>
                </mat-form-field>
                <div class="add_circle_a ml-auto">
                    <button
                        class="rounded-circle bg-green-c text-white"
                        (click)="submit()"
                    ></button>
                </div>
            </form>
        </div>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    #addEditWidgetModal
    [titleColor]="'#ffffff'"
    inputTitle="Crop eye"
    class="crop_modal"
    [showFooter]="false"
>
    <div class="md_content">
        <h4>{{"widget.acquisitionList" | translate}}</h4>
        <div class="aq_list">
            <div class="aq_li" *ngFor="let list of acquisitionList; let idx = index">
                <div class="date">
                    {{ getFormattedDate(list.time) }}
                </div>
                <div class="user">
                  
                    <ng-container *ngFor="let user of personList">
                      <ng-container *ngIf="user?.user">
                          <span *ngIf="user?.user?.id == list?.user">
                            <span class="picture" *ngIf="user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="{{mediaUrl + user?.picture}}" alt="profile picture" />
                            </span>
                            <span class="picture" *ngIf="!user?.picture">
                              <img style="max-width: 100%; display: inline-block;" src="../../../../assets/images/people_placeholder.png" alt="profile picture" />
                            </span>
                            <b *ngIf="list.user === 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</b>
                            <span *ngIf="list.user != 1">{{ user?.firstName }} {{ user?.lastName }} {{ user?.first_name }} {{ user?.last_name }}</span>
                            </span
                        >
                        </ng-container>
                    </ng-container>
                </div>
                <div class="status">
                    <input
                        type="checkbox"
                        (change)="statusChange(list)"
                        [value]="list.accepted"
                        [checked]="list.accepted"
                        [disabled]="
                            !globalRegistry.userData.profile.role == 1000
                        "
                    />
                    <span></span>
                </div>
                <div
                    class="delete"
                    *ngIf="
                        globalRegistry.userData.profile.role == 1000 ||
                        globalRegistry.userData.profile.id == list.user
                    "
                >
                    <span style="cursor: pointer">
                        <em class="material-icons delete-icon" (click)="removeItem(list.id)"
                            >delete</em
                        >
                    </span>
                </div>
            </div>
        </div>
        <a class="btn mt-2 ml-0" target="_blank" [href]="images[0]?.image" style="line-height:20px">View</a>
    </div>
</fap-modal>