import { Routes } from '@angular/router';

import { FapUserProfileContainerComponent } from './containers/fap_user-profile-container/fap_user-profile-container.component';

export const userRoutes: Routes = [
    {
        path:       '',
        component:  FapUserProfileContainerComponent
    }
];
