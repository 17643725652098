<div>
    <div>{{ productName }}</div>
    <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
        <div>
            <span 
                class="product_type_name" 
                *ngIf="type?.id === productType && (type?.name_t || type?.name)" 
                [style.background]="getBgColor(type.color)"
                >
                {{ type?.name_t ? (type.name_t | getTranslation : globalRegistry?.systemData?.translationsMap : langString) : type?.name }}
            </span>
            <ng-container *ngIf="type?.id !== productType">
                <ng-container *ngFor="let childUnit of type?.children">
                    <span
                        class="product_type_name"
                        *ngIf="childUnit?.id === productType && (type?.name_t || type?.name)"
                        [style.background]="getBgColor(childUnit.color)"
                        >{{
                            childUnit?.name_t
                                ? (childUnit.name_t
                                  | getTranslation
                                      : globalRegistry?.systemData
                                            ?.translationsMap
                                      : langString)
                                : childUnit?.name
                        }}</span
                    >
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
