import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';
import { PlanTypeModel } from '../../../../../core/models/company/plan.type.model';

@Component({
    templateUrl: './activities-add-edit-container.component.html',
})
export class ActivitiesAddEditContainerComponent implements OnInit, OnDestroy {
    public isEditMode = false;
    public isPreviewMode = false;
    public farms: Array<FarmModel> = [];
    public equipments: Array<EquipmentModel> = [];
    public farmId: number = null;
    public activityId: number = null;
    public activity: ActivityModel = null;
    private subscriptions: Array<Subscription> = [];
    public activities: Array<ActivityModel> = [];
    public activityTypes: Array<ActivityTypeModel> = [];
    public planTypes: Array<PlanTypeModel> = []
    public activityGroups: Array<ActivityGroupModel> = [];
    public products = [];
    public getMore = true;
    public nextToken: { offset: number; limit: number; group: string; search: string} = null;
    public limit = 20;
    public nextActivityToken: { offset: number; limit: number } = null;

  
    constructor(
        public activityService: ActivityService,
        public navService: NavService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public globalRegistry: GlobalRegistryService,
        public farmService: FarmService,
        public mapService: MapService,
    private toastyService: ToastrService,
    public translate: TranslateService,
    public equipmentsService: EquipmentService,
    public typeService: TypesService,
    public stockService: StockService,
    public cacheService: CacheResolverService
    ) {
      this.subscriptions.push(
        combineLatest([
          activatedRoute.params,
        ]).subscribe(([params]: [Params]) => {
          this.farms = this.globalRegistry.systemData.farms;
          this.navService.createMod.next(true);
          if (params["activityId"] !== undefined) {
            this.activityId = Number(params["activityId"]);
            this.navService.createMod.next(false);
            this.navService.editMod.next(true);
            this.loadActivity(this.activityId);
          }
        })
      );
      this.subscriptions.push(this.equipmentsService.getEquipments().pipe(take(1)).subscribe((equipments: ResponseModel<EquipmentModel[]>): void => {
        this.equipments = equipments.model;
        console.log(this.equipments);
    }));
    this.typeService.getActivityTypes().subscribe((types:ResponseModel<ActivityTypeModel[]>) => {
      this.activityTypes = types.model;
      console.log(this.activityTypes);
  })
    }

    public deleteActivity(id): void {
      console.log(id);
      this.activityService
        .deleteActivity(Number(id))
        .pipe(
          tap(
            () => {
              // this.getActivities();
              this.activityService.getActivitiesList.next();
              this.globalRegistry.addLocalRelationsToService.next(this.activityId);
              this.toastyService.success(this.translate.instant("activity.activityDeletedSuccessfully"));
            },
            () =>
              this.toastyService.error(
                this.translate.instant("activity.activityDeleteError")
              )
          )
        )
        .subscribe();
    }

    private loadActivity(activityId): void {
      this.activityService
          .getActivity(activityId)
          .pipe(take(1))
          .subscribe((activity: ResponseModel<ActivityModel>): void => {
              this.activity = activity.model;
          });
  }

    ngOnInit(): void {
      this.navService.editFarm.next(true);
      this.getTypes();
      this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/activities']);
                this.navService.emitCancel.next(false);
            }
        });
        this.getProducts();
        this.getPlanTypes();
        this.getActivities();
    }

    public getPlanTypes() {
      this.typeService.getPlanTypes().subscribe((types:ResponseModel<PlanTypeModel[]>) => {
        this.planTypes = types.model;
    })
    }

    filterActivities(value) {
      const url = this.activityService.getUrl('activities/');
      const params = { search: value }
      this.subscriptions.push(this.activityService.getActivities(params).subscribe(data => {
        this.cacheService.delete(url+'search='+params.search)
        console.log(data);
        this.nextActivityToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.activities = data.body.results;
        if(this.nextActivityToken) this.getMore = true
      }))
  }

    public getActivities(): void {
    const subscription = this.activityService.getActivities({ limit: this.limit }).subscribe(
      (activities: ResponseModel<ActivityModel[]>) => {
        this.activities = activities.body.results;
        this.nextActivityToken = activities.body.next ? this.globalRegistry.getQueryStringParams(activities.body.next.split('?')[1]) : null;
        const urlWithLimit = this.activityService.getUrl('activities/') + 'limit=' + this.limit;
        this.cacheService.delete(urlWithLimit);
      },
      (error) => {
        console.error('Error fetching activities:', error);
        this.toastr.error(error.error.results.error);
      }
    );

    this.subscriptions.push(subscription);
  }

    public getProducts() {
      const url = this.stockService.getUrl('products/');
      this.subscriptions.push(this.stockService.getProducts({ limit: 200, group: 'EQUIPMENT' }).subscribe(data => {
        this.cacheService.delete(url+'limit='+200+'&group=EQUIPMENT');
        console.log(data);
        this.products = data.body.results;
        this.nextToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
              if(this.nextToken) this.getMore = true;
      }))
    }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.scrolledDown();
    }
}

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrolledDown() {
    if(this.getMore) {
      this.nextToken &&
        this.subscriptions.push(this.stockService.getProducts(this.nextToken).subscribe((data) => {
          console.log(data);
          if(this.products) {
            this.products = [...this.products, ...data.body.results];
          } else {
            this.products = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextToken.offset != params['offset']) {
            this.nextToken = {offset: params['offset'], limit: params['limit'], group: 'EQUIPMENT', search: params['search']};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

  filterProduct(value) {
    this.subscriptions.push(this.stockService.getProducts({search: value, group: "EQUIPMENT"}).subscribe(data => {
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.products = data.body.results;
      if(this.nextToken) this.getMore = true;
    }))
  }

    private getTypes(): void {
      this.typeService
        .getActivityTypes()
        .subscribe((response: ResponseModel<ActivityTypeModel[]>) => {
          this.activityTypes = response.model;
        });
    }

    private getGroups(): void {
      this.activityService.getActivityGroups().subscribe((response: ResponseModel<ActivityGroupModel[]>) => {
        this.activityGroups = response.model
      })
    }

    public ngOnDestroy(): void {
      this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  public submitActivity(activity: ActivityModel): void {
    console.log(activity);
    this.activityService.createActivity(activity).subscribe(
      () => {
        this.activityService.getActivitiesList.next();
        this.globalRegistry.addLocalRelationsToService.next(this.activityId);
        this.toastyService.success(
          this.translate.instant("activity.activityCreatedSuccessfully")
        );
        // this.getActivities();
      },
      () => {
        this.toastyService.error(
          this.translate.instant("activity.activityCreatedError")
        );
      }
    );
  }

  scrolledDownActivities() {
    const url = this.activityService.getUrl('activities/');
    
    console.log('activities scrolled');
    if(this.getMore) {
      this.nextActivityToken &&
        this.subscriptions.push(this.activityService.getActivities(this.nextActivityToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.nextActivityToken.limit+'&offset='+this.nextActivityToken.offset);
          console.log(data);
          if(this.activities) {
            this.activities = [...this.activities, ...data.body.results];
          } else {
            this.activities = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextActivityToken.offset != params['offset']) {
            this.nextActivityToken = {limit: params['limit'], offset: params['offset']};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

// this.subscriptions.push(this.activityService.updateActivity(activity.id, activity).subscribe((): void => {
//   this.toastr.success(this.translateService.instant('activity.activityUpdatedSuccessfully'));
//   this.loadWidgets();
// }));
  public updateActivity(activity: ActivityModel): void {
    console.log(activity);
    
    this.activityService.updateActivity(activity.id, activity)
      .pipe(
        tap(
          () => {
            // this.getActivities();
            this.activityService.getActivitiesList.next();
            this.globalRegistry.addLocalRelationsToService.next(this.activityId);
            this.toastyService.success(
              this.translate.instant("activity.activityUpdatedSuccessfully")
            );
          },
          () =>
            this.toastyService.error(
              this.translate.instant("activity.activityUpdateError")
            )
        )
      )
      .subscribe();
  }
}
