import {
    Component,
    OnInit
} from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './insects-edit-container.component.html',
    styleUrls: ['./insects-edit-container.component.scss']
})
export class InsectsEditContainerComponent implements OnInit {

    constructor(private mapService: MapService) {
    }

    public ngOnInit(): void {
        this.mapService.hideMap();
    }
}
