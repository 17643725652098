<div class="w-100 resource-table">
    <table>
        <thead>
            <tr>
                <th>{{ 'ID' }}</th>
                <th>{{ 'notes.name' | translate }}</th>
                <th>{{ 'attribute.value' | translate }}</th>
                <th><fap-round-button (clicked)="createNewChoice()"></fap-round-button></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let choice of choices">
                <td>
                    {{choice?.id}}
                </td>
                <td>{{ choice?.name }}</td>
                <td>{{choice?.input}}</td>
                <td>
                    <div class="d-flex">
                        <i class="fa fa-edit center f-20 mr-2" (click)="editChoice(choice)"></i>
                        <i class="fa fa-trash center f-20" (click)="deleteChoice(choice.id)"></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<fap-modal #addEditChoicePopup [inputTitle]="'forms.addEditFormFields' | translate" [showFooter]="false">
    <form [formGroup]="addEditChoiceForm" class="col-12 p-0">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
                    <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput>
                    <mat-error *ngIf="addEditChoiceForm.controls['name'].errors?.required">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'ID' }}</mat-label>
                    <input matInput formControlName="id">
                  </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'attribute.value' | translate }}</mat-label>
                    <input matInput formControlName="input" type="number">
                  </mat-form-field>
            </div>
            <div class="col-6">
                <div style="margin-top: 30px">
                    <input id="default" type="checkbox" formControlName="default">
                    <label for="default" style="margin-left: 5px">{{ 'Default' }}</label>
                </div>
            </div>
        </div>
        
            <div class="action_btns d-flex justify-content-end mt-3">
                <button class="btn mr-2" (click)="addChoice()">{{"translations.save" | translate}}</button>
                <button class="btn" (click)="addEditChoicePopup.hideModal()">{{"translations.cancel" | translate}}</button>
            </div>
        
    </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedChoice)" 
        [prefix]="'FIELD_TYPE_'" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>