import { ResponseModel } from './../../../models/response.model';
import { FapRestBaseService } from './../../../base/fap-rest-base.service';
import { NoteModel } from './../../../models/notes/note.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { take } from 'rxjs/operators';
import { CacheResolverService } from '../cache/cache-resolver.service';


@Injectable()
export class NotesService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, _http, '/farm/notes/');
    }

    public getNotesList: Subject<void> = new Subject<void>();

    public notesCoords: BehaviorSubject<any> = new BehaviorSubject<any[]>([]);

    getUrl(slug) {
        return this.url+slug
    }

    public updatedItem;
    public createdItem;
    public deletedItem;

    public getNotes(params?: {}): Observable<ResponseModel<NoteModel[]>> {
        return this.mapRequest<NoteModel[]>(
            this._http.get(this.url,
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            NoteModel,
            true,
            null,
            null,
            true
        ).pipe(take(1));
    }

    public getWidgetNotes(params): Observable<ResponseModel<NoteModel[]>> {
        return this.mapRequest<NoteModel[]>(
            this._http.get(this.url+params,
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                }),
            NoteModel,
            true,
            null,
            null,
            true
        ).pipe(take(1));
    }

    public getNote(notesId: number): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + notesId + '/')
        return this.mapRequest<NoteModel>(
            this._http.get(this.url + notesId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public createNote(note: FormData ): Observable<ResponseModel<NoteModel>> {
        return this.mapRequest<NoteModel>(
            this._http.post(this.url,
                note,
                FapAPIRequestOptions.onlyTokenRequestOptions),
            NoteModel,
            false
        ).pipe(take(1));
    }

    public updateNote(noteId: number, note: FormData): Observable<ResponseModel<any>> {
        this.cacheService.delete(this.url + noteId + '/')
        return this.mapRequest<NoteModel>(
            this._http.put(this.url + noteId + '/',
                note,
                FapAPIRequestOptions.onlyTokenRequestOptions),
            false,
            false
        ).pipe(take(1));
    }

    public deleteNote(noteId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + noteId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

}
