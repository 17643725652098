import { FapBaseModel } from '../../base/fap-base-model';

export class SensorActionModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'start',
        'end',
        'minute',
        'hour',
        'day',
        'month',
        'dweek',
        "duration",
        "enabled",
        "data",
        "sensor",
        "type"
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public start: any;
    public end: any;
    public minute: any;
    public hour: any;
    public day: any;
    public month: any;
    public dweek: any;
    public duration: any;
    public enabled: any;
    public data: any;
    public sensor: any;
    public type: any;
}
