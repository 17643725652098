import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { FapAPIRequestOptions } from "../fap-api-request.options";
import { ResponseModel } from "../../../models/response.model";
import { FapRestBaseService } from "../../../base/fap-rest-base.service";
import { DrillParams } from "../../../interfaces/widget/drill-info.interface";
import { FapBaseModel } from "../../../base/fap-base-model";

export class UnitTypeModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'unit',
        'color',
        'unit_t',
        'aggrec_func',
        'icon',
        'locked',
        'name_t'
    ];
    public static requiredFields: Array<string> = [
        'id',
    ];
    public static extendedFields: {} = { };
    public id: number;
    public name: string;
    public unit: number;
    public color: string;
    public unitT: string;
    public aggrecFunc: string;
    public icon?: string;
    public locked: boolean;
    public nameT: string;
}

@Injectable()
export class UnitTypeService extends FapRestBaseService {
  public drill: Subject<DrillParams> = new Subject<DrillParams>();

  constructor(tosterService: ToastrService, private _http: HttpClient) {
    super(tosterService, _http, "/type/");
  }

  public getObjectTypes(params?: {}): Observable<ResponseModel<UnitTypeModel[]>> {
    return this.mapRequest<UnitTypeModel[]>(
      this._http.get(this.url + "unit/", {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params,
      }),
      UnitTypeModel,
      true
    ).pipe(take(1));
  }
}
