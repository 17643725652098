<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1 class="pool_title">
                <ng-container *ngIf="widget['nameT']"><span [title]="getTranslation(widget['nameT'])">{{ getTranslation(widget["nameT"]) }}</span></ng-container>
                <ng-container *ngIf="widget['name_t']"><span [title]="getTranslation(widget['name_t'])">{{ getTranslation(widget["name_t"]) }}</span></ng-container>
                <span class="edit" (click)="editForm();$event.stopPropagation()"><i class="fa fa-edit"></i></span>
            </h1>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="validateDelete()"><span>{{"widget.delete" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-content" (click)="goToDeviceView()">
        <div class="pool_top">
            <div class="pool_img" style="min-height: 244.4px;">
                <img *ngIf="imageData?.file" [src]="mediaUrl + imageData?.file" alt="">
                <span class="empty_border"></span>
            </div>
            <div class="pool_weather">
                <ng-container *ngIf="weatherData?.forecast">
                    <ng-container *ngFor="let item of getObjectValues(weatherData?.forecast) | slice:0:1">
                        <div class="current_weather" >
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <div class="icon">
                                    <img [src]="mediaUrl + item?.weather_code?.img" alt="">
                                </div>
                                <div class="text">
                                    <h6 class="clearfix m-0">
                                        <span class="value">{{ item.temperature.max }}</span> <span class="unit">{{ item?.temperature?.unit | getUnitType: unitTypesMap }}</span>
                                    </h6>
                                </div>
                            </div>
                            <div class="w-100 pad10">
                                <div class="rain_and_wind d-flex justify-content-between">
                                    <div class="temp_value rw">
                                        <div class="main_val clearfix" *ngIf="item?.rainfall.value && item?.rainfall.value !== 0">
                                            <span class="value">{{ item.rainfall.value }}</span>
                                            <span class="unit">{{ item?.rainfall?.unit | getUnitType: unitTypesMap }}</span>
                                        </div>
                                    </div>
                                    <div class="temp_value rw">
                                        <div class="main_val cleafix" *ngIf="item?.rainfall.value && item?.wind_speed.value !== 0">
                                            <span class="value">{{ item.wind_speed.value }}</span>
                                            <span class="unit">{{ item?.wind_speed?.unit | getUnitType: unitTypesMap }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!weatherData?.forecast">
                    <div class="current_weather not_available">
                        {{ 'widget.weatherInfoNotAvailable' | translate }}
                    </div>
                </ng-container>
                <div class="wgrids">
                    <div class="wgrid" (click)="goToDeviceView(temperatureData?.element)" [ngClass]="{'inactive': !temperatureData?.element?.value_float}">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/hot-water.svg" alt="">
                        </div>
                        <div class="text" *ngIf="temperatureData?.element?.value_float">
                            <div class="value">
                                {{temperatureData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{temperatureData?.element?.unit?.unit}}
                            </div>
                        </div>
                        <div class="text" *ngIf="!temperatureData?.element?.value_float">
                            N/A
                        </div>
                    </div>
                    <div class="wgrid" [ngClass]="{'inactive': !lidData?.element?.value_float}">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/light-bulb.png" alt="">
                        </div>
                        <div class="text" *ngIf="lidData?.element?.value_float">
                            <div class="value">
                                {{lidData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{lidData?.element?.unit?.unit}}
                            </div>
                        </div>
                        <div class="text" *ngIf="!lidData?.element?.value_float">
                            N/A
                        </div>
                    </div>
                    <div class="wgrid" [ngClass]="{'inactive': !PoolightsData?.element?.value_float}">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/filter.png" alt="">
                        </div>
                        <div class="text" *ngIf="PoolightsData?.element?.value_float">
                            <div class="value">
                                {{PoolightsData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{PoolightsData?.element?.unit?.unit}}
                            </div>
                        </div>
                        <div class="text" *ngIf="!PoolightsData?.element?.value_float">
                            N/A
                        </div>
                    </div>
                    <div class="wgrid" [ngClass]="{'inactive': !electrolysisData?.element?.value_float}">
                        <div class="icon">
                            <img src="../../../../../../assets/icons/virus.png" alt="">
                        </div>
                        <div class="text" *ngIf="electrolysisData?.element?.value_float">
                            <div class="value">
                                {{electrolysisData?.element?.value_float}}
                            </div>
                            <div class="unit">
                                {{electrolysisData?.element?.unit?.unit}}
                            </div>
                        </div>
                        <div class="text" *ngIf="!electrolysisData?.element?.value_float">
                            N/A
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pool_bottom">
            <div class="circle_bar">
                <div class="item_timestamp">
                    {{ phTimestamp }}
                </div>
                <div class="trigger_wizard" (click)="triggerWorkFlow()" *ngIf="!(phData?.element?.value_float >= 6 && phData?.element?.value_float <= 8)">
                    <img src="../../../../assets/icons/warning.svg" alt="warning">
                </div>                
                <circle-progress-bar [progressValue]="phData?.element?.value_float" [type]="'ph'" [minValue]="0" [maxValue]="8" [fillColorRange]="{ min: 0, max: 8 }"></circle-progress-bar>
                <div class="text">PH</div>
            </div>
            <div class="circle_bar">
                <div class="item_timestamp">
                    {{ redoxTimestamp }}
                </div>
                <div class="trigger_wizard" (click)="triggerWorkFlow()" *ngIf="redoxData?.element?.value_float < 700">
                    <img src="../../../../assets/icons/warning.svg" alt="warning">
                </div>
                <circle-progress-bar [progressValue]="redoxData?.element?.value_float" [type]="'redox'" [minValue]="0" [maxValue]="9000" [fillColorRange]="{ min: 0, max: 9000 }"></circle-progress-bar>
                <div class="text">Redox</div>
            </div>
        </div>
    </div>
</div>

<fap-modal #addEditPoolModal
[inputTitle]="widget?.nameT ? getTranslation(widget?.nameT) : widget?.name" [inputIsLarge]="true" [showFooter]="false">
<div class="minus_margin">
    <fap-attributes
        [entityRelation]="entityRelation"
        [entityId]="entityInfo?.id"
        [entityInfo]="entityInfo"
        [objectId]="objectId"
        (closeModal)="hideModal()"
        (formUpdate)="onUpdateAction($event)"
        [type]="'edit'"
        [isDynamic]="isDynamic">
    </fap-attributes>
</div>
</fap-modal>

<fap-modal #criticalDeleteModal [inputTitle]="'confirmCriticalOperation' | translate" [inputIsLarge]="true" [showFooter]="false">
    <fap-critical-operation 
        [entityName]="widget?.nameT ? getTranslation(widget?.nameT) : 'this pool'"
        (deleteEmit)="deletePool()"
    ></fap-critical-operation>
</fap-modal>