<div class="resources-table">
    <table class="w-100">
        <thead>
            <th>Resources</th>
            <th class="quantity-header">Quantity</th>
            <th class="add-resource-header">
                <i class="fa fa-plus align-self-center f-20"></i>
            </th>
        </thead>
        <tbody>
            <tr>
                <td class="p-1 bold">
                    Nicogan
                </td>
                <td class="p-1">
                    23g
                </td>
                <td>
                    <div class="d-flex text-center">
                        <i class="fa fa-edit align-self-center f-20"></i>
                        <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="p-1 bold">
                    Gasoline
                </td>
                <td class="p-1">
                    100L
                </td>
                <td>
                    <div class="d-flex text-center">
                        <i class="fa fa-edit align-self-center f-20"></i>
                        <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="p-1 bold">
                    Water
                </td>
                <td class="p-1">
                    1000L
                </td>
                <td>
                    <div class="d-flex text-center">
                        <i class="fa fa-edit align-self-center f-20"></i>
                        <i class="fa fa-trash align-self-center ml-2 f-20"></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
