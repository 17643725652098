import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../services/nav.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'product-tag',
  templateUrl: './product-tag.component.html',
  styleUrls: ['./product-tag.component.scss']
})
export class ProductTagComponent implements OnInit, OnDestroy {

  @Input() productName: string;
  @Input() productType: any;
  private subscriptions: Subscription[] = [];
  public langString: string;

  constructor(public globalRegistry: GlobalRegistryService, private navService: NavService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
  }

  getBgColor(colorCode) {
    if(!colorCode) {
      return
    }
    const hex = colorCode.slice(1);
    const rgb = parseInt(hex, 16);
    const red = (rgb >> 16) & 0xff;
    const green = (rgb >> 8) & 0xff;
    const blue = rgb & 0xff;
    const alpha = 0.3;
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return rgbaColor;
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }

}
