import { HttpHeaders } from '@angular/common/http';

export class FapAPIRequestOptions {

    public static get withTokenRequestOptions(): any {
        const headers: HttpHeaders = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json;q=0.9,*/*;q=0.8',
            authorization: 'Token ' + localStorage.getItem('auth_token'),
            'language': localStorage.getItem('language')
        });

        return {
            headers
        };
    }

    public static get withTokenRequestOptionsMultipart(): any {
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'multipart/form-data;boundary="boundary"',
            accept: '*/*',
            authorization: 'Token ' + localStorage.getItem('auth_token'),
            'language': localStorage.getItem('language')
        });

        return {
            headers
        };
    }

    public static get basicRequestOptions(): any {
        const headers: HttpHeaders  = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json',
            'language': localStorage.getItem('language')
        });

        return {
            headers
        };
    }

    public static get onlyTokenRequestOptions(): any {
        const headers: HttpHeaders = new HttpHeaders({
            authorization: 'Token ' + localStorage.getItem('auth_token'),
            'language': localStorage.getItem('language')
        });

        return {
            headers
        };
    }

    public static get withTokenHeaders(): any {
        const headers: HttpHeaders = new HttpHeaders({
            'content-type': 'application/json',
            accept: 'application/json;q=0.9,*/*;q=0.8',
            authorization: 'Token ' + localStorage.getItem('auth_token'),
            'language': localStorage.getItem('language')
        });
        return headers;
    }
}
