import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';

@Component({
  templateUrl: './plan-container.component.html',
  styleUrls: ['./plan-container.component.scss']
})
export class PlanContainerComponent implements OnInit, OnDestroy {

  constructor(public mapService: MapService) { }

  ngOnInit(): void {
    this.mapService.hideMap();
  }

  ngOnDestroy(): void {
    this.mapService.showMap();
  }

}
