import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { UnitValueModel } from '../../../../../core/models/units/unit-value.model';
@Component({
    selector: 'fap-lot-field-card',
    templateUrl: './fap_lot-field-card.component.html',
    styleUrls: ['./fap_lot-field-card.component.scss']
})
export class FapLotFieldCardComponent implements OnChanges {

    @Input()
    public fieldName: string;

    @Input()
    public fieldSoilType: string;

    @Input()
    public fieldArea: UnitValueModel;

    @Input()
    public fieldPolygon: Array<Array<number>>;

    @Input()
    public fieldOwner: number;

    @Input()
    public fieldPreview: string;

    @Input()
    public parties: Array<PartyModel>;

    @Output()
    public deleteField: EventEmitter<void> = new EventEmitter();

    @Output()
    public editField: EventEmitter<void> = new EventEmitter();

    public ownerName: string;

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'parties') || Object.prototype.hasOwnProperty.call(changes, 'owner')) {
            if (this.parties && this.fieldOwner) {
                const ownerParty: PartyModel = this.parties.find((party: PartyModel): boolean => party.id === this.fieldOwner);
                this.ownerName = ownerParty ? ownerParty.name : null;
            } else {
                this.ownerName = null;
            }
        }
    }

    public getMapPreviewImgSrc(): string {
        if (!this.fieldPolygon) {
            return null;
        }
        let src = 'https://maps.googleapis.com/maps/api/staticmap?path=color:0x00000000|weight:5|fillcolor:0xFFC107|';
        src += this.fieldPolygon.map((point: Array<number>): string => point[1] + ',' + point[0]).join('|');
        src += '&size=200x150&maptype=hybrid&key=AIzaSyDGa4j5DNdO31vj5IEu_-zmjIGHUDLQxbI';
        return src;
    }
}
