import { Pipe, PipeTransform } from '@angular/core';
import { UnitModel } from '../models/units/unit.model';

@Pipe({
  name: 'getProductUnit'
})
export class getProductUnitPipe implements PipeTransform {
  public transform(product: any, unitsMap: any): string {

    if (!unitsMap || !product) {
      return "?"
    }

    let unitPack: UnitModel;
    let unit: UnitModel;

    unitPack = unitsMap.get(product?.pack_unit);
    unit = unitsMap.get(product?.unit);
    let result = '';

    if(product?.quantity_pack > 0) {
      if(product?.quantity_pack > 1) {
        result += `${product?.quantity_pack} `;
      } 
      result += unitPack?.shortName ? unitPack?.shortName : '';
    }

    result += ' ';

    if(product?.quantity === 1) {
      if(product?.quantity_pack > 0) {
        result += `${product?.quantity} `;
      }
    } else {
      result += `${product?.quantity} `
    }
    result += unit?.shortName ? unit?.shortName : '';
    return result ? result.trim() : '';
    }
  }
