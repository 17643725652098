<form [formGroup]="addEditTranslationForm">
    <div class="form-group mb-0">
      <div class="row align-items-baseline">
        <div class="col-12">
          <div class="input-group w-100">
            <div class="langs">
              <ng-container *ngFor="let language of languages">
                <div *ngIf="language.id === langString" class="w-100 current_language">
                  <mat-form-field class="w-100" cdkFocusInitial *ngIf="!isHTML">
                    <mat-label>{{ language.id }}</mat-label>
                    <input
                      matInput
                      [placeholder]="getPlaceholderTranslation('type', label, language.id)"
                      [formControlName]="language.id"
                      (focusout)="onFocusOut(language.id, $event)"
                    >
                  </mat-form-field>
                  <div class="w-100 pos_rel" *ngIf="isHTML">
                    <label class="col-form-label ">{{ language.id }}</label>
                    <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'en'">
                      <ngx-editor-menu [editor]="enEditor" [toolbar]="toolbar"></ngx-editor-menu>
                      <ngx-editor
                        [editor]="enEditor"
                        [(ngModel)]="enBody"
                        (ngModelChange)="onEditorContentChange('en')"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="false"
                        [placeholder]="'Type here...'"
                        style="height: 100%;"
                      ></ngx-editor>
                    </div>
                    <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'fr'">
                      <ngx-editor-menu [editor]="frEditor" [toolbar]="toolbar"></ngx-editor-menu>
                      <ngx-editor
                        [editor]="frEditor"
                        [(ngModel)]="frBody"
                        (ngModelChange)="onEditorContentChange('fr')"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="false"
                        [placeholder]="'Type here...'"
                        style="height: 100%;"
                      ></ngx-editor>
                    </div>
                    <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'ro'">
                      <ngx-editor-menu [editor]="roEditor" [toolbar]="toolbar"></ngx-editor-menu>
                      <ngx-editor
                        [editor]="roEditor"
                        [(ngModel)]="roBody"
                        (ngModelChange)="onEditorContentChange('ro')"
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="false"
                        [placeholder]="'Type here...'"
                        style="height: 100%;"
                      ></ngx-editor>
                    </div>
                  </div>
                  <div class="show_all_toggle">
                    <span (click)="toggleShowAllLanguages()">{{ 'translations.showAllLanguages' | translate }}</span>
                  </div>
                </div>
                <div class="w-100 other_languages" [class.show_all]="showAll === true">
                  <div *ngIf="language.id !== langString" class="w-100">
                    <mat-form-field class="w-100" *ngIf="!isHTML">
                      <mat-label>{{ language.id }}</mat-label>
                      <input matInput [placeholder]="getPlaceholderTranslation('type', label, language.id)" [formControlName]="language.id">
                    </mat-form-field>
                    <div class="pos_rel w-100" *ngIf="isHTML">
                      <label class="col-form-label ">{{ language.id }}</label>
                      <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'en'">
                        <ngx-editor-menu [editor]="enEditor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor
                          [editor]="enEditor"
                          [(ngModel)]="enBody"
                          (ngModelChange)="onEditorContentChange('en')"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="false"
                          [placeholder]="'Type here...'"
                          style="height: 100%;"
                        ></ngx-editor>
                      </div>
                      <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'fr'">
                        <ngx-editor-menu [editor]="frEditor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor
                          [editor]="frEditor"
                          [(ngModel)]="frBody"
                          (ngModelChange)="onEditorContentChange('fr')"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="false"
                          [placeholder]="'Type here...'"
                          style="height: 100%;"
                        ></ngx-editor>
                      </div>
                      <div class="NgxEditor__Wrapper" style="min-height: 180px;" *ngIf="language.id === 'ro'">
                        <ngx-editor-menu [editor]="roEditor" [toolbar]="toolbar"></ngx-editor-menu>
                        <ngx-editor
                          [editor]="roEditor"
                          [(ngModel)]="roBody"
                          (ngModelChange)="onEditorContentChange('ro')"
                          [ngModelOptions]="{standalone: true}"
                          [disabled]="false"
                          [placeholder]="'Type here...'"
                          style="height: 100%;"
                        ></ngx-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="w-100 mt-4 d-flex justify-content-end">
              <button class="btn mr-2" (click)="addTranslation()">{{ 'translations.save' | translate }}</button>
              <button class="btn" (click)="addTranslation()">{{ 'translations.cancel' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  