<div class="plan_item">
    <div class="crop_info">
        <div class="d-flex activities-card" *ngIf="activity">
            <!-- {{activity | json}} -->
            <ng-container *ngFor="let type of activityTypes">
                <div
                    class="abs_child"
                    [style.background]="type?.color"
                    *ngIf="type?.id === activity?.data?.type">
                </div>
            </ng-container>
            <div class="activity_info d-flex justify-content-between w-100">
                <div class="prod_info">
                    <h5>
                        {{ activity?.data?.name }}
                    </h5>
                    <div class="plan_name">
                        <!-- <span *ngIf="activity.children !== 0" (click)="getChildActivityPlan.emit(activity?.data?.id)">toggle</span> -->
                        <p class="m-0">
                            <ng-container *ngFor="let plan of globalRegistry?.systemData?.plans">
                                <ng-container *ngIf="plan?.id === activity?.data?.plan">
                                    <!-- <span class="select-option-info lots">{{ [globalRegistry.systemData.lots, plan?.lots] | getLotNames }}</span> -->
                                     <span>{{ plan.nameT ? getTranslation(plan.nameT) : plan.name }}</span>
                                </ng-container>
                            </ng-container>
                        </p>
                    </div>
                </div>
                <div class="split d-flex">
                    <div class="left_part">
                        <div class="bg-infos">
                            <ng-container *ngFor="let type of activityTypes">
                                <span
                                    class="type_color typebg"
                                    [style.background]="type?.color"
                                    *ngIf="type?.id === activity?.data?.type">
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="mid_part">
                        
                        <div class="sub_contents">
                            <div class="c2 p10">
                                <div class="activity_dates">
                                    <div class="d-flex">
                                        <div class="pl-2 act_date">
                                            <span class="select-option-date">
                                                {{ activity?.data?.real?.data?.started | date: "MMM dd, yyyy" }}
                                            </span>
                                        </div>
                                        <div class="pl-2 act_date">
                                            <span class="select-option-date">
                                                {{ activity?.data?.real?.data?.ended | date: "MMM dd, yyyy" }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="plan_dates">
                                    <div class="d-flex">
                                        <div class="pl-2 act_date">
                                            <span class="select-option-date">
                                                {{ activity?.data?.planned?.data?.started | date: "MMM dd, yyyy" }}
                                            </span>
                                        </div>
                                        <div class="pl-2 act_date">
                                            <span class="select-option-date">
                                                {{ activity?.data?.planned?.data?.ended | date: "MMM dd, yyyy" }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="c4 pc p10">
                                <div class="act_area">
                                    <p class="p-1 select-option-time">
                                        <img src="../../../../../../assets/images/area.png" alt=""/>
                                        {{ activity?.data?.area }} ha
                                    </p>
                                    <p class="pl-1 pt-1 mb-0 select-option-time">
                                        <img src="../../../../../../assets/images/distance.png" alt=""/>
                                        {{ activity?.data?.distance }} km
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mobl">
                <p class="mb-0 select-option-time">
                    <img src="../../../../../../assets/images/area.png" alt=""/>
                    {{ activity?.data?.area }} ha
                </p>
                <p class="pl-2 mb-0 select-option-time">
                    <img src="../../../../../../assets/images/distance.png" alt=""/>
                    {{ activity?.data?.distance }} km
                </p>
            </div> -->
        </div>
    </div>
</div>