<div class="d-flex mb-3">
    <h2 class="header-title mr-4">{{ 'attribute.attributes' | translate }}</h2>
    <div class="d-flex">
        <div class="btn btn-success d-flex align-items-center px-2 mr-4" (click)="addAttributeModal()">
            <i class="material-icons mr-1">add</i>
            {{ 'attribute.addAttribute' | translate }}
        </div>
    </div>
</div>
<div class="mb-5 pb-5">
    <div class="card">
        <table mat-table [dataSource]="attributes" class="mat-elevation-z8">
            <h2 class="card-header-title mr-4">
                <i class="fa fa-plus ml-2 f-20"></i>
            </h2>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'attribute.name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
            </ng-container>

            <ng-container matColumnDef="mandatoryFor">
                <th mat-header-cell *matHeaderCellDef> {{ 'attribute.mandatoryFor' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngFor="let mandatoryEntity of element?.mandatoryFor; let idx=index">
                        {{ ('attribute.' + mandatoryEntity) | translate }}{{ idx < element.mandatoryFor.length - 1 ? ', ' : '' }}
                    </ng-container>    
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'attribute.actions' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <div *ngIf="!element.isDefault" class="attribute-actions">
                        <i class="fa fa-edit center f-20" (click)="editAttribute(element);$event.stopPropagation()"></i>
                        <i class="fa fa-trash center ml-2 f-20"
                            [ngClass]="{'disabled': element.isUsed}"
                            [title]="(element.isUsed ? 'attribute.attributeIsUsed' : '') | translate"
                            (click)="onDeleteAttribute(element)"></i>
                    </div>
                    <div *ngIf="element.isDefault">
                        {{ 'attribute.defaultAttribute' | translate }}
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <fap-modal #addEditAttributeModal
        [inputTitle]="(selectedAttribute ? 'attribute.editAttribute' : 'attribute.createAttribute') | translate">
        <fap-add-edit-attribute
            [attribute]="selectedAttribute"
            (addedAttribute)="addEditAttributeModal.hideModal();selectedAttribute=$event"
            (updatedAttribute)="addEditAttributeModal.hideModal();selectedAttribute=$event">
        </fap-add-edit-attribute>
    </fap-modal>
</div>
