<fap-header *ngIf="shouldShowHeader">
</fap-header>
<div class="extra_pages" [ngClass]="{ 'no_items': !isLoading && items.length != 0 && items.length != 1}">
    <div
        class="overview_container"
        [ngClass]="{ 'desktop-view': width >= 768, 'mobile-view': width < 768 }"
    >

    <span class="loader" *ngIf="isLoading">
        <i></i>
    </span>

    <span *ngIf="!isLoading && items.length === 0" class="empty-list">
        {{'alergotel.noBioParticles' | translate}}
    </span>

        <div class="section_container" *ngIf="langString && !isLoading">
            <div
                *ngFor="let item of items; let i = index"
                class="tmb"
                [ngClass]="i === counter ? 'active' : 'inactive'"
            >
            <div class="title_section">
                <h3>{{ item?.name_t ? (item?.name_t[langString] || item?.name_t.en) : '' }}</h3>
                <div class="images_container" *ngIf="item.images && item.images.length">
                    <div class="image">
                        <img [src]="mediaUrl + item?.images[0]?.file" alt="" [ngClass]="img">
                    </div>
                </div>
            </div>
            <div class="sections" *ngIf="item.description.length">
                <div class="section" *ngFor="let section of item.description">
                    <h3>{{ section?.name_t ? (section?.name_t[langString] || section?.name_t.en) : '' }}</h3>
                    <div class="images_container" *ngIf="section.images.length">    
                        <div class="image">
                            <img [src]="mediaUrl + section?.images[0]?.file" alt="" [ngClass]="img">
                        </div>
                    </div>
                    <p>{{ section?.body_t ? (section?.body_t[langString] || section?.body_t.en) : '' }}</p>
                </div>
            </div>
            </div>
        </div>

        <div class="nav_btns" *ngIf="!isLoading && items.length != 0 && items.length != 1">
            <button (click)="showPreviousSlide()" class="cbtn prev" [ngClass]="{ 'hide': currentIndex === 0}"></button>
            <button (click)="showNextSlide()" class="cbtn next" [ngClass]="{ 'hide': currentIndex === items.length - 1}"></button>
        </div>
    </div>
</div>
