import {
    Component,
    OnInit
} from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './pathogens-container.component.html'
})
export class PathogensContainerComponent implements OnInit {

    constructor(private mapService: MapService) {
    }

    public ngOnInit(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
    }
}
