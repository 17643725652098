import { FapBaseModel } from '../../base/fap-base-model';
import { UnitValueModel } from '../units/unit-value.model';

export class TreatmentModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'aplicability',
        'product',
        'repause_min',
        'repause_max',
        'area',
        'crop_types',
        'quantity_min',
        'quantity_max',
        'dilution',
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
        quantityMin: UnitValueModel,
        quantityMax: UnitValueModel,
        dilution: UnitValueModel,
    };

    public id: number;
    public aplicability: string;
    public product: number;
    public repauseMin: number;
    public repauseMax: number;
    public area: number;
    public cropTypes: number[];
    public quantityMin: UnitValueModel = new UnitValueModel();
    public quantityMax: UnitValueModel = new UnitValueModel();
    public dilution: UnitValueModel = new UnitValueModel();
}
