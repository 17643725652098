import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { CompanyModel } from '../../../../../core/models/company/company.model';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../../../../core/models/response.model';
import { map, tap, take } from 'rxjs/operators';
import { CompanyInterface } from '../../../../../core/services/api/company/data/company.interface';
import { ToastrService } from 'ngx-toastr';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './company-container.component.html'
})
export class CompanyContainerComponent implements OnInit{

    public company$: Observable<CompanyModel>;

    constructor(private companyService: CompanyService,
                private toastyService: ToastrService,
                private mapService: MapService) {
    }

    public ngOnInit(): void {
        this.mapService.hideMap();
        this.company$ = this.companyService.getCompany().pipe(map((response: ResponseModel<CompanyModel>) => {
            return response.model;
        }));
    }

    public updateCompany(company: CompanyInterface): void
    {
        this.companyService.updateCompany(company).pipe(
            tap(() => {
                this.toastyService.success('Company updated succesfully');
            },
            () => {
                this.toastyService.error('Update failed');
            }),
            take(1)
        ).subscribe();
    }

}
