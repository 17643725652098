import { FapHelper } from './../../../../../core/heplers/fap.helper';
import { PeopleGroupModel } from './../../../../../core/models/groups/people-group.model';
import { PersonFarmInterface } from './../../../../../core/services/api/farm/data/person-farm.interface';
import { FarmService } from './../../../../../core/services/api/farm/farm.service';
import { GlobalRegistryService } from './../../../../../core/global-registry/global-registry.service';
import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    Params,
    ActivatedRoute,
    Router
} from '@angular/router';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import {
    tap,
    take
} from 'rxjs/operators';
import { ResponseModel } from '../../../../../core/models/response.model';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { PersonUserInterface } from '../../../../../core/services/api/company/data/person-user.interface';
import { AddUpdatePersonUserInterface } from '../../../../../core/interfaces/people/add-update-person-user.interface';
import { PeopleGroupService } from '../../../../../core/services/api/people-group/people-group.service';
import {
    Observable,
    combineLatest
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../../shared/services/nav.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    templateUrl: './edit-person-container.component.html'
})
export class EditPersonContainerComponent implements OnInit, OnDestroy {

    public farms: Array<FarmModel> = [];
    public filteredFarms: Array<FarmModel> = [];
    public personId: number;
    public person: PersonModel;
    public personCreated: boolean;

    constructor(public activatedRoute: ActivatedRoute,
                public partyService: PartyService,
                public globalRegistry: GlobalRegistryService,
                private toastyService: ToastrService,
                private translateService: TranslateService,
                private companyService: CompanyService,
                private peopleGroupService: PeopleGroupService,
                private farmService: FarmService,
                private mapService: MapService,
                private navService: NavService,
                private router: Router,
                public cacheService: CacheResolverService,
                public widgetService: WidgetsService
                ) {
        this.activatedRoute.params.subscribe((params: Params): void => {
            this.personId = parseInt(params['personId'], 10);
            if(this.personId) {
                this.navService.createMod.next(false);
                this.navService.editMod.next(true);
                this.getPerson();
            }
        });
    }

    public ngOnInit(): void {
        // this.mapService.hideMap();
        this.navService.editFarm.next(true);
        this.navService.submitFarm.next(true);
        this.navService.invalidForm.next(true);
        this.widgetService.setSize(5);
    }

    public ngOnDestroy(): void {
        this.navService.editFarm.next(false);
        this.widgetService.setSize(6);
    }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).pipe(tap(
            (): void => {
                this.globalRegistry.reloadParties();
                this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
            }, (): void => {
                this.toastyService.error(this.translateService.instant('people.party.errorDeletingParty'));
            }),
            take(1)).subscribe();
    }

    public deletePerson(id): void {
        console.log(id);
        this.companyService
          .deletePerson(Number(id))
          .pipe(
            tap(
              () => {
                this.globalRegistry.reloadPersons();
                this.toastyService.success(this.translateService.instant('people.userDeletedSuccesfully'));
              },
              () =>
                this.toastyService.error(
                  this.translateService.instant("Failed to delete person")
                )
            )
          )
          .subscribe();
      }

    public deleteGroup(groupId: number): void {
        this.peopleGroupService.deleteGroup(groupId).pipe(tap(
            (): void => {
                this.globalRegistry.reloadPeopleGroups();
                this.toastyService.success(this.translateService.instant('people.groups.deleteSucces'));
            }, (): void => {
                this.toastyService.error(this.translateService.instant('people.groups.deleteFailed'));
            }),
            take(1)).subscribe();
    }

    public deleteUser(userId: number): void {
        this.companyService.deleteUser(userId).subscribe((): void => {
            const navigateWrapper: () => void = (): void => {
                this.router.navigate(['/pages/people']);
            };
            this.toastyService.success(this.translateService.instant('people.groups.deleteUserSucces'));
            this.getPerson();
            this.globalRegistry.reloadPersons(navigateWrapper);
        }, (): void => {
            this.toastyService.error(this.translateService.instant('people.groups.deleteUserFailed'));
        });
    }

    public addUpdatePersonUser(personUser: AddUpdatePersonUserInterface): void {
        const navigateWrapper: () => void = (): void => {
            this.router.navigate(['/pages/people']);
        };
        const localAttributes = this.globalRegistry.getLocalAttrAndRelations(); 
        if (personUser.personFormIsDirty || localAttributes>0 ) {
            if (personUser.person.id === undefined && !this.personCreated) {
                this.companyService.addPerson(FapHelper.convertObjectToFormData(personUser.person))
                    .subscribe((person: ResponseModel<PersonModel>): void => {
                        this.toastyService.success(this.translateService.instant('people.groups.aditionPersonSucces'));
                        this.personCreated = true;
                        this.person = person.model;
                        this.personId = person.model.id;
                        this.globalRegistry.addLocalRelationsToService.next(this.personId);
                        const observables: Array<Observable<{}>> = [...this.getUserActiveFarmsObservables(personUser.farms, true),
                            ...this.getUserGroupsObservables(personUser.groups, true)];
                        combineLatest(observables).pipe(take(1)).subscribe((): void => {
                            this.globalRegistry.reloadPeopleGroups();
                        }, (): void => {
                            this.toastyService.error(this.translateService.instant('people.groups.updateFail'));
                        });
                        if (personUser.userFormIsDirty) {
                            personUser.user.profile = person.model.id;
                            this.addUser(personUser.user);
                        } else {
                            this.globalRegistry.reloadPersons(navigateWrapper);
                        }
                    }, (): void => {
                        this.toastyService.error(this.translateService.instant('people.groups.createFail'));
                    });
            } else {
                this.companyService.updatePerson(FapHelper.convertObjectToFormData(personUser.person), personUser.person.id)
                    .subscribe((person: ResponseModel<PersonModel>): void => {
                        this.globalRegistry.addLocalRelationsToService.next(personUser.person.id);
                        this.toastyService.success(this.translateService.instant('people.groups.updatePersonSucces'));
                        
                        this.person = person.model;
                        const observables: Array<Observable<{}>> = [...this.getUserActiveFarmsObservables(personUser.farms, false),
                            ...this.getUserGroupsObservables(personUser.groups, false)];
                        combineLatest(observables).pipe(take(1)).subscribe((): void => {
                            this.globalRegistry.reloadPersons(navigateWrapper);
                            this.globalRegistry.reloadPeopleGroups();
                        }, (): void => {
                            this.toastyService.error(this.translateService.instant('people.groups.updateFail'));
                        });
                        if (observables.length === 0) {
                            this.globalRegistry.reloadPersons(navigateWrapper);
                            this.globalRegistry.reloadPeopleGroups();
                        }
                    }, (): void => {
                        this.toastyService.error(this.translateService.instant('people.groups.updateFail'));
                    });
            }
        }
        if (personUser.userFormIsDirty) {
            if (!personUser.user.id && personUser.person.id) {
                this.addUser(personUser.user);
            }
            if (personUser.user.id && personUser.person.id) {
                this.updateUser(personUser.user);
            }
        }
    }

    private getUserActiveFarmsObservables(newActiveFarms: number[], newPerson: boolean): Array<Observable<{}>> {
        const activeFarmsObservables: Array<Observable<{}>> = [];
        if (newPerson) {
            newActiveFarms.forEach((activeFarm: number): void => {
                const personFarm: PersonFarmInterface = {
                    farmId: activeFarm,
                    personId: this.person.id
                };
                activeFarmsObservables.push(this.farmService.addPersonToFarm(personFarm));
            });
        } else {
            const farmsToRemove: number[] = this.person.activeFarms.filter((farmId: number): boolean => !newActiveFarms.includes(farmId));
            const farmsToAdd: number[] = newActiveFarms.filter((farmId: number): boolean => !this.person.activeFarms.includes(farmId));
            farmsToAdd.forEach((activeFarm: number): void => {
                const personFarm: PersonFarmInterface = {
                    farmId: activeFarm,
                    personId: this.person.id
                };
                activeFarmsObservables.push(this.farmService.addPersonToFarm(personFarm));
            });
            farmsToRemove.forEach((removeFarm: number): void => {
                const personFarm: PersonFarmInterface = {
                    farmId: removeFarm,
                    personId: this.person.id
                };
                activeFarmsObservables.push(this.farmService.removePersonFromFarm(personFarm));
            });
        }
        return activeFarmsObservables;
    }

    private getUserGroupsObservables(newGroups: number[], newPerson: boolean): Array<Observable<{}>> {
        const groupsObservables: Array<Observable<{}>> = [];
        if (newPerson) {
            newGroups.forEach((groupId: number): void => {
                const existingGroup: PeopleGroupModel = this.globalRegistry.systemData.peopleGroups
                    .find((group: PeopleGroupModel): boolean => group.id === groupId);
                groupsObservables.push(this.peopleGroupService.updateGroup({
                    id: existingGroup.id,
                    users: [...existingGroup.users, this.person.id]
                }));
            });
        } else {
            const groupsToRemove: number[] = this.person.groups.filter((groupId: number): boolean => !newGroups.includes(groupId));
            const groupsToAdd: number[] = newGroups.filter((groupId: number): boolean => !this.person.groups.includes(groupId));
            groupsToAdd.forEach((groupId: number): void => {
                const existingGroup: PeopleGroupModel = this.globalRegistry.systemData.peopleGroups
                    .find((group: PeopleGroupModel): boolean => group.id === groupId);
                groupsObservables.push(this.peopleGroupService.updateGroup({
                    id: existingGroup.id,
                    users: [...existingGroup.users, this.person.id]
                }));
            });
            groupsToRemove.forEach((groupId: number): void => {
                const existingGroup: PeopleGroupModel = this.globalRegistry.systemData.peopleGroups
                    .find((group: PeopleGroupModel): boolean => group.id === groupId);
                if (existingGroup) {
                    const updatedUserList: Array<number> = existingGroup.users.filter((user: number): boolean => user !== this.person.id);
                    groupsObservables.push(this.peopleGroupService.updateGroup({
                        id: existingGroup.id,
                        users: updatedUserList
                    }));
                }
            });
        }
        return groupsObservables;
    }

    private getPerson(): void {
        const url = this.companyService.getUrl('');
        this.cacheService.delete(url+'persons/' + this.personId + '/');
        this.companyService.getPerson(this.personId).subscribe((response: ResponseModel<PersonModel>): void => {
            this.person = response.model;
        });
    }

    private addUser(user: PersonUserInterface): void {
        this.companyService.addUser(user).subscribe((): void => {
            this.globalRegistry.reloadPersons();
            //this.toastyService.success(this.translateService.instant('people.groups.aditionPersonSucces'));
            this.router.navigate(['/pages/people']);
        },
        (): void => {
            this.toastyService.error('Addition failed');
        });
    }

    private updateUser(user: PersonUserInterface): void {
        const navigateWrapper: () => void = (): void => {
            this.router.navigate(['/pages/people']);
        };
        this.companyService.updateUser(user).subscribe((): void => {
            //this.toastyService.success(this.translateService.instant('people.groups.updatePersonSucces'));
            this.globalRegistry.reloadPersons(navigateWrapper);
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('people.groups.updateFail'));
        });
    }
}
