import { FapBaseModel } from '../../../base/fap-base-model';


export class WeatherDayForecastModel extends FapBaseModel {
    public static fields: Array<string> = [
        'date',
        'humidity',
        'precip',
        'temperature_avg',
        'temperature_max',
        'temperature_min',
        'wind_speed'
    ];

    public static requiredFields: Array<string> = [];

    public static extendedFields: {} = { };

    public date: string;
    public humidity: number;
    public precip: number;
    public temperatureAvg: number;
    public temperatureMax: number;
    public temperatureMin: number;
    public windSpeed: number;
}
