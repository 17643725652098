import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fap-critical-operation',
  templateUrl: './critical-operation.component.html',
  styleUrls: ['./critical-operation.component.scss']
})
export class CriticalOperationComponent {

  @Input() public entityName: string = null;
  @Output() public deleteEmit: EventEmitter<any> = new EventEmitter<any>();
  public showOkButton: boolean = false;
  public inputValue = '';

  constructor() {}

  validateInput(text) {
    const expectedText = 'delete';
    console.log(text);
    if (text === expectedText) {
      this.showOkButton = true;
    } else {
      this.showOkButton = false;
    }
  }

  confirmDelete() {
    if(this.showOkButton) {
      this.deleteEmit.emit();
      this.inputValue = '';
    } else {
      console.error('Please type "delete" in order to continue');
    }
  }
}
