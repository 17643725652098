<ng-container *ngIf="html?.html">
    <div class="custom_html" [innerHTML]="transformedHtml"></div>
    <div class="btns">
        <button class="btn" (click)="hideModal.emit()">{{'alergotel.close' | translate}}</button>
    </div>
</ng-container>
<ng-container *ngIf="workflow && !html">
    <fap-attributes
        [isLoading]="isLoading"
        [entityRelation]="'activity_list'"
        [entityId]="entityInfo?.id"
        [entityInfo]="entityInfo"
        [objectId]="objectId"
        (hideModal)="submitForm.emit()"
        (formUpdate)="onUpdateAction.emit()"
        [formDisplayType]="'workflow_modal'"
        [previousFormClick]="previousFormClick"
        [nextFormClick]="nextFormClick"
        [form]="workflow?.current_form"
        [workflow]="workflow"
        (nextButtonClick)="nextButtonClick.emit($event)"
        (prevButtonClick)="prevButtonClick.emit()"
        [isDynamic]="true"
        [errorFields]="errorFields"
    >
    </fap-attributes>
</ng-container>