import { Component } from '@angular/core';


@Component({
    templateUrl: './lot.component.html',
    styleUrls: ['./lot.component.scss']
})
export class LotComponent {

}
