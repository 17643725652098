import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { FieldModel } from '../../../models/field/field.model';
import { FieldInterface } from '../../../interfaces/field/field.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';


@Injectable()
export class FieldService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/farm/fields/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getFields(params?: {}): Observable<ResponseModel<FieldModel[]>> {
        return this.mapRequest<FieldModel[]>(
            this._http.get(this.url,
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            FieldModel,
            true
        ).pipe(take(1));
    }

    public getField(fieldId: number): Observable<ResponseModel<FieldModel>> {
        return this.mapRequest<FieldModel>(
            this._http.get(this.url + fieldId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            FieldModel,
            false
        ).pipe(take(1));
    }

    public createField(field: FieldInterface): Observable<ResponseModel<FieldModel>> {
        return this.mapRequest<FieldModel>(
            this._http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            FieldModel,
            false
        ).pipe(take(1));
    }

    public updateField(fieldId: number, field: FieldInterface): Observable<ResponseModel<FieldModel>> {
        return this.mapRequest<FieldModel>(
            this._http.patch(this.url + fieldId + '/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            FieldModel,
            false
        ).pipe(take(1));
    }

    public deleteField(fieldId): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + fieldId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }
}
