import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fap-edit-attributes',
    templateUrl: './fap-edit-attributes.component.html',
    styleUrls: ['./fap-edit-attributes.component.scss']
})
export class FapEditAttributesComponent {


  public attributes: Array<any> = [
    {
      id:   0,
      name: 'test name',
      valueType:  'Text',
      value: 'test value',
      info: 'test info',
    }
  ];

  public valueTypes: Array<string> = [
    'Text',
    'Date',
    'Number'
  ];

  private selectedValues: Array<string> = [];

  constructor(private modalService: NgbModal) {
  }

  public openLg(content: any): void {
    this.modalService.open(content, { size: 'lg' });
  }

  public newAttribute(): void
  {
    const attributeId: number = this.attributes.length + 1;
    const attribute: any = {
      id:   attributeId,
      name: '',
      valueType:    '',
      value: '',
      info: '',
    };

    this.selectedValues[this.attributes.length + 1] = '';

    this.attributes.push(attribute);
  }

  public deleteAttribute(id: number): void
  {
    this.attributes = this.attributes.filter((attribute: any) =>
    {
      return attribute.id !== id;
    });
  }
}
