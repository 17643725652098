<div class="row">
  <div class="col-md-12">
    <div class="card">
      <h2 class="card-header-title mr-4">
        {{ 'activity.activityGroups' | translate }}
      </h2>
      <div class="btn btn-success d-flex align-items-center px-3" [routerLink]="'/pages/activity/groups/add'">
        <i class="material-icons mr-1">add</i>
        {{ 'activity.addActivityGroup' | translate }}
      </div>
    </div>
    <div class="cards-grid">
      <fap-activity-group-row *ngFor="let activityGroup of activityGroups" [activityGroup]="activityGroup"
        [activities]="activities" (deleteActivityGroup)="deleteActivityGroup.emit($event);">
      </fap-activity-group-row>
    </div>
  </div>
</div>