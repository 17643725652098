<div *ngIf="widget" class="widget-wrapper">
    <span *ngIf="isEditMode" class="widget-close">
        <i class="material-icons ter-btn-icon ter-btn-icon-danger" (click)="deleteWidget.emit(widget.id)">close</i>
    </span>

    <!-- <ng-container *ngIf="widget.type==='weather'">
        <fap-weather-widget [data]="widget.config"></fap-weather-widget>
    </ng-container>

    <ng-container *ngIf="widget.type==='temperature'">
        <fap-line-chart [chartData]="widget.config"></fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='humidity'">
        <fap-line-chart [chartData]="widget.config"></fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='moist'">
        <fap-line-chart [chartData]="widget.config"></fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='dielectric'">
        <fap-line-chart [chartData]="widget.config">
        </fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='illuminance'">
        <fap-line-chart [chartData]="widget.config">
        </fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='pressure'">
        <fap-line-chart [chartData]="widget.config">
        </fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='wind'">
        <fap-single-pie-chart [chartData]="widget.config"></fap-single-pie-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='rad'">
        <fap-line-chart [chartData]="widget.config">
        </fap-line-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='soil'">
        <fap-soil-widget [widgetData]="widget.config">
        </fap-soil-widget>
    </ng-container>

    <ng-container *ngIf="widget.type==='rainfall'">
        <fap-vertical-bar-chart [chartData]="widget.config"></fap-vertical-bar-chart>
    </ng-container>

    <ng-container *ngIf="widget.type==='photosynthRadiation'">
        <fap-vertical-bar-chart [chartData]="widget.config"></fap-vertical-bar-chart>
    </ng-container>

    <ng-container *ngIf="['lots','farms','fields'].includes(widget.type)">
        <fap-list-widget [widgetData]="widget.config">
        </fap-list-widget>
    </ng-container> -->

    <ng-container  *ngIf="widget.type==='activities'">
        <fap-activity-list
            class="activity-list"
            [widgetConfig]="widget.config"
            [activities]="widget.config.activities ? widget.config.activities : []"
            [activityGroups]="widget.config.activityGroups ? widget.config.activityGroups : []"
            (deleteActivity)="widget.config.deleteActivityFunction($event)"
            (editActivity)="widget.config.editActivityFunction($event)"
            (addActivity)="widget.config.addActivityFunction($event)">
        </fap-activity-list>
    </ng-container>

    <ng-container *ngIf="widget.type==='alerts'">
        <fap-alert-list [widgetConfig]="widget.config">
        </fap-alert-list>
    </ng-container>
</div>

<!-- <fap-line-chart *ngIf="widget.type==='temperature'" [chartData]="widget.config">
</fap-line-chart>

<fap-line-chart *ngIf="widget.type==='humidity'" [chartData]="widget.config">
</fap-line-chart>

<fap-line-chart *ngIf="widget.type==='soil'" [chartData]="widget.config">
</fap-line-chart> -->

<!--<fap-line-chart [chartData]="horizontalLineChartData">
</fap-line-chart>

<fap-line-chart [chartData]="verticalLineChartData">
</fap-line-chart>

<fap-pie-chart-grid [chartData]="pieGridChartData">
</fap-pie-chart-grid>

<fap-single-pie-chart [chartData]="explodedPieChartData">
</fap-single-pie-chart>

<fap-single-pie-chart [chartData]="doughnutPieChartData">
</fap-single-pie-chart>

<fap-advanced-pie-chart [chartData]="advancedPieChartData">
</fap-advanced-pie-chart>

<fap-horizontal-bar-chart [chartData]="chartsBarHorizontalData">
</fap-horizontal-bar-chart> -->