import {
    Component,
    OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../../core/services/api/user/user.service';

@Component({
    templateUrl: './weeds-container.component.html'
})
export class WeedsContainerComponent implements OnDestroy {

public currentUser;  
public subscription: Subscription;  
    constructor(public userService:UserService, public router:Router) {
        this.subscription = this.userService.getCurrentUser.subscribe(data => {
            if(Object.keys(data).length != 0) {
            this.currentUser = data;
            const path = this.router.routerState.snapshot.url;
            this.userService.getMenus.subscribe(menus => {  
                if(Object.keys(menus).length != 0) {
                    const pathExist = menus.some(menu => menu.path === path);
                    if(pathExist === false) {
                        this.router.navigate(['pages/posts']);
                    }
                }
            })
            }
        });
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
