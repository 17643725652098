import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { ActivityModel } from '../../../../core/models/activity/activity.model';

@Component({
    selector: 'fap-activity-list-row',
    templateUrl: './fap_activity-list-row.component.html',
    styleUrls: ['./fap_activity-list-row.component.scss']
})
export class FapActivityListRowComponent {

    @Input()
    public activity: ActivityModel;

    @Input()
    public hideButtons = false;

    @Output()
    public deleteActivity: EventEmitter<number> = new EventEmitter();

    @Output()
    public editActivity: EventEmitter<ActivityModel> = new EventEmitter();

}
