<div class="plan_dates">
    <div class="scrollable_area">
        <div class="gantt-chart">
            <div class="cell" *ngFor="let col of dateColumns; let i = index">
              <div class="activity-row d-flex">
                <div class="activity-cell">
                    <ng-container *ngIf="activityRows?.real">
                        <ng-container *ngFor="let item of activityRows?.real?.items">
                            <ng-container *ngIf="i === item?.start">
                            <div class="activity-overlay"
                                 [style.background]="getActivityTypeColor(activity?.data?.type)"
                                 [style.width]="getActivityOverlayWidth(item?.start, item?.end)"
                                 [style.left]="getActivityOverlayPosition(item?.start)"
                                 [style.height]="hasPlannedItems(activityRows) ? '43%' : '86%'">
                              {{ activity?.data?.name }}
                            </div>
                          </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="activityRows?.planned">
                        <ng-container *ngFor="let item of activityRows?.planned?.items">
                            <ng-container *ngIf="i === item?.start">
                            <div class="activity-overlay"
                                 [style.background]="getActivityTypeColor(activity?.data?.type)"
                                 [style.width]="getActivityOverlayWidth(item?.start, item?.end)"
                                 [style.left]="getActivityOverlayPosition(item?.start)"
                                 [style.opacity]="'0.5'"
                                 [style.top]="'50%'"
                                 [style.height]="hasPlannedItems(activityRows) ? '43%' : '86%'">
                              {{ activity?.data?.name }}
                            </div>
                          </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
              </div>
            </div>
          </div>                       
    </div>
</div>