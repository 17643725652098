import {
    Component, EventEmitter, Input, Output,
    ViewEncapsulation
} from '@angular/core';
import {
    Router,
    NavigationEnd,
    Event
} from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

import {
    NavService,
    Menu
} from '../../services/nav.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../core/services/api/auth/auth.service';
import { UserService } from '../../../core/services/api/user/user.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'fap-sidebar',
    templateUrl: './fap_sidebar.component.html',
    styleUrls: ['./fap_sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FapSidebarComponent {

    public menuItems: Menu[];
    public leftMenuItems: Menu[];
    public url: any;
    public fileurl: any;
    public year: number = new Date().getFullYear();
    public sidebarCollapse: boolean;
    public currentUser;
    @Input()
    public isProfileOpen: boolean;

    @Output()
    public profileToggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    public mediaUrl = environment.mediaUrl

    constructor(public router: Router,
                public navServices: NavService,
                public globalRegistry: GlobalRegistryService,
                public permissionService: NgxPermissionsService,
                public authService: AuthService,
                public translate: TranslateService,
                public userService: UserService,
                public cacheService: CacheResolverService
                ) {
        this.navServices.collapseSidebar.subscribe((collapse: boolean) => {
            this.sidebarCollapse = collapse;
        });
        const accessData = localStorage.getItem("access")?JSON.parse(localStorage.getItem("access")):{};
        const hiddenKeys = [];
        Object.keys(accessData).forEach(key=>{
            if(accessData[key][0]===0){
                hiddenKeys.push(key);
            }
        }) 
        this.navServices.items.subscribe((menuItems: Menu[]) => {
            this.menuItems = menuItems.filter((menuItem: Menu) => {
                return !hiddenKeys.includes(menuItem.key);
                // if (menuItem.permissions && menuItem.permissions.length > 0) {
                //     return menuItem.permissions.some((permission: string) => permissionService.getPermission(permission));
                // }
                // return true;
            });
            console.log(menuItems);
            
            this.userService.getUser().subscribe(response => {
                this.currentUser = response.body.results;
                this.userService.setCurrentUser(this.currentUser);
                console.log(this.currentUser);
                if(Object.keys(this.currentUser.profile.data).length !== 0) {
                const result = menuItems.filter(arr => this.currentUser.profile.data.allowed.includes(arr.key));
                this.leftMenuItems = result;
                }
                const timeZone = response.body.results.timezone;
                // userService.setMenus(response.body.results.timezone);
                console.log(timeZone);
            });
            this.router.events.subscribe((event: Event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter((items: Menu) => {
                        if (items.path === event.url) {
                            this.setNavActive(items);
                        }
                        if (!items.children) {
                            return false;
                        }
                        items.children.filter((subItems: Menu) => {
                            if (subItems.path === event.url) {
                                this.setNavActive(subItems);
                            }
                            if (!subItems.children) {
                                return false;
                            }
                            subItems.children.filter((subSubItems: Menu) => {
                                if (subSubItems.path === event.url) {
                                    this.setNavActive(subSubItems);
                                }
                            });
                        });
                    });
                }
            });
        });
    }

    // Active Nave state
    public setNavActive(item: Menu): void {
        this.menuItems.filter((menuItem: Menu) => {
            if (menuItem !== item) {
                menuItem.active = false;
            }
            if (menuItem.children && menuItem.children.includes(item)) {
                menuItem.active = true;
            }
            if (menuItem.children) {
                menuItem.children.filter((submenuItems: Menu) => {
                    if (submenuItems.children && submenuItems.children.includes(item)) {
                        menuItem.active = true;
                        submenuItems.active = true;
                    }
                });
            }
        });
    }

    // Click Toggle menu
    public toggletNavActive(item: Menu): void {
        if (!item.active) {
            this.menuItems.forEach((a: Menu) => {
                if (this.menuItems.includes(item)) {
                    a.active = false;
                }
                if (!a.children) {
                    return false;
                }
                a.children.forEach((b: Menu) => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }
                });
            });
        }
        item.active = !item.active;
    }

    // Fileupload
    public readUrl(event: any): void {
        if (event.target.files.length === 0) {
            return;
        }
        // Image upload validation
        const mimeType: any = event.target.files[0].type;
        if (mimeType.match(/image\/*/) === null) {
            return;
        }
        // Image upload
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (): void => {
            this.url = reader.result;
        };
    }

    public toggleSidebar(): void {
        if(window.innerWidth < 768) {
            this.navServices.collapseSidebar.next(!this.sidebarCollapse);
        }
    }

    public logOut(): void {
        this.cacheService.clearAllCache();
        this.authService.logout().subscribe(data => {
            console.log(data);
            const countryData = localStorage.getItem('country_info');
            const language = localStorage.getItem('language');
            localStorage.clear();
            localStorage.setItem('country_info', countryData);
            localStorage.setItem('language', language);
            this.globalRegistry.isLoggedIn = false;
        });
        this.router.navigate(['login']);
    }
    public getTranslationsByKey(item: string): string {
        return this.translate.instant(item);
    }

    public emitOpenProfile(): void {
        this.isProfileOpen = !this.isProfileOpen;
        this.profileToggleEvent.emit(this.isProfileOpen);
    }

    public returnIcon(item: Menu): boolean {
        if (item.icon.includes('assets')) {
            return true;
        } else {
            return false;
        }
    }

}
