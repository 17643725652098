import { Component } from '@angular/core';


@Component({
    templateUrl: './crops.component.html',
    styleUrls: ['./crops.component.scss']
})
export class lotsComponent {

}
