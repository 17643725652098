import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Observer
} from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class WebsocketService {
    public sendData: BehaviorSubject<object>;

    private observable: Observable<MessageEvent>;
    private ws: WebSocket;

    public connect(url: string): Observable<MessageEvent> {
        if (!this.observable) {
            this.observable = this.create(url);
        }
        this.sendBehavior();
        return this.observable;
    }

    private sendBehavior(): void {
        if (!this.sendData) {
            this.sendData = new BehaviorSubject(null);
        }

        this.sendData.subscribe((data: object) => {
            if (this.ws.readyState === WebSocket.OPEN) {
                this.ws.send(JSON.stringify(data));
            }
        });
    }

    private create(url: string): Observable<MessageEvent> {
        const wsScheme: string = environment.secure ? 'wss' : 'ws';

        try {
            console.warn(wsScheme);
            this.ws = new WebSocket(wsScheme + '://' + url);
        } catch (error) {
            console.warn(error);
        }

        return new Observable(
            (obs: Observer<MessageEvent>): any => {
                this.ws.onmessage = obs.next.bind(obs);
                this.ws.onerror = obs.error.bind(obs);
                this.ws.onclose = obs.complete.bind(obs);
                return this.ws.close.bind(this.ws);
            }
        );
    }
}
