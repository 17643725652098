import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, EventEmitter, Inject, Output } from "@angular/core";
import { fromEvent } from "rxjs";
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from "rxjs/operators";

@Directive({
  selector: "[resizable]"
})
export class ResizableDirective {
  @Output() widthChange = new EventEmitter<number>();
  @Output() resizeEnd = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef) private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnInit() {
    fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown")
      .pipe(
        tap(e => e.preventDefault()),
        switchMap(() => {
          const { width, right } = this.elementRef.nativeElement.closest("th").getBoundingClientRect();

          return fromEvent<MouseEvent>(this.documentRef, "mousemove").pipe(
            map(({ clientX }) => width + clientX - right),
            distinctUntilChanged(),
            takeUntil(fromEvent(this.documentRef, "mouseup").pipe(
              tap(() => {
                console.log("Mouse up event detected, emitting resizeEnd");
                this.resizeEnd.emit();
              })
            ))
          );
        })
      )
      .subscribe(width => this.widthChange.emit(width));
  }
}