<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="wizard-steps">
                <div class="wizard-steps-header">
                    <ng-container *ngFor="let step of wizardSteps; let index = index;">
                        <h6 class="wizard-step-title" [ngClass]="{'active-tab': index <= activeTabIndex}">
                            {{ step.title | translate }}</h6>
                    </ng-container>
                </div>

                <div class="wizard-steps-body">
                    <ng-container *ngFor="let step of wizardSteps; let index = index;">
                        <div class="step-wrapper">
                            <i class="material-icons step-icon"
                                [ngClass]="{'active-tab': index <= activeTabIndex}">{{ step.icon }}</i>

                            <div *ngIf="index < wizardSteps?.length-1" class="step-separator"
                                [ngClass]="{'active-tab': index <= activeTabIndex-1}"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-sm-12 widget-tab-content">
            <!-- <ngb-tabset [destroyOnHide]="wizardDestroyOnHide">
                <ng-template ngFor [ngForOf]="steps" let-step>
                    <ngb-tab [id]="step.id">
                        <ng-template ngbTabContent>
                            <div>
                                <ng-template [ngTemplateOutlet]="step.templateRef"></ng-template>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ng-template>
                <ngb-tab [id]="reviewTabId" *ngIf="reviewTabTitle !== undefined">
                    <ng-template ngbTabContent>
                        <div>
                            <ng-template ngFor [ngForOf]="reviews" let-review>
                                <div>
                                    <div>{{ review.title | translate }}</div>
                                    <div>
                                        <ng-template [ngTemplateOutlet]="review.templateRef"></ng-template>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset> -->
            <div class="d-flex justify-content-center wizard-form-actions">
                <button *ngIf="activeTabIndex > 0" class="btn btn-primary btn-md btn-tall btn-wide m-2"
                    (click)="prevStep()">
                    {{ 'previousStep' | translate }}
                </button>
                <button *ngIf="activeTabIndex === wizardSteps.length - 1"
                    class="btn btn-success btn-md btn-tall btn-wide m-2" [disabled]="wizardSubmitDisabled"
                    (click)="submitWizard()">
                    {{ 'submit' | translate }}
                </button>
                <button *ngIf="activeTabIndex < wizardSteps.length - 1"
                    class="btn btn-primary btn-md btn-tall btn-wide m-2" [disabled]="wizardNextDisabled"
                    (click)="nextStep()">
                    {{ 'nextStep' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>