<div class="contextmenu" [ngStyle]="{'left.px': x, 'top.px': y}">
    <div class="map-menu">
        <ul>
            <ng-container *ngFor="let option of options">
                <li *ngIf="option.divider" class="divider">
                </li>

                <li *ngIf="option.header" class="header">
                    {{ option.header }}
                </li>

                <li *ngIf="!option.header && !option.divider"
                    (click)="option.clickFunction()"
                    class="item">

                    {{option.text}}

                </li>
            </ng-container>
        </ul>
    </div> 
</div>
