import { FapBaseModel } from '../../base/fap-base-model';

export class UnitValueModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'value',
        'unit',
        'unit_name',
        'unit_short_name'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public value: number;
    public unit: number;
    public unitName: string;
    public unitShortName: string;

    public get valueAndUnit(): string {
        return this.value + ' ' + this.unitShortName;
    }
}
