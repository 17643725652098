import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    Params,
    ActivatedRoute,
    Router
} from '@angular/router';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { ToastrService } from 'ngx-toastr';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ForecastService } from '../../../../../core/services/api/forecast/forecast.service';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { take } from 'rxjs/operators';
import { ResponseModel } from '../../../../../core/models/response.model';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    templateUrl: './people-container.component.html',
    styleUrls: ['./people-container.component.scss']
})
export class PeopleContainerComponent implements OnInit, OnDestroy {

    public filteredPersons: Array<PersonModel>;
    public noFarmPersons: Array<PersonModel>;

    private filteredFarmIds: Array<number> = [];
    private filteredGroupIds: Array<number> = [];
    public forecasts: Array<WeatherModel> = [];

    public farmForecastMapping: { [key: number]: WeatherModel } = {};

    public filteredFarms: Array<FarmModel> = [];

    public enableAddEdit = true;

    public enableDelete = true;
    public subscription: Subscription;
    public currentUser;
    public limit = 10;
    public nextToken: { offset: number; limit: number } = null;
    public getMore = true;
    public isSelectDialogOpen: boolean = false;

    constructor(public farmService: FarmService,
                public activatedRoute: ActivatedRoute,
                public toastyService: ToastrService,
                public companyService: CompanyService,
                public mapService: MapService,
                public forecastService: ForecastService,
                public translate: TranslateService,
                public router: Router,
                public globalRegistry: GlobalRegistryService,
                public userService: UserService,
                public widgetService: WidgetsService,
                public cacheService: CacheResolverService
                ) {
                    this.subscription = this.userService.getCurrentUser.subscribe(data => {
                        if(Object.keys(data).length != 0) {
                        this.currentUser = data;
                        const path = this.router.routerState.snapshot.url;
                        this.userService.getMenus.subscribe(menus => {  
                            if(Object.keys(menus).length != 0) {
                                const pathExist = menus.some(menu => menu.path === path);
                                if(pathExist === false) {
                                    this.router.navigate(['pages/posts']);
                                }
                            }
                        })
                        }
                    })

                    this.getPersons();

        const localQueryParams = localStorage.getItem("queryParams")?JSON.parse(localStorage.getItem("queryParams")):{};
        this.router.navigate([],{
            relativeTo:this.activatedRoute,
            queryParams: localQueryParams
        });
        this.activatedRoute.queryParams.subscribe((queryParams: Params): void => {
            if (!this.forecasts || this.forecasts.length === 0) {
                // this.getForecasts();
            }
            if (queryParams['farms'] !== undefined) {
                // we have farms in query params, but need to check if we have an array of farms
                this.filteredFarmIds = Array.isArray(queryParams['farms'])
                    ? queryParams['farms'].map((farmId: string): number => parseInt(farmId, 10))
                    : [parseInt(queryParams['farms'], 10)];
                this.filteredFarms = this.globalRegistry.systemData.farms.filter((farm:FarmModel)=>{
                    return this.filteredFarmIds.includes(farm.id);
                })
            } else {
                this.filteredFarmIds = [];
                this.filteredFarms = this.globalRegistry.filterFarms(queryParams['filter']);
            }
            if (queryParams['groups'] !== undefined) {
                // we have groups in query params, but need to check if we have an array of groups
                this.filteredGroupIds = Array.isArray(queryParams['groups'])
                    ? queryParams['groups'].map((groupId: string): number => parseInt(groupId, 10))
                    : [parseInt(queryParams['groups'], 10)];
            } else {
                this.filteredGroupIds = [];
            }
            this.refreshPersons();
        });
    }

    public ngOnInit(): void {
        this.mapService.showMap();
        this.widgetService.setSize(6);
        [this.enableAddEdit,this.enableDelete] = this.globalRegistry.getAccessRights("people");
        // this.globalRegistry.reloadPersons();
        // this.getPersons();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.widgetService.setSize(6);
    }

    private getForecasts(): void {
        this.forecastService.getForecast({ content_type: 'farm' })
            .pipe(take(1))
            .subscribe((forecasts: ResponseModel<WeatherModel[]>): void => {
                this.forecasts = forecasts.model;
                this.initForecastMapping();
            });
    }

    private initForecastMapping(): void {
        if(this.forecasts) {
            this.forecasts.forEach((forecast: WeatherModel): void => {
                this.farmForecastMapping[forecast.objectId] = forecast;
            });
        }
    }

    public removePerson(personId: number): void {
        const refreshWrapper: () => void = (): void => { this.refreshPersons(); };
        this.companyService.deletePerson(personId).subscribe((): void => {
            this.toastyService.success(this.translate.instant('people.userDeletedSuccesfully'));
            this.globalRegistry.reloadPersons(refreshWrapper);
        },
            (): void => {
                this.toastyService.error(this.translate.instant('people.failedToDeleteUser'));
            });
    }

    paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

      scrolledDown() {
        const url = this.companyService.getUrl('persons/');
        
        console.log('person scrolled');
        if(this.getMore) {
          this.nextToken &&
            this.subscription.add(this.companyService.getPersons(this.nextToken).subscribe((data) => {
                this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
              console.log(data);
              if(this.filteredPersons) {
                this.filteredPersons = [...this.filteredPersons, ...data.body.results];
              } else {
                this.filteredPersons = data.body.results;
              }
              if(data.body.next == null) {
                this.getMore = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.nextToken.offset != params['offset']) {
                this.nextToken = {limit: params['limit'], offset: params['offset']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
      }

    private refreshPersons(): void {
        if (!this.filteredFarmIds || this.filteredFarmIds.length > 0) {
            this.filteredPersons = this.globalRegistry.systemData.persons.filter((person: PersonModel): boolean => {
                return this.filteredFarmIds.some((farmId: number): boolean => {
                    if (!person.activeFarms || person.activeFarms.length === 0) {
                        return farmId === -1;
                    }
                    return person.activeFarms.includes(farmId);
                });
            });
        } else {
            this.getPersons()
        }
        if (!this.filteredGroupIds || this.filteredGroupIds.length > 0) {
            this.filteredPersons = this.filteredPersons.filter((person: PersonModel): boolean => {
                return this.filteredGroupIds.some((groupId: number): boolean => {
                    if (!person.groups || person.groups.length === 0) {
                        return groupId === -1;
                    }
                    return person.groups.includes(groupId);
                });
            });
        }
        // this.noFarmPersons = this.globalRegistry.systemData.persons.filter((person:PersonModel)=>!person.activeFarms || person.activeFarms.length<1);
    }

    public getPersons() {
        const url = this.companyService.getUrl('persons/');
        this.cacheService.delete(url);
        this.subscription.add(this.companyService.getPersons().subscribe(
            (persons): void => {
              this.filteredPersons = persons.body.results;
              this.nextToken = persons.body.next
                ? this.globalRegistry.getQueryStringParams(persons.body.next.split("?")[1])
                : null;
                if(this.nextToken) {
                    this.getMore =  true;
                }
            }
          )
        );
    }

}
