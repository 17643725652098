<div class="container-fluid p-0">
    <div class="add-edit-activity">
        <div class="card">
            <div class="card-header">
                <h2 class="card-header-title" *ngIf="editActivityGroupMode">
                    {{ 'activity.editActivityGroup' | translate }}</h2>
                <h2 class="card-header-title" *ngIf="!editActivityGroupMode">
                    {{ 'activity.addActivityGroup' | translate }}</h2>
            </div>
            <div class="card-body">
                <div class="activity-group-form mb-3">
                    <form #activityGroupForm="ngForm">
                        <i class="prefix fa fa-pencil center f-20 mr-2"></i>
                        <mat-form-field class="w-90">
                            <input [(ngModel)]="activityGroupName" matInput
                                placeholder="{{ 'activity.activityGroupName' | translate }}"
                                name="activityGroupName" required>
                        </mat-form-field>
                    </form>
                    <div (click)="createActivity()" class="btn btn-success add-activity-card ml-4 px-2">
                        <i class="material-icons mr-1">add</i>
                        <div>{{ 'activity.addActivity' | translate }}</div>
                    </div>
                </div>
                <div class="activities-grid">
                    <fap-activity-card
                        *ngFor="let activity of existingActivities"
                        [activityName]="activity.name"
                        [activityInfo]="activity.info"
                        [activityWorkingTime]="activity.workingTime"
                        [activityStarted]="activity.started"
                        [activityEnded]="activity.ended"
                        [activityType]="activity.activityType"
                        (editActivity)="editActivity(activity)"
                        (deleteActivity)="deleteActivity(activity.id)">
                    </fap-activity-card>
                    <fap-activity-card
                        *ngFor="let activity of newActivities"
                        [activityName]="activity.name"
                        [activityInfo]="activity.info"
                        [activityWorkingTime]="activity.workingTime"
                        [activityStarted]="activity.started"
                        [activityEnded]="activity.ended"
                        [activityType]="activity.activityType"
                        (deleteActivity)="deleteActivity(activity.id)"
                        (editActivity)="editActivity(activity)">
                    </fap-activity-card>
                </div>
            </div>
            <div class="card-footer d-flex justify-content-center">
                <button
                    [disabled]="activityGroupForm.invalid || (!existingActivities?.length && !newActivities?.length)"
                    class="btn btn-success" type="button" (click)="submitActivityGroup()">{{ "save" | translate }}
                    <i class="material-icons pl-1">send</i>
                </button>
            </div>
        </div>
    </div>
</div>

<fap-modal #addEditActivityModal [inputIsMediumLarge]="true" inputTitle="{{ 'activity.addActivityGroup' | translate }}">
    <fap-add-edit-activity
        [activityTypes]="globalRegistry.systemData.activityTypes"
        [activity]="selectedActivity"
        (submitEvent)="createdOrEditedActivity($event)">
    </fap-add-edit-activity>
</fap-modal>
