import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    NgZone
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject, throwError, Observable, Subscription } from 'rxjs';
import { tap, catchError, takeUntil, finalize, map } from 'rxjs/operators';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyInterface } from '../../../../../core/services/api/company/data/company.interface';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { AddressModel } from '../../../../../core/models/company/address.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapsAPILoader } from '@agm/core';
import { environment } from '../../../../../../environments/environment';
import data from "../../../../../../assets/timezone/timezone.json";
import { Menu, NavService } from '../../../../../shared/services/nav.service';
import { UserService } from '../../../../../core/services/api/user/user.service';

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  content: string;
  color?: string;
  iconUrl?: string;
}

interface coords {
  lat: number;
  lng: number;
}


@Component({
    selector: 'fap-create-company',
    templateUrl: './fap_create-company.component.html',
    styleUrls: ['./fap_create-company.component.scss']
})
export class FapCreateCompanyComponent implements OnInit, OnDestroy {
    public createCompanyForm: FormGroup;
    public data$: Observable<Data>;
    @Output()
    public deleteAddress: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @ViewChild('addEditAddressModal')
    public addEditAddressModal: FapModalComponent;

    public selectedAddress: AddressModel;
    private unsubscribe: Subject<any>;
    public companyInfo: any;
    public types = ['person', 'company']
    public fullAddress;
    zoom = 8;
    markers:Array<marker> = [];
    public searchControl: FormControl;
    public mapSearchToggle: boolean;
    public mapTypeControl: boolean;
    @ViewChild('searchRef1', {static: true}) public searchRef1: ElementRef;
    public addressString;
    @Output()
    public coords: EventEmitter<coords> = new EventEmitter();
    public subscriptions: Array<Subscription> = [];
    public coordsString = '';
    defaultCenter = {lat: 51.673858, lng: 7.815982};
    currentCenter = Object.assign({}, this.defaultCenter);
    public type = 'person';
    public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';
    address: AddressModel = new AddressModel();

    constructor(
        private formBuilder: FormBuilder,
        private toastyService: ToastrService,
        public translate: TranslateService,
        public companyService: CompanyService,
        public router: Router,
        private cdr: ChangeDetectorRef,
        public globalRegistry: GlobalRegistryService,
        public toastr: ToastrService,
        private route: ActivatedRoute,
        private userService: UserService,
        private navServices: NavService,
        private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {

        translate.setDefaultLang(localStorage.getItem('language'));
        translate.use(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');
        this.unsubscribe = new Subject();
    }

    public ngOnInit(): void {
        this.getCountriesData();
        console.log(JSON.parse(localStorage.getItem('company')));
        this.companyInfo = JSON.parse(localStorage.getItem('company'));
        this.initCreateCompanyForm();
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
                mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
        }));

        this.searchControl = new FormControl();
          this.mapsAPILoader.load().then(() => {
            const autocomplete = new google.maps.places.Autocomplete(this.searchRef1.nativeElement, {
              types: ["address"]
            });
            autocomplete.addListener("place_changed", () => {
              this.ngZone.run(() => {
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                if (place.geometry === undefined || place.geometry === null) {
                  return;
                }
                this.currentCenter.lat = place.geometry.location.lat();
                this.currentCenter.lng = place.geometry.location.lng();
                this.zoom = 12;
              });
            });
          })
        
    }

    getFocus() {
      this.mapSearchToggle = true;
      this.searchRef1.nativeElement.focus();
    }

    public changeType(ev) {
        if(ev.value === 'company') {
            this.type = 'party';
            this.createCompanyForm.get('cif').setValidators(Validators.required);
            this.createCompanyForm.get('vat').setValidators(Validators.required);
        } else {
            this.type = 'person';
            this.createCompanyForm.get('cif').clearValidators();
            this.createCompanyForm.get('vat').clearValidators();
        }
    }

    public ngOnDestroy(): void {
         this.unsubscribe.next(null);
        this.unsubscribe.complete();
        localStorage.removeItem('company')
    }

    public getCountriesData() {
        this.subscriptions.push(this.companyService.getCountries().subscribe(data => {
            this.globalRegistry.systemData.countries = data.body;
        }));
    }

    mapClicked($event) {
        this.markers = [];
        this.selectedAddress = null;
        this.markers.push({
          lat: $event.coords.lat,
          lng: $event.coords.lng,
          draggable: true,
          content: $event.coords.lat +', '+ $event.coords.lng,
        });
        this.coords.emit({lat: $event.coords.lat, lng: $event.coords.lng});
        this.coordsString = 'Point(' + $event.coords.lat + ' ' + $event.coords.lng + ')';
        console.log(this.coordsString);
        this.reverseGeocode($event.coords.lat, $event.coords.lng);
      }
    
      markerDragEnd(m: marker, $event) {
        console.log("dragEnd", m, $event);
        this.reverseGeocode(m.lat, m.lng);
      }

      reverseGeocode(lat: number, lng: number) {
        // Load Google Maps Geocoding API
        this.mapsAPILoader.load().then(() => {
          const geocoder = new google.maps.Geocoder();
          const latlng = { lat: lat, lng: lng };
    
          // Call geocode method
          geocoder.geocode({ 'location': latlng }, (results, status) => {
            console.log(results);
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                console.log(results[0].address_components);
                this.organizeAddressComponents(results[0].address_components);
                const placeInfo = results[0].formatted_address;
                console.log('Place Information:', placeInfo);
                // this.coordsString = placeInfo;
                // this.parsePlaceInformation(placeInfo);
              } else {
                console.error('No results found');
                this.coordsString = 'No results found';
              }
            } else {
              console.error('Geocoder failed due to: ' + status);
              this.coordsString = 'Geocoder failed';
            }
          });
        });
      }

      organizeAddressComponents(components) {
        let organized = {
            street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            administrative_area_level_2: '',
            postal_code: '',
            country: ''
        };
    
        components.forEach(component => {
            if (component.types.includes('street_number')) {
                organized.street_number = component.long_name;
            }
            if (component.types.includes('route')) {
                organized.route = component.long_name;
            }
            if (component.types.includes('locality')) {
                organized.locality = component.long_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
                organized.administrative_area_level_1 = component.long_name;
            }
            if (component.types.includes('administrative_area_level_2')) {
                organized.administrative_area_level_2 = component.long_name;
            }
            if (component.types.includes('postal_code')) {
                organized.postal_code = component.long_name;
            }
            if (component.types.includes('country')) {
                organized.country = component.long_name;
            }
        });
        console.log(organized);
        this.address.street = [organized.street_number, organized.route].filter(Boolean).join(' ');
        this.address.city = organized.locality;
        this.address.county = organized.administrative_area_level_1 + ' ' + organized.administrative_area_level_2;
        this.address.postal_code = organized.postal_code;
        this.address.country = this.updateCountryName(organized.country);
        this.selectedAddress = this.address;
        this.fullAddress = this.address;
        console.log(this.selectedAddress);
        this.addEditAddressModal.showModal();
        // return organized;
    }    
      
      parsePlaceInformation(placeInfo: string) {
        const parts = placeInfo.split(',').map(part => part.trim());
        const country = parts.pop();
        let postalCode = '';
        let county = '';
        let countryShortName = this.updateCountryName(country);
        // Splitting the last part to check for postal code and county
        const lastPart = parts.pop();
        const lastPartParts = lastPart.split(' ');
        
        // Checking if the last part is a postal code or part of the county
        for (let i = 0; i < lastPartParts.length; i++) {
            if (!isNaN(parseInt(lastPartParts[i]))) {
                postalCode = lastPartParts[i].trim();
            } else {
                county += lastPartParts[i] + ' ';
            }
        }
        county = county.trim(); // Remove trailing space
    
        // City is the last part before the postal code
        const city = parts.pop();
    
        // Joining the remaining parts to form the street address
        const street = parts.join(', ');
    
        this.address.street = street;
        this.address.city = city || '';
        this.address.county = county;
        this.address.number = '';
        this.address.postal_code = postalCode;
        this.address.country = countryShortName;
        this.selectedAddress = this.address;
        this.fullAddress = this.address;
        this.addEditAddressModal.showModal();
        console.log(this.selectedAddress);
    }       

    updateCountryName(countryName: string,) {
      const country = this.globalRegistry.systemData.countries.find(country => country.en === countryName);
      if (country) {
          return country.iso3;
      }
  }

    public submit(): void {
      console.log(this.createCompanyForm.value);
        if(!this.createCompanyForm.valid) {
            Object.keys(this.createCompanyForm.controls).forEach((controlName: string) =>
                this.createCompanyForm.controls[controlName].markAsTouched()
            );
            if(this.createCompanyForm.value.address_id === null) {
              this.toastr.error('Please enter a valid address')
            }
            this.toastr.error(this.translate.instant('equipment.pleaseFillInMandatoryFields'));
            return;
        }

        
        const body: CompanyInterface = {
            accType: this.createCompanyForm.controls['type'].value,
            name: this.createCompanyForm.controls['name'].value,
            cif: this.createCompanyForm.controls['cif'].value,
            vat: this.createCompanyForm.controls['vat'].value,
            addressId: this.createCompanyForm.controls['address_id'].value,
            coords: this.coordsString
        };

        console.log(body)
       
        if(this.companyInfo.id) {
          console.log('company update')
            Object.assign(body, {id: this.companyInfo.id})
           this.companyService.updateCompany(body).pipe(
            tap(() => {
                this.toastyService.success(this.translate.instant('auth.company.companyupdated'));
                this.goToLandingPage();
            }),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error.results.error);
                return throwError(error)
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
           ).subscribe(); 
        } else {
          console.log('company create')
        this.companyService.createCompany(body).pipe(
            tap(() => {
                this.toastyService.success(this.translate.instant('auth.company.companyCreated'));
                this.goToLandingPage();
            }),
            catchError((error: HttpErrorResponse) => {
                this.toastr.error(error.error.results.error);
                return throwError(error);
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
        ).subscribe();
        }
    }

    public goToLandingPage() {
      this.userService.getUser().subscribe(response => {
        const landing = response.body.results.profile.data.landing;
        if(landing) {
            this.navServices.items.subscribe((menuItems: Menu[]) => {
                const result = menuItems.find(obj => obj.key === landing);
                this.router.navigate([result.path]);
            })
        } else {
            this.router.navigateByUrl('/pages');
        }
    })
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.createCompanyForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    private initCreateCompanyForm(): void {
      this.createCompanyForm = this.formBuilder.group({
          name: ['', Validators.compose([
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100)
              ])
          ],
          cif: [''],
          vat: [''],
          type: ['person', Validators.required],
          address_id: [null, Validators.required],
          coords: [null, Validators.required]
      });
      if (this.type === 'person') {
          this.createCompanyForm.get('name').setValue(localStorage.getItem('temp_user_name'));
          this.createCompanyForm.get('cif').clearValidators();
          this.createCompanyForm.get('vat').clearValidators();
        } else {
          this.createCompanyForm.get('cif').setValidators(Validators.required);
          this.createCompanyForm.get('vat').setValidators(Validators.required);
      }
      this.createCompanyForm.get('cif').updateValueAndValidity();
      this.createCompanyForm.get('vat').updateValueAndValidity();
  }

    public addAddressModal(): void {
        // this.selectedAddress = null;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }
  
    public editAddress(address: AddressModel): void {
        this.selectedAddress = address;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    geocode(address: string): Promise<any> {
        const geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
          geocoder.geocode(
            {
              address: address
            },
            (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                resolve(results[0]);
              } else {
                reject(new Error(status));
              }
            }
          );
        });
      }

    addressAdd(ev) {
        this.fullAddress = ev;
        this.addedAddress.emit(ev);
        this.createCompanyForm.get('address_id').patchValue(ev.id)
        this.addressString =  ev.city + ', ' + ev.country;
        this.selectedAddress = ev;
        console.log(this.addressString);
        this.geocode(this.addressString).then(place => {
          this.currentCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          
          this.markers = [];
            this.markers.push({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            draggable: true,
            content: place.geometry.location.lat() +', '+ place.geometry.location.lng(),
          });
          this.coords.emit({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
          this.coordsString = 'Point(' + place.geometry.location.lat() + ' ' + place.geometry.location.lng() + ')';
          this.createCompanyForm.get('coords').patchValue(this.coordsString)
          console.log(this.coordsString);
        })
      }
    
      addressUpdate(ev) {
        this.fullAddress = ev;
        this.updatedAddress.emit(ev);
        this.createCompanyForm.get('address_id').patchValue(ev.id)
        this.addressString =  ev.city + ', ' + ev.country;
        console.log(this.addressString);
        this.geocode(this.addressString).then(place => {
          this.currentCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
          
          this.markers = [];
            this.markers.push({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            draggable: true,
            content: place.geometry.location.lat() +', '+ place.geometry.location.lng(),
          });
          this.coords.emit({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
          this.coordsString = 'Point(' + place.geometry.location.lat() + ' ' + place.geometry.location.lng() + ')';
          this.createCompanyForm.get('coords').patchValue(this.coordsString)
          console.log(this.coordsString);
        })
      }

      mapReady(event) {
        console.log(event);
        this.setCurrentPosition();
      }

      private setCurrentPosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.currentCenter.lat = position.coords.latitude;
            this.currentCenter.lng = position.coords.longitude;
            this.zoom = 15;
          });
        }
      }

}
