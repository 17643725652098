import { Routes } from '@angular/router';
import { FormsComponent } from './forms.component';
// import { FieldContainerComponent } from './containers/field-container/field-container.component';
// import { FieldEditContainerComponent } from './containers/field-edit-container/field-edit-container.component';

export const formRoutes: Routes = [
    // {
    //     path: 'edit/:fieldId',
    //     component: FieldEditContainerComponent
    // },
    // {
    //     path: 'create',
    //     component: FieldEditContainerComponent
    // },
    {
        path: '',
        component: FormsComponent,
        data: {
            filters: {
                hasFarmFilter: true,
                hasFormsFilter: true,
            },
            key: 'forms',
            index: 0
        },
    }
];
