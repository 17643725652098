<div class="image_list">
            <div class="d-flex w-100" style="overflow-x: auto;">
                <div class="img_container">
                    <div class="images" style="display: flex;">
                        <div class="nimg" *ngFor="let equipment of activityEquipments">
                            <ng-container *ngIf="equipment?.product && equipment?.product?.images?.length">
                                <fap-image 
                                    [src]="mediaUrl + equipment?.product?.images[equipment.product.images.length - 1]?.file" 
                                    (deleteImage)="deleteImage.emit(equipment.id)" 
                                    [label]="equipment?.registration_plate"
                                    [type]="equipment?.attachable"
                                    [containerHeight]="120"
                                    >
                                </fap-image>
                            </ng-container>
                            <ng-container *ngIf="equipment?.product && equipment?.product?.images?.length === 0">
                                <fap-image
                                    src="../../../../../assets/images/image_placeholder.png"
                                    (deleteImage)="deleteImage.emit(equipment.id)" 
                                    [label]="equipment?.registration_plate"
                                    [type]="equipment?.attachable"
                                    [containerHeight]="120"
                                >
                                </fap-image>
                            </ng-container>
                        </div>                        
                    </div>
                </div>
                <div class="bx">
                    <div class="input-group align-items-baseline w-100 img_bx">
                        <mat-form-field class="main_combo w-100">
                            <mat-label>{{ 'Equipment' }}</mat-label>
                            <mat-select #aeds (selectionChange)="addImage.emit($event)" name="image" required>
                                <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #typeFilter placeholder="Search">
                                </mat-form-field>
                            </div>
                            <div class="scroll_wrap">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let equipment of equipments | filter : typeFilter.value" [value]="equipment">
                                    <div class="name" style="margin: 5px;">
                                        <div style="display: none;"></div>
                                        <div>{{equipment?.registrationPlate}}</div>
                                    </div>
                                </mat-option>
                            </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
</div>