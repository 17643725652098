import { Component } from '@angular/core';


@Component({
    templateUrl: './precision.component.html',
    styleUrls: ['./precision.component.scss']
})
export class PrecisionComponent {

}
