import {
    NgModule,
    ModuleWithProviders
} from '@angular/core';

import { ApiServicesModule } from './api/api-services.module';
import { AuthService } from './api/auth/auth.service';
import { UserService } from './api/user/user.service';
import { WidgetsWebsocketService } from './websocket/widgets/widgets-websocket.service';
import { WebsocketService } from './websocket/websocket.service';

@NgModule({
    imports: [
        ApiServicesModule.forRoot()
    ],
    declarations: [
    ]
})
export class FapServicesModule {
    public static forRoot(): ModuleWithProviders<FapServicesModule> {
        return {
            ngModule: FapServicesModule,
            providers: [
                AuthService,
                UserService,
                WebsocketService,
                WidgetsWebsocketService,
            ]
        };
    }
}
