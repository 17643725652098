import { Routes } from '@angular/router';
import { CropsContainerComponent } from './containers/crops-container/crops-container.component';
import { CropItemComponent } from './components/crop-item/crop-item.component';


export const cropsRoutes: Routes = [
    {
        path:       '',
        component:  CropsContainerComponent,
        data: {
            filters: {
                hasFarmFilter: true,
                hasCropsFilter: true,
            },
        }
    },
    {
        path: 'edit/:cropId',
        component: CropItemComponent
    },
    {
        path: 'add',
        component: CropItemComponent
    },
];
