import { environment } from '../../../environments/environment';

const layoutConfig = environment.layoutConfig;

export class LayoutConfig {
    static data = {
        settings: {
            layout_type: layoutConfig.settings.layout_type, // 'ltr'
            sidebar: {
                type: layoutConfig.settings.sidebar.type, // 'default'
                body_type: layoutConfig.settings.sidebar.body_type, // 'default'
            },
            sidebar_setting: layoutConfig.settings.sidebar_setting, // 'default-sidebar'
            sidebar_backround: layoutConfig.settings.sidebar_backround, // 'dark-sidebar'
        },
        color: {
            layout_version: layoutConfig.color.layout_version, // 'light'
            color: layoutConfig.color.color, // 'color-2'
            primary_color: layoutConfig.color.primary_color, // '#4466f2'
            secondary_color: layoutConfig.color.secondary_color, // '#1ea6ec'
            mix_layout: layoutConfig.color.mix_layout, // 'default'
        },
        router_animation: layoutConfig.router_animation, // 'fadeIn'
    };
}

