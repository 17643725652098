<fap-header *ngIf="shouldShowHeader">
    <fap-header-actions [title]="'alergotel.reports' | translate"></fap-header-actions>
</fap-header>
<div class="report_detail_page">

    <ng-container *ngIf="report && !isLoading">
        <div class="location">
            <div class="location_name">
                {{report?.location}}
            </div>
            <div class="location_img">
                <img src="../../../../../../assets/images/map.png" alt="map" *ngIf="!lotImage">
                <img [src]="lotImage" alt="lot" *ngIf="lotImage">

                <mat-form-field #select>
                    <!-- <mat-label>{{"farm.lot.lot" | translate}}</mat-label> -->
                    <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="lot" (selectionChange)="getLot($event.value)" [(ngModel)]="lotId">
                        <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #personFilter placeholder="Search" (keydown.enter)="$event.stopPropagation()">
                        </mat-form-field>
                        <mat-label style="position: absolute; right: 65px; cursor: pointer;">
                            <i class="fa fa-search f-20"></i>
                        </mat-label>
                        <div class="input-group-append pl-1" (click)="goToLot()">
                            <span class="input-group-text">
                                <!-- <i class="icofont icofont icofont-plus"></i> -->
                                 <fap-round-button></fap-round-button>
                             </span>
                        </div>
                    </div>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option class="noteIcons" *ngFor="let lot of globalRegistry?.systemData?.lots | filter : personFilter.value" [value]="lot.id">
                            <span class="mat-option-text">
                            <div class="name">{{ lot?.name }}</div>
                            <div class="actions">
                                <i class="fa fa-edit" (click)="goToLot(lot?.id); $event.stopPropagation()"></i>
                                <!-- <i class="fa fa-trash" (click)="deleteLot(lot?.id); $event.stopPropagation()"></i> -->
                            </div>
                            </span>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="infos_section">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "alergotel.infestedSurface" | translate }} (sq.m)</mat-label>
                <input matInput type="text" [value]="report?.infested_area">
            </mat-form-field>
        </div>

        <div class="close_report">
            <button class="btn" [routerLink]="isBaseApplication ? ['/pages/alergotel_close-report/', report.id] : ['/alergotel/close-report/', report.id]">{{ 'alergotel.close' | translate }}</button>
        </div>

        <div class="main_info">
            <div class="history_row d-flex justify-content-between align-items-center">
                <div class="date">
                    {{report?.timestamp | date: 'dd MMM yyyy'}} <span class="time">{{report.timestamp | date: 'HH:mm'}}</span>
                </div>
                <div class="status">
                    <ng-container *ngIf="report.status">
                        <ng-container *ngFor="let code of statusCodes">
                            <span *ngIf="code.key === report?.status && !report?.to_confirm">{{ 'alergotel.status.'+code.value | translate }}</span>
                            <button class="btn" [routerLink]="isBaseApplication ? ['/pages/alergotel_validate-report/', report.id] : ['/alergotel/validate-report/', report.id]" [queryParams]="{ history: report?.id }" *ngIf="code.key === report?.status && report?.to_confirm">{{ 'alergotel.confirm' | translate }}</button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!report.status">
                        -
                    </ng-container>
                </div>
                <div class="user">
                    <ng-container *ngIf="report?.user">
                        <div class="name w-100">{{report?.user?.nickname}}</div>
                        <div class="d-flex justify-content-between">
                            <div class="badges d-flex">
                                <ng-container *ngIf="report.user.badges > 0">
                                    <div *ngFor="let badge of getBadgeArray(report.user.badges)">
                                        <img src="../../../../../../assets/icons/rating.png" alt="rating">
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="!report.user"> - </div>
                </div>
            </div>
            <div class="images" *ngIf="report.images && report.images.length">
                <ng-container *ngFor="let image of report.images">
                    <img [src]="mediaUrl + image.file" alt="image">
                </ng-container>
            </div>
            <div class="info" *ngIf="report?.info">
                {{report?.info}}
            </div>
        </div>

        <ng-container *ngIf="report.history.length">
            <div class="history_section" *ngFor="let row of report.history">
                <div class="history_row d-flex justify-content-between align-items-center">
                    <div class="date">
                        {{row?.timestamp | date: 'dd MMM yyyy'}} <span class="time">{{row.timestamp | date: 'HH:mm'}}</span>
                    </div>
                    <div class="status">
                        <ng-container *ngIf="row.status">
                            <ng-container *ngFor="let code of statusCodes">
                                <span *ngIf="code.key === row?.status && !row?.to_confirm">{{ 'alergotel.status.'+code.value | translate }}</span>
                                <button class="btn" [routerLink]="isBaseApplication ? ['/pages/alergotel_validate-report/', report.id] : ['/alergotel/validate-report/', report.id]" [queryParams]="{ history: row?.id }" *ngIf="code.key === row?.status && row?.to_confirm">{{ 'alergotel.confirm' | translate }}</button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!row.status">
                            -
                        </ng-container>
                    </div>
                    <div class="user">
                        <ng-container *ngIf="row?.user">
                            <div class="name w-100">{{row?.user?.nickname}}</div>
                            <div class="d-flex justify-content-between">
                                <!-- <div class="role_and_rank d-flex" *ngIf="row?.user?.role">
                                    <div class="role">
                                        <div *ngIf="AlergotelRole.isAuthority(row?.user?.role)">Authority</div>
                                        <div *ngIf="AlergotelRole.isLandowner(row?.user?.role) && !AlergotelRole.isAuthority(row?.user?.role)">Landowner</div>
                                        <div *ngIf="AlergotelRole.isSuperuser(row?.user?.role) && !AlergotelRole.isLandowner(row?.user?.role) && !AlergotelRole.isAuthority(row?.user?.role)">Superuser</div>
                                        <div *ngIf="AlergotelRole.isRegistered(row?.user?.role) && !AlergotelRole.isSuperuser(row?.user?.role) && !AlergotelRole.isLandowner(row?.user?.role) && !AlergotelRole.isAuthority(row?.user?.role)">Registered</div>
                                    </div>
                                    <div class="rank">
                                        <span>{{row?.user?.rank}}</span>
                                    </div>
                                </div> -->
                                <div class="badges d-flex">
                                    <ng-container *ngIf="row.user.badges > 0">
                                        <div *ngFor="let badge of getBadgeArray(row.user.badges)">
                                            <img src="../../../../../../assets/icons/rating.png" alt="rating">
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="!row.user"> - </div>
                    </div>
                </div>
                <div class="images">
                    <ng-container *ngIf="row.images && row.images.length">
                        <img *ngFor="let image of row.images" [src]="mediaUrl + image.file" alt="image">
                    </ng-container>
                </div>
                <div class="info" *ngIf="row?.info">
                    {{row?.info}}
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
