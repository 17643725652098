import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTranslation'
})
export class TranslationPipe implements PipeTransform {
  public transform(translationKey: any, translationsMap: any, language: string): string {
    
    if (!translationsMap) {
      return "?"
    }
    const result = translationsMap.get(translationKey);
    return result ? result[language].trim() : translationKey;
    }
  }
