import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fap-section-container',
  templateUrl: './fap-section-container.component.html',
  styleUrls: ['./fap-section-container.component.scss']
})
export class FapSectionContainerComponent {
  
  @Input() public title: string = '';
  @Input() public isEditable: boolean = false;
  @Input() public isAddEnabled: boolean = false;
  @Input() public isTable: boolean = false;
  @Input() public isRefresh: boolean = false;
  @Input() public sticky: boolean = true;

  @Output()
  public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public refreshClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

}
