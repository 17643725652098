<div class="table-wrap">
    <h4 class="table-header-title">
        {{ 'stock.transactions.title' | translate }}
    </h4>

    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-striped">
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> {{ 'stock.transactions.headers.number' | translate }} </th>
                <td mat-cell *matCellDef="let element;let i = index;"> {{ i + pageSize*pageIndex + 1 }} </td>
            </ng-container>

            <ng-container matColumnDef="resource">
                <th mat-header-cell *matHeaderCellDef> {{ 'stock.transactions.headers.resource' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.resource_entry.stock_item.type.name}} </td>
            </ng-container>

            <ng-container matColumnDef="transaction">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="element.type === 'income'" aria-hidden="false" aria-label="Transaction" color="primary">trending_up</mat-icon>
                    <mat-icon *ngIf="element.type === 'outcome'" aria-hidden="false" aria-label="Transaction" color="warn">trending_down</mat-icon>
                    <span>{{ element?.quantity }} {{element?.unit?.short_name}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> {{ 'stock.transactions.headers.cost' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <span>{{ element?.cost | currency:'RON' }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef> {{ 'stock.transactions.headers.date' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <p class="mb-1 pt-1">{{ element?.created_by }}</p>
                    <span class="d-block mb-1">{{ element?.created_at | date: 'short' }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="viewBill">
                <th mat-header-cell *matHeaderCellDef>{{ 'stock.transactions.headers.bill' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <button class="btn btn-success btn-sm">bill</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator
        #paginator
        class="d-flex justify-content-center"
        [length]="totalNoOfTransactions"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[10, 20, 30]"
        showFirstLastButtons>
    </mat-paginator>
</div>
