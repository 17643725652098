import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { UnitInterface } from '../../../../../core/interfaces/unit/unit.interface';
import { AttributeModel } from '../../../../../core/models/attribute/attribute.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { FieldService } from '../../../../../core/services/api/farm/field.service';
import { NotesService } from '../../../../../core/services/api/farm/notes.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'fap-attribute-forms',
  templateUrl: './fap-attribute-forms.component.html',
  styleUrls: ['./fap-attribute-forms.component.scss']
})
export class FapAttributeFormsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public objectId: number;

  @Input()
  public relation: any;

  @Input()
  public entityId: number;

  @Input()
  public formType: any;

  @Output()
  public closeRelations: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public updateRelations: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  public attributeForm: UntypedFormGroup;
  public addEditAttributeForm: UntypedFormGroup;
  public imgPath = ''
  public attributes = [];
  public selectedAttribute: AttributeModel;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  public mediaUrl = environment.mediaUrl;
  public nextImgToken :{ offset: number; limit: number; group: string;} = null;
  public getMoreImg = true;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  @ViewChild('addEditAttributePopup')
  public addEditAttributePopup: FapModalComponent;
  @ViewChild('addEditTranslationPopup')
  public addEditTranslationPopup: FapModalComponent;
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  public icon: any;
  public valueTypes = ['string', 'number', 'entity', 'datetime', 'boolean', 'image', 'coords', 'surface', 'polyline'];
  public inputTypes = ['single', 'multiselect', 'range', 'choice'];
  public filteredInputTypes = [];
  public units: Array<UnitModel> = [];
  public unitForm: UntypedFormGroup;
  public selectedUnitId: number;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  public allEntities = [];
  public langString: string;
  public isEdit = false
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
  @ViewChild('nameInput1', { static: true }) nameInput1: ElementRef;
  public translatedNames:any = [];
  public nameT: any = null;
  public fields = [];
  public translationType = null;
  public selectedFormType = null;
  public translation = null;
  public prefix = '';
  public label: string;
  @ViewChild('addEditTranslationsModal')
  public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditFormTranslationsModal')
  public addEditFormTranslationsModal: FapModalComponent;
  public closeCombo: boolean = true;

  constructor(public formBuilder: UntypedFormBuilder, public globalRegistry: GlobalRegistryService, public companyService: CompanyService, public toastrService: ToastrService, public translateService: TranslateService, public cacheService: CacheResolverService, public confirmModalService: ConfirmModalService, public unitService: UnitService, public equipmentService: EquipmentService, public notesService: NotesService, public fieldService: FieldService, public typeService: TypesService, public navService: NavService) {
   }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
    this.initNewRelationForm();
    this.initaddEditAttributeForm();
    this.getModels();
    this.getFormTypes();
    this.filteredInputTypes = this.inputTypes
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'formType') && this.formType) {
      console.log(this.formType);
      const itemIds = this.formType.fields.map(item => item.type);
      this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id));
      console.log(itemIds);
      this.initNewRelationForm();
      setTimeout(() => {
        this.attributeForm.controls['fields'].setValue(itemIds);
      }, 1000);
      console.log(this.attributeForm.value)
    }
  }

  public initNewRelationForm(): void {
    this.attributeForm = this.formBuilder.group({
        name: [this.selectedFormType ? this.selectedFormType.name_t ? this.selectedFormType.name_t[this.langString] : this.selectedFormType.name : '', Validators.required],
        fields: [this.formType ? this.formType.fields : [], Validators.required],
    });
    console.log(this.formType)   
    console.log(this.selectedFormType);
  }

  public initaddEditAttributeForm(): void {
    this.addEditAttributeForm = this.formBuilder.group({
        name_t: [this.selectedAttribute ? this.selectedAttribute.name : null, Validators.required],
        color: [this.selectedAttribute ? this.selectedAttribute.color : '#000000', Validators.required],
        icon: [this.selectedAttribute ? this.selectedAttribute.icon ? this.selectedAttribute.icon.id : null : null, Validators.required],
        entity: [this.selectedAttribute ? this.selectedAttribute.entity ? this.selectedAttribute.entity.id : null : null, Validators.required],
        input_type: [this.selectedAttribute ? this.selectedAttribute.inputType : 'single', Validators.required],
        unit: [this.selectedAttribute ? this.selectedAttribute.unit : null, Validators.required],
        value_type: [this.selectedAttribute ? this.selectedAttribute.valueType : 'number', Validators.required],
    });
    if(this.selectedAttribute) {
      this.validateInputType(this.selectedAttribute.inputType);
    }
  }

  public getModels() {
    this.typeService.getModels().subscribe(data => {
      this.allEntities = data.body.results;
    })
  }

  public getFormTypes() {
    if (this.relation === 'resource_type') {
      const formTypeId = this.formType.type;
      console.log(formTypeId);
      this.typeService.getFormTypeById(formTypeId).subscribe(data => {
        this.selectedFormType = data.body.results
        if (this.selectedFormType) {
          // let name = '';
          // if(this.selectedFormType.name_t) {
          //   this.translatedNames.filter(trans => {
          //     if (trans.id === this.selectedFormType.name_t[this.langString]) {
          //       name = trans[this.langString];
          //     }
          //   });
          // } else {
          //   name = this.selectedFormType.name
          // }
          this.initNewRelationForm();
        }
      })
    } else {
      const url = this.typeService.getUrl('form_type/');
      this.cacheService.delete(url + 'entity=' + this.relation);
      const params = { entity: this.relation };
  
      this.typeService.getFormTypes(params).subscribe(data => {
        this.selectedFormType = data.body.results[0];
        // this.selectedFormType = this.convertKeysToCamelCase(this.selectedFormType)
        console.log(this.selectedFormType);
  
        if (this.selectedFormType) {
          // let name = '';
          // this.translatedNames.filter(trans => {
          //   if (trans.id === this.selectedFormType.name_t[this.langString]) {
          //     name = trans[this.langString];
          //   }
          // });
          this.initNewRelationForm();
          // let itemIds = this.selectedFormType.fields.map(item => item.type);
          // this.attributeForm.get('name').setValue(this.selectedFormType.name_t[this.langString]);
          // this.attributeForm.get('fields').setValue(itemIds);
          // this.fields = this.selectedFormType.fields;
          // console.log(this.attributeForm.value);
        }
      });
    }
  } 

  public closeRelationsModal(): void {
    this.closeRelations.emit(false);
  }

  public onSubmitNewtRelation(): void {
    if (this.attributeForm.invalid) {
        this.attributeForm.markAllAsTouched();
        return;
    }
    console.log(this.entityId);
    if(this.nameT === null) {
      this.translatedNames.filter(trans => {
        if(trans.id === this.selectedFormType.name_t) {
          this.nameT = trans;
        }
      });
    }
    console.log(this.selectedFormType);
    console.log(this.nameT);
    const body = {
      name_t: this.selectedFormType.name_t.id,
      fields: this.attributeForm.value.fields
    }
    console.log(body);
    if(this.entityId) {
      this.typeService.patchFormTypes(this.entityId, body).subscribe(data => {
        console.log(data);
        this.formType = data.body.results;
        this.globalRegistry.reloadAttributes();
        const itemIds = this.formType.fields.map(item => item.type);
        this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id));
        this.closeRelationsModal();
        this.updateRelations.emit(true);
      })
    }
  }

  addEditAttribute(attribute?:any) {
    console.log(attribute);
    this.addEditAttributeForm.clearValidators();
    this.icon = null;
    if(this.selectedAttribute) {
      this.validateInputType(this.selectedAttribute.inputType);
      this.icon = this.selectedAttribute.icon
    }
    this.selectedAttribute = attribute;
    this.initaddEditAttributeForm();
    this.addEditAttributePopup.showModal();
    this.closeCombo = true;
  }

  submitAttribute() {
    if(!this.nameT) {
      this.translatedNames.filter(trans => {
        if(trans.id === this.selectedAttribute.nameT) {
          this.nameT = trans;
        }
      });
    }
    console.log(this.nameT);
    const body = {
      name_t: this.nameT.id,
      color: this.addEditAttributeForm.value.color,
      icon: this.addEditAttributeForm.value.icon,
      value_type: this.addEditAttributeForm.value.value_type,
      unit: this.addEditAttributeForm.value.unit,
      input_type: this.addEditAttributeForm.value.input_type,
      entity: this.addEditAttributeForm.value.entity      
    }
   
    if(this.selectedAttribute) {
      this.typeService.updateAttribute(this.selectedAttribute.id, body).subscribe(() => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.formTypeUpdatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null
        this.icon = null;
        this.selectedAttribute = null;
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        setTimeout(() => {
          const itemIds = this.formType.fields.map(item => item.type);
          this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id));
        }, 1000)
      })
    } else {
      this.typeService.createAttribute(body).subscribe(() => {
        this.globalRegistry.reloadAttributes();
        this.toastrService.success(this.translateService.instant('attribute.formTypeCreatedSuccessfully'))
        this.addEditAttributePopup.hideModal();
        this.nameT = null;
        this.icon = null;
        this.selectedAttribute = null;
        this.addEditAttributeForm.reset();
        this.addEditAttributeForm.clearValidators();
        this.initaddEditAttributeForm();
        setTimeout(() => {
          const itemIds = this.formType.fields.map(item => item.type);
          this.fields = this.globalRegistry.systemData.attributes.filter(item => itemIds.includes(item.id));
        }, 1000)
      })   
    }
  }

  editAttributeValue(ev) {
    console.log(ev);
  }

  deleteAttribute(ev) {
    this.typeService.deleteAttribute(ev).subscribe(()=>{
      this.globalRegistry.reloadAttributes();
      this.addEditAttributePopup.hideModal();
      this.nameT = null;
      this.toastrService.success(this.translateService.instant('attribute.formTypeCreatedSuccessfully'))
    }, err => {
      this.toastrService.error(err.error.results.error);
    });
  }

  validateInputType(type) {
    const inputTypes = this.inputTypes;
    if(type === 'number') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'multiselect'));
    }
    if(type === 'string') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range'));
    }
    if(type === 'datetime') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'multiselect'));
    }
    if(type === 'entity') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range'));
    }
    if(type === 'boolean' || type === 'coords' || type === 'polyline' || type === 'surface') {
      this.filteredInputTypes = inputTypes.filter(type => !(type === 'range' || type === 'multiselect'));
    }
  }

  removeFromList(id) {
    this.fields = this.fields.filter(item => item.id !== id);
    const itemIds = this.fields.map(item => item.id);
    this.attributeForm.get('fields').patchValue(itemIds);
  }

  public collectAttributes(ev) {
    console.log(ev.value);
    this.fields = this.globalRegistry.systemData.attributes.filter(item => ev.value.includes(item.id));
    console.log(this.fields);
  }

  addImage(ev) {
    console.log(ev)
    const imgObj = {
        id: ev.value.id,
        file: ev.value.file
    }
    this.icon = imgObj.id;
    this.imgPath = imgObj.file;
    this.addEditAttributeForm.get('icon').setValue(this.icon);
  }
  
    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
      if(unit?.locked) {
        return;
    }
      this.currentUnit = unit;
      this.parentUnit = parent ? parent : null;
      if(genre) {
        console.log(genre);
        this.units = this.globalRegistry.systemData.units.filter(unit => {
          return unit.genre == genre
        });
      }
      this.initunitForm();
      this.addEditUnitModal.showModal();
    }
    
    public onDeleteUnit(unit: UnitModel): void {
      if(unit?.locked) {
        return;
    }
     
      this.confirmModalService.openConfirmModal(
          this.translateService.instant('confirmDelete'),
          this.translateService.instant('units.deleteUnitConfirmMessage')
      );
      this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
          if (confirmed) {
              this.deleteUnit(unit.id);
          }
      });
    }
    
    public onUnitAction(unitId: number): void {
      this.selectedUnitId = unitId;
      this.addEditUnitModal.hideModal();
    }

    public submit(): void {

      if(this.unitForm.dirty) {
          const unit: UnitInterface = {
              name: this.unitForm.controls['name'].value,
              shortName: this.unitForm.controls['shortName'].value,
              genre: this.unitForm.controls['genre'].value
          };
    
          if(typeof this.unitForm.controls['parent'].value !== 'string') {
              unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                  null :
                  this.unitForm.controls['parent'].value;
          }
    
          if(this.currentUnit) {
              this.updateUnit({...{id: this.currentUnit.id}, ...unit});
              this.globalRegistry.reloadUnits();
          }
          else {
              this.addUnit(unit);
              this.globalRegistry.reloadUnits();
          }
          this.getUnits()
      }
    }
    
    // public getExchangeUnits(unit) {
    //   let params = {
    //     genre: '$',
    //     exchange: unit
    //   }
    //   this.subscriptions.push(this.unitService.getUnits(params).subscribe(data => {
    //     this.exchangeCurrencies = data.model;
    //   }))
    // }
    
    public getUnits() {
      this.subscriptions.push(this.unitService.getUnits().subscribe(data => {
        this.units = data.model;
      }))
    }
    
    private initunitForm(): void {
      this.unitForm = this.formBuilder.group({
          name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100)
              ])
          ],
          shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(1),
                  Validators.maxLength(100)
              ])
          ],
          parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                  Validators.required
              ])
          ],
          genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                  Validators.required
              ])
          ]
      });
    }
    
    private addUnit(unit: UnitInterface): void {
      this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
          this.toastrService.success(this.translateService.instant('units.unitAddSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(response.model.id);
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.unitAddError'));
      });
    }
    
    private updateUnit(unit: UnitInterface): void {
      this.unitService.updateUnit(unit).subscribe((): void => {
          this.toastrService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(this.currentUnit.id);
    
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.UnitUpdateError'));
      });
    }
    
    public deleteUnit(unitId: number): void {
      this.unitService.deleteUnit(unitId).subscribe((): void => {
          this.toastrService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
          this.globalRegistry.reloadUnits();
      },
      (): void => {
          this.toastrService.error(this.translateService.instant('units.UnitDeleteError'));
      });
    }
    
    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.unitForm.controls[controlName];
      if (!control) {
          return false;
      }
    
      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }

    //translation
    ngOnDestroy(){
      this.subscriptions.forEach(s => {
        s.unsubscribe();
      })
  }

  getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
          return translation
        }
    }

    convertKeysToCamelCase(obj) {
      const camelCaseObj = {};
      for (const key in obj) {
        const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
        camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
      }
      return camelCaseObj;
    }

    // addEditTranslation(input) {
    //   let type: any;
    //   if(input === 'nameInput') {
    //     this.translationType = 'attribute'
    //     type = this.convertKeysToCamelCase(this.selectedAttribute)
    //   } else {
    //     this.translationType = 'field'
    //     type = this.convertKeysToCamelCase(this.selectedFormType)
    //   }
    //     const name = this[input].nativeElement.value;
    //     console.log(type);
    //   if(!name || name === '' || type.nameT === null) {
    //     this.isEdit = false;
    //       this.showModal();
    //   } else {
    //     this.isEdit = true;
    //       this.translatedNames.forEach(translation => {
    //           if(translation.id === type.nameT && (translation[this.langString] === '' || translation[this.langString] == null)) {
    //               translation[this.langString] = name
    //               this.editTranslation(translation);
    //           }
    //           if(translation[this.langString] === name || translation.id === type.nameT) {
    //               console.log(translation.id);
    //               this.editTranslation(translation);
    //           }
    //       });
    //   }
    // }

    addEditTranslation(label: string, inputElement: any, translationTarget: any, popup: any, form: any) {
      this.label = label;
      const name = this[inputElement].nativeElement.value;
      const type: any = this.convertKeysToCamelCase(translationTarget);
      console.log(name);
      console.log(type);
      if (name === null || name === '' || type.nameT === null) {
          this.translation = null;
          popup.showModal();
      } else {
          for (const translation of this.globalRegistry.systemData.translations) {
              if (type && translation.id === type.nameT) {
                  this.translation = translation;
                  console.log(this.translation);
                  popup.showModal();
                  return;
              }
          }
  
          if (Object.keys(type).length === 0 && name) {
              const newTranslation = this[form].controls['name_t'].value;
              for (const translation of this.globalRegistry.systemData.translations) {
                  if (translation.id === newTranslation) {
                      this.translation = translation;
                      console.log(this.translation);
                      popup.showModal();
                      return;
                  }
              }
          }
      }
  }
      onAddUpdateTranslation(translation) {
          console.log(translation);
          this.translation = translation;
          this.addEditAttributeForm.controls['name_t'].setValue(translation[this.langString]);
          this.nameT = translation
          this.addEditTranslationsModal.hideModal();
      }

      onAddUpdateFormTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.attributeForm.controls['name'].setValue(translation[this.langString]);
        this.nameT = translation
        this.addEditFormTranslationsModal.hideModal();
    }


  hideModal() {
    this.addEditTranslationPopup.hideModal();
}

}
