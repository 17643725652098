<div class="widget-card">
    <div class="widget-card-title">
        <div class="d-flex align-items-center">
            {{ 'widget.alerts' | translate }}
            <i class="material-icons ml-1">add</i>
        </div>
        <div *ngIf="widgetConfig&&widgetConfig['objectName']" class="widget-content-type-text">
            {{ widgetConfig['contentType'] | translate }} - {{ widgetConfig['objectName'] }}
        </div>
    </div>
    <div class="widget-card-body">
        <fap-alert-list-row>
        </fap-alert-list-row>
        <fap-alert-list-row>
        </fap-alert-list-row>
        <fap-alert-list-row>
        </fap-alert-list-row>
        <fap-alert-list-row>
        </fap-alert-list-row>
    </div>
</div>
