import { Component } from '@angular/core';

@Component({
  selector: 'fap-section-body',
  templateUrl: './fap-section-body.component.html',
  styleUrls: ['./fap-section-body.component.scss']
})
export class FapSectionBodyComponent {

}
