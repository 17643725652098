import {
    Component
} from '@angular/core';

@Component({
    selector: 'fap-alert-list-row',
    templateUrl: './fap_alert-list-row.component.html',
    styleUrls: ['./fap_alert-list-row.component.scss']
})
export class FapAlertListRowComponent {

}
