<div class="row m-0" id="parent">
    <div class="col-12 p-0">
        <div class="card notedit">
            <fap-section-container [title]="'widget.overview' | translate">
                <form [formGroup]="notesForm">
                    <div class="form-group">
                        <div class="row align-items-baseline">
                            <div class="col-6">
                                <div class="input-group align-items-baseline w-100">
                                    <!-- <mat-form-field class="w-100">
                                        <mat-label>{{ 'notes.name' | translate }}</mat-label>
                                        <input matInput formControlName="name">
                                        <mat-error *ngIf="notesTypeForm.controls['name'].errors?.required">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field> -->

                                    <mat-form-field class="w-100" (click)="addEditTranslation('notes.name', 'nameInput1', note, addEditNameTranslationsModal, 'notesForm')">
                                        <mat-label>{{ "notes.name" | translate }}</mat-label>
                                        <input
                                            matInput
                                            formControlName="name"
                                            name="name"
                                            class="p-0"
                                            style="height: 16px;"
                                            readonly #nameInput1
                                        />
                                        <mat-error
                                                *ngIf="
                                                notesForm.controls[
                                                        'name'
                                                    ].errors?.required
                                                "
                                            >
                                                <strong>{{
                                                    "auth.validation.requiredField"
                                                        | translate
                                                }}</strong>
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-6">
                                <fap-add-edit-delete-select
                                        inputName="{{ 'notes.noteLinkedTo' | translate }}"
                                        class="w-100"
                                        [formControl]="notesForm.controls['parent']"
                                        name="plan"
                                        [inputIsRequired]="true"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="notes"
                                        [inputConfirmDeleteText]="'notes.parentDeleteMessage' | translate"
                                        [closeCombo]="closeCombo"
                                        [isEditable]="false"
                                        [isDeleteable]="false"
                                        [createEnabled]="false"
                                        (clicked)="setParent($event)"
                                        (selectionChange)="notesForm.get('parent').setValue($event)"
                                        (outputOnScroll)="scrolledDownNotes.emit()"
                                        (outputOnSearch)="filterNotes.emit($event)"
                                    >
                                    </fap-add-edit-delete-select>
                            </div>
                        </div>
                        <div class="row align-items-baseline">
                            <div class="col-4">
                                <div class="input-group align-items-baseline w-100">

                                    <fap-add-edit-delete-select 
                                        [inputName]="'notes.note_type' | translate"
                                        class="w-100"
                                        [formControl]="notesForm.controls['type']"
                                        name="type"
                                        [inputIsRequired]="true"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="types"
                                        [inputConfirmDeleteText]="'notes.deleteNoteTypeMessage' | translate"
                                        (outputOnCreate)="showNoteTypeModal(true); closeCombo = false;"
                                        (outputOnEdit)="noteTypeEdit($event); closeCombo = false;"
                                        (outputOnDelete)="noteTypeDelete($event);"
                                        [closeCombo]="closeCombo"
                                        (clicked)="verifyFormType($event)"
                                        (selectionChange)="notesForm.get('type').setValue($event)"
                                        >
                                </fap-add-edit-delete-select>
                                </div>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="pos_rel w-100">
                                    <input matInput [owlDateTime]="dt1" placeholder="Time" formControlName="timestamp" [max]="maxDate" (change)="minValueChanged($event)">
                                    <span class="datetimepicker" [owlDateTimeTrigger]="dt1"><i class="fa fa-calendar"></i></span>
                                    <owl-date-time [pickerMode]="'dialog'" #dt1></owl-date-time>
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="pos_rel w-100">
                                    <input matInput [owlDateTime]="dt2" placeholder="Until" formControlName="until" [min]="minDate" (change)="maxValueChanged($event)">
                                    <span class="datetimepicker" [owlDateTimeTrigger]="dt2"><i class="fa fa-calendar"></i></span>
                                    <owl-date-time [pickerMode]="'dialog'" #dt2></owl-date-time>
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-12 mb-2">
                                <chips 
                                    [items]="globalRegistryService.systemData.lots" 
                                    [selectedItems]="selectedItems"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div> -->
                            <div class="col-4">
                                <mat-form-field class="w-100">
                                    <mat-label>{{"notes.coords" | translate}}</mat-label>
                                    <input class="w-100" matInput readonly [min]="notesForm.controls['coords'].value"
                                        formControlName="coords" [value]="coordsString">    
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <div class="input-group w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{"Assigned to"}}</mat-label>
                                        <mat-select formControlName="assigned_to" name="assigned_to">
                                            <mat-form-field appearance="fill" class="w-100">
                                                <!-- <mat-label>
                                                    <i class="fa fa-search f-20"></i>
                                                </mat-label> -->
                                                <input matInput #personFilter placeholder="Seach">
                                            </mat-form-field>
                                            <div class="scroll_wrap">
                                            <mat-option style="height: 0!important;"></mat-option>
                                            <mat-option *ngFor="let person of personList | filter:personFilter.value" [value]="person?.id">{{person?.firstName}} {{person?.lastName}} {{ person?.first_name }} {{ person?.last_name }}</mat-option>
                                        </div>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                                <div class="col-4">
                                    <fap-add-edit-delete-select
                                        inputName="{{ 'auth.company.plan' | translate }}"
                                        class="w-100"
                                        [formControl]="notesForm.controls['plan']"
                                        name="plan"
                                        [inputIsRequired]="true"
                                        [apiSearchEnabled]="true"
                                        [inputValueFieldName]="'id'"
                                        [inputNameFieldName]="'name'"
                                        [inputNameTFieldName]="'nameT'"
                                        [inputSelectValues]="globalRegistryService?.systemData?.plans"
                                        (selectionChange)="updatePlanNote($event)"
                                        [inputConfirmDeleteText]="'auth.company.planDeleteMessage' | translate"
                                        (outputOnDelete)="onDeletePlan($event)"
                                        (outputOnCreate)="addPlanModal(); closeCombo = false;"
                                        (outputOnEdit)="editPlan($event); closeCombo = false;"
                                        [closeCombo]="closeCombo"
                                    >
                                    </fap-add-edit-delete-select>
                                </div>
                            
                        </div>
                    </div>
                    <fap-image-list [imageIds]="noteImages" [group]="'notes'" (deleteImage)="deleteNoteImage($event)" (addImage)="addImage($event)"></fap-image-list>
                    <div class="form-group">
                        <div class="row ">
                            <div class="col-12">
                                <mat-form-field class="w-100">
                                    <mat-label>{{"notes.info" | translate}}</mat-label>
                                    <textarea matInput formControlName="info" name="info" cols="30" rows="10" class="text-background p-2"></textarea>
                                    <!-- <input matInput formControlName="info" name="info" placeholder="Info"> -->
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row ">
                            <div class="col-12">
                                <div class="input-group justify-content-center">
                                    <mat-label> {{ 'notes.closed' | translate }} </mat-label>
                                    <mat-slide-toggle color="#34A59A" formControlName="active" name="active">{{ 'notes.open' | translate }}</mat-slide-toggle>
                                </div>
                            </div>
                            <!-- <div class="col-7">
                                <button class="btn btn-success"
                                    (click)="submitForm()">{{ 'notes.submit' | translate }}</button>
                            </div> -->
                        </div>
                    </div>
                </form>
            </fap-section-container>
        </div>
<fap-modal #addNoteTypeModal
    [inputPrimaryButtonInterface]="addNoteTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addNoteTypeModalButtonSecondaryInterface"
    [inputTitle]="'notes.addEditNoteType' | translate">
    <form [formGroup]="notesTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput', noteType, addEditTranslationsModal, 'notesTypeForm')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="notesTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <fap-add-edit-delete-select
                inputName="{{ 'stock.formType' | translate }}"
                class="w-100"
                [formControl]="notesTypeForm.controls['form_type']"
                name="formType"
                [inputIsRequired]="true"
                [apiSearchEnabled]="true"
                [inputValueFieldName]="'id'"
                [inputNameFieldName]="'name'"
                [inputNameTFieldName]="'nameT'"
                [inputSelectValues]="formTypes"
                [closeCombo]="closeCombo"
                [isEditable]="false"
                [isDeleteable]="false"
                [createEnabled]="false"
                (selectionChange)="notesTypeForm.get('form_type').setValue($event)"
                (clicked)="setFormType($event)"
            >
            </fap-add-edit-delete-select>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="notesTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

            <fap-attributes *ngIf="objectId && entityInfo"
                [entityRelation]="'note'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="objectId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>

<div class="overlay" *ngIf="isPerson" (click)="isPerson=false"></div>
    <div class="popup" *ngIf="isPerson">
        <fap-people-card-list [persons]="personList" (closeModal)="closeModal($event)"></fap-people-card-list>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(noteType)" 
        [prefix]="prefix" 
        [translation]="translation"
        [group]="'types'"
        [label]="label"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditNameTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(note)" 
        [prefix]="'NOTE_'" 
        [translation]="translation"
        [group]="'notes'"
        [label]="label"
        (submitTranslation)="onAddUpdateNameTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>