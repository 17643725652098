<fap-header>
    <div class="add_circle mr-3">
        <mat-form-field>
            <mat-label>{{'workflow.workflowTypes' | translate}}</mat-label>
            <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="workflowTypes">
                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #workflowFilter placeholder="Search" (keydown.enter)="$event.stopPropagation()">
                </mat-form-field>
                <mat-label style="position: absolute; right: 30px; cursor: pointer;">
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <!-- <div class="input-group-append pl-1">
                    <span class="input-group-text">
                        <i class="icofont icofont icofont-plus" (click)="newWorkflow()"></i>
                     </span>
                </div> -->
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let type of workflows | filter : workflowFilter.value" [value]="type.id" (click)="editWorkflowType(type?.id);">
                    <span class="mat-option-text">
                    <div class="name">{{ type?.name ? type?.name : type?.uniqueid }}</div>
                    <!-- <div class="actions">
                        <i class="fa fa-edit center f-20" (click)="editWorkflow(type?.id);"></i>
                        <i class="fa fa-trash center f-20 ml-1" (click)="deleteWorkflow(type?.id); $event.stopPropagation()"></i>
                    </div> -->
                    </span>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>
    </div>
    <button class="back_icon" *ngIf="workflowInitiated" (click)="clearNav()"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
</fap-header>
<div class="workflow_page">
    <div *ngIf="!workflowInitiated" class="cards-grid d-flex flex-col flex-wrap flex-md-{grow|shrink}-1 justify-content-md-start justify-content-center">
        <ng-container *ngFor="let workflow of workflows">
            <workflow-card [workflow]="workflow" (executeWorkflow)="executeWorkflow($event)" 
            (toggleTableView)="toggleTableView($event)" 
            (continueWorkflow)="getWorkflow($event)"
            (deleteWorkflow)="deleteWorkflow($event)"></workflow-card>
        </ng-container>
    </div>

    <div class="workflow_table" *ngIf="workflowInitiated">
        <table>
            <thead>
                <tr>
                    <th>{{'workflow.icon' | translate}}</th>
                    <th>{{'workflow.name' | translate}}</th>
                    <th>{{'workflow.createdAt' | translate}}</th>
                    <th>{{'workflow.createdBy' | translate}}</th>
                    <th>{{'workflow.steps' | translate}}</th>
                    <th>{{'workflow.status' | translate}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let workflow of workflowList">
                    <td>
                        <div class="image">
                            <div class="user_im img_place_bg">
                            <img *ngIf="selectedType?.icon" [src]="mediaUrl + selectedType?.icon?.file" alt="icon">
                            <img *ngIf="selectedType?.icon == 0 || selectedType?.icon === null" class="user_im" src="../../../../../../assets/images/landscape_placeholder.png" alt="icon">
                        </div>
                        </div>
                    </td>
                    <td>
                        <ng-container *ngFor="let type of workflows">
                            <ng-container *ngIf="type.id === workflow.type">
                                {{type?.name ? type.name : '-'}}
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>{{workflow?.created_at | date: "MMM dd yyyy, HH:mm"}}</td>
                    <td>
                        <div class="bd-highlight noteimg">
                            <div class="card-image valign-wrapper user_im img_place_bg">
                              <ng-container *ngFor="let person of globalRegistry?.systemData?.persons">
                                <ng-container *ngIf="person?.id == workflow?.created_by">
                              <img *ngIf="person?.picture"
                                [src]="mediaUrl + person?.picture"
                                [alt]="'creator'"
                              />
                              <img *ngIf="!person?.picture"
                                [src]="defaultImg"
                                [alt]="'creator'"
                              />
                            </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!workflow?.created_by">
                                <img 
                                [src]="defaultImg"
                                [alt]="'creator'"
                              />
                            </ng-container>
                            </div>
                            
                        </div>
                    </td>
                    <td>{{workflow?.step}} / {{workflow?.steps_count}}</td>
                    <td>
                        <span *ngIf="!workflow?.completed" class="btns">
                            <button class="btn" (click)="getWorkflow(workflow?.id)">Continue</button>
                        </span>
                        <span *ngIf="workflow?.completed">{{'workflow.completed' | translate}}</span>
                    </td>
                    <td>
                        <button class="ricon" (click)="deleteWorkflow(workflow?.id)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<fap-modal [inputIsLarge]="true" #workflowModal [inputTitle]="getModalTitle()" [showFooter]="false" (outputOnHide)="refreshList.emit()"
[modalHeight]="defaultSize === 'pc' ? entityInfo?.form_css?.pc?.height : defaultSize === 'tablet' ? entityInfo?.form_css?.tablet?.height : entityInfo?.form_css?.mobile?.height"
[modalWidth]="defaultSize === 'pc' ? entityInfo?.form_css?.pc?.width : defaultSize === 'tablet' ? entityInfo?.form_css?.tablet?.width : entityInfo?.form_css?.mobile?.width">
    <div class="minus_margin">
        <fap-workflow-modal
            [isLoading]="isLoading"
            [html]="html" 
            [workflow]="workflow"
            [entityInfo]="entityInfo"
            [objectId]="objectId"
            [previousFormClick]="previousFormClick"
            [nextFormClick]="nextFormClick"
            (hideModal)="closeModal()"
            (prevButtonClick)="prevButtonClick()"
            (nextButtonClick)="nextButtonClick($event)"
            (submitForm)="submitForm()"
            (onUpdateAction)="onUpdateAction()"
            [errorFields]="errorFields"
        >
        </fap-workflow-modal>
    </div>
</fap-modal>
