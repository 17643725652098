import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { CompanyService } from '../../../core/services/api/company/company.service';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { NavService } from '../../services/nav.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';

@Component({
  selector: 'fap-carousel-widget',
  templateUrl: './fap-carousel-widget.component.html',
  styleUrls: ['./fap-carousel-widget.component.scss']
})
export class FapCarouselWidgetComponent implements OnInit, OnChanges {

  public selectedWidget;
  @Input()
  public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() public langString: string;
  @Output()
  public emitShow:EventEmitter<boolean> = new EventEmitter<boolean>();
  public subscription: Subscription = new Subscription();
  public toggleMain = false;
  public toggleSub = false;
  public apiUrl = environment.baseBackendUrl;
  public mediaUrl = environment.mediaUrl;
  public sensorIds = [];
  public subscriptions: Array<Subscription> = []
  public fromDate;
  public toDate;
  public agg;
  public dates =[];

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, public sensorService: SensorService, public companyService: CompanyService, private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.getFromDate();
    this.getToDate();
    this.getAgg();
    this.subscription = this.sensorService.getName.subscribe(res => {
      if(res) {
        this.drillData();
      }
    });
    this.localStorageService.watchTimezone().subscribe(data => {
      this.drillData();
    })
  }

  getFromDate() {
    this.subscriptions.push(this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    }));
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  getToDate() {
    this.subscriptions.push(this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    }));
  }

  getAgg() {
    this.subscriptions.push(this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    }));
  }

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
    return lot ? lot.name : '';
  }

  hideMain() {
      this.toggleMain = false;
  }

  toggleMenu() {
      this.toggleMain = !this.toggleMain;
  }

  public drillData() {
    setTimeout(()=> {
      const sensors: Array<SensorModel> = this.widget.config["settings"]["options"].map(
        (sensor: { sensor: SensorModel }) => sensor?.sensor
      );
      const sensorIds = sensors.map((sensor) => sensor.id);
      this.subscriptions.push(this.sensorService.drillData({ sensor: sensorIds.toString(), from:this.fromDate,agg_by: this.agg, to:this.toDate, agg_data:'mean,sum', compare:0}).subscribe(data1 => {
        // let Newdata = JSON.parse(data1);
        // let data = Newdata['0'];
        console.log(data1);
      })
      )
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
      console.log(this.widget);
      this.drillData();
    }
  }

}
