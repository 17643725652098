import { Routes } from '@angular/router';

import { AttributesContainerComponent } from './containers/attributes-container/attributes-container.component';

export const atributesRoutes: Routes = [
    {
        path:       '',
        component:  AttributesContainerComponent
    }
];
