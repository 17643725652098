import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private localStorageSubject = new Subject<string>();

  constructor() {
    this.localStorageSubject.next(localStorage.getItem('timezone'));
  }

  watchTimezone(): Observable<string> {
    return this.localStorageSubject.asObservable();
  }

  updateTimezone(timezone: string): void {
    localStorage.setItem('timezone', timezone);
    this.localStorageSubject.next(timezone);
  }
}
