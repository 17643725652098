import {
    Component,
    Input,
    Output,
    EventEmitter,
    AfterViewInit
} from '@angular/core';
import { MapMenuOptionsInterface } from '../data/map-menu-options.interface';

@Component({
    selector: 'map-menu',
    templateUrl: 'map-menu.component.html',
    styleUrls: ['map-menu.component.scss']
})
export class MapMenuComponent implements AfterViewInit {
    @Input() public x = 0;
    @Input() public y = 0;

    @Input()
    public options: MapMenuOptionsInterface[];

    @Output()
    public menuInit: EventEmitter<any> = new EventEmitter<any>();

    public ngAfterViewInit(): void {
        this.menuInit.emit();
    }
}
