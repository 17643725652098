import { Component } from '@angular/core';


@Component({
    templateUrl: './insects.component.html',
    styleUrls: ['./insects.component.scss']
})
export class InsectsComponent {

}
