import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../services/nav.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'products-label-modal',
  templateUrl: './products-label-modal.component.html',
  styleUrls: ['./products-label-modal.component.scss']
})
export class ProductsLabelModalComponent implements OnInit {

  @Input() show: boolean = false;
  @Input() produced: number = null;
  @Input() isSelectable: boolean = true;
  @Input() group: string = '';

  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedLabel: EventEmitter<any> = new EventEmitter<any>();

  public labels = [];
  public prodFilterForm: FormGroup;
  public prodStatus = false;
  public subscriptions: Subscription[] = []
  public nextLabelToken: { limit: number, offset: number; search?: string; produced?: number } = null;
  public getMore = true;
  public limit = 30;
  public labelId: number;
  public label: number;
  public langString: string = null;
  public subscription: Subscription = new Subscription();
  @ViewChild('rsp1')
    public rsp1: MatSelect;
  
  @ViewChild('addEditLabelModal')
  public addEditLabelModal: FapModalComponent;

  constructor(private stockService: StockService, public globalRegistry: GlobalRegistryService, private navService: NavService, private cacheService: CacheResolverService, private toastr: ToastrService, private translate: TranslateService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initProdSearchForm();
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('show') && this.show === true) {
      this.getLabels(this.produced);
    }
  }

  clearSearch(searchInput: HTMLInputElement): void {
    this.prodFilterForm.get('search')?.setValue('');
    this.filterLabels();
    searchInput.focus();
  }

  filterLabels() {
    const url = this.stockService.getUrl('');
    const params = {
      search: this.prodFilterForm.get('search').value ? this.prodFilterForm.get('search').value : '',
      limit: this.limit
    }
    if(this.prodFilterForm.get('type').value && this.prodFilterForm.get('type').value.length > 0) { Object.assign(params, {type : this.prodFilterForm.get('type').value.toString()}) }
    this.subscriptions.push(this.stockService.getProductLabels(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search+'&limit='+params.limit)
      console.log(data);
      this.nextLabelToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.labels = data.body.results;
      if(this.nextLabelToken) this.getMore = true
    }, (err) => {
      this.toastr.error(err.error.results.error);
    }))
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  public getLabels(produced: number) {
    const url = this.stockService.getUrl('product_labels/');
    let params = { 
      limit: this.limit
    } 
    if(produced != null) {
      Object.assign(params, {produced})
    }
    this.cacheService.delete(url+'limit='+this.limit);
    this.subscriptions.push(this.stockService.getProductLabels(params).subscribe(data => {
      console.log(data);
      this.labels = data.body.results;
      this.nextLabelToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
          if(this.nextLabelToken) this.getMore = true
    }))
  }

  labelDelete(labelId: number) {
    this.stockService.deleteProductLabel(labelId).subscribe(
        () => {
            this.labels = this.labels.filter(label => label.id !== labelId);
            this.toastr.error(this.translate.instant('stock.productDeletedSuccessfully'));
        },
        error => {
          this.toastr.error(this.translate.instant('stock.cannotDeleteProduct'));
        }
    );
}

  updateLabel(updatedLabel: any) {
    const index = this.labels.findIndex(label => label.id === updatedLabel.id);
    if (index !== -1) {
      this.labels[index] = updatedLabel;
      this.labelId = -1;
    }
  }

  addLabel(label: any) {
    this.labels.unshift(label);
    this.labelId = -1;
  }

    public labelEdit(label) {
      this.labelId = -1;
      this.label = label;
      this.labelId = label.id;
      this.addEditLabelModal.showModal();
    }

    public selectLabel(label) {
      if(!this.isSelectable) {
        return
      }
      this.selectedLabel.emit(label)
    }

    public showLabelModal(): void {
      this.labelId = -1;
      this.addEditLabelModal.showModal();
    }

    public setItem(ev) {
      console.log(ev.value);
      this.rsp1.close();
      this.filterLabels();
    }

    public clearValue($event) {
      $event.stopPropagation(); 
      this.prodFilterForm.get('type').setValue(null);
      this.filterLabels();
    }

    public clearProdInputs() {
      this.prodFilterForm.reset();
      this.prodFilterForm.clearValidators();
      this.prodFilterForm.markAsUntouched();
      this.initProdSearchForm();
      this.filterLabels();
    }

    public toggleProdFilter() {
      this.prodStatus = !this.prodStatus;
    }

    public initProdSearchForm() {
      const formBody = {
        search: [''],
        type: [[]],
      };
        this.prodFilterForm = this.formBuilder.group(formBody);
    }

    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    scrolledDownProductLabels() {
      console.log('scrolled');
      const params = this.nextLabelToken;
      if(this.getMore) {
      this.nextLabelToken && 
        this.subscriptions.push(this.stockService.getProductLabels(params).subscribe((data) => {
          console.log(data);
          if(this.labels) {
            this.labels = [...this.labels, ...data.body.results];
          } else {
            this.labels = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextLabelToken.offset != params['offset']) {
            this.nextLabelToken = {offset: params['offset'], limit: params['limit']};
            if(params['search']) this.nextLabelToken.search = params['search'];
            if(params['produced']) this.nextLabelToken.produced = params['produced'];
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }

}
