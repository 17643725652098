import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { PartyComponent } from './party.component';
import { PartyContainerComponent } from './containers/party-container/party-container.component';
import { FapPartyTableComponent } from './components/fap_party-table/fap_party-table.component';
import { PartialsModule } from '../../views-partials/partials.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        PartialsModule
    ],
    declarations: [
        PartyComponent,
        PartyContainerComponent,
        FapPartyTableComponent
    ]
})

export class PartyModule {

}
