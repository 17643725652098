import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ActivityModel } from '../../../core/models/activity/activity.model';
import { ActivityGroupModel } from '../../../core/models/activity/activity-group.model';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { FapModalComponent } from '../../partials';
import { ActivityInterface } from '../../../core/interfaces/activity/activity.interface';
import { Data } from '@angular/router';

@Component({
    selector: 'fap-activity-list',
    templateUrl: './fap_activity-list.component.html',
    styleUrls: ['./fap_activity-list.component.scss']
})
export class FapActivityListComponent implements OnChanges {

    @Input()
    public activities: Array<ActivityModel>;

    @Input()
    public activityGroups: Array<ActivityGroupModel>;

    @Input()
    public widgetConfig: Data;

    @Output()
    public addActivity: EventEmitter<ActivityInterface> = new EventEmitter();

    @Output()
    public editActivity: EventEmitter<ActivityInterface> = new EventEmitter();

    @Output()
    public deleteActivity: EventEmitter<number> = new EventEmitter();

    @ViewChild('addEditActivityModal')
    public addEditActivityModal: FapModalComponent;

    public ungroupedActivities: Array<ActivityModel>;
    public selectedActivity: ActivityModel;

    constructor(public globalRegistry: GlobalRegistryService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'activities') || Object.prototype.hasOwnProperty.call(changes, 'activityGroups')) {
            if (this.activities && this.activityGroups) {
                this.ungroupedActivities = this.activities; // ActivityHelper.getUngroupedActivities(this.activities);
            }
        }
    }

    public addEditActivity(activity?: ActivityModel): void {
        if(activity){
            this.selectedActivity = activity;
        }
        else {
            this.selectedActivity = null;
        }
        this.addEditActivityModal.showModal();
    }

    public submitActivity(activity: ActivityInterface): void {
        if(activity.id !== null && activity.id !== undefined) {
            this.editActivity.emit(activity);
        }
        else {
            this.addActivity.emit(activity);
        }
        this.addEditActivityModal.hideModal();
        this.selectedActivity = null;
    }
}
