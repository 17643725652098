import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PartialsModule } from '../../views-partials/partials.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxNativeDateModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { SchedulerComponent } from './scheduler.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { SchedulerEditContainerComponent } from './containers/scheduler-edit-container/scheduler-edit-container.component';
import { SchedulerEditComponent } from './components/scheduler-edit/scheduler-edit.component';
import { SchedulerLayoutComponent } from './components/scheduler-layout/scheduler-layout.component';
import { SchedulerCardComponent } from './components/scheduler-card/scheduler-card.component';
import { SchedulerContainerComponent } from './containers/scheduler-container/scheduler-container.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        PartialsModule,
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        MatExpansionModule,
        OwlDateTimeModule, 
        OwlNativeDateTimeModule,
    ],
    declarations: [
        SchedulerComponent,
        SchedulerEditContainerComponent,
        SchedulerEditComponent,
        SchedulerLayoutComponent,
        SchedulerCardComponent,
        SchedulerContainerComponent
    ]
})

export class SchedulerModule {

}
