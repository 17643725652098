import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { PartialsModule } from '../../../views-partials/partials.module';
import { AlergotelReportsComponent } from './reports.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AlergotelReportDetailComponent } from './report-detail/report-detail.component';
import { ValidateReportComponent } from './validate-report/validate-report.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { AddReportComponent } from './add-report/add-report.component';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        PartialsModule,
        InfiniteScrollModule,
        MatCheckboxModule
    ],
    declarations: [
        AlergotelReportsComponent,
        AlergotelReportDetailComponent,
        ValidateReportComponent,
        AddReportComponent
    ]
})

export class AlergotelReportsModule {

}
