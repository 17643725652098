import { FapBaseModel } from '../../base/fap-base-model';
import { PolygonCoordsInterface } from '../../interfaces/coords/polygon-coords.interface';
import { UnitValueModel } from '../units/unit-value.model';

export class FieldModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'coords',
        'area',
        'lot',
        'soil_type',
        'owner',
        'static_map_image'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
        area: UnitValueModel
    };

    public id: number;
    public name: string;
    public coords: PolygonCoordsInterface;
    public soilType: string;
    public lot: number;
    public area: UnitValueModel = new UnitValueModel();
    public owner: number;
    public staticMapImage: string;

    public get previewImg(): string {
        if(this.staticMapImage) {
            return this.staticMapImage;
        }
        return '/assets/images/no_image_available.svg';
    }
}
