<div class="form-group" [formGroup]="form">
    <div class="row first-row py-2">
        <div class="col-md-4">
            <mat-form-field>
                <input matInput
                    formControlName='identification'
                    required
                    placeholder="{{ 'farm.crop.disease.identification' | translate }}">
            </mat-form-field>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.kingdom' | translate }}</mat-label>
                    <mat-select formControlName='kingdom'>
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #kingdomFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : kingdomFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.subkingdom' | translate }}</mat-label>
                    <mat-select formControlName="subkingdom">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #subKingdomFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : subKingdomFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
        </div>
    </div>
    <div class="row m-t-15">
        <div class="col-md-4">
            <fap-upload-image
                #diseaseImageUpload
                (outputOnLoadImage)="imageUploaded($event)"
                [inputHeight]="'100%'"
                [inputWidth]="'70%'">
            </fap-upload-image>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.group' | translate }}</mat-label>
                    <mat-select formControlName="group">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #groupFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : groupFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.class' | translate }}</mat-label>
                    <mat-select formControlName="class">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #classFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : classFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.superdivision' | translate }}</mat-label>
                    <mat-select formControlName="superdivision">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #superDivisionFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : superDivisionFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.order' | translate }}</mat-label>
                    <mat-select formControlName="order">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #orderFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : orderFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.genus' | translate }}</mat-label>
                    <mat-select formControlName="genus">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #genusFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : genusFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.subspecies' | translate }}</mat-label>
                    <mat-select formControlName="subspecies">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #subSpeciesFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : subSpeciesFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.symbol' | translate }}</mat-label>
                    <mat-select formControlName="symbol">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #symbolFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : symbolFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.subclass' | translate }}</mat-label>
                    <mat-select formControlName="subclass">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #subclassFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : subclassFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.division' | translate }}</mat-label>
                    <mat-select formControlName="division">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #divisionFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : divisionFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.family' | translate }}</mat-label>
                    <mat-select formControlName="family">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #familyFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : familyFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.species' | translate }}</mat-label>
                    <mat-select formControlName="species">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #speciesFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : speciesFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
            <div class="input-group">
                <mat-form-field>
                    <mat-label>{{ 'farm.crop.disease.duration' | translate }}</mat-label>
                    <mat-select formControlName="duration">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #durationFilter placeholder="Search">
                        </mat-form-field>
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let el of testArray | filter : durationFilter.value" [value]="el">
                            {{el}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="input-group-append">
                    <span class="input-group-text f-20" (click)="openLg(content)"><i
                            class="icofont icofont icofont-plus"></i></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="showSubmit" class="row justify-content-center">
    <button
        [disabled]="form.invalid"
        class="btn btn-success px-2"
        type="button"
        (click)="submitForm()">
        Submit<i class="material-icons pl-1">send</i>
    </button>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add option</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <mat-form-field>
                <input matInput placeholder="Option">
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer center">
        <button type="button" class="btn" (click)="modal.close('Close click')">{{ 'save' | translate }}
            <i class="material-icons f-18 pl-2">send</i>
        </button>
    </div>
</ng-template>
