import { TransactionModel } from './transaction.model';
import { FapBaseModel } from '../../base/fap-base-model';
import { StockTransactionEnum } from './../../enums/stock-transaction.enum';
import { PartyModel } from '../party/party.model';


export class BillModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'party_from',
        'party_to',
        'created_by',
        'bill_type',
        'created_at',
        'updated_at',
        'receiver',
        'receive_date',
        'internal_bill',
        'transactions',
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
        transactions: TransactionModel,
        partyFrom: PartyModel,
        partyTo: PartyModel
    };

    public id: number;
    public partyFrom: PartyModel = new PartyModel();
    public partyTo: PartyModel = new PartyModel();
    public createdBy: string;
    public billType: StockTransactionEnum;
    public createdAt: string;
    public updatedAt: string;
    public receiver: string;
    public receiveDate: string;
    public internalBill: boolean;
    public transactions: TransactionModel[] = [];
    public documentId: string;
    public documentDate: string;
    public total: any;
    public totalVat: any;
    public grandTotal: any;
}
