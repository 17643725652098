import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { FieldComponent } from './field.component';
import { FieldContainerComponent } from './containers/field-container/field-container.component';
import { FapFieldLayoutComponent } from './components/fap_field-layout/fap_field-layout.component';
import { FapFieldCardComponent } from './components/fap_field-card/fap_field-card.component';
import { FieldEditContainerComponent } from './containers/field-edit-container/field-edit-container.component';
import { PartialsModule } from '../../views-partials/partials.module';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        PartialsModule
    ],
    declarations: [
        FieldComponent,
        FieldContainerComponent,
        FapFieldLayoutComponent,
        FapFieldCardComponent,
        FieldEditContainerComponent
    ]
})
export class FieldModule {

}
