import { Routes } from '@angular/router';
import { WorkflowsContainerComponent } from './containers/workflows-container/workflows-container.component';


export const workflowRoutes: Routes = [
    {
        path:       '',
        component:  WorkflowsContainerComponent,
        data: {
            filters: {
                hasFarmFilter: true,
                hasWorkflowsFilter: true,
            },
        }
    },
];
