import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fap-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() public count: number;
  @Input() public pageSize: number = 20;
  @Output() public emitNextPrev: EventEmitter<number> = new EventEmitter();

  public totalPages: number;
  public pages: number[] = [];
  public currentPage: number = 1;
  public pageNumberInput: number;
  private maxPagesToShow: number = 5;

  constructor(private toastr: ToastrService) {
    this.calculatePages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.count || changes.pageSize) {
      this.calculatePages();
    }
  }

  private calculatePages() {
    this.totalPages = Math.ceil(this.count / this.pageSize);
    this.updateDisplayedPages();
  }

  private updateDisplayedPages() {
    const halfRange = Math.floor(this.maxPagesToShow / 2);
    let startPage = Math.max(this.currentPage - halfRange, 1);
    let endPage = startPage + this.maxPagesToShow - 1;

    if (endPage > this.totalPages) {
      endPage = this.totalPages;
      startPage = Math.max(endPage - this.maxPagesToShow + 1, 1);
    }

    this.pages = Array.from({ length: Math.min(this.maxPagesToShow, this.totalPages) }, (_, i) => startPage + i);
  }

  public changePage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updateDisplayedPages();
      this.emitNextPrev.emit(this.currentPage);
    }
  }

  public onPageNumberInputChange(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    const pageNumber = parseInt(input, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= this.totalPages) {
      this.changePage(pageNumber);
    } else {
      this.pageNumberInput = this.currentPage;
      this.toastr.error('Page number ' + pageNumber + ' is not available');
    }
  }
}
