import { FapModalComponent } from '../../../../../shared/partials/components/fap-modal/fap-modal.component';
import { BillsParamsInterface } from '../../../../../core/interfaces/stock/bills-params.interface';
import {
    Component,
    Input,
    ViewChild,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    Output,
    EventEmitter
} from '@angular/core';
import {
    MatLegacyPaginator as MatPaginator,
    LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BillModel } from '../../../../../core/models/stock/bill.model';


@Component({
    selector: 'fap-stock-bills',
    templateUrl: './fap_stock-bills.component.html',
    styleUrls: ['./fap_stock-bills.component.scss']
})
export class FapStockBillsComponent implements AfterViewInit, OnChanges {

    @Input()
    public bills: Array<BillModel> = [];

    @Input()
    public totalNoOfBills = 0;

    @Output()
    public filterBills: EventEmitter<BillsParamsInterface> = new EventEmitter();

    @ViewChild('paginator')
    public paginator: MatPaginator;

    @ViewChild('viewBillModal')
    public viewBillModal: FapModalComponent;

    public displayedColumns: string[] = ['number', 'id', 'billType', 'createdAt', 'createdBy', 'viewBill'];

    public dataSource: MatTableDataSource<BillModel> = new MatTableDataSource<BillModel>(this.bills);

    public pageSize = 10;
    public pageIndex = 0;

    public selectedBill: BillModel = null;

    

    public ngAfterViewInit(): void {
        this.paginator.page.subscribe((event: PageEvent): void => {
            this.pageSize = event.pageSize;
            this.pageIndex = event.pageIndex;
            const billsFilter: BillsParamsInterface = {
                offset: String(this.pageSize * this.pageIndex),
                limit: String(this.pageSize)
            };
            this.filterBills.emit(billsFilter);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'bills'), this.bills) {
            this.dataSource = new MatTableDataSource<BillModel>(this.bills);
        }
    }

    public viewBill(bill: BillModel): void {
        this.selectedBill = bill;
        this.viewBillModal.showModal();
    }
}
