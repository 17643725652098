import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MediaService } from '../../../../core/services/api/media/media.service';

@Injectable()
export class MediaContentResolver
{
    constructor(private mediaService: MediaService) {
    }

    public resolve(): Observable<{}> {
        return this.mediaService.getMediaContent();
    }
}
