import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PeopleGroupModel } from 'src/app/core/models/groups/people-group.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { environment } from '../../../../../environments/environment';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { UnitInterface } from '../../../../core/interfaces/unit/unit.interface';
import { PartyModel } from '../../../../core/models/party/party.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { NewUnitModel } from '../../../../core/models/units/new-unit-model';
import { UnitModel } from '../../../../core/models/units/unit.model';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { PartyService } from '../../../../core/services/api/company/party.service';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { UnitService } from '../../../../core/services/api/units/unit.service';
import { FapModalComponent } from '../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { NavService } from '../../../../shared/services/nav.service';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'fap-add-edit-product',
  templateUrl: './fap-add-edit-product.component.html',
  styleUrls: ['./fap-add-edit-product.component.scss']
})
export class FapAddEditProductComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public productId;
  @Input() public produced = false;
  @Input() public from;
  @Input() public group: string;

  @Output()
  public addedProduct: EventEmitter<any> = new EventEmitter();
  @Output()
  public updatedProduct: EventEmitter<any> = new EventEmitter();
  @Output()
  public deleteGroup: EventEmitter<number> = new EventEmitter();
  @Output()
  public closeModal: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  @ViewChild('fileUploader') fileUploader:ElementRef;
  @ViewChild('ingredientSelect') ingredientSelect: MatSelect;
  @ViewChild('imageRef') public imageRef;
  @ViewChild('addEditGroupModal')
  public addEditGroupModal: FapModalComponent;
  @ViewChild('deleteGroupModal')
  public deleteGroupModal: FapModalComponent;
  @ViewChild('productsLabelModal')
  public productsLabelModal: FapModalComponent;
  // @Output()
  // public createdIngredient: EventEmitter<IngredientModel> = new EventEmitter();

  // @Output()
  // public updatedIngredient: EventEmitter<IngredientModel> = new EventEmitter();

  // @Output()
  // public deleteIngredient: EventEmitter<number> = new EventEmitter();

  // @Output()
  // public deleteLocalIngredient: EventEmitter<number> = new EventEmitter();

  public product;
  public imgWidth: number;
  public imgHeight: number;
  public orientation: string;
  
  public productForm: UntypedFormGroup;
  public localIngredients: Array<IngredientModel> = [];
  public localIngredientsId: Array<any> = [];
  public quantityPack: NewUnitModel;
  public packUnit: NewUnitModel;
  public ingredients: Array<IngredientModel> = [];
  public subscription: Subscription = new Subscription();
  public limit = 20;
  public mediaUrl = environment.mediaUrl;
  public unitForm: UntypedFormGroup;
  public units: Array<UnitModel> = [];
  public selectedGroup;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  public translatedNames:any = [];
  public langString: string;
  public selectedUnitId: number;
  public productImages = [];
  imageSrc: string;
  public defaultPackUnit;
  public defaultUnit;
  public peopleGroup;
  public deleteGroupModalButtonPrimaryInterface: FapModalButtonInterface;
  public deleteGroupModalButtonSecondaryInterface: FapModalButtonInterface;
  public nextImgToken :{ offset: number; limit: number; group: string; } = null;
  public getMoreImg = true;
  
  public localAttributes: Array<IngredientModel> = [];
  public entityInfo: any;
  public resourceTypeId: any;
  public resourceType: any;
  public formType;
  public formFields;
  public label = null;
  public show: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public globalRegistry: GlobalRegistryService,
    public stockService: StockService,
    public toastr: ToastrService,
    public translate: TranslateService,
    public companyService: CompanyService,
    public unitService: UnitService,
    public typesService: TypesService,
    public navService: NavService,
    public partyService: PartyService,
    public translateService: TranslateService,
    public cacheService: CacheResolverService,
    public attributeService: AttributeService
  ) {}

  ngOnInit(): void {
    this.product = null;
    this.entityInfo = null;
    this.resourceType = null;
    this.units = this.globalRegistry.systemData.units;
    this.ingredients = this.globalRegistry.systemData.ingredients;
    this.units.filter(u => {
      if(u.nameT == 'UNIT_CN_PACKAGING') {
        u.children.forEach(element => {
          if(element.nameT == 'UNIT_N_ITEMS') {
            this.defaultPackUnit = element.id
          }
        });
      }
      if(u.nameT == 'UNIT_CN_WEIGHT') {
        u.children.forEach(element => {
          if(element.nameT == 'UNIT_N_KILOGRAM') {
            this.defaultUnit = element.id
          }
        });
      }
    });
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        
    this.initProductForm();
    this.initunitForm();
    this.initDeleteGroupModalButtons();
    // this.getIngredients();
  }

  assignLabel(label) {
    this.label = label;
    this.productForm.get('label').setValue(label.id);
    this.hideLabels();
  }
  
  hideLabels() {
    this.productsLabelModal.hideModal();
    this.show = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'produced') && this.produced) {
      console.log(this.produced);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'from') && this.from) {
      console.log(this.from);
    }
    if(Object.prototype.hasOwnProperty.call(changes, 'productId') && this.productId) {
      console.log(changes);
      console.log(this.productId);
      // this.entityInfo = null;
      if(this.productId == -1) {
        this.resetForm(this.productForm);
        this.packUnit = null;
        this.quantityPack = null;
        this.productImages = [];
        this.productId = null;
        this.product = null;
        this.localIngredients = [];
        this.productForm.get('images').setValue([]);
        this.productForm.get('cost').setValue(0);
        this.productForm.get('alert_quantity').setValue(1);
        // this.productForm.get('ingredients').setValue([]);
        this.quantityPack = {unit: this.defaultUnit, value: 1};
        this.packUnit = {unit: this.defaultPackUnit, value: 1};
        this.localIngredientsId = [];
        this.produced = false;
        this.label = null;
        // this.productForm.get('produced').setValue(0);
        if(this.from) {
          // this.productForm.get('produced').setValue(1);
          this.produced = true;
        }
      } else {
        this.resetForm(this.productForm);
        this.localIngredients = [];
        this.getProduct(this.productId);
      }
    }
    
  }

  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
  }

  public getIngredients() {
    this.globalRegistry.reloadIngredients();
    this.globalRegistry.reloadIngredientsTypes();
    console.log(this.globalRegistry.systemData.ingredients);
  }

  public getProduct(productId) {
    const url = this.stockService.getUrl('products/')
    this.subscription.add(this.stockService.getProduct(productId).subscribe(data => {
      this.cacheService.delete(url+productId+'/')
      console.log(data);
      this.product = data.model;
      this.productImages = data.model.images;
      this.label = data.body.results.label;
      console.log(data.model.label.ingredients);
      this.getIngredients();
      this.getIngredient(data.model.label.ingredients.toString());
      this.initProductForm();
    }))
  }

  public getIngredient(list:string) {
    this.stockService.getIngredient(list).subscribe(data => {
      console.log(data);
      this.localIngredients = data.body.results;
    })
  }

  initProductForm() {
    const formBody = {
      name: [this.product ? this.product.label.name : null, Validators.compose([Validators.required])],
      quantity: [this.product ? this.product.quantity : 1, Validators.compose([Validators.required])],
      prospect: [this.product ? this.product.prospect : null],
      images: [[]],
      pack_unit: [this.product ? this.product.packUnit : this.defaultPackUnit, Validators.compose([Validators.required])],
      quantity_pack: [this.product ? this.product.quantityPack : 1, Validators.compose([Validators.required])],
      unit: [this.product ? this.product.unit : this.defaultUnit, Validators.compose([Validators.required])],
      cost: [this.product ? this.product.cost : 0],
      alert_group: [this.product ? this.product.alertGroup : null],
      alert_quantity: [this.product ? this.product.alertQuantity : 1, Validators.compose([Validators.required])],
      label: [this.product ? this.product.label.id : null, Validators.compose([Validators.required])]
    }
    this.productForm = this.formBuilder.group(formBody);
    if(this.product) {
      this.quantityPack = {unit: this.product.unit ? this.product.unit : this.defaultUnit, value: this.product.quantity ? this.product.quantity : 1};
      this.packUnit = {unit: this.product.packUnit ? this.product.packUnit : this.defaultPackUnit, value: this.product.quantityPack ? this.product.quantityPack: 1};
      console.log(this.product);
      
      if(this.product.label.produced == 0) {
        this.produced = false
      } else {
        this.produced = true;
      }
      if(this.product.label.type) {
        this.getResourceType(this.product.label.type)
      } else {
        this.entityInfo = null;
        this.resourceType = null;
      }
      this.selectIngredient(this.product.label.ingredients);
      this.productImages = this.product.images.map(image => image.id);
      this.productForm.get('images').setValue(this.productImages);
    } else {
      this.productForm.reset();
      this.productForm.controls['alert_quantity'].patchValue(1)
    }
  }

  public getPeopleGroups() {
    const url = this.companyService.getUrl('people_group/');
    this.cacheService.delete(url);
    this.subscription.add(
      this.companyService.getPeopleGroups().subscribe(data => {
        console.log(data);
        this.peopleGroup = data.body.results;
      })
    )
  }

  public editGroup(group: PeopleGroupModel): void {
    this.selectedGroup = group;
    this.addEditGroupModal.showModal();
}

public createdGroup(group: PeopleGroupModel): void {
  console.log(group);
  if (!this.peopleGroup.some(e => e.id === group.id)) {
    this.peopleGroup.push(group);
  } else {
    this.getPeopleGroups();
  }
  this.addEditGroupModal.hideModal();
}

public addGroupModal(): void {
    this.selectedGroup = null;
    this.addEditGroupModal.showModal();
}

private initDeleteGroupModalButtons(): void {
  this.deleteGroupModalButtonPrimaryInterface = {
      clickFunction: (): void => {
          this.deleteGroup.emit(this.selectedGroup.id);
          this.deleteGroupModal.hideModal();
          this.peopleGroup = this.peopleGroup.filter((groupId: number): boolean => groupId !== this.selectedGroup.id); },
      text: this.translateService.instant('yes')
  };
  this.deleteGroupModalButtonSecondaryInterface = {
      clickFunction: (): void => { this.deleteGroupModal.hideModal(); },
      text: this.translateService.instant('cancel')
  };
}

  public getFormType(formTypeId) {
    this.typesService.getFormTypeById(formTypeId).subscribe(data => {
      this.entityInfo = data.body.results;
      console.log(this.entityInfo);
    })
  }

  public getResourceType(type) {
    if(!this.globalRegistry.systemData.resourceTypes.length) {
      return
    }
    console.log(this.globalRegistry.systemData.resourceTypes)
      const resourceType = this.globalRegistry.systemData.resourceTypes.map(obj => this.findObjectById(obj, type)).find(Boolean);
      console.log(resourceType);
      this.resourceTypeId = resourceType.id;
      this.resourceType = resourceType;
      if(resourceType.form_type) {
        this.getFormType(resourceType.form_type);
      } else {
        this.entityInfo = null;
        this.resourceType = null;
      }
  }

  findObjectById(obj, desiredId) {
    if (obj.id === desiredId) {
      return obj;
    }
  
    if (obj.children && obj.children.length > 0) {
      for (const child of obj.children) {
        const foundObject = this.findObjectById(child, desiredId);
        if (foundObject) {
          return foundObject;
        }
      }
    }
  
    return null;
  }

  public deleteResourceType(resourceId?: number): void {
    console.log(resourceId);
    this.typesService.deleteResourceType(resourceId).subscribe(data => {
      console.log(data);
      this.globalRegistry.reloadResourceTypes();
    });
  }

public ifControlHasError(controlName: string, validationType: string): boolean {
  const control: any = this.productForm.controls[controlName];
  if (!control) {
      return false;
  }

  const result: boolean =
      control.hasError(validationType) &&
      (control.dirty || control.touched);
  return result;
  }

  public submitProduct() {

    if(this.quantityPack) {
      this.productForm.get('unit').setValue(this.quantityPack.unit);
      this.productForm.get('quantity').setValue(this.quantityPack.value);
    } 

    if(this.packUnit) {
        this.productForm.get('quantity_pack').setValue(this.packUnit.value);
        this.productForm.get('pack_unit').setValue(this.packUnit.unit);
    }

    if(this.productForm.get('quantity_pack').value == null || this.productForm.get('pack_unit').value == null) {
      this.toastr.error(this.translate.instant('product.validPackageDetails'));
      return;
    }

    if(this.productForm.get('unit').value == null || this.productForm.get('quantity').value == null) {
      this.toastr.error(this.translate.instant('product.quantityDetails'));
      return;
    }

  console.log(this.productForm.value);
  console.log(this.product);
  // return
  if(this.product) {
    if(this.productForm.value.images.length) {
      this.productForm.get('images').setValue(this.productForm.value.images);
    }
    // this.product.producer = this.productForm.value.producer;
    this.stockService.updateProduct(this.product.id, this.productForm.value).subscribe(data => {
      console.log(data);
      if(data) {
        this.updatedProduct.emit(data.body.results);
      this.toastr.success(this.translate.instant('product.productUpdatedSuccessfully'))
      // this.product = null;
      this.productImages = [];
      // this.productForm.reset();
      this.productForm.clearValidators();
      // this.initProductForm();
      }
          },
          error => {
            this.toastr.error(error.error.results.error);
            console.log(this.product);
            console.log(this.productForm.value);
            return;
            // this.initProductForm();
          }
          )
          this.updateAttributes();
  } else {
    this.stockService.addProduct(this.productForm.value).subscribe(data => {
      console.log(data);
      this.addedProduct.emit(data.body.results);
      this.toastr.success(this.translate.instant('product.productCreatedSuccessfully'))
      this.productImages = [];
      this.productForm.reset();
      this.productForm.clearValidators();
      this.product = null;
      this.productId = -1;
      this.label = null;
      this.produced = false;
      this.initProductForm();
    }, error => {
      this.toastr.error(error.error.results.error)
    })
  }

  }

  public updateAttributes() {
    if(this.formType && this.formType.id) {
      this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
          console.log(data);
      })
    }
}

public verifyAlertQuantity(val) {
  // console.log(val.target.value);
  if(val.target.value == 0 || val.target.value == undefined || val.target.value == '') {
    this.productForm.get('alert_group').setValue(null);
  }
}

addImage(event) {
  const imageId = event.value.id;
  this.productImages = [...this.productImages, imageId];
  this.productForm.get('images').setValue(this.productImages);
  console.log(this.productForm.value);
}

deleteImage(imgId) {
  this.productImages = this.productImages.filter((item) => item !== imgId);
  console.log(this.productImages);
  this.productForm.get('images').setValue(this.productImages);
  console.log(this.productForm.value);
}

public submit(): void {

  if(this.unitForm.dirty) {
      const unit: UnitInterface = {
          name: this.unitForm.controls['name'].value,
          shortName: this.unitForm.controls['shortName'].value,
          genre: this.unitForm.controls['genre'].value
      };

      if(typeof this.unitForm.controls['parent'].value !== 'string') {
          unit.parent = this.unitForm.controls['parent'].value === 'new' ?
              null :
              this.unitForm.controls['parent'].value;
      }

      if(this.currentUnit) {
          this.updateUnit({...{id: this.currentUnit.id}, ...unit});
          this.globalRegistry.reloadUnits();
      }
      else {
          this.addUnit(unit);
          this.globalRegistry.reloadUnits();
      }
      this.getUnits()
    }
  }

  public getUnits() {
    this.subscription.add(this.unitService.getUnits().subscribe(data => {
      this.units = data.body.results;
    }))
  }

  private initunitForm(): void {
  this.unitForm = this.formBuilder.group({
      name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(100)
          ])
      ],
      shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(100)
          ])
      ],
      parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
              Validators.required
          ])
      ],
      genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
              Validators.required
          ])
      ]
    });
  }

  private addUnit(unit: UnitInterface): void {
    this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
      this.toastr.success(this.translate.instant('units.unitAddSuccessfull'));
      this.globalRegistry.reloadUnits();
      this.onUnitAction(response.model.id);
    },
    (): void => {
      this.toastr.error(this.translate.instant('units.unitAddError'));
    });
  }

  private updateUnit(unit: UnitInterface): void {
    this.unitService.updateUnit(unit).subscribe((): void => {
        this.toastr.success(this.translate.instant('units.unitUpdateSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.onUnitAction(this.currentUnit.id);

    },
    (): void => {
        this.toastr.error(this.translate.instant('units.UnitUpdateError'));
    });
  }

  public deleteUnit(unitId: number): void {
    this.unitService.deleteUnit(unitId).subscribe((): void => {
        this.toastr.success(this.translate.instant('units.unitDeleteSuccessfull'));
        this.globalRegistry.reloadUnits();
    },
    (): void => {
        this.toastr.error(this.translate.instant('units.UnitDeleteError'));
    });
  }

  public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.unitForm.controls[controlName];
    if (!control) {
        return false;
    }

    const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  public refreshIngredientList(ids) {
    console.log(ids);
  }

  public selectIngredient(value) {
    console.log(value);
    const localIngredients = [];
    this.localIngredientsId = value;
    if(this.globalRegistry.systemData.ingredients && this.globalRegistry.systemData.ingredients.length) {
    this.globalRegistry.systemData.ingredients.forEach(ingredient => {
      this.localIngredientsId.forEach(ingredientId => {
        if(ingredient.id == ingredientId) {
          localIngredients.push(ingredient);
        }
      });
    });
  }
    this.localIngredients = localIngredients;
  }

  public onUnitAction(unitId: number): void {
    this.selectedUnitId = unitId;
    this.addEditUnitModal.hideModal();
  }

  getTranslations() {
    this.subscription.add(
        this.typesService.getTranslations().subscribe((data) => {
            this.translatedNames = data.model;
            return;
        })
    );
}

  getTranslation(translation) {
    const t =this.translatedNames.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  ngOnDestroy(): void {
    this.product = null;
    this.productImages = [];
    this.subscription.unsubscribe();
    this.productForm.reset();
    this.productForm.clearValidators();
  }

}
