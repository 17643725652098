<div class="lib-panel">
    <div class="activity-info">
        <div class="title mb-3">
            {{ activityName }}
            <i class="material-icons delete pull-right ml-3" (click)="deleteActivity.emit()">delete_outline</i>
            <i class="material-icons edit pull-right" (click)="editActivity.emit()">edit</i>
        </div>
        <div class="details">
            <div class="details-row">
                {{ 'activity.info' | translate }}: <b> {{ activityInfo }} </b>
            </div>
            <div class="details-row">
                {{ 'activity.createdBy' | translate }}: <b> {{ activityCreatedBy }} </b>
            </div>
            <div class="details-row">
                {{ 'activity.workingTime' | translate }}: <b> {{ activityWorkingTime }} </b> 
            </div>
            <div class="details-row">
                {{ 'activity.startedOn' | translate }}: <b> {{ activityStarted}} </b>
            </div>
            <div class="details-row">
                {{ 'activity.endedOn' | translate }}: <b> {{ activityEnded }} </b>
            </div>
        </div>
    </div>
</div>
