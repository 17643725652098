<div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header-title">
          {{ 'auth.company.company' | translate }}
            <i class="material-icons f-right mr-1" (click)="submit()">save</i>
        </div>
        <form [formGroup]="editCompanyForm">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'auth.company.companyName' | translate }}*</mat-label>
                    <input matInput formControlName="name">
                    <mat-error *ngIf="ifControlHasError('name','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="ifControlHasError('name','minlength')">
                        <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="ifControlHasError('name','maxlength')">
                        <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.billingAddress' | translate }}*</mat-label>
                  <input matInput formControlName="billingAddress">
                  <mat-error *ngIf="ifControlHasError('billingAddress','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.cif' | translate }}*</mat-label>
                  <input matInput formControlName="cif">
                  <mat-error *ngIf="ifControlHasError('cif','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.registerData' | translate }}*</mat-label>
                  <input matInput formControlName="registerData">
                  <mat-error *ngIf="ifControlHasError('registerData','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.vat' | translate }}*</mat-label>
                  <input matInput formControlName="vat">
                  <mat-error *ngIf="ifControlHasError('vat','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            </form>
      </div>
    </div>
</div>
