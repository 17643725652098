import {
    ActivatedRoute,
    Params,
    Router
} from '@angular/router';
import {
    Component,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { LotService } from '../../../../../core/services/api/farm/lot.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import {
    Subscription
} from 'rxjs';
import { FieldService } from '../../../../../core/services/api/farm/field.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { take } from 'rxjs/operators';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    templateUrl: './field-container.component.html'
})
export class FieldContainerComponent implements OnInit, OnDestroy {

    public fields: Array<FieldModel>;

    public filteredFarms: Array<FarmModel> = [];
    public filteredLots: Array<LotModel> = [];
    public filteredFields: Array<FieldModel> = [];

    private filterLotIds: Array<number> = [];
    private filterFarmIds: Array<number> = [];

    private fieldsPolygons: Array<MapPolygonInterface>;

    private subscriptions: Array<Subscription> = [];
    public currentUser;
    public parties = [];

    constructor(public lotService: LotService,
                public fieldService: FieldService,
                public mapService: MapService,
                public toastr: ToastrService,
                public translate: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public activatedRoute: ActivatedRoute,
                public userService:UserService,
                public router: Router,
                public cacheService: CacheResolverService,
                public widgetService: WidgetsService
                ) {
                    this.subscriptions.push(this.userService.getCurrentUser.subscribe(data => {
                        if(Object.keys(data).length != 0) {
                        this.currentUser = data;
                        const path = this.router.routerState.snapshot.url;
                        this.userService.getMenus.subscribe(menus => {  
                            if(Object.keys(menus).length != 0) {
                                const pathExist = menus.some(menu => menu.path === path);
                                if(pathExist === false) {
                                    this.router.navigate(['pages/home']);
                                }
                            }
                        })
                        }
                    }));
                    this.activatedRoute.queryParams
                    .subscribe((queryParams: Params): void => {
                        if (queryParams['farms'] !== undefined) {
                            // we have farms in query params, but need to check if we have an array of farms
                            this.filterFarmIds = Array.isArray(queryParams['farms'])
                                ? queryParams['farms'].map((farmId: string): number => parseInt(farmId, 10))
                                : [parseInt(queryParams['farms'], 10)];
                        }
                        else {
                            this.filterFarmIds = [];
                        }
                        if (queryParams['lots'] !== undefined) {
                            // we have lots in query params, but need to check if we have an array of lots
                            this.filterLotIds = Array.isArray(queryParams['lots'])
                                ? queryParams['lots'].map((lotId: string): number => parseInt(lotId, 10))
                                : [parseInt(queryParams['lots'], 10)];
                        }
                        else {
                            this.filterLotIds = [];
                        }
                        this.applyFiltering();
                    });
    }

    public ngOnInit(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
        this.widgetService.setSize(6)
        this.refreshData();

    }

    public ngOnDestroy(): void {
        // cleanup for the map
        this.mapService.resetMap();
        this.mapService.mapMenuOptions = [];
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    public showFieldOnMap(field: FieldModel): void {
        this.mapService.centerMapOnPolygonIndex(
            this.mapService.mapPolygons.findIndex((poly: MapPolygonInterface): boolean => field.id === poly.data['field']));
    }

    public showLotOnMap(lot: LotModel): void {
        this.mapService.centerMapOnPolygonIndex(
            this.mapService.mapPolygons.findIndex((poly: MapPolygonInterface): boolean => lot.id === poly.data['lot']));
    }

    public deleteLot(lotId: number): void {
        this.lotService.deleteLot(lotId).pipe(take(1)).subscribe((): void => {
            this.toastr.success(this.translate.instant('farm.lot.lotDeletedSuccessfully'));
            this.refreshData();
            this.globalRegistry.reloadLots();
        }, (): void => {
            this.toastr.error('farm.lot.errorDeletingLot');
            this.refreshData();
        });
    }

    public deleteField(fieldId: number): void {
        this.fieldService.deleteField(fieldId).pipe(take(1)).subscribe((): void => {
            this.toastr.success(this.translate.instant('farm.field.fieldDeletedSuccessfully'));
            this.refreshData();
        }, (): void => {
            this.toastr.error('farm.field.errorDeletingField');
            this.refreshData();
        });
    }

    private initMap(): void {
        this.mapService.isEditMode = false;
        this.mapService.resetMap();
        this.fieldsPolygons = [];
        this.filteredFields.forEach((field: FieldModel): void => {
            // filter fields that don't belong to a lot available for the user
            if (this.globalRegistry.systemData.lots.some((lot: LotModel): boolean => lot.id === field.lot)) {
                this.fieldsPolygons.push({
                    points: MapHelper.convertToAGMPolygon(field.coords.coordinates[0]),
                    strokeColor: '#33de55',
                    fillColor: '#248a38',
                    data: { field: field.id }
                });
            }
        });
        this.filteredLots.forEach((lot: LotModel): void => {
            this.fieldsPolygons.push({
                points: MapHelper.convertToAGMPolygon(lot.coords.coordinates[0]),
                fillColor: '#ffff00',
                strokeColor: '#ffff00',
                data: { lot: lot.id }
            });
        });
        this.mapService.mapPolygons = this.fieldsPolygons;
        this.mapService.centerMapOnPolygons();
    }

    private refreshData(): void {
        const url = this.fieldService.getUrl('');
        this.cacheService.delete(url+'limit=1000&offset=0');
        this.fieldService.getFields({
            limit: 1000,
            offset: 0
        }).pipe(take(1)).subscribe((fields: ResponseModel<FieldModel[]>): void => {
            this.fields = fields.model;
            this.applyFiltering();
            this.initMap();
        });
    }

    private applyFiltering(): void {
        if (this.filterFarmIds.length > 0) {
            this.filteredFarms = this.globalRegistry.systemData.farms.filter((farm: FarmModel): boolean => {
                return this.filterFarmIds.includes(farm.id);
            });
        }
        else {
            this.filteredFarms = this.globalRegistry.systemData.farms;
        }
        if (this.filterLotIds.length > 0) {
            this.filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                return this.filterLotIds.includes(lot.id);
            });
        }
        else if (this.filterFarmIds.length > 0) {
            this.filteredLots = this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
                return this.filteredFarms.some((farm: FarmModel): boolean => farm.id === lot.farm);
            });
        }
        else {
            this.filteredLots = this.globalRegistry.systemData.lots;
        }
        if (this.fields) {
            this.filteredFields = this.fields.filter(
                (field: FieldModel): boolean => this.filteredLots.some((lot: LotModel): boolean => lot.id === field.lot));
            this.initMap();
        }
    }
}
