import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { AttributeRelationModel } from '../../../../core/models/attribute/attribute-relation.model';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { NavService } from '../../../../shared/services/nav.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'fap-attributes',
    templateUrl: './fap-attributes.component.html',
    styleUrls: ['./fap-attributes.component.scss'],
})
export class FapAttributesComponent implements OnChanges {
    @Input()
    public entityRelation: string = '';

    @Input()
    public objectId: number;

    @Input()
    public entityId: number;

    @Input()
    public isDynamic = false;

    @Input() entityInfo: any;
    
    @Input() form: any;

    @Input() type = 'add';

    @Input() typeOfForm = '';

    @Input() formDisplayType = 'modal';

    @Input() previousFormClick = false;
    @Input() nextFormClick = false;

    @Input() showHeader = true;

    @Input() workflow: any;
    @Input() formTypeId: number;
    @Input() btnsDisabled: boolean = false;
    @Input() errorFields: any[] = [];
    @Input() size: string =  null;
    @Input() isLoading: boolean = false;

    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() formUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() formTypeEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() formFieldsEmit: EventEmitter<any> = new EventEmitter<any>();
    @Output() hideModal: EventEmitter<any> = new EventEmitter<any>();
    @Output() nextButtonClick: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() prevButtonClick: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    public currentRelation: AttributeRelationModel;

    public editRelations = false;
    public addRelations = false;
    public filteredEntities = [];
    public subscriptions = [];
    public formType = null;

    constructor(
        public navService: NavService,
        public globalRegistry: GlobalRegistryService,
        public typeService: TypesService,
        public attributeService: AttributeService,
        public cacheService: CacheResolverService,
        public toastrService: ToastrService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if (
            (Object.prototype.hasOwnProperty.call(changes, 'objectId') &&
            this.entityRelation !== 'pool' && this.entityRelation !== 'resource_type' && !this.isDynamic) || this.btnsDisabled
        ) {
            this.updateList();
        }
        if (
            Object.prototype.hasOwnProperty.call(changes, 'entityInfo') &&
            this.entityRelation === 'resource_type'
        ) {
            this.entityId = this.entityInfo.id;
            this.updateResourceTypeList();
        }
        if (
            Object.prototype.hasOwnProperty.call(changes, 'entityRelation') &&
            this.entityRelation === 'pool'
        ) {
            this.updatePoolList();
        }
        if (Object.prototype.hasOwnProperty.call(changes, 'form') && this.form) {
            this.formType = this.form;
            this.formTypeEmit.emit(this.formType);
        }
    }

    public getModels() {
        this.typeService.getModels().subscribe((data) => {
            this.filteredEntities = data.body.results;
        });
    }

    public editForm() {
        this.addRelations = true;
    }

    public updateList() {
        const url = this.attributeService.getUrl('form/');
        if (this.objectId != null) {
            let filters;
        if(this.entityRelation.trim() === '') {
        filters = {
                type: this.formTypeId,
                id: this.objectId
            }
            this.cacheService.delete(
                url + 'type=' + this.formTypeId + '&id=' + this.objectId
            );
        } else {
            filters = {
                entity: this.entityRelation,
                id: this.objectId,
            };
            this.cacheService.delete(
                url + 'entity=' + this.entityRelation + '&id=' + this.objectId
            );
        }
        this.attributeService.getAttributeForm(filters).subscribe({
            next: (response) => {
              console.log('responses', response);
              this.formType = response.body.results[0];
              this.formTypeEmit.emit(this.formType);
            },
            error: (error) => {
              this.toastrService.error(error.error.results.error);
            }
          });          
        }
    }

    public updateResourceTypeList() {
        if (this.objectId != null && this.entityId != null) {
            const filters = {
                type: this.entityId,
                id: this.objectId,
            };
            const url = this.attributeService.getUrl('form/');
            this.cacheService.delete(
                url + 'type=' + this.entityId + '&id=' + this.objectId
            );
            this.attributeService.getAttributeForm(filters).subscribe(
                (response) => {
                    console.log(response);
                    this.formType = response.body.results[0];
                    this.formTypeEmit.emit(this.formType);
                },
                (error) => {
                  this.toastrService.error(error.error.results.error);
                }
            );
        }
    }

    public updatePoolList() {
        console.log(this.objectId);
        if (this.objectId != null && this.entityId) {
            const filters = {
                type: this.entityId,
                id: this.objectId,
            };
            const url = this.attributeService.getUrl('form/');
            this.cacheService.delete(
                url + 'type='+ this.entityId + '&id=' + this.objectId
            );
            this.attributeService
                .getAttributeForm(filters)
                .subscribe((response) => {
                    console.log(response);
                    this.formType = response.body.results[0];
                    // this.formTypeEmit.emit(this.formType);
                });
        }
    }

    public updateRelation() {
        if(this.entityRelation === 'resource_type') {
            this.updateResourceTypeList();
        } else {
            this.updateList();
        }
    }
}
