<fap-header>
    <div class="d-flex filters align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_btn mr-3">
          <fap-round-button [routerLink]="'/pages/farm/create'" [backgroundColor]="'tertiary'"></fap-round-button>
        </div>
      </div>
</fap-header>
<div class="container-fluid ter-container crops-container-scroll">
    <fap-crops-layout 
        key = "lots"
        [farms]="filteredFarms"
        [lots]="filteredLots"
        [farmForecastMapping]="farmForecastMapping"
        (clickedLot)="centerMapOnLot($event)"
        (deleteLot)="deleteLot($event)">
    </fap-crops-layout>
</div>
