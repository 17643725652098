<fap-header>
    <div class="d-flex align-items-center">
        <div class="add_btn mr-3">
            <fap-round-button [routerLink]="'/pages/crops/add'" [backgroundColor]="'tertiary'"></fap-round-button>
        </div>
        <fap-search-filter
          [isDateFilterEnabled]="true"
          [filterLabel]="'Filter'"
          (filter)="getFilteredCrops()"
        ></fap-search-filter>
    </div>
</fap-header>
<div class="crops_page" id="parent100">
    <div class="crops_table" 
        infiniteScroll
        [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="500"
        (scrolled)="getNextParticles()"
        [scrollWindow]="true"
        #cropsParent
    >
        <table>
            <thead>
                <tr>
                    <th>{{'crops.place' | translate}}</th>
                    <th>{{'crops.area' | translate}}</th>
                    <th>{{'crops.product' | translate}}</th>
                    <th>{{'crops.started' | translate}}</th>
                    <th>{{'crops.ended' | translate}}</th>
                    <th>{{'crops.progress' | translate}}</th>
                    <th>{{'crops.calculatedCost' | translate}}</th>
                    <th>{{'crops.totalCost' | translate}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let crop of crops" [routerLink]="'/pages/crops/edit/' + crop.id">
                    <td>
                        <ng-container *ngFor="let lot of globalRegistry?.systemData?.lots">
                            <ng-container *ngIf="lot?.id === crop?.lot">
                                <div class="custom_flex">
                                <div class="image">
                                    <div class="user_im img_place_bg">
                                        <img [src]="lot?.staticMapImage" alt="lot map">
                                    </div>
                                </div>
                                <div class="name">{{lot?.name}}</div>
                            </div>
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <ng-container *ngFor="let lot of globalRegistry?.systemData?.lots">
                            <ng-container *ngIf="lot?.id === crop?.lot">
                                {{getLotArea(lot?.coords) | number:'1.'+globalRegistry.systemData.company.digits}} Ha
                            </ng-container>
                        </ng-container>
                    </td>
                    <td>
                        <div class="custom_flex">
                            <div class="image">
                                <div class="user_im img_place_bg prod_img">
                                    <img *ngIf="crop?.product?.images.length" [src]="mediaUrl + crop?.product?.images[0]?.file" alt="lot map">
                                    <img class="user_im" *ngIf="!crop?.product?.images" src="../../../../../../assets/images/landscape_placeholder.png" alt="">
                                </div>
                            </div>
                            <div class="name">{{crop?.product?.label?.name}}</div>
                        </div>
                    </td>
                    <td>{{crop?.start | date: "MMM dd yyyy, HH:mm"}}</td>
                    <td>{{crop?.end | date: "MMM dd yyyy, HH:mm"}}</td>
                    <td>{{crop?.progress}}%</td>
                    <td>{{crop?.forecastCost}}</td>
                    <td>{{crop?.totalCost}}</td>
                    <td>
                        <button class="ricon" (click)="deleteCrop(crop?.id)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>