import { GlobalRegistryService } from './../../../../../core/global-registry/global-registry.service';
import { Component } from '@angular/core';
import {
    take,
    tap
} from 'rxjs/operators';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { ActivatedRoute } from '@angular/router';
import { PartyService } from '../../../../../core/services/api/company/party.service';
import { ToastrService } from 'ngx-toastr';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';


@Component({
    templateUrl: './party-container.component.html'
})
export class PartyContainerComponent {

    public parties: Array<PartyModel> = [];


    constructor(public activatedRoute: ActivatedRoute,
                public partyService: PartyService,
                private toastyService: ToastrService,
                public globalRegistry: GlobalRegistryService,
                private mapService: MapService) {
        this.mapService.hideMap();
    }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).pipe(tap(
            (): void => {
                this.globalRegistry.reloadParties();
                this.toastyService.success('Party deleted succesfully');
            },
            (): void => {
                this.toastyService.error('Delete failed');
            }),
            take(1)).
        subscribe();
    }
}
