import { Component, OnInit } from '@angular/core';
import { FarmModel } from '../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../core/models/response.model';

import { FarmService } from '../../../../core/services/api/farm/farm.service';

@Component({
    selector: 'fap-layout',
    templateUrl: './fap-layout.component.html',
    styleUrls: ['./fap-layout.component.scss']
})
export class FapLayoutComponent implements OnInit{
    public farms: Array<any> = ['ferma1', 'ferma2', 'ferma3', 'ferma4'];
    private weeds: Array<any> = ['weeds1', 'weeds2', 'weeds3', 'weeds4'];

    constructor(private farmService: FarmService) {
    }

    public ngOnInit(): void {
        this.farmService.getFarms().subscribe((response: ResponseModel<FarmModel[]>) => {
            console.log(response);
        });
    }

}
