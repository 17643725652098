<div class="path pt-1">
    <!-- <i class="material-icons p-2">map</i> -->
    <span *ngIf="isLoading" class="loader">
        <i></i>
      </span>
    <table class="paths" *ngIf="!isLoading">
        <tr *ngFor="let path of paths">
            <td>{{path?.path?.id}}</td>
            <td>
                <span>{{path?.start | date: "MMM dd yyyy, HH:mm"}}</span>
                <span>{{path?.end | date: "MMM dd yyyy, HH:mm"}}</span>
            </td>
            <td></td>
            <td>
                <button (click)="loadPath(path)">
                    <img src="../../../../../../assets/images/path_icon.png" alt="path">
                </button>
            </td>
        </tr>
    </table>
</div>
