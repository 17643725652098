import { FapBaseModel } from '../../base/fap-base-model';
import { PersonProfileModel } from '../person/person-profile.model';
import { PartyModel } from '../party/party.model';


export class PersonFarmModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'role',
        'company',
        'phone',
        'address',
        'picture',
        'job',
        'user',
        'active_farms',
        'party',
        'activities'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
        user: PersonProfileModel,
        party: PartyModel
    };

    public id: number;
    public role: number;
    public company: string;
    public phone: string;
    public address: string;
    public picture: string;
    public job: string;
    public user: PersonProfileModel = new PersonProfileModel();
    public activeFarms: number[];
    public party: PartyModel = new PartyModel();
    public activities: number[];
}
