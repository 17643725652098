import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { BaseTypeModel } from '../../../../../core/models/base/base-type.model';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PostModel } from '../../../../../core/models/farm/posts.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { WidgetsService } from "../../../../../core/services/api/widgets/widgets.service";
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../../shared/services/nav.service';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'posts-layout',
  templateUrl: './posts-layout.component.html',
  styleUrls: ['./posts-layout.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(1000)
      ]),
      transition('* => void', [
        animate(1000, style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class PostsLayoutComponent implements OnInit, OnDestroy {

  @Input()
  public farms: Array<FarmModel>;
  @Input()
  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  @Output()
  public deletePost: EventEmitter<number> = new EventEmitter();
  public subscription: Subscription = new Subscription();
  @Input()
  public posts: Array<any> = [];
  public genericTypes: Array<BaseTypeModel> = []
  public isLoading = false;
  public crops: Array<CropModel> = [];
  @ViewChild('latestPopup') latestPopup: FapModalComponent;
  public apiUrl = environment.baseBackendUrl;
  public activeSlide = null;
  public counter = 0;
  public newPosts: Array<any> = [];
  public fromDate = new Date();
  public toDate =  new Date();
  public lots = [];
  public translatedNames:any = [];
  public langString: string;
  public allLots: Array<LotModel> = [];
  @Input() public persons: Array<PersonModel>;
  @Input() public images = [];
  
  constructor(public farmService: FarmService, public typesService: TypesService, public cropService: CropService,public companyService: CompanyService, public widgetsService: WidgetsService, public sensorService: SensorService, public globalRegistry: GlobalRegistryService, public navService: NavService, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getNewPosts();
    this.subscription.add(this.typesService.getGenericTypes().subscribe(data => {
      this.genericTypes = data.model;
    }));
    this.subscription.add(this.cropService.getCrops().subscribe(data => {
      this.crops = data.model;
    }));
    this.allLots = this.globalRegistry.systemData.lots;     
    this.langString = localStorage.getItem('language');
    this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
      if(lang) {
        this.langString = lang;
        }
     }));
    this.translatedNames = this.globalRegistry.systemData.translations;
  }


  public getNewPosts() {
    const params = {seen: 0};
    this.subscription.add(this.farmService.getNewPosts(params).subscribe(data => {
      this.newPosts = data.body.results;
      this.isLoading = false;
      console.log(this.newPosts);
      if(this.newPosts.length > 0) {
        this.showModal();
      } else {
        this.hideModal();
      }
    }))
  }

  public markAsRead(post: PostModel) {
    post.seen = true
    this.subscription.add(this.farmService.updatePost(post.id, post).subscribe(data => {
      console.log(data);
      this.getNewPosts();
    }))
  }

  public deleteThisPost(postId: number) {
    this.subscription.add(this.farmService.deletePost(postId).subscribe(data => {
      console.log(data);
      this.posts = this.posts.filter(function(item) {
        return item.id != postId;
    });
    }))
  }

  showModal(): void {
    const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
}

hideModal(): void {
  const elem = document.getElementById('custom_modal');
  const parent = document.getElementById('parent');
  elem.classList.remove('active');
  parent.classList.remove('backdrop');
}

showNextImage() {
  const total = this.newPosts.length - 1;
  this.counter = this.inc(total);
}

showPreviousImage() {
  const total = this.newPosts.length - 1;
  this.counter = this.dec(total);
}

inc(total) {
  return this.counter < total ? this.counter + 1 : 0;
}

dec(total) {
  return this.counter > 0 ? this.counter - 1 : total;
}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
