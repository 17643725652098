import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent {

  @Input() locked: boolean = false;
  @Input() isEditable: boolean = true;
  @Input() isDeleteable: boolean = true;
  @Output() editEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

}
