<div class="customizer-links {{size}}" [ngClass]="{'shift1': mapService.mapShiftedOnce, 'shift2': mapService.mapShiftedTwice, 'open' : mapService.mainMapIsOpened }" id="inner-customizer">
    <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical" *ngIf="!mapService.isFullMap">
        <a href="javascript:void(0)" class="nav-link"
            [ngClass]="{'active show': mapService.mainMapIsOpened }"
            id="c-pills-home-tab"
            (click)="toggleMainMap()">

            <div class="settings" *ngIf="showBtn"> <i class="icofont icofont-map-search"></i> </div>
        </a>

        <!-- <a href="javascript:void(0)" class="nav-link"
            [ngClass]="{'active show': mainMap == 'color' }"
            id="c-pills-profile-tab"
            (click)="mapService.mainMapIsOpened()">

            <div class="settings color-settings"> <i class="icofont icofont-color-bucket"></i> </div>
        </a> -->
    </div>
</div>
<div class="customizer-contain {{size}}" [ngClass]="{'shift1': mapService.mapShiftedOnce, 'shift2': mapService.mapShiftedTwice, 'open' : mapService.mainMapIsOpened, 'full' : mapService.isFullMap, 'collapsedSidebar': navService.collapseSidebar && mapService.isFullMap}" (click)="disableContextMenu()" oncontextmenu="return false;">
    <!-- <div (click)="mapService.shiftMapLeft(mapShifter());" class="map-arrow" *ngIf="mapService.mainMapIsOpened && showBtn">

    </div> -->
    <div class="close-map">
        <div class="close-map-btn "
            [ngClass]="{'active show': mapService.mainMapIsOpened }"
            (click)="toggleMainMap()"
            role="tablist" aria-orientation="vertical">
               <i class="icofont icofont-close"></i>
        </div>

    </div>
    <div [ngClass]="{'opened': mapSearchToggle, 'd-flex': mapTypeControl}" class="search">
            <label for="search" class="fa fa-search"> </label>
            <input id="search" matInput (focus)="mapSearchToggle = true" (focusout)="mapSearchToggle = false" type="text" class="form-control color-black" (keydown.enter)="$event.preventDefault()" placeholder="Search map" autocorrect="off" autocapitalize="off" autocomplete="false" spellcheck="off" #search name="search">
            <span (click)="coordsListToggle = !coordsListToggle" [ngStyle]="{'background-color':navService.hoverColor}" *ngIf="mapTypeControl">
                <img src="./assets/images/fap/add_edit_farm.svg" alt="edit farm">
            </span>
    </div>
    <div class="coords-list" *ngIf="coordsListToggle && mapTypeControl">
        <div class="header">
            <p><b>Length:</b> {{polyLength | number: '1.1-2'}} Km</p>
            <p><b>Area: </b>{{mapService.lastPoligonTouchedSelectedArea | number: '1.1-2'}} Ha</p>
        </div>
        <div class="content-wrapper">
            <form [formGroup]="formCoords" (submit)="deleteCoords(formCoords.value)">
            <div class="utils">
                <div class="modifiers">
                    <span (click)="selectAllCheckboxes()">
                    <img src="./assets/images/fap/select_all.svg" alt="select all">
                </span>
                    <button [ngStyle]="{'background': 'none'}" type="submit">
                    <img src="./assets/images/fap/trash.svg" alt="delete selected">
                </button>
                </div>
                <div class="buttons">
                    <button class="import-label"
                           [ngStyle]="{'background-color': navService.sidebarBackgroundColor}"  name="import">
                            import
                        <input name="import" (change)="fileChangeListener($event)" #fileImportInput type="file">
                </button>

                    <button
                        (click)="exportCoords()"
                        [ngStyle]="{'background-color': navService.sidebarBackgroundColor}" >
                        export
                    </button>
                </div>
            </div>
            <div class="overflow-auto">
            <div *ngFor="let coord of coordCheckboxes.controls, let i = index">
                <div class="coords" [ngClass]="{'green': changedCoordinate === i}">
                    <input [formControl]="coord" type="checkbox" (change)="getCheckboxIndex($event, i)"/>
                    <b><p>{{getNumber(i + 1)}}</p></b>
                    <p>{{editableCoords[i].toString()}}</p>
                </div>
            </div>
            </div>
            </form>
        </div>
    </div>
    <agm-map
        [latitude]="mapService.mapFocusPoint.lat"
        [longitude]="mapService.mapFocusPoint.lng"
        [(zoom)]="zoom"
        [streetViewControl]="false"
        [mapTypeControl]="false"
        [mapTypeId]="'hybrid'"
        [fitBounds]="mapService.bounds"
        (contextmenu)="onContextMenuRightClick($event)"
        (mapRightClick)="mapRightClicked($event)"
        (mapReady)="onMapReady($event)"
        (mapClick)="getCoords($event)"
        >

<!--        <button #mapRevertBtn-->
<!--                (click)="mapService.clickedRevertBtn.emit()"-->
<!--                [ngClass]="{'d-none': !mapService.isEditMode}"-->
<!--                class="btn btn-light mr-2 mt-2">-->
<!--            {{ 'revertChanges' | translate }}-->
<!--        </button>-->
        <button *ngFor="let button of mapService.buttons"
                id={{button.value}}
                (click)="mapService.mapButtonClick.emit(button.toString())"
                [ngClass]="{'d-none': !mapService.showButtons}"
                class="btn btn-light m-1 px-1 map-action-left-button">
            {{ button.caption }}
        </button>

        <ng-container>
            <agm-circle
                *ngFor="let circle of mapService.mapCircles"
                [latitude]="circle.center.lat"
                [longitude]="circle.center.lng"
                [radius]="circle.circleRadius"
                [fillColor]="circle.fillCollor"
                [strokeColor]="circle.strokeColor"
                [circleDraggable]="mapService.isEditMode"
                [editable]="mapService.isEditMode"
                (circleClick)="circleClicked($event)"
                (radiusChange)="circleRadiusChange($event)"
                (dragEnd)="circleDragEnd($event)">
            </agm-circle>
        </ng-container>

        <ng-container *ngIf="mapService.reports.length">
            <agm-circle
                *ngFor="let report of mapService.reports"
                [latitude]="report?.coords?.coordinates[0]"
                [longitude]="report?.coords?.coordinates[1]"
                [radius]="calculateRadius(report?.infested_area)"
                [fillColor]="circleFillColor"
                [fillOpacity]="0.9"
                [strokeColor]="circleStrokeColor"
                [strokeWeight]="circleStrokeWeight"
                (circleClick)="reportCircleClicked(report.id)"
            ></agm-circle>
        </ng-container>

        <ng-container>
            <agm-polygon
                *ngFor="let polygon of mapService.mapPolygons; let index = index"
                [editable]="mapService.isEditMode&&polygon.isEditable!==undefined&&polygon.isEditable!==null?polygon.isEditable:mapService.isEditMode"
                [paths]="polygon.points"
                [zIndex]="1000"
                [draggable]="false"
                [strokeColor]="polygon.strokeColor"
                [fillColor]="polygon.fillColor"
                [strokeWeight]="polygon.strokeWeight"
                [polyDraggable]="mapService.isEditMode&&polygon.isEditable!==undefined&&polygon.isEditable!==null?polygon.isEditable:mapService.isEditMode"
                (polyPathsChange)="polyPathsChange($event, index)"
                (polyClick)="polyClick($event, polygon, index)"
                (polyDblClick)="polyClickEdit($event, polygon, index)"
                (polyRightClick)="polyRightClick($event, polygon, index)"
                (polyMouseOver)="polyHover($event, polygon)"
                (polyMouseOut)="polyMouseOut($event, polygon)"
                (polyDragEnd)="polyDragEnd($event)">
            </agm-polygon>
        </ng-container>

        <ng-container>
            <agm-marker
                *ngFor="let marker of mapService.mapMarkers"
                [latitude]="marker.lat"
                [longitude]="marker.lng"
                [label]="marker.label"
                [zoom]="marker?.zoom"
                [iconUrl]="marker.iconUrl"
                [markerDraggable]="false"
                (markerClick)="clickedMarker($event, marker)"
                (dragEnd)="markerDragEnd(marker, $event)">

                <agm-info-window *ngIf="marker.infoWindowDetails">
                    <div class="card-group">
                        <div *ngFor="let info of marker.infoWindowDetails" class="card pr-1">
                        <img class="card-img-top" [src]="info.img">
                        <div class="card-body p-1">
                            <p [class.card-text-link]="info?.link" class="card-text" [innerHTML]="info?.text" (click)="info?.link && navigate(info?.link)"></p>
                          </div>
                                                   
                        </div>
                    </div>
                </agm-info-window>
            </agm-marker>
            <agm-marker 
            *ngFor="let marker of object"
            [latitude]="marker.lat"
            [longitude]="marker.lng"
            [iconUrl]='{"url": apiUrl.slice(0, -1) + marker.icon,"scaledSize": {"height": 25, "width": 25}}'
            >
                
            </agm-marker>
        </ng-container>
        <ng-container>
            <agm-polyline *ngFor="let polyline of mapService.mapPolylines;let i = index;"
                          [strokeColor]="polyline.color"
                          [editable]="mapService.isEditMode"
                          (lineClick)="lineClick($event)"
                          (polyPathChange)="polyPathChange($event)">

                <agm-polyline-point *ngFor="let point of polyline.points"
                                    [latitude]="point.lat"
                                    [longitude]="point.lng">
                </agm-polyline-point>
            </agm-polyline>
        </ng-container>
    </agm-map>
    <div *ngIf="contextMenuEnabled">
        <map-menu
            [x]="contextmenuX"
            [y]="contextmenuY"
            [options]="mapService.mapMenuOptions"
            (menuInit)="onMapMenuInit()">
        </map-menu>
    </div>

    <ngx-spinner type="ball-clip-rotate"
                 size="medium"
                 color="white"
                 bdColor="rgba(70,70,70, .5)"
                 [fullScreen]="false">
    </ngx-spinner>
</div>
