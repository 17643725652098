<div class="activity-row mt-2">
    <div class="heading" (click)="expanded = !expanded">
        <i class="material-icons my-auto">{{ expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</i>
        <div class="activity-row-img">
            <img src="/assets/images/calendar.svg" alt="calendar">
        </div>
        <div class="activity-row-title">
            <h5>{{ activity.name }}</h5>
            <span>{{ activity.info ? activity.info : 'No info' }}</span>
        </div>
        <div *ngIf="!hideButtons" class="activity-row-actions">
            <i class="material-icons" (click)="editActivity.emit(activity)">edit</i>
            <i class="material-icons" (click)="deleteActivity.emit(activity.id)">delete</i>
        </div>
    </div>
    <div *ngIf="expanded" class="extra-info row mt-2">
        <div class="extra-info-row">
            {{ 'activity.startedOn' | translate }}: <b>{{ activity.started ? activity.started : 'xxxx-xx-xx hh-mm-ss' }}</b>
        </div>
        <div class="extra-info-row">
            {{ 'activity.endedOn' | translate }}: <b> {{ activity.ended ? activity.ended : 'xxxx-xx-xx hh-mm-ss' }}</b>
        </div>
        <div class="extra-info-row">
            {{ 'activity.workingTime' | translate }}: <b> {{ activity.workingTime }}</b>
        </div>
        <div class="extra-info-row">
            {{ 'activity.tracking' | translate }}: <b> 1</b>
        </div>
        <div class="extra-info-row">
            {{ 'activity.phaseId' | translate }}: <b> 12</b>
        </div>
    </div>
</div>
