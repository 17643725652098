import { Routes } from '@angular/router';
import { SchedulerContainerComponent } from './containers/scheduler-container/scheduler-container.component';

import { SchedulerEditContainerComponent } from './containers/scheduler-edit-container/scheduler-edit-container.component';

export const schedulerRoutes: Routes = [
    {
        path:       '',
        component:  SchedulerContainerComponent
    },
    {
        path:       'add',
        component:  SchedulerEditContainerComponent
    },
    {
        path: 'edit/:sensorId',
        component: SchedulerEditContainerComponent
    },
];
