import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { FapAPIRequestOptions } from "../fap-api-request.options";
import { ResponseModel } from "../../../models/response.model";
import { FapRestBaseService } from "../../../base/fap-rest-base.service";
import { DrillParams } from "../../../interfaces/widget/drill-info.interface";
import { FapBaseModel } from "../../../base/fap-base-model";
import { CacheResolverService } from "../cache/cache-resolver.service";

export class ObjectModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'extra_text',
        'prod_type',
        'name_t'
    ];
    public static requiredFields: Array<string> = [
        'id',
    ];
    public static extendedFields: {} = { };
    public id: number;
    public extraText: string;
    public prodType: number;
    public nameT: string
}

export class DeviceModel extends FapBaseModel {
  public static fields: Array<string> = [
      'id',
      'name',
      'type',
      'lots',
      'owner',
      'connectivity',
      'coords',
      'display_data',
      'document',
      'product',
      'name_t',
      'last_transmission'
  ];
  public static requiredFields: Array<string> = [
      'id',
  ];
  public static extendedFields: {} = { };
  public id: string;
  public name: string;
  public type?: number;
  public lots: Array<number>;
  public owner?: number;
  public connectivity: any;
  public coords: any;
  public displayData: any[];
  public document: number;
  public product: any;
  public nameT: string;
  public lastTransmission: string;
}

@Injectable()
export class ObjectService extends FapRestBaseService {
  public drill: Subject<DrillParams> = new Subject<DrillParams>();

  constructor(tosterService: ToastrService, private _http: HttpClient, public cacheService: CacheResolverService) {
    super(tosterService, _http, "/device/");
  }

  getUrl(slug) {
    return this.url+slug
  }

  public getObjectTypes(params?: {}): Observable<ResponseModel<ObjectModel[]>> {
    return this.mapRequest<ObjectModel[]>(
      this._http.get(this.url + "object_type/", {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params,
      }),
      ObjectModel,
      true
    ).pipe(take(1));
  }

  public getDevices(params?: {}): Observable<ResponseModel<DeviceModel[]>> {
    this.cacheService.delete(this.url + 'devices/');
    return this.mapRequest<DeviceModel[]>(
      this._http.get(this.url + 'devices/', {
          headers: FapAPIRequestOptions.withTokenHeaders,
          params,
      }),
      DeviceModel,
      true
  ).pipe(take(1));
  }

  public getDevice(deviceId?: {}): Observable<ResponseModel<DeviceModel>> {
    this.cacheService.delete(this.url + 'devices/' + deviceId + '/');
    return this.mapRequest<DeviceModel>(
      this._http.get(this.url + 'devices/' + deviceId + '/', {
          headers: FapAPIRequestOptions.withTokenHeaders,
      }),
      false,
      false,
  ).pipe(take(1));
  }

  public addDevice(device: any): Observable<ResponseModel<{}>> {
    return this.mapRequest(
        this._http.post(this.url + 'devices/',
            this.camelCaseModelToSnakeCaseJson(device),
            FapAPIRequestOptions.withTokenRequestOptions),
            false,
            true
    ).pipe(take(1));
  }

  public updateDevice(deviceId, device: any): Observable<ResponseModel<{}>> {
    return this.mapRequest(
        this._http.patch(this.url + 'devices/'+deviceId+'/',
            this.camelCaseModelToSnakeCaseJson(device),
            FapAPIRequestOptions.withTokenRequestOptions),
            false,
            true
    ).pipe(take(1));
  }

  public getDeviceData(params: {}): Observable<ResponseModel<any[]>> {
    return this.mapRequest<any[]>(
      this._http.get(this.url + "data/", {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params
      }),
      false,
      false
    ).pipe(take(1));
  }

  public getAdvDeviceData(params: {}): Observable<ResponseModel<any[]>> {
    return this.mapRequest<any[]>(
      this._http.get(this.url + "adv_data/", {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params
      }),
      false,
      false
    ).pipe(take(1));
  }

  public getAdvDeviceDataColumns(params: {}): Observable<ResponseModel<any[]>> {
    return this.mapRequest<any[]>(
      this._http.get(this.url + "adv_data/columns/", {
        headers: FapAPIRequestOptions.withTokenHeaders,
        params
      }),
      false,
      false
    ).pipe(take(1));
  }

  public postColumns(params: any, sensorGroupTypeId): Observable<ResponseModel<{}>> {
    return this.mapRequest(
      this._http.put(this.url + "adv_data/columns/?filter=selected&group="+sensorGroupTypeId,
          params,
          FapAPIRequestOptions.withTokenRequestOptions),
          false,
          true
  ).pipe(take(1));
  }
}
