<fap-header>
    <fap-header-actions
        [title]="'leftMenu.devices' | translate"
        [isDeleteEnabled]="false"
    ></fap-header-actions>
</fap-header>
<div class="container-fluid rspc">
    <div class="row">
        <div class="col-md-12">
            <div class="card mt-2 mx-auto">
                <div class="card-header">
                    <p class="mb-0 p-0">{{ 'devices.editDevice' | translate }}</p>
                </div>
                <div class="card-body">
                    <form [formGroup]="deviceEditForm" class="row">
                        <div class="form-group">
                            <div class="row align-items-baseline">
                                <div class="col-12">
                                    <div class="input-group w-100">
                                        <div class="col-6">
                                            <div class="input-group align-items-baseline w-100">
                                                <mat-form-field class="w-100">
                                                    <mat-label>{{ 'devices.id' | translate }}</mat-label>
                                                    <input matInput formControlName="id">
                                                    <mat-error *ngIf="deviceEditForm.controls['id'].errors?.required">
                                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="input-group align-items-baseline w-100">
                                                <mat-form-field class="w-100" (click)="addEditTranslation('notes.name')">
                                                    <mat-label>{{ 'notes.name' | translate }}</mat-label>
                                                    <input matInput formControlName="name" readonly #nameInput/>
                                                    <mat-error *ngIf="deviceEditForm.controls['name'].errors?.required">
                                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <chips 
                                            [items]="globalRegistry.systemData.lots" 
                                            [selectedItems]="selectedItems"
                                            [label]="'activities.lots' | translate"
                                            (updatedItems)="updateItems($event)"
                                            [addEnabled]="false"
                                        >
                                        </chips>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.device)" 
        [prefix]="'DEVICE_'" 
        [translation]="translation"
        [label]="label"
        [group]="'other'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>