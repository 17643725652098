<fap-wizard1
    #wizard
    [wizardSteps]="wizardSteps"
    [wizardReviewTab]="wizardReviewTab"
    [wizardSelectedStep]="'widget-step-1'"
    [wizardSubmitDisabled]="!selectedWidget"
    [wizardNextDisabled]="wizard.selectedTabId==='widget-step-2'&&!selectedWidget"
    (wizardSubmitted)="onSubmit()">
    <ng-template fapWizardContent [id]="'widget-step-1'" [title]="'Widget Info Type'">
        <fap-select-widget-info-type
            [farms]="farms"
            [lots]="lots"
            (selectWidgetInfoType)="selectWidgetInfoType($event)"
            (selectWidgetType)="selectWidgetType($event)">
        </fap-select-widget-info-type>
    </ng-template>
    <ng-template fapWizardContent [id]="'widget-step-2'" [title]="'Select Widget'">
        <fap-select-widget
            #selectWidgetStep
            [selectedWidgetType]="selectedWidgetInfoType"
            [contentType]="selectedContentType"
            (selectWidget)="selectWidget($event)">
        </fap-select-widget>
    </ng-template>
    <ng-template fapWizardReview>
        <fap-configure-widget
            [selectedWidget]="selectedWidget"
            [sensors]="sensors"
            (configurationChanged)="widgetConfig=$event">
        </fap-configure-widget>
    </ng-template>
</fap-wizard1>
