import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { SensorModel } from '../../../models/sensor/sensor.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { DrillParams } from '../../../interfaces/widget/drill-info.interface';
import { SensorActionModel } from '../../../models/sensor/sensor_action.model';

export interface SensorData {
    period?: string;
    total?: number;
}

@Injectable()
export class SensorService extends FapRestBaseService {
    public drill: Subject<DrillParams> = new Subject<DrillParams>();
    public filter: Subject<any> = new Subject<any>();
    public reportFilter: Subject<any> = new Subject<any>();
    public cropFilter: Subject<any> = new Subject<any>();

    private triggerDrill = new BehaviorSubject(false);
    private filterTrigger = new BehaviorSubject(false);
    private exportPostData = new BehaviorSubject(false);
    private postFromDate = new BehaviorSubject<Date>(new Date());
    private postToDate = new BehaviorSubject<Date>(new Date());
    private reportFromDate = new BehaviorSubject<Date>(new Date());
    private reportToDate = new BehaviorSubject<Date>(new Date());
    private reportSearchString = new BehaviorSubject('');
    private cropFromDate = new BehaviorSubject<Date>(new Date());
    private cropToDate = new BehaviorSubject<Date>(new Date());
    private cropSearchString = new BehaviorSubject('');
    private fromDate = new BehaviorSubject<Date>(new Date());
    private toDate = new BehaviorSubject<Date>(new Date());
    private agg = new BehaviorSubject('');
    private lots = new BehaviorSubject([]);
    private farms = new BehaviorSubject([]);

    getName = this.triggerDrill.asObservable();
    getFromDate = this.fromDate.asObservable();
    getToDate = this.toDate.asObservable();
    getPostFromDate = this.postFromDate.asObservable();
    getPostToDate = this.postToDate.asObservable();
    getReportFromDate = this.reportFromDate.asObservable();
    getReportToDate = this.reportToDate.asObservable();
    getSearchString = this.reportSearchString.asObservable();
    getCropFromDate = this.cropFromDate.asObservable();
    getCropToDate = this.cropToDate.asObservable();
    getCropSearchString = this.cropSearchString.asObservable(); 
    getAgg = this.agg.asObservable();
    getLots = this.lots.asObservable();
    getFarms = this.lots.asObservable();
    getFilterTrigger = this.filterTrigger.asObservable();
    getExportPostData = this.exportPostData.asObservable();

    constructor(tosterService: ToastrService, private _http: HttpClient) {
        super(tosterService, _http, '/sensor/');
    }

    public setName(data) {
        this.triggerDrill.next(data);
    }

    public setFilterTrigger(data) {
        this.filterTrigger.next(data);
    }

    public setFromDate(data) {
        this.fromDate.next(data);
    }

    public setToDate(data) {
        this.toDate.next(data);
    }

    public setPostFromDate(data) {
        this.postFromDate.next(data);
    }

    public setPostToDate(data) {
        this.postToDate.next(data);
    }

    public setReportFromDate(data) {
        this.reportFromDate.next(data);
    }

    public setReportToDate(data) {
        this.reportToDate.next(data);
    }

    public setReportSearchString(data) {
        this.reportSearchString.next(data);
    }

    public setCropFromDate(data) {
        this.cropFromDate.next(data);
    }

    public setCropToDate(data) {
        this.cropToDate.next(data);
    }

    public setCropSearchString(data) {
        this.cropSearchString.next(data);
    }

    public setAgg(data) {
        this.agg.next(data);
    }

    public setLots(data) {
        this.lots.next(data);
    }

    public setFarms(data) {
        this.farms.next(data);
    }

    public setExportPostDataTrigger(data) {
        this.exportPostData.next(data);
    }

    getUrl(slug) {
        return this.url+slug
    }

    public updateSensorName(sensorId, params?: {}) : Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.patch(this.url + 'sensors/' + sensorId + '/',
            this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public getSensors(params?: {}): Observable<ResponseModel<SensorModel[]>> {
        return this.mapRequest<SensorModel[]>(
            this._http.get(this.url + 'sensors/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            SensorModel,
            true
        ).pipe(take(1));
    }

    public getActuators(params?: {}): Observable<ResponseModel<SensorModel[]>> {
        return this.mapRequest<SensorModel[]>(
            this._http.get(this.url + 'actuators/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            SensorModel,
            true
        ).pipe(take(1));
    }

    public getSensorActions(params?: {}): Observable<ResponseModel<SensorActionModel[]>> {
        return this.mapRequest<SensorActionModel[]>(
            this._http.get(this.url + 'actions/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            SensorActionModel,
            true
        ).pipe(take(1));
    }

    public postSensorActions(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'actions/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public updateSensorActions(farmId: number, farm: any): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.patch(this.url + 'actions/' + farmId + '/',
                this.camelCaseModelToSnakeCaseJson(farm),
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        );
    }

    public getTriggers(params?: {}): Observable<any> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'trigger/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public postTrigger(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'trigger/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public updateTrigger(triggerId, params?: {}): Observable<any> {
        return this.mapRequest(
            this._http.patch(
                this.url + 'trigger/' + triggerId + '/', 
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
        ).pipe(take(1));
    }

    public deleteTrigger(triggerId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'trigger/' + triggerId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public getSchedulers(params?: {}): Observable<any> {
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'scheduler/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            false,
            false
        ).pipe(take(1));
    }

    public createScheduler(params: {}): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.post(this.url + 'scheduler/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
        ).pipe(take(1));
    }

    public updateScheduler(schedulerId, params: any): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.patch(
                this.url + 'scheduler/' + schedulerId + '/', 
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
        ).pipe(take(1));
    }

    public deleteScheduler(schedulerId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + 'scheduler/' + schedulerId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }

    public deleteSensorActions(params?: {}): Observable<any> {
        return this._http
            .delete(this.url + 'actions/' + params + '/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public drillData(params?: {}): Observable<any> {
        return this._http
            .get(this.url + 'drill_data/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            })
            .pipe(take(1));
    }

    public advdrillData(params?: {}): Observable<any> {
        return this._http
            .get(this.url + 'adv_drill_data/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            })
            .pipe(take(1));
    }

    public getAcquisition(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'command/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public getAdditionalInfo(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'command/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public getConfig(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'command/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public postAcquisition(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'command/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public deleteAcquisition(params?: {}): Observable<any> {
        return this._http
            .post(this.url + 'command/', params, {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }
}
