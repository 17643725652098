import {
    Directive,
    TemplateRef,
    Input
} from '@angular/core';


@Directive({
    selector: '[fapWizardReview]'
})
export class FapWizardReviewDirective {

    @Input()
    public title: string;

    constructor(public templateRef: TemplateRef<any>) {}
}
