import { Component } from '@angular/core';


@Component({
    templateUrl: './people.component.html',
    styleUrls: ['./people.component.scss']
})
export class PeopleComponent {

}
