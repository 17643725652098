<div class="mb-5 pb-5">
        <div class="form-group">
                <i class="fa fa-plus center f-20 ml-2" (click)="addPartyModal()"></i>
            </div>
    <div class="card">
        <table mat-table [dataSource]="parties" class="mat-elevation-z8">
            <h2 class="card-header-title mr-4">
                <i class="fa fa-plus ml-2 f-20"></i>
            </h2>
            <ng-container matColumnDef="name">
                <th id="" mat-header-cell *matHeaderCellDef> {{ 'party.name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> {{ 'party.email' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="website">
                <th mat-header-cell *matHeaderCellDef> {{ 'party.website' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.website}} </td>
            </ng-container>

            <ng-container matColumnDef="payer">
                <th mat-header-cell *matHeaderCellDef> {{ 'party.vatPayer' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <i *ngIf="element.vatPayer" class="fa fa-check center f-20"></i>
                    <i *ngIf="!element.vatPayer"class="fa fa-close center f-20"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'party.actions' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                        <div class="party-actions">
                                <i class="fa fa-edit center f-20"
                                    (click)="editParty(element);$event.stopPropagation()"></i>
                                <i class="fa fa-trash center ml-2 f-20" (click)="onDeleteParty(element.id)"></i>
                        </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <fap-modal #addEditPartyModal [showFooter]="false" inputTitle="{{ 'people.party.addEditParty' | translate }}">
        <fap-add-edit-party #addEditParty
            [party]="selectedParty"
            (addedParty)="addedOrEditedParty()"
            (updatedParty)="addedOrEditedParty()"
            (cancel)="addEditPartyModal.hideModal()"
            >
        </fap-add-edit-party>
    </fap-modal>

</div>
