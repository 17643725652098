<form class="theme-form" [formGroup]="cropTypeForm" id="parent">
    <div class="col-md-12">
        <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
            <mat-label class="col-form-label">{{ "notes.type_name" | translate }}</mat-label>
            <input matInput formControlName="name" readonly #nameInput/>
            <mat-error *ngIf="ifControlHasError('name','required')">
              <strong>{{ "auth.validation.requiredField" | translate }}</strong>
            </mat-error>
          </mat-form-field>
    </div>
    <div class="col-md-12">
        <div>
            <i class="material-icons">format_color_fill</i>
            <div class="color-input ml-2">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Color" formControlName="color" type="Color">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-3">
        <i class="material-icons file-input-icon">image</i>
        <input class="ml-2 w-80" type="file" name="{{ 'chooseIcon' | translate }}">
    </div>
    <div *ngIf="showIngredientsTable" class="col-md-12 mt-3">
        <fap-ingredient-table
            [ingredients]="ingredients"
            [farmId]="farmId"
            [ingredientTypes]="globalRegistry.systemData.ingredientTypes"
            (createdIngredient)="createdIngredient($event)"
            (updatedIngredient)="updatedIngredient($event)"
            (deleteIngredient)="deleteIngredient($event)">
        </fap-ingredient-table>
    </div>
    <!-- <div class="col-md-12 mt-3">
        <fap-disease-table
            [headerTitle]="'farm.crop.disease.pathogen' | translate"
            [context]="'pathogen'"
            (tableUpdated)="updatedPathogensTable($event)">
        </fap-disease-table>
    </div> -->
    <div class="w-100 mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="submit" (click)="submit()">{{ 'translations.save' | translate }}</button>
        <button class="btn" type="submit" (click)="cancel.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.cropType)" 
        [prefix]="prefix" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>