import { Component, Input } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'cell-cost-qty',
  templateUrl: './cell-cost-qty.component.html',
  styleUrls: ['./cell-cost-qty.component.scss']
})
export class CellCostQtyComponent {
  @Input() value: number;
  @Input() unit: string;
  @Input() bold: boolean = true;
  @Input() smallFont: boolean = false;
  @Input() color: string;

  constructor(public globalRegistry: GlobalRegistryService) {}
}
