import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';

@Component({
    selector: 'fap-activity-row',
    templateUrl: './fap_activity-row.component.html',
    styleUrls: ['./fap_activity-row.component.scss']
})
export class FapActivityRowComponent {

    @Input()
    public activity: ActivityModel;

    @Input()
    public hideButtons = false;

    @Output()
    public deleteActivity: EventEmitter<number> = new EventEmitter();

    @Output()
    public editActivity: EventEmitter<ActivityModel> = new EventEmitter();

    public expanded = false;

}
