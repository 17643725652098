import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { PartialsModule } from '../../views-partials/partials.module';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxNativeDateModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import {MatExpansionModule} from '@angular/material/expansion';
import { SimulationsComponent } from './simulations.component';
import { SimulationsContainerComponent } from './containers/simulations-container/simulations-container.component';
import { SimulationsLayoutComponent } from './components/simulations-layout/simulations-layout.component';
import { SimulationsAddEditContainerComponent } from './containers/simulations-add-edit-container/simulations-add-edit-container.component';
import { SimulationsAddEditLayoutComponent } from './components/simulations-add-edit-layout/simulations-add-edit-layout.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        PartialsModule,
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        MatExpansionModule,
    ],
    declarations: [
        SimulationsComponent,
        SimulationsContainerComponent,
        SimulationsLayoutComponent,
        SimulationsAddEditContainerComponent,
        SimulationsAddEditLayoutComponent
    ]
})

export class SimulationsModule {

}
