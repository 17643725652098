import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls: ['./circle-progress-bar.component.scss']
})
export class CircleProgressBarComponent implements OnChanges {

  @Input() progressValue: number;
  @Input() type: string;
  @Input() minValue
  @Input() maxValue
  @Input() fillColorRange: { min: number, max: number };
  color: string;
  circumference: number;

  ngOnChanges(changes: SimpleChanges): void {
    if(Object.prototype.hasOwnProperty.call(changes, 'progressValue') && this.progressValue) {
      if(this.type === 'ph') {
        if (this.progressValue >= 7 && this.progressValue <= 7.8) {
          this.color = '#006495';
        } else {
          this.color = '#FF0000';
        }      
      } else {
        if(this.progressValue < 700 ) {
          this.color = '#FF0000'
        } else {
          this.color = '#006495'
        }
      }
    }
    if (changes.minValue || changes.maxValue) {
      this.calculateCircumference();
    }
  }

  calculateCircumference(): void {
    const radius = 54; // Adjust this value based on the desired radius
    this.circumference = 2 * Math.PI * radius;
  }

  getStrokeDashArray(): string {
    return `${this.circumference} ${this.circumference}`;
  }

  getStrokeDashOffset(): string {
    const progress = (this.progressValue - this.minValue) / (this.maxValue - this.minValue);
    const offset = this.circumference * (1 - progress);
    return `${offset}px`;
  }

}
