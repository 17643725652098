<div class="d-flex justify-content-center align-items-center configure-container">
    <div *ngIf="widgetDataType==='api'">{{ "widget.wizard.noConfigMessage" | translate }}</div>
    <div *ngIf="widgetDataType==='sensor'">
        <mat-form-field>
            <mat-label>{{ "widget.wizard.setSensor" | translate }}</mat-label>
            <mat-select [(value)]="selectedSensor" (selectionChange)="changedSensor()">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #sensorFilter placeholder="Search">
                </mat-form-field>
                <mat-option *ngFor="let sensor of sensors | filter : sensorFilter.value" [value]="sensor.id">{{ sensor.objectId }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
