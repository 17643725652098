<fap-header>
  <div class="d-flex filters align-items-center">
    <!-- <div class="tree_combo mr-3">
      <fap-tree-combo></fap-tree-combo>
    </div> -->
    <div class="add_circle mr-2">
      <mat-form-field>
          <mat-label style="top: -2px; position: relative;">{{'activity.plans' | translate}}</mat-label>
          <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="formTypes" [placeholder]="'activity.plans' | translate" multiple (selectionChange)="filterActivitiesByPlan($event)">
              <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
              <mat-form-field appearance="fill" class="w-100">
                  <input matInput #plansFilter [placeholder]="'activity.plans' | translate" (keydown.enter)="$event.stopPropagation()">
              </mat-form-field>
              <mat-label style="position: absolute; right: 25px; cursor: pointer;">
                  <i class="fa fa-search f-20"></i>
              </mat-label>
              <!-- <div class="input-group-append pl-1">
                  <span class="input-group-text">
                      <i class="icofont icofont icofont-plus" (click)="addNewFormType(); mys.close();"></i>
                   </span>
              </div> -->
          </div>
          <div class="scroll_wrap">
              <mat-option style="height: 0!important;"></mat-option>
              <mat-option class="noteIcons" *ngFor="let plan of globalRegistry?.systemData?.plans| filter : plansFilter.value" [value]="plan.id">
                  <span class="mat-option-text">
                  <div class="name">{{ plan?.nameT ? (plan?.nameT | getTranslation : globalRegistry?.systemData?.translationsMap : langString) : plan?.name }}</div>
                  </span>
              </mat-option>
          </div>
          </mat-select>
      </mat-form-field>
  </div>
      <div class="add_btn mr-3" (click)="createNewActivity()">
        <fap-round-button [backgroundColor]="'tertiary'"></fap-round-button>
      </div>
      <fap-search-filter
        [isSearchEnabled]="true"
>     </fap-search-filter>
    </div>
</fap-header>
<div class="container-fluid ter-container nc"
>
<ng-container *ngIf="isLoading">
  <span class="loader">
      <i></i>
  </span>
</ng-container>
<ng-container *ngIf="!isLoading">
    <fap-activities-layout
         [farms]="filteredFarms"
         [activities]="filteredActivities"
         (deleteActivity)="deleteActivity($event)"
         [activityTypes]="activityTypes"
         [planTypes]="planTypes"
         [formTypes]="formTypes"
         [persons]="globalRegistry?.systemData?.persons"
         >
    </fap-activities-layout>
    <span class="not_found" *ngIf="filteredActivities.length === 0">No activities found</span>
</ng-container>
</div>
<fap-pagination
  [count]="count"
  [pageSize]="limit"
  (emitNextPrev)="handlePagination($event)"
></fap-pagination>

<fap-modal #addEditActivityModal [inputIsLarge]="true" [inputTitle]="'activity.addEditActivity' | translate">
  <fap-activities-add-edit-layout 
    [activity]="activity" 
    [activityTypes]="activityTypes"
    [equipments]="equipments"
    [planTypes]="planTypes"
    [activities]="activities"
    [formTypes]="formTypes"
    [farms]="globalRegistry?.systemData?.farms"
    [lots]="globalRegistry?.systemData?.lots"
    (closeModal)="addEditActivityModal.hideModal(); activity = null"
    (deleteActivity)="deleteActivity($event)"
    (submitActivity)="submitActivity($event)"
    (updateActivity)="updateActivity($event)"
    (baselineActivity)="assignBaselineActivity($event)"
    (triggerBaselineActivity)="setBaselineTrigger($event)"
  >
  </fap-activities-add-edit-layout>
</fap-modal>

<fap-modal #addEditBaselineActivityModal [inputIsLarge]="true" [inputTitle]="'activity.initialPlanning' | translate">
  <fap-activities-add-edit-layout 
    [activity]="baselineActivity"
    [baselineActivityId]="baselineActivityId" 
    [activityTypes]="activityTypes" 
    [equipments]="equipments"
    [planTypes]="planTypes"
    [activities]="activities"
    [isBaselineActivity]="true"
    [farms]="globalRegistry?.systemData?.farms"
    [lots]="globalRegistry?.systemData?.lots"
    (closeModal)="addEditBaselineActivityModal.hideModal(); baselineActivityId = null"
    (deleteBaselineActivity)="deleteBaselineActivity($event)"
    (submitBaselineActivity)="submitBaselineActivity($event)"
    (updateBaselineActivity)="updateBaselineActivity($event)"
  >
  </fap-activities-add-edit-layout>
</fap-modal>