import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { PrecisionParamsInterface } from '../../../../../core/interfaces/api/precision/precision-params.interface';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PrecisionAvailableDataInterface } from '../../../../../core/services/api/precision/data/precision-available-data.interface';
import { PrecisionGeopardConfigsInterface } from '../../../../../core/services/api/precision/data/precision-geopard-configs.interface';
import { PrecisionService } from '../../../../../core/services/api/precision/precision.service';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { GeopardImageFormatEnum } from '../../../../../shared/layout/fap_main-map/precision/data/geopard-image-format.enum';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './precision-container.component.html'
})
export class PrecisionContainerComponent implements OnInit, OnDestroy {

    public showCreateOrEditField = false;
    private farmId: number;
    private lotId: number;

    constructor(public mapService: MapService,
                private activatedRoute: ActivatedRoute,
                private globalRegistry: GlobalRegistryService,
                private precisionService: PrecisionService,
                private router: Router) {
        this.activatedRoute.queryParams
            .subscribe((queryParams: Params): void => {
                if (queryParams['farm'] !== undefined) {
                    this.farmId = parseInt(queryParams['farm'], 10);
                    this.initFarm();

                    if(queryParams['lot'] !== undefined) {
                        this.lotId = parseInt(queryParams['lot'], 10);
                        this.initLot();
                    }
                }
                else {
                    const params: Params = {
                        farm: this.globalRegistry.systemData.farms[0].id,
                        lot: this.getFirstLotByFarm(this.globalRegistry.systemData.farms[0].id)
                    };
                    this.router.navigate([], {
                        relativeTo: this.activatedRoute,
                        queryParams: params
                    });
                }
            });
    }

    public ngOnInit(): void {
        this.setupMap();
        this.precisionMapInit();
        this.mapService.centerMapOnUserLocation();
    }

    public ngOnDestroy(): void {
        this.mapService.resetMap();
        this.mapService.mapMenuOptions = [];
        this.globalRegistry.showPrecisionFilters = false;
    }

    private setupMap(): void {
        this.mapService.showMap(true);
        this.mapService.isEditMode = false;
    }

    private initFarm(): void {
        const filteredFarms: FarmModel[] = this.globalRegistry.systemData.farms.filter((farm: FarmModel) => farm.id === this.farmId);

        if(filteredFarms.length && filteredFarms[0]) {
            const farmPolygon: MapPolygonInterface = {
                points: MapHelper.convertToAGMPolygon(filteredFarms[0].coords.coordinates[0]),
                fillColor: '#ffffff00',
                strokeColor: '#ff0000',
                isEditable: false
            };
            this.mapService.resetMap();
            this.mapService.mapPolygons.push(farmPolygon);
            this.mapService.centerMapOnPolygons();
        }
    }

    private initLot(): void {
        const filteredLots: LotModel[] = this.globalRegistry.systemData.lots.filter((lot: LotModel) => lot.id === this.lotId);

        if(filteredLots.length && filteredLots[0]) {
            const lotPolygon: MapPolygonInterface = {
                points: MapHelper.convertToAGMPolygon(filteredLots[0].coords.coordinates[0]),
                strokeColor: '#ffff00',
                fillColor: '#ffffff00',
                data: { lot: true },
                isEditable: false
            };
            if (filteredLots[0].geopardId) {
                this.globalRegistry.showPrecisionFilters = true;
            }
            this.mapService.resetMap();
            this.mapService.mapPolygons.push(lotPolygon);
            this.mapService.centerMapOnPolygons();
        }
    }

    private precisionMapInit(): void {
        this.mapService.mapZoom.next(15);
        this.fetchPrecisionDates(this.lotId);

        combineLatest([this.globalRegistry.precisionType, this.globalRegistry.precisionDate])
        .subscribe(([type, date]: [string, string]): void => {
            if(date) {
                this.precisionService.getGeopardConfig(this.lotId, {time: date}).subscribe((response: PrecisionGeopardConfigsInterface) => {
                    this.mapService.removeGroundOverlay();
                    const layer: string = response[Object.keys(response)[0]].layers[0];
                    this.mapService.mapSpinnerService.show();
                    setTimeout(() => this.mapService.mapSpinnerService.hide(), 800);
                    const params: PrecisionParamsInterface = {
                        styles: type,
                        layers: layer,

                        /**
                         * @todo depends of layer type
                         */
                        map_format: GeopardImageFormatEnum.LAYER,
                        time: date,
                        width: 256,
                        height: 256
                    };
                    this.mapService.addGroundOverlay(params, this.precisionService, this.lotId);
                });

            }
        });

        this.globalRegistry.precisionDatesList.subscribe((lotId: number) => {
            this.fetchPrecisionDates(lotId);
        });
    }

    private getFirstLotByFarm(farmId: number): number {
        return this.globalRegistry.systemData.lots.filter((lot: LotModel): boolean => {
            return lot.farm === farmId;
        })[0].id;
    }

    private fetchPrecisionDates(lotId: number): void {
        this.precisionService.getAvailableData(lotId).subscribe((response: PrecisionAvailableDataInterface): void => {
            this.globalRegistry.systemData.datesList = response.available_dates
                .filter((date: string): boolean => date !== null && date !== undefined);
            this.globalRegistry.selectedDate = this.globalRegistry.systemData.datesList[0];

            if(this.globalRegistry.selectedDate) {
                this.globalRegistry.precisionDate.emit(this.globalRegistry.selectedDate);
            }
        });
    }

}
