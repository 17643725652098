<div *ngIf="equipment" class="equipment-card" [routerLink]="'/pages/equipments/edit/' + equipment.id">
    <div class="vertical-label {{ equipment.attachable ? 'attached':''}}"
    ><span *ngIf="equipment?.parked">
        {{'equipment.parked' | translate}}
    </span></div>
    <div class="card-image valign-wrapper person-card-image user_im img_place_bg">
        
            <img *ngIf="equipment?.product?.images.length" [src]="mediaUrl + equipment?.product?.images[equipment?.product?.images.length - 1].file">
        
        <!-- <i [routerLink]="'/pages/equipments/edit/' + equipment.id" class="fa fa-pencil ter-btn-icon ter-btn-icon-success edit-equipment"></i> -->
        <!-- <i class="material-icons ter-btn-icon ter-btn-icon-danger delete-equipment" (click)="deleteEquipment.emit(equipment.id)">delete_outline</i>  -->
        <img src="../../../../../../assets/images/landscape_placeholder.png" style="padding: 15px;" alt="" *ngIf="equipment?.product.images.length == 0">
    </div>
    <div class="card-stacked card-stacked-sm">
        <div class="card-content">
            <div class="card-cell-name">
                 
                    <span class="card-title pt-4 mb-3"
                        [routerLink]="'/pages/equipments/edit/' + equipment.id"
                        [queryParams]="{ preview: true }">
                        <h5>{{ equipment?.product?.label?.name }}</h5>
                    </span>
                    <span class="card-text">
                        <b>{{ equipment.registrationPlate }}</b>
                        <span style="display: block;">{{ equipment.manufactureSerial }}</span>
                    </span>
                
            </div>
            <div class="body d-flex">
                <div class="infos pl-3">
                    <p class="mb-0 select-option-time">
                        <img src="../../../../../../assets/images/clock.png" alt=""> {{ calcTime(equipment?.lastActivity?.workingTime) }} min</p>
                        <p class="mb-0 select-option-time"><img
                            src="../../../../../../assets/images/distance.png" alt=""/> {{ equipment?.lastActivity?.distance }} km</p>
                    <p class="mb-0 select-option-time">
                        <img src="../../../../../../assets/images/area.png" alt=""/> {{ equipment?.lastActivity?.area }} ha</p>
                </div>
                <ng-container *ngIf="equipment?.lastActivity?.attached_equipments">
                <ng-container *ngFor="let eq of equipment?.lastActivity?.attached_equipments">
                    <div class="main_eq d-flex" *ngIf="eq.attachable == 0">
                        <div class="p-0 activitycard-implementinfo used">
                            {{ eq?.registration_plate }}
                        </div>
                        <div class="p-0 user_im img_place_bg lands">
                            
                                <ng-container *ngIf="eq?.product?.images.length">
                                    <img [src]="mediaUrl + eq?.product.images[eq?.product.images.length - 1].file" alt="" [attr.class]="image?.data?.orientation">
                                </ng-container>
                            
                            <img style="padding: 15px;" *ngIf="eq?.product.images.length == 0" src="../../../../../../assets/images/landscape_placeholder.png" alt="" />
                        </div>
                    </div>
                </ng-container>
                </ng-container>
                <div class="main_infos">
                    <div class="title_row">
                        <div class="edit_link p-0 mb-0"><h5>{{ equipment?.lastActivity?.name }}</h5>
                        </div>
                    </div>
                    <div class="sub_contents">
                        <div class="c1">
                            <span *ngIf="equipment?.lastActivity?.plan">
                                <p class="m-0">
                                    <ng-container *ngFor="let plan of globalRegistryService?.systemData?.plans">
                                        <ng-container *ngIf="plan?.id === equipment?.lastActivity?.plan">
                                            <i *ngIf="getTranslation(plan?.nameT) !== ''">{{getTranslation(plan?.nameT)}}</i>
                                        </ng-container>
                                    </ng-container>
                                </p>
                              </span>
                        </div>
                        <div class="c2">
                            <div class="p-0 mb-0 act_date">
                                <p class="select-option-date">
                                    {{ equipment?.lastActivity?.started | date: "MMM dd yyyy, HH:mm" }}
                                </p>
                            </div>
                            <!-- <div class="pl-2  act_date">
                                <p class="select-option-date">
                                    {{ equipment?.lastActivity?.ended | date: "MMM dd yyyy, HH:mm" }}
                                </p>
                            </div> -->
                        </div>
                        </div>
                </div>
                <div class="ppl" *ngIf="equipment?.lastActivity?.assignedTo">
                    <ng-container *ngFor="let person of globalRegistryServiceService.systemData.persons">
                        
                        <div class="user user_im img_place_bg assigned" *ngIf="person.id === equipment?.lastActivity.assignedTo">
                            <img
                                src="../../../../../../assets/images/people_placeholder.svg"
                                alt="assigned to"
                                *ngIf="person.picture == null"
                            />
                            <img
                                [src]="mediaUrl + person.picture"
                                alt="assigned to"
                                *ngIf="person.picture != null"
                            />
                        </div>
                    
                    </ng-container>
                    <div class="user user_im img_place_bg" *ngIf="equipment?.lastActivity.assignedTo == null">
                        <img
                            src="../../../../../../assets/images/people_placeholder.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
