<div class="row" *ngFor='let farm of farms'>
    <div class="col-md-12">
        <div class="breadcrumb breadcrumb-colored m-b-30">
                <span class="breadcrumb-item f-20 pointer p-r-5">{{farm}}</span>
                <i class="material-icons pointer" routerLink="/pages/farm/edit">edit</i>
                <i class="material-icons pointer" routerLink="edit">add</i>
        </div>
    </div>
    <div class="col-md-12" *ngFor='let weed of weeds'>
        <div class="card ribbon-vertical-left-wrapper" routerLink="edit">
            <div class="card-body row">
                <div class="ribbon ribbon-vertical-left"></div>
                <div class="col-md-2">
                    <img src="senpai.png"/>
                </div>
                <div class="col-md-3">
                        <span class="f-w-900 f-18 d-flex">Bethausen</span>
                        <span class="d-flex">Bethausen</span>
                </div>
                <div class="col-md-3">
                    <span class="f-w-900 f-18 d-flex">Bethausen</span>
                    <span class="d-flex">Bethausen</span>
                </div>
                <div class="col-md-3">
                    <span class="f-w-900 f-18 d-flex">Bethausen</span>
                    <span class="d-flex">Bethausen</span>
                </div>
            </div>
        </div>
    </div>
</div>
