import { Subscription } from 'rxjs';
import { DiseaseInterface } from '../../../../core/interfaces/crop/disease.interface';
import { FapUploadImageComponent } from '../../../../shared/partials/components/upload/image-upload/fap-upload-image.component';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChild,
    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { DiseaseModel } from '../../../../core/models/crops/disease.model';

@Component({
    selector: 'fap-edit',
    templateUrl: './fap-edit.component.html',
    styleUrls: ['./fap-edit.component.scss']
})
export class FapEditComponent implements OnChanges, OnDestroy {

    @Input()
    public context: string;

    @Input()
    public diseaseData: DiseaseModel;

    @Input()
    public showSubmit = true;

    @Output()
    public submitEvent: EventEmitter<DiseaseInterface> = new EventEmitter();

    @Output()
    public formChanged: EventEmitter<DiseaseInterface> = new EventEmitter();

    @ViewChild('diseaseImageUpload')
    public diseaseImageUpload: FapUploadImageComponent;

    public testArray: Array<any> = [1, 2, 3, 4, 5];

    public form: UntypedFormGroup;
    public diseaseImage: File;

    private subscriptions: Array<Subscription> = [];

    constructor(private modalService: NgbModal,
                private formBuilder: UntypedFormBuilder) {
        this.initForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'formData') && this.diseaseData) {
            this.diseaseImageUpload.url = this.diseaseData.image;
            this.initForm();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }

    public openLg(content: any): void {
        this.modalService.open(content, { size: 'lg' });
    }

    public imageUploaded(file: File): void {
        this.diseaseImage = file;
    }

    public submitForm(): void {
        this.submitEvent.emit(this.getNewDiseaseData());
    }

    public formFieldChanged(): void {
        this.formChanged.emit(this.getNewDiseaseData(true));
    }

    public resetForm(): void {
        this.diseaseImage = null;
        this.diseaseImageUpload.remove();
        this.diseaseImageUpload.url = this.diseaseData ? this.diseaseData.image : null;
        this.initForm();
    }

    private initForm(): void {
        this.form = this.formBuilder.group({
            identification: [this.diseaseData ? this.diseaseData.identification : '', Validators.required],
            kingdom: [this.diseaseData ? this.diseaseData.kingdom : null],
            subkingdom: [this.diseaseData ? this.diseaseData.subkingdom : null],
            group: [this.diseaseData ? this.diseaseData.group : null],
            symbol: [this.diseaseData ? this.diseaseData.symbol : null],
            class: [this.diseaseData ? this.diseaseData.class : null],
            subclass: [this.diseaseData ? this.diseaseData.subclass : null],
            superdivision: [this.diseaseData ? this.diseaseData.superdivision : null],
            division: [this.diseaseData ? this.diseaseData.division : null],
            order: [this.diseaseData ? this.diseaseData.order : null],
            family: [this.diseaseData ? this.diseaseData.family : null],
            genus: [this.diseaseData ? this.diseaseData.genus : null],
            species: [this.diseaseData ? this.diseaseData.species : null],
            subspecies: [this.diseaseData ? this.diseaseData.subspecies : null],
            duration: [this.diseaseData ? this.diseaseData.duration : null]
        });
        this.form.valueChanges.subscribe((): void => {
            this.formFieldChanged();
        });
    }

    private getNewDiseaseData(includeUntouched = false): DiseaseInterface {
        const newDiseaseData: DiseaseInterface = {};
        Object.keys(this.form.controls).forEach((key: string): void => {
            if (!includeUntouched && this.form.get(key).pristine) {
                return; // if the input was untouched, don't push it into the form
            } else {
                newDiseaseData[key] = this.form.value[key] !== null ? this.form.value[key] : '';
            }
        });
        newDiseaseData.image = this.diseaseImage;
        return newDiseaseData;
    }

}
