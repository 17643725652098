import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChanges,
    OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ResponseModel } from 'src/app/core/models/response.model';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { PartyService } from 'src/app/core/services/api/company/party.service';
import { AttributeEntityEnum } from '../../../../core/enums/attribute/attribute-entity.enum';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { FieldInterface } from '../../../../core/interfaces/field/field.interface';
import { PartyModel } from '../../../../core/models/party/party.model';
import { UnitValueModel } from '../../../../core/models/units/unit-value.model';
import { FieldService } from '../../../../core/services/api/farm/field.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { NavService } from '../../../../shared/services/nav.service';
import { AttributeService } from '../../../../core/services/api/attribute/attribute.service';

@Component({
    selector: 'fap-add-edit-field',
    templateUrl: './fap_add-edit-field.component.html',
    styleUrls: ['./fap_add-edit-field.component.scss']
})
export class FapAddEditFieldComponent implements OnInit, OnChanges, OnDestroy{

    @Input()
    public fieldId = null;

    @Input()
    public isSubmitDisabled = false;

    @Input()
    public fieldName = '';

    @Input()
    public soilType = '';

    @Input()
    public area: UnitValueModel;

    @Input()
    public owner: number = null;

    public parties = [];
    public status;

    @Output()
    public submitField: EventEmitter<FieldInterface> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    public selectedParty: PartyModel;
    public entityRelation = AttributeEntityEnum.Field;
    public getMoreParties = true;
    private subscriptions: Array<Subscription> = [];
    public limit = 20;
    public searchKeyword = '';
    public partyToken: { limit: number; offset: number; search: string; } = null;
    public selectedPartyId: number;
    

    constructor(private activatedRoute: ActivatedRoute, private router: Router, public fieldService: FieldService,
        public navService: NavService, private toastyService: ToastrService, public globalRegistry: GlobalRegistryService,
        private translateService: TranslateService, public partyService: PartyService, public cacheService: CacheResolverService, public typesService: TypesService, public attributeService: AttributeService) {}

    ngOnInit(): void {
        this.navService.createMod.next(true);
        this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
            if (value) {
                this.submit();
            }
        }));

        this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
               value && this.deleteCurrentField();
        }));

        this.subscriptions.push(this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/fields']);
                this.navService.emitCancel.next(false);
            }
        }));

        
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'owner') && this.owner) {
            this.selectedPartyId = this.owner
            this.checkDefaultPartyExistence();
        }
    }

    filterParties(value) {
      console.log(value);
      this.subscriptions.push(this.partyService.getParties({search: value}).subscribe(data => {
        console.log(data);
        if(data.body.next) {
          this.getMoreParties = true;
        }
        this.partyToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        this.parties = data.body.results;
        if(this.partyToken) this.getMoreParties = true
      }))
    }

    scrollParties() {
        const url = this.partyService.getUrl('');
        console.log('parties scrolled');
        if(this.getMoreParties) {
        this.partyToken &&
        this.subscriptions.push(this.partyService.getParties(this.partyToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.limit+'&offset='+this.partyToken.offset+'&search='+this.partyToken.search);
            console.log(data);
            if(this.parties) {
              this.parties = [...this.parties, ...data.body.results];
            } else {
              this.parties = data.body.results;
            }
            if(data.body.next == null) {
              this.getMoreParties = false;
              return
            } else {
              const url = data.body.next.split('?')
              const urlParams = new URLSearchParams(url[1]);
              const entries = urlParams.entries();
              const params = this.paramsToObject(entries);
              console.log(params);
              if(this.partyToken.offset != params['offset']) {
              this.partyToken = {limit: params['limit'], offset: params['offset'], search: params['search']};
              } else {
                return
              }
            }
          }));
        } else {
          return
        }
      }

      paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }

    public getParties() {
        const url = this.partyService.getUrl('');
        this.cacheService.delete(url+'limit='+this.limit+'&search='+this.searchKeyword);
        this.subscriptions.push(this.partyService.getParties({ limit: this.limit, search: this.searchKeyword }).subscribe((data: ResponseModel<PartyModel[]>): void => {
          console.log(data);
          this.parties = data.body.results;
          this.partyToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.partyToken) this.getMoreParties = true;
                // this.checkDefaultPartyExistence();
        }))
    }

    public checkDefaultPartyExistence() {
        const INpartyFromPresence = this.parties.some(p => p.id == this.selectedPartyId)
        if(!INpartyFromPresence) {
          this.subscriptions.push(this.partyService.getParty(this.selectedPartyId).subscribe(data => {
            this.parties.unshift(data.body.results);
          }))
        }
    }

    public ngOnDestroy():void {
        this.subscriptions.forEach(s=>s.unsubscribe());
    }

    public submit() {
        console.log(this.fieldId);
        if(!this.area) {
            this.toastyService.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
        }
        this.submitField.emit({name: this.fieldName, soilType: this.soilType, area: { id: this.area.id, unit: this.area.unit, value: this.area.value}, owner: this.owner});
        this.globalRegistry.addLocalRelationsToService.next(this.fieldId);
    }

    public deleteCurrentField() {
        const id = this.fieldId;
        if(this.fieldId) {
        this.fieldService.deleteField(id).subscribe(data => {
            console.log(data);
            this.globalRegistry.addLocalRelationsToService.next(this.fieldId);
            this.toastyService.success(this.translateService.instant('farm.field.fieldDeletedSuccessfully'));
            this.router.navigate(['/pages/fields']);
        })
    }
    }

    public onDeleteParty(party: PartyModel): void {
        if (party.id === this.owner) {
            this.owner = null;
        }
        this.deleteParty.emit(party.id);
    }

}
