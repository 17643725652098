import { Routes } from '@angular/router';
import { PathogensEditContainerComponent } from './containers/pathogens-edit-container/pathogens-edit-container.component';
import { PathogensContainerComponent } from './containers/pathogens-container/pathogens-container.component';

export const pathogensRoutes: Routes = [
    {
        path:       '',
        component:  PathogensContainerComponent
    },
    {
        path:       'edit',
        component:  PathogensEditContainerComponent
    }
];
