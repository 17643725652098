import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SharedModule } from '../../../shared/shared.module';
import { HomeComponent } from './home.component';
import { HomeLayoutComponent } from './components/home-layout/fap-home-layout.component';
import { HomeContainerComponent } from './containers/home-container/home-container.component';
import { PartialsModule } from '../../views-partials/partials.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule,
        DragDropModule

    ],
    declarations: [
        HomeComponent,
        HomeContainerComponent,
        HomeLayoutComponent
    ]
})

export class HomeModule {

}
