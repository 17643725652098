import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'fap-add-edit-disease',
  templateUrl: './fap-add-edit-disease.component.html',
  styleUrls: ['./fap-add-edit-disease.component.scss']
})
export class FapAddEditDiseaseComponent implements OnInit, OnDestroy {

  public subscriptions = new Subscription();

  constructor(
    public mapservice: MapService,
    public navService: NavService,
    public router: Router
    ) { }

  ngOnInit(): void {
    this.mapservice.showMap();
    this.navService.editFarm.next(true);
    this.navService.submitFarm.next(true);
    this.navService.invalidForm.next(true);
    this.navService.createMod.next(true);
    this.subscriptions.add(
      this.navService.emitSubmit.subscribe((value: boolean) => {
          value && this.submitForm();
      })
  );
  this.subscriptions.add(
      this.navService.emitDelete.subscribe((value: boolean) => {
          value && this.delete();
      })
  );
  this.subscriptions.add(
    this.navService.emitCancel.subscribe((value: boolean) => {
      if (value) {
        this.router.navigate(["/pages/diseases"]);
        this.navService.emitCancel.next(false);
      }
    })
  )
  }
  
  public submitForm() {
    console.log('submit triggered')
  }

  public delete() {
    console.log('delete triggered')
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.navService.editFarm.next(false);
    this.navService.submitFarm.next(false);
    this.navService.invalidForm.next(false);
    this.navService.createMod.next(false);
  }

}
