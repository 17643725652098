import { Component, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapAddEditPartyComponent } from '../../../../views-partials/fap_view-components/fap_add-edit-party/fap_add-edit-party.component';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fap-party-table',
    templateUrl: './fap_party-table.component.html',
    styleUrls: ['./fap_party-table.component.scss']
})
export class FapPartyTableComponent {

    @Input()
    public parties: Array<PartyModel> = [];

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();


    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;

    @ViewChild('addEditParty')
    public addEditParty: FapAddEditPartyComponent;

    public displayedColumns: string[] = ['name', 'email', 'website', 'payer', 'actions'];
    public selectedParty: PartyModel;

    constructor(private confirmModalService: ConfirmModalService,
                private translateService: TranslateService) {
    }

    public editParty(party: PartyModel): void {
        this.selectedParty = party;
        this.addEditPartyModal.showModal();
    }

    public addPartyModal(): void {
        this.selectedParty = null;
        this.addEditParty.resetForm();
        this.addEditPartyModal.showModal();
    }

    public addedOrEditedParty(): void {
        this.addEditPartyModal.hideModal();
        this.addEditParty.resetForm();
    }

    public onDeleteParty(partyId: number): void {
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('party.deleteParty'),
            this.translateService.instant('party.messages.deletePartyMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                this.deleteParty.emit(partyId);
            }
        });
    }
}
