<div class="container-fluid">
    <scheduler-edit
             [lots]="lots"
             [farms]="farms"
             [sensorId]="sensorId"
             (submitSensor)="submitSensor($event)"
             (updateSensor)="updateSensor($event)"
             (deleteSensor)="deleteSensor($event)"
             >
    </scheduler-edit>
</div>
