import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AllergyProfileComponent } from './allergy-profile.component';
import { SharedModule } from '../../../../shared/shared.module';
import { PartialsModule } from '../../../views-partials/partials.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        PartialsModule
    ],
    declarations: [
        AllergyProfileComponent
    ]
})

export class AllergyProfileModule {

}
