import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { CacheResolverService } from '../cache/cache-resolver.service';

@Injectable()
export class BillingService extends FapRestBaseService {
    constructor(tosterService: ToastrService, private _http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, _http, '/billing/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getAssets(): Observable<ResponseModel<any[]>> {
        this.cacheService.delete(this.url+'assets/')
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'assets/',
                FapAPIRequestOptions.withTokenRequestOptions),
            false
        ).pipe(take(1));
    }

    public getListedProducts(params?:any): Observable<ResponseModel<any[]>> {
        return this.mapRequest<[]>(
            this._http.get(
                this.url + 'offers/', {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public buyProduct(params): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'offers/buy/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                false
        ).pipe(take(1));
    }

    public getAnafAccess(params): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.get(this.url + 'anaf/access/?token='+params.token,
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                false
        ).pipe(take(1));
    }
}
