import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {
  private scrollPosition: Record<string, number> = {};

  setScrollPosition(position: number, relation: string) {
    this.scrollPosition[relation] = position;
  }

  getScrollPosition(relation: string): number {
    return this.scrollPosition[relation] || 0;
  }
}
