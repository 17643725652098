import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Add a second';
  downSecondLabel = 'Minus a second';
  upMinuteLabel = 'Add a minute';
  downMinuteLabel = 'Minus a minute';
  upHourLabel = 'Add a hour';
  downHourLabel = 'Minus a hour';
  prevMonthLabel = 'Previous month';
  nextMonthLabel = 'Next month';
  prevYearLabel = 'Previous year';
  nextYearLabel = 'Next year';
  switchToMonthViewLabel = 'Change to month view';
  switchToMultiYearViewLabel = 'Choose month and year';
  cancelBtnLabel = 'Cancel';
  setBtnLabel = 'Set';
  rangeFromLabel = 'From';
  rangeToLabel = 'To';

  dateLabel = {year: 'numeric', month: 'short', day: 'numeric'};
  monthYearLabel = {year: 'numeric', month: 'short'};
  timeLabel = {hour: 'numeric', minute: '2-digit', hour12: false};
  dateTimeLabel = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: false};
  monthYearA11yLabel = {year: 'numeric', month: 'long'};
  dateA11yLabel = {year: 'numeric', month: 'long', day: 'numeric'};
  dateTimeA11yLabel = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: false};
}
