import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'fap-activity-card',
    templateUrl: './fap_activity-card.component.html',
    styleUrls: ['./fap_activity-card.component.scss']
})
export class FapActivityCardComponent {

    @Input()
    public activityName: string;

    @Input()
    public activityInfo: string;

    @Input()
    public activityId: number;

    @Input()
    public activityCreatedBy: number;

    @Input()
    public activityWorkingTime: string;

    @Input()
    public activityStarted: string;

    @Input()
    public activityEnded: string;

    @Input()
    public activityType: number;

    @Output()
    public deleteActivity: EventEmitter<void> = new EventEmitter();

    @Output()
    public editActivity: EventEmitter<void> = new EventEmitter();
}
