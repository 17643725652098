import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { CropTypeIngredientService } from '../../../../core/services/api/crop/crop-type-ingredient.service';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import {
    Component,
    ViewChild,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output,
    OnInit,
    ElementRef
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials/components/fap-modal/fap-modal.component';
import { CropTypeModel } from '../../../../core/models/type/crop-type.model';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { CropTypeInterface } from '../../../../core/services/api/types/data/crop-type.interface';
import { NameValueInterface } from '../../../../core/interfaces/general/name-value.interface';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
    selector: 'fap-add-edit-crop-type',
    templateUrl: './fap_add-edit-crop-type.component.html',
    styleUrls: ['./fap_add-edit-crop-type.component.scss']
})
export class FapAddEditCropTypeComponent implements OnChanges, OnInit {

    @ViewChild('addEditIngredientModal')
    public addEditIngredientModal: FapModalComponent;

    @Input()
    public cropType: CropTypeModel;

    @Input()
    public farmId?: number;

    @Output()
    public addCropType: EventEmitter<CropTypeInterface> = new EventEmitter();

    @Output()
    public updateCropType: EventEmitter<CropTypeInterface> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

    public cropTypeForm: UntypedFormGroup;
    public showIngredientsTable = false;
    public ingredients: Array<IngredientModel> = [];
    public isEdit = false;
    public subscription: Subscription = new Subscription();
    public translatedNames:any;
    public translation = null;
    public prefix = 'CROP_';
    @ViewChild('mySelect') mySelect;
    public langString: string;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public label: string;

    constructor(private formBuilder: UntypedFormBuilder,
                public globalRegistry: GlobalRegistryService,
                private cropTypeIngredientService: CropTypeIngredientService,
                private typesService: TypesService,
                private toastr: ToastrService,
                private translateService: TranslateService,
                private navService: NavService
                ) {
                    this.translatedNames = this.globalRegistry.systemData.translations;
        this.initCropTypeForm();
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'cropType'))
        {
            this.initCropTypeForm(this.cropType);
        }
    }

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }

    addEditTranslation(label) {
    this.label = label;
    const name = this.nameInput.nativeElement.value;
    const type: any = this.convertKeysToCamelCase(this.cropType);
    if(name === null || name === '' || type.nameT === null) {
        this.translation = null
        this.addEditTranslationsModal.showModal();
    } else {
        this.globalRegistry.systemData.translations.forEach(translation => {
            if(translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
            }
        });
    }
    }

    onAddUpdateTranslation(translation) {
        console.log(translation);
        this.translation = translation;
        this.cropTypeForm.controls['name_t'].setValue(translation.id);
        this.cropTypeForm.controls['name'].setValue(translation[this.langString]);
        this.addEditTranslationsModal.hideModal();
    }

    public addIngredient(): void {
        this.addEditIngredientModal.showModal();
    }

    public deleteIngredient(ingredientId: number): void {
        this.cropTypeIngredientService.deleteIngredient(ingredientId).pipe(take(1)).subscribe((): void => {
            this.cropType.ingredients = this.cropType.ingredients.filter((ingredient: IngredientModel): boolean => ingredient.id !== ingredientId);
            this.ingredients = this.cropType.ingredients;
            this.updateCropTypeTable();
            this.globalRegistry.reloadCropTypes();
        });
    }

    public submit(): void {
        if(this.cropTypeForm.invalid) {
            Object.keys(this.cropTypeForm.controls).forEach((controlName: string): void =>
                this.cropTypeForm.controls[controlName].markAsTouched()
            );
        }
        const cropTypeIngredients: number[] = this.ingredients.map((ingredient: IngredientModel): number => ingredient.id);
        const body: CropTypeInterface = this.cropType ?
        {
            id: this.cropType.id,
            name: this.cropTypeForm.controls['name'].value,
            name_t: this.cropTypeForm.controls['name_t'].value,
            color: this.cropTypeForm.controls['color'].value,
            ingredients: cropTypeIngredients
        } :
        {
            name: this.cropTypeForm.controls['name'].value,
            name_t: this.cropTypeForm.controls['name_t'].value,
            color: this.cropTypeForm.controls['color'].value,
            ingredients: cropTypeIngredients
        };

        this.cropType ? this.updateCropType.emit(body) : this.addCropType.emit(body);
        this.globalRegistry.reloadCropTypes();
    }

    public updatedPathogensTable(pathogens: NameValueInterface[]): void {
        // TODO: handle data update here
        console.log(pathogens);
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.cropTypeForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public updatedIngredient(ingredient: IngredientModel): void {
        Object.assign(this.ingredients.find((ingr: IngredientModel): boolean => ingr.id === ingredient.id), ingredient);
        this.updateCropTypeTable();
    }

    public createdIngredient(ingredient: IngredientModel): void {
        this.ingredients.push(ingredient);
        this.updateCropTypeTable();
    }

    private updateCropTypeTable(): void {
        const cropTypeIngredients: number[] = this.ingredients.map((ingr: IngredientModel): number => ingr.id);
        this.typesService.updateCropType({ id: this.cropType.id, ingredients: cropTypeIngredients }).pipe(take(1)).subscribe((): void => {
            this.toastr.success(this.translateService.instant('farm.crop.cropTypeIngredientsUpdatedSuccessfully'));
        });
        this.globalRegistry.reloadCropTypes();
    }

    private initCropTypeForm(cropType?: CropTypeModel): void {
        this.ingredients = [];
        if(!cropType){
            this.showIngredientsTable = false;
        }
        else {
            this.ingredients = cropType.ingredients;
            this.showIngredientsTable = true;
        }
        this.cropTypeForm = this.formBuilder.group({
                name: [cropType ? cropType.name : '', Validators.compose([
                    Validators.required,
                ])],
                name_t: [cropType ? cropType.nameT : ''],
                color: [cropType ? cropType.color : '']
        });
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
              return translation
          }
      }

}
