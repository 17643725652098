import {Pipe,PipeTransform} from "@angular/core";

@Pipe({
    pure:false,
    name:"getLotNames"
})

export class getLotNamesPipe implements PipeTransform{

    public transform([lots,  lotIds]){
        
        if(lotIds.length) {   
            const names = [];
            const maxToShow = 1;
            let additionalCount = 0;
            
            lotIds.forEach(id => {
                lots.forEach(lot => {
                if (lot.id === id) {
                    if (names.length < maxToShow) {
                    names.push(' ' + lot.name);
                    } else {
                    additionalCount++;
                    }
                }
                });
            });
            
            if (additionalCount > 0) {
                return `${names.slice(0, maxToShow).toString()}   +${additionalCount} more`;
            } else {
                return names.toString();
            }
          } else {
            return '';
          }
    }

}