import { Routes } from '@angular/router';
import { NotFoundComponent } from './views/pages/not-found/not-found.component';

export const ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'pages',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];
