<form class="theme-form row" [formGroup]="resourceTypeForm" id="parent">
    <div class="form-group col-md-12">
        <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
            <input matInput placeholder="Name" formControlName="name" readonly #nameInput> 
            <mat-error *ngIf="ifControlHasError('name','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group col-md-12" *ngIf="resourceTypes">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label"><i class="fa fa-home"></i>{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentResource && selectedResource">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #resourceFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <mat-option *ngFor="let parent of resourceTypes | filter : resourceFilter.value" [value]="parent.id">
                    {{ parent.name_t ? getTranslation(parent?.name_t) : parent.name }}
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifControlHasError('parent','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field> 
    </div>
    <div class="col-md-12">
        <div class="text-center">
            <i class="material-icons">format_color_fill</i>
            <div class="color-input ml-2">    
                <div>Color</div>
                <input class="w-100" type="color" formControlName="color">
                <mat-error *ngIf="ifControlHasError('color','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </div>
        </div>
    </div>
    <div class="col-12 mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="button" (click)="submit()">{{'translations.save' | translate}}</button>
        <button class="btn" type="button" (click)="cancel.emit()">{{'translations.cancel' | translate}}</button>
    </div>
</form>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.selectedResource)" 
        [prefix]="prefix" 
        [translation]="translation"
        [label]="label"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>
