
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h5>
                    Treatments
                    <i class="material-icons f-right" (click)="openModal()">add</i>
                </h5>
            </div>
            <div *ngFor="let treatment of treatments" class="card-body px-3 py-2">
                <div class="row body" *ngIf="treatment">
                    <div class="col-sm-3 px-1 center">
                        <p>
                            {{treatment.product}}
                        </p>
                    </div>
                    <div class="col-sm-3 px-1 center">
                        <i class="material-icons">
                            rotate_left
                        </i>
                            {{treatment.type}}
                    </div>
                    <div class="col-sm-2 px-1 center">
                        <div class="row">
                            <div class="col-md-12">
                                min:
                                <span>
                                    {{treatment.min}}l
                                </span> 
                            </div>
                            <div class="col-md-12">
                                max:
                                <span>
                                    {{treatment.max}}l
                                </span> 
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1 px-1 center">
                        {{treatment.surface}} ha
                    </div>
                    <div class="col-sm-1 px-1 center">
                        <i class="icofont icofont-water-drop"></i>{{treatment.dilution}}l
                    </div>
                    <div class="col-sm-2 px-1 center f-right">
                        <i class="fa fa-pencil f-20 p-l-5" (click)="openModal()"></i>
                        <i class="fa fa-trash-o f-20 p-l-5" (click)="deleteTreatment(treatment.id)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<fap-modal #addTreatment
                [inputTitle]="'title dsaf adsf asd'"
                [inputPrimaryButtonInterface]="addTeratmentButtonPrimaryInterface"
                [inputSecondaryButtonInterface]="addTreatmentButtonSecondaryInterface"
                [inputIsExtraLarge]="true">
                <div class="form-group">
                    <div class="row">
                        <div *ngIf="products" class="col-md-4">
                            <div class="input-group">
                                <mat-form-field>
                                    <mat-label>Product</mat-label>
                                    <mat-select>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <!-- <mat-label>
                                                <i class="fa fa-search f-20"></i>
                                            </mat-label> -->
                                            <input matInput #productFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let product of products | filter : productFilter.value" [value]="product">
                                            {{product}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="input-group-append">
                                    <span class="input-group-text f-20"><i
                                        class="icofont icofont icofont-plus" ></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <mat-form-field>
                                    <mat-label>Type</mat-label>
                                    <mat-select>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <!-- <mat-label>
                                                <i class="fa fa-search f-20"></i>
                                            </mat-label> -->
                                            <input matInput #typeFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let type of types | filter : typeFilter.value" [value]="type">
                                            {{type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="input-group-append">
                                    <span class="input-group-text f-20"><i
                                        class="icofont icofont icofont-plus" ></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <mat-form-field>
                                    <mat-label>Aplicability</mat-label>
                                    <mat-select>
                                        <mat-form-field appearance="fill" class="w-100">
                                            <!-- <mat-label>
                                                <i class="fa fa-search f-20"></i>
                                            </mat-label> -->
                                            <input matInput #aplicabilityFilter placeholder="Search">
                                        </mat-form-field>
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let aplicability of aplicabilities | filter : aplicabilityFilter.value" [value]="aplicability">
                                            {{aplicability}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="input-group-append">
                                    <span class="input-group-text f-20">
                                        <i class="material-icons">
                                            rotate_right
                                        </i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput placeholder="Min.(L)">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput placeholder="Max.(L)">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput placeholder="Surface(ha)">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field>
                                <input matInput placeholder="Dilution(L)">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
</fap-modal>
