<div class="page-wrapper" *ngIf="data$ | async as data" id="parent">
    <div class="auth-bg">
      <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
        <div class="authentication-box">
          <div class="card mt-4 p-4" *ngIf="!showConfirmModal && !hideForm">
            <h4 class="text-center">{{ 'user.accountDelete' | translate }}</h4>
            <!-- <h6 class="text-center">{{ 'user.fiedsRequired' | translate }}</h6> -->
            <form class="theme-form" [formGroup]="deleteForm">
              <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{'user.registrationEmail' | translate}}</mat-label>
                    <input matInput formControlName="email" style="width: 100%;">
                    <mat-error *ngIf="ifControlHasError('email','email')">
                      <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="ifControlHasError('email','required')">
                      <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="ifControlHasError('email','minlength')">
                        <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="ifControlHasError('email','maxlength')">
                        <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
              </div>
              <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'user.reason' | translate }}</mat-label>
                    <mat-select (selectionChange)="valueChange($event)">
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let option of options" [value]="option">{{option.text}}</mat-option>
                    </div>
                </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group" *ngIf="selectedOption === 100">
                    <div class="w-100">
                        <mat-form-field class="w-100">
                            <mat-label>{{"notes.info" | translate}}</mat-label>
                            <textarea matInput formControlName="info" name="info" cols="30" rows="10" class="text-background p-2"></textarea>
                        </mat-form-field>
                    </div>
              </div>
            <div class="form-row">
                <div class="col-sm-12">
                    <button class="btn btn-success" type="button" (click)="confirmDelete()">{{ 'user.accountDelete' | translate }}</button>
                </div> 
            </div>
            </form>
          </div>

          <div class="card mt-4 p-4" *ngIf="showConfirmModal">
            <h4 class="text-center">{{ 'user.accountDelete' | translate }}</h4>
            <h6 class="text-center">{{ 'user.yourDataWillBeDeleted' | translate }}</h6>
            <div class="buttons">
                <button class="btn btn-success" type="button" (click)="deleteUser()">{{ 'yes' | translate }}</button>
                <button class="btn btn-danger" type="button" (click)="cancel()">{{ 'no' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="custom_modal" id="custom_modal">
    <div class="modal_header">
        <h2>{{'user.accountDelete' | translate}}</h2>
        <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
    </div>
    <div class="modal_body">
        <h6>{{'auth.register.mailSent' | translate}}</h6>
        <button class="btn btn-success mt-2" type="button" (click)="hideModal()">Ok</button>
    </div>
  </div>