<!-- Page Header Start-->
<div class="page-main-header"
     [ngClass]="{'open' : toggleSidebar}" id="page-main-header">
    <div class="main-header-right row">
        <div class="mobile-sidebar">
            <div class="media-body text-right switch-sm">
                <label class="switch">
                    <a href="javascript:void(0)" (click)="navServices.collapseSidebar.next(!toggleSidebar);">
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="mobile-filters d-flex">
            <ng-content></ng-content>

        </div>
        <div class="nav-right">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown language">
                    <a class="text-white d-flex align-items-center" href="javascript:void(0)">
                        <h6 class="text-white">{{this.language | uppercase}}</h6>
                    </a>
                    <ul class="language-dropdown onhover-show-div p-20">
                        <li><a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                            class="flag-icon flag-icon-is"></i> English</a></li>
                        <li><a href="javascript:void(0)" data-lng="ro" (click)="changeLanguage('ro')"><i
                            class="flag-icon flag-icon-ro"></i> Română</a></li>
                        <li><a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i
                            class="flag-icon flag-icon-fr"></i> Français</a></li>
                    </ul>
                </li>
                <!-- <li class="onhover-dropdown ml-5 d-flex align-items-center notifications">
                    <app-feather-icons class="bell" [icon]="'bell'"></app-feather-icons><span class="dot bg-white"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0"><span>
                                            <app-feather-icons class="shopping-color" [icon]="'shopping-bag'">
                                            </app-feather-icons>
                                        </span>Your order ready for Ship..!<small class="pull-right">9:00 AM</small>
                                    </h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-success"><span>
                                            <app-feather-icons class="download-color font-success" [icon]="'download'">
                                            </app-feather-icons>
                                        </span>Download Complete<small class="pull-right">2:30 PM</small></h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-danger"><span>
                                            <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">
                                            </app-feather-icons>
                                        </span>250 MB trush files<small class="pull-right">5:00 PM</small></h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>
                    </ul>
                </li>
                <li class="mr-5 d-flex align-items-center messages">
                    <a>
                        <app-feather-icons class="right_side_toggle" [icon]="'message-circle'">
                        </app-feather-icons><span class="dot bg-white"></span>
                    </a>
                </li> -->

<!--                Profile Start-->
               <!-- <li class="onhover-dropdown p-0 d-flex">
                   <div class="media align-items-center">
                       <img class="align-self-center pull-right rounded-circle user-image"
                           [src]="globalRegistry.userData.profile.pictureSrc" alt="header-user">
                       &lt;!&ndash; <div class="dotted-animation">
                           <span class="animate-circle border-white"></span>
                           <span class="main-circle bg-white"></span>
                       </div> &ndash;&gt;
                   </div>
                   <ul class="profile-dropdown onhover-show-div p-20">
                       <li>
                           <a (click)="router.navigate(['pages/user-profile'])">
                               <app-feather-icons [icon]="'user'"></app-feather-icons>{{ "header.editProfileLabel" | translate }}
                           </a>
                       </li>
                       <li>
                           <a (click)="router.navigate(['pages/company'])">
                               <app-feather-icons [icon]="'briefcase'"></app-feather-icons>{{ "header.companyLabel" | translate }}
                           </a>
                       </li>
                       <li>
                           <a href="javascript:void(0)">
                               <app-feather-icons [icon]="'settings'"></app-feather-icons>{{ "header.settingsLabel" | translate }}
                           </a>
                       </li>
                       <li>
                           <a (click)="logOut()">
                               <app-feather-icons [icon]="'log-out'"></app-feather-icons>{{ "header.logoutLabel" | translate }}
                           </a>
                       </li>
                   </ul>
               </li> -->
<!--                Profile end-->
            </ul>
            <div class="d-lg-none mobile-toggle pull-right"
                (click)="openSecondNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
