<div class="dark-body-only">
    <!-- page-wrapper Start-->
    <div class="page-wrapper overflow-auto" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
        <div *ngIf="isSelectDialogOpen" (click)="isSelectDialogOpen = false" class="select-plantations-bckg"></div>
        <div class="select-plantations" *ngIf="isSelectDialogOpen">
            <div class="select-plantations-header">
                <p>{{ 'leftMenu.farms' | translate }}</p>
                <span (click)="isSelectDialogOpen = false"></span>
            </div>
            <div class="dialog-content">
                <div class="search-pane">
                    <span (click)="checkAllNodes()">
                    <img src="./assets/images/fap/check_all.svg" alt="Check All">
                    </span>
                    <div class="d-flex align-items-center">
                        <span>
                            <i [ngStyle]="{'cursor': 'pointer'}" (click)="clearInput()"
                               *ngIf="plantationFilter else farmsSearch" class="fa fa-times f-20"></i>
                            <ng-template #farmsSearch><i class="fa fa-search f-20"></i></ng-template>
                         </span>
                        <input placeholder="Search" (input)="setFilterValue()" [(ngModel)]="plantationFilter" matInput>
                    </div>
                </div>

                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                        <button mat-icon-button disabled></button>
                        <mat-checkbox class="checklist-leaf-node"
                                      [checked]="checklistSelection.isSelected(node)"
                                      (change)="todoLeafItemSelectionToggle(node)">{{flatLots[node.item]}}</mat-checkbox>
                    </mat-tree-node>

                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.filename">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                            </mat-icon>
                        </button>
                        <mat-checkbox class="theme_color" [checked]="descendantsAllSelected(node)"
                                      [indeterminate]="descendantsPartiallySelected(node)"
                                      (change)="todoItemSelectionToggle(node)">{{flatFarms[node.item]}}</mat-checkbox>
                    </mat-tree-node>
                </mat-tree>
                <div class="row sticky_btn">
                    <button (click)="searchSubmit(); isSelectDialogOpen = false" mat-button class="btn-success">Ok</button>
                </div>
            </div>
        </div>
        <!-- <fap-header [isSelectDialogOpen]="isSelectDialogOpen" (baseDialogEvent)="toggleSearchDialog($event); fetchQueryParams()"></fap-header> -->
        <!-- Page Body Start-->
        <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
            <div class="page-sidebar" [style.background-image]="'url(' + mediaUrl + 'settings/menu_background.png' + ')'" (mouseleave)="navServices.collapseSidebar.next(true)" id="pages-sidebar" [class.open]="collapseSidebar"
                [ngClass]="customizer.data.settings.sidebar_backround"
                [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
                <fap-sidebar (profileToggleEvent)="toggleProfile($event)" [isProfileOpen]="isProfileOpen"></fap-sidebar>
            </div>
            <div class="page-body">
                <!-- <app-breadcrumb></app-breadcrumb> -->
                <main>
                    <div>
                        <div class="p-0 {{size}}" [ngClass]="{'col-xl-3 col-4': mapShiftedTwice,'col-xl-4 col-5': mapShiftedOnce && !mapShiftedTwice,'cut-down': mapIsOpened && !mapShiftedTwice && !mapShiftedOnce, 'col-md-12': !mapIsOpened}">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <!-- Page Body End-->
    </div>
    <!-- page-wrapper End-->
</div>
<div *ngIf="isProfileOpen" (click)="toggleProfile(false)" class="user-profile-bckg"></div>
<div *ngIf="isProfileOpen" class="user-profile-wrapper">
    <fap-user-profile-container (toggleProfileModal)="toggleProfile($event)"
                                [isProfileOpen]="isProfileOpen"
                                [buttonColor]="sidebarColor"
                                [accentColor]="hoverColor" >
    </fap-user-profile-container>
</div>
<fap-main-map (toggledMap)="onMapToggle($event)" (shiftMap)="onMapShift($event)"></fap-main-map>
