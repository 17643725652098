import {
    Component,
    Output,
    EventEmitter,
    Input
} from '@angular/core';


@Component({
    selector: 'fap-upload-image',
    templateUrl: './fap-upload-image.component.html',
    styleUrls: ['./fap-upload-image.component.scss']
})
export class FapUploadImageComponent {

    @Input()
    public inputHeight = '100px';

    @Input()
    public inputWidth = '120px';

    @Input()
    public inputClearAfterLoad = false;

    @Output()
    public outputOnLoadImage: EventEmitter<File> = new EventEmitter();

    public url: string | ArrayBuffer = '';
    public fileToUpload: File = null;

    public isLoaded = false;
    public dragging = false;
    public imageLoaded = false;


    public handleClick(e: Event): void {
        if (this.isLoaded) {
            e.preventDefault();
            this.isLoaded = false;
        }
    }

    public handleDragEnter(): void {
        this.dragging = true;
    }

    public handleDragLeave(): void {
        this.dragging = false;
    }

    public handleDrop(e: DragEvent): void {
        e.preventDefault();
        const target: DataTransfer = e.dataTransfer;
        this.readAndProcess(target);
        this.dragging = false;
    }

    public handleImageLoad(): void {
        this.imageLoaded = true;
    }

    public handleInputChange(event: Event): void {
        const target: any = event.target;
        this.readAndProcess(target);
    }

    public readAndProcess(target: any): void {
        if (target.files && target.files[0]) {
            this.outputOnLoadImage.emit(target.files[0]);

            const reader: FileReader = new FileReader();
            reader.readAsDataURL(target.files[0]);

            reader.onload = (): void => {
                if(this.inputClearAfterLoad) {
                    this.url = '';
                } else {
                    this.url = reader.result;
                }
            };
        }
    }

    public remove(): void {
        this.url = '';
        this.imageLoaded = false;
        this.outputOnLoadImage.emit(null);
    }

}
