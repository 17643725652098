import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { PlanTypeModel } from '../../../../../core/models/company/plan.type.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ActivityService } from '../../../../../core/services/api/activity/activity.service';
import { ToastrService } from 'ngx-toastr';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';

@Component({
    selector: 'fap-activities-layout',
    templateUrl: './fap-activities-layout.component.html',
    styleUrls: ['./fap-activities-layout.component.scss'],
})
export class FapActivitiesLayoutComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public activityTypes: Array<ActivityModel>;

    @Input()
    public planTypes: Array<PlanTypeModel>;

    @Input()
    public activities: Array<ActivityModel>;

    @Input() public persons

    @Output()
    public addNote: EventEmitter<ActivityModel> = new EventEmitter();
  
    @Output()
    public updateNote: EventEmitter<ActivityModel> = new EventEmitter();

    public selectedActivity: ActivityModel;
    public equipments: EquipmentModel[];
    public removeActivityModalButtonPrimaryInterface: FapModalButtonInterface;
    public removeActivityModalButtonSecondaryInterface: FapModalButtonInterface;
    public subscription: Subscription
    @ViewChild('removeActivityModal')
    public removeActivityModal: FapModalComponent;
    @ViewChild('addEditActivityModal')
    public addEditActivityModal: FapModalComponent;
    @ViewChild('addEditBaselineActivityModal')
    public addEditBaselineActivityModal: FapModalComponent;
    public activitiesByFarm: { [key: string]: ActivityModel[] } = {};
    public activitiesWithNoFarm: Array<ActivityModel> = [];
    public isLoading = false;
    public baselineActivityId = null;
    public baselineActivity: ActivityModel;
    @Input() public formTypes = [];

    constructor(private translateService: TranslateService, private equipmentsService:EquipmentService, public widgetService:WidgetsService, public companyService: CompanyService, private mapService: MapService, public globalRegistry: GlobalRegistryService, private activityService: ActivityService, private toastyService: ToastrService, public translate: TranslateService, private typesService: TypesService, private cacheService: CacheResolverService) {
        this.equipmentsService.getEquipments().pipe(take(1)).subscribe((equipments: ResponseModel<EquipmentModel[]>): void => {
          this.equipments = equipments.model;
          console.log(this.equipments);
      });
    }

    public showRemoveEquipmentModal(activity: ActivityModel): void {
        this.selectedActivity = activity;
        this.removeActivityModal.showModal();

    }

    ngOnInit(): void {
        this.isLoading = true;
        if(this.activities) {
          this.isLoading = false;
        }
        this.widgetService.setSize(7);
        this.mapService.showMap();
    }

    ngOnChanges(): void {
        this.isLoading = false;
    }

    public getActivity(activityId) {
        this.activityService.getActivity(activityId).subscribe(data => {
            this.selectedActivity = data.model;
            this.addEditActivityModal.showModal();
        }, (error) => {
            this.toastyService.error(error.error.results.error);
        })
    }

    public deleteActivity(id): void {
        console.log(id);
          this.activityService.deleteActivity(id).subscribe(data => {
            this.toastyService.success(this.translate.instant("activity.activityDeletedSuccessfully"));
            this.activityService.setActivityList(true);
            this.addEditActivityModal.hideModal();
          }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      public submitActivity(activity: ActivityModel): void {
        console.log(activity);
        this.activityService.createActivity(activity).subscribe(data => {
            if(data) {
                this.toastyService.success(
                    this.translate.instant("activity.activityCreatedSuccessfully")
                  );
                  this.activityService.setActivityList(true);
            }
        }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      public updateActivity(activity: ActivityModel): void {
          this.activityService.updateActivity(activity.id, activity).subscribe(data => {
            if(data) {
                this.toastyService.success(
                    this.translate.instant("activity.activityUpdatedSuccessfully")
                  );
                  this.activityService.setActivityList(true);
            }
          }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      public deleteBaselineActivity(id): void {
        console.log(id);
          this.activityService.deleteActivity(id).subscribe(data => {
            this.toastyService.success(this.translate.instant("activity.baselineActivityDeletedSuccessfully"));
            this.addEditBaselineActivityModal.hideModal();
            this.selectedActivity.baseline = null;
          }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      public submitBaselineActivity(activity: ActivityModel): void {
        console.log(activity);
        this.activityService.createActivity(activity).subscribe(data => {
            if(data) {
                this.toastyService.success(
                    this.translate.instant("activity.baselineActivityCreatedSuccessfully")
                  );
                  this.selectedActivity.baseline = data.model.id;
            }
        }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      public updateBaselineActivity(activity: ActivityModel): void {
          this.activityService.updateActivity(activity.id, activity).subscribe(data => {
            if(data) {
                this.toastyService.success(
                    this.translate.instant("activity.baselineActivityUpdatedSuccessfully")
                  );
                  this.selectedActivity.baseline = data.model.id;
            }
          }, (err) => {
            this.toastyService.error(err.error.results.error)
          })
      }

      setBaselineTrigger(activity) {
          console.log(activity)
            this.selectedActivity = activity;
            if(activity.baseline) {
                console.log(activity.baseline)
            } else {
                this.createBaselineActivity(activity)
            }
      }

      public createBaselineActivity(activity) {
        const activityData = {};
        this.activityService.createBaselineActivity(activityData, activity.id).subscribe(data => {
            if(data) {
                console.log(data.model);
                this.baselineActivity = data.model;
                this.selectedActivity.baseline = data.model.id
            }
        })
    }

    assignBaselineActivity(activityId) {
        this.baselineActivityId = activityId;
        this.addEditBaselineActivityModal.showModal();
    }

    ngOnDestroy(): void {
        this.widgetService.setSize(6);
    }
}
