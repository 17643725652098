import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { AttributeService } from './../../../../../core/services/api/attribute/attribute.service';
import {
    Component,
} from '@angular/core';
import { TypesService } from '../../../../../core/services/api/types/types.service';

@Component({
    templateUrl: 'attributes-container.component.html'
})
export class AttributesContainerComponent {

    constructor(public attributeService: AttributeService,
                public globalRegistry: GlobalRegistryService,
                public toastr: ToastrService,
                public translationService: TranslateService,
                public typesService: TypesService,
                public mapService: MapService) {
        this.mapService.hideMap();
    }

    public deleteAttribute(attributeId: number): void {
        this.typesService.deleteAttribute(attributeId).subscribe((): void => {
            this.globalRegistry.reloadAttributes();
            this.toastr.success(this.translationService.instant('attribute.attributeDeletedSuccessfully'));
        }, (): void => {
            this.toastr.error(this.translationService.instant('attribute.errorDeletingAttribute'));
        });
    }
}
