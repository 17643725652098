import { Routes } from "@angular/router";
import { ActivitiesAddEditContainerComponent } from './containers/activities-add-edit-container/activities-add-edit-container.component';
import { ActivitiesContainerComponent } from './containers/activities-container/activities-container.component';


export const activitiesRoutes: Routes = [
    {
        path:'',
        component: ActivitiesContainerComponent
    },
    {
        path: 'edit/:activityId',
        component : ActivitiesAddEditContainerComponent
    },
    {
        path: 'add',
        component: ActivitiesAddEditContainerComponent
    }
]