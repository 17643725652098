import {
    Component,
    Input
} from '@angular/core';

import { FapWizardBaseDirective } from '../fap-wizard-base.component';


@Component({
    selector: 'fap-wizard1',
    templateUrl: './fap-wizard1.component.html',
    styleUrls: [
        '../fap-wizard-base.component.scss',
        './fap-wizard1.component.scss'
    ]
})
export class FapWizard1Component extends FapWizardBaseDirective {

    @Input()
    public wizardSubmitDisabled = false;

    @Input()
    public wizardNextDisabled = false;

    constructor() {
        super();
    }

}
