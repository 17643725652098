    <div class="card mb-4">
        <h2 class="card-header-title mr-4" *ngIf="selectedFarm">
            {{ selectedFarm.name }}
        </h2>
        <div class="d-flex flex-wrap">
            <button class="btn btn-success mr-3 px-2" (click)="stockIncomeModal.showModal()">
                <i class="material-icons mr-1">add</i>
                {{ 'stock.transactions.income' | translate }}
            </button>
            <button class="btn btn-primary px-2" (click)="stockOutcomeModal.showModal()">
                {{ 'stock.transactions.outcome' | translate }}
                <i class="material-icons ml-1">arrow_forward</i>
            </button>
        </div>
    </div>
    <div class="grid">
        <fap-stock-list
            [stockItems]="stockItems">
        </fap-stock-list>

        <fap-stock-transactions
            [transactions]="transactions"
            [totalNoOfTransactions]="totalNoOfTransactions"
            (filterTransactions)="filterTransactions.emit($event)">
        </fap-stock-transactions>

        <fap-stock-bills
            [bills]="bills"
            [totalNoOfBills]="totalNoOfBills"
            (filterBills)="filterBills.emit($event)">
        </fap-stock-bills>
    </div>