import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalRegistryService } from '../global-registry/global-registry.service';

@Injectable()
export class CanActivateAuthGuard implements CanActivate {
    constructor(private _globalRegistry: GlobalRegistryService, private _router: Router) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // Check if the route has a specific data property indicating no authentication check is needed
        if (route.data && route.data.noAuthCheck) {
            return true;
        }

        if (localStorage.getItem('auth_token') !== undefined){
            this._globalRegistry.isLoading = false;
            this._globalRegistry.isDataLoaded = true;
            this._globalRegistry.isLoggedIn = true;
            return true;
        } else {
            this.redirectToLoginPage();
            return false;
        }
    }

    private redirectToLoginPage(): void {
        this._router.navigateByUrl('/login');
    }
}
