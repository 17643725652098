<div class="w-100 resource-table">
    <table>
        <thead>
            <tr>
                <th>{{ 'stock.ingredients.ingredientsName' | translate }}</th>
                <th class="quantity-header">{{ 'stock.quantity' | translate }}</th>
                <th><fap-round-button (clicked)="clicked.emit()"></fap-round-button></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ingredient of ingredients">
                <td>
                    <ng-container *ngFor="let type of globalRegistry?.systemData?.ingredientTypes">
                        <span *ngIf="type?.id === ingredient?.type">{{type?.nameT ? getTranslation(type?.nameT) : type?.name}}</span>
                    </ng-container>
                </td>
                <td>{{ ingredient?.quantity }} - {{ ingredient?.max }} <b>{{ ingredient?.unit?.short_name }}</b></td>
                <td>
                    <i class="fa fa-trash center f-20" (click)="deleteLocalIngredient.emit(ingredient.id)"></i>
                    <!-- <i class="fa fa-edit center f-20" (click)="selectedIngredient=ingredient;addEditIngredientModal.showModal();"></i> -->
                </td>
            </tr>
        </tbody>
    </table>
</div>
