import { LotModel } from '../../../../../core/models/lot/lot.model';
import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    AfterViewInit,
    Input
} from '@angular/core';
import { WidgetHelper } from '../../../../../core/heplers/widget.helper';
import { WidgetInfoTypeInfo } from '../../../../../core/interfaces/widget/widget-info-type-info.interface';
import { FarmModel } from '../../../../../core/models/farm/farm.model';

@Component({
    selector: 'fap-select-widget-info-type',
    templateUrl: './fap_select-widget-info-type.component.html',
    styleUrls: ['./fap_select-widget-info-type.component.scss']
})
export class FapSelectWidgetInfoTypeComponent implements OnInit, AfterViewInit {

    @Input()
    public farms: Array<FarmModel> = [];

    @Input()
    public lots: Array<LotModel> = [];

    @Output()
    public selectWidgetInfoType: EventEmitter<string> = new EventEmitter();

    @Output()
    public selectWidgetType: EventEmitter<{}> = new EventEmitter();

    public widgetInfoTypes: string[];
    public widgetInfoTypeInfo: { [key: string]: WidgetInfoTypeInfo };

    public selectedWidgetInfoType: string;
    public selectedWidgetType = 'user';
    public selectedFarm: number;
    public selectedLot: number;

    public ngOnInit(): void {
        this.widgetInfoTypes = WidgetHelper.getWidgetInfoTypes();
        this.widgetInfoTypeInfo = WidgetHelper.getWidgetInfoTypeInfo();
        this.selectedWidgetInfoType = this.widgetInfoTypes[0];
        this.selectedFarm = this.farms[0]?.id;
        this.selectedLot = this.lots[0]?.id;
    }

    public ngAfterViewInit(): void {
        this.selectWidgetInfoType.emit(this.selectedWidgetInfoType);
    }

    public clickWidgetInfoType(widgetInfoType: string): void {
        this.selectedWidgetInfoType = widgetInfoType;
        this.selectWidgetInfoType.emit(widgetInfoType);
    }

    public changedWidgetType(): void {
        const options: {} = {
            type: this.selectedWidgetType,
            id: 0
        };
        if (this.selectedWidgetType === 'farm') {
            options['id'] = this.selectedFarm;
        }
        if (this.selectedWidgetType === 'lot') {
            options['id'] = this.selectedLot;
        }
        this.selectWidgetType.emit(options);
    }
}
