import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTypes } from '../fap-round-button/round-buton.enum';

@Component({
  selector: 'fap-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {

  @Input() src:string = '';
  @Input() label: any = null;
  @Input() containerHeight: number = 185;
  @Input() isDeleteable: boolean = true;
  @Input() type: number = null;
  @Output() deleteImage: EventEmitter <any> = new EventEmitter();
  public ButtonTypes = ButtonTypes;

}
