import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';

@Component({
    selector: 'fap-activity-groups-layout',
    templateUrl: './fap_activity-groups-layout.component.html',
    styleUrls: ['./fap_activity-groups-layout.component.scss']
})
export class FapActivityGroupsLayoutComponent {

    @Input()
    public activityGroups: Array<ActivityGroupModel>;

    @Input()
    public activities: Array<ActivityModel>;

    @Output()
    public deleteActivityGroup: EventEmitter<number> = new EventEmitter();
    
}
