
import { LOCALE_ID, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'moment-timezone/data/packed/latest.json'

if (environment.production) {
  enableProdMode();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.console.log = () => {}
}

// document.title = environment.fap || 'FAP';
document.title = environment.appName || 'Lumbara'
document.getElementById('appFavicon').setAttribute('href', environment.mediaUrl + 'settings/app_icon.png');

platformBrowserDynamic().bootstrapModule(AppModule, {providers: [{provide: LOCALE_ID, useValue: "en-US"}]})
  .catch((err: any) => console.error(err));
