import { Component } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';

@Component({
    selector: 'simulations-layout',
    templateUrl: './simulations-layout.component.html',
    styleUrls: ['./simulations-layout.component.scss'],
})
export class SimulationsLayoutComponent {
    public simulationCols = [];
    public simulationList = [];
    public tableHeaderLabels = [
        { index: 1, name: 'location' },
        { index: 2, name: 'name' },
        { index: 3, name: 'start' },
        { index: 4, name: 'end' },
        { index: 5, name: 'duration' },
    ];
    public tableHeaders = [];
    public dynamicVals = [];
    public dynamicTable: Array<any> = [];
    public tableValues = [];
    public dataMap: Array<any> = [];
    public i = 3;

    constructor(public globalRegistry: GlobalRegistryService) {
        const result = {
            columns: [
                {
                    id: 'cost_0',
                    name_t: null,
                },
                {
                    id: 'produced_1',
                    name_t: null,
                },
                {
                    id: 'co2_2',
                    name_t: null,
                },
                {
                    id: 'fuel_3',
                    name_t: null,
                },
            ],
            list: [
                {
                    name: 'Corn on Pensacola',
                    lot: 142,
                    exchange: 1,
                    start: '2022-07-11T22:33:00',
                    end: '2022-07-12T22:33:00',
                    duration: '1d 33:00:00',
                    id: 1,
                    columns: {
                        cost_0: {
                            min: 10,
                            max: 10.5,
                        },
                        produced_1: {
                            min: 10,
                            max: 10.5,
                        },
                        co2_2: {
                            min: 10,
                            max: 10.5,
                        },
                        fuel_3: {
                            min: 110,
                            max: 150,
                        },
                    },
                },
                {
                  name: 'Corn on Test',
                  lot: 110,
                  exchange: 1,
                  start: '2022-07-11T22:33:00',
                  end: '2022-07-12T22:33:00',
                  duration: '1d 33:00:00',
                  id: 2,
                  columns: {
                      cost_0: {
                          min: 11,
                          max: 11.5,
                      },
                      produced_1: {
                          min: 11,
                          max: 11.5,
                      },
                      co2_2: {
                          min: 11,
                          max: 11.5,
                      },
                  },
              },
              {
                name: 'Corn on Sample',
                lot: 111,
                exchange: 1,
                start: '2022-07-11T22:33:00',
                end: '2022-07-12T22:33:00',
                duration: '1d 33:00:00',
                id: 3,
                columns: {
                    cost_0: {
                        min: 12,
                        max: 12.5,
                    },
                    produced_1: {
                        min: 12,
                        max: 12.5,
                    },
                    fuel_3: {
                        min: 130,
                        max: 170,
                    },
                },
            },
            ],
        };
        const cols = result.columns;
        cols.map((element) => {
            if (element.name_t == null) {
                const name = element.id.split('_')[0];
                const obj = { name: name + '<span>Min/Max</span>' };
                Object.assign(element, obj);
            } else {
                const name = element.name_t.split('_')[0];
                const obj = { name: name + '<span>Min/Max</span>' };
                Object.assign(element, obj);
            }
        });
        const temp = [];
        if (cols.length) {
            cols.forEach((element, i) => {
                const obj = {
                    index: i + 5,
                    name: element['name'],
                };
                temp.push(obj);
            });
            this.tableHeaders = this.mergeArrays([
                this.tableHeaderLabels,
                temp,
            ]);
            console.log(this.tableHeaders);
        }
        this.simulationCols = cols;
        this.simulationList = result.list;
        console.log(this.simulationList);
        this.executeData(this.simulationList)
    }

    executeData(simulations) {
      this.dataMap = [];
      simulations.forEach(simulation => {
        const newData = {};

        Object.keys(simulation.columns).forEach(key => {
          const newKey = key.split('_')[0];
          newData[newKey] = simulation.columns[key];
        });

        console.log(newData);
       
        const row = {
          name: simulation.name,
          costs: newData,
        }
        this.dataMap.push(row);
      })
    }


    mergeArrays(arrays) {
        return [...new Set([].concat(...arrays))];
    }

    getExtraFieldsById(id: string, name: string) {
      let val = null
      const refinedName = name.split('<span>')[0];
      this.dataMap.forEach(data => {
        if(data.name === id) {
          val = data.costs[refinedName] ? data.costs[refinedName]['min'] + '<span>'+ data.costs[refinedName]['max'] +'</span>' : '-';
        } 
      });
      return val
    }

    public getLotName(lotId: number) {
        if(lotId != 0) {
            const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
            return lot ? lot.name : '';
        }
      }
}
