import { Routes } from '@angular/router';

import { InsectsContainerComponent } from './containers/insects-container/insects-container.component';
import { InsectsEditContainerComponent } from './containers/insects-edit-container/insects-edit-container.component';


export const insectsRoutes: Routes = [
    {
        path:       '',
        component:  InsectsContainerComponent
    },
    {
        path:       'edit',
        component:  InsectsEditContainerComponent
    }
];
