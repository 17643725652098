import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTypes } from './round-buton.enum';

@Component({
    selector: 'fap-round-button',
    templateUrl: './round-button.component.html',
    styleUrls: ['./round-button.component.scss']
})
export class RoundButtonComponent {

    @Input()
    public backgroundColor: string = 'primary';

    @Input()
    public color: string;

    @Output()
    public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public border: string = '';

    @Input() public action: string = ButtonTypes.ADD;
}
