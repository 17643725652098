import { Routes } from '@angular/router';
import { MyProductsContainerComponent } from './containers/my-products-container/my-products-container.component';


export const myProductsRoutes: Routes = [
    {
        path:       '',
        component:  MyProductsContainerComponent
    },
];
