import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PersonModel } from '../../../../core/models/person/person.model';

@Component({
  selector: 'fap-people-card-list',
  templateUrl: './fap-people-card-list.component.html',
  styleUrls: ['./fap-people-card-list.component.scss']
})
export class FapPeopleCardListComponent {

  @Input()
  public persons:PersonModel;
  @Output()
  public closeModal: EventEmitter < FormData > = new EventEmitter();

  close(event){
    this.closeModal.emit(event);
  }

}
