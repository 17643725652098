import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { Subscription } from 'rxjs';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../services/nav.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'products-modal',
  templateUrl: './products-modal.component.html',
  styleUrls: ['./products-modal.component.scss']
})
export class ProductsModalComponent implements OnInit, OnChanges, OnDestroy {

  public prodFilterForm: FormGroup;
  public products: Array<any> = [];
  public prodStatus = false;
  public subscriptions: Subscription[] = []
  public nextProductToken: { limit: number, offset: number; search?: string; produced?: number } = null;
  public getMore = true;
  public limit = 30;
  public productId: number;
  public product: number;
  @ViewChild('addEditProductModal')
  public addEditProductModal: FapModalComponent;
  @ViewChild('rsp')
    public rsp: MatSelect;
  @Input() show: boolean = false;
  @Input() group: string = '';
  @Input() produced: number = null;
  @Input() isSelectable: boolean = true;
  @Output() hideModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedProduct: EventEmitter<any> = new EventEmitter<any>();
  public langString: string = null;
  public subscription: Subscription = new Subscription();


  constructor(public globalRegistry: GlobalRegistryService, private stockService: StockService, private cacheService: CacheResolverService, public formBuilder: FormBuilder, public toastrService: ToastrService, public translate: TranslateService, private navService: NavService) {}

  ngOnInit(): void {
    this.initProdSearchForm();
    this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('show') && this.show === true) {
      this.getProducts(this.produced);
    }
  }

  filterProduct() {
    const url = this.stockService.getUrl('');
    const params = {
      search: this.prodFilterForm.get('search').value ? this.prodFilterForm.get('search').value : '',
      limit: this.limit
    }
    if(this.prodFilterForm.get('type').value && this.prodFilterForm.get('type').value.length > 0) { Object.assign(params, {type : this.prodFilterForm.get('type').value.toString()}) }
    this.subscriptions.push(this.stockService.getProducts(params).subscribe(data => {
      this.cacheService.delete(url+'search='+params.search+'&limit='+params.limit)
      console.log(data);
      this.nextProductToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.products = data.body.results;
      if(this.nextProductToken) this.getMore = true
    }, (err) => {
      this.toastrService.error(err.error.results.error);
    }))
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  public getProducts(produced: number) {
    const url = this.stockService.getUrl('products/');
    let params = { 
      limit: this.limit
    } 
    if(produced != null) {
      Object.assign(params, {produced})
    }
    this.cacheService.delete(url+'limit='+this.limit);
    this.subscriptions.push(this.stockService.getProducts(params).subscribe(data => {
      console.log(data);
      this.products = data.body.results;
      this.nextProductToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
          if(this.nextProductToken) this.getMore = true
    }))
  }

  productDelete(productId: number) {
    this.stockService.deleteProduct(productId).subscribe(
        () => {
            this.products = this.products.filter(product => product.id !== productId);
            this.toastrService.error(this.translate.instant('stock.productDeletedSuccessfully'));
        },
        error => {
          this.toastrService.error(this.translate.instant('stock.cannotDeleteProduct'));
        }
    );
}

  updateProduct(updatedProduct: any) {
    const index = this.products.findIndex(product => product.id === updatedProduct.id);
    if (index !== -1) {
      this.products[index] = updatedProduct;
      this.productId = -1;
    }
  }

  addProduct(product: any) {
    this.products.unshift(product);
    this.productId = -1;
  }

    public productEdit(productId) {
      this.productId = -1;
      this.productId = productId
      this.addEditProductModal.showModal();
    }

    public selectProduct(product) {
      if(!this.isSelectable) {
        return
      }
      this.selectedProduct.emit(product)
    }

    public showProductModal(): void {
      this.productId = -1;
      this.addEditProductModal.showModal();
    }

    public setItem(ev) {
      console.log(ev.value);
      this.rsp.close();
      this.filterProduct();
    }

    public clearValue($event) {
      $event.stopPropagation(); 
      this.prodFilterForm.get('type').setValue(null);
      this.filterProduct();
    }

    public clearProdInputs() {
      this.prodFilterForm.reset();
      this.prodFilterForm.clearValidators();
      this.prodFilterForm.markAsUntouched();
      this.initProdSearchForm();
      this.filterProduct();
    }

    public toggleProdFilter() {
      this.prodStatus = !this.prodStatus;
    }

    clearSearch(searchInput: HTMLInputElement): void {
      this.prodFilterForm.get('search')?.setValue('');
      this.filterProduct();
      searchInput.focus();
    }

    public initProdSearchForm() {
      const formBody = {
        search: [''],
        type: [[]],
      };
        this.prodFilterForm = this.formBuilder.group(formBody);
    }

    paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) {
        result[key] = value;
      }
      console.log(result);
      return result;
    }

    scrolledDownProducts() {
      console.log('scrolled');
      const params = this.nextProductToken;
      if(this.getMore) {
      this.nextProductToken && 
        this.subscriptions.push(this.stockService.getProducts(params).subscribe((data) => {
          console.log(data);
          if(this.products) {
            this.products = [...this.products, ...data.body.results];
          } else {
            this.products = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextProductToken.offset != params['offset']) {
            this.nextProductToken = {offset: params['offset'], limit: params['limit']};
            if(params['search']) this.nextProductToken.search = params['search'];
            if(params['produced']) this.nextProductToken.produced = params['produced'];
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }
}
