import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CacheResolverService {
  private cache = new Map<string, [Date, HttpResponse<any>]>();

  set(key, params, value, timeToLive: number | null = null) {
    console.log(`Set cache key `, key + params);

    if (timeToLive) {
      const expiresIn = new Date();
      expiresIn.setSeconds(expiresIn.getSeconds() + timeToLive);
      this.cache.set(key + params, [expiresIn, value]);
    } else {
      this.cache.set(key + params, [null, value]);
    }
  }

  get(key, params) {
    const tuple = this.cache.get(key + params);

    if (!tuple) return null;

    // Extract tuple
    const expiresIn = tuple[0];
    const httpSavedResponse = tuple[1];
    const now = new Date();

    if (expiresIn && expiresIn.getTime() < now.getTime()) {
      this.cache.delete(key + params);
      return null;
    }

    return httpSavedResponse;
  }

  delete(key) {
    const tuple = this.cache.get(key);
    console.log(key);
    if (!tuple) return null;

    // Extract tuple
    // const expiresIn = tuple[0];
    const httpSavedResponse = tuple[1];
    this.cache.delete(key);
    return httpSavedResponse;
  }

  clearAllCache() {
    this.cache.clear();
  }
}
