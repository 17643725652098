<div class="container-fluid p-0">
    <div class="add-edit-equipment pb-0">

        <fap-section-container [title]="'Equipment data'">
            <form [formGroup]="equipmentForm" class="equipment-form mt-4 mb-2 clearfix">
                <mat-form-field class="nhalf_width col-4">
                    <mat-label>{{ 'Farm' | translate }}</mat-label>
                    <mat-select formControlName="farm" (valueChange)="changeFarm($event)">
                    <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                        <input matInput #farmFilter placeholder="Search">
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let farm of farms | filter : farmFilter.value" [value]="farm.id">{{ farm.name }}</mat-option>
                    </div>
                </mat-select>
                </mat-form-field>
                <div class="nhalf_width col-4" style="display: inline-block;">
                    <mat-form-field class="w-100">
                        <mat-label>{{ 'equipment.product' | translate }}</mat-label>
                        <mat-select #mys formControlName="product" [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" name="product" required (infiniteScroll)="scrolledDown()" msInfiniteScroll (selectionChange)="setProduct($event)">
                            <div class="d-flex" style="padding: 0 10px; position: relative; align-items: center;">
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput #typeFilter placeholder="Search" (keydown.enter)="filterProduct(typeFilter.value); $event.stopPropagation()">
                            </mat-form-field>
                            <mat-label style="position: absolute; right: 65px; cursor: pointer;" (click)="filterProduct(typeFilter.value)">
                                <i class="fa fa-search f-20"></i>
                            </mat-label>
                            <div class="input-group-append pl-1" (click)="showAlertPopup()">
                                <span class="input-group-text">
                                    <i class="icofont icofont icofont-plus"></i>
                                </span>
                            </div>
                        </div>
                        <div class="scroll_wrap" (scroll)="onScroll($event)">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option class="noteIcons" *ngFor="let stockItem of products" [value]="stockItem?.product?.id">
                                <span class="mat-option-text">
                                <div class="name">{{ stockItem?.product?.label?.name }}</div>
                                <!-- <div class="actions">
                                    <i class="fa fa-edit" (click)="productEdit(stockItem?.product); $event.stopPropagation()"></i>
                                    <i class="fa fa-trash" (click)="productDelete(product?.id); $event.stopPropagation()"></i>
                                </div> -->
                                </span>
                            </mat-option>
                        </div>
                        </mat-select>
                    </mat-form-field>
                    <!-- <span *ngIf="showProdError" class="errmsg" style="color: red;">Products are empty, <span style="border-bottom: 1px solid red; cursor: pointer;" (click)="createDoc()">click here</span> to create one</span> -->
                </div>
                    <div class="nhalf_width col-4" style="display: inline-block">
                        <fap-add-edit-delete-select
                            style="display: inline-block; margin-bottom: -15px;"
                            [previewMode]="isPreviewMode"
                            class="w-100"
                            [formControl]="equipmentForm.controls['rentedFrom']"
                            name="rentedFrom"
                            [inputName]="'equipment.rentedFrom' | translate"
                            [inputConfirmDeleteText]="'people.party.deletePartyMessage' | translate"
                            [inputNameFieldName]="'name'"
                            [inputNameTFieldName]="'nameT'"
                            [inputValueFieldName]="'id'"
                            [apiSearchEnabled]="true"
                            [inputSelectValues]="parties"
                            [inputIsRequired]="true"
                            (outputOnCreate)="selectedParty=null;addEditPartyModal.showModal()"
                            (outputOnEdit)="selectedParty=$event;addEditPartyModal.showModal()"
                            (selectionChange)="equipmentForm.get('rentedFrom').setValue($event)"
                            (outputOnScroll)="scrollParties()"
                            (outputOnSearch)="filterParties($event)"
                            (outputOnDelete)="onDeleteRentedFrom($event)">
                        </fap-add-edit-delete-select>
                        <!-- <mat-form-field class="w-100">
                            <mat-label>{{ 'equipment.rentedFrom' | translate }}</mat-label>
                            <mat-select #mys [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()" formControlName="rentedFrom" name="rentedFrom" required (infiniteScroll)="scrollParties()" msInfiniteScroll>
                                <div class="d-flex" style="padding: 0 10px; position: relative; align-items: center;">
                                <mat-form-field appearance="fill" class="w-100">
                                    <input matInput #partyFilter placeholder="Search" (keydown.enter)="filterParties(partyFilter.value); $event.stopPropagation()">
                                </mat-form-field>
                                <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterParties(partyFilter.value)">
                                    <i class="fa fa-search f-20"></i>
                                </mat-label>
                            </div>
                            <div class="scroll_wrap" (scroll)="onScroll($event)">
                                <mat-option style="height: 0!important;"></mat-option>
                                <mat-option class="noteIcons" *ngFor="let party of parties" [value]="party.id">
                                    <span class="mat-option-text">
                                    <div class="name">{{ party?.name }}</div>
                                    </span>
                                </mat-option>
                            </div>
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                <!-- <fap-unit-value class="half_width"
                    [previewMode]="isPreviewMode"
                    [fieldName]="'equipment.manufacturePower' | translate"
                    [(ngModel)]="manufacturePower"
                    [ngModelOptions]="{standalone: true}">
                </fap-unit-value> -->
                <!-- <mat-form-field class="half_width" (click)="manufactureDatePicker.open()">
                    <mat-label>{{ 'equipment.manufactureDate' | translate }}</mat-label>
                    <input matInput readonly [max]="equipmentForm.controls['lastInspection'].value"
                        formControlName="manufactureDate" [matDatepicker]="manufactureDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="manufactureDatePicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #manufactureDatePicker></mat-datepicker>
                </mat-form-field> -->
    
                <mat-form-field class="nhalf_width col-4">
                    <mat-label>{{ 'equipment.registrationPlate' | translate }}</mat-label>
                    <input formControlName="registrationPlate" matInput
                        placeholder="{{ 'equipment.registrationPlate' | translate }}" name="registrationPlate">
                </mat-form-field>
                <mat-form-field class="nhalf_width col-4">
                    <mat-label>{{ 'equipment.manufactureSerial' | translate }}</mat-label>
                    <input formControlName="manufactureSerial" matInput
                        placeholder="{{ 'equipment.manufactureSerial' | translate }}" name="manufactureSerial">
                </mat-form-field>
                <div class="nhalf_width ddd col-4" style="display: inline-block; top: -15px;">
                    <div class="d-flex" style="justify-content: space-between;">
                        <p class="para" style="width: 90px; font-size: 15px; margin-bottom: 0; margin-left: 30px;">
                            Edit parking 
                            on map
                            <!-- <span [ngStyle]="{'background':navService.hoverColor}">
                                <img src="./assets/images/fap/add_edit_farm.svg" alt="Edit on map">
                            </span> -->
                        </p>
                        <span>
                            <img class="mt_12" [src]=navService.triangle alt="triangle">
                        </span>
                    </div>
                </div>
                <mat-form-field class="nhalf_width col-4">
                    <mat-label>{{ 'equipment.workingHours' | translate }}</mat-label>
                    <input formControlName="workingHours" type="number" min="0" matInput
                        placeholder="{{ 'equipment.workingHours' | translate }}" name="workingHours">
                </mat-form-field>
                <!-- <mat-form-field class="nhalf_width col-4" (click)="getBills()">
                    <mat-label>{{'equipment.bill' | translate}}</mat-label>
                    <mat-select #mys formControlName="bill" name="bill" required (infiniteScroll)="scrollBills()" msInfiniteScroll>
                        <div class="d-flex" style="padding: 0 10px; position: relative; align-items: center;">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #billFilter placeholder="Search">
                        </mat-form-field>
                        <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterDocs(billFilter.value)">
                            <i class="fa fa-search f-20"></i>
                        </mat-label>
                    </div>
                    <div class="scroll_wrap" (scroll)="onScrollDoc($event)">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let bill of bills | filter : billFilter.value" [value]="bill.id">{{ bill?.docDate | date: "MMM dd yyyy" }} / {{ bill?.docId }}</mat-option>
                    </div>
                    </mat-select>
                </mat-form-field> -->
                
                <mat-form-field class="nhalf_width col-4 rltv">
                    <mat-label>{{ 'equipment.inspection' | translate }}</mat-label>
                    <input matInput
                        placeholder="{{ 'equipment.inspection' | translate }}" name="inspection">
                </mat-form-field>
                <!-- <mat-checkbox formControlName="parked">
                    {{ 'equipment.parked' | translate }}
                </mat-checkbox> -->
            </form>
        </fap-section-container>
        
        <fap-attributes
            [entityRelation]="'equipment'"
            [entityId]="entityInfo?.id"
            [entityInfo]="entityInfo"
            [objectId]="equipmentId"
            (formTypeEmit)="formType = $event"
            (formFieldsEmit)="formFields = $event"
        >
        </fap-attributes>

        <div class="last_activity mt-3" *ngIf="equipment?.lastActivity">
            <fap-section-container
                [title]="'Activity'"
            >
            <div class="body d-flex">
                <div class="infos">
                    <p class="mb-0 select-option-time">
                        <img src="../../../../../../assets/images/clock.png" alt=""> {{ calcTime(equipment?.lastActivity?.workingTime) }} min</p>
                        <p class="mb-0 select-option-time"><img
                            src="../../../../../../assets/images/distance.png" alt=""/> {{ equipment?.lastActivity?.distance }} km</p>
                    <p class="mb-0 select-option-time">
                        <img src="../../../../../../assets/images/area.png" alt=""/> {{ equipment?.lastActivity?.area }} ha</p>
                </div>
                <div *ngIf="equipment?.lastActivity?.attached_equipments">
                    <!-- <ng-container *ngFor="let eq of equipment?.lastActivity?.attached_equipments"> -->
                        <div class="main_eq d-flex" *ngIf="equipment?.lastActivity?.attached_equipments[0].attachable == 0">
                            <div class="p-0 activitycard-implementinfo used">
                                {{ eq?.registration_plate }}
                            </div>
                            <div class="p-0 user_im img_place_bg lands">
                                
                                    <ng-container *ngIf="equipment?.lastActivity?.attached_equipments[0]?.product?.images.length">
                                        <img [src]="mediaUrl + equipment?.lastActivity?.attached_equipments[0]?.product?.images[equipment?.lastActivity?.attached_equipments[0]?.product?.images.length - 1].file" alt="" [attr.class]="image?.data?.orientation">
                                    </ng-container>
                                
                                <img style="padding: 15px;" *ngIf="equipment?.lastActivity?.attached_equipments[0]?.product.images.length == 0" src="../../../../../../assets/images/landscape_placeholder.png" alt="" />
                            </div>
                        </div>
                    <!-- </ng-container> -->
                </div>
                <div class="main_infos">
                    <div class="title_row">
                        <div class="edit_link p-0 mb-0"><h5>{{ equipment?.lastActivity?.name }}</h5>
                        </div>
                    </div>
                    <div class="sub_contents">
                        <div class="c1">
                            <span *ngIf="equipment?.lastActivity?.plan">
                                <p class="m-0">
                                    <ng-container *ngFor="let plan of globalRegistryService?.systemData?.plans">
                                        <ng-container *ngIf="plan?.id === equipment?.lastActivity?.plan">
                                            <i *ngIf="getTranslation(plan?.nameT) !== ''">{{getTranslation(plan?.nameT)}}</i>
                                        </ng-container>
                                    </ng-container>
                                </p>
                              </span>
                        </div>
                        <div class="c2">
                            <div class="p-0 mb-0 act_date">
                                <p class="select-option-date">
                                    {{ equipment?.lastActivity?.started | date: "MMM dd yyyy, HH:mm" }}
                                </p>
                            </div>
                            <!-- <div class="pl-2  act_date">
                                <p class="select-option-date">
                                    {{ equipment?.lastActivity?.ended | date: "MMM dd yyyy, HH:mm" }}
                                </p>
                            </div> -->
                        </div>
                        </div>
                </div>
                <div class="ppl">
                    <ng-container *ngFor="let person of globalRegistryService.systemData.persons">
                        <div class="user user_im img_place_bg assigned" *ngIf="person.id === equipment?.lastActivity.assignedTo">
                            <img
                                src="../../../../../../assets/images/people_placeholder.svg"
                                alt="assigned to"
                                *ngIf="person.picture == null"
                            />
                            <img
                                [src]="mediaUrl + person.picture"
                                alt="assigned to"
                                *ngIf="person.picture != null"
                            />
                        </div>
                    </ng-container>
                    <div class="user user_im img_place_bg" *ngIf="equipment?.lastActivity.assignedTo == null">
                        <img
                            src="../../../../../../assets/images/people_placeholder.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            </fap-section-container>
        </div>
        <div class="mt-3">
        <fap-equipment-paths [equipment]="equipment"></fap-equipment-paths>
        </div>
    </div>
</div>

<fap-modal #addEditPartyModal
    [showFooter]="false"
    inputTitle="{{ 'people.party.addEditParty' | translate }}">
    <fap-add-edit-party [party]="selectedParty"
    (addedParty)="addParty($event)"
    (updatedParty)="addedOrUpdatedParty($event)"
    (cancel)="addEditPartyModal.hideModal()"
    >
    </fap-add-edit-party>
</fap-modal>

<fap-modal #productAlertModal [inputTitle]="'stock.addEquipment' | translate" [inputIsMedium]="true">
    <div class="alert_box">
        <div class="alert_content">
            <div>{{ 'stock.addEquipmentMessage' | translate }}</div>
        </div>
        <div class="btns">
            <button class="btn btn-primary" (click)="goToInvoiceIn()">{{ 'stock.invoiceIn' | translate }}</button>
            <button class="btn btn-primary" (click)="goToInternalNote()">{{ 'stock.internalNote' | translate }}</button>
            <button class="btn btn-primary" (click)="goToProductionNote()">{{ 'stock.productionNote' | translate }}</button>
        </div>
    </div>
</fap-modal>
