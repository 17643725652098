import { UtilsHelper } from './utils.helper';

export class FapHelper {
    public static convertObjectToFormData(object: {}): FormData {
        const formData: FormData = new FormData();
        Object.getOwnPropertyNames(object).map((key: string): void => {
            if (object[key]) {
                if ((key === 'image' || key === 'picture')) {
                    formData.append(key, object[key], object[key].name);
                }
                formData.append(UtilsHelper.camelCaseToSnakeCaseString(key), object[key].toString());
            }
        });
        return formData;
    }
}
