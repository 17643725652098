<div class="infosection w-100 d-flex">
    <div class="main_part">
        <div class="name_t">
            <b [innerHTML]="getHtmlContent(nameT)"></b>
        </div>
        <div class="body_t" [innerHTML]="getHtmlContent(bodyT)"></div>
        <div class="img_container">
            <div class="images" style="display: flex;">
                <div class="nimg" *ngFor="let image of description?.images">
                    <fap-image [isDeleteable]="false" [src]="mediaUrl+image?.file" [containerHeight]="150"></fap-image>
                </div>
            </div>
        </div>
    </div>
    <div class="actions">
        <action-buttons 
            [locked]="description?.locked" 
            (editEmit)="editDescription.emit(description)" 
            (deleteEmit)="deleteDescription.emit(description?.id)"
        ></action-buttons>
    </div>
</div>