<div class="asset_card" *ngIf="asset" [ngClass]="{'green': asset?.transaction?.id}">
    <div class="image">
        <div class="user_im img_place_bg">
        <img *ngIf="asset?.product?.images?.length" [src]="mediaUrl + asset?.product?.images[asset?.product?.images?.length - 1]?.file" alt="product">
        <img *ngIf="asset?.product?.images?.length == 0 || asset?.product === null" src="../../../../../../assets/images/image_placeholder.png" alt="product">
    </div>
    </div>
    <div class="info">
        <div class="name">{{asset?.name}}</div>
        <div class="txn">
            <div class="txn_details" *ngIf="asset?.transaction?.id">
                <div class="rw">
                    {{ 'assets.id' | translate }}: {{asset?.transaction?.document?.doc_id}}/{{asset?.transaction?.document?.doc_date | date: "MMM dd yyyy"}}
                </div>
                <div class="rw">
                    {{ 'assets.expires' | translate }}: -
                </div>
                <div class="rw">
                    {{ 'assets.serial' | translate }}: {{asset?.data?.license?.id}}
                </div>
            </div>
        </div>
    </div>
</div>