import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
    selector: 'simulations-add-edit-container',
    templateUrl: './simulations-add-edit-container.component.html',
    styleUrls: ['./simulations-add-edit-container.component.scss'],
})
export class SimulationsAddEditContainerComponent implements OnInit, OnDestroy {
    public simulationId: number = null;
    public simulation: any;
    public farms: FarmModel[] = [];

    public subscriptions: Subscription[] = [];

    constructor(
        private mapService: MapService,
        public activatedRoute: ActivatedRoute,
        public globalRegistry: GlobalRegistryService,
        public navService: NavService,
        public router: Router,
        public widgetService: WidgetsService
    ) {
        this.navService.editFarm.next(true);
        this.subscriptions.push(
            combineLatest([activatedRoute.params]).subscribe(
                ([params]: [Params]) => {
                    this.farms = this.globalRegistry.systemData.farms;
                    this.navService.createMod.next(true);
                    if (params['simulationId'] !== undefined) {
                        this.simulationId = Number(params['simulationId']);
                        this.navService.createMod.next(false);
                        this.navService.editMod.next(true);
                        this.loadSimulation(this.simulationId);
                    }
                }
            )
        );
    }

    ngOnInit(): void {
        this.mapService.hideMap();
        this.widgetService.setSize(12);
        this.navService.emitCancel.subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['/pages/simulations']);
                this.navService.emitCancel.next(false);
            }
        });
    }

    public loadSimulation(simulationId) {
        console.log(simulationId);
    }

    public submitSimulation(event) {
      console.log(event)
    }

    public updateSimulation(event) {
      console.log(event)
    }

    public deleteSimulation(event) {
      console.log(event)
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void =>
            subscription.unsubscribe()
        );
        this.mapService.isEditMode = true;
        this.navService.editFarm.next(false);
        this.widgetService.setSize(6);
    }
}
