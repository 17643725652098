import { PartyService } from './../../../../../core/services/api/company/party.service';
import {
    Component,
    OnInit
} from '@angular/core';
import {
    ActivatedRoute,
    Params
} from '@angular/router';
import { Observable } from 'rxjs';
import {
    map
} from 'rxjs/operators';

import { TransactionModel } from './../../../../../core/models/stock/transaction.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { StockIncomeInterface } from '../../../../../core/interfaces/stock/stock-income.interface';
import { StockOutcomeInterface } from '../../../../../core/interfaces/stock/stock-outcome.interface';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { BillModel } from '../../../../../core/models/stock/bill.model';
import { TransactionsParamsInterface } from './../../../../../core/interfaces/stock/transactions-params.interface';
import { BillsParamsInterface } from './../../../../../core/interfaces/stock/bills-params.interface';

@Component({
    templateUrl: './stock-farm-container.component.html'
})
export class StockFarmContainerComponent implements OnInit {

    public stockItems$: Observable<StockItemModel[]>;
    public selectedFarm$: Observable<FarmModel>;

    public transactions: Array<TransactionModel>;
    public totalNoOfTransactions = 0;
    public bills: Array<BillModel>;
    public totalNoOfBills = 0;

    public transactionFilters: TransactionsParamsInterface = {};
    public billsFilters: BillsParamsInterface = {};

    public farmId: number;

    constructor(public globalRegistry: GlobalRegistryService,
                private stockService: StockService,
                private mapService: MapService,
                private activatedRoute: ActivatedRoute,
                private farmService: FarmService,
                private toastrService: ToastrService,
                private translateService: TranslateService,
                private typesService: TypesService,
                private partyService: PartyService) {
        this.activatedRoute.params.subscribe((params: Params): void => {
            this.farmId = parseInt(params['farmId'], 10);
            this.selectedFarm$ = this.farmService.getFarm(this.farmId).pipe(map((response: ResponseModel<FarmModel>): FarmModel => {
                return response.model;
            }));
            // this.getBills();
            this.getStockItems();
            this.getTransactions();
        });
    }

    public ngOnInit(): void {
        this.mapService.hideMap();
        this.selectedFarm$ = this.farmService.getFarm(this.farmId).pipe(map((response: ResponseModel<FarmModel>): FarmModel => {
            return response.model;
        }));

        this.getStockItems();
        this.getTransactions();
        // this.getBills();
    }

    public addStockIncome(stockIncome: StockIncomeInterface): void {
        this.stockService.addStockIncome({...{farm: this.farmId}, ...stockIncome}).subscribe((): void => {
            this.toastrService.success(this.translateService.instant('stock.stockIncomeSucces'));
            this.getStockItems();
            this.getTransactions();
        },
        (): void => {
            this.toastrService.error(this.translateService.instant('stock.stockIncomeError'));
        });
    }

    public addStockOutcome(stockOutcome: StockOutcomeInterface): void {
        this.stockService.addStockOutcome({...{farm: this.farmId}, ...stockOutcome}).subscribe((): void => {
            this.toastrService.success(this.translateService.instant('stock.stockOutcomeSucces'));
            this.getStockItems();
            this.getTransactions();
        },
        (): void => {
            this.toastrService.error(this.translateService.instant('stock.stockOutcomeError'));
        });
    }

    public getTransactions(params?: TransactionsParamsInterface): void {
        if (params) {
            this.transactionFilters = params;
        }
        this.transactionFilters.resource_entry__stock_item__farm__id = String(this.farmId);
        this.stockService.getTransactions(this.transactionFilters)
            .subscribe((transactions: ResponseModel<TransactionModel[]>): void => {
                console.log(transactions);
                this.transactions = transactions.body.results;
                this.totalNoOfTransactions = transactions.body.count;
            });
    }

    // public getBills(params?: BillsParamsInterface): void {
    //     if (params) {
    //         this.billsFilters = params;
    //     }
    //     this.billsFilters.farm = String(this.farmId);
    //     this.stockService.getBills(this.billsFilters).pipe(take(1))
    //         .subscribe((bills: ResponseModel<BillModel[]>): void => {
    //             this.bills = bills.model;
    //             this.totalNoOfBills = bills.body.count;
    //         });
    // }

    public deleteParty(partyId: number): void {
        this.partyService.deleteParty(partyId).subscribe((): void => {
            this.globalRegistry.reloadParties();
            this.toastrService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
        }, (): void => {
            this.toastrService.success(this.translateService.instant('people.party.errorDeletingParty'));
        });
    }

    private getStockItems(): void {
        this.stockItems$ = this.stockService.getStockItem({farm__id: this.farmId}).pipe(
            map((response: ResponseModel<StockItemModel[]>): StockItemModel[] => response.model));
    }
}
