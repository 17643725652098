import {
    Component,
    AfterViewInit,
    Output,
    EventEmitter,
    OnInit,
    ViewChild,
    ElementRef, NgZone, OnDestroy, Input, OnChanges, SimpleChanges
} from '@angular/core';
import { MouseEvent, PolyMouseEvent, LatLng, MapsAPILoader } from '@agm/core';
import { PathEvent } from '@agm/core/directives/polyline';
import { PolygonPathEvent } from '@agm/core/directives/polygon';
import { NgxSpinnerService } from 'ngx-spinner';
import * as feather from 'feather-icons';

import { MapHelper } from '../../../core/heplers/map.helper';
import { MapMarkerInterface } from './data/map-marker.interface';
import { MapPointInterface } from './data/map-point.interface';
import { MapCirclePointInterface } from './data/map-circle-point.interface';
import { MapPolygonInterface } from './data/map-polygon.interface';
import { MapService } from './service/map-service';
import { MapButtonInterface } from './data/map-button.interface';
import { NavService } from '../../services/nav.service';
import { filter, map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { FarmService } from '../../../core/services/api/farm/farm.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../core/models/farm/farm.model';
import { LotModel } from '../../../core/models/lot/lot.model';
import { LotService } from '../../../core/services/api/farm/lot.service';
import { CsvDataService } from '../../services/csv-data.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {  Subscription } from 'rxjs';
import { NotesService } from '../../../core/services/api/farm/notes.service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { environment } from '../../../../environments/environment';
import { ActivityService } from 'src/app/core/services/api/activity/activity.service';

export interface CoordCheckbox {
    id: number;
    coords: number[];
    selected: boolean;
}

@Component({
    selector: 'fap-main-map',
    templateUrl: './fap_main-map.component.html',
    styleUrls: ['./fap_main-map.component.scss']
})
export class FapMainMapComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

    public contextmenuX = 0;
    public contextmenuY = 0;
    public contextMenuEnabled = false;
    public shifter = 2;
    public latitude: number;
    public longitude: number;
    public address: string;
    public mapTypeControl: boolean;
    public mapSearchToggle: boolean;
    public coordsListToggle = false;
    public editable: number;
    public editableCoords: number[][] = [];
    public editableCoordsBackup: number[][] = [];
    public markerCoordinates: number[][] = [];
    public changedCoordinate: number;
    public csvRecords: any[] = [];
    public header = true;
    public formCoords;
    public zoom: number;
    public mapIconUrl = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'


    @Output()
    public toggledMap: EventEmitter<boolean> = new EventEmitter();
    @Output()
    public shiftMap: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('mapRevertBtn')
    public mapRevertBtn: ElementRef;

    @ViewChild('search')
    public searchElementRef: ElementRef;

    @Input() public showMap = true;

    @ViewChild('fileImportInput', {static: false})
    public fileImportInput: any;

    private isCreateMod: boolean;
    private isFarm: boolean;
    private polyLength: number;
    private geoCoder
    private checkedBoxes: number[] = [];
    private checkboxArr: CoordCheckbox[];
    private subscriptions: Array<Subscription> = [];
    public size;
    public icon = './assets/images/fap/Pin-white.svg';
    public object = [];
    public showBtn = true;
    public apiUrl = environment.baseBackendUrl;
    public opened = false;
    public previousSize = null;

    circleRadius = 20; // in meters
    circleFillColor = '#FF0000';
    circleStrokeColor = '#000000';
    circleStrokeWeight = 2;

    constructor(public mapService: MapService,
                public navService: NavService,
                public farmService: FarmService,
                public lotService: LotService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public globalRegistryService: GlobalRegistryService,
                private csvDataService: CsvDataService,
                private fb: UntypedFormBuilder,
                private ngxCsvParser: NgxCsvParser,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private spinner: NgxSpinnerService,
                private notesService: NotesService,
                public widgetsService: WidgetsService,
                public activityService: ActivityService,
                ) {
        this.editableCoordsBackup = this.editableCoords;
        this.navService.createMod.subscribe((value: boolean) => {
            this.isCreateMod = value;
        });
        // Revert changes
        this.mapService.clickedRevertBtn.asObservable().subscribe(() => {
            this.editableCoords = this.editableCoordsBackup;
            this.checkboxArray();
            this.dynamicCheckboxCreate();
            if (!this.isCreateMod) {
                this.redrawPolygon();
            } else {
                this.polyLength = 0;
                this.mapService.lastPoligonTouchedSelectedArea = 0;
            }
            this.polygonSubmit();
        });

        this.subscriptions.push(this.navService.emitCancel.subscribe(() => {
                this.editableCoords = [];
                this.editableCoordsBackup = [];
                this.mapService.mapPolygons = [];
                this.checkboxArray();
                this.dynamicCheckboxCreate();
                this.redrawPolygon();
        }));

        this.subscriptions.push(this.mapService.mapZoom.subscribe((zoom: number) => {
            this.zoom = zoom;
        }));

        this.subscriptions.push(this.mapService.toggledMap.subscribe((opened: boolean): void => {
            this.toggledMap.emit(opened);
        }));
        this.subscriptions.push(this.mapService.shiftMap.subscribe((shifted: number): void => {
            this.shiftMap.emit(shifted);
        }));
        this.mapService.mapSpinnerService = this.spinner;
        const currentLocationStr: string = localStorage.getItem('currentLocation');
        if (currentLocationStr) {
            this.mapService.currentLocation = JSON.parse(currentLocationStr);
        }
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position): void => {
                this.mapService.currentLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                localStorage.setItem('currentLocation', JSON.stringify(this.mapService.currentLocation));
                this.mapService.onCurrentLocationLoaded.emit();
            });
        } else {
            this.mapService.onCurrentLocationLoaded.emit();
        }
        this.subscriptions.push(this.navService.editFarm.subscribe((value: boolean) => {
            this.mapTypeControl = value;
        }));
        this.router.events.pipe(
            startWith(this.router),
            filter(
                (event: RouterEvent) => event instanceof NavigationEnd || event instanceof Router
            ),
            map((event) => {
                return event['url'];
            }),
           // map(path => this[path])
        ).subscribe((url: string) => {
            if (url.includes('lot/edit')) {
                this.isFarm = false;
                this.editableFarmOrLotSelect(Number.parseInt(url.slice(16)), 'lot');
            } else if (url.includes('farm/edit')) {
                this.isFarm = true;
                this.editableFarmOrLotSelect(Number.parseInt(url.slice(17)), 'farm');
            }
        });

        this.navService.createMod.subscribe(() => {
           this.editableCoords = [];
           this.editableCoordsBackup = [];
           this.checkboxArray();
           this.dynamicCheckboxCreate();
        });
    }

    public ngAfterViewInit(): void {
        setTimeout((): void => {
            feather.replace();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'showMap') && this.showMap === false) {
            this.mapService.hideMap();
        }
    }

    public centerOnCircles() {
        console.log('centerOnCircles function called');
        const latitudes = this.mapService.reports.map(report => report.coords.coordinates[0]);
        const longitudes = this.mapService.reports.map(report => report.coords.coordinates[1]);

        console.log('Latitudes:', latitudes);
        console.log('Longitudes:', longitudes);

        const avgLat = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
        const avgLng = longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;

        this.mapService.mapFocusPoint = {lat: +avgLat, lng: +avgLng};
        this.mapService.mapZoom.next(20)
        console.log(this.mapService.mapFocusPoint);
    }

    public ngOnInit(): void {
        // this.getLengthOfPolygon().subscribe()
        if(this.mapService.reports.length) {
            console.log(this.mapService.reports);
        }
        console.log(this.apiUrl);
        this.widgetsService.getMapResize.subscribe(data => {
            this.showBtn = data;
            if(this.showBtn == false) {
                setTimeout(() => {
                    this.mapShifter();
                }, 1000);
            }
        });
        this.widgetsService.getSize.subscribe(size => {
            this.size = 'size'+size;
        })
        console.log(this.size);
        this.widgetsService.getDCoords.subscribe((object) => {
            if(object) {
                object.map(el => {
                    const coordArr = el.coord.split(',');
                    el.lat = coordArr[0];
                    el.lng = coordArr[1];
                });
            }
            console.log(object);
            this.object = object;
        })
        this.mapService.lastPoligonTouchedSelectedLenght.subscribe((value: number) => {
            this.polyLength = value;
        });
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.mapService.clickedRevertBtn.subscribe(() => {
        });
        this.mapService.onPalygonDrawed.subscribe(() => {
                this.setMapMarkers();
        });
        this.mapService.mainMapIsOpened = true;
        this.mapService.getMapDisplay.subscribe(data => {
            this.opened = data;
        });
        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder();
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    if (place.geometry === undefined || place.geometry === null) {
                        this.mapService.mapFocusPoint.lat = this.mapService.currentLocation.lat;
                        this.mapService.mapFocusPoint.lng = this.mapService.currentLocation.lng;
                        return;
                    }
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.mapService.mapFocusPoint.lat = this.latitude;
                    this.mapService.mapFocusPoint.lng = this.longitude;
                    this.mapService.mapZoom.next(16);
                    this.searchElementRef.nativeElement.value = ''
                });
            });
        });
        if(window.innerWidth <= 767) {
            this.mapService.setMapDisplay(false);
            this.mapService.getMapDisplay.subscribe(data => {
                this.mapService.mainMapIsOpened = data;
                if(data == true) {
                    this.mapService.mainMapIsOpened = false
                }
            })
        }
        this.widgetsService.getSize.subscribe(size => {
            if(size !== 12) {
                this.previousSize = size;
            }
        })
    }

    public reportCircleClicked(id) {
        console.log(id);
        this.mapService.reportId.next(id);
    }

    public ngOnDestroy(): void {
        // this.mapService.mapZoom.unsubscribe();
        // this.mapService.onPolygonDelete.unsubscribe();
        // this.mapService.toggledMap.unsubscribe();
        // this.mapService.shiftMap.unsubscribe();
        // this.farmService.selectFarm.unsubscribe();
        // this.lotService.selectLot.unsubscribe();
        // this.mapService.clickedRevertBtn.unsubscribe();
        this.editableCoords = [];
        this.subscriptions.forEach(s=>s.unsubscribe());
    }


    public mapShifter(): number {
        if (this.shifter > 3) {
            this.shifter = 1;
        }
        console.log(this.shifter);
        return this.shifter++;
    }

    // activates the menu with the coordinates
    public onContextMenuRightClick(event: any): void {
        this.contextmenuX = event.clientX;
        this.contextmenuY = event.clientY;
        if (this.mapService.isEditMode && this.mapService.mapHasContextMenu) {
            this.contextMenuEnabled = true;
        }
    }

    // disables the menu
    public disableContextMenu(): void {
        this.contextMenuEnabled = false;
    }

    public toggleMainMap(): void {
        this.mapService.mainMapIsOpened = !this.mapService.mainMapIsOpened;
        this.toggledMap.emit(this.mapService.mainMapIsOpened);
        if (this.mapService.mainMapIsOpened === false) {
            this.widgetsService.setSize(12);
        } else {
            if (window.innerWidth <= 767) {
                this.widgetsService.setSize(12);
            } else {
                this.widgetsService.setSize(this.previousSize);
            }
        }
    }
    

    public mapRightClicked(event: MouseEvent): void {
        this.mapService.rightClickLastPosition.lat = event.coords.lat;
        this.mapService.rightClickLastPosition.lng = event.coords.lng;
    }

    public onMapMenuInit(): void {
        this.mapService.onMapMenuInit.emit();
    }

    public onMapReady(gmap: google.maps.Map): void {
        // this.initDrawingManager(map);
        // gmap.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.mapRevertBtn.nativeElement);
        this.mapService.buttons.forEach((button: MapButtonInterface): void => {
            gmap.controls[google.maps.ControlPosition.LEFT_CENTER].push(document.getElementById(button.value));
        });
        this.mapService.onMapInit.next(gmap);
    }

    public polyPathsChange(event: PolygonPathEvent<LatLng | LatLng[]>, index: number): void {
        const polygon: Array<MapPointInterface> = [];
        this.markerCoordinates = this.editableCoords;
        this.editableCoords = [];
        this.mapService.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(event.newArr[0], 'ha');
        this.polyLength = MapHelper.getPolygonLength(event.newArr[0]) / 1000;
        event.newArr[0].map((point: any): any => {
            polygon.push({
                lat: point.lat,
                lng: point.lng
            });
            this.editableCoords.push([point.lat, point.lng]);
        });
        this.mapService.mapPolygons[index] = {
            points: polygon,
            strokeColor: this.isFarm ? '#a31f1f' : '#ffff00',
            fillColor: this.isFarm ? '#de3333' : '#ffff00',
        };
        this.editableCoords = this.multiArrayUnique(this.editableCoords);
        this.polygonSubmit();
        this.mapService.onLastPolyPathChange.emit(this.mapService.mapPolygons[index]);
        this.setMapMarkers();
        this.checkboxArray();
        this.dynamicCheckboxCreate();
    }

    public markerDragEnd(marker: MapMarkerInterface): void {
        this.mapService.onMarkerDragEnd.emit(marker);
    }

    public clickedMarker(marker: MapMarkerInterface): void {
        this.mapService.clickedMarker.emit(marker);
    }

    public circleClicked(event: MapCirclePointInterface): void {
        this.mapService.circleCliked.emit(event);
    }

    public circleRadiusChange(newCircleRadius: number): void {
        this.mapService.circleRadiusChanged.emit(newCircleRadius);
    }

    public circleDragEnd(cirleCenter: MapCirclePointInterface): void {
        this.mapService.circleDragEnd.emit(cirleCenter);
    }

    public polyClick(event: PolyMouseEvent, poligon: MapPolygonInterface, polyIndex: number): void {
        const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
        const chars = getLastItem(this.router.url).split('?');
        const editRoute = this.router.url.split('/');
        if(chars[0] == 'activities') {
            this.globalRegistryService.systemData.lots.forEach((eachLot: LotModel) => {
                eachLot.coords.coordinates.forEach((farmFilter: (number[])[]) => {
                    farmFilter.forEach((farmFilter2: number[]) => {
                        farmFilter2.forEach((farmFilter3: number) => {
                            if (farmFilter3 === poligon.points[0].lat) {
                                this.editable = eachLot.id;
                                this.editableCoords = eachLot.coords.coordinates[0];
                                this.lotService.selectLot.next(eachLot.id);
                                this.mapService.centerMapOnPolygon(poligon);
                                poligon.isEditable = false;
                                poligon.strokeWeight = 10;
                                this.router.navigateByUrl('/pages/activities/add?lot=' + this.editable);
                                const currentLocation = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
                                localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
                                // this.centerOnLocation();
                            }
                        });
                    });
                });
            });
        } else if(chars[0] == 'add' || editRoute[editRoute.length-2] == 'edit') {
            this.globalRegistryService.systemData.lots.forEach((eachLot: LotModel) => {
                eachLot.coords.coordinates.forEach((farmFilter: (number[])[]) => {
                    farmFilter.forEach((farmFilter2: number[]) => {
                        farmFilter2.forEach((farmFilter3: number) => {
                            if (farmFilter3 === poligon.points[0].lat) {
                                this.editable = eachLot.id;
                                this.editableCoords = eachLot.coords.coordinates[0];
                                this.lotService.selectLot.next(eachLot.id);
                                this.mapService.centerMapOnPolygon(poligon);
                                poligon.isEditable = false;
                                poligon.strokeWeight = 10;
                                console.log(this.editable);
                                this.activityService.setActivityLots(this.editable);
                                const currentLocation = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
                                localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
                                // this.centerOnLocation();
                            }
                        });
                    });
                });
            });
        }
        this.mapService.onPolyClick.emit({
            polygon: poligon,
            index: polyIndex
        });
        poligon.strokeWeight = 10;
        this.mapService.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(poligon.points, 'ha');
        this.polyLength = this.mapService.getLengthOfPolygon(poligon);
    }

    public polyRightClick(event: PolyMouseEvent, poligon: MapPolygonInterface, polyIndex: number): void {
        this.mapService.onPolyRightClick.emit({
            polygon: poligon,
            index: polyIndex
        });
    }

    public polyDragEnd(event: MouseEvent): void {
        this.mapService.polyDrangEnd.emit(event);
    }

    public lineClick(event: PolyMouseEvent): void {
        this.mapService.lineClick.emit(event);
    }

    public polyPathChange(event: PathEvent): void {
        this.mapService.polyPathChange.emit(event);
    }

    // public initDrawingManager(map: any): void {
    //     const options = {
    //         drawingControl: true,
    //         drawingControlOptions: {
    //             drawingModes: ['circle']
    //             // drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle']
    //         },
    //         polygonOptions: {
    //             draggable: true,
    //             editable: true
    //         },
    //         // drawingMode: google.maps.drawing.OverlayType.CIRCLE,
    //         circleOptions: {
    //             fillColor: '#ffff00',
    //             fillOpacity: 1,
    //             strokeWeight: 2,
    //             strokeColor: 'red',
    //             clickable: false,
    //             editable: true,
    //             zIndex: 1
    //           }
    //     };

    //     this.drawingManager = new google.maps.drawing.DrawingManager(options);
    //     this.drawingManager.setMap(map);
    //     google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle: any) => {
    //         this.drawingManager.setOptions({
    //             drawingMode: google.maps.drawing.OverlayType.,
    //             drawingControl: false
    //           });
    //       });
    //     this.drawingManager.setOptions({
    //         drawingControl: false
    //       });
    // }

    public getNumber(i: number): string {
        if (i % 100 === 0 && i !== 0) {
            return '' + i + '.';
        } else if (i % 10 === 0 && i !== 0) {
            return '0' + i + '.';
        } else {
            return '00' + i + '.';
        }
    }

    public polyClickEdit(event: PolyMouseEvent, poligon: MapPolygonInterface, polyIndex: number): void {
        console.log(event, polyIndex);
        this.globalRegistryService.systemData.farms.forEach((eachFarm: FarmModel) => {
            eachFarm.coords.coordinates.forEach((farmFilter: (number[])[]) => {
                farmFilter.forEach((farmFilter2: number[]) => {
                    farmFilter2.forEach((farmFilter3: number) => {
                        if (farmFilter3 === poligon.points[0].lat) {
                            this.editable = eachFarm.id;
                            this.editableCoords = eachFarm.coords.coordinates[0];
                            this.farmService.selectFarm.next(eachFarm.id);
                            this.mapService.centerMapOnPolygon(poligon);
                            poligon.isEditable = true;
                            poligon.strokeWeight = 10;
                            this.router.navigateByUrl('/pages/farm/edit/' + this.editable);
                            const currentLocation = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
                            localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
                            // this.centerOnLocation();
                        }
                    });
                });
            });
        });
        this.globalRegistryService.systemData.lots.forEach((eachLot: LotModel) => {
            eachLot.coords.coordinates.forEach((farmFilter: (number[])[]) => {
                farmFilter.forEach((farmFilter2: number[]) => {
                    farmFilter2.forEach((farmFilter3: number) => {
                        if (farmFilter3 === poligon.points[0].lat) {
                            this.editable = eachLot.id;
                            this.editableCoords = eachLot.coords.coordinates[0];
                            this.lotService.selectLot.next(eachLot.id);
                            this.mapService.centerMapOnPolygon(poligon);
                            poligon.isEditable = true;
                            poligon.strokeWeight = 10;
                            this.router.navigateByUrl('/pages/lot/edit/' + this.editable);
                            const currentLocation = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
                            localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
                            // this.centerOnLocation();
                        }
                    });
                });
            });
        });
        this.editableCoords = this.multiArrayUnique(this.editableCoords);
        this.markerCoordinates = this.editableCoords;
        this.setMapMarkers();
        this.checkboxArray();
        this.dynamicCheckboxCreate();
        this.redrawPolygon();
    }

    public polyHover($event: PolyMouseEvent, poligon: MapPolygonInterface): void {
        poligon.strokeWeight = 10;
    }

    public polyMouseOut($event: PolyMouseEvent, polygon: MapPolygonInterface): void {
        polygon.strokeWeight = 5;
    }

    public get coordCheckboxes(): UntypedFormArray {
        return this.formCoords.get('coords');
    }

    public checkboxFormBuilder(): UntypedFormArray {
        const arr = this.checkboxArr.map((coords: CoordCheckbox) => {
            return this.fb.control(coords.selected);
        });
        return this.fb.array(arr);
    }

    public selectAllCheckboxes(): void {
        let checker = true;
        this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
            checker = checker && checkboxItem.value;
        });
        if (!checker) {
            this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
                checkboxItem.setValue(true);
            });
        } else {
            this.coordCheckboxes.controls.forEach((checkboxItem: AbstractControl) => {
                checkboxItem.setValue(false);
            });
        }

    }

    public getCheckboxIndex($event: Event, index: number): void {
        this.checkedBoxes.push(index);
    }

    public checkboxArray(): void {
        this.checkboxArr = [];
        this.editableCoords.map((coords: number[], index: number) => {
            this.checkboxArr.push({id: index, coords, selected: false});
        });
    }

    // Delete selected coordinates
    private deleteCoords(value: any): void {
        const selectedIndex = [];
        const coordinates = {lat: this?.editableCoords[0][0], lng: this?.editableCoords[0][1]};
        value.coords.forEach((selected: boolean, i: number) => {
            if (selected === true) {
                selectedIndex.push(i);
            }
        });
        this.editableCoords = this.arrayRemove(this.editableCoords, selectedIndex);
        this.polygonSubmit();
        if (this.editableCoords.length === 0) {
            this.mapService.mapPolygons = [];
        }
        this.mapService.mapFocusPoint.lat = coordinates.lat;
        this.mapService.mapFocusPoint.lng = coordinates.lng;
        this.setMapMarkers();
        this.checkboxArray();
        this.dynamicCheckboxCreate();
        this.redrawPolygon();
    }

    private importCoords(results: any): void {
        const importedCoords: number[][] = [];
        results.forEach((record: any) => {
            importedCoords.push([Number.parseFloat(record.lat), Number.parseFloat(record.lng)]);
        });
        this.editableCoords = this.multiArrayUnique(importedCoords);
        this.polygonSubmit();
        // this.editableCoordsBackup = this.editableCoords;
        this.mapService.mapFocusPoint.lat = this.editableCoords[0][0];
        this.mapService.mapFocusPoint.lng = this.editableCoords[0][1];
        this.redrawPolygon();
        this.checkboxArray();
        this.dynamicCheckboxCreate();
        this.setMapMarkers();
    }

    private dynamicCheckboxCreate(): void {
        this.formCoords = this.fb.group({
            coords: this.checkboxFormBuilder()
        });
    }

    private exportCoords(): void {
        if (this.editableCoords.length > 0) {
            const items = [];
            this.editableCoords.forEach((line: number[]) => {
                const csvLine = {
                    lng: line[1],
                    lat: line[0]
                };
                items.push(csvLine);
            });
            this.csvDataService.exportToCsv('coords.csv', items);
        }
    }

     private arrayRemove(arr: Array<Array<number>>, index: number[]): Array<Array<number>> {
        return arr.filter((value: number[], ind: number) => {
            return !index.includes(ind);
        });
    }

    private fileChangeListener($event: any): void {
        // Select the files from the event
        const files = $event.srcElement.files;
        // Parse the file you want to select for the operation along with the configuration
        this.ngxCsvParser.parse(files[0], {header: this.header, delimiter: ','})
            .pipe().subscribe((result: Array<any>) => {
            this.csvRecords = result;
            this.importCoords(result);
        }, (error: NgxCSVParserError) => {
            console.log(error);
        });
    }

    private redrawPolygon(): void {
        const editablePoints: MapPointInterface[] = this.editableCoords.map((coords: number[]) => {
            const poly: MapPointInterface = {};
            poly.lat = coords[0];
            poly.lng = coords[1];
            return poly;
        });
        this.mapService.mapPolygons = [];
        const polygon: MapPolygonInterface = {
            points: editablePoints,
            strokeColor: this.isFarm ? '#a31f1f' : '#ffff00',
            fillColor: this.isFarm ? '#de3333' : '#ffff00',
            strokeWeight: 5,
            isEditable: true,
        };
        this.mapService.mapPolygons.push(polygon);
        this.mapService.polyPathChange.emit();
        this.mapService.onLastPolyPathChange.emit(polygon);
        this.mapService.lastPoligonTouchedSelectedArea = MapHelper.getPolygonArea(polygon.points, 'ha');
        this.polyLength = this.mapService.getLengthOfPolygon(polygon);
    }

    private editableFarmOrLotSelect(farmOrLot: number, type: string): void {
        this.editable = farmOrLot;
        this.editableCoords = [];
        let editable: FarmModel[] | LotModel[];

        if (type === 'farm') {
            editable = this.globalRegistryService.systemData.farms.filter((selected: FarmModel) => {
                return selected.id === farmOrLot;
            });
            if(editable[0]) {
                this.editableCoords = editable[0].coords.coordinates[0];
            }
        } else if (type === 'lot') {
            editable = this.globalRegistryService.systemData.lots.filter((selected: LotModel) => {
                return selected.id === farmOrLot;
            });
            if(editable[0]) {
                this.editableCoords = editable[0].coords.coordinates[0];
            }
        }
        this.mapService.currentLocation = {
            lat: this.latitude,
            lng: this.longitude,
        };
        this.editableCoords = this.multiArrayUnique(this.editableCoords);
        this.editableCoordsBackup = this.editableCoords;
        this.markerCoordinates = this.editableCoords;
        this.checkboxArray();
        this.dynamicCheckboxCreate();
        this.redrawPolygon();
        localStorage.setItem('currentLocation', JSON.stringify(this.mapService.currentLocation));
        this.mapService.onCurrentLocationLoaded.emit();
        this.mapService.centerOnLocation();
    }

    private setMapMarkers(): void {
        this.mapService.mapMarkers = [];
        this.editableCoords.forEach((coord: number[], index: number) => {
            if ((this.markerCoordinates.length === this.editableCoords.length) ? (this.markerCoordinates[index][0] === coord[0]) : true) {
                this.mapService.mapMarkers.push({lat: coord[0], lng: coord[1], label: this.getNumber(index + 1), iconUrl: './assets/images/fap/Pin-white.svg'});
            } else {
                this.mapService.mapMarkers.push({lat: coord[0], lng: coord[1], label: this.getNumber(index + 1), iconUrl: './assets/images/fap/Pin-green.svg'});
                this.changedCoordinate = index;
            }
        });
        this.markerCoordinates = this.editableCoords;
    }

    private polygonSubmit(): void {
        if (this.editableCoords.length >= 3) {
            this.navService.submitFarm.next(true);
        } else {
            this.navService.submitFarm.next(false);
        }
    }

    private multiArrayUnique(arr: number[][]): number[][] {
        const uniques = [];
        const itemsFound = {};
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < arr.length; i++) {
            const stringifies = JSON.stringify(arr[i]);
            if (itemsFound[stringifies]) {
                continue;
            }
            uniques.push(arr[i]);
            itemsFound[stringifies] = true;
        }
        return uniques;
    }

    getCoords($event) {
        const editRoute = this.router.url.split('/');
        if((editRoute[2] == 'devices' && editRoute[3] == 'edit') || (editRoute[2] == 'notes' && editRoute[3] == 'edit')) {
        this.mapService.mapMarkers.pop();
        this.notesService.notesCoords.next($event.coords);
        console.log($event);
        const markerInterface = {
            lat: $event.coords.lat,
            lng: $event.coords.lng,
            iconUrl: this.mapIconUrl
          }
          this.mapService.mapMarkers.push(markerInterface);
        }
    }

    calculateRadius(area: number): number {
        if (area <= 0) {
          area = 1;
        }
        const radius = Math.sqrt(area / Math.PI) * 5;
        return radius;
      }

    navigate(link) {
        this.router.navigate(['pages/'+link]);
    }

}

export interface ObjectInterface {
    coord?: string;
    icon?: string;
}