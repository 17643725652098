import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter
} from '@angular/core';
import { ActivityModel } from '../../../../../core/models/activity/activity.model';
import { ActivityGroupModel } from '../../../../../core/models/activity/activity-group.model';
import { ActivityHelper } from '../../../../../core/heplers/activity.helper';

@Component({
    selector: 'fap-activity-group-row',
    templateUrl: './fap_activity-group-row.component.html',
    styleUrls: ['./fap_activity-group-row.component.scss']
})
export class FapActivityGroupRowComponent implements OnChanges {

    @Input()
    public activities: Array<ActivityModel>;

    @Input()
    public activityGroup: ActivityGroupModel;

    @Output()
    public deleteActivityGroup: EventEmitter<number> = new EventEmitter();

    public groupActivities: Array<ActivityModel>;

    public expanded = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'activities') || Object.prototype.hasOwnProperty.call(changes, 'activityGroup')) {
            if(this.activities && this.activityGroup) {
                this.groupActivities = ActivityHelper.getGroupedActivities(this.activities, this.activityGroup.id);
            }
        }
    }

}
