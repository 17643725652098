import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnInit,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CropTypeModel } from '../../../../core/models/type/crop-type.model';
import { FapModalComponent } from '../../../../shared/partials';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import {
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
    FormGroup
} from '@angular/forms';
import { CropInterface } from '../../../../core/services/api/crop/data/crop.interface';
import { CropTypeInterface } from '../../../../core/services/api/types/data/crop-type.interface';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { FarmModel } from '../../../../core/models/farm/farm.model';
import { PartyModel } from '../../../../core/models/party/party.model';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TranslateService } from '@ngx-translate/core';
import { StockIncomeInterface } from '../../../../core/interfaces/stock/stock-income.interface';
import { StockOutcomeInterface } from '../../../../core/interfaces/stock/stock-outcome.interface';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { CropModel } from '../../../../core/models/crops/crop.model';
import { ResponseModel } from '../../../../core/models/response.model';
import { TranslationModel } from '../../../../core/models/type/translation-type.model';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { StockItemModel } from '../../../../core/models/stock/stock-item.model';
import { ProductModel } from '../../../../core/models/stock/product.model';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import { UnitModel } from '../../../../core/models/units/unit.model';
import { NewUnitModel } from '../../../../core/models/units/new-unit-model';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { PartyService } from '../../../../core/services/api/company/party.service';
import { UnitInterface } from '../../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../../core/services/api/units/unit.service';
import { take } from 'rxjs/operators';
import { PersonModel } from '../../../../core/models/person/person.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    selector: 'fap-culture-edit',
    templateUrl: './fap-culture-edit.component.html',
    styleUrls: ['./fap-culture-edit.component.scss'],
    providers: [DecimalPipe]
})
export class FapCultureEditComponent implements OnChanges,OnInit, OnDestroy {
    @ViewChild('addProductModal')
    public addProductModal: FapModalComponent;
    @ViewChild('addImageModal')
    public addImageModal: FapModalComponent;
    @ViewChild('addResourceTypeModal')
    public addResourceTypeModal: FapModalComponent;
    @ViewChild('addEditUnitModal')
    public addEditUnitModal: FapModalComponent;
    @ViewChild('mySelect') mySelect;
    @Input()
    public showMarkUnmarkAsEndedBtn = false;

    @Input()
    public showDeleteCultureBtn = false;

    @Input()
    public farmId?: number;

    @Input()
    public farms?: Array<FarmModel>;

    @Input()
    public parties?: Array<PartyModel>;

    @Input()
    public resourceTypes?: Array<ResourceTypeModel>;

    @Input()
    public crop: CropModel;

    @Output()
    public cropChanged: EventEmitter<CropInterface> = new EventEmitter();

    @Output()
    public deletedCulture: EventEmitter<void> = new EventEmitter();

    @Output()
    public addStockOutcome: EventEmitter<StockOutcomeInterface> = new EventEmitter();

    @Output()
    public addStockIncome: EventEmitter<StockIncomeInterface> = new EventEmitter();

    @ViewChild('addEditPartyModal')
    public addEditPartyModal: FapModalComponent;

    @Output()
    public addedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public updatedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    public markedAsEnded = false;

    public selectedCropType: CropTypeModel;

    public enableAddEdit = true;

    public enableDelete = true;

    @ViewChild('addEditCropTypeModal')
    public addEditCropTypeModal: FapModalComponent;

    public cultureForm: FormGroup;

    public translatedNames: Array<TranslationModel>;
    public langString: string;
    public subscription: Subscription = new Subscription();
    
    public product: ProductModel;
    public products = [];
    public ingredients: Array<IngredientModel> = [];
    public selectedResource: ResourceTypeModel;
    public selectedUnit: UnitModel;
    public currentUnit: UnitModel;
    public parentUnit: UnitModel;
    public parentResource: ResourceTypeModel;
    public images =  [];
    public image: File;
    imageSrc: string;
    public changedImage: boolean;
    public productImages:any = [];
    public items = [];
    public selectedParty: PartyModel;
    public units: Array<UnitModel> = [];
    public unitForm: FormGroup;
    public selectedUnitId: number;
    public localIngredients: Array<IngredientModel> = [];
    public localIngredientsId: number[] = [];
    public bill;
    public quantityPack: NewUnitModel;
    public packUnit: NewUnitModel;

    public rowDistance: NewUnitModel;
    public plantDistance: NewUnitModel;
    public searchKeyword = '';
    public addProductModalButtonPrimaryInterface: FapModalButtonInterface;
    public addProductModalButtonSecondaryInterface: FapModalButtonInterface;
    public addImageModalButtonPrimaryInterface: FapModalButtonInterface;
    public addImageModalButtonSecondaryInterface: FapModalButtonInterface;
    public imgWidth: number;
    public imgHeight: number;
    public orientation: string;
    public stockItems: Array<StockItemModel> = [];
    public entries = [];
    public max = 9999;
    public resDocId: any;
    public resDocItemIds: Array<any> = [];
    public isLoading = false
    public productUnit;
    public persons: Array<PersonModel>;
    public lotId: number;
    public localDocId: any;
    public resourcesDoc: any;
    public areaUnits: Array<UnitModel>= [];
    public uForecastedCost = 0;
    public pForecastedCost = 0;
    public uCurrentCost = 0;
    public pCurrentCost = 0;
    public uSavings = 0;
    public pSavings = 0;
    public yield = 0;
    public currency;
    public getMore = true;
    public nextToken: { offset: number; limit: number; search: string } = null;
    public limit = 20;
    public newProduct;

    public documentFarm: number = null;
    public p_items;
    public r_items;

    @Output()
    public deleteProduct: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedProduct: EventEmitter<any> = new EventEmitter();

    @Output()
    public updatedProduct: EventEmitter<any> = new EventEmitter();

    @Output()
    public updatedDoc: EventEmitter<any> = new EventEmitter();

    @ViewChild('mys') mys;
    @ViewChild('fileUploader') fileUploader:ElementRef;
        
    public productId;
    @ViewChild('addEditProductModal')
    public addEditProductModal: FapModalComponent;
    @ViewChild('typeFilter')
    public typeFilter: ElementRef
    public myMath = Math;
    public cropFarm;

    constructor(private typesService: TypesService,
                private toastyService: ToastrService,
                public globalRegistry: GlobalRegistryService,
                public formBuilder: UntypedFormBuilder,
                public stockService: StockService,
                public translateService: TranslateService,
                public cropService: CropService,
                public router: Router,
                public route: ActivatedRoute,
                private _decimalPipe: DecimalPipe,
                public navService: NavService, public confirmModalService: ConfirmModalService, public companyService: CompanyService, public partyService: PartyService, public unitService: UnitService, public widgetService: WidgetsService, public cacheService: CacheResolverService) {
        
        this.translatedNames = globalRegistry.systemData.translations;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'crop') && this.crop) {
            this.initForm();
        }
    }

    public ngOnInit(){
      this.widgetService.setSize(6);
      this.initForm();
      this.initunitForm();
      this.getProducts();
      this.units = this.globalRegistry.systemData.units;
      this.units.filter(u => {
        if(u.genre === 'm') {
          this.areaUnits.push(u);
        }
      });
      // this.widgetService.setSize(4);
      this.persons = this.globalRegistry.systemData.persons;
      this.farms = this.globalRegistry.systemData.farms;
      this.lotId = this.route.snapshot.params.lotId;
      [this.enableAddEdit,this.enableDelete] = this.globalRegistry.getAccessRights("lots");
      this.langString = localStorage.getItem('language');
      this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
        if(lang) {
          this.langString = lang;
        }
      }));
      this.parties = this.globalRegistry.systemData.parties;
        if(this.rowDistance) {
          console.log(this.rowDistance);
      }
    }

    public addCropTypeModal(cropType?: CropTypeModel): void {
        this.selectedCropType = cropType;
        this.addEditCropTypeModal.showModal();
    }

    getCropFarm(lotId) {
      const lot = this.globalRegistry.systemData.lots.filter(l => {
          if(l.id === lotId) {
              return l;
          }
      });
      this.cropFarm = lot[0].farm;
      console.log(this.cropFarm);
      return
      
  }

  public goToBill() {
    if(this.crop) {
        if(this.crop.forecastDoc) {
            this.router.navigate(['/pages/stock/bill/'+this.crop.forecastDoc], {queryParams: {type: 'estimation_note', from: 'lot', lotId: this.lotId, farm: this.cropFarm, new_doc: 'false'}});
        } else {
            this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'estimation_note', from: 'lot', farm: this.cropFarm, lotId: this.lotId, new_doc: 'true'}});
        }
    } else {
        this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'estimation_note', from: 'lot', new_doc: 'true', lotId: this.lotId, farm: this.cropFarm}});
    }
}

public setUnit(unit) {
  this.cultureForm.get('distUnit').setValue(unit.value);
}

    public initForm(): void {
        this.cultureForm = this.formBuilder.group({
            product: [this.crop && this.crop.product ? this.crop.product.id : null, Validators.compose([Validators.required])],
            progress: [this.crop && this.crop.progress ? this.crop.progress : 0, []],
            start: [this.crop && this.crop.start ? this.crop.start : new Date(), []],
            rowDistance: [this.crop && this.crop.distRow ? this.crop.distRow : null, []],
            plantDistance: [this.crop && this.crop.distPlant ? this.crop.distPlant : null, []],
            distUnit: [this.crop && this.crop.distUnit ? this.crop.distUnit : null, []],
            usedForecastedCost: [null],
            producedForecastedCost: [null],
            forecastedDoc: [this.crop && this.crop.forecastDoc ? this.crop.forecastDoc: null, []],
            usedCurrentCost: [null],
            producedCurrentCost: [null],
            usedSavings: [null],
            producedSavings: [null],
            yield: [null]
        });
        
        
        
        this.cultureForm.valueChanges.subscribe((): void => {
            this.emitCultureChanged();
        });
        
        if(this.crop) {
          if(this.crop.lot) {
            this.getCropFarm(this.crop.lot);
          }
          // console.log(this.crop);
          if(this.crop.forecastDoc) {
            this.localDocId = this.crop.forecastDoc;
            this.getDocDetails(this.localDocId);
          } else {
            if(this.route.snapshot.queryParams.doc) {
              this.localDocId = this.route.snapshot.queryParams.doc;
              this.cultureForm.get("forecastedDoc").setValue(this.localDocId);
              this.emitCultureChanged();
              this.updatedDoc.emit(this.localDocId);
              // this.getDocDetails(this.localDocId);
          }
          }        
        }
    }

    public getForecastInfo(id) {
      
      this.subscription.add(this.cropService.getForecastInfo(id).subscribe(data => {
        this.r_items = data.body.used.resources;
        this.p_items = data.body.produced.resources;
        
        this.yield = data.body.yield;

        this.uForecastedCost = this.myMath.abs(data.body.used.forecasted);
        this.uCurrentCost = this.myMath.abs(data.body.used.real);
        this.uSavings = this.uForecastedCost - this.uCurrentCost;

        this.pForecastedCost = this.myMath.abs(data.body.produced.forecasted);
        this.pCurrentCost = this.myMath.abs(data.body.produced.real);
        this.pSavings = this.pForecastedCost - this.pCurrentCost;

        this.cultureForm.get("usedForecastedCost").setValue(this.transformDecimal(this.myMath.abs(this.uForecastedCost)));
        this.cultureForm.get("producedForecastedCost").setValue(this.transformDecimal(this.myMath.abs(this.pForecastedCost)));
        this.cultureForm.get('usedCurrentCost').patchValue(this.transformDecimal(this.myMath.abs(this.uCurrentCost)));
        this.cultureForm.get('producedCurrentCost').patchValue(this.transformDecimal(this.myMath.abs(this.pCurrentCost)));
        this.cultureForm.get('usedSavings').patchValue(this.transformDecimal(this.uSavings));
        this.cultureForm.get('producedSavings').patchValue(this.transformDecimal(this.pSavings));
        this.cultureForm.get('yield').patchValue(this.yield);
      }));
      return
  }

  transformDecimal(num) {
    return this._decimalPipe.transform(num, '1.2-2', 'en-US');
  }

    public getDocDetails(id) {
      this.subscription.add(this.stockService.getDoc(id).subscribe(data => {
          this.resourcesDoc = data.body.results;
          console.log(data.body.results);
          this.currency = data.body.results.currency;
          if(data) {
              this.getForecastInfo(this.crop.id);
              return
          }
      }))
  }

    public deleteCropType(cropTypeId: number): void {
        this.typesService.deleteCropType(cropTypeId).subscribe((): void => {
            this.cultureForm.controls['cropType'].setValue(null);
            this.globalRegistry.reloadCropTypes();
            this.globalRegistry.reloadLots();
            this.toastyService.success(this.translateService.instant('crops.cropsType.messages.deleteSuccess'));
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failDelete'));
        });
    }

    public addCropType(cropType: CropTypeInterface): void {
        this.addEditCropTypeModal.hideModal();
        this.typesService.addCropType(cropType).subscribe((response: ResponseModel<CropTypeModel>): void => {
            this.globalRegistry.reloadCropTypes();
            this.cultureForm.controls['cropType'].setValue(response.model.id);
            this.toastyService.success(this.translateService.instant('crops.cropsType.messages.createSuccess'));
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failCreate'));
        });
    }

    public updateCropType(cropType: CropTypeInterface): void {
        this.addEditCropTypeModal.hideModal();
        this.typesService.updateCropType(cropType).subscribe((response: ResponseModel<CropTypeModel>): void => {
            this.globalRegistry.reloadCropTypes();
            this.cultureForm.controls['cropType'].setValue(response.model.id);
            this.toastyService.success(this.translateService.instant('crops.cropsType.messages.updateSuccess'));
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failUpdate'));
        });
    }

    public emitCultureChanged(): void {
        this.cropChanged.emit({
            lot: this.lotId,
            product: this.cultureForm.value['product'],
            distRow:  this.cultureForm.value['rowDistance'],
            distPlant: this.cultureForm.value['plantDistance'],
            distUnit: this.cultureForm.value['distUnit'],
            start: this.cultureForm.value['start'] ? new Date(this.cultureForm.value['start']).toISOString().substr(0, 10) : null,
            progress: this.cultureForm.value.progress,
            end: this.markedAsEnded ? new Date(Date.now()).toISOString().substr(0, 10) : null,
            forecastDoc: this.localDocId
        });
    }

      ngOnDestroy(): void {
          this.subscription.unsubscribe();
          this.widgetService.setSize(6);
      }
    
      validateAllFormFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
          const control = formGroup.get(field);             
          if (control instanceof UntypedFormControl) {             
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {        
            this.validateAllFormFields(control);            
          }
        });
      }
      
      getProducts() {
        this.subscription.add(this.stockService.getProducts({ group: 'SEEDS', limit: this.limit, search: this.searchKeyword }).subscribe(data => {
          this.products = data.body.results;
          this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
                if(this.crop && this.crop.product) {
                  if(this.products.length > 0) {
                  if(this.products.findIndex(p => p.id == this.crop.product.id) == -1) {
                    this.subscription.add(this.stockService.getProduct(this.crop.product.id).subscribe(data => {
                      this.newProduct = data.body.results;
                      this.products.unshift(this.newProduct);
                      this.cultureForm.controls['product'].setValue(this.newProduct.id);
                      console.log(this.products);
                    })
                  )}
                  }
                }
        }))
      }
    
      onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          console.log("End");
          this.scrolledDown();
        }
    }
    
      paramsToObject(entries) {
        const result = {}
        for(const [key, value] of entries) {
          result[key] = value;
        }
        console.log(result);
        return result;
      }
    
      scrolledDown() {
        // console.log('scrolled');
        if(this.getMore) {
          this.nextToken &&
            this.subscription.add(this.stockService.getProducts(this.nextToken).subscribe((data) => {
              console.log(data);
              if(this.products) {
                this.products = [...this.products, ...data.body.results];
              } else {
                this.products = data.body.results;
              }
              if(data.body.next == null) {
                this.getMore = false;
                return
              } else {
                const url = data.body.next.split('?')
                const urlParams = new URLSearchParams(url[1]);
                const entries = urlParams.entries();
                const params = this.paramsToObject(entries);
                console.log(params);
                if(this.nextToken.offset != params['offset']) {
                this.nextToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
                } else {
                  return
                }
              }
            }));
          } else {
            return
          }
      }

      filterProduct(value) {
        this.subscription.add(this.stockService.getProducts({search: value}).subscribe(data => {
          console.log(data);
          this.nextToken = data.body.next
                ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
                : null;
                if(this.nextToken) this.getMore = true;
          this.products = data.body.results;
        }))
      }
    
      public showProductModal(): void {
      this.productId = -1;
      console.log(this.productId);
      this.addEditProductModal.showModal();
    }
    
    public hideProductModal(): void {
      this.addEditProductModal.hideModal();
    }

    productDelete(productId) {
      this.stockService.deleteProduct(productId).subscribe(data => {
        this.cultureForm.controls['product'].reset();
        console.log(data);
        this.products = this.products.filter(product => {
          return product.id !== productId
      });
      })
    }

    public productEdit(product) {
      this.cultureForm.controls['product'].setValue(product.id);
      this.productId = product.id
      console.log(this.productId);
      this.addEditProductModal.showModal();
    }
    
    public addProductAction(ev) {
      console.log(ev);
      this.cultureForm.controls['product'].setValue(ev.id);
      this.addedProduct.emit(ev);
      
      this.productUnit = this.product.unit;
      this.products.findIndex((product) => {
          if(product.id === ev.id) {
            return product = ev;
          }
      })
      this.mys.close();
      this.typeFilter.nativeElement.value = ''
      this.stockService.getProducts().subscribe(data => {
        this.products = data.body.results;
        const productExist = data.body.results.some((p) => p.id === ev.id);
        if(!productExist) {
          this.products.unshift(ev);
          this.cultureForm.controls['product'].setValue(ev.id);
        }
      })
      this.addEditProductModal.hideModal();
    }
    
    public updateProductAction(ev) {
      console.log(ev);
      console.log(this.products);
      this.updatedProduct.emit(ev);
      
      const target = this.products.find((obj) => obj.id === ev.id);
      Object.assign(target, ev);
      this.mys.close();
      
      this.addEditProductModal.hideModal();
    }

  

  // public onDeleteParty(party: PartyModel): void {
  //     this.selectedParty = party;
  //     if (this.productForm.controls['producer'].value === this.selectedParty.id) {
  //         this.productForm.controls['producer'].setValue(null);
  //     }
  // }

  public onDeleteParty(party: PartyModel): void {
    this.partyService.deleteParty(party.id).pipe(take(1)).subscribe(
        (): void => {
            this.getParties();
            // this.globalRegistry.reloadParties();
            this.toastyService.success(this.translateService.instant('people.party.partyDeletedSuccessfully'));
        }, (): void => {
            this.toastyService.success(this.translateService.instant('people.party.errorDeletingParty'));
        }
    );
}
    
      public selectResourceType(resource?: ResourceTypeModel, parent?:  ResourceTypeModel): void {
        this.selectedResource = resource;
        this.parentResource = parent ? parent : null;
        this.addResourceTypeModal.showModal();
    }
    
    public deleteResourceType(resourceId?: number): void {
      console.log(resourceId);
      this.typesService.deleteResourceType(resourceId).subscribe(data => {
        console.log(data);
      });
    }
    
    public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
      if(unit?.locked) {
        return;
    }
      this.currentUnit = unit;
      this.parentUnit = parent ? parent : null;
      if(genre) {
        console.log(genre);
        this.units = this.globalRegistry.systemData.units.filter(unit => {
          return unit.genre == genre
        });
      }
      // if(!unit && !parent) {
      //   this.mySelect.close();
      //   this.units = this.globalRegistry.systemData.units;
      // }
      this.initunitForm();
      this.addEditUnitModal.showModal();
    }
    
    public onDeleteUnit(unit: UnitModel): void {
      if(unit?.locked) {
        return;
    }
     
      this.confirmModalService.openConfirmModal(
          this.translateService.instant('confirmDelete'),
          this.translateService.instant('units.deleteUnitConfirmMessage')
      );
      this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
          if (confirmed) {
              this.deleteUnit(unit.id);
          }
      });
    }
 
    public onUnitAction(unitId: number): void {
      this.selectedUnitId = unitId;
      this.addEditUnitModal.hideModal();
    }
    
    getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
        return translation
    }
    }
    
    // public getImages() {
    //   let url = this.companyService.getUrl('images/')
    //   this.cacheService.delete(url+'group=products');
    //     this.subscription.add(this.companyService.getCompanyImages({group: 'products'}).subscribe((response): void => {
    //       this.images = response.body["results"];
    //       console.log(this.images);
    //  }))
    // }
    
    public getParties() {
        this.subscription.add(this.partyService.getParties().subscribe((response: ResponseModel<PartyModel[]>): void => {
        this.parties = response.body.results;
        console.log(response);
    }));
    }
     
    public submit(): void {
    
      if(this.unitForm.dirty) {
          const unit: UnitInterface = {
              name: this.unitForm.controls['name'].value,
              shortName: this.unitForm.controls['shortName'].value,
              genre: this.unitForm.controls['genre'].value
          };
    
          if(typeof this.unitForm.controls['parent'].value !== 'string') {
              unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                  null :
                  this.unitForm.controls['parent'].value;
          }
    
          if(this.currentUnit) {
              this.updateUnit({...{id: this.currentUnit.id}, ...unit});
              this.globalRegistry.reloadUnits();
          }
          else {
              this.addUnit(unit);
              this.globalRegistry.reloadUnits();
          }
          this.getUnits()
      }
    }
    
    public getUnits() {
        this.subscription.add(this.unitService.getUnits().subscribe(data => {
        this.units = data.body.results;
      }))
    }
    
    private initunitForm(): void {
      this.unitForm = this.formBuilder.group({
          name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(3),
                  Validators.maxLength(100)
              ])
          ],
          shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                  Validators.required,
                  Validators.minLength(1),
                  Validators.maxLength(100)
              ])
          ],
          parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                  Validators.required
              ])
          ],
          genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                  Validators.required
              ])
          ]
      });
    }
    
    private addUnit(unit: UnitInterface): void {
        this.subscription.add(this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
          this.toastyService.success(this.translateService.instant('units.unitAddSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(response.model.id);
      },
      (): void => {
          this.toastyService.error(this.translateService.instant('units.unitAddError'));
      }));
    }
    
    private updateUnit(unit: UnitInterface): void {
      this.unitService.updateUnit(unit).subscribe((): void => {
          this.toastyService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
          this.globalRegistry.reloadUnits();
          this.onUnitAction(this.currentUnit.id);
    
      },
      (): void => {
          this.toastyService.error(this.translateService.instant('units.UnitUpdateError'));
      });
    }
    
    public deleteUnit(unitId: number): void {
      this.unitService.deleteUnit(unitId).subscribe((): void => {
          this.toastyService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
          this.globalRegistry.reloadUnits();
      },
      (): void => {
          this.toastyService.error(this.translateService.instant('units.UnitDeleteError'));
      });
    }
    
    public getIngredients() {
        this.subscription.add(this.stockService.getIngredients().subscribe(data => {
        this.ingredients = data.body.results;
      }))
    }
    
    public updatedIngredient(ingredient: IngredientModel): void {
      Object.assign(this.ingredients.find((ingr: IngredientModel): boolean => ingr.id === ingredient.id), ingredient);
      // this.updateCropTypeTable();
    }
    
    public createdIngredient(ingredient: IngredientModel): void {
      this.ingredients.push(ingredient);
      this.localIngredients.push(ingredient);
      this.localIngredientsId.push(ingredient.id);
      console.log(ingredient);
      // this.updateCropTypeTable();
    }
    
    public deleteLocalIngredient(ingredientId: number): void {
      this.localIngredients = this.localIngredients.filter(ingredient => {
        return ingredient.id !== ingredientId
    });
    const ids = [];
    if(this.localIngredients.length == 0) {
      return
    }
    this.localIngredients.forEach(element => {
      ids.push(element.id);
    });
    console.log(ids);
    console.log(this.localIngredients);
    if(ids.length > 0) {
      this.selectIngredient(ids);
    }
    }
    
    public editIngredient(ingredient) {
      console.log(ingredient);
    }
    
    public deleteIngredient(ingredientId) {
      this.stockService.deleteIngredient(ingredientId).pipe(take(1)).subscribe((): void => {
          this.ingredients = this.ingredients.filter((ingredient: IngredientModel): boolean => ingredient.id !== ingredientId);
          this.toastyService.success(this.translateService.instant('stock.ingredients.successDeletedMessage'));
      });
    }
    
    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.unitForm.controls[controlName];
      if (!control) {
          return false;
      }
    
      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
    }
    
    public refreshIngredientList(ids) {
      console.log(ids);
    }
    
    public selectIngredient(value) {
      console.log(value);
      const localIngredients = [];
      this.localIngredientsId = value
      this.ingredients.forEach(ingredient => {
        this.localIngredientsId.forEach(ingredientId => {
          if(ingredient.id == ingredientId) {
            localIngredients.push(ingredient);
          }
        });
      });
      this.localIngredients = localIngredients;
    }

    public addPartyModal(): void {
        this.selectedParty = null;
        this.addEditPartyModal.showModal();
      }
    
      public addParty(ev) {
        this.parties.push(ev);
        // this.globalRegistry.reloadParties();
        this.addEditPartyModal.hideModal();
    }
    
     public addedOrUpdatedParty(ev) {
      const evArr = [ev];
      evArr.map(x => {
        const index = this.parties.findIndex(d=> d.id === x.id)
        this.parties[index] = x  
      });
      this.addEditPartyModal.hideModal();
    }
    
    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.cultureForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean =
            control.hasError(validationType) &&
            (control.dirty || control.touched);
        return result;
    }

    public editParty(party: PartyModel): void {
        this.selectedParty = party;
        this.addEditPartyModal.showModal();
    }

}
