import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { NavService } from '../../../../services/nav.service';
import { Router } from '@angular/router';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import moment from 'moment';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'fap-search-filter',
  templateUrl: './fap-search-filter.component.html',
  styleUrls: ['./fap-search-filter.component.scss']
})
export class FapSearchFilterComponent implements OnInit {
  @Input() public filterLabel: string = '';
  @Input() public exportLabel: string = '';
  @Input() public searchPlaceholder: string = '';
  @Input() public component: string = 'dashboard';

  @Input() public isDateFilterEnabled: boolean = false;
  @Input() public isSearchEnabled: boolean = false;
  @Input() public isDayFilterEnabled: boolean = false;
  @Input() public isExportFilterEnabled: boolean = false;

  @Output() public search: EventEmitter<any> = new EventEmitter<any>();
  @Output() public export: EventEmitter<any> = new EventEmitter<any>();
  @Output() public filter: EventEmitter<any> = new EventEmitter<any>();

  public filterObj: FormGroup;
  public searchFilter: FormControl = new FormControl();


  constructor(private sensorService: SensorService, public navServices: NavService, private router: Router, public globalRegistry: GlobalRegistryService,private dateAdapter: DateAdapter<Date>) {
    
  }

  ngOnInit(): void {
    // Calculate fourteen days from now and set to 00:00 hours
    const fourteenDaysFromNow = new Date();
    fourteenDaysFromNow.setDate(fourteenDaysFromNow.getDate() - 14);
    fourteenDaysFromNow.setHours(0, 0, 0, 0);

    // Calculate six months from now and set to 00:00 hours
    const sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() - 6);
    sixMonthsFromNow.setHours(0, 0, 0, 0);

    // Set end date to the current date and set to 23:59:59 hours
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    console.log(fourteenDaysFromNow);
    console.log(sixMonthsFromNow);

    // Set the from and to dates in the sensor service
    this.sensorService.setFromDate(this.formatDate(fourteenDaysFromNow));
    this.sensorService.setPostFromDate(this.formatDate(sixMonthsFromNow));
    this.sensorService.setPostToDate(this.formatDate(endDate));
    this.sensorService.setToDate(this.formatDate(endDate));
    this.sensorService.setAgg('day');

    // Initialize filter object with form controls
    this.filterObj = new FormGroup({
        start: this.component === 'dashboard' ? new FormControl(fourteenDaysFromNow) : new FormControl(sixMonthsFromNow),
        end: new FormControl(endDate),
        aggBy: new FormControl('day')
    });
}

  public formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      const milliseconds = ('00' + date.getMilliseconds()).slice(-3);
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  public setFromDate(date) {
    this.filterObj.get('start').setValue(date.value);
    // this.sensorService.setPostFromDate(this.formatDate(date.value))
  }

  public setToDate(date) {
      this.filterObj.get('end').setValue(date.value);
      // this.sensorService.setPostToDate(this.formatDate(date.value))
  }

  public changeDate(date: string) {
    const d = new Date(this.filterObj.value.end);
    const e = new Date(this.filterObj.value.end);
    d.setHours(0, 0, 0, 0);
    e.setHours(23, 59, 59, 999);

    let start: Date;
    let end: Date;

    if (date === 'hour') {
        d.setDate(d.getDate() - 1);
        start = new Date(d);
        end = new Date(e);
    }

    if (date === 'day') {
        d.setDate(d.getDate() - 14);
        start = new Date(d);
        end = new Date(e);
    }

    if (date === 'month') {
        d.setFullYear(d.getFullYear() - 1);
        start = new Date(d);
        end = new Date(e);
    }

    if (start && end) {
        this.filterObj.get('start').patchValue(this.formatDate(start));
        this.filterObj.get('end').patchValue(this.formatDate(end));
        console.log(this.filterObj.value);
    }
}

  setToday() {
    const today = this.dateAdapter.today();
    this.filterObj.controls['start'].setValue(today);
    this.filterObj.controls['end'].setValue(today);
  }

  public filterItems():void {
    console.log(this.searchFilter.value);
  }

  public clearSearchFilter(): void {
    this.searchFilter.reset("");
    const params = window.location.search
      ? this.globalRegistry.getQueryStringParams(window.location.search)
      : {};
    delete params["filter"];
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: "merge",
    });
    this.onSearchSubmit('');
  }

  public onSearchSubmit(value: string): void {
    console.log(value);
    this.router.navigate([], {
        queryParams: {filter: value},
        queryParamsHandling: 'merge'
    });
  }

}
