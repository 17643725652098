import { FapBaseModel } from '../../base/fap-base-model';

export class ResourcesModel extends FapBaseModel {
    public static fields: Array<string> = [
        'name',
        'colorType',
        'selectedFarm'
    ];

    public name: string;
    public colorType: string;
    public selectedFarm: any;
}
