export enum AttributeRelationEnum {
    String = 'string',
    Number = 'number',
    MinMaxRange = 'min-max',
    NumberList = 'list-number',
    StringList = 'list-string',
    Datetime = 'datetime',
    DateRange = 'date-range',
    WeightRange = 'weight-range',
    Entity = 'entity',
    EntityList = 'list-entity'
}
