<div class="table-wrap">
    <h4 class="table-header-title">
        {{ 'stock.stockOverview' | translate}}
    </h4>

    <div class="table-responsive">
        <table class="table table-striped" *ngIf="stockItems">
            <thead>
                <tr>
                    <th> {{ 'stock.index' | translate}} </th>
                    <th> {{ 'stock.stockType' | translate}} </th>
                    <th> {{ 'stock.quantity' | translate}} </th>
                </tr>
            </thead>
            <tbody>
                <ng-template ngFor [ngForOf]="stockItems" let-stockItem let-index="index">
                    <tr>
                        <td>{{ index + 1 }}</td>
                        <td>{{ stockItem.type.fullPathName }}</td>
                        <td>{{ stockItem.totalQuantity }}</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
        <div class="no-data" *ngIf="!stockItems || stockItems.length === 0">
            {{ 'stock.noData' | translate }}
        </div>
    </div>
</div>
