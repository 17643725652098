import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { ResponseModel } from '../../../../../core/models/response.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import {
    DeviceModel,
    ObjectService,
} from '../../../../../core/services/api/objects/object.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import {
    DeviceTypeModel,
    TypesService,
} from '../../../../../core/services/api/types/types.service';
import { NavService } from '../../../../../shared/services/nav.service';
import * as uuid from 'uuid';
import { SensorActionModel } from '../../../../../core/models/sensor/sensor_action.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../../../core/services/websocket/data.service';
import { take } from 'rxjs/operators';
import { FapModalComponent } from '../../../../../shared/partials';

@Component({
    selector: 'scheduler-edit',
    templateUrl: './scheduler-edit.component.html',
    styleUrls: ['./scheduler-edit.component.scss'],
})
export class SchedulerEditComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public title: string;

    @Input()
    public lots: Array<LotModel>;

    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public isPreviewMode = false;

    @Input()
    public sensorId: number;

    @Output()
    public submitSensor: EventEmitter<FormData> = new EventEmitter();

    @Output()
    public updateSensor: EventEmitter<FormData> = new EventEmitter();

    @Output()
    public deleteSensor: EventEmitter<any> = new EventEmitter();
    public subscriptions = new Subscription();
    public sensorsForm: UntypedFormGroup;
    public actionsForm: UntypedFormGroup;
    public nameForm: UntypedFormGroup;
    public lotGroup: any = [];
    public initialSensorForm: any;
    public devices: any = [];
    public device = null;
    public lot = null;
    public sensors: Array<SensorModel> = [];
    public sensor: SensorModel;
    public deviceTypes: Array<DeviceTypeModel> = [];
    public deviceType: any;
    public condition: any;
    public isEdit = false;
    public minDate = new Date();
    public actions: any = [];
    public tempActions: any = [];
    public currentActionId = null;
    public sensorData: any;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    @ViewChild('editActionModal')
    public editActionModal: FapModalComponent;
    @ViewChild('updateSensorNameModal')
    public updateSensorNameModal: FapModalComponent;

    constructor(
        public navService: NavService,
        public formBuilder: UntypedFormBuilder,
        public objectService: ObjectService,
        public globalRegistry: GlobalRegistryService,
        public sensorService: SensorService,
        public activatedRoute: ActivatedRoute,
        public typesService: TypesService,
        public router: Router,
        public toastr: ToastrService,
        public translateService: TranslateService,
        public dataService: DataService
    ) {}

    ngOnInit(): void {
      this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        console.log('total actions');
        console.log(localStorage.getItem('sensorActions'));
        console.log('new actions');
        console.log(localStorage.getItem('addAction'));
        console.log('to be updates actions');
        console.log(localStorage.getItem('updateActions'));
        console.log('to be deleted actions');
        console.log(localStorage.getItem('deleteActionIds'));

        this.farms = this.globalRegistry.systemData.farms;
        this.lots = this.globalRegistry.systemData.lots;
        const result = this.lots.map((item) => {
            return {
                ...item,
                farms: this.farms.filter((el) => el.id === item.farm),
            };
        });
        this.activatedRoute.params.subscribe((data) => {
            this.sensorId = data.sensorId;
        });
        const res = Array.from(
            result
                .reduce((lots, lot) => {
                    lots.set(
                        lot.farm,
                        (lots.get(lot.farm) || []).concat(lot)
                    );
                    return lots;
                }, new Map())
                .entries(),
            ([farm, data]) => ({ farm, data })
        );
        this.lotGroup = res;
        this.subscriptions.add(
            this.navService.emitSubmit.subscribe((value: boolean) => {
                value && this.submitForm();
            })
        );

        this.subscriptions.add(
            this.navService.emitDelete.subscribe((value: boolean) => {
                value && this.deleteCurrentSensor();
            })
        );
        this.initForm();
        this.initActionForm();
        this.initNameForm();
        this.getSensor();
        this.getData();
    }

    public getFarmName(farmid) {
        let farm;
        this.farms.forEach((element) => {
            if (element.id == farmid) {
                farm = element;
            }
        });
        return farm.name;
    }

    public submitForm() {
        console.log('total actions');
        console.log(localStorage.getItem('sensorActions'));
        console.log('new actions');
        console.log(localStorage.getItem('addAction'));
        console.log('to be updates actions');
        console.log(localStorage.getItem('updateActions'));
        console.log('to be deleted actions');
        console.log(localStorage.getItem('deleteActionIds'));

        if (localStorage.getItem('addAction')) {
            const newActionArr = JSON.parse(localStorage.getItem('addAction'));
            if (newActionArr.length > 0) {
                newActionArr.forEach((action) => {
                    this.subscriptions.add(
                        this.sensorService
                            .postSensorActions(action)
                            .subscribe((data) => {
                                console.log(data);
                            })
                    );
                });
            }
        }

        if (localStorage.getItem('updateActions')) {
            const updateActionArr = JSON.parse(
                localStorage.getItem('updateActions')
            );
            if (updateActionArr.length > 0) {
                updateActionArr.forEach((action) => {
                    if (action.id !== undefined) {
                        this.subscriptions.add(
                            this.sensorService
                                .updateSensorActions(action.id, action).pipe(take(1))
                                .subscribe(
                                    (updatedAction: ResponseModel<SensorActionModel>): void => {
                                        console.log(updatedAction);
                                    })
                        );
                    }
                });
            }            
        }

        if (localStorage.getItem('deleteActionIds')) {
            const deleteActionArr = JSON.parse(
                localStorage.getItem('deleteActionIds')
            );
            if (deleteActionArr.length > 0) {
                deleteActionArr.forEach((actionId) => {
                    if (typeof actionId != 'string') {
                        this.subscriptions.add(
                            this.sensorService
                                .deleteSensorActions(actionId)
                                .subscribe((data) => {
                                    console.log(data);
                                })
                        );
                    }
                });
            }
        }

        setTimeout(() => {
            this.router.navigate(['pages/scheduler']);
        }, 1000);
    }

    public deleteCurrentSensor() {
        console.log('delete current sensor')
    }

    private getSensor(): void {
        const param = { type: 'actuator' };
        this.subscriptions.add(
            this.subscriptions.add(
                this.sensorService
                    .getSensors(param)
                    .subscribe(
                        (sensors: ResponseModel<SensorModel[]>): void => {
                            this.sensors = sensors.model;
                            // console.log(sensors);
                            console.log(this.sensorId);
                            const sensor = this.sensors.filter(
                                (sensor: SensorModel) => {
                                    return sensor.id == this.sensorId;
                                }
                            );
                            console.log(sensor[0]);
                            this.sensor = sensor[0];
                            if (this.sensor) {
                                this.getActions();
                                this.getDevices();
                                if(this.sensor.lastValue) {
                                console.log(this.sensor.lastValue.value_float);
                                if(this.sensor.lastValue.value_float == 1) {
                                  this.sensorsForm.get('active').patchValue(true);
                                } else if(this.sensor.lastValue.value_float == 0) {
                                  this.sensorsForm.get('active').patchValue(false);
                                } else {
                                  return
                                }
                              }
                            }
                            // let sensorObj = sensor;
                            // if (sensorObj['0']) {
                            //     this.sensor = sensorObj['0'];
                            //     if(this.sensor.data.conditions) {
                            //     this.condition =
                            //         this.sensor.data.conditions['0']['0'].sensor +
                            //         ' ' +
                            //         this.sensor.data.conditions['0']['1'] +
                            //         ' ' +
                            //         this.sensor.data.conditions['0']['2'];
                            //     console.log(this.condition);
                            //     }
                            //     console.log(this.sensor);
                            //
                            // }
                        }
                    )
            )
        );
    }

    public getActions() {
        this.actions = [];
        const param1 = { sensor: this.sensorId };
        this.subscriptions.add(
            this.sensorService
                .getSensorActions(param1)
                .subscribe((data: ResponseModel<SensorActionModel[]>): void => {
                    if (data) {
                        console.log(data.model);
                        this.actions = data.model;
                        localStorage.setItem(
                            'sensorActions',
                            JSON.stringify(data.model)
                        );
                        this.tempActions = JSON.parse(
                            localStorage.getItem('sensorActions')
                        );
                        console.log(this.tempActions);
                    }
                })
        );
    }

    public getDevices() {
        this.subscriptions.add(
            this.objectService
                .getDevices()
                .subscribe((devices: ResponseModel<DeviceModel[]>): void => {
                    this.devices = devices.body.results;
                    const device = this.devices.filter(
                        (device: DeviceModel) => {
                            return device.id == this.sensor.device;
                        }
                    );
                    this.device = device[0];
                    console.log(this.device);
                    this.lot = this.device.lots[0];
                    console.log(this.lot);
                    if (this.device) {
                        this.getTypes();
                    }
                })
        );
    }

    getTranslation(translation) {
      const t =this.translatedNames.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
        return translation
    }
    }

    public getTypes() {
        this.subscriptions.add(
            this.typesService
                .getDeviceTypes()
                .subscribe((data: ResponseModel<DeviceTypeModel[]>): void => {
                    this.deviceTypes = data.body.results;
                    const deviceType = this.deviceTypes.filter((type) => {
                        return (type.id = this.device.type);
                    });
                    this.deviceType = deviceType[0];
                    console.log(this.deviceType);
                    this.initForm();
                })
        );
    }

    public resetForm(): void {
        this.sensorsForm.setValue(this.initialSensorForm);
        this.sensorsForm.markAsPristine();
    }

    private initForm(): void {
        const formBody: {} = {
            name: [this.sensor && this.sensor.name ? this.sensor.name : null],
            device: [this.device ? this.device.id : null],
            active: [this.sensor && this.sensor.lastValue.value_float == 1 ? true : false],
            type: [this.device ? this.device.type : null],
            condition: [this.sensor ? this.condition : null],
            run: [null],
            lot: [this.lot],
        };
        if (this.sensor) {
            console.log(this.sensor);
            formBody['id'] = this.sensor.id;
        }
        this.sensorsForm = this.formBuilder.group(formBody);
        this.initialSensorForm = this.sensorsForm.value;
        this.isPreviewMode
            ? this.sensorsForm.disable()
            : this.sensorsForm.enable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'sensor') && this.sensor) {
            this.initForm();
        }
    }

    public addAction() {
        this.initActionForm();
        console.log('Add action clicked');
        this.isEdit = false;
        this.editActionModal.showModal();
        console.log(localStorage.getItem('sensorActions'));
        this.actionsForm.reset();
    }

    public editAction(action) {
        console.log(action);
        this.currentActionId = action.id ? action.id : action.tempId;
        this.showUpdateModal();
        Object.keys(this.actionsForm.controls).forEach((key) => {
            this.actionsForm.get(key).setErrors(null);
        });
        const start = new Date(action.start).toISOString();
        const end = new Date(action.end).toISOString();
        this.actionsForm.get('start').patchValue(start);
        this.actionsForm.get('end').patchValue(end);
        this.actionsForm.get('minute').patchValue(action.minute);
        this.actionsForm.get('day').patchValue(action.day);
        this.actionsForm.get('hour').patchValue(action.hour);
        this.actionsForm.get('duration').patchValue(action.duration);
        this.actionsForm.get('month').patchValue(action.month);
        this.actionsForm.get('enabled').patchValue(action.enabled);
        this.actionsForm.get('dweek').patchValue(action.dweek);
        this.actionsForm.get('data').patchValue(action.data);
        this.actionsForm.get('type').patchValue(action.type);
        this.actionsForm.get('sensor').patchValue(this.sensorId);
    }

    public showUpdateModal() {
        this.isEdit = true;
        this.editActionModal.showModal();
        this.initActionForm();
    }

    public initActionForm() {
        this.actionsForm = new UntypedFormGroup({
            minute: new UntypedFormControl('', Validators.required),
            hour: new UntypedFormControl('', Validators.required),
            day: new UntypedFormControl('', Validators.required),
            month: new UntypedFormControl('', Validators.required),
            dweek: new UntypedFormControl('', Validators.required),
            duration: new UntypedFormControl(0, Validators.required),
            enabled: new UntypedFormControl(false, Validators.required),
            data: new UntypedFormControl({}, Validators.required),
            type: new UntypedFormControl('', Validators.required),
            sensor: new UntypedFormControl(this.sensorId, Validators.required),
            start: new UntypedFormControl('', Validators.required),
            end: new UntypedFormControl('', Validators.required),
        });
    }

    public initNameForm() {
      this.nameForm = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required)
      })
    }

    getNotification(sensorId) {
        const obj = {
            cmd: 600,
            sensor: sensorId,
            real: false,
            params: { state: true },
        };
        this.subscriptions.add(
            this.sensorService.postAcquisition(obj).subscribe((data) => {
                console.log(data);
            })
        );
    }

    public saveRelationInLocal(newAction) {
        const addAction = localStorage.getItem('addAction')
            ? JSON.parse(localStorage.getItem('addAction'))
            : [];
        addAction.push({ ...newAction, tempId: uuid.v4() });
        localStorage.setItem('addAction', JSON.stringify(addAction));
        const attributeRelations = localStorage.getItem('sensorActions')
            ? JSON.parse(localStorage.getItem('sensorActions'))
            : [];
        addAction.forEach((element) => {
            attributeRelations.push(element);
        });
        console.log(attributeRelations);
        localStorage.setItem(
            'sensorActions',
            JSON.stringify(attributeRelations)
        );
        console.log(localStorage.getItem('sensorActions'));
        this.tempActions = JSON.parse(localStorage.getItem('sensorActions'));
        console.log(localStorage.getItem('addAction'));
        console.log(this.tempActions);
        this.editActionModal.hideModal();
    }

    public deleteAction(actionId) {
        this.tempActions = this.tempActions.filter((action) => {
            if (action.id) {
                if (action.id == actionId) {
                    const deleteAction = localStorage.getItem('deleteActionIds')
                        ? JSON.parse(localStorage.getItem('deleteActionIds'))
                        : [];
                    deleteAction.push(actionId);
                    localStorage.setItem(
                        'deleteActionIds',
                        JSON.stringify(deleteAction)
                    );
                    console.log(localStorage.getItem('deleteActionIds'));
                }
                return action.id != actionId;
            } else if (action.tempId) {
                if (action.tempId == actionId) {
                    const deleteAction = localStorage.getItem('deleteActionIds')
                        ? JSON.parse(localStorage.getItem('deleteActionIds'))
                        : [];
                    deleteAction.push(actionId);
                    localStorage.setItem(
                        'deleteActionIds',
                        JSON.stringify(deleteAction)
                    );
                    console.log(localStorage.getItem('deleteActionIds'));
                }
                return action.tempId != actionId;
            }
        });

        localStorage.setItem('sensorActions', JSON.stringify(this.tempActions));
    }

    public saveAction() {
        const formData = {
            data: this.actionsForm.get('data').value,
            day: this.actionsForm.get('day').value,
            minute: this.actionsForm.get('minute').value,
            month: this.actionsForm.get('month').value,
            dweek: this.actionsForm.get('dweek').value,
            duration: this.actionsForm.get('duration').value
                ? this.actionsForm.get('duration').value
                : 0,
            sensor: this.sensorId,
            start: this.actionsForm.get('start').value.format(),
            end: this.actionsForm.get('end').value.format(),
            type: this.actionsForm.get('type').value,
            enabled: this.actionsForm.get('enabled').value,
        };
        this.saveRelationInLocal(formData);
    }

    public updateAction() {
        const id = this.currentActionId;
        console.log(id);
        const formData = {
            sensor: this.actionsForm.get('sensor').value,
            start:
                typeof this.actionsForm.get('start').value == 'object'
                    ? this.actionsForm.get('start').value.format()
                    : this.actionsForm.get('start').value,
            end:
                typeof this.actionsForm.get('end').value == 'object'
                    ? this.actionsForm.get('end').value.format()
                    : this.actionsForm.get('end').value,
            enabled: this.actionsForm.get('enabled').value,
        };
        console.log(formData);
        const updateAction = localStorage.getItem('sensorActions')
            ? JSON.parse(localStorage.getItem('sensorActions'))
            : [];
        // Find the attribute to update from global registry, set updated values
        const attributeToUpdate = updateAction.find(
            (item) => item.id == id || item.tempId == id
        );
        console.log(attributeToUpdate);
        attributeToUpdate['start'] = formData.start;
        attributeToUpdate['end'] = formData.end;
        attributeToUpdate['enabled'] = formData.enabled;

        const index = updateAction.findIndex(
            (item) => item.id == id || item.tempId == id
        );
        index < 0
            ? updateAction.push(attributeToUpdate)
            : updateAction.splice(index, 1, attributeToUpdate);
        console.log(updateAction);
        this.tempActions = updateAction;
        localStorage.setItem('sensorActions', JSON.stringify(updateAction));
        const localUpdateActionIds = localStorage.getItem('updateActions')
            ? JSON.parse(localStorage.getItem('updateActions'))
            : [];
        localUpdateActionIds.push(attributeToUpdate);
        localStorage.setItem(
            'updateActions',
            JSON.stringify(localUpdateActionIds)
        );
        console.log(localStorage.getItem('updateActions'));
        // this.sensorService.postSensorActions(formData).subscribe((data) => {
        //     console.log(data.results);
        //     if (data.results) {
        //         this.getSensor();
        //     }
        // });

        this.actionsForm.reset();
        Object.keys(this.actionsForm.controls).forEach((key) => {
            this.actionsForm.get(key).setErrors(null);
        });
        this.isEdit = false;
        this.editActionModal.hideModal();
    }

    public changeStatus(state) {
        console.log(state.checked);
        const val = state.checked ? 1 : 0;
        const postObj = {
            value: val,
        };
        const postData = {
            cmd: 650,
            sensor: this.sensorId,
            params: postObj,
        };

        this.subscriptions.add(
            this.sensorService.postAcquisition(postData).subscribe(
                (response) => {
                    console.log(response);
                    this.toastr.success(
                        this.translateService.instant('Triggered new action')
                    );
                },
                (): void => {
                    this.toastr.error(
                        this.translateService.instant(
                            'Error occured when triggering action'
                        )
                    );
                }
            )
        );
    }

    public getData() {
      this.subscriptions.add(this.dataService.getEyeData.subscribe((res) => {
      this.sensorData = res;
        if (
            this.sensorData.cmd == 650 &&
            this.sensorData.params.sensor ==
               this.sensorId
        ) {
            this.getSensor();
            this.toastr.success(
              this.translateService.instant(this.sensorData.parmas.device + ' ' + this.sensorData.params.last_value.value_float == '0' ? 'Stopped' : 'Started')
          );
        }
    }))
  }

  public showNameForm(event) {
    console.log(event.target.value);
    this.updateSensorNameModal.showModal();
    this.nameForm.get('name').patchValue(event.target.value);
}

public saveName() {
  console.log(this.sensorId, this.nameForm.value);
  this.sensorService.updateSensorName(this.sensorId, this.nameForm.value).subscribe(data => {
    this.sensor = data.body.results;
    this.sensorsForm.get('name').patchValue(data.body.results.name);
    this.toastr.success(
      this.translateService.instant('Sensor Name Updated')
  );
  this.updateSensorNameModal.hideModal();
  })
}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        localStorage.removeItem('sensorActions');
        localStorage.removeItem('addAction');
        localStorage.removeItem('updateActions');
        localStorage.removeItem('deleteActionIds');
    }
}
