import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { Subscription } from 'rxjs';
import { NavService } from '../../../services/nav.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-info-sections',
  templateUrl: './fap-info-sections.component.html',
  styleUrls: ['./fap-info-sections.component.scss']
})
export class FapInfoSectionsComponent implements OnChanges, OnInit {

  @Input() public descriptions = [];
  @Input() public isEditable: boolean = false;

  @Output() public updatedDescription: EventEmitter<any> = new EventEmitter();

  @ViewChild('addEditDescriptionModal')
  public addEditDescriptionModal: FapModalComponent;
  public langString
  public description = null;
  public descriptionId = -1;
  public subscriptions: Array<Subscription> = [];

  constructor(private typeService: TypesService, private navService: NavService, private toastr: ToastrService, public globalRegistry: GlobalRegistryService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('descriptions') && this.descriptions.length) {
      console.log(this.descriptions);
    }
  }

  public addNewInfo() {
    console.log('new info');
    this.typeService.createInfoSection({}).subscribe(data => {
      this.description = data.body.results;
      this.descriptionId = data.body.results.id;
    }, (error) => {
      this.toastr.error(error.error.results.error);
    })
    this.addEditDescriptionModal.showModal();
  }

  public addDescription(description) {
    console.log(description);
    // this.description = description;
  }

  public editDescription(description) {
    this.typeService.getInfoSectionById(description.id).subscribe(data => {
      this.description = data.body.results;
      this.descriptionId = data.body.results.id;
      this.addEditDescriptionModal.showModal();
    })
  }

  getTranslation(translation) {
    if(translation == null) {
      return
    }
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  public deleteDescription(descriptionId) {
    console.log(descriptionId);
    this.typeService.deleteInfoSection(descriptionId).subscribe(data => {
      this.descriptions = this.descriptions.filter(obj => obj.id !== descriptionId);
      this.toastr.success('Description deleted successfully');
    }, (error) => {
      this.toastr.error(error.error.results.error);
    })
  }

  public updateDescription(description) {
    console.log(description);
    console.log(this.descriptionId);
    // this.description = description;
    this.typeService.updateInfoSection(this.descriptionId, description).subscribe(data => {
      this.description = data.body.results;
      this.updatedDescription.emit(data.body.results);
      this.addEditDescriptionModal.hideModal();
      this.toastr.success('Description updated successfully');
    }, (error) => {
      this.toastr.error(error.error.results.error);
    })
  }
}
