import {
    Directive,
    TemplateRef,
    Input
} from '@angular/core';


@Directive({
    selector: '[fapWizardContent]'
})
export class FapWizardContentDirective {

    @Input()
    public id: string;

    @Input()
    public title: string;

    constructor(public templateRef: TemplateRef<any>) {}

}
