import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { ActivityTypeModel } from '../../../../../core/models/activity/activity-type.model';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { NavService } from '../../../../../shared/services/nav.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../../../../../core/models/response.model';
import { CropTypeModel } from '../../../../../core/models/type/crop-type.model';
import { CropTypeInterface } from '../../../../../core/services/api/types/data/crop-type.interface';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FapModalComponent } from '../../../../../shared/partials';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { UnitInterface } from '../../../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../../../core/services/api/units/unit.service';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';
import { CropInterface } from '../../../../../core/services/api/crop/data/crop.interface';
import { environment } from '../../../../../../environments/environment';
import { CropModel } from '../../../../../core/models/crops/crop.model';

@Component({
  selector: 'crop-item',
  templateUrl: './crop-item.component.html',
  styleUrls: ['./crop-item.component.scss']
})
export class CropItemComponent implements OnInit {

  public subscriptions: Subscription[] = [];
  public cropId: number;
  public crop: CropModel;
  cropData: any;
  numberOfColumns: number;
  activities: any[];
  dateColumns: Date[];
  columnWidth = 60;
  activityTypes: ActivityTypeModel[] = [];
  public langString;
  public selectedCropType: CropTypeModel;
  @ViewChild('addEditCropTypeModal')
  public addEditCropTypeModal: FapModalComponent;
  public productId;
  @ViewChild('addEditProductModal')
  public addEditProductModal: FapModalComponent;
  @ViewChild('typeFilter')
  public typeFilter: ElementRef;
  @ViewChild('productsModal')
  public productsModal: FapModalComponent;
  public myMath = Math;
  public cropFarm;
  public cultureForm: UntypedFormGroup;
  public lotId: number;
  public localDocId: number;
  public resourcesDoc: any;
  public areaUnits: Array<UnitModel>= [];
  public uForecastedCost = 0;
  public pForecastedCost = 0;
  public uCurrentCost = 0;
  public pCurrentCost = 0;
  public uSavings = 0;
  public pSavings = 0;
  public yield = 0;
  public currency;
  public getMore = true;
  public nextToken: { offset: number; limit: number; search: string } = null;
  public limit = 20;
  public newProduct;
  public units: Array<UnitModel> = [];
  public unitForm: FormGroup;
  public documentFarm: number = null;
  public p_items;
  public r_items;
  public selectedUnit: UnitModel;
  public currentUnit: UnitModel;
  public parentUnit: UnitModel;
  public products;
  public selectedUnitId: number;
  @ViewChild('mys') mys;
  public searchKeyword = '';
  @ViewChild('addEditUnitModal')
  public addEditUnitModal: FapModalComponent;
  public cropProduct: number;
  public entityInfo: any;
  public formType;
  public formFields;
  public resources;
  public mediaUrl: string = environment.mediaUrl;
  public defaultCurrency: number;
  public product: any;
  public activeUrl:string = '';
  public show: boolean = false;

  constructor(private widgetService: WidgetsService, private mapService: MapService, private activatedRoute: ActivatedRoute, public cropService: CropService, public typeService: TypesService, public globalRegistry: GlobalRegistryService, public navService: NavService, public toastyService: ToastrService, public translateService: TranslateService, public formBuilder: FormBuilder, public router: Router, public route: ActivatedRoute, public stockService: StockService, public unitService: UnitService, public attributeService: AttributeService, public cacheService: CacheResolverService) {}

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetService.setSize(12);
    this.initForm();
      this.initunitForm();
      this.getProducts();
      this.units = this.globalRegistry.systemData.units;
      this.units.filter(u => {
        if(u.genre === 'm') {
          this.areaUnits.push(u);
        }
      });
      this.lotId = this.crop && this.crop.id;
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    if(this.activatedRoute.snapshot.params && this.activatedRoute.snapshot.params.cropId) {
      this.cropId = this.activatedRoute.snapshot.params.cropId;
      this.getCropPlan(this.cropId);
      this.subscriptions.push(
        this.cropService.getCrop(this.cropId).subscribe(data => {
          this.crop = data.model;
          console.log(this.crop);
          this.initForm();
        })
      )
    }
    this.getActivityTypes();
    if(this.route.snapshot.queryParams.doc) {
      this.localDocId = this.route.snapshot.queryParams.doc;
      console.log(this.localDocId);
      this.cultureForm.get("forecastedDoc").setValue(+this.localDocId);
    }
    if(this.route.snapshot.queryParams.mode) {
      this.editCrop();
    }
    this.navService.createActivity.subscribe(route => {
      if(route === 'crops/add') {
        this.router.navigate(['/pages/activities/add'], {queryParams: {from: 'crop'}});
      } else {
        this.router.navigate(['/pages/activities/add'], {queryParams: {from: 'crop', cropId: this.cropId}});
      }
    })
    this.defaultCurrency = this.globalRegistry.systemData.company.currency;
    let url = this.activatedRoute['_routerState'].snapshot.url;
    const segments = url.split("/");
    this.activeUrl = segments.slice(2, 4).join("/");
  }

  public submit(): void {
    
    if(this.unitForm.dirty) {
        const unit: UnitInterface = {
            name: this.unitForm.controls['name'].value,
            shortName: this.unitForm.controls['shortName'].value,
            genre: this.unitForm.controls['genre'].value
        };
  
        if(typeof this.unitForm.controls['parent'].value !== 'string') {
            unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                null :
                this.unitForm.controls['parent'].value;
        }
  
        if(this.currentUnit) {
            this.updateUnit({...{id: this.currentUnit.id}, ...unit});
            this.globalRegistry.reloadUnits();
        }
        else {
            this.addUnit(unit);
            this.globalRegistry.reloadUnits();
        }
        this.getUnits()
    }
  }
  
  public getUnits() {
      this.subscriptions.push(this.unitService.getUnits().subscribe(data => {
      this.units = data.body.results;
    }))
  }
  
  private initunitForm(): void {
    this.unitForm = this.formBuilder.group({
        name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
        ],
        shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100)
            ])
        ],
        parent: [this.currentUnit && this.parentUnit ? this.parentUnit.id : '', Validators.compose([
                Validators.required
            ])
        ],
        genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                Validators.required
            ])
        ]
    });
  }
  
  private addUnit(unit: UnitInterface): void {
      this.subscriptions.push(this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
        this.toastyService.success(this.translateService.instant('units.unitAddSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.onUnitAction(response.model.id);
    },
    (): void => {
        this.toastyService.error(this.translateService.instant('units.unitAddError'));
    }));
  }
  
  private updateUnit(unit: UnitInterface): void {
    this.unitService.updateUnit(unit).subscribe((): void => {
        this.toastyService.success(this.translateService.instant('units.unitUpdateSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.onUnitAction(this.currentUnit.id);
  
    },
    (): void => {
        this.toastyService.error(this.translateService.instant('units.UnitUpdateError'));
    });
  }
  
  public deleteUnit(unitId: number): void {
    this.unitService.deleteUnit(unitId).subscribe((): void => {
        this.toastyService.success(this.translateService.instant('units.unitDeleteSuccessfull'));
        this.globalRegistry.reloadUnits();
    },
    (): void => {
        this.toastyService.error(this.translateService.instant('units.UnitDeleteError'));
    });
  }

  public onUnitAction(unitId: number): void {
    this.selectedUnitId = unitId;
    this.addEditUnitModal.hideModal();
  }

  getActivityTypes() {
    this.typeService.getActivityTypes().subscribe(data => {
      this.activityTypes = data.model;
    })
  }

  getCropPlan(cropId) {
    this.cropService.getCropPlan(cropId).subscribe(data => {
      console.log(data);
      this.cropData = data.body.crop;
      this.numberOfColumns = data.body.cols;
      this.activities = data.body.rows;
      console.log('Crop Data:', this.cropData);
      console.log('Number of Columns:', this.numberOfColumns);
      console.log('Activities:', this.activities);
      this.generateDateColumns(this.cropData.start, this.cropData.end);
    })
  }

  generateDateColumns(startDate: string, endDate: string): void {
    this.dateColumns = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      this.dateColumns.push(new Date(d));
    }
  }

getActivityTypeColor(activityTypeId: number) {
  let color = '#BA6969';
  this.activityTypes.forEach(type => {
    if(type.id === activityTypeId) {
      color = type.color;
    }
  });
  return color;
}

getTranslation(translation) {
  const t =this.globalRegistry.systemData.translations.filter(trans => {
    return trans.id === translation
  });
  if(t[0]) {
    if(t[0][this.langString] === null || t[0][this.langString] === '') {
      return translation
    } else {
      return t[0][this.langString];
    }
  } else {
      return translation
    }
}

getActivityOverlayWidth(start: number, end: number): string {
  const totalDays = end - start + 1; // Assuming start and end are indices
  return (totalDays * this.columnWidth) + 'px';
}

getActivityOverlayPosition(start: number): string {
  const leftPosition = start * this.columnWidth;
  return (leftPosition) + 'px';
}

openPopup() {
  this.router.navigate([], {
    relativeTo: this.route,
    queryParams: { mode: 'edit' },
    queryParamsHandling: 'merge'
  });
}

editCrop() {
  const elem = document.getElementById('crop_edit_modal');
  const parent = document.getElementById('parent101');
  elem.classList.add('active');
  parent.classList.add('backdrop');
}

closeModal() {
  this.router.navigate([]);
  const elem = document.getElementById('crop_edit_modal');
  const parent = document.getElementById('parent101');
  elem.classList.remove('active');
  parent.classList.remove('backdrop');
}

public addCropTypeModal(cropType?: CropTypeModel): void {
  this.selectedCropType = cropType;
  this.addEditCropTypeModal.showModal();
}

getCropFarm(lotId) {
const lot = this.globalRegistry.systemData.lots.filter(l => {
    if(l.id === lotId) {
        return l;
    }
});
this.cropFarm = lot[0].farm;
console.log(this.cropFarm);
return

}

public goToBill() {
if(this.crop) {
  if(this.crop.forecastDoc) {
      this.router.navigate(['/pages/stock/bill/'+this.crop.forecastDoc], {queryParams: {type: 'estimation_note', from: 'crop', cropId: this.cropId, farm: this.cropFarm, new_doc: 'false'}});
  } else {
      this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'estimation_note', from: 'crop', farm: this.cropFarm, cropId: this.cropId, new_doc: 'true'}});
  }
} else {
  this.router.navigate(['/pages/stock/bill/add'], {queryParams: {type: 'estimation_note', from: 'crop', new_doc: 'true', cropId: this.cropId, farm: this.cropFarm}});
}
}

public setUnit(unit) {
this.cultureForm.get('distUnit').setValue(unit.value);
}

public initForm(): void {
  this.cultureForm = this.formBuilder.group({
      product: [this.crop && this.crop.product ? this.crop.product.id : null, Validators.compose([Validators.required])],
      progress: [this.crop && this.crop.progress ? this.crop.progress : 0, []],
      start: [this.crop && this.crop.start ? this.crop.start : new Date(), []],
      rowDistance: [this.crop && this.crop.distRow ? this.crop.distRow : null, []],
      plantDistance: [this.crop && this.crop.distPlant ? this.crop.distPlant : null, []],
      distUnit: [this.crop && this.crop.distUnit ? this.crop.distUnit : null, []],
      usedForecastedCost: [null],
      producedForecastedCost: [null],
      forecastedDoc: [this.crop && this.crop.forecastDoc ? this.crop.forecastDoc: null, []],
      usedCurrentCost: [null],
      producedCurrentCost: [null],
      usedSavings: [null],
      producedSavings: [null],
      yield: [null]
  });
  
  if(this.crop) {
    if(this.crop.lot) {
      this.getCropFarm(this.crop.lot);
    }
    // console.log(this.crop);
    // if(this.crop.forecastDoc) {
    //   this.localDocId = this.crop.forecastDoc;
    //   this.getDocDetails(this.localDocId);
    // } else {
    //   if(this.route.snapshot.queryParams.doc) {
    //     this.localDocId = this.route.snapshot.queryParams.doc;
    //     this.cultureForm.get("forecastedDoc").setValue(this.localDocId);
    //     this.getDocDetails(this.localDocId);
    // }
    // }  
    if(this.crop.product) {
      this.product = this.crop.product
    }
    this.getForecastInfo(this.crop.id);
  }
}

getProduct(productId: number) {
  this.stockService.getProduct(productId).subscribe(data => {
    this.product = data.body.results;
  })
}

public getForecastInfo(cropId: number) {

this.subscriptions.push(this.cropService.getForecastInfo(cropId).subscribe(data => {
  this.resources = data.body.results
}));
return
}

transformDecimal(num) {
return num.toFixed(this.globalRegistry.systemData.company.digits);
}

public getDocDetails(id) {
this.subscriptions.push(this.stockService.getDoc(id).subscribe(data => {
    this.resourcesDoc = data.body.results;
    console.log(data.body.results);
    this.currency = data.body.results.currency;
    if(data) {
        this.getForecastInfo(this.crop.id);
        return
    }
}))
}

public deleteCropType(cropTypeId: number): void {
  this.typeService.deleteCropType(cropTypeId).subscribe((): void => {
      this.cultureForm.controls['cropType'].setValue(null);
      this.globalRegistry.reloadCropTypes();
      this.globalRegistry.reloadLots();
      this.toastyService.success(this.translateService.instant('crops.cropsType.messages.deleteSuccess'));
  },
  (): void => {
      this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failDelete'));
  });
}

public addCropType(cropType: CropTypeInterface): void {
  this.addEditCropTypeModal.hideModal();
  this.typeService.addCropType(cropType).subscribe((response: ResponseModel<CropTypeModel>): void => {
      this.globalRegistry.reloadCropTypes();
      this.cultureForm.controls['cropType'].setValue(response.model.id);
      this.toastyService.success(this.translateService.instant('crops.cropsType.messages.createSuccess'));
  },
  (): void => {
      this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failCreate'));
  });
}

public updateCropType(cropType: CropTypeInterface): void {
  this.addEditCropTypeModal.hideModal();
  this.typeService.updateCropType(cropType).subscribe((response: ResponseModel<CropTypeModel>): void => {
      this.globalRegistry.reloadCropTypes();
      this.cultureForm.controls['cropType'].setValue(response.model.id);
      this.toastyService.success(this.translateService.instant('crops.cropsType.messages.updateSuccess'));
  },
  (): void => {
      this.toastyService.error(this.translateService.instant('crops.cropsType.messages.failUpdate'));
    });
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }

  getProducts() {
    this.subscriptions.push(this.stockService.getProducts({ group: 'SEEDS', limit: this.limit, search: this.searchKeyword }).subscribe(data => {
      this.products = data.body.results;
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
            if(this.crop && this.crop.product) {
              if(this.products.length > 0) {
              if(this.products.findIndex(p => p.id == this.crop.product) == -1) {
                this.subscriptions.push(this.stockService.getProduct(this.crop.product.id).subscribe(data => {
                  this.newProduct = data.body.results;
                  this.products.unshift(this.newProduct);
                  this.cultureForm.controls['product'].setValue(this.newProduct.id);
                  console.log(this.products);
                })
              )}
              }
            }
    }))
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
      this.scrolledDown();
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrolledDown() {
    // console.log('scrolled');
    if(this.getMore) {
      this.nextToken &&
        this.subscriptions.push(this.stockService.getProducts(this.nextToken).subscribe((data) => {
          console.log(data);
          if(this.products) {
            this.products = [...this.products, ...data.body.results];
          } else {
            this.products = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextToken.offset != params['offset']) {
            this.nextToken = {offset: params['offset'], limit: params['limit'], search: params['search']};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

  filterProduct(value) {
    this.subscriptions.push(this.stockService.getProducts({search: value}).subscribe(data => {
      console.log(data);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
      this.products = data.body.results;
    }))
  }

  assignProduct(product) {
    this.product = product;
    this.cultureForm.get('product').setValue(product.id);
    this.hideProducts();
  }

  hideProducts() {
    this.productsModal.hideModal();
    this.show = false;
  }

  public showProductModal(): void {
  this.productId = -1;
  console.log(this.productId);
  this.addEditProductModal.showModal();
  }

  public hideProductModal(): void {
  this.addEditProductModal.hideModal();
  }

  productDelete(productId) {
  this.stockService.deleteProduct(productId).subscribe(data => {
    this.cultureForm.controls['product'].reset();
    console.log(data);
    this.products = this.products.filter(product => {
      return product.id !== productId
  });
  })
  }

  public productEdit(product) {
  this.cultureForm.controls['product'].setValue(product.id);
  this.productId = product.id
  console.log(this.productId);
  this.addEditProductModal.showModal();
  }

  public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
    if(unit?.locked) {
      return;
  }
    this.currentUnit = unit;
    this.parentUnit = parent ? parent : null;
    if(genre) {
      console.log(genre);
      this.units = this.globalRegistry.systemData.units.filter(unit => {
        return unit.genre == genre
      });
    }
    
    this.initunitForm();
    this.addEditUnitModal.showModal();
  }

  public addProductAction(ev) {
  console.log(ev);
  this.cultureForm.controls['product'].setValue(ev.id);

  this.products.findIndex((product) => {
      if(product.id === ev.id) {
        return product = ev;
      }
  })
  this.mys.close();
  this.typeFilter.nativeElement.value = ''
  this.stockService.getProducts().subscribe(data => {
    this.products = data.body.results;
    const productExist = data.body.results.some((p) => p.id === ev.id);
    if(!productExist) {
      this.products.unshift(ev);
      this.cultureForm.controls['product'].setValue(ev.id);
    }
  })
  this.addEditProductModal.hideModal();
  }

  public updateProductAction(ev) {
  console.log(ev);
  console.log(this.products);
  const target = this.products.find((obj) => obj.id === ev.id);
  Object.assign(target, ev);
  this.mys.close();

  this.addEditProductModal.hideModal();
  }

  public getFormTypes() {
    const url = this.typeService.getUrl('form_type/');
    this.cacheService.delete(url+'entity=crop');
    const params = {entity: 'crop'}
    this.typeService.getFormTypes(params).subscribe(data => {
      console.log(data);
      this.entityInfo = data.body.results[0];
    })
  }

  saveCrop() {
    if(this.crop) {
      console.log(this.cultureForm.value);
      let cropInterface: CropInterface = {
        id: this.cropId,
        lot: this.crop.lot,
        start: this.cultureForm.value.start,
        end: this.cultureForm.value.end,
        progress: this.cultureForm.value.progress,
        distRow: this.cultureForm.value.distRow,
        distPlant: this.cultureForm.value.distPlant,
        distUnit: this.cultureForm.value.distUnit,
        product: this.cultureForm.value.product,
        forecastDoc: this.cultureForm.value.forecastedDoc
      };
      this.cropService.updateCrop(this.cropId, cropInterface).subscribe(data => {
        if(data) {
          this.crop = data.model;
          this.closeModal();
          this.toastyService.success(this.translateService.instant('farm.crop.cropUpdatedSuccessfully'));
        }
      }, (err) => {
          this.toastyService.error(this.translateService.instant('farm.crop.errorUpdatingCrop'));
          this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge'
          });
      })
    }
  }

  public updateAttributes() {
    this.attributeService.updateForm(this.formType.id, {updated_fields: this.formFields}).subscribe(data => {
        console.log(data);
    })
  }

  getBgColor(colorCode) {
    if(!colorCode) {
      return
    }
    const hex = colorCode.slice(1);
    const rgb = parseInt(hex, 16);
    const red = (rgb >> 16) & 0xff;
    const green = (rgb >> 8) & 0xff;
    const blue = rgb & 0xff;
    const alpha = 0.3;
    const rgbaColor = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    return rgbaColor;
    }

  public getLotName(lotId: number): string {
    return lotId !== 0 ? this.globalRegistry.systemData.lots.find(lot => lot.id === lotId)?.name ?? '' : '';
  }

}
