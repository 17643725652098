import { BillModel } from '../../../../../core/models/stock/bill.model';
import { TransactionsParamsInterface } from '../../../../../core/interfaces/stock/transactions-params.interface';
import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter
} from '@angular/core';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';
import { TransactionModel } from '../../../../../core/models/stock/transaction.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { TranslateService } from '@ngx-translate/core';

import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { StockIncomeInterface } from '../../../../../core/interfaces/stock/stock-income.interface';
import { ResourceTypeModel } from '../../../../../core/models/type/resource-type.model';
import { BillsParamsInterface } from '../../../../../core/interfaces/stock/bills-params.interface';

@Component({
    selector: 'fap-stock-overview',
    templateUrl: './fap_stock-overview.component.html',
    styleUrls: ['./fap_stock-overview.component.scss']
})
export class FapStockOverviewComponent {

    @Input()
    public stockItems: Array<StockItemModel>;

    @Input()
    public transactions: Array<TransactionModel>;

    @Input()
    public totalNoOfTransactions: number;

    @Input()
    public bills: Array<BillModel>;

    @Input()
    public totalNoOfBills: number;

    @Input()
    public selectedFarm: FarmModel;

    @Input()
    public parties: Array<PartyModel>;

    @Input()
    public resourceTypes: Array<ResourceTypeModel>;

    @Output()
    public addStockIncome: EventEmitter<StockIncomeInterface> = new EventEmitter();

    @Output()
    public addStockOutcome: EventEmitter<StockIncomeInterface> = new EventEmitter();

    @Output()
    public filterTransactions: EventEmitter<TransactionsParamsInterface> = new EventEmitter();

    @Output()
    public filterBills: EventEmitter<BillsParamsInterface> = new EventEmitter();

    @Output()
    public deleteParty: EventEmitter<number> = new EventEmitter();

    @ViewChild('stockIncomeModal')
    public stockIncomeModal: FapModalComponent;

    @ViewChild('stockOutcomeModal')
    public stockOutcomeModal: FapModalComponent;


    constructor(private translateService: TranslateService) {
    }
}
