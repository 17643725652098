import { FapBaseModel } from '../../../base/fap-base-model';
import { WeatherForecastModel } from './weather-forecast.model';


export class WeatherModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'content_type',
        'coords',
        'forecast',
        'humidity',
        'last_update',
        'object_id',
        'precip',
        'pressure',
        'temperature',
        'wind_dir',
        'wind_speed'
    ];

    public static requiredFields: Array<string> = [];

    public static extendedFields: {} = {
        forecast: WeatherForecastModel
    };

    public id: number;
    public contentType: number;
    public coords: string;
    public forecast: WeatherForecastModel = new WeatherForecastModel();
    public humidity: number;
    public lastUpdate: number;
    public objectId: number;
    public precip: number;
    public pressure: number;
    public temperature: number;
    public windDir: string;
    public windSpeed: number;
}
