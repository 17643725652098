import { TranslateService } from '@ngx-translate/core';
import { FapModalComponent } from '../../../../../shared/partials/components/fap-modal/fap-modal.component';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import {
    Component,
    EventEmitter,
    Output,
    Input,
    ViewChild
} from '@angular/core';
import { AttributeModel } from '../../../../../core/models/attribute/attribute.model';

@Component({
    selector: 'fap-attributes-layout',
    templateUrl: './fap_attributes-layout.component.html',
    styleUrls: ['./fap_attributes-layout.component.scss']
})
export class FapAttributesLayoutComponent {

    @Input()
    public attributes: Array<AttributeModel> = [];

    @Output()
    public deleteAttribute: EventEmitter<number> = new EventEmitter();

    @ViewChild('addEditAttributeModal')
    public addEditAttributeModal: FapModalComponent;

    public displayedColumns: string[] = ['name', 'mandatoryFor', 'actions'];
    public selectedAttribute: AttributeModel;

    constructor(public confirmModalService: ConfirmModalService,
                public translateService: TranslateService) {
    }

    public addAttributeModal(): void {
        this.selectedAttribute = null;
        this.addEditAttributeModal.showModal();
    }

    public editAttribute(attribute: AttributeModel): void {
        this.selectedAttribute = attribute;
        this.addEditAttributeModal.showModal();
    }

    public onDeleteAttribute(attribute: AttributeModel): void {
        if (attribute.isUsed) {
            return;
        }
        this.confirmModalService.openConfirmModal(
            this.translateService.instant('attribute.deleteAttribute'),
            this.translateService.instant('attribute.deleteAttributeMessage'));
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                this.deleteAttribute.emit(attribute.id);
            }
        });
    }
}
