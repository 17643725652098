<form [formGroup]="productForm">
    <div class="form-group" [ngClass]="{'overlay': product?.locked}">
        <div class="form-group w-100">
            <div class="rw">
                <div class="col col-12 d-flex p-0">
                    <fap-image-list [imageIds]="productImages" [group]="'products'" (deleteImage)="deleteImage($event)" (addImage)="addImage($event)"></fap-image-list>
                </div>
            </div>
        </div>
        <div style="height: 10px; width: 100%;"></div>
        <div class="row align-items-baseline">
           
            <div class="col-12">
                <div class="input-group w-100">
                    <mat-form-field class="w-100" (click)="productsLabelModal.showModal(); show = true">
                        <mat-label>{{"stock.product" | translate}}</mat-label>
                        <input style="display: none;" type="text" matInput formControlName="label" readonly="true" name="label">
                        <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                            {{label?.name}}
                        </span>
                    </mat-form-field>
                </div>
            </div>
           
            <div class="col-6">
                <fap-unit-value class="half_width"
                    [fieldName]="'product.packaging' | translate"
                    [(ngModel)]="packUnit"
                    [ngModelOptions]="{standalone: true}">
                </fap-unit-value>
            </div>
            <div class="col-6">
                <fap-unit-value class="half_width"
                    [fieldName]="'product.productQuantity' | translate"
                    [(ngModel)]="quantityPack"
                    [ngModelOptions]="{standalone: true}">
                </fap-unit-value>
            </div>
            
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>{{ 'product.cost' | translate }}</mat-label>
                        <input matInput placeholder="Cost" type="number" formControlName="cost">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div class="input-group w-100">
                    <mat-form-field>
                        <mat-label>{{ 'product.alertQty' | translate }}</mat-label>
                        <input matInput type="number" placeholder="Alert quantity" required (change)="verifyAlertQuantity($event)" formControlName="alert_quantity">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-6">
                <div
                    class="input-group align-items-baseline w-100"
                >
                <mat-form-field class="w-100" (click)="getPeopleGroups()" [ngClass]="{'dbld': productForm.controls['alert_quantity'].value == 0}">
                    <mat-label class="col-form-label">{{ 'product.alertGroup' | translate }}</mat-label>
                    <mat-select formControlName="alert_group">
                        <div class="d-flex" style="align-items: center; padding: 0 10px;">
                        <mat-form-field appearance="fill" class="w-100">
                            <!-- <mat-label>
                                <i class="fa fa-search f-20"></i>
                            </mat-label> -->
                            <input matInput #userGroupsFilter placeholder="Search">
                        </mat-form-field>
                        <!-- <i class="fa fa-plus center f-20 ml-2" (click)="addGroupModal()"></i> -->
                         <fap-round-button (clicked)="addGroupModal()"></fap-round-button>
                    </div>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-option *ngFor="let group of peopleGroup | filter : userGroupsFilter.value" [value]="group.id">
                            <div class="party-name">
                                {{ group?.name }}
                            </div>
                            <div class="party-actions">
                                <i class="fa fa-edit center f-20"
                                    (click)="editGroup(group);$event.stopPropagation()"></i>
                                <i class="fa fa-trash center ml-2 f-20"
                                    (click)="selectedGroup=group;deleteGroupModal.showModal();$event.stopPropagation()">
                                </i>
                            </div>
                        </mat-option>
                    </div>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
            <!-- <div class="col-6">
                
            </div> -->
            <div class="form-group w-100 mt-4" style="max-width: calc(100% - 30px); margin: auto;" *ngIf="resourceType?.entity_form">
                <fap-attributes
                    [entityRelation]="'resource_type'"
                    [entityId]="entityInfo?.id"
                    [objectId]="product?.id"
                    [entityInfo]="entityInfo"
                    (formTypeEmit)="formType = $event"
                    (formFieldsEmit)="formFields = $event"
                    >
                </fap-attributes>
            </div>
            <!-- <div style="height: 30px; width: 100%;"></div> -->
            
        </div>
    </div>
    <div class=" mt-3 d-flex justify-content-end">
        <button class="btn mr-2" *ngIf="!product?.locked" type="submit" (click)="submitProduct()">{{ 'translations.save' | translate }}</button>
        <button class="btn" type="button" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>

<fap-modal #addEditGroupModal [inputTitle]="'people.groups.addEditGroup' | translate">
    <fap-add-edit-group [group]="selectedGroup" (createdGroup)="createdGroup($event)" (cancel)="addEditGroupModal.hideModal()"></fap-add-edit-group>
</fap-modal>

<fap-modal #deleteGroupModal [inputPrimaryButtonInterface]="deleteGroupModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="deleteGroupModalButtonSecondaryInterface"
    inputTitle="{{ 'people.groups.deleteGroup' | translate }}">
    {{ 'people.groups.deleteGroupMessage' | translate}}
</fap-modal>

<fap-modal #productsLabelModal [inputIsLarge]="true"
[inputTitle]="'stock.products' | translate" [showFooter]="false">
    <products-label-modal (hideModal)="hideLabels()" (selectedLabel)="assignLabel($event)" [show]="show">
    </products-label-modal>
</fap-modal>