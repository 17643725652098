<div class="table-wrap">
    <h4 class="table-header-title">
        {{ 'stock.bills' | translate }}
    </h4>
    
    <div *ngIf="!bills || bills.length < 0" class="no-bills d-flex">{{ 'stock.noBillsAvailable' | translate }}</div>
    
    <table [ngClass]="{'d-none': !bills || bills.length==0 }" mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-striped">
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element;let i = index;"> {{ i + pageSize*pageIndex + 1 }}. </td>
        </ng-container>
    
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
        </ng-container>
    
        <ng-container matColumnDef="billType">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.billType === 'income'" aria-hidden="false" aria-label="Bill Type" color="primary">trending_up</mat-icon>
                <mat-icon *ngIf="element.billType === 'outcome'" aria-hidden="false" aria-label="Bill Type" color="warn">trending_down</mat-icon>
            </td>
        </ng-container>
    
        <!-- <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> Price</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.price | currency:'RON' }}</span>
            </td>
        </ng-container> -->
    
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> {{ 'date' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.createdAt | date: 'short' }}</span>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> {{ 'createdBy' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <span>{{ element.createdBy }}</span>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="viewBill">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <!-- <button class="btn btn-success btn-sm" (click)="viewBill(element)">{{ 'stock.viewBill' | translate }}</button> -->
                <button class="btn btn-success btn-sm" [routerLink]="'/pages/stock/bill/' + element?.id">{{ 'stock.viewBill' | translate }}</button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
        [ngClass]="{'d-none': !bills || bills.length==0 }"
        #paginator
        class="d-flex justify-content-center"
        [length]="totalNoOfBills"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="[10, 20, 30]"
        showFirstLastButtons>
    </mat-paginator>
</div>

<fap-modal #viewBillModal
    [inputIsExtraLarge]="true">
    <fap-stock-bill-details [bill]="selectedBill">
    </fap-stock-bill-details>
</fap-modal>
