import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../shared/services/nav.service';
import { ActivityModel } from '../../../core/models/activity/activity.model';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../../../core/models/response.model';
import { ActivityService } from '../../../core/services/api/activity/activity.service';
import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
import { ActivityTypeModel } from '../../../core/models/activity/activity-type.model';
import { PlanTypeModel } from '../../../core/models/company/plan.type.model';
import { MapService } from '../../../shared/layout/fap_main-map/service/map-service';
import { WidgetsService } from '../../../core/services/api/widgets/widgets.service';
import { PlanModel } from '../../../core/models/company/plan.model';
import { TypesService } from '../../../core/services/api/types/types.service';

@Component({
  selector: 'fap-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, OnDestroy, AfterViewInit {

  public langString;
  public subscriptions: Array<Subscription> = [];
  public activities: any = [];
  public searchString: string ='';
  public count: number = 0;
  public pageNumber: number = 1;
  public isLoading: boolean = false;
  public activityTypes: Array<ActivityTypeModel>;
  public planTypes: Array<PlanTypeModel>;
  public plans: Array<PlanModel>;
  public nextToken: { offset: number; limit: number } = null;
  public limit = 20;
  public offset = 0;
  public getMore = true;
  public dateColumns: Date[];
  public columnWidth = 60;
  public activityRows = [];
  public planData;
  public isActive = false;

  @ViewChild('datesContainer') datesContainer: ElementRef;

  constructor(public globalRegistry: GlobalRegistryService, public navService: NavService, private toastr: ToastrService, private activityService: ActivityService, private cacheService: CacheResolverService, private mapService: MapService, private widgetService: WidgetsService, private typeService: TypesService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
    }));
    this.plans = this.globalRegistry.systemData.plans;
    // this.getActivities();
    this.getActivityPlans();
    this.getActivityTypes();
    this.widgetService.setMapResize(false);
    this.widgetService.setSize(12);
    this.mapService.hideMap();
    console.log(this.globalRegistry.systemData.plans)
  }

  ngAfterViewInit() {
    this.enableDragScroll();
  }

  enableDragScroll() {
    const datesContainer = this.datesContainer.nativeElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
  
    datesContainer.addEventListener('mousedown', (e) => {
      isDown = true;
      datesContainer.classList.add('active');
      startX = e.pageX - datesContainer.offsetLeft;
      scrollLeft = datesContainer.scrollLeft;
    });
  
    datesContainer.addEventListener('mouseleave', () => {
      if (isDown) {
        isDown = false;
        datesContainer.classList.remove('active');
      }
    });
  
    datesContainer.addEventListener('mouseup', () => {
      if (isDown) {
        isDown = false;
        datesContainer.classList.remove('active');
      }
    });
  
    datesContainer.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - datesContainer.offsetLeft;
      const walk = (x - startX) * 2; // The multiplier can be adjusted for faster/slower scrolling
      datesContainer.scrollLeft = scrollLeft - walk;
    });
  }  

  getActivityPlans() {
    let url = this.activityService.getUrl('plan/deep=1');
    this.activityService.getActivityPlans({deep: 1}).subscribe(data => {
      this.cacheService.delete(url);
      console.log(data);
      this.planData = data.body;
      this.activities = this.planData.rows;
      if(this.planData.period.start) {
        this.generateDateColumns(this.planData.period.start, this.planData.period.end);
      }
    })
  }

  private getActivityTypes(): void {
    this.typeService
      .getActivityTypes()
      .subscribe((response: ResponseModel<ActivityTypeModel[]>) => {
        this.activityTypes = response.model;
      });
  }

  filterActivitiesByPlan(event) {
    if(event.value.length === 0) {
      this.getActivityPlans();
    } else {
      let url = this.activityService.getUrl('plan/');
      let params = {list: event.value.toString(), deep: 1}
      this.activityService.getActivityPlans(params).subscribe(data => {
        this.cacheService.delete(url+'list='+event.value.toString()+'&deep=1');
        console.log(data);
        this.planData = data.body;
        this.activities = this.planData.rows;
        if(this.planData && this.planData.period) {
          this.generateDateColumns(this.planData.period.start, this.planData.period.end);
        }
      })
    }
  }

  filterPlans(ev) {
    console.log(ev);
  }

    getTranslation(translation) {
      const t =this.globalRegistry.systemData.translations.filter(trans => {
        return trans.id === translation
      });
      if(t[0]) {
        if(t[0][this.langString] === null || t[0][this.langString] === '') {
          return translation
        } else {
          return t[0][this.langString];
        }
      } else {
          return translation
        }
    }

    public handlePagination(pageNumber: number) {
      this.pageNumber = pageNumber;
      console.log(pageNumber);
      console.log(this.limit);
      console.log(pageNumber * this.limit);
      this.offset = pageNumber * this.limit - this.limit;
    }

    generateDateColumns(startDate: string, endDate: string): void {
      this.dateColumns = [];
      const start = new Date(startDate);
      const end = new Date(endDate);
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        this.dateColumns.push(new Date(d));
      }
    }
  
    getActivityTypeColor(activityTypeId: number) {
      let color = '#BA6969';
      this.activityTypes.forEach(type => {
        if(type.id === activityTypeId) {
          color = type.color;
        }
      });
      return color;
    }

    getActivityOverlayWidth(start: number, end: number): string {
      const totalDays = end - start + 1; // Assuming start and end are indices
      return (totalDays * this.columnWidth) + 'px';
    }
    
    getActivityOverlayPosition(start: number): string {
      const leftPosition = start * this.columnWidth;
      return (leftPosition) + 'px';
    }

    toggleActivityRow() {
      this.isActive = !this.isActive;
    }

    getChildActivityPlan(activityId: number) {
      this.activityService.getActivityPlanById(activityId).subscribe(data => {
        console.log(data);
      })
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(s => {
        s.unsubscribe();
      });
      this.widgetService.setMapResize(true);
    }
}
