import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { WidgetInterface } from '../../../interfaces/widget/widget.interface';
import { WidgetModel } from '../../../models/widget/widget.model';
import { ResponseModel } from '../../../models/response.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';

@Injectable()
export class WidgetsService extends FapRestBaseService {

    private drill = new BehaviorSubject(true);
    private createWidget = new BehaviorSubject(true);
    private mapResize = new BehaviorSubject(true);
    private size = new BehaviorSubject(6);
    private dCoords = new BehaviorSubject([]);

    getDrill = this.drill.asObservable();
    getCreateWidget = this.createWidget.asObservable();
    getMapResize = this.mapResize.asObservable();
    getSize = this.size.asObservable();
    getDCoords = this.dCoords.asObservable();

    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/widgets/');
    }

    public setDrill(data) {
        this.drill.next(data);
    }
    public setCreateWidget(data) {
        this.createWidget.next(data);
    }
    public setMapResize(data) {
        this.mapResize.next(data);
    }
    public setSize(data) {
        this.size.next(data);
    }
    public setDCoords(data) {
        this.dCoords.next(data.locations);
    }

    public types: {}[] = [
        {
            id: "graph",
            name : "widget.graph"
        },
        // {
        //     id: "graph_range",
        //     name : "widget.graphRange"
        // },
        {
            id: "graph_percentage",
            name : "widget.graphPercentage"
        },
        {
            id: "table",
            name : "widget.table"
        },
        {
            id: "soil",
            name : "widget.soil"
        },
        {
            id: "cropeye",
            name: "widget.cropeye"
        },
        {
            id: "image",
            name: "widget.image"
        },
        {
            id: "overview",
            name: "widget.overview"
        },
        {
            id: "fungal_alert",
            name: "widget.fungalAlert"
        },
        {
            id: "pest_alert",
            name: "widget.pestAlert"
        },
        {
            id: "pathogen_alert",
            name: "widget.pathogenAlert"
        },
        {
            id: "herbicide",
            name: "widget.herbicide"
        },
        {
            id: "images_list",
            name: "widget.imagesList"
        },
        {
            id: "bins",
            name: "widget.bins"
        },
        {
            id: "device_info",
            name: "widget.deviceInfo"
        },
        {
            id: "weather_forecast",
            name: "widget.weatherForecast"
        },
        {
            id: "sensor_value",
            name: "widget.value"
        },
        {
            id: "activities",
            name: "leftMenu.activities"
        },
        {
            id: "pool",
            name: "leftMenu.pools"
        },
        {
            id: "map",
            name: "leftMenu.map"
        },
        {
            id: "devices",
            name: "leftMenu.devices"
        }
    ];

    public graphTypes:{}[] = [
        {
            id: "bar",
            name: "widget.column"
        },
        {
            id:"line",
            name:"widget.line"
        },
        {
            id:"area",
            name:"widget.area"
        }
    ]

    public percentageGraphTypes:{}[] = [
        {
            id: "donut",
            name: "widget.gradientDonut"
        },
        {
            id:"pie",
            name:"widget.simplePie"
        },
        {
            id:"radialBar",
            name:"widget.radialBar"
        },
        {
            id:"polarArea",
            name:"widget.polarArea"
        }
    ]

    getUrl(slug) {
        return this.url+slug
      }

    public addWidget(widget): Observable<ResponseModel<WidgetModel>> {
        return this.mapRequest<WidgetModel>(
            this._http.post(this.url + 'widgets/',
                this.camelCaseModelToSnakeCaseJson(widget),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            WidgetModel,
            false
        ).pipe(take(1));
    }

    public deleteWidget(widgetId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'widgets/' + widgetId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public updateWidget(widgetId:number,widget: WidgetInterface): Observable<ResponseModel<WidgetModel>> {
        return this.mapRequest<WidgetModel>(
            this.http.patch(this.url + 'widgets/' + widgetId + "/",
                this.camelCaseModelToSnakeCaseJson(widget),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            WidgetModel,
            false
        ).pipe(take(1));
    }

    public getWidgets(params?: {}): Observable<ResponseModel<WidgetModel[]>> {
        return this.mapRequest<WidgetModel[]>(
            this._http.get(this.url + 'widgets/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            WidgetModel,
            true
        ).pipe(take(1));
    }

    public getWidgetView(params?: {}): Observable<ResponseModel<WidgetModel[]>> {
        return this.mapRequest<WidgetModel[]>(
            this._http.get(this.url + 'widgets/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            WidgetModel,
            true
        ).pipe(take(1));
    }

    public getWidgetCategories(): Observable<any> {
        return this._http
            .get(this.url + 'views/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }

    public addWidgetCategory(widget): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'views/',
                this.camelCaseModelToSnakeCaseJson(widget),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false,
            false
        ).pipe(take(1));
    }

    public deleteWidgetCategory(widgetId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'views/' + widgetId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public updateWidgetCategory(widgetId:number,category: any): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this.http.patch(this.url + 'views/' + widgetId + "/",
                this.camelCaseModelToSnakeCaseJson(category),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false,
            false
        ).pipe(take(1));
    }

    public getProfiles(): Observable<any> {
        return this._http
            .get(this.url + 'ui_profiles/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
            })
            .pipe(take(1));
    }
}
