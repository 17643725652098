import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
    Renderer2
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RegisterInterface } from '../../../../../core/services/api/auth/data/register.interface';
import { Subject, throwError, Observable } from 'rxjs';
import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { tap, catchError, takeUntil, finalize, map } from 'rxjs/operators';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';


@Component({
    selector: 'fap-register',
    templateUrl: './fap_register.component.html',
    styleUrls: ['./fap_register.component.scss']
})
export class FapRegisterComponent implements OnInit, OnDestroy {
    public registerForm: UntypedFormGroup;
    public data$: Observable<Data>;
    public gdprForm: UntypedFormGroup;
    private unsubscribe: Subject<any>;
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    disableScrollDown = false;
    public pageEnd = false;
    public defaultLang = null;
    public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';
    
    constructor(
        private formBuilder: UntypedFormBuilder,
        private toastyService: ToastrService,
        public translate: TranslateService,
        private authService: AuthService,
        public router: Router,
        private el: ElementRef, private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute) {
        console.log(localStorage.getItem('language'));
        this.unsubscribe = new Subject();
    }

    public ngOnInit(): void {
        this.initRegisterForm();
        this.initGdprForm();
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
              mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
          }));
          
          const gdprUrl = `${environment.mediaUrl}settings/gdpr_${localStorage.getItem('language')}.html?timestamp=${new Date().getTime()}`;
          
          fetch(gdprUrl)
            .then(response => response.text())
            .then(text => {
              document.getElementById('gdprContent').innerHTML = text;
            });          
        this.renderer.selectRootElement(this.el.nativeElement.querySelector('input[name="firstName"]')).focus();
    }

    public ngOnDestroy(): void {
         this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }


    public submit(): void {
        if(this.gdprForm.get('accept').value === true) {
        const body: RegisterInterface = {
            firstName: this.registerForm.controls['firstName'].value,
            lastName: this.registerForm.controls['lastName'].value,
            email: this.registerForm.controls['email'].value,
        };
        localStorage.setItem('temp_user_name', this.registerForm.controls['firstName'].value + ' ' + this.registerForm.controls['lastName'].value)
        this.authService.register(body).pipe(
            tap(() => {
                localStorage.setItem('register_email', body.email);
                this.toastyService.success(this.translate.instant('auth.register.mailSent'));
                this.router.navigateByUrl('/login');
            }),
            catchError((error: HttpErrorResponse) => {
                console.log(error);
                this.toastyService.error(this.translate.instant('auth.register.userExists'));
                this.router.navigateByUrl('/login');
                return throwError(error);
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.cdr.markForCheck();
            })
        ).subscribe(), (err => {
            console.log(err);
            this.toastyService.error(err.message);
        })
        } else {
            this.toastyService.warning(this.translate.instant('auth.register.acceptTerms'));
            return;
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.registerForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    private initRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            lastName: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            email: ['', Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                    Validators.maxLength(320)
                ]),
            ]
        });
    }

    showModal() {
        if(this.registerForm.invalid) {
            Object.keys(this.registerForm.controls).forEach((controlName: string) =>
                this.registerForm.controls[controlName].markAsTouched()
            );
            return;
        } else {
            const elem = document.getElementById('custom_modal');
            const parent = document.getElementById('parent');
            elem.classList.add('active');
            parent.classList.add('backdrop');
        }
    }

    hideModal() {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.remove('active');
        parent.classList.remove('backdrop');
    }

    private initGdprForm() {
        this.gdprForm = this.formBuilder.group({
            accept: [false, Validators.required]
        });
    }

    onScroll(): void {
        const el = document.querySelector('#el')
        const observer = new window.IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            this.pageEnd = true;
            this.gdprForm.get('accept').setValue(true);
            return
          } else {
              return
          }
        }, {
          root: null,
          threshold: 0.1,
        })
        
        observer.observe(el);
    }
}
