import { Component } from '@angular/core';


@Component({
    templateUrl: './farm.component.html',
    styleUrls: ['./farm.component.scss']
})
export class FarmComponent {

}
