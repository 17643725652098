<div class="container-fluid p-0">
    <div class="mb-5 pb-4">
        <fap-widget-board
            [widgets]="widgets"
            [sensors]="sensors"
            [farms]="farms"
            [lots]="lots"
            (addWidget)="addWidget.emit($event)"
            (deleteWidget)="deleteWidget.emit($event)">
        </fap-widget-board>
    </div>
</div>


<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="example-boundary">
                <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag>
                  I can only be dragged within the dotted container
                </div>
                <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag>
                  I can only be dragged within the dotted container
                </div>
                <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag>
                  I can only be dragged within the dotted container
                </div>
                <div class="example-box" cdkDragBoundary=".example-boundary" cdkDrag>
                  I can only be dragged within the dotted container
                </div>
            </div>
        </div>
    </div>
</div> -->
