import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { FarmInterface } from '../../../../views/pages/farm/data/farm.interface';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { PersonFarmInterface } from './data/person-farm.interface';
import { PersonFarmModel } from '../../../models/farm/person-farm.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { FarmModel } from '../../../models/farm/farm.model';
import { FapCustomRequestOptionsService } from '../../../base/fap-custom-request-option.service';
import { PostModel } from '../../../models/farm/posts.model';


@Injectable()
export class FarmService extends FapRestBaseService {

    public selectFarm: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/farm/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getFarms(params?: {}): Observable<ResponseModel<FarmModel[]>> {
        return this.mapRequest<FarmModel[]>(
            this._http.get(this.url + 'farm/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            FarmModel,
            true
        ).pipe(take(1));
    }

    public getFarm(farmId: number): Observable<ResponseModel<FarmModel>> {
        return this.mapRequest<FarmModel>(
            this._http.get(this.url + 'farm/' + farmId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            FarmModel,
            false
        ).pipe(take(1));
    }

    public createFarm(farm: FarmInterface): Observable<ResponseModel<FarmModel>> {
        return this.mapRequest(
            this._http.post(this.url + 'farm/',
                this.camelCaseModelToSnakeCaseJson(farm),
                FapAPIRequestOptions.withTokenRequestOptions),
            FarmModel,
            false
        );
    }

    public updateFarm(farmId: number, farm: FarmInterface): Observable<ResponseModel<FarmModel>> {
        return this.mapRequest(
            this._http.patch(this.url + 'farm/' + farmId + '/',
                this.camelCaseModelToSnakeCaseJson(farm),
                FapAPIRequestOptions.withTokenRequestOptions),
            FarmModel,
            false
        );
    }


    public getPeople(): Observable<ResponseModel<PersonFarmModel[]>> {
        return this.mapRequest<PersonFarmModel[]>(
            this._http.get(this.url + 'people_admin/', {
                headers: FapCustomRequestOptionsService.withTokenHeaders
            }),
            PersonFarmModel,
            true
        ).pipe(take(1));
    }

    public removePersonFromFarm(person: PersonFarmInterface): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'people_admin/remove_person/',
                this.camelCaseModelToSnakeCaseJson(person),
                FapAPIRequestOptions.withTokenRequestOptions)
      );
    }

    public addPersonToFarm(person: PersonFarmInterface): Observable<any> {
        return this.mapRequest(
            this._http.post(this.url + 'people_admin/add_person/',
                this.camelCaseModelToSnakeCaseJson(person),
                FapAPIRequestOptions.withTokenRequestOptions)
      );
    }

    public deleteFarm(farmId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'farm/' + farmId + '/',
            FapAPIRequestOptions.withTokenRequestOptions)
        );
    }

    public getPosts(params?: {}): Observable<ResponseModel<PostModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'posts/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false
        );
    }

    public getPost(postId: number): Observable<ResponseModel<PostModel>> {
        return this.mapRequest(
            this._http.get(this.url + 'posts/' + postId, {
                headers: FapAPIRequestOptions.withTokenHeaders
            }),
            false
        );
    }

    public getNewPosts(params?: {}): Observable<ResponseModel<PostModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'posts/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false
        );
    }

    public filterPost(params?: {}): Observable<ResponseModel<PostModel[]>> {
        return this.mapRequest(
            this._http.get(this.url + 'posts/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            false
        );
    }

    public updatePost(postId:number, params:{}): Observable<ResponseModel<PostModel[]>> {
        return this.mapRequest(
            this._http.patch(this.url + 'posts/' + postId + '/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions),
            FarmModel,
            false
        );
    }

    public sendMessage(personIds:number[], data:any): Observable<ResponseModel<PostModel[]>> {
        return this.mapRequest(
            this._http.post(this.url + 'posts/send/?to='+personIds.toString(),
            this.camelCaseModelToSnakeCaseJson(data),
            FapAPIRequestOptions.withTokenRequestOptions)
        );
    }

    public deletePost(postId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'posts/' + postId + '/',
            FapAPIRequestOptions.withTokenRequestOptions)
        );
    }
}
