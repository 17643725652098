import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { UserInterface } from './data/user.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { UserModel } from '../../../models/user/user.model';
import { Menu } from '../../../../shared/services/nav.service';


@Injectable()
export class UserService extends FapRestBaseService {

    primaryColor = '#007bff';
    secondaryColor = '#6c757d';

    private currentUser: BehaviorSubject<any> = new BehaviorSubject({});
    private themeVariables: BehaviorSubject<any> = new BehaviorSubject({});
    private timezone: BehaviorSubject<string> = new BehaviorSubject('');
    private activatedMenus: BehaviorSubject<Menu[]> = new BehaviorSubject(<Menu[]>[])
    getCurrentUser = this.currentUser.asObservable();
    getMenus = this.activatedMenus.asObservable();
    getTimezone = this.timezone.asObservable();
    getTheme = this.themeVariables.asObservable();

    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/auth/user/');
    }

    public setCurrentUser(user) {
        this.currentUser.next(user);
    }

    public setTheme(theme) {
        this.themeVariables.next(theme);
        console.log(this.themeVariables);
    }

    public setMenus(menus) {
        this.activatedMenus.next(menus);
    }

    public setTimeZone(timezone) {
        this.timezone.next(timezone);
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getUser(): Observable<ResponseModel<UserModel>> {
        return this.mapRequest<UserModel>(
            this._http.get(this.url, FapAPIRequestOptions.withTokenRequestOptions),
            UserModel
        );
    }

    public updateUser(user: any): Observable<ResponseModel<UserModel>> {
        const snakeUser = this.camelCaseModelToSnakeCaseJson(user)
        return this.mapRequest<UserModel>(
            this.http.patch(this.url + snakeUser.id + '/', snakeUser,
            FapAPIRequestOptions.withTokenRequestOptions),
            UserModel
        );
    }

    // public changePassword(body: ChangePasswordInterface): Observable<{}> {
    //     const requestOptions = FapCustomRequestOptionsService.tokenRequestOptions();
    //     return this.mapRequest(
    //         this._http.post(this.url + 'change_password/',
    //             body,
    //             { headers: new HttpHeaders({
    //                 'content-type': 'application/json',
    //                 'accept': 'application/json;q=0.9,*/*;q=0.8',
    //                 'authorization': 'Basic ' + btoa('test'),
    //             }),
    //             withCredentials: false}
    //         )
    //     );
    // }

}
