import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
  } from '@angular/core';
  import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
  } from '@angular/forms';
  import { TranslateService } from '@ngx-translate/core';
  import * as moment from 'moment';
  import { ToastrService } from 'ngx-toastr';
  import { Subscription } from 'rxjs';
  import { environment } from '../../../../environments/environment';
  import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
  import { NoteModel } from '../../../core/models/notes/note.model';
  import { WidgetModel } from '../../../core/models/widget/widget.model';
  import { CacheResolverService } from '../../../core/services/api/cache/cache-resolver.service';
  import { CompanyService } from '../../../core/services/api/company/company.service';
  import { NotesService } from '../../../core/services/api/farm/notes.service';
  import { SensorService } from '../../../core/services/api/sensor/sensor.service';
  import { DataService } from '../../../core/services/websocket/data.service';
  import { FapModalComponent } from '../../partials';
  
  export enum AcquisitionCodes {
      ACQUISITION_LIST = 410,
      ACQUISITION_DETAIL = 415,
      SEND_REQUEST = 412,
      DELETE_ACQUISITION = 420
  }
  
  @Component({
    selector: 'fap-crop-eye-simple-widget',
    templateUrl: './fap-crop-eye-simple-widget.component.html',
    styleUrls: ['./fap-crop-eye-simple-widget.component.scss'],
  })
  export class FapCropEyeSimpleWidgetComponent implements OnInit, OnDestroy, OnChanges {
  
  //   @ViewChild('nav') slider: NgImageSliderComponent;
    @Input()
    public widget: WidgetModel;
  
    public selectedWidget;
  
    @Input()
    public notes: NoteModel[];
  
    public sensor = null;
  
    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  
    @ViewChild('addEditWidgetModal') addEditWidgetModal: FapModalComponent;
    @ViewChild('notesModal') notesModal: FapModalComponent;
  
    public zoomLevel = 0;
  
    public images: any[];
  
    public zoomLevelString = '0x';
    public currentPan = 0;
    public currentIteration = 0;
    public currentObject;
    public currentObjectLength;
    public currentPanLength;
    public currentImageIndex: number;
  
    public currentImage: any;
  
    public toggleMain = false;
    public toggleSub = false;
    public personList: any;
    public imageForm: UntypedFormGroup;
    public selectedType: number;
    public showMenu = true;
    public requestIds = [];
    public initialPanValue = 0;
    public initialZoomValue = 0;
    public selectedRequest;
    public subscriptions: Array<Subscription> = [];
    public eyeData;
    public mediaUrl = environment.mediaUrl;
  
    selectedDropdownValue = 0;
    public selectedRecord = null;
  
    public zoomLevels = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20,
    ];
    public panLevels = [
        0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225,
        240, 255, 270, 285, 300, 315, 330, 345,
    ];
    public tiltLevels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
  
    public dropdownValue = [];
    public acquisitionList = [];
    public AqLists = [];
    public listLength: number;
    public imageTypes = [
        { id: 20, name: 'Single Image' },
        { id: 30, name: '360° Image' },
    ];
    public selected = 0;
    public batteryLevel = null;
    public actions = null;
    public batteryValue = null;
    public apiUrl = environment.baseBackendUrl;
    public batterysensor = null;
    public batteryData = null;
    public hideCtrls = false;
    public translatedNames: any = [];
    @Input() public langString: string;
    @ViewChild('confirmPopup') confirmPopup: FapModalComponent;
  
      constructor(
          private globalRegistry: GlobalRegistryService,
          public companyService: CompanyService,
          private fb: UntypedFormBuilder,
          public sensorService: SensorService,
          public toastr: ToastrService,
          public translateService: TranslateService,
          public dataService: DataService,
          public notesService: NotesService,
          public cacheService: CacheResolverService
      ) {
      }
  
      public initForm(): void {
          console.log(this.zoomLevelString);
          this.personList = this.globalRegistry.systemData.persons;
          this.imageForm = this.fb.group({
              pan: [this.currentIteration ? this.currentIteration : 0, [Validators.max(350), Validators.min(0)]],
              tilt: [
                  this.currentPan ? this.currentPan : 0,
                  [Validators.required, Validators.max(90), Validators.min(0)],
              ],
              zoom: [this.zoomLevelString ? +this.zoomLevelString.substring(0, this.zoomLevelString.length-1) : 0, [Validators.required]],
              size: [null, Validators.required]
          });
      }
  
      public restrict(value) {
          // console.log(value.target.value);
          if (value.target.value > 90) {
              const newValue = 90;
              this.imageForm.get('tilt').patchValue(newValue);
          }
      }
  
      public toggleImageMenu() {
          this.showMenu = !this.showMenu;
      }
  
      public getLotName(lotId: number = this.widget.objectId): string {
          const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
          return lot ? lot.name : '';
        }
  
      statusChange(list) {
          list.accepted = !list.accepted;
          // console.log(list);
      }
  
      prevImageClick() {
          // this.slider.prev();
          if (this.currentImageIndex === 0) return;
          this.currentImageIndex = this.currentImageIndex - 1;
          this.currentImage = this.images[this.currentImageIndex];
      }
  
      nextImageClick() {
          // this.slider.next();
          if (
              !this.images.length ||
              this.currentImageIndex === this.images.length - 1
          )
              return;
          this.currentImageIndex = this.currentImageIndex + 1;
  
          this.currentImage = this.images[this.currentImageIndex];
      }
  
      getFormattedDate(time): string {
          const formattedDate = moment(time, 'M/D/YYYY, h:mm:ss A').format('DD MMM YYYY HH:mm');
          return formattedDate;
        }
  
      ngOnInit(): void {
          this.initForm();
          this.images = [];
          this.personList=this.globalRegistry.systemData.persons
          this.currentImageIndex = 0;
          this.currentImage = this.images[this.currentImageIndex];
          setTimeout(() => {
              this.getConfig();
              this.getAcquisition();
              // this.getNotes();
          }, 1000);
          this.subscriptions.push(this.dataService.getTrigger.subscribe((res) => {
              if (res == true) {
                  console.log(res);
                  this.getData();
              }
          }));
          this.translatedNames = this.globalRegistry.systemData.translations;
      }
  
      getTranslation(translation) {
          const t =this.globalRegistry.systemData.translations.filter(trans => {
            return trans.id === translation
          });
          if(t[0]) {
              if(t[0][this.langString] === null || t[0][this.langString] === '') {
                return translation
              } else {
                return t[0][this.langString];
              }
            } else {
              return translation
            }
        }
  
      ngOnDestroy(): void {
          this.subscriptions.forEach(s => {
              s.unsubscribe();
          });
      }
  
      showModal(widget, pos?:any): void {
          this.initForm();
          this.selectedWidget = widget;
          this.addEditWidgetModal.showModal();
          if(pos) {
              this.imageForm.get('focus').setValue({x: pos.x, y: pos.y})
          }
      }
  
      showDataModal(widget): void {
          this.selectedWidget = widget;
          this.notesModal.showModal();
      }
  
      removeItem(id) {
          console.log(id);
          const postData = {
              cmd: 420,
              sensor: this.sensor,
              params: {},
              id,
          };
          this.subscriptions.push(this.sensorService.deleteAcquisition(postData).subscribe(() => {
              this.AqLists = [];
              this.acquisitionList = [];
              this.getAcquisition();
              this.addEditWidgetModal.hideModal();
          }));
      }
  
      changeType(event) {
          this.selectedType = event.value;
          if (event === 30) {
              this.imageForm.get('pan').patchValue([360, 360]);
          } else {
              this.imageForm.get('pan').patchValue([]);
          }
      }
  
      toDateTime(secs) { //epoch
          const date = new Date(secs * 1000);
          const localTime = date.toLocaleString('en-US');
          return localTime;
      }
  
      getData() {
          this.subscriptions.push(this.dataService.getEyeData.subscribe((res) => {
              this.eyeData = res;
              console.log(res);
              console.log(this.sensor);
              console.log(this.dropdownValue);
              if (
                  this.eyeData.cmd == AcquisitionCodes.ACQUISITION_LIST &&
                  this.eyeData.params.sensor ==
                      this.sensor
              ) {
                  console.log(this.eyeData)
                  setTimeout(() => {
                      this.getAcquisition();
                      this.eyeData = null;
                  }, 100)
                  return
              }
              if (
                  this.eyeData.cmd == AcquisitionCodes.ACQUISITION_DETAIL &&
                  this.eyeData.params.sensor ==
                      this.sensor
              ) {
                  
                  setTimeout(() => {
                      if(this.eyeData?.params?.record?.id) {
                      console.log(this.eyeData);
                      if (
                          this.eyeData?.params && this.selectedRequest.id == this.eyeData?.params?.record?.id
                      ) {
                          this.selectedRequest.data =
                          this.eyeData.params.record.data;
                      } else {
                          const date = new Date(this.eyeData.params.record.time * 1000);
                          const localTime = date.toLocaleString('en-US');
                          const newData = {
                              id: this.eyeData.params.record.id,
                              time: localTime,
                              images: [],
                              value: localTime,
                          }
                          this.dropdownValue.unshift(newData);
                          const request = {
                              id: this.eyeData.params.record.id,
                              order: this.eyeData.params.state.order,
                              user: this.eyeData.params.state.user,
                              accepted: this.eyeData.params.state.accepted,
                              time: this.toDateTime(this.eyeData.params.record.id),
                          };
                          this.acquisitionList.unshift(request);
                          this.selectedRequest.data =
                          this.eyeData.params.record.data;
                      }
                      console.log(this.selectedRequest);
                      if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
                          const image = {
                              image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                                  this.currentPan
                              ][this.currentIteration],
                              thumbImage:
                              this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                                      this.currentPan
                                  ][this.currentIteration],
                              tilt: this.currentIteration,
                              pan: this.currentPan,
                              
                          };
                          this.images = [image];
                          } else {
                              this.images = [];
                          }
                      // this.changeDropdown(this.eyeData.params.record.id);
                      this.eyeData = null;
                      return
                  }
                  }, 1000);
              
              }
              if (this.eyeData.cmd == 600) {
                  this.toastr.success(this.eyeData.params.info);
              }
              if (
                  this.eyeData.cmd == 500 &&
                  this.eyeData.list[0].sensor == this.batterysensor
              ) {
                  // console.log(this.batteryData);
                  setTimeout(() => {
                      this.batteryValue = this.eyeData.list[2].valueFloat;
                      if (this.batteryValue) {
                          const value =
                              ((this.batteryValue -
                                  this.batteryData.battery.min) *
                                  100) /
                              (this.batteryData.battery.max -
                                  this.batteryData.battery.min);
                          // console.log(value);
                          this.batteryLevel = value.toFixed(0);
                      }
                  }, 100);
              }
          }));
          this.dataService.setTrigger(false);
      }
  
      submit() {
          this.images = [];
          if (this.selectedType === 30) {
              this.imageForm.get('pan').patchValue([360, 360]);
          }
          const postObj = {
              pan: [this.imageForm.get('pan').value],
              zoom: [this.imageForm.get('zoom').value],
              tilt: [this.imageForm.get('tilt').value],
            }
          const postData = {
              cmd: AcquisitionCodes.ACQUISITION_DETAIL,
              sensor: this.sensor,
              params: [postObj],
          };
          // this.imageForm.get('zoom').reset();
          // this.imageForm.get('pan').reset();
          // this.imageForm.get('tilt').reset();
  
          this.subscriptions.push(this.sensorService.postAcquisition(postData).subscribe(
              () => {
                  // console.log(response);
                  this.listLength+= 1;
                  this.getAcquisition();
                  // var snowball = document.getElementById('sball');
                  // snowball.style.display = 'none';
                  this.toastr.success(
                      this.translateService.instant(
                          'widget.CreatedNewAcquistionRequest'
                      )
                  );
              },
              (): void => {
                  this.toastr.error(
                      this.translateService.instant(
                          'widget.failedToCreateNewAcquisition'
                      )
                  );
              }
          ));
          this.addEditWidgetModal.hideModal();
          // this.confirmPopup.hideModal()
          
      }
  
      getConfig() {
          this.subscriptions.push(this.sensorService
              .getAcquisition({
                  cmd: 100,
                  sensor: this.sensor,
                  params: {},
              })
              .subscribe((data) => {
                  console.log(data);
                  if (data.battery) {
                      // (value - min)*100/(max-min)
                      this.batteryData = data;
                      this.batterysensor = data.battery.sensor;
                      if (this.batteryValue) {
                          const value =
                              ((this.batteryValue - data.battery.min) * 100) /
                              (data.battery.max - data.battery.min);
                          // console.log(value);
                          this.batteryLevel = value.toFixed(0);
                      }
                  }
                  if (data.actions) {
                      this.actions = data.actions;
                      console.log(this.actions);
                  }
              }));
      }
  
      additionalCmd(cmd, id) {
          this.subscriptions.push(this.sensorService
              .getAcquisition({
                  cmd: cmd,
                  sensor: this.sensor,
                  id: id,
                  params: {
                      "zoom": this.initialZoomValue,
                      "pan": this.currentIteration,
                      "tilt": this.currentPan,
                  },
              })
              .subscribe(() => {
                  // console.log(data);
              }));
      }
  
      onImageClick(event: MouseEvent, widget) {
          const x = event.offsetX;
          const y = event.offsetY;
          const pos = {x, y};
          this.showModal(widget, pos)
        }
  
      getAcquisition() {
          this.AqLists = [];
          this.images = [];
          this.acquisitionList = [];
          this.dropdownValue = [];
          const params = { filter: 'all' };
          function toDateTime(secs) { //epoch
              const date = new Date(secs * 1000);
              const localTime = date.toLocaleString('en-US');
              // const utcTime = date.toUTCString();
              return localTime;
          }
          const url = this.sensorService.getUrl('command/');
          this.cacheService.delete(url+'cmd='+AcquisitionCodes.ACQUISITION_LIST+'&sensor='+this.sensor+'&params='+params)
          this.subscriptions.push(this.sensorService
              .getAcquisition({
                  cmd: AcquisitionCodes.ACQUISITION_LIST,
                  sensor: this.sensor,
                  params: params,
              })
              .subscribe(
                  (data) => {
                      const keys = [];
                      this.dropdownValue = [];
                      for (const key in data) {
                          const request = {
                              id: key,
                              order: data[key].order,
                              user: data[key].user,
                              accepted: data[key].accepted,
                              time: toDateTime(key),
                          };
                          keys.push(key);
                          const dValue = {
                              id: Number(key),
                              value: toDateTime(key),
                              images: [],
                              time: toDateTime(key),
                          };
  
                          this.acquisitionList.push(request);
                          this.dropdownValue.push(dValue);
                          this.listLength = this.dropdownValue.length;
                          this.requestIds.push(key);
                      }
                      this.dropdownValue.reverse();
                      this.acquisitionList.reverse();
                      console.log(this.acquisitionList);
                      if(this.acquisitionList.length) {
                          this.changeDropdown(this.acquisitionList[0].id);
                      }
                      this.eyeData = null;
                      return
                  },
                  (error): void => {
                      console.log(error);
                      this.toastr.error(
                          this.translateService.instant(
                              'Failed to fetch acquisition'
                          )
                      );
                  }
              ));
      }
  
      hideMain() {
          this.toggleMain = false;
      }
  
      toggleMenu() {
          this.toggleMain = !this.toggleMain;
      }
  
      public zoomInClick() {
          this.images = [];
          this.initialPanValue = 0;
          this.currentPan = 0;
          this.initialZoomValue = this.initialZoomValue + 1;
  
          this.zoomLevelString = Object.keys(this.selectedRequest.data)[
              this.initialZoomValue
          ];
  
          this.currentObject = this.selectedRequest.data[this.zoomLevelString];
          this.currentPan = Number(Object.keys(this.currentObject)[0]);
          this.currentPanLength = Object.keys(
              this.currentObject[this.currentPan]
          ).length;
  
          this.currentIteration = Number(
              Object.keys(this.currentObject[this.currentPan])[0]
          );
          if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
          const image = {
              image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                  this.currentPan
              ][this.currentIteration],
              thumbImage:
              this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                      this.currentPan
                  ][this.currentIteration],
              tilt: this.currentIteration,
              pan: this.currentPan,
              
          };
          this.images = [image];
          } else {
              this.images = [];
          }
      }
  
      public zoomOutClick() {
          this.images = [];
          this.initialPanValue = 0;
          this.currentPan = 0;
          this.initialZoomValue = this.initialZoomValue - 1;
  
          this.zoomLevelString = Object.keys(this.selectedRequest.data)[
              this.initialZoomValue
          ];
          this.currentObject = this.selectedRequest.data[this.zoomLevelString];
          this.currentPan = Number(Object.keys(this.currentObject)[0]);
  
          this.currentPanLength = Object.keys(
              this.currentObject[this.currentPan]
          ).length;
  
          this.currentIteration = Number(
              Object.keys(this.currentObject[this.currentPan])[0]
          );
          if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
          const image = {
              image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                  this.currentPan
              ][this.currentIteration],
              thumbImage:
              this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                      this.currentPan
                  ][this.currentIteration],
              tilt: this.currentIteration,
              pan: this.currentPan,
              
          };
          this.images = [image];
          } else {
              this.images = [];
          }
      }
  
      public previousNextValue(value) {
          const previousValue = this.selectedDropdownValue;
          if (previousValue === 0) {
              this.selectedDropdownValue = this.dropdownValue[0].id;
          } else {
              const currentIndex = this.dropdownValue.findIndex(
                  (x) => x.id === previousValue
              );
              this.selectedDropdownValue =
                  this.dropdownValue[
                      value ? currentIndex + 1 : currentIndex - 1
                  ].id;
          }
          this.changeDropdown(this.selectedDropdownValue);
      }
  
      public nextPan() {
          this.images = [];
          this.initialPanValue = this.initialPanValue + 1;
          this.currentIteration = Number(
              Object.keys(this.currentObject[this.currentPan])[
                  this.initialPanValue
              ]
          );
          if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
          const image = {
              image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                  this.currentPan
              ][this.currentIteration],
              thumbImage:
              this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                      this.currentPan
                  ][this.currentIteration],
              tilt: this.currentIteration,
              pan: this.currentPan,
              
          };
          this.images = [image];
          } else {
              this.images = []
          }
  
          if (!this.currentIteration) {
              this.currentPan = Number(Object.keys(this.currentObject)[1]);
              this.currentPanLength = Object.keys(
                  this.currentObject[this.currentPan]
              ).length;
              if (this.currentPanLength == this.initialPanValue) {
                  this.currentPan = Number(Object.keys(this.currentObject)[2]);
              }
              this.currentIteration = Number(
                  Object.keys(this.currentObject[this.currentPan])[
                      this.initialPanValue
                  ]
              );
              this.initialPanValue = this.initialPanValue + 1;
          }
      }
  
      public prevPan() {
          this.images = [];
          this.initialPanValue = this.initialPanValue - 1;
  
          this.currentIteration = Number(
              Object.keys(this.currentObject[this.currentPan])[
                  this.initialPanValue
              ]
          );
          if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
          const image = {
              image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                  this.currentPan
              ][this.currentIteration],
              thumbImage:
              this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                      this.currentPan
                  ][this.currentIteration],
              tilt: this.currentIteration,
              pan: this.currentPan,
              
          };
          this.images = [image];
          } else {
              this.images = []
          }
      }
  
      hideCtrlsFn(res, currentPan){
          if(Object.keys(res['data'][Object.keys(res.data)[0]][currentPan]).length === 1) return true
          else return false
      }
  
      public changeDropdown(value) {
          setTimeout(() => {
              this.images = [];
          });
          function toDateTime(secs) {
              const t = new Date(1970, 0, 1); // Epoch
              t.setSeconds(secs).toLocaleString('en-US');
              console.log(moment.utc(t).local());
              return t;
          }
          this.subscriptions.push(this.sensorService
              .getAcquisition({
                  cmd: AcquisitionCodes.SEND_REQUEST,
                  sensor: this.sensor,
                  params: {},
                  id: value,
              })
              .subscribe(
                  (res) => {
                      this.currentPan = 0;
                      this.currentIteration = 0;
                      this.initialPanValue = 0;
                      this.initialZoomValue = 0;
                      this.currentObjectLength = 0;
                      // this.currentFocus = {x: 50, y: 50}
                      this.currentObject = {};
                      res.time = toDateTime(res.id);
                      this.images = [];
                      this.selectedRequest = res;
                      console.log(res);
                      this.zoomLevelString = Object.keys(
                          this.selectedRequest.data
                      )[0];
                      this.currentObject =
                          this.selectedRequest.data[this.zoomLevelString];
  
                      this.currentObjectLength = Object.keys(
                          this.selectedRequest.data
                      ).length;
                      this.currentPan = Number(
                          Object.keys(this.currentObject)[0]
                      );
                      this.currentIteration = Number(
                          Object.keys(this.currentObject[this.currentPan])[0]
                      );
  
                      this.currentPanLength = Object.keys(
                          this.currentObject[this.currentPan]
                      ).length;
                      const currentPan = this.currentPan
                      if(Object.keys(this.selectedRequest.data).length === 1){
                          this.hideCtrls = this.hideCtrlsFn(res, currentPan);
                      } else {
                          this.hideCtrls = false;
                      }
                      if(this.selectedRequest.data[this.zoomLevelString][this.currentPan][this.currentIteration]) {
                      const image = {
                          image: this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                              this.currentPan
                          ][this.currentIteration],
                          thumbImage:
                          this.mediaUrl + this.selectedRequest.data[this.zoomLevelString][
                                  this.currentPan
                              ][this.currentIteration],
                          tilt: this.currentIteration,
                          pan: this.currentPan,
                          
                      };
                      this.images = [image];       
                      } else {
                          this.images = [];
                      }
                      console.log(this.images)
                      const entry = this.dropdownValue.findIndex(
                          (x) => x.id === parseInt(value)
                      );
                      if(this.dropdownValue[entry]?.id) {
                          this.selectedDropdownValue = this.dropdownValue[entry].id;
                      }
                      this.eyeData = null;
                      return;
                  },
                  (error): void => {
                      console.log(error);
                      // this.router.navigate(['login'])
                      this.images = [];
                      this.toastr.error(
                          this.translateService.instant('Failed to fetch record')
                      );
                  }
              ));
  
          // console.log(value);
      }
  
      closeModal(widget): void {
          this.selectedWidget = widget;
          this.confirmPopup.hideModal();
      }
  
      showConfirmModal(pos) {
          if(pos) {
              this.imageForm.get('focus').setValue({x: pos.x, y: pos.y})
          }
          this.confirmPopup.showModal();
      }
  
      // getNotes() {
      //     this.subscriptions.push(this.notesService
      //         .getWidgetNotes(
      //             `?sensor=${this.sensor}&service=eye`
      //         )
      //         .subscribe(() => {
      //             // console.log(data);
      //         }));
      // }
  
      ngOnChanges(changes: SimpleChanges): void {
          if(Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget) {
              const sensorList = Object.values(this.widget.settings['options']);
              this.sensor = sensorList.find(item => item['ref'] === '250_120')?.['id'];
              console.log(this.sensor);
          }
      }
  
      public json1 = {
          id: 121001231,
          time: '21-09-30T10:21:40.000Z',
          data: {
              '0x': [
                  [
                      0,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/0-0-0.jpg',
                  ],
                  [
                      90,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/0-90-0.jpg',
                  ],
              ],
              '5x': [
                  [
                      90,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/5-90-0.jpg',
                  ],
              ],
              '10x': [
                  [
                      90,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/10-90-0.jpg',
                  ],
              ],
              '15x': [
                  [
                      90,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/15-90-0.jpg',
                  ],
              ],
              '20x': [
                  [
                      90,
                      0,
                      'https://chdpapi.lumbara.com/media/eye/0EA13E/2021-09-30/10_21_40/20-90-0.jpg',
                  ],
              ],
          },
      };
  }
  