import { Routes } from '@angular/router';
import { LotEditContainerComponent } from './containers/lot-edit-container/lot-edit-container.component';
import { CultureEditContainerComponent } from './containers/culture-edit-container/culture-edit-container.component';
import { AccessGuard } from '../../../core/guard/access.guard';

export const lotRoutes: Routes = [
    {
        path: 'edit-culture/:lotId',
        component: CultureEditContainerComponent,
        data:{
            key:"lots",
            index:1
        },
        canActivate:[AccessGuard]
    },
    {
        path: 'edit/:lotId',
        component: LotEditContainerComponent,
        data: {
            filters: {
                hasDateFilters: true
            },
            key:"lots",
            index:1
        },
        canActivate:[AccessGuard]
    },
    {
        path: 'add',
        component: LotEditContainerComponent,
        data: {
            key:"lots",
            index:1
        },
        canActivate:[AccessGuard]
    },
    {
        path: '',
        redirectTo: 'add',
        pathMatch: 'full'
    },
];
