import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OverviewService } from '../../../core/services/api/overview/overview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AgmMap } from '@agm/core';
import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions } from '../../../shared/widgets/fap-chart-widget/fap-chart-widget.component';
import * as localData from './data.json';
import moment from 'moment';

@Component({
    selector: 'fap-overview',
    templateUrl: './fap-overview.component.html',
    styleUrls: ['./fap-overview.component.scss'],
   
})
export class FapOverviewComponent implements OnInit, OnDestroy {
    public token: string = environment.overviewToken;
    public overviewData = null;
    public imageObjects = [];
    public apiUrl = environment.baseBackendUrl;
    public activeSlide = null;
    public counter = 0;
    public index:number;
    public size = '';
    public innerWidth: any;
    public location = '';
    defaultCenter = {lat: 51.673858, lng: 7.815982};
    currentCenter = Object.assign({}, this.defaultCenter);
    zoom = 15;
    @ViewChild(AgmMap) agmMap: AgmMap;
    public mediaUrl = environment.mediaUrl;
    @ViewChild("chart") chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;
    public interactionTimer;
    currentTime: string;
    private intervalId: any;

    constructor(public overviewService: OverviewService, public route:ActivatedRoute, public router:Router) {
    }

    public get width() {
        return window.innerWidth;
    }

    public get height() {
      return window.innerHeight;
  }

    public get language() {
      return navigator.language.substr(0, 2);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.location = params['location'];
      this.token = params['token'] || environment.overviewToken;
      console.log(params['token']);
    });
    this.overviewService.overviewContent(this.token, {}).subscribe((data) => {
      this.overviewData = data.body;
      // this.overviewData = localData;
      this.imageObjects = this.overviewData.pages;
      if (data) {
        this.setNextImageTimer();
      }
  
      // Reset timer on interactions
      document.addEventListener('click', this.resetTimer.bind(this));
      document.addEventListener('scroll', this.resetTimer.bind(this));
    },
    (error) => {
      console.log(error);
      this.router.navigate(['login']);
    });
  
    // Define interactionTimer property
    this.interactionTimer = null;

    this.intervalId = setInterval(() => {
      this.currentTime = moment().format('HH:mm:ss');
    }, 1000);
  }
  
  resetTimer() {
    clearTimeout(this.interactionTimer);
    // this.setNextImageTimer();
  }
  
  setNextImageTimer() {
    if(this.overviewData.displaytime == 0) {
      return
    }
    this.interactionTimer = setTimeout(() => {
      this.showNextImage();
    }, this.overviewData.displaytime * 1000);
    return;
  }
  
  showNextImage() {
    clearTimeout(this.interactionTimer);
    const total = this.imageObjects.length - 1;
    this.counter = this.inc(total);
    // Set timer for the next slide
    this.setNextImageTimer();
  }
  
  showPreviousImage() {
    clearTimeout(this.interactionTimer);
    const total = this.imageObjects.length - 1;
    this.counter = this.dec(total);
  }

  goToSlide(pageId) {
    const pageIndex = this.overviewData.pages.findIndex(page => page.id === pageId);
    if (pageIndex !== -1) {
      this.counter = pageIndex;
  } else {
      console.error(`Page with ID ${pageId} not found.`);
  }
  }
  
  inc(total) {
    return this.counter < total ? this.counter + 1 : 0;
  }
  
  dec(total) {
    return this.counter > 0 ? this.counter - 1 : total;
  }
  
    mapReady(event) {
      console.log(event);
      this.setCurrentPosition();
    }

    private setCurrentPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.currentCenter.lat = position.coords.latitude;
          this.currentCenter.lng = position.coords.longitude;
          this.zoom = 15;
        });
      }
    }

    calculateCenter(markers: any[]): { lat: number; lng: number } {
      let totalLat = 0;
      let totalLng = 0;
  
      for (const m of markers) {
        totalLat += m.coords[0];
        totalLng += m.coords[1];
      }
  
      const averageLat = totalLat / markers.length;
      const averageLng = totalLng / markers.length;
  
      return { lat: averageLat, lng: averageLng };
    }
  
    async checkIcon(iconUrl: string): Promise<boolean> {
      try {
        const response = await fetch(iconUrl);
        return response.status === 200; // 200 means the image exists
      } catch (error) {
        return false; // Error occurred, image not available
      }
    }

    public getCalculation(input: string): string {
      const value = parseInt(input.replace('px', ''), 10);
      const result = value + 30;
      return `${result}px`;
  }
  
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }

}
