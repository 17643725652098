import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnDestroy {

  @ViewChild('cameraVideo') cameraVideo: ElementRef;
  @ViewChild('capturedImage') capturedImage: ElementRef;
  @ViewChild('retakeButton') retakeButton: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  @Input() public position = 'closeup';

  @Output() public emitImage: EventEmitter<any> = new EventEmitter();
  
  mediaStream: MediaStream;
  public image: File;

  showVideo = false;
  showRetakeButton = false;
  showTakeButton = false;
  cameraOpened = false;

  async openCamera() {
    this.capturedImage.nativeElement.src = '';
    this.image = null;
    try {
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.cameraVideo.nativeElement.srcObject = this.mediaStream;
      this.cameraOpened = true; // Set the flag to true
      this.showVideo = true;
      this.showTakeButton = true;
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  }

  async takePicture() {
    if (this.mediaStream) {
      const videoElement = this.cameraVideo.nativeElement;
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
  
      const context = canvas.getContext('2d');
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
      // Convert canvas image to a Blob
      canvas.toBlob(blob => {
        if (blob) {
          const imageFile = new File([blob], 'captured-image.jpeg', { type: 'image/jpeg' });
          this.image = imageFile;
          console.log(this.image);

          this.capturedImage.nativeElement.src = URL.createObjectURL(blob);
          
          // Stop the media stream and update button visibility
          this.mediaStream.getTracks().forEach(track => track.stop());
        }
      }, 'image/jpeg');
      this.showRetakeButton = true;
      this.showTakeButton = false;
      this.showVideo = false;
      this.mediaStream.getTracks().forEach(track => track.stop());
    }
  }
  
  
  retake() {
    this.capturedImage.nativeElement.src = '';
    this.image = null;
    this.openCamera();
    this.showRetakeButton = false;
    this.showTakeButton = true;
  }

  async chooseFromGallery() {
    const fileInput = this.fileInput.nativeElement;
    fileInput.click();
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach(track => track.stop());
      this.showVideo = false; // Hide the video tag
      this.showTakeButton = false;
      this.cameraOpened = false;
      this.showRetakeButton = false;
    }
  }

  handleFileInput(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files[0];

    this.image = selectedFile;

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        this.capturedImage.nativeElement.src = reader.result as string;
      };

      reader.readAsDataURL(selectedFile);
    }
  }

  next() {
    if(!this.image) {
      alert('Please take a picture to proceed');
      return;
    }
    const obj = {['img_'+this.position]: this.image}
    console.log(obj);
    this.emitImage.emit(obj);
    setTimeout(() => {
      this.image = null;
      this.capturedImage.nativeElement.src = '';
      this.showRetakeButton = false;
      this.cameraOpened = false;
    }, 100)
  }

  ngOnDestroy() {
    this.capturedImage.nativeElement.src = '';
    // this.mediaStream.getTracks().forEach(track => track.stop());
    this.image = null;
  }

}
