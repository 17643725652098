<form class="theme-form" [formGroup]="activityForm">
    <div>
        <mat-form-field>
            <input required matInput placeholder="{{ 'activity.title' | translate }}" formControlName="title">
        <mat-error *ngIf="activityForm.get('title').invalid">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
        </mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input matInput placeholder="Phase ID">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input formControlName="started"
                matInput
                type="datetime-local"
                placeholder="start date"
                placeholder="{{ 'activity.startedOn' | translate }}">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input formControlName="ended"
                matInput
                type="datetime-local"
                placeholder="start date"
                placeholder="{{ 'activity.endedOn' | translate }}">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>{{ 'activity.activityType' | translate }}</mat-label>
            <mat-select required formControlName="activityType">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #activityTypeFilter placeholder="Search">
                </mat-form-field>
                <mat-option *ngFor="let activityType of activityTypes | filter : activityTypeFilter.value" [value]="activityType.id">
                    {{ activityType.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="activityForm.get('activityType').invalid">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <input matInput placeholder="Info" formControlName="info">
        </mat-form-field>
    </div>
    <div>
        <mat-form-field>
            <mat-label>Tracking</mat-label>
            <mat-select>
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #trackingFilter placeholder="Search">
                </mat-form-field>
                <mat-option *ngFor="let tracking of trackingList | filter : trackingFilter.value" [value]="tracking">{{tracking}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="boxes">
        <div class="assigned-to-box">
            <span>Assigned to:</span>
            <div class="frame">
                <div class="frame-header"></div>
                <div class="justify-content-center h-75 d-flex">
                    <i class="fa fa-plus f-20 align-self-center"></i>
                </div>
            </div>
        </div>
        <div class="used-equipment-box">
            <span>Used Equipment:</span>
            <div class="frame">
                <div class="frame-header"></div>
                <div class="justify-content-center h-75 d-flex">
                    <i class="fa fa-plus align-self-center f-20"></i>
                </div>
            </div>
        </div>
        <div class="attached-equipment-box">
            <span>Attached Equipment 1:</span>
            <div class="frame">
                <div class="frame-header"></div>
                <div class="justify-content-center h-75 d-flex">
                    <i class="fa fa-plus align-self-center f-20"></i>
                </div>
            </div>
        </div>
        <div class="attached-equipment-box">
            <span>Attached Equipment 2:</span>
            <div class="frame">
                <div class="frame-header"></div>
                <div class="justify-content-center h-75 d-flex">
                    <i class="fa fa-plus align-self-center f-20"></i>
                </div>
            </div>
        </div>
    </div>
    <div>
        <fap-resources-table></fap-resources-table>
    </div>
</form>
<div class="col-md-12 mt-3 mt-md-5 d-flex justify-content-center">
    <button class="btn btn-success d-flex align-items-center" [disabled]="activityForm.invalid" (click)="submitForm()">Submit
        <i class="material-icons pl-1">send</i>
    </button>
</div>
