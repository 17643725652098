import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivityModel } from '../../../../core/models/activity/activity.model';
import { ActivityTypeModel } from '../../../../core/models/activity/activity-type.model';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-plan-dates',
  templateUrl: './plan-dates.component.html',
  styleUrls: ['./plan-dates.component.scss']
})
export class PlanDatesComponent implements OnChanges, OnInit {

  @Input() public dateColumns = [];
  @Input() public planData;
  @Input() public activity: any;
  @Input() public activityTypes: Array<ActivityTypeModel> = [];
  public activityRows = {real: null, planned: null};
  public columnWidth = 60;
  public langString
  public subscriptions: Array<Subscription> = [];

  constructor(private navService: NavService, public globalRegistry: GlobalRegistryService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('planData') && this.planData) {
      this.activityRows = { real: null, planned: null };
      this.planData.rows.forEach(row => {
        if (row && row.data.real && row.data.real.data.id === this.activity.data.id) {
          this.activityRows.real = row.data.real;
          this.activityRows.planned = row.data.planned;
        }
      });
    }
  }  

  getActivityTypeColor(activityTypeId: number) {
    let color = '#BA6969';
    this.activityTypes && this.activityTypes.length && this.activityTypes.forEach(type => {
      if(type.id === activityTypeId) {
        color = type.color;
      }
    });
    return color;
  }

  getActivityOverlayPosition(start: number): string {
    const leftPosition = start * this.columnWidth;
    return (leftPosition) + 'px';
  }
  
  getActivityOverlayWidth(start: number, end: number): string {
    const width = (end - start + 1) * this.columnWidth;
    return width + 'px';
  }
  
  hasPlannedItems(row: any): boolean {
    return row?.planned?.items && row.planned.items.length > 0;
  }
  
}
