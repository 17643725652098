import { Component } from '@angular/core';


@Component({
    templateUrl: './stock.component.html',
    styleUrls: ['./stock.component.scss']
})
export class StockComponent {

}
