import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    OnDestroy
} from '@angular/core';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { EquipmentTypeModel } from '../../../../../core/models/type/equipment-type.model';
import { NameValueInterface } from '../../../../../core/interfaces/general/name-value.interface';
import { PartyModel } from '../../../../../core/models/party/party.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
    selector: 'fap-equipments-layout',
    templateUrl: './fap_equipments-layout.component.html',
    styleUrls: ['./fap_equipments-layout.component.scss']
})
export class FapEquipmentsLayoutComponent implements OnChanges, OnInit, OnDestroy {

    @Input()
    public farms: Array<FarmModel>;

    @Input()
    public equipments: Array<EquipmentModel>;

    @Input()
    public equipmentTypes: Array<EquipmentTypeModel>;

    @Input()
    public equipmentPoweredTypes: Array<NameValueInterface>;

    @Input()
    public farmForecastMapping: { [key: number]: WeatherModel } = {};

    @Input()
    public parties: Array<PartyModel>;

    @Output()
    public deleteEquipment: EventEmitter<number> = new EventEmitter();

    public selectedEquipment: EquipmentModel;
    

    public removeEquipmentModalButtonPrimaryInterface: FapModalButtonInterface;
    public removeEquipmentModalButtonSecondaryInterface: FapModalButtonInterface;

    @ViewChild('removeEquipmentModal')
    public removeEquipmentModal: FapModalComponent;

    public equipmentsByFarm: { [key: string]: EquipmentModel[] } = {};

    public equipmentsWithNoFarm: Array<EquipmentModel> = [];
    public isLoading = false;
    public subscriptions: Array<Subscription> = [];
    public langString: string;

    constructor(private translateService:TranslateService, public companyService: CompanyService, public widgetService: WidgetsService, public mapService: MapService, private navService: NavService) { 
        this.initRemoveEquipmentModalButtons();
    }
    
    public ngOnInit(): void {
        this.isLoading = true
        this.widgetService.setSize(7);
        this.mapService.showMap();
        this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
    }

    public showRemoveEquipmentModal(equipment: EquipmentModel): void {
        this.selectedEquipment = equipment;
        this.removeEquipmentModal.showModal();
    }

    private initRemoveEquipmentModalButtons(): void {
        this.removeEquipmentModalButtonSecondaryInterface = {
            clickFunction: (): void => {
                this.deleteEquipment.emit(this.selectedEquipment.id);
                this.removeEquipmentModal.hideModal();
            },
            text: this.translateService.instant('delete')
        };
        this.removeEquipmentModalButtonPrimaryInterface = {
            clickFunction: (): void => {
                this.removeEquipmentModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'farms') || Object.prototype.hasOwnProperty.call(changes, 'equipments')) {
            this.isLoading = false
            if (this.farms && this.equipments) {
                this.equipmentsByFarm = {};
                this.farms.forEach((farm: FarmModel): void => {
                    this.equipmentsByFarm[farm.id] = this.equipments.filter((equipment: EquipmentModel): boolean => equipment.farm === farm.id);
                });
                this.equipmentsWithNoFarm = this.equipments.filter((equipment: EquipmentModel): boolean =>
                    equipment.farm === null || equipment.farm === undefined);
            }
        }
    }

    public ngOnDestroy() {
        this.widgetService.setSize(6);
    }
}
