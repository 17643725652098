import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
    pure: true
})
export class TimeAgoPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            moment.locale('en', {
                relativeTime: {
                    future: 'in %s',
                    past: '%s ago',
                    s: '3s',
                    ss: '%ss',
                    m: '1m',
                    mm: '%dm',
                    h: '1h',
                    hh: '%dh',
                    d: 'a day',
                    dd: '%dd',
                    M: '1M',
                    MM: '%dM',
                    y: '1y',
                    yy: '%dy',
                },
            });
            const dstring = moment(value).local();
            return value = moment(dstring).fromNow();
        }
        return value;
    }

}