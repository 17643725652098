import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetsService } from 'src/app/core/services/api/widgets/widgets.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { BillingService } from '../../../../../core/services/api/billing/billing.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
  selector: 'my-products-container',
  templateUrl: './my-products-container.component.html',
  styleUrls: ['./my-products-container.component.scss']
})
export class MyProductsContainerComponent implements OnInit, OnDestroy {

  public assets = [];
  public subscriptions: Array<Subscription> = [];
  constructor(public globalRegistry: GlobalRegistryService, public billingService: BillingService, public widgetsService: WidgetsService, public mapService: MapService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.billingService.getAssets().subscribe(data => {
      this.assets = data.body.results;
    }))
    this.widgetsService.setSize(12);
    this.mapService.hideMap();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
    this.widgetsService.setSize(6);
  }

}
