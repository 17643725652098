import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompanyModel } from '../../../../../core/models/company/company.model';
import { CompanyInterface } from '../../../../../core/services/api/company/data/company.interface';


@Component({
    selector: 'fap-company-layout',
    templateUrl: './fap_company-layout.component.html',
    styleUrls: ['./fap_company-layout.component.scss']
})
export class FapCompanyLayoutComponent implements OnInit, OnChanges{

    @Input()
    public company: CompanyModel;

    @Output()
    public updateCompany: EventEmitter<CompanyInterface> = new EventEmitter();

    public editCompanyForm: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    public ngOnInit(): void {
        this.initEditCompanyForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'company')) {
            if( this.company !== null && this.company !== undefined)
            {
                this.initEditCompanyForm();
            }
        }
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.editCompanyForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public submit(): void {
        if(this.editCompanyForm.invalid) {
            Object.keys(this.editCompanyForm.controls).forEach((controlName: string) =>
                this.editCompanyForm.controls[controlName].markAsTouched()
            );
            return;
        }

        const body: CompanyInterface = {
            id: this.company.id,
            name: this.editCompanyForm.controls['name'].value,
            cif: this.editCompanyForm.controls['cif'].value,
            vat: this.editCompanyForm.controls['vat'].value,
        };

        if(this.editCompanyForm.touched)
        {
            this.updateCompany.emit(body);
        }
    }

    private initEditCompanyForm(): void {
        this.editCompanyForm = this.formBuilder.group({
            name: [this.company && this.company.name ? this.company.name : '', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(100)
                ])
            ],
            billingAddress: [this.company && this.company.billingAddress ? this.company.billingAddress : '', Validators.compose([
                    Validators.required
                ])
            ],
            cif: [this.company && this.company.cif ? this.company.cif : '', Validators.compose([
                    Validators.required
                ])
            ],
            registerData: [this.company && this.company.registerData ? this.company.registerData : '', Validators.compose([
                    Validators.required
                ])
            ],
            vat: [this.company && this.company.vat ? this.company.vat : '', Validators.compose([
                    Validators.required
                ])
            ]
        });
    }
}
