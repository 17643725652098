<div class="widget-card">
    <div class="widget-card-title">
        <div class="d-flex align-items-center">
            {{ 'widget.activities' | translate }}
            <i class="material-icons ml-1" (click)="addEditActivity()">add</i>
        </div>
        <div *ngIf="widgetConfig['objectName']" class="widget-content-type-text">
            {{ widgetConfig['contentType'] | translate }} - {{ widgetConfig['objectName'] }}
        </div>
    </div>
    <div class="widget-card-body">
        <ng-container *ngFor="let activity of ungroupedActivities">
            <fap-activity-list-row
                [activity]="activity"
                (deleteActivity)="deleteActivity.emit($event)"
                (editActivity)="addEditActivity(activity)">
            </fap-activity-list-row>
        </ng-container>
        <ng-container *ngFor="let activityGroup of activityGroups">
            <fap-activity-list-group-row
                [activityGroup]="activityGroup"
                [activities]="activities">
            </fap-activity-list-group-row>
        </ng-container>
    </div>
</div>
<fap-modal
    #addEditActivityModal
    [inputIsExtraLarge]="true"
    inputTitle="{{'widget.activities' | translate}}">
    <fap-add-edit-activity
        [activityTypes]="globalRegistry.systemData.activityTypes"
        [activity]="selectedActivity"
        (submitEvent)="submitActivity($event)">
    </fap-add-edit-activity>
</fap-modal>
