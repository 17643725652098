<fap-header>
    <div class="add_circle mr-2">
        <mat-form-field>
            <mat-label style="top: -2px; position: relative;">{{'activity.plans' | translate}}</mat-label>
            <mat-select #mys panelClass="prevent-enter-close" disableOptionCentering="true" name="formTypes" [placeholder]="'activity.plans' | translate" multiple (selectionChange)="filterActivitiesByPlan($event)">
                <div class="d-flex" style="padding: 0 10px; align-items: center; position: relative;">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #plansFilter [placeholder]="'activity.plans' | translate" (keydown.enter)="$event.stopPropagation()">
                </mat-form-field>
                <mat-label style="position: absolute; right: 25px; cursor: pointer;">
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <!-- <div class="input-group-append pl-1">
                    <span class="input-group-text">
                        <i class="icofont icofont icofont-plus" (click)="addNewFormType(); mys.close();"></i>
                     </span>
                </div> -->
            </div>
            <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option class="noteIcons" *ngFor="let plan of plans | filter : plansFilter.value" [value]="plan.id">
                    <span class="mat-option-text">
                    <div class="name">{{ plan?.nameT ? getTranslation(plan?.nameT) : plan?.name }}</div>
                    </span>
                </mat-option>
            </div>
            </mat-select>
        </mat-form-field>
    </div>
</fap-header>
<div class="crop_detail_page w-100 d-flex">
    <div class="activity_row" [ngClass]="{'active': isActive}">
        <fap-plan-item *ngFor="let activity of activities" [activity]="activity" [dateColumns]="dateColumns" [planData]="planData" [activityTypes]="activityTypes" (getChildActivityPlan)="getChildActivityPlan($event)"></fap-plan-item>
        <div class="toggle_slider" (click)="toggleActivityRow()"><i class="fa fa-chevron-right"></i></div>
    </div>
    <div class="dates_container" #datesContainer>
        <div class="header-row">
          <div class="date-column" *ngFor="let column of dateColumns">
            <div class="plan_month">{{ column | date: 'MMM' }}</div>
            <div class="plan_date">{{ column | date: 'dd' }}</div>
          </div>
        </div>
        <div class="plan_dates">
          <fap-plan-dates *ngFor="let activity of activities" [activity]="activity" [dateColumns]="dateColumns" [planData]="planData" [activityTypes]="activityTypes"></fap-plan-dates>
        </div>
      </div>      
</div>