<!--error-404 start-->
<div class="error-wrapper">
    <div class="container">
        <img class="img-100" src="assets/images/other-images/sad.png" alt="">
        <div class="error-heading">
            <img src="assets/images/cloud-bg-1.png" class="cloud-first" alt="">
            <h2 class="headline font-danger">404</h2>
            <img src="assets/images/cloud-bg-2.png" class="cloud-second" alt="">
        </div>
        <div class="col-md-8 offset-md-2">
            <p class="sub-content">{{ 'notFound.notFoundMessage' | translate }}
            </p>
        </div>
        <div class="">
            <a [routerLink]="'/pages/posts'" class="btn btn-danger-gradien btn-lg">{{'notFound.backToHome' | translate}}</a>
        </div>
    </div>
</div>
<!--error-404 end-->
