<div class="page-wrapper" id="parent">
  <div class="auth-bg">
    <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
      <div class="authentication-box">
        <div class="card mt-4 p-4">
          <h4 class="text-center">{{ 'auth.register.newUser' | translate }}</h4>
          <h6 class="text-center">{{ 'auth.register.fiedsRequired' | translate }}</h6>
          <form class="theme-form" [formGroup]="registerForm">
            <div class="form-row">
              <div class="col-md-6">
                  <div class="form-group">
                      <mat-form-field class="w-100" cdkFocusInitial>
                          <mat-label class="col-form-label">{{ 'auth.register.firstName' | translate }}</mat-label>
                          <input matInput formControlName="firstName" autofocus name="firstName">
                          <mat-error *ngIf="ifControlHasError('firstName','required')">
                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                          </mat-error>
                          <mat-error *ngIf="ifControlHasError('firstName','minlength')">
                              <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                          </mat-error>
                          <mat-error *ngIf="ifControlHasError('firstName','maxlength')">
                              <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                          </mat-error>
                      </mat-form-field>
                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field class="w-100">
                      <mat-label class="col-form-label">{{ 'auth.register.lastName' | translate }}</mat-label>
                      <input matInput formControlName="lastName">
                      <mat-error *ngIf="ifControlHasError('lastName','required')">
                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                      </mat-error>
                      <mat-error *ngIf="ifControlHasError('lastName','minlength')">
                          <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                      </mat-error>
                      <mat-error *ngIf="ifControlHasError('lastName','maxlength')">
                          <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                      </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">Email</mat-label>
                  <input matInput formControlName="email" style="width: 100%;">
                  <mat-error *ngIf="ifControlHasError('email','email')">
                    <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('email','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('email','minlength')">
                      <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('email','maxlength')">
                      <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                  </mat-error>
              </mat-form-field>
            </div>
          <div class="form-row">
              <div class="col-sm-4">
                  <button class="btn btn-success" type="button" (click)="showModal()">{{ 'auth.sigUpLabel' | translate }}</button>
              </div>
              <div class="col-sm-8">
                  <div class="text-left mt-2 m-l-20">{{ 'auth.register.alreadyUser' | translate }}  <button class="btn-link text-capitalize" (click)="router.navigateByUrl('/login')">{{ 'auth.loginLabel' | translate }}</button></div>
              </div>
          </div>
          <!-- <div class="form-divider"></div>
            <div class="social mt-3">
              <div class="form-group btn-showcase d-flex">
                <button class="btn social-btn btn-fb d-inline-block"> <i class="fa fa-facebook"></i></button>
                <button class="btn social-btn btn-twitter d-inline-block"><i class="fa fa-google"></i></button>
                <button class="btn social-btn btn-google d-inline-block"><i class="fa fa-twitter"></i></button>
                <button class="btn social-btn btn-github d-inline-block"><i class="fa fa-github"></i></button>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </div>
</div>

<div class="custom_modal" id="custom_modal">
  <div class="modal_header">
      <h2>GDPR (EU General Data Protection Regulation)</h2>
      <button type="button" class="btn close-modal" (click)="hideModal()"><i class="fa fa-times"></i></button>
  </div>
  <form [formGroup]="gdprForm" id="form">
    <div class="text_part" #scrollMe (scroll)="onScroll()" id="text_part">
      <div id="gdprContent"></div>
      <div id="el" style="min-height: 10px;"></div>
    </div>
      <div class="form-group fixed_part">
          <div class="row align-items-baseline">
              <div class="col-12" style="padding: 15px 0; background: #fff;">
                  <div class="input-group w-100">
                      <div class="col-12 d-flex">
                        <input id="accept" type="checkbox" formControlName="accept">
                        <label for="accept">{{ 'auth.register.readTermsAndConditions' | translate }}</label>
                      </div>
                      <div class="col-12 mt-3">
                          <button class="btn btn-success" [disabled]="!pageEnd" type="submit" (click)="submit()">{{ 'auth.sigUpLabel' | translate }}</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </form>
</div>