import { Routes } from '@angular/router';
import { FapAddEditDiseaseComponent } from './diseases/fap-add-edit-disease/fap-add-edit-disease.component';
import { FapDiseaseOverviewComponent } from './diseases/fap-disease-overview/fap-disease-overview.component';

export const treatmentRoutes: Routes = [
    {
        path:  '',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasTreatmentsFilter: true
            },
            key: 'diseases',
            index: 0
        },
        redirectTo: 'weed',
        pathMatch: 'full'
    },
    {
        path: 'weed',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasTreatmentsFilter: true
            },
            key: 'diseases',
            index: 0
        },
        component: FapDiseaseOverviewComponent
    },
    {
        path: 'insect',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasTreatmentsFilter: true
            },
            key: 'diseases',
            index: 0
        },
        component: FapDiseaseOverviewComponent
    },
    {
        path: 'viral',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasTreatmentsFilter: true
            },
            key: 'diseases',
            index: 0
        },
        component: FapDiseaseOverviewComponent
    },
    {
        path: 'fungal',
        data: {
            filters: {
                hasFarmFilter: true,
                hasLotFilter: true,
                hasTreatmentsFilter: true
            },
            key: 'diseases',
            index: 0
        },
        component: FapDiseaseOverviewComponent
    },
    {
        path: 'add',
        component:  FapAddEditDiseaseComponent
    },
    {
        path: ':diseaseId',
        component: FapAddEditDiseaseComponent
    },
];
