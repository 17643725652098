import { AbstractControl } from '@angular/forms';

export class FapConfirmPasswordValidator {

    public static matchPassword(control: AbstractControl): AbstractControl | null {
        const password: string = control.get('password').value;

        const confirmPassword: string = control.get('confirmPassword').value;

        if (password !== confirmPassword) {
            control.get('confirmPassword').setErrors({confirmPassword: true});
        } else {
            return null;
        }
    }
}
