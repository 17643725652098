import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';

@Component({
  selector: 'scheduler-edit-container',
  templateUrl: './scheduler-edit-container.component.html',
  styleUrls: ['./scheduler-edit-container.component.scss']
})
export class SchedulerEditContainerComponent implements OnInit, OnDestroy {

  public lots: Array<LotModel>;
  public farms: Array<FarmModel>;
  public sensors: Array<SensorModel>;
  public sensorId: number;
  public sensor: SensorModel;
  public farmId: number;
  public subscriptions = new Subscription();

  constructor(public sensorService:SensorService, public toastyService: ToastrService, public globalRegistry: GlobalRegistryService, public translate: TranslateService, public activateRoute:ActivatedRoute, public navService:NavService, public mapService: MapService, public router: Router) {
    this.activateRoute.params.subscribe(params => {
      this.navService.createMod.next(true);
        if (params["sensorId"] !== undefined) {
          this.sensorId = Number(params["sensorId"]);
          this.navService.createMod.next(false);
          this.navService.editMod.next(true);
          this.getSensor();
        }
    })
   }

  ngOnInit(): void {
    if (window.innerWidth >= 767) {
      this.mapService.showMap();
  }
  this.mapService.resetMap();
    this.navService.editFarm.next(true);
    this.navService.submitFarm.next(true);
    this.navService.invalidForm.next(true);
    
    this.subscriptions.add(
      this.navService.emitCancel.subscribe((value: boolean) => {
        if (value) {
          this.router.navigate(["/pages/scheduler"]);
          this.navService.emitCancel.next(false);
        }
      })
    )
    this.getSensor();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.navService.editFarm.next(false);
  }

  public submitSensor(sensor: FormData): void {
    console.log(sensor)
    // this.sensorService.createSensor(sensor).subscribe(
    //   () => {
    //     // this.sensorService.getSensors.next();
    //     this.globalRegistry.addLocalRelationsToService.next(this.sensorId);
    //     this.toastyService.success(
    //       this.translate.instant("sensors.messages.createSuccess")
    //     );
    //     this.getsensors();
    //   },
    //   () => {
    //     this.toastyService.error(
    //       this.translate.instant("sensors.messages.failCreate")
    //     );
    //   }
    // );
  }

  public updateSensor(sensor): void {
    console.log(sensor);
    // this.sensorService
    //   .updateSensor(Number(sensor.id), sensor)
    //   .pipe(
    //     tap(
    //       () => {
    //         this.getsensors();
    //         this.sensorService.getsensorsList.next();
    //         this.globalRegistry.addLocalRelationsToService.next(this.sensorId);
    //         this.toastyService.success(
    //           this.translate.instant("sensors.messages.updateSuccess")
    //         );
    //       },
    //       () =>
    //         this.toastyService.error(
    //           this.translate.instant("sensors.messages.failUpdate")
    //         )
    //     )
    //   )
    //   .subscribe();
  }

  public deleteSensor(id): void {
    console.log(id);
    // this.sensorService
    //   .deleteSensor(Number(id))
    //   .pipe(
    //     tap(
    //       () => {
    //         this.getsensors();
    //         this.sensorService.getsensorsList.next();
    //         this.globalRegistry.addLocalRelationsToService.next(this.sensorId);
    //         this.toastyService.success(this.translate.instant("sensors.messages.deleteSuccess"));
    //       },
    //       () =>
    //         this.toastyService.error(
    //           this.translate.instant("sensors.messages.failUpdate")
    //         )
    //     )
    //   )
    //   .subscribe();
  }

  private getSensor(): void {
    console.log('call sensors commented')
    // this.subscriptions.add(this.sensorService
    //     .getSensors(this.sensorId)
    //     .subscribe((sensors: ResponseModel<SensorModel[]>): void => {
    //         this.sensor = sensors.model[0];
            
    //             let param1 = { sensor: this.sensor.id };
    //             if (this.sensor.actions == undefined) {
    //               this.sensor.actions = [];
    //             }
    //             this.sensorService
    //                 .getSensorActions(param1)
    //                 .subscribe((data) => {
    //                     if (data) {
    //                       this.sensor.actions.push(data.body.results);
    //                     }
    //                 });
            
    //         console.log(this.sensor);
    //     }));
  }

}
