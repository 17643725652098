<form class="theme-form" [formGroup]="attributeForm">
    <div class="form-group ">
        <mat-form-field class="w-100 col-12">
            <mat-label class="col-form-label">{{ 'attribute.name' | translate }}</mat-label>
            <input required matInput formControlName="name">
            <mat-error *ngIf="attributeForm.controls['name']?.errors?.required">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <!-- <mat-form-field class="w-100" [ngClass]="{'col-8': selectedValueType=='number', 'col-12': selectedValueType!=='number'}">
        <mat-label>{{ 'attribute.valueType' | translate }}</mat-label>
        <mat-select required formControlName="valueType" (ngModelChange)="changedValueType($event)">
            <mat-form-field class="w-100">
                <mat-label>
                    <i class="fa fa-search f-20"></i>
                </mat-label>
                <input matInput #attributeValueTypeFilter>
            </mat-form-field>
            <mat-option *ngFor="let attributeValueType of attributeService.attributeValueTypes | filter : attributeValueTypeFilter.value" [value]="attributeValueType.id">
                {{ attributeValueType.name | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="col-4" *ngIf="selectedValueType=='number'">
        <mat-label>{{ 'attribute.unit' | translate }}</mat-label>
        <mat-select formControlName="unit">
            <mat-option *ngFor="let unit of attributeService.unitTypes" [value]="unit['name']">
                {{unit['name']}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <div class="form-group">
        <mat-form-field class="w-100 col-12">
            <mat-label> {{ 'attribute.mandatoryFor' | translate }}</mat-label>
            <mat-select [(ngModel)]="defaultEntities" formControlName="mandatoryFor" multiple>
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let entity of entityTypes" [value]="entity">{{ ('attribute.' + entity) | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-between p-2">
        <button
            [ngStyle]="{'background': navService.headerBackgroundColor}"
            (click)="true || deleteRelation(relation, attribute?.id)"
            class="delete" >
            <img src="./assets/images/fap/basket.svg" alt="delete relation">

        </button>
        <button [ngStyle]="{'background': navService.headerBackgroundColor}"
                class="save"
                (click)="submit()">OK</button>
    </div>
</form>
