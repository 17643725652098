<div class="alert-row">
    <div class="alert-edit">
        <i class="fa fa-pencil ter-btn-icon ter-btn-icon-success"></i>
    </div>
    <div class="alert-row-dates">
        <span class="alert-start-date">2019-03-20 11:00</span>
        <span class="alert-end-date">2019-03-20 11:00</span>
    </div>
    <div class="alert-row-title">
        <h5>Alert test</h5>
        <span>John Doe</span>
    </div>
    <div class="alert-delete">
        <i class="material-icons ter-btn-icon ter-btn-icon-danger">delete_outline</i>
    </div>
</div>
