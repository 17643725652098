import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { NavService } from '../../../../shared/services/nav.service';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'add-edit-body-translations',
  templateUrl: './add-edit-body-translations.component.html',
  styleUrls: ['./add-edit-body-translations.component.scss']
})
export class AddEditBodyTranslationsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public prefix: string;
  @Input() public label: string;
  @Input() public group: string;
  @Input() public obj: {} = {};
  @Input() public translation: any;
  @Input() public isHTML: boolean = false;
  @Output() public submitTranslation: EventEmitter<any> = new EventEmitter();
  @Output() public updateTranslation: EventEmitter<any> = new EventEmitter();
  @Output() public cancelTranslation: EventEmitter<any> = new EventEmitter();
  public isEdit = false;
  @Input() public field = 'nameT';

  public enBody = '';
  public frBody = '';
  public roBody = '';

  public enEditor: Editor;
  public frEditor: Editor;
  public roEditor: Editor;
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public addEditTranslationForm: UntypedFormGroup;
  public subscriptions: Array<Subscription> = [];
  public langString: string = null;
  public translatedNames = [];
  public languages = [
    { id: 'en', label: 'English', body: 'enBody' },
    { id: 'fr', label: 'Français', body: 'frBody' },
    { id: 'ro', label: 'Română', body: 'roBody' }
  ];
  public showAll = false;
  @ViewChild('firstInput', { static: false }) firstInput: ElementRef;

  constructor(
    public typeService: TypesService,
    public globalRegistry: GlobalRegistryService,
    public navService: NavService,
    public toastr: ToastrService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
    this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
      if (lang) {
        this.langString = lang;
      }
    }));
    this.translatedNames = this.globalRegistry.systemData.translations;
    this.enEditor = new Editor();
    this.frEditor = new Editor();
    this.roEditor = new Editor();
    this.initAddEditTranslationForm();
  }

  initAddEditTranslationForm() {
    this.addEditTranslationForm = new UntypedFormGroup({
      en: new UntypedFormControl(''),
      fr: new UntypedFormControl(''),
      ro: new UntypedFormControl(''),
      group: new UntypedFormControl(this.group ? this.group : '')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.translation && changes.translation.currentValue !== changes.translation.previousValue) {
      this.editTranslation(changes.translation.currentValue);
      if (this.firstInput && !this.isHTML) {
        this.firstInput.nativeElement.select();
      }
    }
  }

  toggleShowAllLanguages() {
    this.showAll = !this.showAll;
  }

  createObjectMap(arrayOfObjects: any[], objectMap: Map<number, any>): Map<number, any> {
    arrayOfObjects.forEach(obj => {
      objectMap.set(obj.id, obj);
      if (obj.children && obj.children.length) {
        this.createObjectMap(obj.children, objectMap);
      }
    });
    return objectMap;
  }

  addTranslation() {
    if (this.addEditTranslationForm.invalid) {
      this.showAll = true;
      this.toastr.error(this.translateService.instant('equipment.pleaseFillInMandatoryFields'));
      return;
    }
    this.addEditTranslationForm.controls['en'].setValue(this.enBody);
    this.addEditTranslationForm.controls['fr'].setValue(this.frBody);
    this.addEditTranslationForm.controls['ro'].setValue(this.roBody);

    if (this.isEdit === false) {
      this.subscriptions.push(this.typeService.postTranslations(this.addEditTranslationForm.value).subscribe(data => {
        this.submitTranslation.emit(data.body.results);
        this.globalRegistry.systemData.translations.push(data.body.results);
        this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
        this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
      }));
    } else {
      this.subscriptions.push(this.typeService.updateTranslations(this.translation.id, this.addEditTranslationForm.value).subscribe(data => {
        this.submitTranslation.emit(data.model);
        const translationIndex = this.globalRegistry.systemData.translations.findIndex(
          fruit => fruit.id === data.model.id
        );
        if (translationIndex !== -1) {
          this.globalRegistry.systemData.translations[translationIndex] = {
            ...this.globalRegistry.systemData.translations[translationIndex],
            ...data.model
          };
          this.globalRegistry.systemData.translationsMap = new Map<number, any[]>();
          this.createObjectMap(this.globalRegistry.systemData.translations, this.globalRegistry.systemData.translationsMap);
        }
      }));
    }
  }

  onFocusOut(langId: string, event: Event) {
    const currentValue = (event.target as HTMLInputElement).value.trim();
    this.languages.forEach(language => {
      if (language.id !== langId && !this.addEditTranslationForm.controls[language.id].value) {
        this.addEditTranslationForm.controls[language.id].setValue(currentValue + ' ' + language.id);
      }
    });
  }

  onEditorContentChange(lang: string) {
    this.addEditTranslationForm.controls[lang].setValue(this[`${lang}Body`]);
  }

  editTranslation(translation) {
    if (translation) {
      this.isEdit = true;
      this.addEditTranslationForm.controls['en'].setValue(translation.en);
      this.addEditTranslationForm.controls['fr'].setValue(translation.fr);
      this.addEditTranslationForm.controls['ro'].setValue(translation.ro);
      this.enBody = translation.en;
      this.frBody = translation.fr;
      this.roBody = translation.ro;
      if (this.firstInput && !this.isHTML) {
        this.firstInput.nativeElement.focus();
      }
    } else {
      this.isEdit = false;
      this.initAddEditTranslationForm();
    }
  }

  getTranslation(translation) {
    const t = this.translatedNames.filter(trans => trans.id === translation);
    if (t[0]) {
      if (t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation;
      } else {
        return t[0][this.langString];
      }
    } else {
      return translation;
    }
  }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  getPlaceholderTranslation(typeKey: string, labelKey: string, language: string): string {
    if (typeKey && labelKey && language) {
      this.translateService.use(language);
      const typeTranslation = this.translateService.instant(typeKey);
      const labelTranslation = this.translateService.instant(labelKey);
      this.translateService.use(this.langString);
      return `${typeTranslation} ${labelTranslation}`;
    }
  }

  ngOnDestroy(): void {
    this.showAll = false;
    this.enEditor.destroy();
    this.frEditor.destroy();
    this.roEditor.destroy();
  }

}
