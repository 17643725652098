import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { CompanyComponent } from './company.component';
import { FapCompanyLayoutComponent } from './components/fap_company-layout/fap_company-layout.component';
import { CompanyContainerComponent } from './containers/company-container/company-container.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    declarations: [
        CompanyComponent,
        CompanyContainerComponent,
        FapCompanyLayoutComponent
    ]
})

export class CompanyModule {

}
