<form [formGroup]="addEditSchedulerForm" class="col-12 p-0">
    <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name', 'nameInput2', scheduler, addEditSchedulerTranslationPopup, 'addEditSchedulerForm')">
        <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
        <input matInput formControlName="name" readonly #nameInput2>
        <mat-error *ngIf="addEditSchedulerForm.controls['name_t'].errors?.required">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
        </mat-error>
    </mat-form-field>

    <div class="day_info row">
        <div class="month col-6">
            <mat-form-field class="w-100">
                <mat-label>Month</mat-label>
                <mat-select
                    formControlName="month"
                    required
                    multiple
                >
                    <div class="scroll_wrap">
                        <mat-option
                            *ngFor="let month of availableMonths" [value]="month?.id">
                            {{month?.month}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="day col-6">
            <mat-form-field class="w-100">
                <mat-label>Day</mat-label>
                <mat-select
                    formControlName="day"
                    required
                    multiple
                    (selectionChange)="changeDay($event)"
                >
                    <div class="scroll_wrap">
                        <mat-option
                            *ngFor="
                                let day of [
                                    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 
                                    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
                                ]" [value]="day">
                            {{day}}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="days mb-2">
        <div class="days_container">
            <div class="day" *ngFor="let day of availableDays">
                <label >
                    <input type="checkbox" [formControl]="getCheckboxControl(day.id)" [value]="day.id">
                    <span class="box"></span>
                    <span>{{ day.day }}</span>
                </label>
            </div>
        </div>
    </div>

    <div class="time_info row">
            <div class="hours col-6">
                <mat-form-field class="w-100">
                    <mat-label>Hour</mat-label>
                    <mat-select
                        formControlName="hour"
                        required
                        multiple
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let t of [
                                        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 
                                        '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'
                                    ]
                                "[value]="t">
                                {{t}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="minute col-6">
                <mat-form-field class="w-100">
                    <mat-label>Minute</mat-label>
                    <mat-select
                        formControlName="minute"
                        required
                    >
                        <div class="scroll_wrap">
                            <mat-option
                                *ngFor="
                                    let t of [
                                        '5', '10', '15', '20', '25', '30',
                                        '35', '40', '45', '50', '55', '60'
                                    ]
                                "[value]="t">
                                {{t}}
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
    </div>
    <div class="action_btns d-flex justify-content-end p-0">
        <button class="btn mr-2" (click)="submitScheduler(); closeModal.emit();">{{ 'translations.save' | translate }}</button>
        <button class="btn" (click)="closeModal.emit()">{{ 'translations.cancel' | translate }}</button>
    </div>
</form>

<fap-modal #addEditSchedulerTranslationPopup [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.scheduler)" 
        [prefix]="'SCHEDULER_'" 
        [translation]="translation"
        [label]="label"
        [group]="'schedulers'"
        (submitTranslation)="onAddUpdateSchedulerTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>