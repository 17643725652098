import { Routes } from '@angular/router';

import { CompanyContainerComponent } from './containers/company-container/company-container.component';

export const companyRoutes: Routes = [
    {
        path:       '',
        component:  CompanyContainerComponent
    }
];
