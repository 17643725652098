import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { StockService } from '../../../../../core/services/api/stock/stock.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { ResponseModel } from '../../../../../core/models/response.model';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { Router } from '@angular/router';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';

@Component({
    templateUrl: './stock-container.component.html'
})
export class StockContainerComponent implements OnInit, OnDestroy {

    public stockOverview$: Observable<StockItemModel[]>;
    public subscription: Subscription;
    public currentUser;

    constructor(public globalRegistry: GlobalRegistryService,
                private stockService: StockService,
                private mapService: MapService,
                private userService: UserService,
                private router: Router
                ) {
                    this.subscription = this.userService.getCurrentUser.subscribe(data => {
                        if(Object.keys(data).length != 0) {
                        this.currentUser = data;
                        const path = this.router.routerState.snapshot.url;
                        this.userService.getMenus.subscribe(menus => {  
                            if(Object.keys(menus).length != 0) {
                                const pathExist = menus.some(menu => menu.path === path);
                                if(pathExist === false) {
                                    this.router.navigate(['pages/posts']);
                                }
                            }
                        })
                        }
                    });
    }

    public ngOnInit(): void {
        this.mapService.hideMap();

        this.stockOverview$ = this.stockService.getStockItems().pipe(map((response: ResponseModel<StockItemModel[]>): StockItemModel[] => {
            return response.model;
        }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
