<div class="main-header-left d-none d-lg-block">
<!--    <div class="logo-wrapper"><a href="javascript:void(0)"></a></div>-->
</div>
<div class="sidebar custom-scrollbar">
    <div class="sidebar-user text-center">
        <div (click)="emitOpenProfile()" class="u-posRelative">
            <div *ngIf="globalRegistry?.userData?.profile?.picture" class="prof-pic rounded-circle" [ngStyle]="{'background-image': 'url(' + mediaUrl + globalRegistry?.userData?.profile?.picture + ')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
            <div class="rounded-circle user-status"></div></div>

            <div *ngIf="!globalRegistry?.userData?.profile?.picture" class="prof-pic rounded-circle" [ngStyle]="{'background-image': 'url(../../../../assets/images/image_placeholder.png)', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center'}">
                <div class="rounded-circle user-status"></div></div>
            <!--      <div class="profile-edit"><a>-->
            <!--          <i class="icofont icofont-pencil-alt-5"><input class="upload" type="file" (change)="readUrl($event)" /></i>-->
            <!--        </a></div>-->
<!--            <ul class="profile-dropdown profile onhover-show-div p-20">-->
<!--                <li>-->
<!--                    <a (click)="emitOpenProfile()">-->
<!--                        <app-feather-icons [icon]="'user'"></app-feather-icons>-->
<!--                        {{ "header.editProfileLabel" | translate }}-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <a (click)="router.navigate(['pages/company'])">-->
<!--                        <app-feather-icons [icon]="'briefcase'"></app-feather-icons>-->
<!--                        {{ "header.companyLabel" | translate }}-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <a href="javascript:void(0)">-->
<!--                        <app-feather-icons [icon]="'settings'"></app-feather-icons>-->
<!--                        {{ "header.settingsLabel" | translate }}-->
<!--                    </a>-->
<!--                </li>-->
<!--                <li>-->
<!--                    <a (click)="logOut()">-->
<!--                        <app-feather-icons [icon]="'log-out'"></app-feather-icons>-->
<!--                        {{ "header.logoutLabel" | translate }}-->
<!--                    </a>-->
<!--                </li>-->
<!--            </ul>-->
        </div>
        <h6 class="mt-3 f-14">{{ globalRegistry.userData.profile.display }}</h6>
        <p>{{ globalRegistry.userData.profile.job }}</p>
    </div>
    <div class="sidebar-background" [style.background-image]="'url(' + mediaUrl + 'settings/menu_background.svg' + ')'">
        <ul class="sidebar-menu">
            <li (click)="navServices.collapseSidebar.next(true)" *ngFor="let menuItem of leftMenuItems" [ngClass]="{active: menuItem.active}">
                <!-- Sub -->
                <a href="javascript:void(0)" class="sidebar-header d-flex" *ngIf="menuItem.type === 'sub'"
                   (click)="toggletNavActive(menuItem)">
                    <img class="menu-icon" *ngIf="returnIcon(menuItem); else menuIcon" [src]="menuItem.icon"
                         alt="menu icon"/>
                    <ng-template #menuIcon>
                        <app-feather-icons *ngIf="menuItem.icon; else spacing" [icon]="menuItem.icon"></app-feather-icons>
                    </ng-template>
                    <span>{{getTranslationsByKey(menuItem.title)}}<span
                        class="badge badge-{{menuItem.badgeType}} ml-3"
                        *ngIf="menuItem.badgeType">{{menuItem.badgeValue | translate}}</span></span>
                    <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
                </a>
                <!-- Link -->
                <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                   class="sidebar-header d-flex"
                   *ngIf="menuItem.type === 'link'" (click)="toggleSidebar()">
                    <img class="menu-icon" *ngIf="returnIcon(menuItem); else menuIcon" [src]="menuItem.icon"
                         alt="menu icon"/>
                    <ng-template #menuIcon>
                        <app-feather-icons *ngIf="menuItem.icon; else spacing"[icon]="menuItem.icon"></app-feather-icons>
                    </ng-template>
                    <span>{{getTranslationsByKey(menuItem.title)}}<span
                        class="badge badge-{{menuItem.badgeType}} ml-3"
                        *ngIf="menuItem.badgeType">{{menuItem.badgeValue | translate}}</span></span>
                    <i class="fa fa-angle-right pull-right"></i>
                </a>
                <!-- External Link -->
                <a href="{{ !menuItem.type ? null : menuItem.path }}" class="sidebar-header d-flex"
                   *ngIf="menuItem.type === 'extLink'">
                    <img class="menu-icon" *ngIf="returnIcon(menuItem); else menuIcon" [src]="menuItem.icon"
                         alt="menu icon"/>
                    <ng-template #menuIcon>
                        <app-feather-icons *ngIf="menuItem.icon; else spacing" [icon]="menuItem.icon"></app-feather-icons>
                    </ng-template>
                    <span>{{getTranslationsByKey(menuItem.title)}}<span
                        class="badge badge-{{menuItem.badgeType}} ml-3"
                        *ngIf="menuItem.badgeType">{{menuItem.badgeValue | translate}}</span></span>
                    <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
                </a>
                <!-- External Tab Link -->
                <a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank" class="sidebar-header d-flex"
                   *ngIf="menuItem.type === 'extTabLink'">
                    <img class="menu-icon" *ngIf="returnIcon(menuItem); else menuIcon" [src]="menuItem.icon"
                         alt="menu icon"/>
                    <ng-template #menuIcon>
                        <app-feather-icons *ngIf="menuItem.icon; else spacing" [icon]="menuItem.icon"></app-feather-icons>
                    </ng-template>
                    <span>{{getTranslationsByKey(menuItem.title)}}<span
                        class="badge badge-{{menuItem.badgeType}} ml-3"
                        *ngIf="menuItem.badgeType">{{menuItem.badgeValue | translate}}</span></span>
                    <i class="fa fa-angle-right pull-right" *ngIf="menuItem.children"></i>
                </a>
                <!-- 2nd Level Menu -->
                <ul class="sidebar-submenu" [ngClass]="{'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
                    *ngIf="menuItem.children">
                    <li (click)="navServices.collapseSidebar.next(true)" *ngFor="let childrenItem of menuItem.children" [ngClass]="{active: childrenItem.active}">
                        <!-- Sub -->
                        <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'"
                           (click)="toggletNavActive(childrenItem)">
                            <i class="fa fa-circle"></i>{{childrenItem.title | translate }} <span
                            class="badge badge-{{childrenItem.badgeType}} pull-right"
                            *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- Link -->
                        <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
                           *ngIf="childrenItem.type === 'link'"
                           routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fa fa-circle"></i>{{childrenItem.title | translate }} <span
                            class="badge badge-{{childrenItem.badgeType}} pull-right"
                            *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- External Link -->
                        <a href="{{ !childrenItem.type ? null : childrenItem.path }}"
                           *ngIf="childrenItem.type === 'extLink'"
                           routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="fa fa-circle"></i>{{childrenItem.title | translate }} <span
                            class="badge badge-{{childrenItem.badgeType}} pull-right"
                            *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- External Tab Link -->
                        <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                           *ngIf="childrenItem.type === 'extTabLink'">
                            <i class="fa fa-circle"></i>{{childrenItem.title | translate }} <span
                            class="badge badge-{{childrenItem.badgeType}} pull-right"
                            *ngIf="childrenItem.badgeType">{{childrenItem.badgeValue | translate}}</span>
                            <i class="fa fa-angle-down pull-right" *ngIf="childrenItem.children"></i>
                        </a>
                        <!-- 3rd Level Menu -->
                        <ul class="sidebar-submenu" *ngIf="childrenItem.children">
                            <li *ngFor="let childrenSubItem of childrenItem.children">
                                <!-- Link -->
                                <a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
                                   *ngIf="childrenSubItem.type === 'link'" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    <i class="fa fa-circle"></i>{{childrenSubItem.title | translate}} <span
                                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue | translate}}</span>
                                </a>
                                <!-- External Link -->
                                <a  href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
                                   *ngIf="childrenSubItem.type === 'extLink'" routerLinkActive="active"
                                   [routerLinkActiveOptions]="{exact: true}">
                                    <i class="fa fa-circle"></i>{{childrenSubItem.title | translate}} <span
                                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue | translate}}</span>
                                </a>
                                <!-- External Tab Link -->
                                <a  href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}" target="_blank"
                                   *ngIf="childrenSubItem.type === 'extTabLink'">
                                    <i class="fa fa-circle"></i>{{childrenSubItem.title | translate}} <span
                                    class="badge badge-{{childrenSubItem.badgeType}} pull-right"
                                    *ngIf="childrenSubItem.badgeType">{{childrenSubItem.badgeValue | translate}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                <div class="mt-2">
                    <a href="javascript:void(0)" (click)="logOut()" class="sidebar-logout">Log out</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="sidebar-footer">
        <p class="ml-2 mr-5">v {{navServices.version}}</p>
        <p>
            <img height="20" class="pl-1" src="{{mediaUrl + 'settings/platform_logo.png'}}" alt="App logo"/>
        </p>
    </div>
</div>
<ng-template #spacing>
    <div class="spacing"></div>
</ng-template>

