import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { EquipmentModel } from '../../../models/equipment/equipment.model';
import { EquipmentInterface } from '../../../interfaces/equipment/equipment.interface';
import { take } from 'rxjs/operators';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { CacheResolverService } from '../cache/cache-resolver.service';

@Injectable()
export class EquipmentService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient, public cacheService: CacheResolverService) {
        super(tosterService, _http, '/equipment/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getEquipment(equipmentId: number): Observable<ResponseModel<EquipmentModel>> {
        this.cacheService.delete(this.url + 'equipment/' + equipmentId + '/')
        return this.mapRequest(
            this._http.get(this.url + 'equipment/' + equipmentId + '/',
            FapAPIRequestOptions.withTokenRequestOptions),
            EquipmentModel,
            false
        );
    }

    public getEquipments(params?: {}): Observable<ResponseModel<EquipmentModel[]>> {
        this.cacheService.delete(this.url + 'equipment/limit=1000&offset=0')
        params = params || { limit: 1000, offset: 0 };
        return this.mapRequest<EquipmentModel[]>(
            this._http.get(this.url + 'equipment/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            EquipmentModel,
            true
        ).pipe(take(1));
    }

    public getTracking(params?: {}): Observable<ResponseModel<any[]>> {
        // params = params || { limit: 1000, offset: 0 };
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'tracking/',
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            false
        ).pipe(take(1));
    }

    public deleteEquipment(equipmentId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + 'equipment/' + equipmentId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )
        );
    }

    public createEquipment(equipment: EquipmentInterface): Observable<ResponseModel<EquipmentModel>> {
        this.cacheService.delete(this.url + 'equipment/')
        return this.mapRequest(
            this._http.post(this.url + 'equipment/',
                this.camelCaseModelToSnakeCaseJson(equipment),
                FapAPIRequestOptions.onlyTokenRequestOptions
            ),
            EquipmentModel,
            false
        );
    }

    public updateImage(image: FormData, equipmentId: number): Observable<ResponseModel<EquipmentModel>> {
        return this.mapRequest(
            this._http.patch(this.url + 'equipment/' + equipmentId + '/',
                image,
                FapAPIRequestOptions.onlyTokenRequestOptions
            ),
            EquipmentModel,
            false
        );
    }

    public updateEquipment(equipmentId: number, equipment: EquipmentInterface): Observable<ResponseModel<EquipmentModel>> {
        this.cacheService.delete(this.url + 'equipment/' + equipmentId + '/')
        return this.mapRequest(
            this._http.patch(this.url + 'equipment/' + equipmentId + '/',
                this.camelCaseModelToSnakeCaseJson(equipment),
                FapAPIRequestOptions.onlyTokenRequestOptions
            ),
            EquipmentModel,
            false
        );
    }
}
