<fap-header>
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_circle mennu mr-2" (click)="dropdown()">
            <div class="rounded-circle"></div>
            <div class="dropdown_cover" [ngClass]="{'open' : showDropdown}">
                <div  class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'invoice_in', new_doc: 'true'}">
                    <div class="rounded-circle r invoice_in"></div>
                    <div class="stock_text">{{"stock.invoiceIn" | translate}}</div>
                </div>
                <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'invoice_out', new_doc: 'true'}">
                    <div class="rounded-circle r invoice_out"></div>
                    <div class="stock_text">{{"stock.invoiceOut" | translate}}</div>
                </div>
                <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'internal_note', new_doc: 'true'}">
                    <div class="rounded-circle r internal_note"></div>
                    <div class="stock_text">{{"stock.internalNote" | translate}}</div>
                </div>
                <div class="add_circle mr-2" (click)="dropdown()" [routerLink]="'/pages/stock/bill/add'" [queryParams]="{type: 'production_note', new_doc: 'true'}">
                    <div class="rounded-circle r production_note"></div>
                    <div class="stock_text">{{"stock.productionNote" | translate}}</div>
                </div>
            </div>
        </div>
        <div class="add_circle mr-2" (click)="showDocuments = true; docsModal.showModal()">
            <div class="rounded-circle r all_docs"></div>
        </div>
        <div class="add_circle mr-5" (click)="showProducts = true; productsModal.showModal()">
            <div class="rounded-circle r production_note"></div>
        </div>
    </div>

</fap-header>
<div id="parent">
<div class="stock_box d-flex">
    <div class="stock_left">
        <div class="sbox" #stockTable (scroll)="onDivScroll('stockTable', $event)">
          <!-- <div class="d-flex theme_bg header" style="justify-content: space-between; align-items: center;">
            <h3 class="custom_title">{{ 'leftMenu.stock' | translate }}</h3>
            <span class="ricon" (click)="refreshItems()"></span>
          </div> -->
          <fap-section-container [title]="'leftMenu.stock' | translate" [isTable]="true" [isRefresh]="true" (refreshClicked)="refreshItems()">
            <div class="table-responsive">
                <table class="stock_table">
                    <thead>
                        <tr>
                            <th style="width: 40px;">
                                <!-- <input type="checkbox" name="checkAll" (change)="CheckAllOptions()"><span class="checkbx"></span> -->
                            </th>
                            <th style="width: 150px;">{{ 'stock.ingredients.ingredientQuantity' | translate }}</th>
                            <th>{{ 'stock.product' | translate }}</th>
                            <th>{{ 'stock.location' | translate }}</th>
                            <th style="width: 50px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of stockOverview">
                            <td><input type="checkbox" name="check" [(ngModel)]="item.checked" [value]="item.value" (change)="selectEntry($event, item)"><span class="checkbx"></span></td>
                            <td style="text-align: right; padding-right: 15px;">
                                <div class="frs">{{ item?.quantity | number: '1.0-2' }}</div>
                                <div class="inlin">
                                    {{ item?.product | getProductUnit: globalRegistry?.systemData?.unitsMap }}
                                </div>
                            </td>
                            <td>
                                <product-tag [productName]="item?.product?.label?.name" [productType]="item?.product?.label?.type"></product-tag>
                            </td>
                            <td>
                                <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                    <b><span *ngIf="farm?.id === item?.farm">{{farm?.name}}</span></b>
                                </ng-container>
                            </td>
                            <td class="dlt">
                                <span class="ricon"  (click)="refreshItem(item?.id)"></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
              </div>
          </fap-section-container>
        </div>
    </div>
    <div class="stock_right" *ngIf="entries && entries.length > 0">
        <div class="entries" (scroll)="onDivScroll('entriesTable', $event)">
            <div class="sbox" #entriesTable>
                <fap-section-container [title]="'stock.entries' | translate" [isTable]="true">
                    <div class="table-responsive">
                        <table class="entry_table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{{ 'stock.when' | translate }}</th>
                                    <th>{{ 'stock.product' | translate }}</th>
                                    <th class="green">{{ 'stock.available' | translate }}</th>
                                    <th>{{ 'stock.price' | translate }}</th>
                                    <th>{{ 'stock.from' | translate }}</th>
                                    <th>{{ 'stock.invoice' | translate }}</th>
                                    <th>{{ 'stock.invoiceQty' | translate }}</th>
                                    <th>{{ 'stock.location' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let entry of entries" (click)="getTransaction(entry?.id)">
                                    <td style="color: gray;">{{entry?.id}}.</td>
                                    <td><i>{{getMoment(entry?.document?.doc_date)}}</i></td>
                                    <td>
                                        <product-tag [productName]="entry?.product?.label?.name" [productType]="entry?.product?.label?.type"></product-tag>
                                    </td>
                                    <td class="green split" style="font-size: 17px;  text-align: center">
                                        <b style="width: 40px!important;">{{entry?.remains_quantity | number: '1.0-2'}}</b> 
        
                                        <div class="inlin">
                                            <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                        </div>
                                    </td>
                                    <td class="split"><b>{{entry?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </b> 
                                        <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                            <b style="font-weight: normal;" *ngIf="unit?.id === entry?.unit">{{unit?.shortName}}</b>
                                            <ng-container *ngIf="unit?.id !== entry?.document?.currency">
                                                <ng-container *ngFor="let childUnit of unit?.children">
                                                    <b style="font-weight: normal;" *ngIf="childUnit?.id === entry?.document?.currency">{{childUnit?.shortName}}</b> 
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <!-- <ng-container *ngFor="let party of globalRegistry?.systemData?.parties">
                                            <span *ngIf="party?.id === entry?.document?.party_from">{{party?.name}}</span>
                                        </ng-container> -->
                                        <span>{{getPartyInfo(entry?.document?.party_from) | async}}</span>
                                    </td>
                                    <td>{{entry?.document?.doc_id}} <span style="display:block;">{{entry?.document?.doc_date | date: "MMM dd, yyyy"}}</span></td>
                                    <td class="p45 split"><b style="width: 40px!important;">{{entry?.quantity}} </b> 
                                        <div class="inlin">
                                           <b>{{ entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                            <b *ngIf="farm?.id === entry?.document?.farm">{{farm?.name}}</b>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                </fap-section-container>
            </div>
        </div>
        <div class="transactions" *ngIf="transactions[0]?.id" (scroll)="onDivScroll('transactionsTable', $event)">
            <div class="sbox" #transactionsTable>
                <fap-section-container [title]="getTitle(transactions[0])" [isTable]="true">
                    <div class="table-responsive">
                        <table class="transaction_table">
                            <thead>
                                <tr>
                                    <th>{{ 'stock.when' | translate }}</th>
                                    <th>{{ 'stock.quantity' | translate }}</th>
                                    <th>{{ 'stock.cost' | translate }}</th>
                                    <th>{{ 'stock.from' | translate }}</th>
                                    <th>{{ 'stock.to' | translate }}</th>
                                    <th>{{ 'stock.document' | translate }}</th>
                                    <th>{{ 'stock.location' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transaction of transactions">
                                    <td><i>{{getMoment(transaction?.document?.doc_date)}}</i></td>
                                    <td class="split trr" style="font-size: 17px;  text-align: center;" [style.color]="transaction?.quantity > 0 ? 'rgba(43, 103, 76, 1)' : 'rgba(255, 0, 0, 1)'"><b style="width: 40px!important">
                                        {{transaction?.quantity | number: '1.0-2'}}
                                    </b> 
        
                                    <div class="inlin">
                                        <b>{{ transaction?.resource_entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                    </div>
                                </td>
                                <td><b>{{transaction?.cost | number:'1.'+globalRegistry.systemData.company.digits}} </b> 
                                    <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                        <b style="font-weight: normal;" *ngIf="unit?.id === transaction?.document?.currency">{{unit?.shortName}}</b>
                                        <ng-container *ngIf="unit?.id !== transaction?.resource_entry?.unit">
                                            <ng-container *ngFor="let childUnit of unit?.children">
                                                <b style="font-weight: normal; padding-left: 5px;" *ngIf="childUnit?.id === transaction?.document?.currency">{{childUnit?.shortName}}</b> 
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <span>{{getPartyInfo(transaction?.document?.party_from) | async}}</span>
                                </td>
                                <td>
                                    {{getPartyInfo(transaction?.document?.party_to) | async}}
                                </td>
                                <td (click)="goToBill(transaction?.document?.id, transaction?.document?.type)"><a class="underline">{{transaction?.document?.doc_id}} <span style="display: block;">{{transaction?.document?.doc_date | date: "MMM dd, yyyy"}}</span></a></td>
                                    <td><ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                        <b *ngIf="farm?.id === transaction?.document?.farm">{{farm?.name}}</b>
                                    </ng-container></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fap-section-container>                
            </div>
        </div>
    </div>
</div>

</div>

<fap-modal #productsModal [inputIsLarge]="true"
[inputTitle]="'stock.products' | translate" [showFooter]="false">
    <products-modal (hideModal)="hideProducts()" [isSelectable]="false" [show]="showProducts"></products-modal>
</fap-modal>

<fap-modal #docsModal [inputIsExtraLarge]="true"
[inputTitle]="'stock.documents' | translate" [showFooter]="false">
    <documents-modal (hideModal)="hideDocuments()" [isSelectable]="false" [show]="showDocuments"></documents-modal>
</fap-modal>

<fap-modal #addEditProductModal
[inputTitle]="'product.addEditProduct' | translate">
        <fap-add-edit-product 
            [productId]="productId"
            (closeModal)="addEditProductModal.hideModal()"
            (addedProduct)="getProducts();addEditProductModal.hideModal()"
            (updatedProduct)="getProducts();addEditProductModal.hideModal()"
        >
        </fap-add-edit-product>
</fap-modal>