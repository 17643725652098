import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    ElementRef,
    ChangeDetectorRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Router, Data, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { FapModalComponent } from '../../../../../shared/partials';
import { FapModalButtonInterface } from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';


@Component({
    selector: 'fap-billing-plan',
    templateUrl: './fap_billing-plan.component.html',
    styleUrls: ['./fap_billing-plan.component.scss']
})
export class FapBillingPlanComponent implements OnInit, OnDestroy, AfterViewInit {
    public data$: Observable<Data>;

    @ViewChild('paymentModal', { static: true })
    public paymentModal: FapModalComponent;

    public paymentModalButtonPrimaryInterface: FapModalButtonInterface;
    public paymentModalButtonSecondaryInterface: FapModalButtonInterface;

    @ViewChild('cardInfo')
    public cardInfo: ElementRef;
    public _totalAmount: number;
    public card: any;
    public cardHandler: any = this.onChange.bind(this);
    public cardError: string;

    private unsubscribe: Subject<any>;

    constructor(
        public translate: TranslateService,
        public router: Router,
        private cd: ChangeDetectorRef,
        public route: ActivatedRoute) {

        this.unsubscribe = new Subject();
    }

    public ngOnInit(): void {
        this.data$ = this.route.data.pipe(map((data: Data) => {
            return {
                mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
            };
        }));

    }

    public ngOnDestroy(): void {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();

        if (this.card) {
            // We remove event listener here to keep memory clean
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }

    public subscribeToPlan(plan: object): void {
        console.log(plan);
        this._totalAmount = 30;
        this.paymentModal.showModal();
    }

    public ngAfterViewInit(): void {
        this.initiateCardElement();
        // this.setupPaymentModal();
    }

    public initiateCardElement(): void {
        // Giving a base style here, but most of the style is in scss file
        const cardStyle: object = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',

                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };
        this.card = elements.create(
            'card',
            {
                hidePostalCode: true,
                cardStyle
            }
        );
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }

    public onChange({error}: any): void {
        if (error) {
            this.cardError = error.message;
        } else {
            this.cardError = null;
        }
        this.cd.detectChanges();
    }

    public async createStripeToken(): Promise<void> {
            const {token, error}: any = await stripe.createToken(this.card);
            if (token) {
                this.onSuccess(token);
            } else {
                this.onError(error);
            }
    }

    public onSuccess(token: any): void {
            // this.dialogRef.close({token});
            console.log(token);
            this.paymentModal.hideModal();
    }

    public onError(error: Error): void {
            if (error.message) {
                this.cardError = error.message;
            }
    }
}
