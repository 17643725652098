<fap-header>
  <div class="d-flex filters align-items-center">
    <div class="tree_combo mr-3">
      <fap-tree-combo></fap-tree-combo>
    </div>
    <div class="add_btn mr-3">
      <fap-round-button [backgroundColor]="'tertiary'" (clicked)="createNewPost()"></fap-round-button>
    </div>
    <fap-search-filter
      [isDateFilterEnabled]="true"
      [isExportFilterEnabled]="true"
      [filterLabel]="'Filter'"
      [exportLabel]="'Export'"
      [component]="'posts'"
      (filter)="filterPosts($event)"
      (export)="exportPosts()"
    ></fap-search-filter>
  </div>
</fap-header>
<div
  class="container-fluid ter-container nc"
  infiniteScroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="500"
  (scrolled)="scrolledDown()"
  [scrollWindow]="false"
>
  <posts-layout
    [farms]="filteredFarms"
    [farmForecastMapping]="farmForecastMapping"
    (deletePost)="deletePost($event)"
    [posts]="filteredPosts"
    [persons]="globalRegistry?.systemData?.persons"
    [images]="images"
  >
  </posts-layout>
</div>
