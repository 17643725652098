import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'fap-upload-avatar',
    templateUrl: './fap-upload-avatar.component.html',
    styleUrls: ['./fap-upload-avatar.component.scss']
})
export class FapUploadAvatarComponent implements OnChanges {

    @Input()
    public inputLabel: string;

    @Input()
    public inputDescription: string;

    @Input()
    public inputImageUrl: string | ArrayBuffer;

    @Input()
    public imageIsRemovable = true;

    @Input()
    public inputHaveOutline = true;

    @Input()
    public inputIsCircle = false;

    @Input()
    public inputIsRed = true;

    @Input()
    public accentColor: string;

    @Output()
    public outputImageLoaded: EventEmitter<File> = new EventEmitter();

    @Output()
    public outputImageRemoved: EventEmitter<void> = new EventEmitter();

    public defaultImage = '';
    public innerImage: string | ArrayBuffer = '';

    constructor() {
        //
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'inputImageUrl') &&
            this.inputImageUrl !== null && this.inputImageUrl !== undefined &&
            this.inputImageUrl !== '') {

            this.innerImage = this.inputImageUrl;
        }
    }

    public handleImageRemove(): void {
        this.outputImageRemoved.emit();
        this.innerImage = this.defaultImage;
    }

    public handleInputChange(event: Event): void {
        const target: any = event.target;
        this.readAndProcess(target);
    }

    private readAndProcess(target: any): void {
        if (target.files && target.files[0]) {
            this.outputImageLoaded.emit(target.files[0]);

            const reader: FileReader = new FileReader();
            reader.readAsDataURL(target.files[0]);

            reader.onloadend = (): void => {
                this.innerImage = reader.result;
            };
        }
    }
}
