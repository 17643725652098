import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { ResponseModel } from 'src/app/core/models/response.model';
import { DocumentModel } from 'src/app/core/models/stock/document.model';
import { CompanyService } from 'src/app/core/services/api/company/company.service';
import { PartyService } from 'src/app/core/services/api/company/party.service';
import { StockService } from 'src/app/core/services/api/stock/stock.service';
import { UserService } from 'src/app/core/services/api/user/user.service';
import { MapService } from 'src/app/shared/layout/fap_main-map/service/map-service';

@Component({
  selector: 'print-pdf',
  templateUrl: './print-pdf.component.html',
  styleUrls: ['./print-pdf.component.scss']
})
export class PrintPdfComponent implements OnInit, OnDestroy {

  @ViewChild('exportPDFbtn') fileInput: ElementRef;
  public subscription: Subscription = new Subscription();
  public billId;
  public bill;
  public items = [];
  public p_items = [];
  public resDocItemIds = [];
  public userAddress: any;
  public companyAddress: any;
  public partyFrom;
  public partyTo;

  constructor(public mapService: MapService, public stockService: StockService, public route: ActivatedRoute, public globalRegistry: GlobalRegistryService, public companyService: CompanyService, public userService: UserService, public partyService: PartyService) {
    this.userService.getCurrentUser.subscribe((user) => {
      this.userAddress = this.getAddress(user.profile.party.address_id);
    });
  }

  public getAddress(addressId) {
    if(addressId) {
      this.companyService.getSingleAddress(addressId).subscribe(data => {
        console.log(data.body.results);
        return data.body.results;
      })
    }
  }

  ngOnInit(): void {
    this.mapService.hideMap();
    console.log(this.fileInput);
    this.subscription.add(
      combineLatest([this.route.params]).subscribe(
          ([params]: [Params]) => {
              console.log(params);
              if (params['billId'] !== undefined) {
                  this.billId = Number(params['billId']);
                  this.getBill(this.billId);
              }
          }
      )
  );
    setTimeout(() => {
      // this.fileInput.nativeElement.click()
    }, 1000)
  }

  public getBill(billId) {
    this.subscription.add(this.stockService.getDoc(billId).subscribe((response: ResponseModel<DocumentModel>): void => {
      this.bill = response.body.results;
      console.log(this.bill);
      this.getDocItems(this.bill.id)
    }));
  }

  getDocItems(id) {
    const p0 = {
      document: id,
      type: 0
    };
    const p1 = {
      document: id,
      type: 1
    }
    if(this.route.snapshot.queryParams.type !== 'invoice_in') {
      this.subscription.add(
        this.stockService.getDocument(p0).subscribe(data => {
          const items = [];
          data.body.results.forEach(element => {
          const obj = {
            id: element.id,
            product: element.resource_entry.product,
            currency: element.document.currency,
            quantity: +element.quantity,
            unitPrice: +element.resource_entry.cost,
            vatPercentage: +element.resource_entry.vat,
            totalPrice: +(+element.quantity * +element.resource_entry.cost),
            vat: this.getVat(+element.quantity, +element.resource_entry.cost, +element.resource_entry.vat / 100),
            grandTotal: +(+element.quantity * element.resource_entry.cost) + this.getVat(+element.quantity, +element.resource_entry.cost, +element.resource_entry.vat / 100).toFixed(this.globalRegistry.systemData.company.digits),
            costOrig: +element.cost_orig
          }
          items.push(obj); 
          this.resDocItemIds.push(obj.id);
          this.items = items;
          console.log(items);
        });
      }))
    }

    if(this.route.snapshot.queryParams.type == 'production_note' || this.route.snapshot.queryParams.type == 'estimation_note' || this.route.snapshot.queryParams.type == 'invoice_in') {
      this.subscription.add(
        this.stockService.getDocument(p1).subscribe(data => {
          const p_items = [];
          const items = [];
          data.body.results.forEach(element => {
            if(this.route.snapshot.queryParams.type === 'invoice_in') {
              const obj = {
                id: element.id,
                product: element.resource_entry.product,
                currency: element.document.currency,
                quantity: +element.quantity,
                unitPrice: +element.resource_entry.cost,
                vatPercentage: +element.resource_entry.vat,
                totalPrice: +(+element.quantity * +element.resource_entry.cost),
                vat: this.getVat(+element.quantity, +element.resource_entry.cost, +element.resource_entry.vat / 100),
                grandTotal: +(+element.quantity * element.resource_entry.cost) + this.getVat(+element.quantity, +element.resource_entry.cost, +element.resource_entry.vat / 100),
                costOrig: +element.cost_orig
              }
              items.push(obj); 
              this.resDocItemIds.push(obj.id);
              this.items = items;
              } else {
                const obj = {
                  id: element.id,
                  product: element.resource_entry.product,
                  currency: element.document.currency,
                  quantity: +element.quantity,
                  unitPrice: +element.cost,
                  vatPercentage: +element.vat,
                  totalPrice: +(+element.quantity * +element.cost),
                  vat: this.getVat(+element.quantity, +element.cost, +element.vat / 100),
                  grandTotal: +(+element.quantity * element.cost) + this.getVat(+element.quantity, +element.cost, +element.vat / 100)
                }
                p_items.push(obj); 
                this.resDocItemIds.push(obj.id);
                this.p_items = p_items;
              }
            });
        })
      )
    }
  }

  getVat(quantity, unitPrice, vat) {
    const value = quantity * unitPrice;
    const vatValue = vat * value
    return vatValue;
  }
  
  print() {
    let printContents;
    printContents = document.getElementById("print").innerHTML.toString();
    printContents = (<string>printContents + "").replace("col-sm", "col-xs");
    // console.log(printContents);
    const popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Reporte</title>
          <meta name="viewport" content="width=10000, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
          <link rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
          <style>
            .salto_pagina_despues{
              page-break-after:always;
            }
            
            .salto_pagina_anterior{
              page-break-before:always;
            }

            .content {
              height: 100vh;
              width: 100%;
              display: flex;
              flex-direction: column;
            }

            .img-content {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .observation {
              height: 150px;
              overflow: hidden;
              overflow-y: auto;
            }
          </style>
        </head>
        <body onload="window.print();">
          ${printContents}
        </body>
      </html>`);
    /* window.close(); */
    popupWin.document.close();
  }

  ngOnDestroy(): void {
    this.mapService.showMap();
  }

}
