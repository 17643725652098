import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { FapUnitsLayoutComponent } from './components/fap_units-layout/fap_units-layout.component';
import { UnitsContainerComponent } from './containers/units-container/units-container.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { UnitsComponent } from './units.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatTableModule,
        MatSelectModule,
        PartialsModule
    ],
    declarations: [
        UnitsContainerComponent,
        FapUnitsLayoutComponent,
        UnitsComponent
    ]
})

export class UnitsModule {

}
