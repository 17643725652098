import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    public transform(items: any, term: string, excludes: any = []): any {
        if (!term || !items) {
          return items;
        }

        return this.filter(items, term, excludes);
    }

    private filter(items: Array<{ [key: string]: any }>, term: string, excludes: any): Array<{ [key: string]: any }> {

        const toCompare: string = term.toLowerCase();

        function checkInside(item: any, word: string): boolean {

          if (typeof item === 'string' && item.toString().toLowerCase().includes(toCompare)) {
            return true;
          }

          for (const property in item) {
            if (item[property] === null || item[property] === undefined || excludes.includes(property)) {
              continue;
            }
            if (typeof item[property] === 'object') {
              if (checkInside(item[property], word)) {
                return true;
              }
            }
            else if (item[property].toString().toLowerCase().includes(toCompare)) {
              return true;
            }
          }
          return false;
        }

        return items.filter( (item: any): boolean => {
          return checkInside(item, term);
        });
      }
}
