import {
    Injectable
} from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable()
export class FapConfig {
    private static sharedInstance: FapConfig = new FapConfig();


    public readonly APP_VERSION: string = '0.1.0';
    public readonly API_VERSION: string = '1.0';

    public readonly NO_IMAGE_PATH: string = 'assets/images/single-page/1_thumb.jpg';

    private readonly API_URL_SUFIX: string = 'api/';

    public static getInstance(): FapConfig {
        return FapConfig.sharedInstance;
    }


    constructor() {
        if (FapConfig.sharedInstance) {
            throw new Error('Error: Use FapConfig.sharedInstance <singleton>');
        }
        FapConfig.sharedInstance = this;
    }

    public get API_URL(): string {
        return environment.baseBackendUrl + this.API_URL_SUFIX + this.API_VERSION;
    }

    public get WS_URL(): string {
        let wsUrl =  environment.baseBackendUrl;
        wsUrl = wsUrl.replace('http', 'ws');
        return wsUrl;
    }

    public get OVERVIEW_TOKEN(): string {
        return environment.overviewToken
    }

    public get APP_NAME(): string {
        return environment.appName
    }

    public get APP_ICON(): string {
        return environment.appIcon
    }

}
