export enum ObjectEnum {
    Bin = 205,
    Pool = 20000,
    PoolLight = 20001
}

export enum UnitEnum {
    Lid = 1000,
    Temperature = 10,
    Humidity = 20,
    Pressure = 40,
    Ph = 400,
    Redox = 440,
    Chlorine = 480,
    Dose = 460
}