import { FapBaseModel } from '../../base/fap-base-model';
import { IngredientModel } from './ingredient.model';

export class ProductLabelModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'name_t',
        'code',
        'type',
        'producer',
        'produced',
    ];

    public static requiredFields: Array<string> = [
        'id',
        'name_t',
        'code',
        'type',
    ];

    public static extendedFields: {} = {
        
    };

    public id: number;
    public name: string;
    public nameT: string;
    public uniqueId: string;
    public code: string;
    public type: number;
    public producer: string;
    public produced: any;
    public ingredients: Array<IngredientModel>;
}
