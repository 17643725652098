import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { PathogensComponent } from './pathogens.component';
import { PathogensContainerComponent } from './containers/pathogens-container/pathogens-container.component';
import { PathogensEditContainerComponent } from './containers/pathogens-edit-container/pathogens-edit-container.component';
import { PartialsModule } from '../../views-partials/partials.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule
    ],
    declarations: [
        PathogensComponent,
        PathogensContainerComponent,
        PathogensEditContainerComponent
    ],
})

export class PathogensModule {

}
