<!-- <ng-container *ngFor="let farm of farms"> -->
    <!-- <fap-farm-header
    [addRoute]="'equipments'"
    [farm]="farm"
    [forecast]="farmForecastMapping[farm.id]">
    </fap-farm-header> -->
    <div class="cards-grid" *ngIf="!isLoading">
        <fap-equipment-card 
            *ngFor="let equipment of equipments"
            [equipment]="equipment"
            [equipmentTypes]="equipmentTypes"
            [equipmentPoweredTypes]="equipmentPoweredTypes"
            [parties]="parties"
            [images]="images"
            [langString]="langString"
            (deleteEquipment)="showRemoveEquipmentModal(equipment)">
        </fap-equipment-card>
    </div>
    <div class="loader_container">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>
<!-- </ng-container> -->
<!-- <div class="card-header-wrap d-flex mb-4">
    <h2 class="card-header-title">{{ 'equipment.equipmentsNotInAFarm' | translate }}</h2>
    <div [routerLink]="'/pages/equipments/add'" class="btn btn-success add-equipment-card ml-4 px-2">
        <i class="material-icons">add</i>
        {{ 'equipment.addEquipment' | translate }}
    </div>
</div>
<div class="mb-5">
    <div class="cards-grid mb-5">
        <fap-equipment-card
            *ngFor="let equipment of equipmentsWithNoFarm"
            [equipment]="equipment"
            [equipmentTypes]="equipmentTypes"
            [equipmentPoweredTypes]="equipmentPoweredTypes"
            (deleteEquipment)="showRemoveEquipmentModal(equipment)">
        </fap-equipment-card>
    </div>
</div> -->

<fap-modal #removeEquipmentModal
    [inputPrimaryButtonInterface]="removeEquipmentModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="removeEquipmentModalButtonSecondaryInterface"
    inputTitle="{{ 'equipment.removeEquipment' | translate }}">
    <span class="remove-person-text">
        {{ 'people.areYouSureYouWantToRemove' | translate }}
        <b>{{ selectedEquipment?.product?.label?.name }}</b>
    </span>
</fap-modal>

