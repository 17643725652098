import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { PeopleComponent } from './people.component';
import { FapPeopleLayoutComponent } from './components/fap_people-layout/fap_people-layout.component';
import { PeopleContainerComponent } from './containers/people-container/people-container.component';
import { FapAddEditPersonComponent } from './components/fap_add-edit-person/fap_add-edit-person.component';
import { EditPersonContainerComponent } from './containers/edit-person-container/edit-person-container.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { FapPersonHeaderComponent } from './components/fap-person-header/fap-person-header.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatSelectModule,
        MatListModule,
        InfiniteScrollModule
    ],
    declarations: [
        PeopleComponent,
        PeopleContainerComponent,
        FapPeopleLayoutComponent,
        FapAddEditPersonComponent,
        EditPersonContainerComponent,
        FapPersonHeaderComponent,
    ]
})

export class PeopleModule {

}
