import { BasePermissionsGuard } from './base-permissions.guard';
import { AuthGuard } from './auth.guard';
import { CanActivateAuthGuard } from './can-activate-auth.guards';
import { AccessGuard } from './access.guard';

export const APP_GUARDS: Array<any> = [
    BasePermissionsGuard,
    AuthGuard,
    CanActivateAuthGuard,
    AccessGuard
];
