import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FapModalComponent } from '../fap-modal/fap-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FapModalButtonInterface } from '../fap-modal/data/fap-modal-button.interface';
import { environment } from '../../../../../environments/environment';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'fap-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit, OnChanges {

  @Input() public imageIds: number[] = [];
  public images: any[] = [];
  @Input() public group: string = '';
  @Output()
  public addImage: EventEmitter <any> = new EventEmitter();
  @Output()
  public deleteImage: EventEmitter <any> = new EventEmitter();
  public mediaUrl: string = environment.mediaUrl;
  @Input() type: string = 'multiselect';
  @Input() height: any = null;
  @Input() public defaultSize:string = 'pc';
  public rowHeight = 185;

  constructor(private companyService: CompanyService, private cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, private translateService: TranslateService, private toastr: ToastrService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('imageIds')) {
        this.images = this.images.filter(image => this.imageIds.includes(image.id));
        this.imageIds.forEach(id => {
            if (!this.images.some(image => image.id === id)) {
                this.getImage(id);
            }
        });
    }
    if(changes.hasOwnProperty('height')) {
      this.getHeight(this.height);
    }
}

getHeight(height) {
  if(height && height[this.defaultSize] && height[this.defaultSize]['rows']) {
    this.rowHeight = height[this.defaultSize]['rows'] * 46;
    return this.rowHeight - 30
  }
  console.log(this.rowHeight);
}

public getImage(id) {
    if (id && id != 0) {
        this.companyService.getCompanyImage(id).subscribe({
            next: (data) => {
                const exists = this.images.some(i => i.id === id);
                if (!exists) {
                    this.images.push(data.body.results);
                }
            },
            error: (err) => {
                console.error(err);
                const index = this.imageIds.indexOf(id);
                if (index !== -1) {
                    this.imageIds.splice(index, 1);
                }
            }
        });
    }
}


  public addNewImage(newImage) {
    this.images.unshift(newImage);
    this.addImage.emit(newImage);
  }

}
