<div class="notedit table-responsive">
    <table class="items_table" *ngIf="items && items.length > 0">
        <thead>
            <tr>
                <th>{{ "activities.item" | translate }}</th>
                <th>{{ "activities.qty" | translate }}</th>
                <th *ngIf="showCosts">{{"activities.cost" | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items">
                <td>
                    <product-tag [productName]="item?.product?.label?.name" [productType]="item?.product?.label?.type"></product-tag>
                </td>
                <td>
                    <cell-cost-qty 
                        [value]="item?.real?.qty"
                        [unit]="item?.product | getProductUnit: globalRegistry?.systemData?.unitsMap"
                    >
                    </cell-cost-qty>
                    <cell-cost-qty 
                        [style.display]="display ? 'block' : 'none'"
                        [value]="item?.estimation?.qty"
                        [unit]="item?.product | getProductUnit: globalRegistry?.systemData?.unitsMap"
                        [bold]="false"
                        [smallFont]="true"
                        [color]="getColor(item?.real?.qty, item?.estimation?.qty)"
                    >
                    </cell-cost-qty>
            </td>
            <td *ngIf="showCosts">
                <cell-cost-qty 
                    [value]="item?.real?.cost"
                    [unit]="defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap"
                >
                </cell-cost-qty>
                <cell-cost-qty 
                    [style.display]="display ? 'block' : 'none'"
                    [value]="item?.estimation?.cost"
                    [unit]="defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap"
                    [bold]="false"
                    [smallFont]="true"
                    [color]="getColor(item?.real?.cost, item?.estimation?.cost)"
                >
                </cell-cost-qty>
            </td>
            </tr>
            <tr *ngIf="showCosts" class="costs_row">
                <td></td>
                <td class="big_text">{{'stock.total' | translate}}</td>
                <td class="big_text">
                    <cell-cost-qty 
                    [value]="cost?.real"
                    [unit]="defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap"
                >
                </cell-cost-qty>
                <cell-cost-qty 
                    [style.display]="display ? 'block' : 'none'"
                    [value]="cost?.estimation"
                    [unit]="defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap"
                    [bold]="false"
                    [smallFont]="true"
                    [color]="getColor(item?.real?.cost, item?.estimation?.cost)"
                >
                </cell-cost-qty>
                </td>
            </tr>
        </tbody>
    </table>
</div>