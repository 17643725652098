import { Component } from '@angular/core';


@Component({
    templateUrl: './weeds.component.html',
    styleUrls: ['./weeds.component.scss']
})
export class WeedsComponent {

}
