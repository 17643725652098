import { FapBaseModel } from '../../base/fap-base-model';
import { ResourceTypeModel } from './../type/resource-type.model';
import { ProductModel } from './product.model';

export class StockItemModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'farm',
        'product',
        'quantity',
        'quantity_in',
        'quantity_out',
        'unit',
        'checked'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'farm',
        'product',
        'quantity',
        'quantity_in',
        'quantity_out',
        'unit'
    ];

    public static extendedFields: {} = {
        type: ResourceTypeModel
    };

    public id: number;
    public farm: number;
    public product: ProductModel = new ProductModel();
    public quantity: number;
    public quantityIn: number;
    public quantityOut: number;
    public unit: string;
    public checked =  false;
}
