import { Component } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { TypesService } from '../../../../../core/services/api/types/types.service';

@Component({
  templateUrl: './crops-container.component.html',
  styleUrls: ['./crops-container.component.scss']
})
export class CropsContainerComponent {
  public cropTypes = [];
  public subscriptions: Array<Subscription> = [];
  constructor(public globalRegistry: GlobalRegistryService, public attributeService: AttributeService, public widgetsService: WidgetsService, public mapService: MapService, public typeService: TypesService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.typeService.getCropTypes().subscribe(data => {
      this.cropTypes = data.model;
      this.typeService.setCropTypes(data.model);
    }))
    this.widgetsService.setSize(12);
    this.mapService.hideMap();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
    this.widgetsService.setSize(6);
  }
}
