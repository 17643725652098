import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MapService } from '../../../../shared/layout/fap_main-map/service/map-service';
import { CropService } from '../../../../core/services/api/crop/crop.service';
import { WidgetsService } from '../../../../core/services/api/widgets/widgets.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';
import { FapModalComponent } from '../../../../shared/partials';
import { TranslateService } from '@ngx-translate/core';
import { StockService } from '../../../../core/services/api/stock/stock.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { ProductUsageTypeModel } from '../../../../core/models/stock/product_usage_type.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-treatments-list',
  templateUrl: './treatments-list.component.html',
  styleUrls: ['./treatments-list.component.scss']
})
export class TreatmentsListComponent implements OnInit, OnDestroy {

  @ViewChild('addEditTreatmentsModal')
  public addEditTreatmentsModal: FapModalComponent;

  public treatments: any[] = [];
  public treatmentId: number = -1;
  public treatment = null;
  public productUsageTypes: Array<ProductUsageTypeModel> = [];
  public formTypes = [];
  public count: number = 0;
  public pageNumber: number = 1;
  public isLoading: boolean = false;
  public limit: number = 30;
  public searchString = '';
  public subscriptions: Array<Subscription> = [];

  constructor(private toastr: ToastrService, private mapService: MapService, private cropService: CropService, private widgetService: WidgetsService, private cacheService: CacheResolverService, private translate: TranslateService, private stockService: StockService, private typeService: TypesService, private activatedRoute: ActivatedRoute, public globalRegistry: GlobalRegistryService) {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (queryParams['filter'] !== undefined) {
        this.isLoading = true;
        const search = (queryParams['filter'] as string).toLowerCase();
        this.searchString = search;
        this.stockService.getProductUsages({ search }).subscribe(data => {
          const urlWithSearch = this.stockService.getProductUsages('activities/') + 'search=' + search;
          this.cacheService.delete(urlWithSearch);
          this.treatments = data.body.results;
          this.count = data.body.count
          this.isLoading = false;
        });
      }
    }));
  }

  ngOnInit(): void {
    this.mapService.hideMap();
    this.widgetService.setSize(12);
    this.getTreatments();
    this.getProductUsageTypes();
    this.getFormTypes();
    this.widgetService.setMapResize(false);
  }

  public handlePagination(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.getTreatments();
  }

  public getTreatments(): void {
    this.isLoading = true;
    const subscription = this.stockService.getProductUsages({ page: this.pageNumber, search: this.searchString }).subscribe(
      (data) => {
        // this.filteredActivities = [];
        this.treatments = data.body.results;
        // console.log(data);
        this.count = data.body.count;
        // this.nextToken = activities.body.next ? this.globalRegistry.getQueryStringParams(activities.body.next.split('?')[1]) : null;
        const urlWithLimit = this.stockService.getUrl('product_usages/') + 'page=' + this.pageNumber + '&search=' + this.searchString;
        this.cacheService.delete(urlWithLimit);
        if(data.body.count) {
          this.isLoading = false;
        }
      },
      (error) => {
        this.toastr.error(error.error.results.error);
      }
    );

    this.subscriptions.push(subscription);
  }

  public getFormTypes() {
    const url = this.typeService.getUrl('form/');
    this.cacheService.delete(url);
    this.typeService.getFormTypes().subscribe((data) => {
        this.formTypes = data.body.results;
        // console.log(data.body.results);
    });
  }

  public getProductUsageTypes() {
    let url = this.typeService.getUrl('product_usage/')
    this.typeService.getProductUsageTypes().subscribe(data => {
      this.cacheService.delete(url);
      this.productUsageTypes = data.model;
    })
  }

  newTreatment() {
    this.addEditTreatmentsModal.showModal();
    this.treatment = null;
    this.treatmentId = -1;
  }

  editTreatment(treatment) {
    let treatmentId = treatment.id;
    this.stockService.getProductUsageById(treatmentId).subscribe(data => {
      if(data) {
        this.treatment = data.body.results;
        this.treatmentId = this.treatment.id;
        this.addEditTreatmentsModal.showModal();
      }
    }, (error) => {
      this.toastr.error(error.error.results.error)
    })
  }

  deleteTreatment(treatmentId: number) {
    this.stockService.deleteProductUsage(treatmentId).subscribe(
        () => {
            this.treatments = this.treatments.filter(product => product.id !== treatmentId);
            this.toastr.success(this.translate.instant('productsUsage.productUsageDeletedSuccessfully'));
        },
        error => {
          this.toastr.error(error.error.results.error);
        }
    );
  }

  updateTreatment(updatedProduct: any) {
    const index = this.treatments.findIndex(product => product.id === updatedProduct.id);
    if (index !== -1) {
      this.treatments[index] = updatedProduct;
      this.treatmentId = -1;
      this.addEditTreatmentsModal.hideModal();
      this.toastr.success(this.translate.instant('productsUsage.productUsageUpdatedSuccessfully'));
    }
  }

  addTreatment(product: any) {
    this.treatments.unshift(product);
    this.treatmentId = -1;
    this.addEditTreatmentsModal.hideModal();
    this.toastr.success(this.translate.instant('productsUsage.productUsageCreatedSuccessfully'));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.mapService.showMap();
    this.widgetService.setSize(6);
    this.widgetService.setMapResize(true);
  }
}
