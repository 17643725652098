import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { AuthViewsComponent } from './auth-views.component';
import { FapLoginComponent } from './fap_views/fap_login/fap_login.component';
import { FapRegisterComponent } from './fap_views/fap_register/fap_register.component';
import { FapConfirmAccountComponent } from './fap_views/fap_confirm-account/fap_confirm-account.component';
import { FapCreateCompanyComponent } from './fap_views/fap_create-company/fap_create-company.component';
import { FapBillingPlanComponent } from './fap_views/fap_billing-plan/fap_billing-plan.component';
import { SharedModule } from '../../../shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from "../../views-partials/partials.module";
import { AgmCoreModule } from '@agm/core';
import { MapHelper } from '../../../core/heplers/map.helper';
import { FapResetPasswordComponent } from './fap_views/fap-reset-password/fap-reset-password.component';

@NgModule({
    declarations: [
        AuthViewsComponent,
        FapLoginComponent,
        FapRegisterComponent,
        FapConfirmAccountComponent,
        FapCreateCompanyComponent,
        FapBillingPlanComponent,
        FapResetPasswordComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        PartialsModule,
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
    ]
})
export class AuthViewsModule {
    public static forRoot(): ModuleWithProviders<AuthViewsModule> {
        return {
            ngModule: AuthViewsModule,
            providers: []
        };
    }
}
