import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { combineLatest, Subscription } from "rxjs";
import { GlobalRegistryService } from "../../../core/global-registry/global-registry.service";
import { DrillParams } from "../../../core/interfaces/widget/drill-info.interface";
import { SoilLevels } from "../../../core/interfaces/widget/soil-level.interface";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import {
  SensorData,
  SensorService,
} from "../../../core/services/api/sensor/sensor.service";

@Component({
  selector: "fap-soil-widget",
  templateUrl: "./fap_soil-widget.component.html",
  styleUrls: ["./fap_soil-widget.component.scss"],
})
export class FapSoilWidgetComponent implements OnChanges, OnInit, OnDestroy {
  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public widget: WidgetModel;

  public params: DrillParams;

  public sensorDataSubscription: Subscription;
  public toggleMain = false;
  public toggleSub = false;

  public levelValues: SoilLevels = {
    level30: {},
    level15: {},
    level10: {},
    level5: {},
  };


  constructor(
    public globalRegistry: GlobalRegistryService,
    public sensorService: SensorService
  ) {}

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
    return lot ? lot.name : '';
  }

  public ngOnInit() {
    this.sensorDataSubscription = this.sensorService.drill.subscribe(
      (params) => {
        this.params = params;
        this.getSensorData();
      }
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    const monthBack = new Date();
    monthBack.setFullYear(new Date().getFullYear() - 3);
    this.params = {
      from: monthBack.toISOString(),
      to: new Date().toISOString(),
      agg_by: "all",
    };
    if (Object.prototype.hasOwnProperty.call(changes, "widget") && this.widget) {
      this.getSensorData();
    }
  }

  public ngOnDestroy() {
    this.sensorDataSubscription.unsubscribe();
  }

  hideMain() {
    this.toggleMain = false
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  public getTotal(sensorData: SensorData[]): number {
    let total = 0;
    for (const data of sensorData) {
      total += data.total;
    }
    return total;
  }

  public getSensorData(): void {
    const levels: SoilLevels = this.widget.config["settings"]["options"];

    for (const level of Object.keys(levels)) {
      const observables = Object.values(levels[level]).map((sensor) =>
        this.sensorService.drillData({ ...this.params, sensor })
      );
      combineLatest(observables).subscribe((drilled) => {
        Object.keys(levels[level]).forEach(
          (key, index) =>
            (this.levelValues[level][key] = this.getTotal(drilled[index]))
        );
      });
    }
  }
}
