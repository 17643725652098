import {
    Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { MapPointInterface } from '../../../../../shared/layout/fap_main-map/data/map-point.interface';
import { MapPolygonInterface } from '../../../../../shared/layout/fap_main-map/data/map-polygon.interface';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';


@Component({
    selector: 'fap-equipment-path-row',
    templateUrl: './fap_equipment-path-row.component.html',
    styleUrls: ['./fap_equipment-path-row.component.scss']
})
export class FapEquipmentPathRowComponent implements OnInit, OnDestroy, OnChanges{

    @Input() paths = [];
    path: MapPolygonInterface
    public i = 0;
    public isLoading = false;

    constructor(public mapService:MapService) {
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.i = 0;
        setTimeout(() => {
            this.isLoading = false;
        }, 1000)
    }

    public loadPath(path) {
        this.path = path.path;
        console.log(path);
        if(this.path) {
        const points:Array<MapPointInterface> = []
        path.path.forEach(element => {
            const pt:MapPointInterface = {
                lat: element.value_coord.coordinates[0],
                lng: element.value_coord.coordinates[1],
            }
            points.push(pt);
        });
        const mapPath:MapPolygonInterface = {
            points: points,
            fillColor: 'transparent',
            strokeColor: 'yellow',
            strokeWeight: 2,
            isDraggable: false,
            isEditable: false
        }
        console.log(this.mapService.mapPolygons);
        this.mapService.mapPolygons.push(mapPath);
        this.mapService.mapMarkers.push({
            lat: points[0].lat,
            lng: points[0].lng,
            iconUrl: this.mapService.setMarkerIconColor('red'),
            infoWindowDetails: [{
                img: null,
                text: moment(path.path[0].timestamp).format('DD MMM yyyy, HH:mm') 
            }]
        });
        this.mapService.mapMarkers.push({
            lat: points[points.length - 1].lat,
            lng: points[points.length - 1].lng,
            iconUrl: this.mapService.setMarkerIconColor('red'),
            infoWindowDetails: [{
                img: null,
                text: moment(path.path[path.path.length - 1].timestamp).format('DD MMM yyyy, HH:mm') 
            }]
        });
        this.mapService.centerMapOnPolygon(mapPath);
        this.i+= 1;
        if(this.i > 1) 
        {
            // this.mapService.mapPolygons.pop();
            // this.mapService.mapMarkers.pop();
        }
        }
    }

    public ngOnDestroy(): void {
        this.mapService.mapPolygons = [];
        this.mapService.mapMarkers = [];
        this.i = 0;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'paths') && this.paths) {
            this.isLoading = false;
        }
    }
}
