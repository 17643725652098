import { FapBaseModel } from '../../base/fap-base-model';


export class PlanModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name_t',
        'lots',
        'start',
        'end',
        'progress',
        'forecast_doc',
        'forecast_cost',
        'total_cost',
        'type'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public nameT: string;
    public lots: Array<number>;
    public start: string;
    public end: string;
    public progress: number;
    public forecastDoc: number;
    public forecastCost: number;
    public totalCost: number;
    public type: number;
}
