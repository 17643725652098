<fap-header>
  <div class="d-flex filters align-items-center">
    <div class="tree_combo mr-3">
      <fap-tree-combo></fap-tree-combo>
    </div>
      <div class="add_btn mr-3">
        <fap-round-button [backgroundColor]="'tertiary'" (clicked)="createNewScheduler()"></fap-round-button>
      </div>
    </div>
</fap-header>
<div
  class="container-fluid ter-container nc"
  style="overflow-x: hidden; width: 100%"
>
  <scheduler-layout
    [farms]="filteredFarms"
    [actuators]="actuators"
    [sensors]="sensors"
    [devices]="devices"
    [farmForecastMapping]="farmForecastMapping"
    (addNote)="addSensor($event)"
    (deleteNote)="deleteSensor($event)"
  >
  </scheduler-layout>
</div>
