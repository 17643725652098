import { FapBaseModel } from '../../base/fap-base-model';


export class UnitModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'name_t',
        'genre',
        'short_name',
        'parent',
        'children',
        'is_default',
        'locked'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
        children: UnitModel
    };

    public id: number;
    public name: string;
    public nameT: string;
    public genre: string;
    public shortName: string;
    public children: Array<UnitModel> = [];
    public parent: number;
    public isDefault: boolean;
    public locked: boolean;
}
