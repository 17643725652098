<fap-header>
    <div class="d-flex filters align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <fap-search-filter
          [isSearchEnabled]="true"
  >     </fap-search-filter>
      </div>
  </fap-header>
<div class="stock_box d-flex">
    <div class="stock_left">
        <div class="sbox">
            <!--<div class="title_row">
                <p>Diseases</p>
                 <span
                    (click)="newDisease()"
                    class="theme_color"
                ></span> 
            </div>
             <div class="table-responsive">
            <table class="stock_table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Latin</th>
                        <th>Type</th>
                        <th>Images</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of diseases">
                        <td><input type="radio" name="check" (change)="selectEntry($event, item)"></td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.latin}}</td>
                        <td>{{item?.type}}</td>
                        <td>
                            <ng-container *ngFor="let image of images">
                                <span *ngIf="image?.id == item?.images[0]">
                                    <img [src]="image?.file" alt="">
                                </span>
                            </ng-container>
                        </td>
                        <td>
                            <div class="actions">
                                <i class="fa fa-edit" (click)="editDisease(item?.id); $event.stopPropagation()"></i>
                                <i class="fa fa-trash" (click)="deleteDisease(item?.id); $event.stopPropagation()"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div> -->
          <div class="new_table">
            <fap-section-container [title]="'Diseases'">
                <div class="diseases_ov" infiniteScroll
              [infiniteScrollDistance]="4"
              [infiniteScrollThrottle]="500"
              (scrolled)="scrolledDown()"
              [scrollWindow]="false">
                  <div class="disease" *ngFor="let item of diseases" (click)="selectEntry($event, item)">
                      <div class="img user_im img_place_bg">
                       
                            <div *ngIf="item?.images.length > 0">
                                <img [src]="mediaUrl + item?.images[item?.images.length - 1].file" alt="">
                            </div>
                        
                        <img *ngIf="item?.images.length == 0" src="../../../../../../assets/images/image_placeholder.png" alt="">
                      </div>
                      <div class="txt">
                          <h4><b>
                            <span *ngIf="item?.name_t">
                                <span *ngIf="getTranslation(item?.name_t) === '' || getTranslation(item?.name_t) == null || getTranslation(item?.name_t) == undefined ">{{item?.name}}</span>
                                <span *ngIf="getTranslation(item?.name_t) !== ''">{{getTranslation(item?.name_t)}}</span>
                            </span>
                            <span *ngIf="!item?.name_t">{{item.name}}</span>
                        </b> <i *ngIf="item?.latin">({{ item?.latin }})</i></h4>
                          <p (click)="displayInfo(item?.info)" [innerHTML]="getTranslation(item?.info)"></p>
                      </div>
                  </div>
              </div>
            </fap-section-container>
          </div>
        </div>
    </div>
    <div class="stock_right" *ngIf="treatments && treatments.length > 0">
        <fap-treatments-table [treatments]="treatments"></fap-treatments-table>
        <div class="transactions" *ngIf="prevention?.id">
            <div class="sbox">
                <h3>Transactions on: {{transaction?.resource_entry?.id}}. {{transaction?.resource_entry?.product?.label?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                    <b *ngIf="type?.id === transaction?.resource_entry?.product?.label?.type">{{type?.name}}</b>
                    <ng-container *ngIf="type?.id !== transaction?.resource_entry?.product?.label?.type">
                        <ng-container *ngFor="let childUnit of type?.children">
                            <b *ngIf="childUnit?.id === transaction?.resource_entry?.product?.label?.type">{{childUnit?.name}}</b> 
                        </ng-container>
                    </ng-container>
                </ng-container></h3>
                <div class="table-responsive">
                <table class="transaction_table">
                    <thead>
                        <tr>
                            <th>When</th>
                            <th>Quantity</th>
                            <th>Product</th>
                            <th>From</th>
                            <th>Document</th>
                            <th>To</th>
                            <th>Cost</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{getMoment(transaction?.resource_entry?.document?.doc_date)}}</td>
                            <td>{{transaction?.resource_entry?.quantity}} <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                <b *ngIf="unit?.id === entry?.unit">{{unit?.shortName}}</b>
                                <ng-container *ngIf="unit?.id !== transaction.resource_entry?.unit">
                                    <ng-container *ngFor="let childUnit of unit?.children">
                                        <b *ngIf="childUnit?.id === transaction.resource_entry?.unit">{{childUnit?.shortName}}</b> 
                                    </ng-container>
                                </ng-container>
                            </ng-container></td>
                            <td>{{transaction?.resource_entry?.product?.label?.name}} <ng-container *ngFor="let type of globalRegistry?.systemData?.resourceTypes">
                                <b *ngIf="type?.id === transaction?.resource_entry?.product?.label?.type">{{type?.name}}</b>
                                <ng-container *ngIf="type?.id !== transaction?.resource_entry?.product?.label?.type">
                                    <ng-container *ngFor="let childUnit of type?.children">
                                        <b *ngIf="childUnit?.id === transaction?.resource_entry?.product?.label?.type">{{childUnit?.name}}</b> 
                                    </ng-container>
                                </ng-container>
                            </ng-container></td>
                            <td><ng-container *ngFor="let party of globalRegistry?.systemData?.parties">
                                <span *ngIf="party?.id === transaction?.resource_entry?.document?.party_from">{{party?.name}}</span>
                            </ng-container></td>
                            <td>{{transaction?.resource_entry?.document?.doc_id}} <span>{{transaction?.resource_entry?.document?.doc_date | date: "MMM dd yyyy"}}</span></td>
                            <td><ng-container *ngFor="let party of globalRegistry?.systemData?.parties">
                                <span *ngIf="party?.id === transaction?.resource_entry?.document?.party_to">{{party?.name}}</span>
                            </ng-container></td>
                            <td>{{transaction?.resource_entry?.cost}} 
                                <ng-container *ngFor="let unit of globalRegistry?.systemData?.units">
                                    <b *ngIf="unit?.id === transaction?.resource_entry?.document?.unit">{{unit?.shortName}}</b>
                                    <ng-container *ngIf="unit?.id !== transaction?.resource_entry?.document?.unit">
                                        <ng-container *ngFor="let childUnit of unit?.children">
                                            <b *ngIf="childUnit?.id === transaction?.resource_entry?.document?.unit">{{childUnit?.shortName}}</b> 
                                        </ng-container>
                                    </ng-container>
                                </ng-container></td>
                            <td><ng-container *ngFor="let farm of globalRegistry?.systemData?.farms">
                                <b *ngIf="farm?.id === transaction?.resource_entry?.document?.farm">{{farm?.name}}</b>
                            </ng-container></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #contextPopup
    [titleColor]="'#ffffff'"
    inputTitle="Disease info"
>
    <div class="mod_body">
        <p [innerHTML]="getTranslation(context)"></p>
    </div>
</fap-modal>