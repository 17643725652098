

<div class="upload-image overwidth overwrite" [style.width]="inputWidth" [style.height]="inputHeight">
    <label class="uploader overwrite" ondragover="return false;"
        (click)="handleClick($event)"
        (dragenter)="handleDragEnter()"
        (dragleave)="handleDragLeave()"
        (drop)="handleDrop($event)"
        [style.height]="inputHeight">

        <i class="fa fa-plus icon"
           *ngIf="!url"></i>

        <img *ngIf="url"
             [src]="url"
             (load)="handleImageLoad()"
             [class.loaded]="imageLoaded"/>

        <input #input class="inputFileUpload" type="file" name="file" accept="image/*"
            (change)="handleInputChange($event)">
    </label>
    <i *ngIf="imageLoaded" class="icofont icofont-close delete" (click)="remove();input.value=''"></i>
</div>
