import { Component } from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    templateUrl: './units-container.component.html'
})
export class UnitsContainerComponent {

    constructor(public globalRegistry: GlobalRegistryService,
                private mapService: MapService) {
        this.mapService.hideMap();
    }
}
