<div>
	<div class="d-flex mb-3">
		<button class="btn btn-success mr-3" (click)="addWidgetModal.showModal()">{{ 'widget.board.addWidget' | translate }}</button>
		<button *ngIf="!isEditMode" class="btn btn-primary mr-3" (click)="isEditMode=true">{{ 'widget.board.editWidgets' | translate }}</button>
		<button *ngIf="isEditMode" class="btn btn-primary" (click)="isEditMode=false">{{ 'widget.board.exitEdit' | translate }}</button>
	</div>
	<div class="widgets-grid">
		<fap-widget-wrapper
			*ngFor="let widget of widgets"
			style.gridRow="span {{widget?.config['numberOfRows']}}"
			[widget]="widget"
			[isEditMode]="isEditMode"
			(deleteWidget)="deleteWidget.emit($event)">
		</fap-widget-wrapper>
	</div>
</div>
<fap-modal #addWidgetModal [inputIsMediumLarge]="true" [inputTitle]="'widget.addWidget' | translate"
	(outputOnShow)="addWidgetWizard.reset()">
	<fap-add-widget-wizard
		#addWidgetWizard
		[sensors]="sensors"
		[farms]="farms"
		[lots]="lots"
		(submitEvent)="addWidget.emit($event);addWidgetModal.hideModal()">
	</fap-add-widget-wizard>
</fap-modal>
