<div class="edit-create">
    <h5 class="mobBtn">{{ title }}</h5>

    <button *ngIf="isSubmitEnabled" (click)="navServices.emitSubmit.next(true)"  class="btn mobBtn actionBtn">
        <span>{{ 'header.save' | translate }}</span>
        <span><i class="fa fa-check"></i></span>
    </button>
    <button (click)="navServices.emitCancel.next(true); cancel.emit()" class="btn mobBtn actionBtn">
        <span>{{ 'header.cancel' | translate }}</span>
        <span><i class="fa fa-ban"></i></span>
    </button>
    <button *ngIf="isDeleteEnabled" (click)="navServices.emitDelete.next(true)"  class="btn mobBtn actionBtn">
        <span>{{ 'header.delete' | translate }}</span>
        <span><i class="fa fa-trash"></i></span>
    </button>
</div>