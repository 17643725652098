<div class="scheduler_info">
    <div class="top_row d-flex" *ngIf="showHeader">
        <div class="name">
            <b>{{ trigger?.name_t ? getTranslation(trigger?.name_t) : trigger?.id }}</b>
        </div>
        <div class="duration">
            <b>{{ trigger?.duration + ' min'}}</b>
        </div>
        <div class="until">
            <span class="display-inline-block">{{ 'Until ' }}</span>
            <b>{{ trigger?.end | date: 'MMM dd, YY' }}</b>
        </div>
    </div>
    <div class="bottom_row d-flex" *ngIf="scheduler">
        <div class="days">
            <div class="day_row months d-flex">
                <div class="left_col">{{ 'months' }}</div>
                <div class="right_col">{{ getMonths(scheduler?.month) }}</div>
            </div>
            <div class="day_row weekdays d-flex">
                <div class="left_col">{{ 'week days' }}</div>
                <div class="right_col">{{ getWeekdays(scheduler?.dweek) }}</div>
            </div>
            <div class="day_row hours d-flex">
                <div class="left_col">{{ 'day' }}</div>
                <div class="right_col">{{ getDaysOfMonth(scheduler?.day) }}</div>
            </div>
        </div>
        <div class="times">
            <div class="day_row months d-flex">
                <div class="left_col">{{ 'hours' }}</div>
                <div class="right_col">{{ getHours(scheduler?.hour) }}</div>
            </div>
            <div class="day_row months d-flex">
                <div class="left_col">{{ 'min' }}</div>
                <div class="right_col">{{ getMinutes(scheduler?.minute) }}</div>
            </div>
        </div>
    </div>
</div>
<div class="form_info" *ngIf="entityInfo">
    <h6>{{'forms.withSettings' | translate}}</h6>
    <div class="row light_bg mb-2">
        <fap-classical-form
            [entityInfo]="entityInfo"
            [formType]="formType"
            [type]="'edit'"
            [formDisplayType]="'form'"
        ></fap-classical-form>
    </div>
</div>