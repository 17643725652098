export class AlergotelRole {
    static NOT_REGISTERED = 0;
    static REGISTERED = 0x1;
    static SUPERUSER = 0x2;
    static LANDOWNER = 0x4;
    static AUTHORITY = 0x8;
  
    static TAG_REFERENCE = "reference";
  
    static isRegistered(roles: number): boolean {
      return (roles & AlergotelRole.REGISTERED) > 0;
    }
  
    static isSuperuser(roles: number): boolean {
      return (roles & AlergotelRole.SUPERUSER) > 0;
    }
  
    static isLandowner(roles: number): boolean {
      return (roles & AlergotelRole.LANDOWNER) > 0;
    }
  
    static isAuthority(roles: number): boolean {
      return (roles & AlergotelRole.AUTHORITY) > 0;
    }
  }
  