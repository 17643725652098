import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { UserComponent } from './user.component';
import { FapUserProfileContainerComponent } from './containers/fap_user-profile-container/fap_user-profile-container.component';
import { FapUserProfileLayoutComponent } from './components/fap_user-profile-layout/fap_user-profile-layout.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        PartialsModule
    ],
    exports: [
        FapUserProfileLayoutComponent,
        FapUserProfileContainerComponent
    ],
    declarations: [
        UserComponent,
        FapUserProfileContainerComponent,
        FapUserProfileLayoutComponent,
    ]
})

export class UserModule {

}
