import { Routes } from '@angular/router';
import { SimulationsAddEditContainerComponent } from './containers/simulations-add-edit-container/simulations-add-edit-container.component';
import { SimulationsContainerComponent } from './containers/simulations-container/simulations-container.component';

export const simulationsRoutes: Routes = [
    {
        path:       '',
        component:  SimulationsContainerComponent
    },
    {
        path:       'add',
        component:  SimulationsAddEditContainerComponent
    },
    {
        path: 'edit/:simulationId',
        component: SimulationsAddEditContainerComponent
    },
];
