<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="card-content">
        <div class="custom-img" *ngIf="dataSource">
          <img *ngIf="widget.settings['background']['file']" [src]="mediaUrl + widget.settings['background']['file']" style="width: 100%; object-fit: cover; max-height: 100%; max-width: 100%; height: 100%;"/>
          <ng-container>
              <span class="itm" *ngFor="let item of localSensorData" [style]="item['css']">
                  <!-- {{item | json}} -->
                  <b style="font-weight: normal; color: #fff!important;">
                <ng-container *ngIf="item?.sensor?.nameT">
                    {{ getTranslation(item?.sensor?.nameT) }}
                </ng-container>
                <ng-container *ngIf="!item?.sensor?.nameT">
                    {{ item?.objects ? item?.objects[0]?.nameT ? getTranslation(item?.objects[0]?.nameT) : item?.objects[0]?.extraText  : '' }}
                </ng-container>
            </b> <b>{{item?.sensor?.lastValue?.value_float | number:'1.'+globalRegistry.systemData.company.digits}} </b>
            <span *ngIf="item?.unitTypes && item?.unitTypes[0]?.unit != null" class="unit">{{ item?.unitTypes[0]?.unit }}</span></span>
          </ng-container>
        </div>
      </div>
</div>
