import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { ForecastService } from '../../../../../core/services/api/forecast/forecast.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { ConfirmModalService } from '../../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { DeviceModel, ObjectService } from '../../../../../core/services/api/objects/object.service';

@Component({
  selector: 'scheduler-container',
  templateUrl: './scheduler-container.component.html',
  styleUrls: ['./scheduler-container.component.scss']
})
export class SchedulerContainerComponent implements OnInit {

  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  public farms: Array<FarmModel>;
  public forecasts: Array<WeatherModel> = [];
  public subscriptions: Array<Subscription> = [];
  public currentUser;
  public filteredFarms: Array<FarmModel> = [];
  public sensors: Array<SensorModel> = [];
  public devices: Array<DeviceModel> = [];
  public actuators: Array<any> = [];
  public isSelectDialogOpen: boolean = false;
  constructor(public forecastService: ForecastService, public userService:UserService, public router:Router, public mapService:MapService, public activatedRoute:ActivatedRoute, public farmService:FarmService, public sensorService:SensorService, public globalRegistry: GlobalRegistryService, public toastyService: ToastrService, public translate: TranslateService, public confirmModalService: ConfirmModalService, public widgetService: WidgetsService,
    public http: HttpClient, public cacheService: CacheResolverService, public objectService: ObjectService) {

    this.subscriptions.push(this.userService.getCurrentUser.subscribe(data => {
      if(Object.keys(data).length != 0) {
      this.currentUser = data;
      const path = this.router.routerState.snapshot.url;
      this.userService.getMenus.subscribe(menus => {  
          if(Object.keys(menus).length != 0) {
              const pathExist = menus.some(menu => menu.path === path);
              if(pathExist === false) {
                  this.router.navigate(['pages/posts']);
              }
          }
      })
      }
  }));
    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.subscriptions.push(
      combineLatest([
        activatedRoute.queryParams,
        // this.farmService.getFarms(),
      ]).subscribe(
        ([queryParams]: [
          Params,
          
        ]): void => {
          if (!this.forecasts || this.forecasts.length === 0) {
            // this.getForecasts();
          }
          this.farms = globalRegistry.systemData.farms;
          if (queryParams["farms"] !== undefined) {
            // we have farms in query params, but need to check if we have an array of farms
            const filteredFarmIds: Array<number> = Array.isArray(
              queryParams["farms"]
            )
              ? queryParams["farms"].map((farmId: string): number =>
                  Number(farmId)
                )
              : [Number(queryParams["farms"])];
            this.filteredFarms = this.farms.filter(
              (farm: FarmModel): boolean => {
                return filteredFarmIds.includes(farm.id);
              }
            );
          } else {
            this.filteredFarms = this.farms;
          }
        }
      )
    );
    this.mapService.showMap();

    this.mapService.centerMapOnUserLocation();

   }

  ngOnInit(): void {
    this.getActuators();
    this.getSensors();
    this.getDevices();
    this.widgetService.setSize(6);
  }

  public addSensor(sensor: FormData): void {
    console.log(sensor)
  }

  public deleteSensor(sensorId: number): void {
    console.log(sensorId)
  }

  public getSensors() {
    this.sensorService.getSensors().subscribe(data => {
      this.sensors = data.model;
    })
  }

  public getDevices() {  
    this.subscriptions.push(this.objectService.getDevices({limit: 1000}).subscribe(data => {
      this.devices = data.model;
      console.log(this.devices);
      // this.nextDevicesToken = data.body.next
      //       ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
      //       : null;
    }))
  }

  private getActuators() {
    const url = this.sensorService.getUrl('sensors/');
    this.cacheService.delete(url+'group=actuator');
    this.sensorService
        .getActuators()
        .subscribe(sensors => {
          console.log(sensors);
            this.actuators = sensors.body.results;
            console.log(this.sensors);
        });
  }

  public createNewScheduler() {
    console.warn("Creating new scheduler doesn't exists yet");
  }

}
