import { Component, ViewChild } from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials/components/fap-modal/fap-modal.component';
import { FapModalButtonInterface } from '../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';

@Component({
    selector: 'fap-edit-treatments',
    templateUrl: './fap-edit-treatments.component.html',
    styleUrls: ['./fap-edit-treatments.component.scss']
})
export class FapEditTreatmentsComponent {

    @ViewChild('addTreatment', { static: true })
    public addTreatment: FapModalComponent;

    public addTeratmentButtonPrimaryInterface: FapModalButtonInterface;
    public addTreatmentButtonSecondaryInterface: FapModalButtonInterface;

    public testArray: Array<any> = [1, 2, 3, 4, 5];

    public treatments: Array<any> = [
    {
        id: 1,
        product: 'Adengo 456S',
        type:    'Potatoes',
        aplicability: 'Preemergent',
        min: 45,
        max: 54,
        surface: 23,
        dilution: 2
    },
    {
        id: 2,
        product: 'Adengo 456S',
        type:    'Potatoes',
        aplicability: 'Preemergent',
        min: 45,
        max: 54,
        surface: 23,
        dilution: 2
    },
    {
        id: 3,
        product: 'Adengo 456S',
        type:    'Potatoes',
        aplicability: 'Preemergent',
        min: 45,
        max: 54,
        surface: 23,
        dilution: 2
    }
    ];

    public products: Array<string> = [
    'Adengo 456S',
    'Adengo 456S',
    'Adengo 456S',
    'Adengo 456S'
    ];

    public types: Array<string> = [
    'Potatoes',
    'Potatoes',
    'Potatoes',
    'Potatoes'
    ];

    public aplicabilities: Array<string> = [
    'Preemergent',
    'Preemergent',
    'Preemergent',
    'Preemergent'
    ];

    constructor() {
        this.setupChangeClientAgentsModal();
    }

    public openModal(): void {
        this.addTreatment.showModal();
    }

    public deleteTreatment(treatmentId: number): void
    {

    this.treatments = this.treatments.filter((treatment: any) =>
        {
            return treatment.id !== treatmentId;
        });
    }

    private setupChangeClientAgentsModal(): void {
        this.addTeratmentButtonPrimaryInterface = {
            text: 'Salvare',
            clickFunction: (): void => {
                this.addTreatment.hideModal();
            }
        };
        this.addTreatmentButtonSecondaryInterface = {
            text: 'Anulare',
            clickFunction: (): void => {
                this.addTreatment.hideModal();
            }
        };
    }

}

