<div class="card">
    <div class="add-unit btn btn-success px-2" (click)="showAddUnitModal()">
        <i class="material-icons">add</i>
        <p>{{ 'units.addUnit' | translate }}</p>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'units.name' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="shortName">
            <th mat-header-cell *matHeaderCellDef> {{ 'units.shortName' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.shortName}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center"> {{ 'units.actions' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="text-center">
                <i class="fa fa-pencil f-20" (click)="showEditUnitModal(element)"></i>
                <i class="fa fa-trash ml-2 f-20" (click)="deleteUnitModal.showModal()"></i>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<fap-modal #addEditUnitModal
    inputTitle="{{ 'units.addEditUnit' | translate }}">
    <fap-add-edit-unit
        (cancel)="addEditUnitModal.hideModal"
        [selectedUnit]="selectedUnit"></fap-add-edit-unit>
</fap-modal>

<fap-modal #deleteUnitModal [inputPrimaryButtonInterface]="deleteUnitModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="deleteUnitModalButtonSecondaryInterface"
    inputTitle="{{ 'units.deleteUnit' | translate }}">
    {{ 'units.deleteUnitMessage' | translate}}
</fap-modal>
