import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { DeviceModel, ObjectService } from 'src/app/core/services/api/objects/object.service';
import { StockService } from 'src/app/core/services/api/stock/stock.service';
import { WidgetsService } from 'src/app/core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
  selector: 'devices-container',
  templateUrl: './devices-container.component.html',
  styleUrls: ['./devices-container.component.scss']
})
export class DevicesContainerComponent implements OnInit, OnDestroy {

  public devices: Array<DeviceModel>;
  public subscriptions: Array<Subscription> = [];
  public getMore = true;
  public nextToken: { limit: number; offset: number; } = null;
  public limit = 10;

  constructor(public objectService: ObjectService, public widgetsService: WidgetsService, public stockService: StockService, public cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, public mapService: MapService) { }

  ngOnInit(): void {
    // this.getDevices();
    this.widgetsService.setSize(8);
  }

  public getDevices() {
    const url = this.objectService.getUrl('devices/')
    this.cacheService.delete(url);
    this.subscriptions.push(this.objectService.getDevices().subscribe(data => {
      this.devices = data.model;
      console.log(this.devices);
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
    }))
  }

  public addNewDevice(device) {
    this.objectService.addDevice(device).subscribe((data)=> {
      console.log(data);
      const url = this.stockService.getUrl('');
      this.cacheService.delete(url + 'stock_resources/?group=DEVICES');
      this.getDevices();
    })
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrolledDown() {
    const url = this.objectService.getUrl('');
    if(this.getMore) {
    this.nextToken &&
      this.objectService.getDevices(this.nextToken).subscribe((data) => {
        this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
        console.log(data);
        if(this.devices) {
          this.devices = [...this.devices, ...data.body["results"]];
        } else {
          this.devices = data.body["results"];
        }
        if(data.body["next"] == null) {
          this.getMore = false;
          return
        } else {
          const url = data.body['next'].split('?')
          const urlParams = new URLSearchParams(url[1]);
          const entries = urlParams.entries();
          const params = this.paramsToObject(entries);
          if(this.nextToken.offset != params['offset']) {
          this.nextToken = {limit: params['limit'], offset: params['offset']};
          } else {
            return
          }
        }
      });
    } else {
      return
    }
  }

  ngOnDestroy(): void {
    this.mapService.mapMarkers = [];
    this.mapService.resetMap();
    this.widgetsService.setSize(6)
  }

}
