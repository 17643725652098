import { WidgetsEnum } from '../enums/widgets.enum';
import { WidgetInfo } from '../interfaces/widget/widget-info.interface';
import { WidgetInfoTypeInfo } from '../interfaces/widget/widget-info-type-info.interface';
import { WidgetDataTypesEnum } from '../enums/widget-data-types.enum';

export class WidgetHelper {
    private static defaultConfig: {} = {
        activities: {
            numberOfRows: 2
        },
        // alerts: {},
        weather: {},
        photosynthRadiation: {
            chartTitle: 'widget.photosynthRadiationChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.radLabel',
            chartGradient: true,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
        },
        pressure: {
            chartColorScheme: {
                domain: ['#23a863']
            },
            chartTitle: 'widget.atmosphericPressureChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.pressureLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        illuminance: {
            chartColorScheme: {
                domain: ['#ebe834']
            },
            chartTitle: 'widget.illuminanceChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.illuminanceLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        rainfall: {
            chartTitle: 'widget.rainfallChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.rainfallLabel',
            chartGradient: true,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
        },
        soilTemperature: {
            chartColorScheme: {
                domain: ['#e0451a']
            },
            chartTitle: 'widget.soilTemperatureChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.temperatureLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        rad: {
            chartColorScheme: {
                domain: ['#c0c0c0']
            },
            chartTitle: 'widget.radiationChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.umolLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        moist: {
            chartColorScheme: {
                domain: ['#1a69e0']
            },
            chartTitle: 'widget.moistureChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.moistureLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        dielectric: {
            chartColorScheme: {
                domain: ['#a88332']
            },
            chartTitle: 'widget.dielectricChart',
            chartXAxisLabel: 'widget.timeLabel',
            chartYAxisLabel: 'widget.dielectricLabel',
            chartGradient: true,
            chartRoundEdges: true,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        temperature: {
            chartColorScheme: {
                domain: ['#000000']
            },
            chartTitle: 'widget.temperatureChart',
            chartXAxisLabel: 'widget.dateLabel',
            chartYAxisLabel: 'widget.temperatureLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        humidity: {
            chartColorScheme: {
                domain: ['#000000']
            },
            chartTitle: 'widget.humidityChart',
            chartXAxisLabel: 'widget.dateLabel',
            chartYAxisLabel: 'widget.humidityLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
            numberOfRows: 2
        },
        soil: {
            chartColorScheme: {
                domain: ['#000000']
            },
            chartTitle: 'widget.soilChart',
            chartXAxisLabel: 'widget.dateLabel',
            chartYAxisLabel: 'widget.soilLabel',
            chartGradient: true,
            chartRoundEdges: false,
            chartShowLegend: false,
            chartShowXAxis: true,
            chartShowXAxisLabel: true,
            chartShowYAxis: true,
            chartShowYAxisLabel: true,
        },
        wind: {
            chartColorScheme: {
                domain: ['#143fef', '#1ea6ec', '#1a8436', '#0062cc', '#ff850d', '#ff2046']
            },
            chartTitle: 'widget.windDirection',
            pieChartData: [
                {
                    name: 'N',
                    value: 2
                },
                {
                    name: 'NNE',
                    value: 1
                },
                {
                    name: 'NE',
                    value: 1
                },
                {
                    name: 'NEE',
                    value: 1
                },
                {
                    name: 'E',
                    value: 2
                },
                {
                    name: 'SEE',
                    value: 1
                },
                {
                    name: 'SE',
                    value: 1
                },
                {
                    name: 'SSE',
                    value: 1
                },
                {
                    name: 'S',
                    value: 2
                },
                {
                    name: 'SSW',
                    value: 1
                },
                {
                    name: 'SW',
                    value: 1
                },
                {
                    name: 'SWW',
                    value: 1
                },
                {
                    name: 'W',
                    value: 1
                },
                {
                    name: 'NWW',
                    value: 1
                },
                {
                    name: 'NW',
                    value: 1
                },
                {
                    name: 'NNW',
                    value: 1
                }
            ],
            isDoughnut: false,
            arcWidth: 0.30,
            chartGradient: false,
            chartExplodeSlices: true,
            chartShowLabels: true
        },
        lots: {
            type: 'lots',
            title: 'widget.lotList',
            items: []
        },
        farms: {
            type: 'farms',
            title: 'widget.farmList',
            items: []
        },
        fields: {
            type: 'fields',
            title: 'widget.fieldList',
            items: []
        }
    };

    private static widgetByInfoTypes: {[key: string]: WidgetsEnum[] } = {
        weather: [
            WidgetsEnum.Weather,
            WidgetsEnum.Humidity,
            WidgetsEnum.Temperature,
            WidgetsEnum.Wind,
            WidgetsEnum.Rainfall,
            WidgetsEnum.Rad,
            WidgetsEnum.Illuminance,
            WidgetsEnum.Pressure,
            WidgetsEnum.PhotosynthRadiation
        ],
        soil: [
            WidgetsEnum.Soil,
            WidgetsEnum.Moist,
            WidgetsEnum.Dielectric,
            WidgetsEnum.SoilTemperature
        ],
        other: [
            WidgetsEnum.Fields,
            WidgetsEnum.Lots,
            WidgetsEnum.Farms,
            WidgetsEnum.Activities
        ]
    };

    private static widgetsByContentType: {[key: string]: WidgetsEnum[] } = {
        farm: [
            WidgetsEnum.Weather,
            WidgetsEnum.Humidity,
            WidgetsEnum.Temperature,
            WidgetsEnum.Wind,
            WidgetsEnum.Soil,
            WidgetsEnum.Lots,
            WidgetsEnum.Activities,
            WidgetsEnum.Moist,
            WidgetsEnum.Dielectric,
            WidgetsEnum.Rad,
            WidgetsEnum.SoilTemperature,
            WidgetsEnum.Rainfall,
            WidgetsEnum.Illuminance,
            WidgetsEnum.Pressure,
            WidgetsEnum.PhotosynthRadiation
        ],
        lot: [
            WidgetsEnum.Weather,
            WidgetsEnum.Humidity,
            WidgetsEnum.Temperature,
            WidgetsEnum.Wind,
            WidgetsEnum.Soil,
            WidgetsEnum.Fields,
            WidgetsEnum.Activities,
            WidgetsEnum.Moist,
            WidgetsEnum.Dielectric,
            WidgetsEnum.Rad,
            WidgetsEnum.SoilTemperature,
            WidgetsEnum.Rainfall,
            WidgetsEnum.Illuminance,
            WidgetsEnum.Pressure,
            WidgetsEnum.PhotosynthRadiation
        ],
        user: [
            WidgetsEnum.Humidity,
            WidgetsEnum.Temperature,
            WidgetsEnum.Wind,
            WidgetsEnum.Soil,
            WidgetsEnum.Farms,
            WidgetsEnum.Activities,
            WidgetsEnum.Moist,
            WidgetsEnum.Dielectric,
            WidgetsEnum.Rad,
            WidgetsEnum.SoilTemperature,
            WidgetsEnum.Illuminance,
            WidgetsEnum.Pressure,
            WidgetsEnum.PhotosynthRadiation
        ]
    };

    private static widgetInfo: {[key: string]: WidgetInfo} = {
        photosynthRadiation: {
            title: 'widget.photosynthRadiation',
            description: 'widget.widgetDescription.photosynthWidget',
            icon: 'timeline',
            previewImg: ''
        },
        pressure: {
            title: 'widget.atmosphericPressure',
            description: 'widget.widgetDescription.pressurehWidget',
            icon: 'timeline',
            previewImg: ''
        },
        illuminance: {
            title: 'widget.illuminance',
            description: 'widget.widgetDescription.illuminanceWidget',
            icon: 'timeline',
            previewImg: ''
        },
        rainfall: {
            title: 'widget.rainfall',
            description: 'widget.widgetDescription.rainfallWidget',
            icon: 'timeline',
            previewImg: ''
        },
        soilTemperature: {
            title: 'widget.soilTemperature',
            description: 'widget.widgetDescription.soilTemperatureWidget',
            icon: 'timeline',
            previewImg: ''
        },
        rad: {
            title: 'widget.radiation',
            description: 'widget.widgetDescription.radWidget',
            icon: 'timeline',
            previewImg: ''
        },
        dielectric: {
            title: 'widget.dielectric',
            description: 'widget.widgetDescription.dielectricWidget',
            icon: 'timeline',
            previewImg: ''
        },
        moist: {
            title: 'widget.moist',
            description: 'widget.widgetDescription.moistWidget',
            icon: 'timeline',
            previewImg: ''
        },
        temperature: {
            title: 'widget.temperature',
            description: 'widget.widgetDescription.temperatureWidget',
            icon: 'timeline',
            previewImg: ''
        },
        humidity: {
            title: 'widget.humidity',
            description: 'widget.widgetDescription.humidityWidget',
            icon: 'timeline',
            previewImg: ''
        },
        weather: {
            title: 'widget.weather',
            description: 'widget.widgetDescription.weatherWidget',
            icon: 'cloud',
            previewImg: ''
        },
        soil: {
            title: 'widget.soil',
            description: 'widget.widgetDescription.soilWidget',
            icon: 'grass',
            previewImg: ''
        },
        farms: {
            title: 'widget.farms',
            description: 'widget.widgetDescription.farmsWidget',
            icon: 'home',
            previewImg: ''
        },
        lots: {
            title: 'widget.lots',
            description: 'widget.widgetDescription.lotsWidget',
            icon: 'square',
            previewImg: ''
        },
        fields: {
            title: 'widget.fields',
            description: 'widget.widgetDescription.fieldsWidget',
            icon: 'home',
            previewImg: ''
        },
        wind: {
            title: 'widget.wind',
            description: 'widget.widgetDescription.windWidget',
            icon: 'chart',
            previewImg: ''
        },
        activities: {
            title: 'widget.activities',
            description: 'widget.widgetDescription.activitiesWidget',
            icon: 'list',
            previewImg: ''
        }
    };

    private static widgetInfoTypeInfo: {[key: string]: WidgetInfoTypeInfo} = {
        weather: {
            title: 'widget.weather',
            description: 'widget.widgetesCategories.weatherWidget',
            icon: 'cloud',
        },
        soil: {
            title: 'widget.soil',
            description: 'widget.widgetesCategories.soilWidget',
            icon: 'grass',
        },
        other: {
            title: 'widget.other',
            description: 'widget.widgetesCategories.otherWidget',
            icon: 'apps',
        }
    };

    private static widgetDataTypes: { [key: string]: string } = {
        [WidgetsEnum.Weather]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Humidity]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Temperature]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Wind]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Soil]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Fields]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Lots]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Farms]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Activities]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Moist]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Dielectric]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Rad]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.SoilTemperature]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Rainfall]: WidgetDataTypesEnum.API,
        [WidgetsEnum.Illuminance]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.Pressure]: WidgetDataTypesEnum.Sensor,
        [WidgetsEnum.PhotosynthRadiation]: WidgetDataTypesEnum.Sensor
    };

    public static getWidgetsByInfoType(widgetInfoType: string, contentType: string): WidgetsEnum[] {
        return WidgetHelper.widgetByInfoTypes[widgetInfoType]
            .filter((widget: WidgetsEnum): boolean => WidgetHelper.widgetsByContentType[contentType].includes(widget));
    }

    public static getDefaultConfig(widget: string): any {
        return JSON.parse(JSON.stringify(WidgetHelper.defaultConfig[widget])); // create a copy
    }

    public static getWidgetInfo(): {[key: string]: WidgetInfo } {
        return JSON.parse(JSON.stringify(WidgetHelper.widgetInfo)); // create a copy
    }

    public static getWidgetInfoTypeInfo(): {[key: string]: WidgetInfoTypeInfo } {
        return JSON.parse(JSON.stringify(WidgetHelper.widgetInfoTypeInfo)); // create a copy
    }

    public static getWidgets(): string[] {
        return Object.keys(WidgetHelper.widgetInfo);
    }

    public static getWidgetInfoTypes(): string[] {
        return Object.keys(WidgetHelper.widgetByInfoTypes);
    }

    public static getWidgetDataType(widget: string): string {
        return WidgetHelper.widgetDataTypes[widget];
    }
}
