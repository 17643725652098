<div *ngIf="widget" class="dashboard-card dash_cont" style="overflow: hidden;">
    <div class="d-flex justify-content-between" style="position: relative; max-height: 100%; z-index: 3;">
      <div class="col-md-4 col-xs-4 pl-0">
        <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
      </div>
      <div class="col-md-6 col-xs-6 col_lot_name">
        <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
      </div>
    </div>
    <div class="sat_wid">
        <img id="myImgId" *ngIf="imageUrl" [src]="apiUrl+imageUrl" alt="water" (click)="getCoords($event)">
        <img id="myImgId" (click)="getCoords($event)" *ngIf="imageUrl==null" src="../../../../assets/images/lux56List.png" alt="satellite" class="placeholder_img">
        <span class="placeholder" *ngIf="imageUrl==null && selectedRecord==null">{{"widget.newAcquisition" | translate}}</span>
    <span class="placeholder" *ngIf="imageUrl==null && selectedRecord!=null">{{"widget.analysisOngoing" | translate}}</span>
        <div id="sball" style="border-radius: 50%; width: 34px; height: 34px; background: #000; border: 3px solid #fff; display: none;
        z-index: 1;"></div>
        <select id="date" *ngIf="dates.length > 0" (change)="changeDropdown($event.target.value)"
        [value]="selectedDate" class="custom-select ce_simple bs-select border-green-select text-center m-0">
            <option *ngFor="let date of dates" value="{{date.id}}">{{date.date | date: "dd MMM yyyy HH:mm"}}</option>
        </select>
        <div class="status" *ngIf="infoText">
            <img *ngIf="status" src="../../../../assets/images/emoji{{status}}.png" alt="emoji">
            <span *ngIf="infoText">{{infoText}}</span>
        </div>
        <div class="add_circle_a ml-auto" *ngIf="logic == true">
            <div class="rounded-circle bg-green-c text-white" (click)="getAcquisition()"></div>
          </div>
    </div>
  </div>
  <fap-modal
    [inputIsLarge]="true"
    #confirmPopup
    [titleColor]="'#ffffff'"
    inputTitle="Request Acquisition"
>
    <div class="mod_body">
        <h2>{{"widget.takePicture" | translate}}</h2>
        <div class="btns d-flex justify-content-end">
            <button class="btn mr-1" (click)="createRequest()">{{"yes" | translate}}</button>
            <button class="btn" (click)="closeModal(widget)">{{"no" | translate}}</button>
        </div>
    </div>
</fap-modal>