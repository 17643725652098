import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FapConfirmModalComponent } from '../../partials/components/fap-confirm-modal/fap-confirm-modal.component';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConfirmModalService {

    public dialogRef: MatDialogRef<FapConfirmModalComponent>;

    constructor(public dialog: MatDialog) {
    }

    public openConfirmModal(title: string, message: string): void{
        this.dialogRef = this.dialog.open(FapConfirmModalComponent, {
            width: '800px',
            height: 'auto',
            data: {title, message}
          });

    }

    public onModalClose(): Observable<any> {
        return this.dialogRef.afterClosed().pipe(take(1));
    }
}

