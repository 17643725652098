import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FapRestBaseService } from './../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { IngredientInterface } from './../../../interfaces/ingredient/ingredient.interface';
import { FapAPIRequestOptions } from './../fap-api-request.options';
import { IngredientModel } from '../../../models/stock/ingredient.model';

@Injectable()
export class IngredientService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/stock/ingredients/');
    }

    public getIngredients(params?: {}): Observable<ResponseModel<IngredientModel[]>> {
        return this.mapRequest<IngredientModel[]>(
            this._http.get(this.url ,                 {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            IngredientModel,
            true
        ).pipe(take(1));
    }

    public getIngredient(ingredientId: number): Observable<ResponseModel<IngredientModel>> {
        return this.mapRequest<IngredientModel>(
            this._http.get(this.url + ingredientId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            IngredientModel,
            false
        ).pipe(take(1));
    }

    public createIngredient(field: IngredientInterface): Observable<ResponseModel<IngredientModel>> {
        return this.mapRequest<IngredientModel>(
            this._http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            IngredientModel,
            false
        ).pipe(take(1));
    }

    public updateIngredient(ingredientId: number, field: IngredientInterface): Observable<ResponseModel<IngredientModel>> {
        return this.mapRequest<IngredientModel>(
            this._http.patch(this.url + ingredientId + '/',
                this.camelCaseModelToSnakeCaseJson(field),
                FapAPIRequestOptions.withTokenRequestOptions),
            IngredientModel,
            false
        ).pipe(take(1));
    }

    public deleteIngredient(ingredientId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.delete(this.url + ingredientId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }
}
