import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../../../core/services/api/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit, OnDestroy {public deleteForm: UntypedFormGroup;
  public data$: Observable<Data>;
  public gdprForm: UntypedFormGroup;
  private unsubscribe: Subject<any>;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  disableScrollDown = false;
  public pageEnd = false;
  public defaultLang = null;
  public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';
  public showConfirmModal = false;
  public selectedOption = 0;
  public hideForm = false;
  public options = [
    {id: 1, text: "I already have another account"},
    {id: 2, text: "I don't want this service"},
    {id: 3, text: "I don't like the application"},
    {id: 100, text: "Other reason"}
  ];
  public confirmationKey = null;
  
  constructor(
      private formBuilder: UntypedFormBuilder,
      private toastyService: ToastrService,
      public translate: TranslateService,
      private authService: AuthService,
      public router: Router,
      private cdr: ChangeDetectorRef,
      private route: ActivatedRoute) {
      console.log(localStorage.getItem('language'));
      this.unsubscribe = new Subject();
  }

  public ngOnInit(): void {
      this.initDeleteForm();
      this.data$ = this.route.data.pipe(map((data: Data) => {
          return {
              mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
          };
      }));
      this.route.params.pipe(
        tap((params: Params) => {
            this.confirmationKey = params.confirmationKey;
            if(this.confirmationKey) {
              this.showConfirmModal = true
            }
        }),
        takeUntil(this.unsubscribe)
    ).subscribe();
  }

  public ngOnDestroy(): void {
       this.unsubscribe.next(null);
      this.unsubscribe.complete();
  }


  public confirmDelete(): void {
    console.log(this.deleteForm.value);
    this.authService.removeUser(this.deleteForm.value).subscribe(() => {
      this.hideForm = true;
      this.showModal();
    },
    err => {
      this.toastyService.error(err.error.results.error);
    })
  }

  public deleteUser() {
    this.authService.confirmRemoveUser({confirmation_key: this.confirmationKey}).subscribe(() => {
      this.toastyService.success(this.translate.instant('user.accountDeleted'));
      this.showConfirmModal = false;
    },
    err => {
      this.toastyService.error(err.error.results.error);
    })
  }

  public cancel() {
    this.showConfirmModal = false;
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
      const control: any = this.deleteForm.controls[controlName];
      if (!control) {
          return false;
      }

      const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  showModal() {
    if(this.deleteForm.invalid) {
        Object.keys(this.deleteForm.controls).forEach((controlName: string) =>
            this.deleteForm.controls[controlName].markAsTouched()
        );
        return;
    } else {
        const elem = document.getElementById('custom_modal');
        const parent = document.getElementById('parent');
        elem.classList.add('active');
        parent.classList.add('backdrop');
    }
}

hideModal() {
    this.deleteForm.reset();
    const elem = document.getElementById('custom_modal');
    const parent = document.getElementById('parent');
    elem.classList.remove('active');
    parent.classList.remove('backdrop');
}

  valueChange(val) {
    console.log(val.value);
    this.selectedOption = val.value.id;
    this.deleteForm.get('reason').patchValue(val.value);
    this.deleteForm.get('info').patchValue('')
  }

  private initDeleteForm(): void {
      this.deleteForm = this.formBuilder.group({
          email: ['', Validators.compose([
                  Validators.required,
                  Validators.email,
                  Validators.minLength(3),
                  Validators.maxLength(320)
              ]),
          ],
          reason: [''],
          info: ['']
      });
  }
}
