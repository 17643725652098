import { Routes } from '@angular/router';

import { UnitsContainerComponent } from './containers/units-container/units-container.component';

export const unitsRoutes: Routes = [
    {
        path:       '',
        component:  UnitsContainerComponent
    }
];
