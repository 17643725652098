import { Component } from '@angular/core';


@Component({
    templateUrl: './equipments.component.html',
    styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent {

}
