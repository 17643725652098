import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'utcToLocal',
})
export class UtcToLocalPipe implements PipeTransform {
    private timezoneDataUrl = 'assets/timezone/timezone.json';

  constructor(private http: HttpClient) {}

  private loadTimezoneData(): Observable<any> {
    return this.http.get(this.timezoneDataUrl).pipe(
      catchError((error) => {
        console.error('Failed to load timezone data', error);
        return of([]); 
      })
    );
  }

  private getTimezoneOffset(timezone: string, timestamp: number): number {
    const date = new Date(timestamp);
    const options = { timeZone: timezone, timeZoneName: 'short' as const };
  
    const formatter = new Intl.DateTimeFormat('en', options);
    const parts = formatter.formatToParts(date);
  
    // Find the timezone offset part
    const offsetPart = parts.find(part => part.type === 'timeZoneName');
    const offsetSign = offsetPart && offsetPart.value.startsWith('-') ? -1 : 1;
  
    const offsetMinutes = date.getTimezoneOffset();
    const offset = (offsetMinutes * offsetSign) / 60; // Convert offset from minutes to hours

    return offset;
  }  

  transform(timestamp: string | number, userTimezone: string): Observable<string> {
    let utcDate;
    if (typeof timestamp === 'string') {
      utcDate = new Date(timestamp);
    } else if (typeof timestamp === 'number') {
      utcDate = new Date(timestamp * 1000);
    } else {
      throw new Error('Invalid timestamp format');
    }

    return this.loadTimezoneData().pipe(
      switchMap(() => {
        const offset = this.getTimezoneOffset(userTimezone, utcDate.getTime());
        const localDate = new Date(utcDate.getTime() + offset * 60000);
        return of(localDate.toISOString());
      }),
      catchError(() => {
        return of(utcDate.toISOString());
      })
    );
  }
}
