import { BaseTypeModel } from './../base/base-type.model';
import { IngredientModel } from './../stock/ingredient.model';

export class CropTypeModel extends BaseTypeModel {

    public static fields: Array<string> = BaseTypeModel.fields.concat([
        'company',
        'ingredients'
    ]);

    public static extendedFields: {} = {
        ingredients: IngredientModel
    };

    public company: number;
    public ingredients: IngredientModel[] = [];
}
