<fap-header>
    <fap-header-actions [title]="'leftMenu.fields' | translate" [isDeleteEnabled]="fieldId ? true : false"></fap-header-actions>
</fap-header>
<div class="container-fluid nc">
    <div class="row m-0">
        <div class="col-md-12 p-0">
            <fap-section-container
            [title]="(isEditMode ? 'farm.field.editField' : 'farm.field.addField') | translate"
            >
            <fap-add-edit-field class="w-100"
                [fieldId]="fieldId"
                [fieldName]="fieldName"
                [area]="area"
                [soilType]="soilType"
                [isSubmitDisabled]="fieldPolygon?.points?.length<3"
                [owner]="owner"
                (submitField)="submitField($event)"
                (deleteParty)="deleteParty($event)">
            </fap-add-edit-field>
            </fap-section-container>
            <fap-attributes
                [entityRelation]="'field'"
                [entityId]="entityInfo?.id"
                [entityInfo]="entityInfo"
                [objectId]="fieldId"
                (formTypeEmit)="formType = $event"
                (formFieldsEmit)="formFields = $event"
            >
    </fap-attributes>
        </div>
    </div>
</div>
