import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { FapActivityRowComponent } from './components/fap_activity-row/fap_activity-row.component';
import { ActivityComponent } from './activity.component';
import { FapActivityGroupsLayoutComponent } from './components/fap_activity-groups-layout.component/fap_activity-groups-layout.component';
import { FapActivityGroupRowComponent } from './components/fap_activity-group-row/fap_activity-group-row.component';
import { ActivityGroupsContainerComponent } from './containers/activity-groups-container/activity-groups-container.component';
import { ActivityGroupAddEditContainerComponent } from './containers/activity-group-add-edit-container/activity-group-add-edit-container.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FapActivityCardComponent } from './components/fap_activity-card/fap_activity-card.component';
import { PartialsModule } from '../../views-partials/partials.module';


const COMPONENTS: any[] = [
    ActivityComponent,
    FapActivityGroupsLayoutComponent,
    FapActivityGroupRowComponent,
    FapActivityRowComponent,
    FapActivityCardComponent
];

const CONTAINERS: any[] = [
    ActivityGroupsContainerComponent,
    ActivityGroupAddEditContainerComponent
];


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        ...CONTAINERS,
        ...COMPONENTS
    ]
})
export class ActivityModule {

}
