import { Component, Input } from '@angular/core';

@Component({
  selector: 'fap-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent  {

    @Input()
    public color: string;

    @Input()
    public progress: number;

}
