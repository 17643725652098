<fap-header>
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_btn mr-2">
            <fap-round-button [backgroundColor]="'tertiary'" (clicked)="showModal()"></fap-round-button>
        </div>
        <div class="add_circle mr-3" (click)="showConfigModal()">
            <div class="rounded-circle r config"></div>
        </div>
        <fap-search-filter
            [isSearchEnabled]="true"
>       </fap-search-filter>
    </div>
</fap-header>
<div class="container-fluid devices_page" id="parent">
    <div class="loader_container">
        <span *ngIf="isLoading" class="loader">
            <i></i>
          </span>
    </div>
    <div class="devices_table" *ngIf="!isLoading" #deviceContainer
    infiniteScroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="500"
    (scrolled)="scrolledDownDeviceData()"
    [scrollWindow]="false"
    (scroll)="onDivScroll($event)"
    style="flex: auto;"> 
        <table *ngIf="deviceColumns && deviceColumns.length">
            <thead>
                <tr>
                    <th *ngFor="let head of tableHeaderLabels" [innerHTML]="head.name"></th>
                    <th *ngFor="let head of deviceColumns" resizable (widthChange)="onResize($event, i)" [ngStyle]="{'width.px': columnWidths[i]}">{{ head?.name ? head?.name : '' }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of deviceData">
                    <td>
                        <div class="toggle_children" *ngIf="data.children != 0">child</div>
                        <div class="boxx">
                            <div class="img">
                                <ng-container *ngFor="let type of entityTypes">
                                    <ng-container *ngIf="type?.id === data?.type">
                                        <img *ngIf="type?.icon" [src]="mediaUrl + type?.icon?.file" alt="">
                                        <img *ngIf="!type?.icon" src="../../../../../../assets/images/image_placeholder.png" alt="">
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!data.type">
                                    <img src="../../../../../../assets/images/image_placeholder.png" alt="">
                                </ng-container>
                            </div>
                            <div class="text">
                                <ng-container *ngFor="let type of entityTypes">
                                    <ng-container *ngIf="type?.id === data?.type">
                                        <b>{{type?.name}}</b>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="!data?.type">
                                    <b><i style="padding-right: 5px;">No type found</i></b>
                                </ng-container>
                            </div>
                        </div>
                    </td>
                    <td *ngFor="let num of numberArray">
                        <ng-container *ngFor="let field of data?.fields; let i = index;">
                            <ng-container *ngIf="field && num == field?.column">
                                <div [innerHTML]="getExtraFieldsById(field, deviceColumns[num]?.field_type?.unit)" (click)="handleButtonClick(field)">
                                </div>
                            </ng-container>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<fap-modal
    [inputIsLarge]="true"
    #deviceModal
    [titleColor]="'#ffffff'"
    [inputTitle]="'devices.addNewDevice' | translate"
    [showFooter]="false"
>
    <div>
        <form [formGroup]="deviceForm">
            <div class="form-group m-0">
                <div class="row align-items-baseline">
                    <div class="w-100">
                        <div class="input-group w-100">
                            <div class="col-6" (click)="getResourceEntries()">
                                <mat-form-field>
                                    <mat-label>{{"stock.entry" | translate}}</mat-label>
                                    <mat-select formControlName="resource_entry" name="resource_entry" required (selectionChange)="setEntry($event)" (infiniteScroll)="scrolledDown()" msInfiniteScroll>
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #typeFilter4 placeholder="Search">
                                        </mat-form-field>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScroll($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option class="noteIcons" *ngFor="let entry of resourceEntries | filter : typeFilter4.value" [value]="entry.id">
                                            <span class="mat-option-text">
                                                <div class="name clearfix">
                                                    <div class="nme">
                                                          {{ entry?.product?.label?.name }}
                                                    </div> 
                                                    <div class="amt">
                                                        <div class="quty">
                                                            {{ entry?.remains_quantity | number: '1.0-2'}}
                                                        </div> 
                                                        <div class="unt">
                                                            <div class="inlin">
                                                               <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                    <mat-error *ngIf="deviceForm.controls['resource_entry'].errors?.required">
                                        <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                    </mat-error>
                                </mat-form-field>
                        </div>
                        <div class="col-6 d-flex nn posrltv custom_border">
                            <div class="colq">
                                <div class="input-group w-100">
                                    <mat-form-field>
                                        <mat-label>{{'stock.ingredients.ingredientQuantity' | translate}}</mat-label>
                                        <input type="number" style="text-align: right;" matInput formControlName="quantity" [max]="max">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="colu" style="padding: 0;
                            
                            width: 90px;
                            
                            " *ngIf="entry && entry.unit">
                                <div class="inlin">
                                    <b>{{entry?.product | getProductUnit: globalRegistry?.systemData?.unitsMap}}</b>
                                </div>
        
                            </div>
                            <div class="abs error" *ngIf="entry && !entry.unit">
                                <span>{{'stock.specifyProductUnit' | translate}}</span>
                            </div>
                        </div>
                            
                            </div>
                            <div class="col-12">
                                <chips 
                                    [items]="globalRegistry.systemData.lots" 
                                    [selectedItems]="selectedItems"
                                    [label]="'activities.lots' | translate"
                                    (updatedItems)="updateItems($event)"
                                    [addEnabled]="false"
                                >
                                </chips>
                            </div>
                            <div class="col mt-2 d-flex justify-content-end pb-4">
                                <button class="btn mr-2" (click)="addDevice()">{{ "translations.save" | translate }}</button>
                                <button class="btn" (click)="deviceModal.hideModal()">{{ "translations.cancel" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
        </form>
    </div>
</fap-modal>

<fap-modal
    [inputIsLarge]="true"
    [showFooter]="false"
    #configModal
    [titleColor]="'#ffffff'"
    inputTitle="Config"
    (outputOnHide)="reroute()"
>
    <div class="config_block">
        <form [formGroup]="sensorForm" class="row">
            <div class="col-md-12">
                <fap-add-edit-delete-select 
                    [inputName]="'sensor.sensorGroup' | translate"
                    class="w-100"
                    [formControl]="sensorForm.controls['sensorGroup']"
                    name="group"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'nameT'"
                    [inputSelectValues]="sensorGroupTypes"
                    [inputConfirmDeleteText]="'sensor.deleteSensorGroupMessage' | translate"
                    (selectionChange)="changeGroup($event)"
                    (outputOnCreate)="showSensorGroupTypeModal(); closeCombo = false;"
                    (outputOnEdit)="sensorGroupTypeEdit($event); closeCombo = false;"
                    (outputOnDelete)="sensorGroupTypeDelete($event);"
                    [closeCombo]="closeCombo"
                    >
                </fap-add-edit-delete-select>
            </div>
            <!-- <mat-form-field class="col-md-12">
                <mat-label>{{ 'sensor.sensorGroup' | translate }}</mat-label>
                <mat-select formControlName="sensorGroup" #mys name="sensorGroup" required (selectionChange)="changeGroup($event.value)">
                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                    <mat-form-field appearance="fill" class="w-100">
                        <input matInput #typeFilter placeholder="Search">
                    </mat-form-field>
                    <div class="input-group-append pl-1" (click)="showSensorGroupTypeModal(true)">
                        <span class="input-group-text"><i
                                class="icofont icofont icofont-plus"></i></span>
                    </div>
                </div>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option class="noteIcons" *ngFor="let type of sensorGroupTypes | filter : typeFilter.value" [value]="type.id">
                        <div class="name">
                            <span *ngIf="type?.nameT">
                                <span *ngIf="getTranslation(type?.nameT) === '' || getTranslation(type?.nameT) == null || getTranslation(type?.nameT) == undefined ">{{type?.name}}</span>
                                <span *ngIf="getTranslation(type?.nameT) !== ''">{{getTranslation(type?.nameT)}}</span>
                                </span>
                            <span *ngIf="!type?.nameT">{{type?.name}}</span>                                                    
                        </div>
                        <div class="actions">
                            <i class="fa fa-edit" (click)="sensorGroupTypeEdit($event, type)"></i>
                            <i class="fa fa-trash" (click)="sensorGroupTypeDelete($event, type)"></i>
                        </div>
                    </mat-option>
                </div>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field class="col-md-5 col-sm-4">
                <mat-label>{{ "sensor.selectColumns" | translate }}</mat-label>
                <mat-select #matSelect required formControlName="sensorIds" (selectionChange)="onOptionSelected($event)">
                  <mat-select-trigger>
                    {{ 'Select items' }}
                  </mat-select-trigger>
                  <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100">
                    <input matInput #farmFilter placeholder="Search" />
                  </mat-form-field>
                  <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <div class="slct">
                      <mat-option
                        *ngFor="let column of allDeviceColumns | filter : farmFilter.value"
                        [value]="column?.field_type?.id"
                        [ngClass]="{'highlighted': column.highlighted}"
                        style="text-transform: capitalize"
                        (click)="$event.preventDefault();"
                      >
                      <span class="middle_text d-flex no_bg align-items-center">
                            <span class="small_text">{{ column?.field_type?.id }}.</span>
                            <div class="device_name">
                                <span>{{ column?.name }}</span>
                                <span class="small_text" style="display: block;">
                                    {{ column?.field_type?.unit_type?.unit }}
                                </span>
                            </div>
                        </span>
                      </mat-option>
                    </div>
                  </div>
                </mat-select>
              </mat-form-field>
          
            <div class="btns-group">
                <button class="btn" (click)="selectAllItems()"><img src="../../../../../../assets/images/select_all.png" alt=""></button>
                <button class="btn" (click)="removeAllItems()"><img src="../../../../../../assets/images/remove_all.png" alt=""></button>
            </div>
          </form>                   

        <div class="form-group">
            <mat-list (dragover)="allowDrop($event)" (drop)="onDrop($event)">
                <mat-list-item
                    *ngFor="let column of deviceColumns; let idx = index"
                    style="text-transform: capitalize"
                    draggable="true"
                    (dragstart)="onDragStart($event, idx)"
                >
                <span class="small_text">{{ column?.field_type?.id }}.</span>
                <span class="middle_text d-flex align-items-center">
                    {{column?.name}}
                  <span class="small_text pt-0" style="display: block; padding-left: 5px;">{{ column?.field_type?.unit_type?.unit }}</span>
                    </span>
                    <span class="mr-1 ml-auto" style="text-align: right; display: flex">
                    <span class="mr-1 ml-auto btn config_btn" *ngIf="selectedWidgetType === 'sensor_value' || selectedWidgetType === 'image' || selectedWidgetType === 'images_list'" (click)="editCss(column)">{{ 'widget.config' | translate }}</span>
                        <em class="material-icons pt-1 theme-color" (click)="removeItem(column?.field_type?.id)">delete</em>
                    </span>
                </mat-list-item>
            </mat-list>
        </div>

        <div class="btn-group d-flex justify-content-end">
            <button class="btn mr-2" (click)="addColumns()">{{ 'translations.save' | translate }}</button>
            <button class="btn" (click)="configModal.hideModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</fap-modal>

<fap-modal #addEditSensorGroupTypeModal
    [inputPrimaryButtonInterface]="addEditSensorGroupTypeModalButtonPrimaryInterface"
    [inputSecondaryButtonInterface]="addEditSensorGroupTypeModalButtonSecondaryInterface"
    [inputTitle]="'sensor.addEditSensorGroupType' | translate">
    <form [formGroup]="sensorGroupTypeForm" class="col-12 p-0">
        <div class="form-group">
            <mat-form-field class="w-100" (click)="addEditTranslation('notes.type_name')">
                <mat-label class="col-form-label">{{ 'notes.type_name' | translate }}</mat-label>
                <input matInput formControlName="name" readonly #nameInput>
                <mat-error *ngIf="sensorGroupTypeForm.controls['name'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group">
            <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'notes.color' | translate }}</mat-label>
                <input class="w-100" matInput type="color" formControlName="color">
                <mat-error *ngIf="sensorGroupTypeForm.controls['color'].errors?.required">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</fap-modal>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.sensorGroupType)" 
        [prefix]="'SENSOR_GROUP_'" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>