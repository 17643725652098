<nav aria-label="Page navigation" class="custom_pagination d-flex justify-content-between">
    <ul class="pagination">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="changePage(currentPage - 1)" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" *ngFor="let page of pages" [class.active]="page === currentPage">
        <a class="page-link" (click)="changePage(page)">{{ page }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="changePage(currentPage + 1)" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
    <div class="page-search d-flex align-items-center">
      <label for="page">Go to: </label>
      <input
        name="page"
        type="number"
        [(ngModel)]="pageNumberInput"
        (change)="onPageNumberInputChange($event)"
        placeholder="Page"
        min="1"
        [max]="totalPages"
      />
    </div>
  </nav>
  