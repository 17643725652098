import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { TokenModel } from '../../../models/auth/token.model';
import { environment } from '../../../../../environments/environment';


@Injectable()
export class OverviewService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/sensor/');
    }

    public overviewContent(token, body): Observable<ResponseModel<TokenModel>> {
        // const token = environment.overviewToken;
         const header = new HttpHeaders().set(
            "Authorization",
            token
          );
        return this.mapRequest(
            this._http.post(this.url + 'overview/',
                this.camelCaseModelToSnakeCaseJson(body),
                {headers:header})
        );
    }
}