import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { ResponseModel } from './../../../models/response.model';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { PartyModel } from '../../../models/party/party.model';
import { PartyInterface } from './data/party.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { map, take } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js'

@Injectable()
export class PartyService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/company/party/');
    }

    getUrl(slug) {
      return this.url+slug
    }

    public getParties(params?:{}): Observable<ResponseModel<PartyModel[]>> {
        return this.mapRequest<PartyModel[]>(
            this._http.get(this.url,
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                })
                .pipe(map((x) => {
                    return this.decryptResponse(x['raw'])
                })), false).pipe(take(1))
    }

    decryptResponse(r) {
        const token = localStorage.getItem('auth_token').substring(0, 32);
        const _key = CryptoJS.enc.Utf8.parse(token);
        const decrypted = CryptoJS.AES.decrypt(
          r, _key, {
            keySize: 32,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
          }).toString(CryptoJS.enc.Utf8);

          const data = JSON.parse(decrypted)

          const toCamel = (s) => {
            return s.replace(/([-_][a-z])/ig, ($1) => {
              return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
            });
          };
          
          const isArray = function (a) {
            return Array.isArray(a);
          };
          
          const isObject = function (o) {
            return o === Object(o) && !isArray(o) && typeof o !== 'function';
          };
          
          const keysToCamel = function (o) {
            if (isObject(o)) {
              const n = {};
          
              Object.keys(o)
                .forEach((k) => {
                  n[toCamel(k)] = keysToCamel(o[k]);
                });
          
              return n;
            } else if (isArray(o)) {
              return o.map((i) => {
                return keysToCamel(i);
              });
            }
          
            return o;
          };
          return keysToCamel(data);
        }

        public getParty(partyId: number): Observable<ResponseModel<PartyModel>> {
            return this.mapRequest(
                this._http.get(this.url  + partyId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
                PartyModel,
                false
            );
        }

    public updateParty(party: PartyInterface): Observable<ResponseModel<PartyModel>> {
        return this.mapRequest<PartyModel>(
            this._http.put(this.url + party.id + '/',
                this.camelCaseModelToSnakeCaseJson(party),
                FapAPIRequestOptions.withTokenRequestOptions),
                PartyModel
        );
    }

    public createParty(party: PartyInterface): Observable<ResponseModel<PartyModel>> {
        return this.mapRequest<PartyModel>(
            this._http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(party),
                FapAPIRequestOptions.withTokenRequestOptions),
                PartyModel
        );
    }

    public deleteParty(partyId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + partyId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        );
    }
}
