import { FapBaseModel } from '../../base/fap-base-model';
import { BillResourceEntryModel } from './bill_resource_entry.model';
import { DocumentModel } from './document.model';

export class DocumentItemModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'resource_entry',
        'document',
        'quantity',
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public resourceEntry: BillResourceEntryModel;
    public document: DocumentModel;
    public quantity: number;
}
