import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlergotelService } from '../../../../../core/services/api/alergotel/alergotel.service';
import { environment } from '../../../../../../environments/environment';
import { AlergotelRole } from '../../alergotel-role';
import { Subscription, combineLatest } from 'rxjs';
import { NavService } from '../../../../../shared/services/nav.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

@Component({
  selector: 'report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.scss']
})
export class AlergotelReportDetailComponent implements OnInit, OnDestroy {

  AlergotelRole = AlergotelRole;

  public reportId = -1;
  public report;
  public isLoading = false;
  public options = ['4mp', '5mp', '10mp'];
  public selectedValue;
  public description;
  public mediaUrl = environment.mediaUrl;
  public statusCodes = [
    { "key": 0, "value": "REPORTED" },
    { "key": 1, "value": "VALIDATE_REPORT" },
    { "key": 2, "value": "AUTHORITY_CHECK" },
    { "key": 3, "value": "NOTIFY_OWNER" },
    { "key": 4, "value": "OWNER_FIX" },
    { "key": 5, "value": "CHECK_AFTER" },
    { "key": 6, "value": "CLOSED" }
  ];
  public isBaseApplication = true;
  public subscriptions:Subscription[] = [];
  public coords;
  public lot;
  public lotImage = null;
  public lotId: number;
  showSelect = false;
  @ViewChild('select') select: MatSelect;
  shouldShowHeader = false;

  constructor(private activatedRoute: ActivatedRoute, public alergotelService: AlergotelService, public router: Router, public navService: NavService, public mapService: MapService, public toastr: ToastrService, public translate: TranslateService, public globalRegistry: GlobalRegistryService) { 
  //   this.activatedRoute.params.subscribe((params: Params): void => {
  //     this.reportId = params['reportId'];
  //     this.getReportDetail();
  // });
  this.subscriptions.push(
    combineLatest([
      activatedRoute.params,
    ]).subscribe(([params]: [Params]) => {
      // this.navService.viewMod.next(true);
      if (params["reportId"] !== undefined) {
        this.reportId = Number(params["reportId"]);
        this.navService.createMod.next(false);
        this.navService.editMod.next(true);
        this.getReportDetail();
      }
    })
  );
  }

  ngOnInit(): void {
    const url = this.router.url;
      const containsPages = url.includes('pages');
      if (containsPages) {
        this.shouldShowHeader = true;
      }
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams['lot']) {
          this.lotId = parseInt(queryParams['lot'], 10);
      }
  })
    this.navService.editFarm.next(true);
    const currentUrl = this.router.url;
    if (currentUrl.includes('pages')) {
      this.isBaseApplication = true;
    } else {
      this.isBaseApplication = false;
    }

    this.subscriptions.push(this.navService.emitCancel.subscribe((value: boolean) => {
      if (value) {
        if(this.isBaseApplication) {
          this.router.navigate(['/pages/alergotel_reports'])
        } else {
          this.router.navigate(['/alergotel/reports'])
        }
          this.navService.emitCancel.next(false);
      }
  }));
  this.subscriptions.push(this.navService.emitSubmit.subscribe((value: boolean) => {
    value && this.updateReport();
  }));
  this.subscriptions.push(this.navService.emitDelete.subscribe((value: boolean) => {
    value && this.deleteReport();
  }));
  }

  updateReport() {
    if(!this.lotId) {
      this.toastr.error(this.translate.instant('alergotel.clickMapToCreateLot'));
      return
    } else {
      this.alergotelService.updateReport(this.reportId, {lot: this.lotId}).subscribe(data => {
        console.log(data);
        this.toastr.success(this.translate.instant('alergotel.reportUpdated'));
        if(this.isBaseApplication) {
          this.router.navigate(['/pages/alergotel_reports'])
        } else {
          this.router.navigate(['/alergotel/reports'])
        }
      }, (error) => {
        this.toastr.error(error.error.results.error);
      })
    }
  }

  deleteReport() {
    this.alergotelService.deleteReport(this.report.id).subscribe(data => {
      console.log(data);
      this.toastr.success(this.translate.instant('alergotel.reportDeleted'));
      if(this.isBaseApplication) {
        this.router.navigate(['/pages/alergotel_reports'])
      } else {
        this.router.navigate(['/alergotel/reports'])
      }
    }, (error) => {
      this.toastr.error(error.error.results.error);
    })
  }

  getReportDetail() {
    this.isLoading = true;
    this.alergotelService.getReportDetail(this.reportId).subscribe(data => {
      this.report = data.body.results;
      this.isLoading = false;
      console.log(this.report);
      if(this.report.coords && this.report.coords.coordinates) {
        const coords = {
          lat: this.report.coords.coordinates[0],
          lng: this.report.coords.coordinates[1]
        }
        this.mapService.mapMarkers.push(coords);
        this.mapService.centerMapOnMarkers();
      }
      if(this.report.lot) {
        this.getLot(this.report.lot);
      }
    }, (err) => {
      this.toastr.error(err.error.results.error);
    })
  }

  public getLot(lotId:number) {
    const lot = this.globalRegistry.systemData.lots.filter(lot => {
      return lot.id === lotId;
    });
    this.lotId = lotId;
    this.lot = lot[0];
    this.lotImage = this.lot.staticMapImage;
  }

  getBadgeArray(count: number): any[] {
    return new Array(count);
}

goToLot(id?:number) {
  if(id) {
    this.router.navigate(['/pages/lot/edit/' + id], {queryParams: {from: 'report', id: this.report.id}});
  } else {
    this.router.navigate(['/pages/lot/add'], {queryParams: {from: 'report', id: this.report.id}});
  }
}

ngOnDestroy() {
  this.subscriptions.forEach(sub => {
    sub.unsubscribe();
  });
  this.mapService.mapMarkers = [];
  this.navService.editFarm.next(false);
  this.navService.editMod.next(false);
  this.navService.viewMod.next(false);
}

}
