import {
    Component,
    ViewChild,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    OnInit,
    ElementRef,
    OnDestroy
} from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { FapModalComponent } from '../../../../shared/partials/components/fap-modal/fap-modal.component';
import { ResourcesModel } from '../../../../core/models/stock/resources.model';
import { ResourceTypeModel } from '../../../../core/models/type/resource-type.model';
import { ResourceTypeInterface } from '../../../../core/interfaces/type/resource-type.interface';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { ResponseModel } from '../../../../core/models/response.model';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { CacheResolverService } from '../../../../core/services/api/cache/cache-resolver.service';

@Component({
    selector: 'fap-add-edit-resource-type',
    templateUrl: './fap_add-edit-resource-type.component.html',
    styleUrls: ['./fap_add-edit-resource-type.component.scss']
})
export class FapAddEditResourceTypeComponent implements OnChanges, OnInit, OnDestroy{

    @ViewChild('addEditIngredientModal')
    public addEditIngredientModal: FapModalComponent;

    @ViewChild('nFilter')
    public nFilter: ElementRef

    @Input()
    public selectedResource: any;

    @Input()
    public resourceTypes: Array<ResourceTypeModel>;

    @Input()
    public parentResource: ResourceTypeModel;

    @Input()
    public farmId?: number;

    @Output()
    public resourceTypeActionCompleted: EventEmitter<ResourceTypeModel> = new EventEmitter();

    @Output()
    public resourceTypeUpdate: EventEmitter<ResourceTypeModel> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    public resources: ResourcesModel = new ResourcesModel();

    public resourceTypeForm: UntypedFormGroup;
    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public isEdit = false;
    public translation = null;
    public prefix = 'RESOURCE_';
    public formTypes:any = [];
    
    @ViewChild('mySelect') mySelect;
    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;
    @ViewChild('addEditTranslationsModal')
    public addEditTranslationsModal: FapModalComponent;
    public label: string;

    constructor(public global: GlobalRegistryService,
                private formBuilder: UntypedFormBuilder,
                private typesService: TypesService,
                private translateService: TranslateService,
                private toastyService: ToastrService,
                private navService: NavService,
                public cacheService: CacheResolverService,
                public globalRegistry: GlobalRegistryService
                ) {
        this.initResourceTypeForm();
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.global.systemData.translations;
        // this.getFormTypes();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'selectedResource')) {
            console.log(this.selectedResource);
            this.initResourceTypeForm();
        }
    }

    public addIngredient(): void {
        this.addEditIngredientModal.showModal();
    }

    public submit(): void {
console.log(this.resourceTypeForm.value);
        if(this.resourceTypeForm.dirty || this.resourceTypeForm.controls['name'].value!='') {
            const resourceType: ResourceTypeInterface = {
                name: this.resourceTypeForm.controls['name'].value,
                name_t: this.resourceTypeForm.controls['name_t'].value,
                color: this.resourceTypeForm.controls['color'].value,
                // form_type: this.resourceTypeForm.controls['form_type'].value,
                parent: this.resourceTypeForm.controls['parent'].value === 'new' ? null : this.resourceTypeForm.controls['parent'].value
            };

            if(this.selectedResource) {
                this.updateResourceType({...{id: this.selectedResource.id}, ...resourceType});
            }
            else {
                this.addResourceType(resourceType);
            }
        }
    }

    convertKeysToCamelCase(obj) {
        const camelCaseObj = {};
        for (const key in obj) {
          const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
          camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
        }
        return camelCaseObj;
      }
      
      addEditTranslation(label) {
        this.label = label;
        const name = this.nameInput.nativeElement.value;
        const type: any = this.convertKeysToCamelCase(this.selectedResource);
      
        if (name === '' || type.nameT === null) {
            this.translation = null;
            this.addEditTranslationsModal.showModal();
            return;
        }
      
        for (const translation of this.globalRegistry.systemData.translations) {
            if (type && translation.id === type.nameT) {
                this.translation = translation;
                console.log(this.translation);
                this.addEditTranslationsModal.showModal();
                return;
            }
        }
      
        if (Object.keys(type).length === 0 && name) {
            const newTranslation = this.resourceTypeForm.controls['name_t'].value;
            for (const translation of this.globalRegistry.systemData.translations) {
                if (translation.id === newTranslation) {
                    this.translation = translation;
                    console.log(this.translation);
                    this.addEditTranslationsModal.showModal();
                    return;
                }
            }
        }
    }
    
        onAddUpdateTranslation(translation) {
            console.log(translation);
            this.translation = translation;
            this.resourceTypeForm.controls['name_t'].setValue(translation.id);
            this.resourceTypeForm.controls['name'].setValue(translation[this.langString]);
            this.addEditTranslationsModal.hideModal();
        }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: AbstractControl = this.resourceTypeForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    private initResourceTypeForm(): void {
        this.resourceTypeForm = this.formBuilder.group({
            name: [this.selectedResource ? this.selectedResource.name : '', Validators.compose([
                Validators.required,
            ])],
            name_t: [this.selectedResource ? this.selectedResource.name_t : '', Validators.compose([
                Validators.required,
            ])],
            parent: [this.parentResource ? this.parentResource.id : null, Validators.compose([
                Validators.required,
            ])],
            color: [this.selectedResource ? this.selectedResource.color : '', Validators.compose([
                Validators.required,
            ])],
            form_type: [this.selectedResource ? this.selectedResource.form_type : '', Validators.compose([
                Validators.required,
            ])],
        });
    }

    public getFormTypes() {
        const url = this.typesService.getUrl('form/');
        this.cacheService.delete(url);
        this.typesService.getFormTypes().subscribe((data) => {
            this.formTypes = data.body.results;
            console.log(data.body.results);
        });
      }

    private addResourceType(resourceType: ResourceTypeInterface): void {
        this.typesService.addResourceType(resourceType).subscribe((response: ResponseModel<ResourceTypeModel>): void => {
            this.toastyService.success(this.translateService.instant('stock.resourceTypeAddSuccesfull'));
            // this.global.reloadResourceTypes();
            this.resourceTypeActionCompleted.emit(response.body.results);
        },
        (): void => {
            this.toastyService.error(this.translateService.instant('stock.resourceTypeAddError'));
        });
    }

    private updateResourceType(resourceType: ResourceTypeInterface): void {
        this.typesService.updateResourceType(resourceType).subscribe((response: ResponseModel<ResourceTypeModel>): void => {
            this.toastyService.success(this.translateService.instant('stock.resourceTypeUpdateSuccesfull'));
            // this.global.reloadResourceTypes();
            this.resourceTypeUpdate.emit(response.body.results);

        },
        (): void => {
            this.toastyService.error(this.translateService.instant('stock.resourceTypeUpdateError'));
        });
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
        }
      }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
