import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';

@Component({
  selector: 'fap-treatments-table',
  templateUrl: './treatments-table.component.html',
  styleUrls: ['./treatments-table.component.scss']
})
export class TreatmentsTableComponent {

  @Input() public treatments: any[] = [];

  @Output() public editTreatment: EventEmitter<any> = new EventEmitter();
  @Output() public deleteTreatment: EventEmitter<any> = new EventEmitter();

  constructor(public globalRegistry: GlobalRegistryService) {}
}
