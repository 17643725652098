import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { FapRestBaseService } from '../../../base/fap-rest-base.service';


@Injectable()
export class SystemDataService extends FapRestBaseService {

    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/');
    }

}
