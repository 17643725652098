import {
    Component,
    OnInit
} from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { MapMarkerInterface } from '../../../../../shared/layout/fap_main-map/data/map-marker.interface';
import { MapPointInterface } from '../../../../../shared/layout/fap_main-map/data/map-point.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './pathogens-edit-container.component.html',
    styleUrls: ['./pathogens-edit-container.component.scss']
})
export class PathogensEditContainerComponent implements OnInit {

    constructor(public mapService: MapService,
                public translate: TranslateService) {
        this.mapService.mapHasContextMenu = true;
        this.mapService.onMapMenuInit.subscribe((): void => {
            this.addMenuOptions();
        });

        this.mapService.onCurrentLocationLoaded.subscribe((): void => {
            this.addMarkers();
        });

        this.mapService.onLastPolyPathChange.subscribe((polygon: Array<MapPointInterface>): void => {
            console.warn(polygon);
            console.error(this.mapService.mapPolygons);
        });

        this.mapService.onMarkerDragEnd.subscribe((marker: MapMarkerInterface): void => {
            console.log(marker);
        });

        this.mapService.clickedMarker.subscribe((marker: MapMarkerInterface): void => {
            console.log(marker);
        });

        this.mapService.mapPolylines = [
                {
                    points: [
                        {
                            lat: 45.858151484595254,
                            lng: 21.0814183786499
                        },
                        {
                            lat: 45.92314938974543,
                            lng: 21.10064445286865
                        }
                    ],
                    color: 'red'
                },
                {
                    points: [
                        {
                            lat: 45.92314938974543,
                            lng: 21.10064445286865
                        },
                        {
                            lat: 45.94607166656696,
                            lng: 21.2077611520874
                        },
                        {
                            lat: 45.953710320041765,
                            lng: 21.36980949193115,
                        }
                    ],
                    color: 'blue'
                },
                {
                    points: [
                        {
                            lat: 45.953710320041765,
                            lng: 21.36980949193115,
                        },
                        {
                            lat: 45.87345195252621,
                            lng: 21.35882316380615,
                        },
                        {
                            lat: 45.831365532201964,
                            lng: 21.3176244333374,
                        }
                    ],
                    color: '#ffdc36'
                },
                {
                    points: [
                        {
                            lat: 45.831365532201964,
                            lng: 21.3176244333374,
                        },
                        {
                            lat: 45.78541675004209,
                            lng: 21.3121312692749,
                        }
                    ],
                    color: '#0288d1'
                },
            ];
    }

    public ngOnInit(): void {
        if (window.innerWidth >= 767) {
            this.mapService.showMap();
        }
    }

    private addMarkers(): void {
        this.mapService.mapMarkers = [{
            lat: this.mapService.mapFocusPoint.lat,
            lng: this.mapService.mapFocusPoint.lng,
            label: '3',
            iconUrl: this.mapService.setMarkerIconColor('green'),
            infoWindowDetails: [
                {
                    img: '../../../../assets/images/avtar/4.jpg',
                    text: 'restesddsfdsa fdasf dasf sdfsd'
                },
                {
                    img: '../../../../assets/images/avtar/4.jpg',
                    text: 'restesddsfdsa fdasf dasf sdfsd'
                },
                {
                    img: '../../../../assets/images/avtar/4.jpg',
                    text: 'restesddsfdsa fdasf dasf sdfsd'
                },
                {
                    img: '../../../../assets/images/avtar/4.jpg',
                    text: 'restesddsfdsa fdasf dasf sdfsd'
                }
            ]}
        ];
    }

    private addMenuOptions(): void {
        const mapServiceContext: MapService = this.mapService;
        this.mapService.mapMenuOptions = [
            {
                header: this.translate.instant('map.actions.polygonHeader')
            },
            {
                text: this.translate.instant('map.actions.addMarker'),
                clickFunction(): void {
                   mapServiceContext.addMarker();
                }
            },
            {
                text: this.translate.instant('map.actions.drawPolygon'),
                clickFunction(): void {
                    mapServiceContext.drawNewPolygon();
                }
            },
            {
                text: this.translate.instant('map.actions.addPolyline'),
                clickFunction(): void {
                    mapServiceContext.drawNewPolyline();
                }
            },
            {
                text: this.translate.instant('revertChanges'),
                clickFunction(): void {
                    mapServiceContext.clickedRevertBtn.emit();
                }
            },
            {
                divider: true
            },
            {
                text: this.translate.instant('map.actions.copyCoordinates')
            }
        ];
    }
}
