<fap-header></fap-header>
<div
  class="container-fluid ter-container nc"
  style="overflow-x: hidden; width: 100%"
>
  <simulations-layout
    (deleteSimulation)="deleteSimulation($event)"
  >
  </simulations-layout>
</div>
