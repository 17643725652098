<div class="lot-card mt-2 mb-3" style="display: none;">
    <img class="lot-card-img" [src]="fieldPreview ? fieldPreview : getMapPreviewImgSrc()">
    <h4 class="lot-card-title mb-3">{{ fieldName }}</h4>

    <p class="lot-card-tex mb-1">{{ fieldArea?.valueAndUnit }}</p>
    <p class="card-text mb-1">{{ fieldSoilType }}</p>
    <p class="d-flex">
        <b>{{ 'farm.field.owner'  | translate }}:
        </b>{{ ownerName ? ownerName : ( 'farm.field.noOwner'  | translate ) }}
    </p>
    <div class="d-flex justify-content-end">
        <i class="fa fa-pencil edit-field mr-3" (click)="editField.emit()"></i>
        <i class="fa fa-close delete-field" (click)="deleteField.emit()"></i>
    </div>
</div>
