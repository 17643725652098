<form class="theme-form" [formGroup]="ingredientTypeForm" id="parent1">
    <div class="row">
    <div class="col-md-12">
        <mat-form-field class="w-100" (click)="addEditTranslation('stock.inredientType.name')">
            <mat-label class="col-form-label">{{ 'stock.inredientType.name' | translate }}</mat-label>
            <input matInput placeholder="{{ 'stock.inredientType.name' | translate }}" formControlName="name" readonly #nameInput>
            <mat-error *ngIf="ifControlHasError('name','required')">
                <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12">
        <div>
            <i class="material-icons">format_color_fill</i>
            <div class="color-input ml-2">    
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'stock.inredientType.color' | translate }}</mat-label>
                    <input matInput class="w-100" placeholder="{{ 'stock.inredientType.color' | translate }}" formControlName="color" type="Color">
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- <fap-upload-avatar [inputImageUrl]="innerImage" (outputImageLoaded)="imageChanged($event)">
    </fap-upload-avatar> -->
    <div class="col-md-12 d-flex justify-content-end">
        <button class="btn mr-2"
                type="button"
                [disabled]="ingredientTypeForm.invalid"
                (click)="submit()">{{ 'translations.save' | translate }}
        </button>
        <button class="btn" type="button" (click)="cancel.emit()">
            {{ 'translations.cancel' | translate }}
        </button>
    </div>
    </div>
</form>

<fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.ingredientType)" 
        [prefix]="prefix" 
        [label]="label"
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>
