import { StockItemModel } from './stock-item.model';
import { FapBaseModel } from '../../base/fap-base-model';
import { UnitModel } from '../units/unit.model';

export class ResourceEntryModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'stock_item',
        'storage_temperature',
        'info',
        'storage_humidity_min',
        'storage_humidity_max',
        'virtual_quantity',
        'quantity',
        'ingredients',
        'created_at',
        'updated_at',
        'cost',
        'unit'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'stock_item',
        'virtual_quantity',
        'quantity',
        'ingredients',
        'cost'
    ];

    public static extendedFields: {} = {
        stock_item: StockItemModel,
        unit: UnitModel
    };

    public id: number;
    public stockItem: StockItemModel = new StockItemModel();
    public storageTemperature: number;
    public info: string;
    public storageHumidityMin: number;
    public storageHumidityMax: number;
    public virtualQuantity: number;
    public quantity: number;
    public ingredients: Array<number> = [];
    public createdAt: string;
    public updatedAt: string;
    public cost: number;
    public unit: UnitModel = new UnitModel();
}
