import { Routes } from '@angular/router';
import { PartyContainerComponent } from './containers/party-container/party-container.component';


export const partyRoutes: Routes = [
    {
        path:       '',
        component:  PartyContainerComponent
    }
];
