<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="card-content" *ngIf="activities?.length != 0" (scroll)="onScroll($event)">
        <div class="activity_card d-flex" [ngClass]="{ 'activity-overlay': activity?.completed }" *ngFor="let activity of activities">
            <ng-container *ngFor="let type of activityTypes">
                <ng-container *ngIf="activity?.type === type.id">
                <div class="icon">
                    <img *ngIf="type?.icon?.file" [src]="mediaUrl + type?.icon?.file" alt="icon">
                    <img *ngIf="!type?.icon || !type?.icon?.file" src="../../../../assets/images/image_placeholder.png" alt="icon">
                    <span class="workflow_counter" *ngIf="activity?.workflows?.count">{{activity?.workflows?.count}}</span>
                </div>
                </ng-container>
            </ng-container>
            <div class="info">
               <div class="w-100 activity_name"> <b>{{activity?.nameT ? getTranslation(activity?.nameT) : activity.name_t ? activity.name_t : activity.name ? activity.name : 'No name'}}</b></div>
               <div class="w-100 type_name"><small>{{ getActivityTypeName(activity?.type) }}</small></div>
            </div>
            <div class="start_date">
                {{ activity?.started | timeAgo}}
            </div>
            <div class="assigned_to">
                <span class="play_workflow" *ngIf="checkWorkflowPresence(activity)" (click)="getActivityForm(activity?.type, activity?.id)"><i class="fa fa-play"></i></span>
                <span *ngIf="!checkWorkflowPresence(activity)">
                    <img *ngIf="person?.picture" [src]="mediaUrl + person?.picture" alt="">
                    <img *ngIf="!person?.picture" style="object-fit: inherit;" src="../../../../assets/images/image_placeholder.png" alt="icon">
                </span>
            </div>
            <div class="status_icon" *ngIf="activity?.completed">
                <img src="../../../../assets/icons/checked.svg" alt="check">
            </div>
        </div>
    </div>
</div>
<fap-modal [inputIsLarge]="true" #workflowModal [inputTitle]="entityInfo?.name_t?.[langString] ? entityInfo?.name_t?.[langString] : entityInfo?.name" [showFooter]="false" 
[modalHeight]="defaultSize === 'pc' ? entityInfo?.form_css?.pc?.height : defaultSize === 'tablet' ? entityInfo?.form_css?.tablet?.height : entityInfo?.form_css?.mobile?.height"
[modalWidth]="defaultSize === 'pc' ? entityInfo?.form_css?.pc?.width : defaultSize === 'tablet' ? entityInfo?.form_css?.tablet?.width : entityInfo?.form_css?.mobile?.width"
>
<!-- {{entityInfo | json}} -->
    <div class="minus_margin">
        <fap-workflow-modal 
            [isLoading]="isLoading"
            [entity]="'activity'"
            [html]="html" 
            [workflow]="workflow"
            [entityInfo]="entityInfo"
            [objectId]="formTypeId"
            [previousFormClick]="previousFormClick"
            [nextFormClick]="nextFormClick"
            (hideModal)="closeModal()"
            (prevButtonClick)="prevButtonClick()"
            (nextButtonClick)="nextButtonClick($event)"
            (submitForm)="submitForm()"
            (onUpdateAction)="onUpdateAction()"
            [errorFields]="errorFields"
        >
        </fap-workflow-modal>
    </div>
</fap-modal>