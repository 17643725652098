import { Pipe, PipeTransform } from '@angular/core';
import { UnitModel } from '../models/units/unit.model';

@Pipe({
    pure: false,
    name: 'getUnit',
})
export class getUnitPipe implements PipeTransform {
    public transform(unit: any, unitsMap: any): string {
    
        if (!unitsMap) {
          return "?"
        }
    
        let unitPack: UnitModel
        unitPack = unitsMap.get(unit);
        return unitPack && unitPack.shortName ? unitPack.shortName : '';
        }
}
