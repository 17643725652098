import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CropTypeModel } from '../../../../../core/models/type/crop-type.model';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { CropService } from '../../../../../core/services/api/crop/crop.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { MapHelper } from '../../../../../core/heplers/map.helper';
import { environment } from '../../../../../../environments/environment';
import { NavService } from '../../../../../shared/services/nav.service';
import { Subscription } from 'rxjs';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';

@Component({
  selector: 'crops-layout',
  templateUrl: './crops-layout.component.html',
  styleUrls: ['./crops-layout.component.scss']
})
export class CropsLayoutComponent implements OnInit, OnDestroy {
  @Input() public cropTypes: CropTypeModel[];
  public crops: CropModel[];
  public mediaUrl = environment.mediaUrl;
  public langString: string;
  public subscriptions: Subscription[] = [];
  public fromDate = new Date();
  public toDate =  new Date();
  public searchString;
  public limit = 30;
  public getMore = true;
  public nextToken: { limit: number; offset: number; search: string, from, to } = null;
  public isLoading = false;
  @ViewChild('cropsParent', { static: true }) cropsParent: ElementRef;
  
  constructor(public cropService: CropService, public globalRegistry: GlobalRegistryService,public navService: NavService, public sensorService: SensorService, public cacheService: CacheResolverService) {
  }

  ngOnInit(): void {
    this.getSearchString();
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(
            this.navService.getCurrentLanguage.subscribe((lang) => {
                if (lang) {
                    this.langString = lang;
                }
            })
        );
    this.cropService.getCrops({limit: 1000}).subscribe(data => {
      console.log(data);
      this.crops = data.model;
    })
  }
  
  getSearchString() {
    this.subscriptions.push(this.sensorService.getSearchString.subscribe(res => {
      console.log(res);
      this.searchString = res;
    }));
  }

  public getFilteredCrops() {
    if(this.fromDate && this.toDate) {
    this.cropsParent.nativeElement.scroll(0,0);
    const url = this.cropService.getUrl('crops/');
    this.isLoading = true;
    const fromDate = this.fromDate;
    const toDate = this.toDate;
    const searchString = this.searchString? this.searchString : ''
    this.cropService.getCrops({
        from: fromDate,
        limit: 30,
        offset: 0,
        to: toDate,
        search: searchString
     }).subscribe(data => {
      this.cacheService.delete(url+'from='+fromDate+'&limit='+this.limit+'&offset=0'+'&to='+toDate+'&search='+searchString);
        console.log(data.body.results);
        this.crops = data.model;
        this.nextToken = data.body.next
              ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
              : null;
        if(this.nextToken) this.getMore = true
        this.isLoading = false;
     });
    }
  }

  paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }
  
  getNextParticles() {
  const url = this.cropService.getUrl('');
  if(this.getMore) {
  this.nextToken &&
    this.cropService.getCrops(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'from='+this.nextToken.from+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset+'&search='+this.nextToken.search+'&to='+this.nextToken.to);
      console.log(data);
      if(this.crops) {
        this.crops = [...this.crops, ...data.body.results];
      } else {
        this.crops = data.body.results;
      }
      // this.filterdItems = this.items;
      if(data.body.next == null) {
        this.getMore = false;
        return
      } else {
        const url = data.body.next.split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.nextToken.offset != params['offset']) {
        this.nextToken = {from: params['from'], limit: params['limit'], offset: params['offset'], search : params['search'] ? params['search'] : '', to: params['to']};
        } else {
          return
        }
      }
    });
  } else {
    return
  }
  }
  
  getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params, param) => {
            const [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  }

  getLotArea(coords) {
    return coords ? MapHelper.getPolygonArea(coords.coordinates[0], 'ha') : 0;
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter((trans) => {
        return trans.id === translation;
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation;
    }
  }
  
  deleteCrop(id) {
    console.log(id)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
