<fap-header>
    <fap-header-actions [title]="'equipment.equipment' | translate" [isDeleteEnabled]="equipmentId ? true : false"></fap-header-actions>
</fap-header>
<fap-add-edit-equipment-layout
    [isEditMode]="isEditMode"
    [isPreviewMode]="isPreviewMode"
    [equipment]="equipment"
    [farms]="globalRegistry.systemData.farms"
    [equipmentId]="equipmentId"
    [isSubmitDisabled]="!(parkingPlace && parkingPlace.points.length>3)"
    [equipmentTypes]="globalRegistry.systemData.equipmentTypes"
    [equipmentPoweredTypes]="equipmentPoweredTypes"
    (submitEquipment)="submitEquipment($event)"
    (enabledEdit)="enableEdit($event)"
    (deleteParty)="deleteParty($event)"
    (deleteEquipmentType)="deleteEquipmentType($event)"
    (deleteEquipment)="deleteEquipment($event)"
    [resourceTypes]="resourceTypes"
    >
</fap-add-edit-equipment-layout>
