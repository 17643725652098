<div class="products_modal">
        <div class="prods">
            <div class="search_filters">
                <form class="prod_search" [formGroup]="prodFilterForm" [ngClass]="prodStatus ? 'show' : ''">
                    <div class="form-group m-0">
                        <div class="mt-2 row align-items-baseline">
                            <div class="col-10 p-0">
                                <div class="row search_form">
                                    <div class="col-6">
                                        <div class="input-group align-items-baseline w-100">
                                            <mat-form-field class="w-100">
                                                <mat-label class="col-form-label">{{ 'stock.search' | translate }}</mat-label>
                                                <input
                                                    type="text"
                                                    matInput
                                                    formControlName="search"
                                                    (keyup)="filterProduct()"
                                                    #searchInput
                                                >
                                                <button
                                                    *ngIf="prodFilterForm.get('search').value"
                                                    matSuffix
                                                    mat-icon-button
                                                    aria-label="Clear"
                                                    class="clear_btn"
                                                    (click)="clearSearch(searchInput)"
                                                >
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group w-100">
                                            <mat-form-field class="w-100">
                                                <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                                <mat-select #rsp class="select" formControlName="type" multiple (selectionChange)="setItem($event)">
                                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                                        <mat-form-field appearance="fill" class="w-100">
                                                            <input matInput #resourceFilter placeholder="Search">
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="scroll_wrap">
                                                        <mat-option style="height: 0!important;"></mat-option>
                                                        <mat-option [value]="null"></mat-option>
                                                        <mat-optgroup class="noteIcons" *ngFor="let parent of globalRegistry?.systemData?.resourceTypes | filter : resourceFilter.value">
                                                            <span class="mat-option-text">
                                                                <div class="type-name green">
                                                                    <b>
                                                                        {{ parent?.name_t ? getTranslation(parent?.name_t) : parent?.name }}
                                                                    </b>
                                                                </div>
                                                            </span>
                                                            <mat-option style="height: 0!important;"></mat-option>
                                                            <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                                                <div class="type-name">
                                                                    <span>
                                                                        {{ child?.name_t ? getTranslation(child?.name_t) : child?.name }}
                                                                    </span>
                                                                </div>
                                                            </mat-option>
                                                        </mat-optgroup>
                                                    </div>
                                                </mat-select>
                                                <button class="clear_btn" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue($event)" *ngIf="prodFilterForm.get('type').value && prodFilterForm.get('type').value.length > 0">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 p-0">
                                <div class="text-center w-100 d-flex justify-content-center">
                                    <!-- <button class="btn btn-lg" type="button" (click)="filterProduct()">
                                        <img src="../../../../../../assets/images/search.png" alt="search">
                                    </button> -->
                                    <fap-round-button (click)="showProductModal()"></fap-round-button>
                                    <!-- <button class="button add_prod">
                                        <em class="fa fa-plus center f-20 ml-2" (click)="showProductModal()"></em>
                                    </button> -->
                                    <!-- <button type="button" class="refresh" (click)="clearProdInputs()">
                                        <img src="../../../../../../assets/images/filter.png" alt="">
                                    </button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <span class="toggle_filters" (click)="toggleProdFilter()">Toggle filters <img src="../../../../../../assets/images/setting.png"></span>
            </div>
            
        <div infiniteScroll
            [infiniteScrollDistance]="4"
            [infiniteScrollThrottle]="0"
            (scrolled)="scrolledDownProducts()"
            [scrollWindow]="false"
            style="height: calc(90vh - 150px); flex: auto; overflow-y: auto;">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{{ 'product.product' | translate }}</th>
                            <th>{{ 'product.packaging' | translate }}</th>
                            <th>{{ 'product.producer' | translate }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products">
                            <td>{{ product?.id }}.</td>
                            <td (click)="selectProduct(product)">
                                <div class="d-flex name_actions">
                                    <product-tag [productName]="product?.label?.name" [productType]="product?.label?.type"></product-tag>
                                </div>
                            </td>
                            <td>
                                <b>{{ product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b>
                            </td>
                            <td>
                                {{product?.label?.producer?.name}}
                            </td>
                            <td>
                                <div class="actions">
                                <action-buttons [locked]="product.locked" (editEmit)="productEdit(product?.id)" (deleteEmit)="productDelete(product.id)"></action-buttons>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>

  <fap-modal #addEditProductModal [showFooter]="false"
    [inputTitle]="'product.addEditProduct' | translate">
        <fap-add-edit-product 
            [productId]="productId"
            (closeModal)="addEditProductModal.hideModal()"
            (addedProduct)="addProduct($event);addEditProductModal.hideModal()"
            (updatedProduct)="updateProduct($event);addEditProductModal.hideModal()"
        >
        </fap-add-edit-product>
</fap-modal>