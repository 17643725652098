import { Routes } from '@angular/router';
import { DeviceItemComponent } from './components/device-item/device-item.component';
import { DeviceViewComponent } from './components/device-view/device-view.component';
import { DevicesContainerComponent } from './containers/devices-container/devices-container.component';

export const deivcesRoutes: Routes = [
    {
        path:       '',
        component:  DevicesContainerComponent
    },
    {
        path: 'edit/:deviceId',
        component: DeviceItemComponent
    },
    {
        path: 'view/:deviceId',
        component: DeviceViewComponent,
        data: {
            filters: {
                hasFarmFilter:true,
                hasDeviceViewFilter:true,
                hasDashboardFilter: true
            }
        },
    },
];
