import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { LoginBodyInterface } from '../services/api/auth/data/login-body.interface';

@Injectable()
export class FapCustomRequestOptionsService {

    // static defaultRequestOptions() {
    // 	const headers = new HttpHeaders({
    // 		'content-type': 'application/json',
    // 		'accept': 'application/json;q=0.9,*/*;q=0.8',
    // 	});
    // 	return {
    // 		headers: headers,
    // 		withCredentials: true,
    // 	};
    // }

    static authRequestOptions(body: LoginBodyInterface) {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            'accept': 'application/json;q=0.9,*/*;q=0.8',
            'authorization': 'Basic ' + btoa(body.email + ':' + body.password),
        });
        return {
            headers: headers,
            withCredentials: true
        };
    }

    static tokenRequestOptions() {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            'accept': 'application/json;q=0.9,*/*;q=0.8',
            'authorization': 'Token ' + localStorage.getItem('auth_token')
        });
        return {
            headers: headers,
            withCredentials: true,
        };
    }

    static get withTokenHeaders(): HttpHeaders {
        const headers: HttpHeaders = new HttpHeaders({
            'content-type': 'application/json',
            'accept': 'application/json;q=0.9,*/*;q=0.8',
            'authorization': 'Token ' + localStorage.getItem('auth_token')
        });
        return headers;
    }

    // static tokenFormDataRequestOptions() {
    // 	const headers = new HttpHeaders({
    // 		'accept': 'multipart/settingsForm-data;q=0.9,*/*;q=0.8',
    // 		'authorization': 'Token ' + JSON.parse(localStorage.getItem('user')).token,
    // 	});
    // 	return {
    // 		headers: headers,
    // 		withCredentials: true,
    // 	};
    // }
}