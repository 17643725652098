<div class="widgets d-flex">
    <div *ngFor="let widget of widgets"
        class="widget"
        [ngClass]="{'selected-widget': selectedWidget===widget}"
        (click)="clickWidget(widget)">
        <img [src]="widgetInfo[widget].previewImg" alt="preview">
        <div class="d-flex widget-title my-1">{{ widgetInfo[widget].title | translate }}</div>
        <div class="d-flex justify-content-center widget-subtitle">{{ widgetInfo[widget].description | translate }}</div>
    </div>
</div>
