<div 
    *ngIf="items.length" 
    class="forecast_page" 
    [style.background]="css?.bg_text_color" 
    [ngStyle]="{
        'zoom': itemCss?.zoom[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')] || css?.zoom[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')]
    }"    
    >
    <ng-container *ngFor="let item of items; let isFirst = first">
      <ng-container *ngIf="isFirst; else notFirst">
        <div class="today">
            <div class="today_date">
                <h3 [style.color]="css?.text_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 2) }">{{ item?.data?.day?.text }}</h3>
                <p [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">{{ item?.data?.day?.value }}</p>
            </div>
            <div class="today_weather d-flex justify-content-between align-items-center">
                <div class="weather_icon">
                    <img [src]="mediaUrl + item?.data?.sky?.icon" alt="weather">
                </div>
                <div class="weather_data">
                    <h2 [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 4) }">{{ item?.data?.temperature_min?.value }}<span [ngStyle]="{ 'font-size': getFontSize(css?.unit_size, 3.5), 'vertical-align': css?.unit_vertical_align, 'font-weight': css?.unit_font_weight }"class="unit" [style.color]="css?.unit_color">{{ item?.data?.temperature_min?.unit }}</span></h2>
                    <div class="wind_and_uv d-flex">
                        <div class="wind d-flex">
                            <div class="icn">
                                <img src="../../../../../assets/icons/wind.svg" alt="wind">
                            </div>
                            <div class="value" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">
                                <div [style.color]="css?.value_color">{{item?.data?.wind_speed?.value}} <span class="unit" [style.color]="css?.unit_color" [ngStyle]="{'vertical-align': css?.unit_vertical_align, 'font-weight': css?.unit_font_weight}">{{item?.data?.wind_speed?.unit}}</span></div>
                                <div class="wind_direction" [style.color]="css?.value_color">{{item?.data?.wind_direction?.value}}</div>
                            </div>
                        </div>
                        <div class="uv d-flex">
                            <div class="icn">
                                <img src="../../../../../assets/icons/uv-index.svg" alt="uv index">
                            </div>
                            <div class="value" [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1) }">
                                {{item?.data?.uv?.text}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </ng-container>
      <ng-template #notFirst>
        <div class="other_day d-flex justify-content-between align-items-center">
            <div class="other_date">
                <h3 [style.color]="css?.text_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">{{ item?.data?.day?.text }}</h3>
                <p [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1) }">{{ item?.data?.day?.value }}</p>
            </div>
            <div class="weather_icon">
                <img [src]="mediaUrl + item?.data?.sky?.icon" alt="weather">
            </div>
            <div class="weather_data d-flex align-items-baseline">
                <h2 [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 2.5) }">{{ item?.data?.temperature_max?.value }} <span [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1), 'vertical-align': css?.unit_vertical_align, 'font-weight': css?.unit_font_weight }" class="unit" [style.color]="css?.unit_color">{{ item?.data?.temperature_max?.unit }}</span></h2>

                <div class="min_temp" [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">{{ item?.data?.temperature_min?.value }} <span [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1), 'vertical-align': css?.unit_vertical_align, 'font-weight': css?.unit_font_weight }" class="unit" [style.color]="css?.unit_color">{{ item?.data?.temperature_min?.unit }}</span></div>
            </div>
            <div class="wind value">
                <div [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">{{item?.data?.wind_speed?.value}} <span [ngStyle]="{'font-size': getFontSize(css?.text_size, 1), 'vertical-align': css?.unit_vertical_align, 'font-weight': css?.unit_font_weight}" class="unit" [style.color]="css?.unit_color">{{item?.data?.wind_speed?.unit}}</span></div>
                <div class="wind_direction" [style.color]="css?.value_color" [ngStyle]="{ 'font-size': getFontSize(css?.text_size, 1.5) }">{{item?.data?.wind_direction?.value}}</div>
            </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  