import { Input, ContentChildren, QueryList, Output, EventEmitter, Directive } from '@angular/core';
// import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { FapWizardStepInterface } from './data/fap-wizard-step.interface';
import { FapWizardReviewTabInterface } from './data/fap-wizard-review-tab.interface';
import { FapWizardContentDirective } from './fap-wizard-content.directive';
import { FapWizardReviewDirective } from './fap-wizard-review.directive';


let nextId = 0;

@Directive({
    selector: '[fapBaseWizardContent]'
})
export class FapWizardBaseDirective {

    @Output()
    public wizardSubmitted: EventEmitter<any> = new EventEmitter();

    @Input()
    public wizardDestroyOnHide = false;

    @Input()
    public set wizardSelectedStep(id: string) {
        setTimeout(() => {
            this.onTabClick(id);
        });
    }

    @Input()
    public wizardSteps: Array<FapWizardStepInterface> = [];

    @Input()
    public set wizardReviewTab(tab: FapWizardReviewTabInterface) {
        this.reviewTabTitle = tab.title;
        this.wizardSteps.push({
            id: tab.id ? tab.id : this.reviewTabId,
            title: tab.tabTitle,
            icon: tab.icon ? tab.icon : 'flaticon-globe'
        });
        if (tab.id) {
            this.reviewTabId = tab.id;
        }
    }

    public reviewTabTitle: string;
    public reviewTabId = `fap_wizard_review_#${nextId++}`;

    @ContentChildren(FapWizardContentDirective)
    public steps: QueryList<FapWizardContentDirective>;

    @ContentChildren(FapWizardReviewDirective)
    public reviews: QueryList<FapWizardReviewDirective>;

    // @ViewChild(NgbTabset, { static: false })
    // public tabset: NgbTabset;

    public isLoaded = false;
    public uniqueId: string;

    public activeTabIndex = 0;

    public selectedTabId: string;

    constructor() {
        this.uniqueId = `fap_wizard_#${nextId++}`;
    }


    public onTabClick(tabId: string): void {
        this.selectedTabId = tabId;
        // this.tabset.select(tabId);
        this.updateActiveTabs(tabId);
    }

    public updateActiveTabs(tabId: string): void {
        this.selectedTabId = tabId;
        this.wizardSteps.map((step: FapWizardStepInterface, index: number) => {
            if (tabId === step.id) {
                this.activeTabIndex = index;
            }
        });
    }

    public prevStep(): void {
        this.selectedTabId = this.wizardSteps[this.activeTabIndex - 1].id;
        // this.tabset.select(this.selectedTabId);
        this.updateActiveTabs(this.selectedTabId);
    }

    public nextStep(): void {
        this.selectedTabId = this.wizardSteps[this.activeTabIndex + 1].id;
        // this.tabset.select(this.selectedTabId);
        this.updateActiveTabs(this.selectedTabId);
    }

    public submitWizard(): void {
        this.wizardSubmitted.emit();
    }

}
