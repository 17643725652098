import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
    pure: false,
    name: "getLastTransmission"
})

export class getLastTransmissionPipe implements PipeTransform {
    public transform(data: any) {
        if (data && data.lastValues && data.lastValues.length) {
            const timestamps = data.lastValues.map(value => value.value.timestamp);
    
            const validTimestamps = timestamps
                .filter(timestamp => timestamp !== undefined && timestamp !== null);
        
            if (validTimestamps.length > 0) {
                const maxVal = Math.max(...validTimestamps);
                
                if (!isNaN(maxVal)) {
                    return this.toDateTime(maxVal);
                } else {
                    return '-';
                }
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }
    

    toDateTime(secs) {
        return moment.unix(secs).utc().local().fromNow();
    }
}

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'a few seconds',
        ss: '%ss',
        m: 'a minute',
        mm: '%dm',
        h: 'an hour',
        hh: '%dh',
        d: 'a day',
        dd: '%dd',
        M: 'a month',
        MM: '%dM',
        y: 'a year',
        yy: '%dy',
    },
});
