import { FapBaseModel } from '../../base/fap-base-model';


export class PersonProfileModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'date_joined',
        'email',
        'profile',
        'first_name',
        'last_name',
        'deleted'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'email'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public userName: string;
    public dateJoined: Date;
    public email: string;
    public firstName: string;
    public lastName: string;
    public profile: number;
    public deleted: boolean;

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

}
