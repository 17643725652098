import { AttributesComponent } from './attributes.component';
import { AttributesContainerComponent } from './containers/attributes-container/attributes-container.component';
import { FapAttributesLayoutComponent } from './components/fap_attributes-layout/fap_attributes-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { PartialsModule } from '../../views-partials/partials.module';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatTableModule,
        PartialsModule
    ],
    declarations: [
        AttributesComponent,
        AttributesContainerComponent,
        FapAttributesLayoutComponent
    ]
})
export class AttributesModule {

}
