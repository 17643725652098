import { FapBaseModel } from '../../base/fap-base-model';
import { PolygonCoordsInterface } from '../../interfaces/coords/polygon-coords.interface';
import { ProductModel } from '../stock/product.model';
import { EquipmentActivityModel } from './equipment-activity.model';


export class EquipmentModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'farm',
        'product',
        'working_hours',
        'manufacture_serial',
        'bill',
        'inspection',
        'registration_plate',
        'rented_from',
        'garage_area',
        'info',
        'parked',
        'last_activity',
        'attachable'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: any = {
    };

    public id: number;
    public farm: number;
    public product: ProductModel = new ProductModel();
    public workingHours: number;
    public manufactureSerial: string;
    public bill: any;
    public inspection: any;
    public registrationPlate: string;
    public rentedFrom: number;
    public garageArea: PolygonCoordsInterface;
    public info: string;
    public parked: boolean;
    public lastActivity: EquipmentActivityModel;
    public paths: Array<Array<Array<number>>>;
    public attachable?: number
}
