export class UtilsHelper {
    public static camelCaseToSnakeCaseString(camelCaseStr: string): string {
        return camelCaseStr.replace(/\.?([A-Z]+)/g, (x: string, y: string): string => '_' + y.toLowerCase()).replace(/^_/, '');
    }

    public static getDayNameFromDateString(date: string): string {
        const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        return days[new Date(date).getDay()];
    }

    public static getDeepCopy<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }
}
