import {
    Component, EventEmitter, Input,
    OnInit, Output
} from '@angular/core';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { UserInterface } from '../../../../../core/services/api/user/data/user.interface';
import { UserService } from '../../../../../core/services/api/user/user.service';
import { tap, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../../../../../core/models/response.model';
import { UserModel } from '../../../../../core/models/user/user.model';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { CompanyService } from '../../../../../core/services/api/company/company.service';

@Component({
    selector: 'fap-user-profile-container',
    templateUrl: './fap_user-profile-container.component.html'
})
export class FapUserProfileContainerComponent implements OnInit {
    @Input()
    public isProfileOpen: boolean;

    @Input()
    public buttonColor: string;

    @Input()
    public accentColor: string;

    @Output()
    public toggleProfileModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    userData;

    constructor(public globalRegistry: GlobalRegistryService,
                public userService: UserService,
                public toastyService: ToastrService,
                public mapService: MapService,
                public companyService: CompanyService) {
                 this.userData =  globalRegistry.userData
    }

    public ngOnInit(): void {
        this.mapService.hideMap();
    }

    public updateProfilePicture(picture: FormData): void {
        this.companyService.updatePerson(picture, this.globalRegistry.userData.profile.id).subscribe(data => {
            if(data) {
                this.globalRegistry.reloadUserData();
            }
        });
    }

    public resetPassword($event: any): void {
        console.log($event)
        this.toastyService.success('Check Your email for password reset');
    }

    public toggleProfile($event: boolean): void {
        this.toggleProfileModal.emit($event);
    }

    public updateUser(user: UserInterface): void {
        this.userService.updateUser(user).pipe(tap(
            (response: ResponseModel<UserModel>) => {
                this.globalRegistry.initUserData(response.model);
                this.userData = response.model
                this.toastyService.success('User updated succesfully');
                this.globalRegistry.reloadUserData();
            },
            () => {
                this.toastyService.error('Update failed');
            }),
            take(1)).
        subscribe();
    }
}
