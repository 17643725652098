import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';
import { ObjectModel } from '../../../core/services/api/objects/object.service';
import { SensorModel } from '../../../core/models/sensor/sensor.model';
import { UnitTypeModel } from '../../../core/services/api/unit-type/unit-type.service';
import { GlobalRegistryService } from '../../../core/global-registry/global-registry.service';
import { Subscription, combineLatest } from 'rxjs';
import { NavService } from '../../services/nav.service';
import { MapService } from '../../layout/fap_main-map/service/map-service';
import { DataService } from '../../../core/services/websocket/data.service';
import { MapPointInterface } from '../../layout/fap_main-map/data/map-point.interface';
import { MapMarkerInterface } from '../../layout/fap_main-map/data/map-marker.interface';
import { SensorService } from '../../../core/services/api/sensor/sensor.service';
import { MapPolygonInterface } from '../../layout/fap_main-map/data/map-polygon.interface';
import * as moment from 'moment';

@Component({
    selector: 'fap-map-widget',
    templateUrl: './map-widget.component.html',
    styleUrls: ['./map-widget.component.scss'],
})
export class MapWidgetComponent implements OnInit, OnChanges {
    @Input() public widget: WidgetModel;
    @Input() objects: ObjectModel[] = [];
    @Input() sensors: SensorModel[] = [];
    @Input() unitTypes: UnitTypeModel[] = [];
    @Input() public langString: string;
    public selectedWidget;
    defaultCenter = { lat: 49.6116, lng: 6.1319 };
    currentCenter = Object.assign({}, this.defaultCenter);
    public lotName: string;
    public toggleMain = false;
    public toggleSub = false;
    public showMenu = true;
    public translatedNames: any = [];
    public subscriptions: Subscription[] = [];
    zoom = 14;
    public bounds: google.maps.LatLngBounds;
    public markers: MapMarkerInterface[] = [];
    public mapType = 'position';
    public fromDate = new Date();
    public toDate = new Date();
    public agg = 'day';
    public i = 0;
    public mapPolygons: MapPolygonInterface[] = [];
    public paths = [];

    @Output()
    public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private globalRegistry: GlobalRegistryService,
        public navService: NavService,
        public mapService: MapService,
        public dataService: DataService,
        public sensorService: SensorService
    ) {}

    ngOnInit(): void {
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.getFromDate();
        this.getToDate();
        this.getAgg();
        this.subscriptions.push(this.sensorService.getName.subscribe(res => {
          if(res && this.sensors.length > 0) {
            setTimeout(() => {
                this.getCoords(this.widget.settings['sensors']);
            }, 1000)
          }
        }));
    }

    getFromDate() {
        this.subscriptions.push(
            this.sensorService.getFromDate.subscribe((res) => {
                this.fromDate = res;
            })
        );
    }

    getToDate() {
        this.subscriptions.push(
            this.sensorService.getToDate.subscribe((res) => {
                this.toDate = res;
            })
        );
    }

    getAgg() {
        this.subscriptions.push(
            this.sensorService.getAgg.subscribe((res) => {
                this.agg = res;
            })
        );
    }

    public getLotName(lotId: number = this.widget.objectId): string {
        const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
        return lot ? lot.name : '';
      }

    hideMain() {
        this.toggleMain = false;
    }

    toggleMenu() {
        this.toggleMain = !this.toggleMain;
    }

    getTranslation(translation) {
        const t = this.globalRegistry.systemData.translations.filter((trans) => {
            return trans.id === translation;
        });
        if (t[0]) {
            if(t[0]) {
                if(t[0][this.langString] === null || t[0][this.langString] === '') {
                  return translation
                } else {
                  return t[0][this.langString];
                }
              } else {
                return;
            }
        } else {
            return translation;
        }
    }

    mapClicked($event) {
        console.log($event);
    }

    mapReady(event) {
        console.log(event);
        // this.setCurrentPosition();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.hasOwnProperty('widget') && this.widget) {
            console.log(this.widget);
            const sensorIds = this.widget["settings"]["sensors"].map(
                (sensor) => sensor?.id
              );
            setTimeout(() => {
                this.sensorService.drillData({
                    sensor: sensorIds,
                    from: this.fromDate,
                    to: this.toDate,
                }).subscribe((drilled) => {
                    console.log(drilled);
                    this.getCoords(this.widget.settings['sensors']);
                });
            }, 1000);
        }
        if (Object.prototype.hasOwnProperty.call(changes, 'widget') && this.widget && this.sensors.length > 0) {
            this.mapType = this.widget.settings['mapType'];
            this.getLotName(this.widget.objectId);
            setTimeout(() => {
                this.getCoords(this.widget.settings['sensors']);
                console.log(this.widget.settings['sensors']);
            }, 1000)
        }
    }

    public getCoords(sensors) {
        let sensorId = sensors[0]?.id;
        console.log(sensorId);
        if(this.sensors.length > 0) {
            this.sensors.forEach(element => {
                if(element.id === sensorId) {
                    console.log(element);
                    console.log(this.mapType);
                    if (this.mapType === 'position') {
                        this.markers = [];
                        
                            const lng = element.lastValue.value_coord.coordinates[0];
                            const lat = element.lastValue.value_coord.coordinates[1];
                            this.markers.push({
                                lat: lat,
                                lng: lng,
                                iconUrl: this.setMarkerIconColor('red'),
                                infoWindowDetails: [
                                  {
                                    img: null,
                                    text: this.toDateTime(element.lastValue.timestamp).toString(),
                                  },
                                ],
                            });
                        
                       
                            setTimeout(() => {
                                this.centerMapOnMarkers();
                            }, 1000)
                        
                    } else {
                        
                                this.sensorService.drillData({
                                    sensor: sensorId,
                                    from: this.fromDate,
                                    to: this.toDate,
                                }).subscribe((drilled) => {
                                console.log(Object.values(drilled[0]['results']));
                                const paths = Object.values(drilled[0]['results']);
                                paths.forEach((element) => {
                                  console.log(element);
                                  this.loadPath(element);
                              });
                            });
                       
                    }
                }           
            });
        }
    }

    public centerMapOnMarkers(): void {
        if (!this.markers.length) {
            return;
        }
        this.bounds = new google.maps.LatLngBounds();
        if (this.markers.length) {
            // let latSum = 0;
            // let lngSum = 0;
            this.markers.forEach((point: MapPointInterface): void => {
                // latSum += point.lat;
                // lngSum += point.lng;
                this.bounds.extend(
                    new google.maps.LatLng(point.lat, point.lng)
                );
            });
        }

        this.currentCenter.lat += this.markers[0].lat;
        this.currentCenter.lng += this.markers[0].lng;
        this.zoom += this.markers[0].zoom;

        this.zoom = 14;
    }

    private setCurrentPosition() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.currentCenter.lat = position.coords.latitude;
                this.currentCenter.lng = position.coords.longitude;
                this.zoom = 15;
            });
        }
    }

    public loadPath(paths) {
      this.paths = [];
      console.log(paths);
      this.paths = paths;
      if (this.paths) {
        const points: Array<MapPointInterface> = [];
        this.paths.forEach((element) => {
          const pt: MapPointInterface = {
            lat: element.value_coord.coordinates[1],
            lng: element.value_coord.coordinates[0],
          };
          points.push(pt);
          
          // Add a marker for each point
          this.markers.push({
            lat: pt.lat,
            lng: pt.lng,
            iconUrl: this.setMarkerIconColor('red'),
            infoWindowDetails: [
              {
                img: null,
                text: this.toDateTime(element.timestamp).toString(),
              },
            ],
          });
        });
    
        // Create a path for the polyline
        const path: google.maps.LatLngLiteral[] = points.map((point) => ({
          lat: point.lat,
          lng: point.lng,
        }));
    
        // Create and configure the polyline
        const mapPath: MapPolygonInterface = {
          points: path,
          fillColor: 'transparent',
          strokeColor: 'yellow',
          strokeWeight: 2,
          isDraggable: false,
          isEditable: false,
        };
    
        // Push the polyline to the mapPolygons array
        this.mapPolygons.push(mapPath);
    
        // Center the map on the markers
        this.centerMapOnMarkers();
      }
    }
    
    public setMarkerIconColor(markerColor: string): string {
        return (
            'http://maps.google.com/mapfiles/ms/icons/' +
            markerColor +
            '-dot.png'
        );
    }

    toDateTime(secs) {
      const t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(secs).toLocaleString('en-US');
      return moment(t).format('DD MMM yyyy, HH:mm');
  }
}
