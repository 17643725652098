import { Routes } from '@angular/router';

import { PeopleContainerComponent } from './containers/people-container/people-container.component';
import { EditPersonContainerComponent } from './containers/edit-person-container/edit-person-container.component';
import { AccessGuard } from '../../../core/guard/access.guard';

export const peopleRoutes: Routes = [
    {
        path:       '',
        component:  PeopleContainerComponent
    },
    {
        path: 'edit/:personId',
        component: EditPersonContainerComponent,
        data:{
            key:"people",
            index: 1
        },
        canActivate:[AccessGuard]
    },
    {
        path: 'add',
        component: EditPersonContainerComponent,
        data:{
            key:"people",
            index: 1
        },
        canActivate:[AccessGuard]
    },
];
