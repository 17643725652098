import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EquipmentModel } from '../../../../../core/models/equipment/equipment.model';
import { EquipmentService } from '../../../../../core/services/api/equipment/equipment.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';

@Component({
    selector: 'fap-equipment-paths',
    templateUrl: './fap_equipment-paths.component.html',
    styleUrls: ['./fap_equipment-paths.component.scss']
})
export class FapEquipmentPathsComponent implements OnChanges, OnDestroy {

    @Input()
    public equipment: EquipmentModel;
    public subscriptions: Array<Subscription> = [];
    public paths = [];

    constructor(public equipmentService: EquipmentService, public mapService:MapService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if (Object.prototype.hasOwnProperty.call(changes, 'equipment') && this.equipment) {
            this.subscriptions.push(this.equipmentService.getTracking({equipments: this.equipment.id}).subscribe(data => {
                this.paths = data.body.results;
                // this.paths.forEach(path => {
                //     console.log(path);
                //     let points = [];
                //     path.path.forEach(element => {
                //         let point = {
                //             lat: element.value_coord.coordinates[0],
                //             lng: element.value_coord.coordinates[1],
                //             zoom: element.value_coord.coordinates[2]
                //         }
                //         points.push(point);
                //         this.mapService.mapMarkers.push({
                //             lat: element.value_coord.coordinates[0],
                //             lng: element.value_coord.coordinates[1],
                //             iconUrl: this.mapService.setMarkerIconColor('red'),
                //             infoWindowDetails: [{
                //                 img: null,
                //                 text: moment(element.timestamp).format('DD MMM yyyy, HH:mm') 
                //             }]
                //         });
                //         this.mapService.mapPolylines.push({
                //             points: points,
                //             color: '#000'
                //         });
                //     });  
                // });
            }))
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        })
    }
}
