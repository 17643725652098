<div class="lot-card col-12 m-1" [ngStyle]="{'border': '1px solid' + navService.sidebarBackgroundColor}">
    <div class="lot-img-wrap col-4">
        <div class="lot-img" [ngStyle]="{'background-image':'url(' + preview + ')'}">
        </div>       
        <div class="lot-description-item surface text-center">
            <span class="item-text" style="font-size: 14px;">{{ surfaceArea | number:'1.'+globalRegistry.systemData.company.digits }} Ha</span>
        </div>
    </div>

    <!--    <h4 [routerLink]="'/pages/lot/edit/' + lot.id" class="lot-title pt-2">{{ inputTitle }}</h4>-->
    <div class="col-8">
        <div class="lot-description mb-1">
            <h6 [routerLink]="enableAddEdit?'/pages/lot/edit/' + lot?.id:[]" class="text-truncate">{{lot.name}}</h6>
        </div>
        <div class="d-flex align-items-center mb-1 mt_10">
            <p *ngIf="!currentCrop" class="card-text">{{ cropText | translate }}</p>
            <p *ngIf="currentCrop" class="card-text color-primary">{{ cropText | translate }}</p>
        </div>
        <div *ngIf="currentCrop" class="lot-description my-3">
<!--            <div *ngIf="currentCrop" class="lot-description-item w-100">-->
<!--                <span class="item-title">{{'crops.lot.startedOn' | translate }}: </span>-->
<!--                <span class="item-text"><span class="item-text">{{currentCrop.start}}</span></span>-->
<!--            </div>-->
            <div class="lot-description-item ">
                <fap-progress-bar color="#22AF47" [progress]="currentCrop.progress"></fap-progress-bar>
                <!-- <span class="item-text"><span class="item-text">{{currentCrop.progress}}</span></span> -->
            </div>
        </div>
<!--        <div class="d-flex align-items-center mb-3">-->
<!--            <div class="crop-circle">-->
<!--                <img src="/assets/images/default-crop-icon.png">-->
<!--            </div>-->
<!--            <p class="card-text">{{ cropText }}</p>-->
<!--        </div>-->
        <div *ngIf="currentCrop" class="crop-alerts-wrap d-flex justify-content-between mb-3">
            <div class="crop-alert d-flex align-items-center">
                <span class="crop-number crop-number-alert">2</span>
                <span class="ml-1">{{ 'crops.lot.alerts' | translate }}</span>
            </div>

            <div *ngIf="currentCrop"  class="crop-alert d-flex align-items-center">
                <span class="crop-number crop-number-activities">2</span>
                <span class="ml-1">{{ 'crops.lot.activities' | translate }}</span>
            </div>
        </div>

        <div *ngIf="currentCrop === null && enableAddEdit" class="lot-btn-wrap">
        <button [ngStyle]="{'background-color': navService.sidebarBackgroundColor}" class="text-truncate text-uppercase p-0" type="button"
                placement="top"
                ngbTooltip="btn btn-square btn-primary" [routerLink]="'/pages/lot/edit-culture/'+lot.id">
            start
        </button>
        </div>


        <!--        <div class="lot-btn-wrap">-->
<!--            <button *ngIf="!currentCrop; else editCrop" class="btn btn-primary text-truncate" type="button"-->
<!--                    placement="top"-->
<!--                    ngbTooltip="btn btn-square btn-primary" [routerLink]="'/pages/lot/edit-culture/'+lot.id">-->
<!--                {{ 'crops.lot.beginCrop' | translate }}-->
<!--            </button>-->

<!--            <ng-template #editCrop>-->
<!--                <button class="btn btn-success text-truncate" type="button" [routerLink]="currentRoute">-->
<!--                    {{ 'crops.lot.editCrop' | translate }}-->
<!--                </button>-->
<!--            </ng-template>-->
<!--            <button class="btn btn-danger" (click)="deleteLot.emit(lot.id)">-->
<!--                {{ 'farm.lot.deleteLot' | translate }}-->
<!--            </button>-->
<!--        </div>-->

    </div>
</div>
