import { Component, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSelect } from '@angular/material/select';
import { SensorService } from '../../../../core/services/api/sensor/sensor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonTypes } from '../fap-round-button/round-buton.enum';

@Component({
  selector: 'fap-tree-combo',
  templateUrl: './fap-tree-combo.component.html',
  styleUrls: ['./fap-tree-combo.component.scss']
})
export class FapTreeComboComponent {
  public lotGroup = [];

  public toggleAllItems: boolean = false;

  modelGroup: any[]; // the selected values

  @ViewChild('matSelect') matSelect: MatSelect;

  public ButtonTypes = ButtonTypes
  
  constructor(public globalRegistry: GlobalRegistryService, private translate: TranslateService, public sensorService: SensorService, private activatedRoute: ActivatedRoute, public router: Router) {
    this.lotGroup = this.globalRegistry.systemData.farms.map(farm => ({
      ...farm,
      lots: this.globalRegistry.systemData.lots.filter(lot => lot.farm === farm.id)
  }));
  console.log(this.lotGroup);
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const lotIds: number[] = [];
      params.keys.forEach(key => {
        if (key === 'lots') {
          const paramValue = params.getAll(key);
          paramValue.forEach(value => {
            lotIds.push(+value);
          });
        }
      });
      this.modelGroup = lotIds;
    });
  }

  selectAll(select: NgModel) {
    let lots: any[] = []; 
    for(let group of this.lotGroup){
      for(let lot of group.lots){
        lots.push(lot.id);
      }
    }

    select.update.emit(lots);
  }

  deselectAll(select: NgModel) {
    select.update.emit([]);
  }

  test() {
    console.log(this.modelGroup)
  }

  toggleAll(itemSelect) {
    this.toggleAllItems = !this.toggleAllItems;
    if(this.toggleAllItems) {
      this.selectAll(itemSelect);
    } else {
      this.deselectAll(itemSelect);
    }
  }

  getSelectedLotsDisplay(): string {
    const selectedLots = this.modelGroup ? this.modelGroup.length : 0;
    if (selectedLots === 0) {
        const placeholder = this.translate.instant('leftMenu.farms');
        return `<span class="mild_opacity">${placeholder}</span>`;
    } else if (selectedLots === 1) {
        return this.getLotNameById(this.modelGroup[0]);
    } else {
        const firstLotName = this.getLotNameById(this.modelGroup[0]);
        const remainingLotsCount = selectedLots - 1;
        const othersText = remainingLotsCount === 1 ? 'other' : 'others';
        return `${firstLotName} (+${remainingLotsCount} ${othersText})`;
    }
  }

  getLotNameById(lotId: any): string {
    for (const group of this.lotGroup) {
      const foundLot = group.lots.find(lot => lot.id === lotId);
      if (foundLot) {
        return foundLot.name;
      }
    }
    return '';
  }

  updateUrl() {
    console.log(this.modelGroup);
    const originalParams = {};
    const queryParams: {} = Object.assign({}, originalParams);
    const farmIds = this.modelGroup.map(lotId => {
      const lot = this.globalRegistry.systemData.lots.find(lot => lot.id === lotId);
      return lot ? lot.farm : null;
  });
  const uniqueFarmIds: number[] = [...new Set(farmIds.filter(id => id !== null))]; 
  console.log(uniqueFarmIds);
    let farms: number[] = uniqueFarmIds;
    let lots: number[] = this.modelGroup;
    queryParams["farms"] = farms && farms.length > 0 ? farms : undefined;
    queryParams["lots"] = lots && lots.length > 0 ? lots : undefined;
    console.log(queryParams);
    this.sensorService.setLots(queryParams['lots']);
    this.sensorService.setFarms(queryParams['farms']);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge'
    });
    localStorage.setItem("queryParams",JSON.stringify(queryParams));
    
    this.matSelect.close();
  }

  navigateToEntity(entity: string, entityId: number) {
    this.router.navigate(['pages/'+entity+'/edit/'+entityId]);
  }

  deleteFarm(farmId: number) {
    console.warn("farm deletion not implemented yet")
  }

  deleteLot(lotId: number) {
    console.warn("lot deletion not implemented yet")
  }
}
