export enum AttributeEntityEnum {
    Farm = 'farm',
    Lot = 'lot',
    Field = 'field',
    Equipment = 'equipment',
    Person = 'person',
    Note = 'note',
    Crop = 'crop',
    Party = 'party',
    StockItem = 'stock_item',
    Activity = 'activity'
}
