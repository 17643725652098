import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { WidgetHelper } from '../../../../../core/heplers/widget.helper';
import { WidgetDataTypesEnum } from '../../../../../core/enums/widget-data-types.enum';
import { SensorModel } from '../../../../../core/models/sensor/sensor.model';

@Component({
    selector: 'fap-configure-widget',
    templateUrl: './fap_configure-widget.component.html',
    styleUrls: ['./fap_configure-widget.component.scss']
})
export class FapConfigureWidgetComponent implements OnChanges {

    @Input()
    public selectedWidget: string;

    @Input()
    public sensors: Array<SensorModel> = [];

    @Output()
    public configurationChanged: EventEmitter<{}> = new EventEmitter();

    public configuration: {} = {};
    public widgetDataType: string;

    public selectedSensor: number;

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'selectedWidget') && this.selectedWidget) {
            this.widgetDataType = WidgetHelper.getWidgetDataType(this.selectedWidget);
            if (this.widgetDataType === WidgetDataTypesEnum.Sensor) {
                this.configuration = { sensor: this.selectedSensor};
            }
            else {
                this.configuration = {};
            }
            this.configurationChanged.emit(this.configuration);
        }
        if(Object.prototype.hasOwnProperty.call(changes, 'sensors') && this.sensors && this.sensors.length) {
            this.selectedSensor = this.sensors[0].id;
        }
    }

    public changedSensor(): void {
        this.configuration = { sensor: this.selectedSensor };
        this.configurationChanged.emit(this.configuration);
    }

    public reset(): void {
        this.selectedWidget = null;
        this.configuration = {};
        this.configurationChanged.emit(null);
    }
}
