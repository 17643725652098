import { Routes } from '@angular/router';

import { WeedsContainerComponent } from './containers/weeds-container/weeds-container.component';
import { WeedsEditContainerComponent } from './containers/weeds-edit-container/weeds-edit-container.component';


export const weedsRoutes: Routes = [
    {
        path:       '',
        component:  WeedsContainerComponent
    },
    {
        path:       'edit',
        component:  WeedsEditContainerComponent
    }
];
