<fap-header>
    <fap-header-actions [title]="'farm.lot.plantation' | translate" [isDeleteEnabled]="false"></fap-header-actions>
</fap-header>
<div class="container-fluid p-0">
    <div class="card mx-auto">
        <div class="card-header" style="padding: 5px 15px; border-radius: 0;">
            <p style="font-size: 18px; margin: 0; color: white; padding: 0.5rem;">Plantation Data: {{ lot?.name }}</p>
        </div>
        <div class="card-body mb-5" style="padding: 15px">
            <fap-culture-edit
                *ngIf="!isNoCrop"
                [crop]="lot?.currentCrop"
                [farmId]="lot ? lot.farm : null"
                [showMarkUnmarkAsEndedBtn]="true"
                [showDeleteCultureBtn]="true"
                [farms]="globalRegistry.systemData.farms"
                [parties]="globalRegistry.systemData.parties"
                [resourceTypes]="globalRegistry.systemData.resourceTypes"
                (cropChanged)="updateCulture($event)"
                (addStockIncome)="addStockIncome($event)"
                (addStockOutcome)="addStockOutcome($event)"
                (deletedCulture)="deleteCulture()"
                (updatedDoc)="getExistingCultureData($event)"
                (deleteParty)="deleteParty($event)">
            </fap-culture-edit>
            <div *ngIf="isNoCrop" class="mt-5">
                <div class="row center mb-2">{{ 'farm.crop.noCultureStarted' | translate }}</div>
                <div class="row center">
                    <button class="btn btn-success px-2" type="button" (click)="startNewCulture()">
                        {{ 'farm.crop.startNewCulture' | translate }}<i class="material-icons pl-1">add</i>
                    </button>
                </div>
            </div>
        </div>
        <!-- <div class="card-footer d-flex justify-content-center">
            <button class="btn btn-success" type="button"
                [disabled]="(crop.cropType === null || crop.start === null) && !isNoCrop" (click)="submit()">{{ 'save' | translate }}
                <i class="material-icons pl-1">send</i>
            </button>
        </div> -->
    </div>
</div>
