<div class="">
    <fap-home-layout
        class="w-100"
        [userData]="globalRegistry.userData"
        [widgets]="filteredWidgets"
        [sensors]="sensors"
        [farms]="globalRegistry.systemData.farms"
        [lots]="globalRegistry.systemData.lots"
        (addWidget)="addWidget($event)"
        (deleteWidget)="deleteWidget($event)">
    </fap-home-layout>
</div>
