<form [formGroup]="addEditUnitForm" class="col-md-12 p-0">
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.name' | translate }}</mat-label>
            <input matInput formControlName="name">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('name','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.shortName' | translate }}</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('shortName','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'units.genre' | translate }}</mat-label>
            <!-- <input matInput formControlName="genre"> -->
            <mat-select required formControlName="genre">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #genreFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                <mat-option style="height: 0!important;"></mat-option>
                <mat-option *ngFor="let genre of this.globalRegistry.genres | filter : genreFilter.value" [value]="genre?.indicator">
                    <span>{{ genre?.name }} ( <b>{{genre?.indicator}}</b> )</span>
                </mat-option>
            </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('genre','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-group" *ngIf="units">
        <mat-form-field class="w-100">
            <mat-label class="col-form-label">{{ 'stock.parent' | translate }}</mat-label>
            <mat-select required formControlName="parent" [disabled]="!parentUnit && selectedUnit">
                <mat-form-field appearance="fill" class="w-100">
                    <!-- <mat-label>
                        <i class="fa fa-search f-20"></i>
                    </mat-label> -->
                    <input matInput #unitFilter placeholder="Search">
                </mat-form-field>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                <mat-option [value]="'new'" class="font-weight-bold">
                    {{'units.newParent' | translate}}
                </mat-option>
                <mat-option *ngFor="let parent of globalRegistry?.systemData?.units | filter : unitFilter.value" [value]="parent.id">
                    {{ parent.name }}
                </mat-option>
                </div>
            </mat-select>
            <mat-error *ngIf="ifAddEditUnitsFormControlHasError('parent','required')">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="w-100 mt-3 d-flex justify-content-end">
        <button class="btn mr-2" type="button" (click)="submit()">{{'translations.save' | translate}}</button>
        <button class="btn" type="button" (click)="cancel.emit()">{{'translations.cancel' | translate}}</button>
    </div>
</form>
