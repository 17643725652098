<div class="custom_modal" id="docs_modal">
    <div class="docs">
        <div class="search_filters">
            <form class ="doc_search" [formGroup]="docSearchForm" [ngClass]="status ? 'show' : ''">
                <div class="form-group m-0">
                    <div class="mt-2 d-flex align-items-baseline">
                        <div class="col-10 p-0">
                            <div class="row search_form">
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label class="col-form-label">{{ 'stock.search' | translate }}</mat-label>
                                            <input type="text" matInput formControlName="search">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'stock.type' | translate }}</mat-label>
                                            <mat-select formControlName="type">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <input matInput #docTypeFilter placeholder="Search">
                                                </mat-form-field>
                                                <div class="scroll_wrap">
                                                    <mat-option [value]="null"></mat-option>
                                                <mat-option *ngFor="let type of documentTypes | filter : docTypeFilter.value" [value]="type.key">
                                                    {{type.value}}
                                                </mat-option>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="input-group align-items-baseline w-100">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{ 'stock.farm' | translate }}</mat-label>
                                            <mat-select formControlName="farm">
                                                <mat-form-field appearance="fill" class="w-100">
                                                    <input matInput #farmFilter placeholder="Search">
                                                </mat-form-field>
                                                <div class="scroll_wrap">
                                                    <mat-option [value]="null"></mat-option>
                                                <mat-option *ngFor="let farm of globalRegistry?.systemData?.farms | filter : farmFilter.value" [value]="farm.id">
                                                    {{farm.name}}
                                                </mat-option>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="w-100" (click)="from.open()">
                                        <mat-label>
                                            {{ 'From' }}</mat-label>
                                        <input matInput readonly formControlName="from" [matDatepicker]="from">
                                        <mat-datepicker-toggle matSuffix [for]="from">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #from></mat-datepicker>
                                        <mat-error *ngIf="ifControlHasError('from','required')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field class="w-100" (click)="to.open()">
                                        <mat-label>
                                            {{ 'To' }}</mat-label>
                                        <input matInput readonly formControlName="to" [matDatepicker]="to">
                                        <mat-datepicker-toggle matSuffix [for]="to">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #to></mat-datepicker>
                                        <mat-error *ngIf="ifControlHasError('to','required')">
                                            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 p-0">
                            <div class="text-center">
                                <button class="btn btn-lg" type="button" (click)="filterDocs()">
                                    <img src="../../../../../../assets/images/search.png" alt="search">
                                </button>
                                <button type="button" class="refresh" (click)="clearDocInputs()">
                                    <img src="../../../../../../assets/images/filter.png" alt="">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <span class="toggle_filters" (click)="toggleDocFilter()">Toggle filters <img src="../../../../../../assets/images/setting.png"></span>
        </div>
        <div infiniteScroll
            [infiniteScrollDistance]="4"
            [infiniteScrollThrottle]="0"
            (scrolled)="scrolledDown()"
            [scrollWindow]="false"
            #documentTable
            (scroll)="onDivScroll('documentTable', $event)"
            style="height: calc(90vh - 150px); flex: auto; overflow-y: auto;"
            >
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Doc ID</th>
                            <th>Party From</th>
                            <th>Party To</th>
                            <th>Farm</th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let doc of docs">
                            <td>{{doc.docDate | date: "MMM dd yyyy"}}</td>
                            <td style="text-transform: capitalize;">{{doc.type.replace('_', ' ')}}</td>
                            <td (click)="goToBill(doc.id, doc.type)"><a class="link">{{doc.docId}}</a></td>
                            <td>
                                {{doc?.partyFrom?.name}}
                            </td>
                            <td>
                                {{doc?.partyTo?.name}}
                            </td>
                            <td>
                                <ng-container *ngFor="let farm of globalRegistry.systemData.farms">
                                    <ng-container *ngIf="farm.id == doc.farm">{{farm?.name}}</ng-container>
                                </ng-container>
                            </td>
                            <!-- <td>
                                <div class="btns">
                                    <button class="btn-white-bg" (click)="exportAsPDF(doc)" title="print PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>
                                    <button class="btn-white-bg" (click)="exportAsExcel(doc)" title="download as Excel"><i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>        
</div>