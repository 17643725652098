import { NameValueInterface } from '../interfaces/general/name-value.interface';

export class FapStaticDataService {

    public static equipmentPoweredTypesStaticData(): NameValueInterface[] {
        return [
            {
                id: 'diesel',
                name: 'equipment.equipmentTypes.dieselType'
            },
            {
                id: 'electric',
                name: 'equipment.equipmentTypes.electricType'
            },
            {
                id: 'gas',
                name: 'equipment.equipmentTypes.gasType'
            },
            {
                id: 'hybrid',
                name: 'equipment.equipmentTypes.hybridType'
            }
        ];
    }
}
