<div class="people_container">
  <!-- <div style="height: 10px; width: 100%"></div> -->
  <ng-template ngFor [ngForOf]="persons" let-person>
    <div class="person-list">
      <fap-user-card
        [person]="person"
        [crops]="crops"
        (removePerson)="showRemovePersonModal(person)"
        [enableAddEdit]="enableAddEdit"
        [enableDelete]="enableDelete"
      >
      </fap-user-card>
    </div>
  </ng-template>
</div>
<fap-modal
  #removePersonModal
  [inputPrimaryButtonInterface]="removePersonModalButtonPrimaryInterface"
  [inputSecondaryButtonInterface]="removePersonModalButtonSecondaryInterface"
  inputTitle="{{ 'people.removePerson' | translate }}"
>
  <span class="remove-person-text">
    {{ "people.areYouSureYouWantToRemove" | translate }}
    <b>{{ selectedPerson?.firstName + " " + selectedPerson?.lastName }} {{ selectedPerson?.first_name + " " + selectedPerson?.last_name }}</b>
  </span>
</fap-modal>
