<div class="cell_cost_quantity">
    <div class="value_unit" [ngClass]="{'small_font': smallFont }">
        <div [ngClass]="color" style="clear: both;">
            <div class="value" [ngClass]="bold ? 'bold' : ''">{{ value }}</div>
            <div class="unit">
                <span>{{ unit }}</span>
            </div>
        </div>
    </div>
</div>
