import { FapBaseModel } from '../../base/fap-base-model';


export class AddressModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'type',
        'street',
        'number',
        'building',
        'appt',
        'city',
        'county',
        'postal_code',
        'country',
        'email',
        'website',
        'phone_mobile',
        'phone_fix',
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public type: string;
    public street: string;
    public number: string;
    public building: string;
    public appt: string;
    public city: string;
    public county: string;
    public postal_code: string;
    public country: string;
    public email: string;
    public website: string;
    public phone_mobile: string;
    public phone_fix: string;
}
