import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { FieldModel } from '../../../../../core/models/field/field.model';
import { PartyModel } from '../../../../../core/models/party/party.model';

@Component({
    selector: 'fap-field-card',
    templateUrl: './fap_field-card.component.html',
    styleUrls: ['./fap_field-card.component.scss']
})
export class FapFieldCardComponent implements OnChanges {

    @Input()
    public field: FieldModel;

    @Input()
    public parties: Array<PartyModel>;

    @Output()
    public deleteField: EventEmitter<number> = new EventEmitter();

    public fieldOwnerName: string;

    constructor(public router:Router, public globalRegistry: GlobalRegistryService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'owners') || Object.prototype.hasOwnProperty.call(changes, 'field')) {
            if (this.parties && this.field) {
                const selectedParty: PartyModel = this.parties.find((party: PartyModel) => party.id === this.field.owner);
                this.fieldOwnerName = selectedParty ? selectedParty.name : null;
            }
        }
    }

    public edit(id) {
        this.router.navigate(['/pages/fields/edit/' + id]);
    }

}
