<div [formGroup]="selectFormGroup" class="add-edit-delete-select">
    <div class="form-group w-100">
    <mat-form-field class="w-100">
        <mat-label class="col-form-label">{{ inputName }}</mat-label>
        <mat-select #aeds panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()"  formControlName="value" [required]="inputIsRequired" (infiniteScroll)="scrolledDown()" [alwaysCallback]="true" msInfiniteScroll [infiniteScrollDistance]="4" (selectionChange)="selectionChange.emit($event.value)" [multiple]="multiple"
        [infiniteScrollThrottle]="500">
            <div class="d-flex" style="align-items: center; padding: 0 10px;">
            <mat-form-field class="w-100" appearance="fill">
                <input matInput (keydown.enter)="searchKeyword(selectFilter.value); $event.stopPropagation()" #selectFilter placeholder="Search">
            </mat-form-field>
            <mat-label *ngIf="apiSearchEnabled" style="position: absolute; cursor: pointer;" (click)="searchKeyword(selectFilter.value)" [style.right]="createEnabled ? '80px' : '35px'">
                <i class="fa fa-search f-20"></i>
            </mat-label>
            <!-- <i class="fa fa-plus center f-20 ml-2"
           (click)="outputOnCreate.emit(selectFilterValue);$event.stopPropagation()" alt='Create new equipment type' [ngClass]="{'preview': previewMode}"></i> -->
           <fap-round-button [backgroundColor]="'tertiary'" (clicked)="outputOnCreate.emit(selectFilterValue)" *ngIf="createEnabled"></fap-round-button>
        </div>
        <div class="scroll_wrap" #fapaeds (scroll)="onScroll($event)">
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option *ngFor="let val of inputSelectValues | filter : selectFilter.value" [value]="val[inputValueFieldName]" (click)="clicked.emit(val)">
                <div class="name t">
                    <span *ngIf="val[inputNameTFieldName]">{{getTranslation(val[inputNameTFieldName])}}</span>
                    <span *ngIf="!val[inputNameTFieldName] || val[inputNameTFieldName] == null || val[inputNameTFieldName] === ''">{{ val[inputNameFieldName] | translate }}</span>
                </div>
                <div *ngIf="!val[inputReadOnlyFieldName]" class="actions">
                        <action-buttons 
                            [locked]="val?.locked" 
                            (editEmit)="outputOnEdit.emit(val)" 
                            (deleteEmit)="clickedValue=val;deleteValueModal.showModal()"
                            [isEditable]="isEditable"
                            [isDeleteable]="isDeleteable"
                        ></action-buttons>
                </div>
            </mat-option>
        </div>
        </mat-select>
        
        <mat-error *ngIf="selectFormGroup.controls['value'].errors?.required">
            <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
        </mat-error>
        <button class="clear" matSuffix mat-icon-button aria-label="Clear" (click)="clearValue($event)" *ngIf="selectFormGroup.get('value').value">
            <mat-icon>close</mat-icon>
          </button>
    </mat-form-field>
    <!-- <img class="editor-pen" src="./assets/images/fap/edit_pencil.svg"
           (click)="outputOnCreate.emit(selectFilterValue)" alt='Create new equipment type' [ngClass]="{'preview': previewMode}"/> -->
</div>
</div>

<fap-modal #deleteValueModal
    inputTitle="{{ 'confirmDelete' | translate }}"
    [inputPrimaryButtonInterface]="deleteModalPrimaryButtonInterface"
    [inputSecondaryButtonInterface]="deleteModalSecondaryButtonInterface">
    {{ inputConfirmDeleteText }}
</fap-modal>
