<div class="field-card">
    <div class="field-card-img col-4">
        <div class="img" [ngStyle]="{'background-image':'url(' + field.previewImg + ')'}"></div>
        <p class="field-description-item surface text-center"><b>{{ field.area?.value }} 
            <ng-container *ngFor="let unit of globalRegistry.systemData.units">
                <ng-container *ngIf="unit?.id === field?.area?.unit">{{unit?.shortName}}</ng-container>
                <ng-container *ngIf="unit.children">
                    <ng-container *ngFor="let child of unit.children">
                        <ng-container *ngIf="child.id === field?.area?.unit">{{child?.shortName}}</ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </b></p>
    </div>
    <div class="description col-8">
        <h5 class="card-text mb-1" (click)="edit(field.id)">
            <b>{{ fieldOwnerName ? fieldOwnerName : ( 'farm.field.noOwner'  | translate ) }}</b>
        </h5>
        <p class="card-title"><i>{{ field.name }}</i></p>
        <!-- <p class="field-area-on-map mb-1">
            <b>{{ field.area?.value }} <span>{{ field.area?.unitShortName }}</span></b>
        </p> -->
        <!-- <div class="field-btn-wrap mt-3">
            <button class="btn btn-success" [routerLink]="'/pages/fields/edit/' + field.id">
                {{ 'farm.field.editField' | translate }}
            </button>
            <button class="btn btn-danger" (click)="deleteField.emit(field.id)">
                {{ 'farm.field.deleteField' | translate }}
            </button>
        </div> -->
    </div>
</div>
