import {
    Router,
    CanActivate
} from '@angular/router';
import {
    Observable,
    Observer
} from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '../services/api/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _authService: AuthService) {}

    public canActivate(): Observable<boolean> {
        return Observable.create((o: Observer<boolean>) => {
            this._authService.keepAlive().subscribe(
                () => {
                    o.next(true);
                    o.complete();
                },
                () => {
                    o.next(false);
                    o.complete();
                    this._router.navigate(['login']);
                }
            );
            return;
        });
    }
}
