<div id="parent">
<div class="page-wrapper" *ngIf="data$ | async as data">
  <div class="auth-bg" *ngIf="!passwordChanged">
    <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
      <div class="authentication-box" *ngIf="!isPhoneVerificationEnabled">
        <div class="card mt-4 p-4">
          <h4 class="text-center">{{'auth.confirmAccount.passwordLabel' | translate}}</h4>
          <h6 class="text-center">{{'auth.confirmAccount.requiredFieldsLabel' | translate}}</h6>
          <form class="theme-form" [formGroup]="confirmAccountForm" autocomplete="off">
            <div class="form-group">
              <mat-form-field class="w-100" cdkFocusInitial>
                  <mat-label class="col-form-label">Password</mat-label>
                  <input autofocus class="pwd" matInput formControlName="password" [type]="show ? 'text' : 'password'" autocomplete="new-password" name="password">
                  <span [ngClass]="show ? 'fa-eye-slash' : 'fa-eye'" class="form-control-feedback fa fa-eye" value="" (click)="togglePassword()"></span>
                  <mat-error *ngIf="ifControlHasError('password','hasNumber')">
                    <strong>{{ 'auth.validation.passwordHasNumber' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('password','hasCapitalCase')">
                    <strong>{{ 'auth.validation.passwordHasCapitalCase' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('password','hasSmallCase')">
                    <strong>{{ 'auth.validation.passwordHasSmallCase' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('password','hasSpecialCharacters')">
                    <strong>{{ 'auth.validation.passwordHasSpecialCharacters' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('password','minlength')">
                    <strong>{{ 'auth.validation.minLengthField' | translate }} 8</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">Confirm password</mat-label>
                  <input class="pwd" matInput formControlName="confirmPassword" [type]="show1 ? 'text' : 'password'" autocomplete="new-password">
                  <span [ngClass]="show1 ? 'fa-eye-slash' : 'fa-eye'" class="form-control-feedback fa fa-eye" value="" (click)="togglePassword1()"></span>
                  <mat-error *ngIf="ifControlHasError('confirmPassword','confirmPassword')">
                    <strong>{{ 'auth.validation.matchPassword' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
          <div class="form-row">
              <div class="col-sm-12">
                  <button class="btn btn-success" type="submit" (click)="submit()">{{ 'submit' | translate }}</button>
              </div>
              <!-- <div class="col-sm-8">
                  <div class="text-left mt-2 m-l-20" *ngIf="">{{ 'auth.register.alreadyUser' | translate }}  <button class="btn-link text-capitalize" (click)="router.navigateByUrl('/login')">{{ 'auth.login.registerLabel' | translate }}</button></div>
              </div> -->
          </div>
          <!-- <div class="form-divider"></div>
            <div class="social mt-3">
              <div class="form-group btn-showcase d-flex">
                <button class="btn social-btn btn-fb d-inline-block"> <i class="fa fa-facebook"></i></button>
                <button class="btn social-btn btn-twitter d-inline-block"><i class="fa fa-google"></i></button>
                <button class="btn social-btn btn-google d-inline-block"><i class="fa fa-twitter"></i></button>
                <button class="btn social-btn btn-github d-inline-block"><i class="fa fa-github"></i></button>
              </div>
            </div> -->
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="passwordChanged" class="password_updated">
      <img src="../../../../../../assets/icons/check.png" alt="check">
      <div class="text">
        <h5>{{'auth.passwordUpdated' | translate}}</h5>
        <p *ngIf="link === ''">Go to login</p>
        <p *ngIf="link !== ''"><span (click)="router.navigateByUrl('/login')">click here to login</span></p>
      </div>
    </div>
</div>

<div class="custom_modal" id="custom_modal400">
  <div class="modal_header">
      <h2>{{"auth.enterPhoneNumber" | translate}}</h2>
      <!-- <button type="button" class="btn close-modal" (click)="hideProductionModal()"><i class="fa fa-times"></i></button> -->
  </div>
  <form [formGroup]="phoneForm">
      <div class="form-group">
          <div class="row align-items-baseline">
            <div class="col-4">
              <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{"auth.countryCode" | translate}}</mat-label>
                    <mat-select formControlName="countryCode" style="text-align: right;" (selectionChange)="checkCode($event.value)">
                        <mat-form-field appearance="fill" class="w-100">
                        <input matInput #country placeholder="Search"/>
                        </mat-form-field>
                        <div class="scroll_wrap">
                                <div class="slct">
                                  <mat-option style="height:0;"></mat-option>
                                    <mat-option *ngFor="let timezone of data | filter : country.value" [value]="timezone?.CountryCallingCode" style="text-transform: capitalize">
                                        <div class="type-name ddd" [attr.name]="timezone.Country +' ('+ timezone?.Region + ')'"><b style="width: 50px; display: inline-block;">{{ '+'+timezone?.CountryCallingCode }}</b></div>
                                    </mat-option>
                                </div>
                           
                        </div>
                    </mat-select>
                  </mat-form-field>
          </div>
        </div>
          <div class="col-8">
            <div class="input-group w-100">
              <mat-form-field cdkFocusInitial>
                  <mat-label>{{"people.phone" | translate}}</mat-label>
                  <input type="number" matInput ngDefaultControl formControlName="phone" autofocus>
              </mat-form-field>
          </div>
          </div>
          </div>
          <div class="row">
              <div class="col">
                  <button class="btn" (click)="getPin()">{{"notes.submit" | translate}}</button>
              </div>
          </div>
      </div>
  </form>
</div>

<div class="custom_modal" id="custom_modal401">
  <div class="modal_header">
      <h2>{{"auth.enterPin" | translate}}</h2>
      <!-- <button type="button" class="btn close-modal" (click)="hideProductionModal()"><i class="fa fa-times"></i></button> -->
  </div>
  <form [formGroup]="OTPForm">
    <div class="cc">
      <p>{{"auth.checkSMS" | translate}} {{phoneNumber}}</p>
      <div class="counter">{{counter | timer}}</div>
    </div>
      <div class="form-group otp">
          <div class="align-items-baseline">
            <div class="input-group w-100">
              <mat-form-field cdkFocusInitial>
                  <mat-label>{{"auth.pin" | translate}}</mat-label>
                  <input type="number" matInput formControlName="pin" name="pin" autofocus>
              </mat-form-field>
          </div>
          </div>
          <div class="row">
              <div class="col">
                  <button class="btn" (click)="confirmPhonePin()">{{"notes.submit" | translate}}</button>
              </div>
          </div>
      </div>
  </form>
</div>
</div>