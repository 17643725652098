<span *ngIf="isLoading" class="loader">
    <i></i>
</span>
<ng-container *ngFor="let actuator of actuators">
  <div class="cards-grid">
    <ng-container *ngIf="getDeviceInfo(actuator?.id) as device">
      <scheduler-card
        [actuator]="actuator"
        [device]="device"
        [sensor]="getSensorInfo(actuator?.id)"
        [schedulers]="schedulers"
        (deleteTrigger)="deleteTrigger($event)"
      ></scheduler-card>
    </ng-container>
  </div>
</ng-container>