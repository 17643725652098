import { Component, OnDestroy, OnInit } from '@angular/core';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';

@Component({
  selector: 'simulations-container',
  templateUrl: './simulations-container.component.html',
  styleUrls: ['./simulations-container.component.scss']
})
export class SimulationsContainerComponent implements OnInit, OnDestroy {

  constructor(private mapService: MapService, private widgetService: WidgetsService) { }

  ngOnInit(): void {
    this.widgetService.setSize(12);
    this.mapService.hideMap();
  }

  public deleteSimulation(id) {
    console.log(id);
  }

  ngOnDestroy(): void {
    this.widgetService.setSize(6);
  }

}
