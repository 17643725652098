import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import { NgxPermissionsModule } from 'ngx-permissions';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { SharedModule } from '../../../shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PartialsModule } from '../../views-partials/partials.module';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule,NgxNativeDateModule } from '@angular-material-components/datetime-picker'
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PlanComponent } from './plan.component';
import { PlanLayoutComponent } from './components/plan-layout/plan-layout.component';
import { PlanContainerComponent } from './containers/plan-container/plan-container.component';
// import { NgGanttEditorModule } from 'ng-gantt' 

@NgModule({
    imports:[
        MatTableModule,
        RouterModule,
        SharedModule,
        CommonModule,
        FormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatButtonModule,
        InfiniteScrollModule,
    ],
    declarations:[
        PlanComponent,
        PlanContainerComponent,
        PlanLayoutComponent
    ]
})

export class PlanModule {

}