import { Component, Input } from '@angular/core';
import { StockItemModel } from '../../../../../core/models/stock/stock-item.model';


@Component({
    selector: 'fap-stock-list',
    templateUrl: './fap_stock-list.component.html',
    styleUrls: ['./fap_stock-list.component.scss']
})
export class FapStockListComponent {

    @Input()
    public stockItems: Array<StockItemModel>;

}
