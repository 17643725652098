import { CropTypeIngredientService } from '../../../../core/services/api/crop/crop-type-ingredient.service';
import { IngredientService } from '../../../../core/services/api/ingredient/ingredient.service';
import { IngredientInterface } from '../../../../core/interfaces/ingredient/ingredient.interface';
import { IngredientModel } from '../../../../core/models/stock/ingredient.model';
import { ToastrService } from 'ngx-toastr';
import { IngredientTypeModel } from '../../../../core/models/type/ingredient-type.model';
import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    OnInit
} from '@angular/core';
import { FapModalComponent } from '../../../../shared/partials/components/fap-modal/fap-modal.component';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators
} from '@angular/forms';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { TypesService } from '../../../../core/services/api/types/types.service';
import { TranslateService } from '@ngx-translate/core';
import { ResponseModel } from '../../../../core/models/response.model';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';
import { UnitModel } from '../../../../core/models/units/unit.model';
import { ConfirmModalService } from '../../../../shared/services/confirm-modal.service.ts/confirm-modal.service';
import { UnitInterface } from '../../../../core/interfaces/unit/unit.interface';
import { UnitService } from '../../../../core/services/api/units/unit.service';
import { StockService } from '../../../../core/services/api/stock/stock.service';

@Component({
    selector: 'fap-add-edit-ingredient',
    templateUrl: './fap_add-edit-ingredient.component.html',
    styleUrls: ['./fap_add-edit-ingredient.component.scss']
})
export class FapAddEditIngredientComponent implements OnChanges, OnInit {

    @Input()
    public ingredient: IngredientModel;

    @Input()
    public farmId: number;

    @Input()
    public context?: string;

    @ViewChild('addIngredientTypeModal')
    public addIngredientTypeModal: FapModalComponent;

    @ViewChild('mys') mys;

    @Output()
    public createdIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public updatedIngredient: EventEmitter<IngredientModel> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    public ingredientForm: UntypedFormGroup;
    public selectedIndredientType: IngredientTypeModel;

    public translatedNames:any = [];
    public langString: string;
    public subscription: Subscription = new Subscription();
    public unitForm: UntypedFormGroup;
    public units: Array<UnitModel> = [];
    public selectedUnit: UnitModel;
    public currentUnit: UnitModel;
    public parentUnit: UnitModel;
    public selectedUnitId: number;
    @ViewChild('addEditUnitModal')
    public addEditUnitModal: FapModalComponent;
    public closeCombo: boolean = true;

    constructor(public formBuilder: UntypedFormBuilder,
                private typesService: TypesService,
                private toastyService: ToastrService,
                public translate: TranslateService,
                public ingredientService: IngredientService,
                public cropTypeIngredientService: CropTypeIngredientService,
                public globalRegistry: GlobalRegistryService,
                public navService: NavService,
                public confirmModalService: ConfirmModalService,
                public unitService: UnitService,
                public stockService: StockService
                ) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.langString = localStorage.getItem('language');
        this.subscription.add(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
        }));
        this.translatedNames = this.globalRegistry.systemData.translations;
        this.units = this.globalRegistry.systemData.units;
        this.initunitForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'ingredient')) {
            this.initForm();
        }
    }

    public addIngredientType(ingredientType?: IngredientTypeModel): void {
        this.selectedIndredientType = ingredientType;
        console.log(ingredientType);
        this.addIngredientTypeModal.showModal();
    }

    public initForm(): void {
        this.ingredientForm = this.formBuilder.group({
            ingredientType: [this.ingredient ? this.ingredient.type : '', Validators.compose([Validators.required])],
            ingredientQuantity: [this.ingredient ? this.ingredient.quantity : '', Validators.compose([Validators.required])],
            ingredientUnit: [this.ingredient ? this.ingredient.unit ? this.ingredient.unit.id : '' : '', Validators.compose([Validators.required])],
            ingredientSubType: [this.ingredient ? this.ingredient['sub_type'] : ''],
            // ingredinentValue: [this.ingredient ? this.ingredient.value : '', Validators.compose([Validators.required])],
            ingredientMinValue: [this.ingredient ? this.ingredient.min : ''],
            ingredientMaxValue: [this.ingredient ? this.ingredient.max : ''],
            ingredientDescription: [this.ingredient ? this.ingredient.info : ''],
        });
        this.ingredientForm.valueChanges.subscribe((): void => {
            // this.emitCultureChanged();
        });
    }

    public updatedIngredients(ev) {
        this.ingredientForm.get('ingredientType').setValue(ev.id);
        this.addIngredientTypeModal.hideModal();
        this.mys.close();
        this.globalRegistry.reloadIngredients();
        // this.globalRegistry.reloadIngredientsTypes()
    }

    public createdIngredientType(ev) {
        this.ingredientForm.get('ingredientType').setValue(ev.id);
        this.addIngredientTypeModal.hideModal();
        this.mys.close();
        this.globalRegistry.reloadIngredients();
        // this.globalRegistry.reloadIngredientsTypes()
    }

    public submit(): void {
        // console.log(this.ingredientForm.value);
        // return;
        if(this.ingredientForm.invalid) {
            Object.keys(this.ingredientForm.controls).forEach((controlName: string): void =>
                this.ingredientForm.controls[controlName].markAsTouched()
            );
        }
        
        const body: IngredientInterface = {
            
            type: this.ingredientForm.controls['ingredientType'].value,
            subType: this.ingredientForm.controls['ingredientSubType'].value,
            min: this.ingredientForm.controls['ingredientMinValue'].value,
            max: this.ingredientForm.controls['ingredientMaxValue'].value,
            unit: this.ingredientForm.controls['ingredientUnit'].value,
            quantity: this.ingredientForm.controls['ingredientMinValue'].value,
            info: this.ingredientForm.controls['ingredientDescription'].value
        };

        // const service: StockService | IngredientService = this.context === 'cropType'
        //     ? this.cropTypeIngredientService
        //     : this.ingredientService;

        // this.ingredient ? this.updateIngredient({...{id: this.ingredient.id}, ...body}, service) : this.createIngredient(body, service);

        if(this.ingredient) {
            this.updateIngredient({...{id: this.ingredient.id}, ...body});
            this.globalRegistry.reloadIngredients();
        }
        else {
            this.createIngredient(body);
            this.globalRegistry.reloadIngredients();
        }
        this.getUnits()
    }

    public deleteIngredientType(ingrdientTypeId: number): void {
        this.typesService.deleteIngredientType(ingrdientTypeId).subscribe((): void => {
            this.globalRegistry.reloadIngredientsTypes();
            this.toastyService.success(this.translate.instant('stock.ingredients.successDeletedMessage'));
        },
        (): void => {
            this.toastyService.error(this.translate.instant('stock.ingredients.failDeletedMessage'));
        });
    }

    private createIngredient(ingredientType: IngredientInterface): void {
        this.stockService.createIngredient(ingredientType).subscribe(
            (ingredient: ResponseModel<IngredientModel>): void => {
                console.log(ingredient);
                this.createdIngredient.emit(ingredient.model);
                this.initForm();
                this.globalRegistry.reloadIngredientsTypes();
                this.toastyService.success(this.translate.instant('stock.ingredients.successAddMessage'));
                this.closeCombo = true;
            },
            (): void => {
                this.toastyService.error(this.translate.instant('stock.ingredients.failAddMessage'));
            }
        );
    }

    private updateIngredient(ingredientType: IngredientInterface): void {
        this.stockService.updateIngredient(ingredientType.id, ingredientType).subscribe(
            (ingredient: ResponseModel<IngredientModel>): void => {
                this.updatedIngredient.emit(ingredient.model);
                this.globalRegistry.reloadIngredientsTypes();
                this.toastyService.success(this.translate.instant('stock.ingredients.successUpdateMessage'));
                this.closeCombo = true;
            },
            (): void => {
                this.toastyService.error(this.translate.instant('stock.ingredients.failUpdateMessage'));
            }
        );
    }

    getTranslation(translation) {
        const t =this.translatedNames.filter(trans => {
          return trans.id === translation
        });
        if(t[0]) {
            if(t[0][this.langString] === null || t[0][this.langString] === '') {
              return translation
            } else {
              return t[0][this.langString];
            }
          } else {
            return translation
        }
      }

      public selectUnit(unit?: UnitModel, parent?:  UnitModel, genre?: string): void {
        if(unit?.locked) {
          return;
      }
        this.currentUnit = unit;
        this.parentUnit = parent ? parent : null;
        if(genre) {
          console.log(genre);
          this.units = this.globalRegistry.systemData.units.filter(unit => {
            return unit.genre == genre
          });
        }
        // if(!unit && !parent) {
        //   this.mySelect.close();
        //   this.units = this.globalRegistry.systemData.units;
        // }
        this.initunitForm();
        this.addEditUnitModal.showModal();
      }
      
      public onDeleteUnit(unit: UnitModel): void {
        if(unit?.locked) {
          return;
      }
       
        this.confirmModalService.openConfirmModal(
            this.translate.instant('confirmDelete'),
            this.translate.instant('units.deleteUnitConfirmMessage')
        );
        this.confirmModalService.onModalClose().subscribe((confirmed: boolean): void => {
            if (confirmed) {
                this.deleteUnit(unit.id);
            }
        });
      }
      
public submitUnit(): void {

    if(this.unitForm.dirty) {
        const unit: UnitInterface = {
            name: this.unitForm.controls['name'].value,
            shortName: this.unitForm.controls['shortName'].value,
            genre: this.unitForm.controls['genre'].value
        };
  
        if(typeof this.unitForm.controls['parent'].value !== 'string') {
            unit.parent = this.unitForm.controls['parent'].value === 'new' ?
                null :
                this.unitForm.controls['parent'].value;
        }
  
        if(this.currentUnit) {
            this.updateUnit({...{id: this.currentUnit.id}, ...unit});
        }
        else {
            this.addUnit(unit);
        }
        this.getUnits()
    }
  }
  
  public getUnits() {
    this.unitService.getUnits().subscribe(data => {
      this.units = data.body.results;
    })
  }
  
  private initunitForm(): void {
    this.unitForm = this.formBuilder.group({
        name: [this.currentUnit ? this.currentUnit.name : '', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
        ],
        shortName: [this.currentUnit ? this.currentUnit.shortName : '', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(100)
            ])
        ],
        parent: [this.currentUnit && this.currentUnit ? this.currentUnit.parent : '', Validators.compose([
                Validators.required
            ])
        ],
        genre: [this.currentUnit && this.currentUnit.genre ? this.currentUnit.genre : '', Validators.compose([
                Validators.required
            ])
        ]
    });
  }
  
  private addUnit(unit: UnitInterface): void {
    this.unitService.addUnit(unit).subscribe((response: ResponseModel<UnitModel>): void => {
        this.toastyService.success(this.translate.instant('units.unitAddSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.units = this.globalRegistry.systemData.units;
        this.onUnitAction(response.model.id);
    },
    (): void => {
        this.toastyService.error(this.translate.instant('units.unitAddError'));
    });
  }
  
  private updateUnit(unit: UnitInterface): void {
    this.unitService.updateUnit(unit).subscribe((): void => {
        this.toastyService.success(this.translate.instant('units.unitUpdateSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.units = this.globalRegistry.systemData.units;
        this.onUnitAction(this.currentUnit.id);
  
    },
    (): void => {
        this.toastyService.error(this.translate.instant('units.UnitUpdateError'));
    });
  }
  
  public deleteUnit(unitId: number): void {
    this.unitService.deleteUnit(unitId).subscribe((): void => {
        this.toastyService.success(this.translate.instant('units.unitDeleteSuccessfull'));
        this.globalRegistry.reloadUnits();
        this.units = this.globalRegistry.systemData.units;
    },
    (): void => {
        this.toastyService.error(this.translate.instant('units.UnitDeleteError'));
    });
  }

  public onUnitAction(unitId: number): void {
    this.selectedUnitId = unitId;
    this.addEditUnitModal.hideModal();
  }

  public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.unitForm.controls[controlName];
    if (!control) {
        return false;
    }
  
    const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}
