import { Component, Input } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'fap-overview-image',
  templateUrl: './fap-overview-image.component.html',
  styleUrls: ['./fap-overview-image.component.scss']
})
export class FapOverviewImageComponent {

  @Input() item: any = null;
  public mediaUrl = environment.mediaUrl;

  public get width() {
    return window.innerWidth;
  }

  public get height() {
    return window.innerHeight;
  }

}
