import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'fap-weather-forecast',
  templateUrl: './fap-weather-forecast.component.html',
  styleUrls: ['./fap-weather-forecast.component.scss']
})
export class FapWeatherForecastComponent implements OnInit {

@Input() public items = [];
@Input() public css: any = null;
@Input() public itemCss: any = null;
public mediaUrl: string = environment.mediaUrl;

constructor() {}

ngOnInit(): void {
  
}

public get width() {
  return window.innerWidth;
}

public get height() {
  return window.innerHeight;
}

getFontSize(textSize: string, multiplier: number): string {
  const parsedSize = parseInt(textSize, 10);
  const newSize = parsedSize * multiplier;
  return newSize + 'px';
}

}
