import { FapBaseModel } from '../../base/fap-base-model';


export class PartyModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'address_id',
        'accounts',
        'cif_id',
        'vat_id',
        'vat',
        'own',
        'locked'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public addressId: number;
    public accounts: any;
    public cifId: string;
    public vatId: number;
    public vat: number;
    public own: number;
    public locked: boolean;
}
