import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { InsectsComponent } from './insects.component';
import { InsectsContainerComponent } from './containers/insects-container/insects-container.component';
import { InsectsEditContainerComponent } from './containers/insects-edit-container/insects-edit-container.component';
import { PartialsModule } from '../../views-partials/partials.module';



@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule
    ],
    declarations: [
        InsectsComponent,
        InsectsContainerComponent,
        InsectsEditContainerComponent,
    ],
})

export class InsectsModule {

}
