import { FapBaseModel } from '../../base/fap-base-model';
import { PolygonCoordsInterface } from '../../interfaces/coords/polygon-coords.interface';


export class FarmModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'coords',
        'static_map_image',
        'party'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public coords: PolygonCoordsInterface;
    public staticMapImage: string;
    public party: number;

    public get previewImg(): string {
        if (this.staticMapImage) {
            return this.staticMapImage;
        }
        return '/assets/images/no_image_available.svg';
    }
}
