<fap-header *ngIf="shouldShowHeader">
    <fap-header-actions [isSubmitEnabled]="false" [isDeleteEnabled]="false" (cancel)="goBack()"></fap-header-actions>
</fap-header>
<div class="custom_camera_modal" id="custom_modal90" *ngIf="showCamera">
    <div class="close_btn"></div>
    <camera [position]="position" (emitImage)="addImage($event)"></camera>
</div>

<div class="add_report_page" [ngClass]="{ 'h60': isBaseApplication }" *ngIf="!showCamera">
    <form [formGroup]="reportForm">
        <div class="infos_section">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "alergotel.infestedSurface" | translate }}</mat-label>
                <input matInput type="text" [value]="report?.infested_area" formControlName="infested_area">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{ "alergotel.additionalInformation" | translate }}</mat-label>
                <textarea class="info" style="height: 120px; resize: none" formControlName="name" matInput name="info" cols="10" rows="10"></textarea>
            </mat-form-field>
        </div>
        <div class="bottom_part">
            <div class="checkboxes">
                <section class="example-section">
                    <mat-checkbox class="example-margin" [checked]="true" #checkBox1><div style="white-space: normal; position: relative;
                        top: -2px;">{{'alergotel.displayPictures' | translate}}</div></mat-checkbox>
                </section>
                <section class="example-section">
                    <mat-checkbox class="example-margin" [checked]="true" #checkBox2><div style="white-space: normal; position: relative;
                        top: -2px;">{{'alergotel.confirmGdpr' | translate}}</div></mat-checkbox>
                </section>
            </div>
            <div class="btn-container">
                <button class="btn" (click)="addReport()" [disabled]="!checkBox1?.checked || !checkBox2?.checked">{{ 'alergotel.send' | translate }}</button>
            </div>            
        </div>
    </form>
</div>