import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EquipmentModel } from '../../../../core/models/equipment/equipment.model';
import { environment } from '../../../../../environments/environment';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'fap-equipment-combo',
  templateUrl: './fap-equipment-combo.component.html',
  styleUrls: ['./fap-equipment-combo.component.scss']
})
export class FapEquipmentComboComponent {
  
  @Input() public equipments: EquipmentModel[] = [];
  @Input() public activityEquipments: any[] = [];
  @Output()
  public addImage: EventEmitter <any> = new EventEmitter();
  @Output()
  public deleteImage: EventEmitter <any> = new EventEmitter();
  public mediaUrl = environment.mediaUrl;
  @ViewChild('aeds') public aeds: MatSelect;

  constructor() {}
  
}
