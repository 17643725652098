import { FapBaseModel } from '../../base/fap-base-model';


export class CompanyModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'vat',
        'cif',
        'register_data',
        'register_date',
        'billing_plan',
        'billing_address',
        'currency',
        'vat_rates',
        'digits',
        'address_id'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public vat: string;
    public cif: string;
    public registerData: string;
    public registerDate: string;
    public billingPlan: string;
    public billingAddress: number;
    public currency: number;
    public vatRates: {};
    public digits: number;
    public addressId: number;
}
