<div class="info_section">
    <div class="section_header" *ngIf="isEditable">
        <h2>{{ 'description' | translate }}</h2>
        <fap-round-button (clicked)="addNewInfo()"></fap-round-button>
    </div>
    <div class="info_section_body">
        <ng-container *ngFor="let description of descriptions">
            <fap-info-section 
                [description]="description" 
                [nameT]="getTranslation(description.name_t)"
                [bodyT]="getTranslation(description.body_t)"
                [isEditable]="isEditable" 
                [langString]="langString"
                (editDescription)="editDescription($event)"
                (deleteDescription)="deleteDescription($event)"
            >
            </fap-info-section>
        </ng-container>
    </div>
</div>

<fap-modal #addEditDescriptionModal [inputTitle]="'addEditDescription' | translate" [inputIsMediumLarge]="true" [showFooter]="false" (outputOnHide)="description = null; descriptionId = -1">
    <fap-add-edit-info-section 
        [description]="description"
        [descriptionId]="descriptionId"
        (addedDescription)="addDescription($event)"
        (updatedDescription)="updateDescription($event)"
        (closeModal)="addEditDescriptionModal.hideModal();"
        [langString]="langString"
    >
    </fap-add-edit-info-section>
</fap-modal>