<div class="activity-row">
    <div class="activity-row-title">
        <h5>{{activity.name}}</h5>
    </div>
    <div class="activity-row-dates">
        <span class="activity-end-date" *ngIf="!activity.ended; else ended">xxxx - xx - xx --:--</span>
        <ng-template #ended>
            <span>{{activity.ended}}</span>
        </ng-template>
        <span class="activity-start-date" *ngIf="!activity.started; else started">xxxx - xx - xx --:--</span>
        <ng-template #started>
            <span>{{activity.started}}</span>
        </ng-template>
    </div>
    <div class="d-flex justify-content-end mt-2">
        <i *ngIf="!hideButtons" class="fa fa-pencil ter-btn-icon ter-btn-icon-success mr-2" (click)="editActivity.emit(activity)"></i>
        <i *ngIf="!hideButtons" class="material-icons ter-btn-icon ter-btn-icon-danger" (click)="deleteActivity.emit(activity.id)">delete_outline</i>
    </div>
</div>
