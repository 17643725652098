<ng-container [formGroup]="filterObj">
    <div class="d-flex align-items-center">
        <div class="mr-3" *ngIf="isSearchEnabled">
            <div class="lots ibox">
                <!-- <i class="material-icons select-icon text-white">view_quilt</i> -->
                <mat-form-field
                class="w-100"
                    [ngStyle]="{'background-color':navServices.searchBarBackground}"
                    appearance="outline">
                    <span class="search" *ngIf="!searchFilter.value, else clear">
                    <i class="fa fa-search f-20"></i>
                    </span>
                    <ng-template #clear>
                            <span (click)="clearSearchFilter()" class="search">
                             <i class="fa fa-times f-20"></i>
                            </span>
                    </ng-template>
                    <input
                        (keydown.enter)="onSearchSubmit(searchFilter.value)"
                        matInput
                        [placeholder]="'Search' + searchPlaceholder"
                        [formControl]="searchFilter"
                        type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="farms mr-2 date_picker" *ngIf="isDateFilterEnabled">
            <mat-form-field appearance="outline" class="dateform">
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="start" (dateChange)="setFromDate($event)" placeholder="Start Date">
                  <input matEndDate formControlName="end" (dateChange)="setToDate($event)" placeholder="End Date">  
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field> 
              <!-- <button mat-raised-button (click)="setToday()">Today</button>                         -->
        </div>
        <div class="farms mr-2 drill_type" *ngIf="isDayFilterEnabled">
            <mat-form-field appearance="outline" class="aggregate">
                <mat-select placeholder="Aggregate By" formControlName="aggBy" (selectionChange)="changeDate($event.value)">
                    <mat-option value="hour">
                        <div class="type-name">
                            <span>Hour</span>
                        </div>
                    </mat-option>
                    <mat-option value="day">
                        <div class="type-name">
                            <span>Day</span>
                        </div>
                    </mat-option>
                    <mat-option value="month">
                        <div class="type-name">
                            <span>Month</span>
                        </div>
                    </mat-option>
                    <!-- <mat-option value="year">Year</mat-option> -->
                </mat-select>
            </mat-form-field>
        </div>
        <div class="mr-2" *ngIf="isDateFilterEnabled">
            <button class="btn btn-drill" (click)="filter.emit(filterObj.value)">{{ filterLabel }}</button>
        </div>
        <div *ngIf="isExportFilterEnabled">
            <button class="btn btn-drill" (click)="export.emit()">{{ exportLabel }}</button>
        </div>
    </div>
</ng-container>