<div class="nc" 
    infiniteScroll
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="500"
    (scrolled)="scrolledDown()"
    [scrollWindow]="false"
    style="height: 100vh; overflow-x: hidden; width: 100%; flex: auto;"
>
    <ng-container>
        <!-- <ng-gantt [options]="editorOptions" [data]="data" #editor></ng-gantt> -->
    </ng-container>
    <span *ngIf="isLoading" class="loader">
        <i></i>
    </span>
</div>