import { Component } from '@angular/core';
import { AttributeService } from '../../../../../core/services/api/attribute/attribute.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { Subscription } from 'rxjs';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { TypesService } from '../../../../../core/services/api/types/types.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';

@Component({
  templateUrl: './workflows-container.component.html',
  styleUrls: ['./workflows-container.component.scss']
})
export class WorkflowsContainerComponent {
  public workflows = [];
  public subscriptions: Array<Subscription> = [];
  constructor(public globalRegistry: GlobalRegistryService, public attributeService: AttributeService, public widgetsService: WidgetsService, public mapService: MapService, public typeService: TypesService, private cacheService: CacheResolverService) { }

  ngOnInit(): void {
    this.getWorkflowList();
    this.widgetsService.setSize(12);
    this.mapService.hideMap();
  }

  getWorkflowList() {
    let url = this.typeService.getUrl('workflow/')
    this.cacheService.delete(url);
    this.subscriptions.push(
      this.typeService.getWorkflows().subscribe(data => {
      this.workflows = data.body.results;
    })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    })
    this.widgetsService.setSize(6);
  }
}
