import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceModel, ObjectService } from 'src/app/core/services/api/objects/object.service';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { CacheResolverService } from '../../../../../core/services/api/cache/cache-resolver.service';

@Component({
  selector: 'scheduler-layout',
  templateUrl: './scheduler-layout.component.html',
  styleUrls: ['./scheduler-layout.component.scss']
})
export class SchedulerLayoutComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public farms: Array<FarmModel>;
  @Input() public sensors: any = [];
  @Input() public actuators: any = [];
  @Input() public devices: Array<DeviceModel> = [];
  @Input() public farmForecastMapping: { [key: number]: WeatherModel } = {};
  
  @Output() public addSensor: EventEmitter<any> = new EventEmitter();
  @Output() public updateSensor: EventEmitter<any> = new EventEmitter();
  @Output() public deleteSensor: EventEmitter<number> = new EventEmitter();
  
  public isLoading = false;
  public subscription: Subscription
  public schedulers: any = [];

  constructor(public objectService: ObjectService, public sensorService: SensorService, public cacheService: CacheResolverService) { }

  ngOnInit() {
    // console.log(this.sensors);
    this.getSchedulers();
  }

  ngOnChanges() {
    if(this.sensors != undefined) {
      this.isLoading = false
    }
  }

  deleteTrigger(triggerId) {
    console.log(triggerId);
  }

  getDeviceInfo(actuatorId: string): any | undefined {
    const sensor = this.sensors.find(sensor => sensor.id === actuatorId);
    return sensor ? this.devices.find(device => device.id === sensor.device) : undefined;
  }

  getSensorInfo(actuatorId: string): any | undefined {
    return this.sensors.find(sensor => sensor.id === actuatorId);
  }

  getSchedulers() {
    const url = this.sensorService.getUrl('scheduler/');
    this.cacheService.delete(url);
    this.sensorService.getSchedulers().subscribe((data) => {
      console.log(data);
      this.schedulers = data.body.results;
    });
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

}
