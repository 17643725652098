// state.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public scrollPositions: { [key: string]: number } = {};
  public buttonClickStates: { [key: string]: boolean } = {};
  public selectedEntryIds: Set<number> = new Set<number>();
  public resourceEntryId: number

  // stock pages start
  addSelectedEntryId(entryId: number): void {
    this.selectedEntryIds.add(entryId);
  }

  removeSelectedEntryId(entryId: number): void {
    this.selectedEntryIds.delete(entryId);
  }

  clearSelectedEntryIds(): void {
    this.selectedEntryIds.clear();
  }

  getSelectedEntryIds(): Set<number> {
    return this.selectedEntryIds;
  }

  setResourceEntryId(entryId: number): void {
    this.resourceEntryId = entryId
  }

  getResourceEntryId(): number {
    return this.resourceEntryId;
  }

  // stock pages end
}
