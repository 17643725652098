import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { PersonModel } from '../../../../../core/models/person/person.model';
import { WeatherModel } from '../../../../../core/models/widget/weather/weather.model';
import { CompanyService } from '../../../../../core/services/api/company/company.service';
import { FarmService } from '../../../../../core/services/api/farm/farm.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { WidgetsService } from '../../../../../core/services/api/widgets/widgets.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { TableUtil } from '../../../../../shared/widgets/fap-table-widget/tableUtil';
import { FormControl, FormGroup } from '@angular/forms';
import { TimezoneService } from '../../../../../core/services/api/timezone/timezone.service';

export interface PeriodicElement {
  ID: number;
  Name: string;
  Type: string;
  Time: string;
  Lot: string;
  Crop: string;
  URL: string;
  Info: string;
  Created_By: string;
  Post_Type: string;
}
@Component({
  selector: 'posts-container',
  templateUrl: './posts-container.component.html',
  styleUrls: ['./posts-container.component.scss']
})
export class PostsContainerComponent implements OnInit, OnDestroy {

  public filteredFarms: Array<FarmModel> = [];
  public farmForecastMapping: { [key: number]: WeatherModel } = {};
  public forecasts: Array<WeatherModel> = [];
  public posts = [];
  public filteredPosts = [];
  public farms: Array<FarmModel>;
  public notes: Array<any> = [];
  private subscriptions: Array<Subscription> = [];
  public limit: 20;
  public getMore = true;
  public nextToken: { from: any; limit: number; lots: any; offset: number; to: any } = null;
  public isLoading = false;
  public crops: Array<CropModel> = [];
  public fromDate;
  public toDate;
  public lots = [];
  public translatedNames:any = [];
  public langString: string;
  public allLots: Array<LotModel> = [];
  public persons: Array<PersonModel> = [];
  public personIds = [];
  public images = [];
  imgSub$: Subscription;
  imgInstanceSub$: Subscription;
  public placeholderImg = '../../../../../../assets/images/placeholder.gif';
  
  public isSelectDialogOpen: boolean = false;

  constructor(public mapService:MapService, public router: Router, public activatedRoute: ActivatedRoute, public farmService: FarmService, public companyService: CompanyService, public globalRegistry: GlobalRegistryService, public widgetsService: WidgetsService, public sensorService: SensorService, public cacheService: CacheResolverService, private timezoneService: TimezoneService) {
    localStorage.setItem(
      "access",
      JSON.stringify({
        lots: [1, 1, 1],
        people: [1, 1, 1],
        equipments: [1, 1, 1],
        notes: [1,1,1]
      })
    );

    const localQueryParams = localStorage.getItem("queryParams")
      ? JSON.parse(localStorage.getItem("queryParams"))
      : {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: localQueryParams,
    });
    this.subscriptions.push(
      combineLatest([
        activatedRoute.queryParams,
        
      ]).subscribe(
        ([queryParams]: [
          Params,
        ]): void => {
          if (queryParams["farms"] !== undefined) {
            // we have farms in query params, but need to check if we have an array of farms
            const filteredFarmIds: Array<number> = Array.isArray(
              queryParams["farms"]
            )
              ? queryParams["farms"].map((farmId: string): number =>
                  Number(farmId)
                )
              : [Number(queryParams["farms"])];
            this.filteredFarms = this.globalRegistry.systemData.farms.filter(
              (farm: FarmModel): boolean => {
                return filteredFarmIds.includes(farm.id);
              }
            );
          } else {
            this.filteredFarms = this.globalRegistry.systemData.farms;
          }
          const url = this.farmService.getUrl('posts/');
          this.getPersons();
          if (queryParams["filter"] !== undefined) {
            const search = (queryParams["filter"] as string).toLowerCase();
            farmService.getPosts({search}).subscribe(data => {
              cacheService.delete(url+'search='+search);
              this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
              this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
            if(this.nextToken) this.getMore = true;
            this.filteredPosts = data.body.results;
            this.checkImages(this.filteredPosts)
            this.getPersons();
            console.log(this.filteredPosts);
            })
          }
        }
      )
    );
    this.mapService.showMap();
    this.mapService.centerMapOnUserLocation();
  }

  public createNewPost() {
    console.warn("creating new post doesn't exist yet!");
  }

  ngOnInit(): void {
    this.initMap();
    this.widgetsService.setDrill(true);
    this.widgetsService.setCreateWidget(true);
    this.getLots();
    this.widgetsService.setSize(6);
    this.getFromDate();
    this.getToDate();
    console.log(this.fromDate);
    console.log(this.toDate)
    this.getFilteredPosts({start: this.fromDate, end: this.toDate});
    this.subscriptions.push(this.sensorService.getFilterTrigger.subscribe(res => {
      if(res) {
        this.getFilteredPosts({start: this.fromDate, end: this.toDate});
        // this.getPersons();
      }
    }));
    this.subscriptions.push(this.sensorService.getExportPostData.subscribe(res => {
      if(res) {
        this.exportArray();
      }
    }));

  }

  ensureDateFormat(value: any): { start: Date, end: Date } {
    let formattedStart: Date;
    let formattedEnd: Date;

    if (typeof value.start === 'string') {
        formattedStart = new Date(value.start);
    } else if (value.start instanceof Date) {
        formattedStart = value.start;
    }

    if (typeof value.end === 'string') {
        formattedEnd = new Date(value.end);
    } else if (value.end instanceof Date) {
        formattedEnd = value.end;
    }

    return {
        start: formattedStart,
        end: formattedEnd
    };
}

public filterPosts(value) {
    this.getFilteredPosts({start: value.start, end: value.end});
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      timeZoneName: 'short' 
  };
  return date.toLocaleString('en-US', options).replace(',', '');
}

  public exportPosts() {
    this.exportArray();
  }

  public getImage(id) {
    if(id && id!=0) {
      this.imgInstanceSub$ = this.companyService
        .getCompanyImage(id)
        .subscribe({
          next: (data) => {
            const bool = this.images.some(i => i.id == id)
            if(!bool) {
              this.images.push(data.body.results)
            }
          },
          error: (err) => console.error(err)
        });
      }
  }

  public checkImages(posts) {
    const imageIds = [];
    posts.forEach(post => {
     post.images.forEach(imgId => {
      const bool = this.images.some(i => i.id == imgId)
      if(!bool) {
            imageIds.push(imgId)
        }
      });
    })
    const unique = imageIds.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });
    unique.forEach(element => {
      this.getImage(element);
    });
  }

  getPersons() {
    this.companyService.getPersons().subscribe(data => {
      this.globalRegistry.systemData.persons = data.body.results;
      if (this.filteredPosts) {
        this.personIds = [];
        this.filteredPosts.forEach(post => {
          const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === post.created_by);
          if (!personExist) {
            this.personIds.push(post.created_by);
          }
        });
        const filteredArray = this.personIds.filter(item => item !== null);
        console.log(filteredArray);
        const unique = filteredArray.filter((elem, index, self) => index === self.indexOf(elem));
        if (unique.length > 0) {
          const personObservables = unique.map(element => this.companyService.getPerson(element));
          forkJoin(personObservables).subscribe(personDataArray => {
            personDataArray.forEach(data => {
              if (data) {
                this.globalRegistry.systemData.persons.push(data.model);
              }
            });
          });
        }
      }
    });
  }


  public deletePost(ev) {
    console.log('delete post clicked', ev);
  }

  private initMap(): void {
    this.mapService.centerMapOnUserLocation();
}

paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  console.log(result);
  return result;
}

scrolledDown() {
  const url = this.farmService.getUrl('posts/');
  console.log('scrolled');
  const fromDate = this.fromDate;
  const toDate = this.toDate;
  const lots = this.lots? this.lots.toString() : []
  if(this.getMore) {
  this.nextToken &&
    this.farmService.getPosts(this.nextToken).subscribe((data) => {
      this.cacheService.delete(url+'from='+fromDate+'&limit='+this.nextToken.limit+'&lots='+lots+'&offset='+this.nextToken.offset+'&to='+toDate);
      console.log(data);
      if(this.filteredPosts.length > 0) {
        this.filteredPosts = [...this.filteredPosts, ...data.body["results"]];
        this.checkImages(this.filteredPosts)
      } else {
        this.filteredPosts = data.body["results"];
        this.checkImages(this.filteredPosts)
      }
      // this.filteredPosts = this.posts;
      this.getPersons();
      if(data.body["next"] == null) {
        this.getMore = false;
        return
      } else {
        const url = data.body['next'].split('?')
        const urlParams = new URLSearchParams(url[1]);
        const entries = urlParams.entries();
        const params = this.paramsToObject(entries);
        if(this.nextToken.offset != params['offset']) {
        this.nextToken = {from: params['from'], limit: params['limit'], lots:params['lots'], offset: params['offset'], to: params['to']};
        } else {
          return
        }
      }
    });
  } else {
    return
  }
}


getFilteredPosts(obj) {
  const url = this.farmService.getUrl('posts/');
  this.isLoading = true;
  console.log(obj);
    const { start, end }: { start: any, end: any } = obj;
    console.log('Original start:', start);
    console.log('Original end:', end);
    
    const isISODateString = (dateString: string): boolean => {
      const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      return isoRegex.test(dateString);
    };

    const formatLocalISOString = (date: Date, endOfDay: boolean = false): string => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      let hours = ('0' + date.getHours()).slice(-2);
      let minutes = ('0' + date.getMinutes()).slice(-2);
      let seconds = ('0' + date.getSeconds()).slice(-2);
      let milliseconds = ('00' + date.getMilliseconds()).slice(-3);

      if (endOfDay) {
          hours = '23';
          minutes = '59';
          seconds = '59';
          milliseconds = '999';
      }

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    const checkAndFormatDate = (date: any, endOfDay: boolean = false): string => {
      if (typeof date === 'string' && isISODateString(date)) {
          return date;
      } else if (date instanceof Date) {
          const formattedDate = formatLocalISOString(date, endOfDay);
          return formattedDate;
      } else {
          return date;
      }
    };

    const formattedStart = checkAndFormatDate(start);
    const formattedEnd = checkAndFormatDate(end, true);

  const lots = this.lots? this.lots.toString() : []
  this.farmService.filterPost({
      from: formattedStart,
      to: formattedEnd,
      lots: lots
   }).subscribe(data => {
    this.cacheService.delete(url+'from='+formattedStart+'&to='+formattedEnd+'&lots='+lots);
      console.log(data.body.results);
      this.filteredPosts = data.body.results;
      this.checkImages(this.filteredPosts)
      this.nextToken = data.body.next
            ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
            : null;
      this.getPersons();
      if(this.nextToken) this.getMore = true
      this.isLoading = false;
   });
}

getFromDate() {
  this.subscriptions.push(this.sensorService.getPostFromDate.subscribe(res => {
    this.fromDate = res;
  }));
}

getToDate() {
  this.subscriptions.push(this.sensorService.getPostToDate.subscribe(res => {
    this.toDate = res;
  }));
}

getLots() {
    this.activatedRoute.queryParams.subscribe(data => {
      if(data.lots) {
        this.lots = data.lots;
      }
    });
}

exportArray() {

  const dataSource = [];
  const copyData = [...this.filteredPosts];
  const crops = this.crops;
  
  copyData.forEach(element => {
  //   if(element.crop) {
  //   crops.forEach(e => {
  //     if(e.id == element.crop) {
  //       element.crop = e.cropType.nameT ? this.getTranslation(e.cropType.nameT) : e.cropType.name;
  //     } 
  //   });
  // }
    this.allLots.forEach(l => {
      if(l.id == element.lot) {
        element.lot = l.name
      }
    });

    this.persons.forEach(p => {
      if(p.id == element.created_by) {
        element.created_by = p.name  
      }
    });

    dataSource.push(element);
  });

  const onlyNameAndSymbolArr: Partial<PeriodicElement>[] = dataSource.map(x => ({
    ID: x.id,
    Name: x.name ? x.name : '-',
    Post_Type: x.post_type ? x.post_type : '-',
    Crop: x.crop ? x.crop : '-' ,
    Lot: x.lot ? x.lot : '-',
    Info: x.info ? x.info : '-',
    URL: x.url ? x.url : '-',
    Time: x.timestamp ? moment(x.timestamp).format('DD MMM YYYY, HH:MM') : '-',
    Created_By: x.created_by ? x.created_by : '-'
  }));
  TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "ExampleArray");
  this.getFilteredPosts({start: this.fromDate, end: this.toDate});
  this.sensorService.setExportPostDataTrigger(false);
}

getTranslation(translation) {
  const t =this.translatedNames.filter(trans => {
    return trans.id === translation
  });
  if(t[0]) {
    if(t[0][this.langString] === null || t[0][this.langString] === '') {
      return translation
    } else {
      return t[0][this.langString];
    }
  } else {
    return translation
  }
}

public ngOnDestroy(): void {
  this.mapService.mapPolygons = [];
  this.subscriptions.forEach(s => {
    s.unsubscribe();
  });
  
  if(this.imgInstanceSub$) this.imgInstanceSub$.unsubscribe();
  this.images.shift();
}

}
