import { Routes } from '@angular/router';
import { ActivityGroupsContainerComponent } from './containers/activity-groups-container/activity-groups-container.component';
import { ActivityGroupAddEditContainerComponent } from './containers/activity-group-add-edit-container/activity-group-add-edit-container.component';

export const activityRoutes: Routes = [
    {
        path: 'groups/add',
        component: ActivityGroupAddEditContainerComponent
    },
    {
        path: 'groups/edit/:activityGroupId',
        component: ActivityGroupAddEditContainerComponent
    },
    {
        path: 'groups',
        component:  ActivityGroupsContainerComponent
    },
    {
        path: '',
        redirectTo: 'groups',
        pathMatch: 'full'
    }
];
