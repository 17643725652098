import { FapBaseModel } from '../../base/fap-base-model';

export class UserDataModel extends FapBaseModel {
    public static fields: Array<string> = [
        'allowed',
        'timezone',
        'language',
        'devices_view'
    ];

    public allowed: any;
    public timezone: string;
    public language: string;
    public devices_view?: any
}
