import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { FarmComponent } from './farm.component';
import { FapFarmEditLayoutComponent } from './components/fap_farm-edit-layout/fap_farm-edit-layout.component';
import { FarmEditContainerComponent } from './containers/farm-container/farm-edit-container.component';
import { FarmManagementContainerComponent } from './containers/farm-management-container/farm-management-container.component';
import { FapFarmManagementLayoutComponent } from './components/fap_farm-management-layout/fap_farm-management-layout.component';
import { FapFarmCardComponent } from './components/fap_farm-card/fap_farm-card.component';
import { PartialsModule } from '../../views-partials/partials.module';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        PartialsModule,
        MatInputModule,
        MatSelectModule
    ],
    declarations: [
        FarmComponent,
        FarmEditContainerComponent,
        FapFarmEditLayoutComponent,
        FarmManagementContainerComponent,
        FapFarmManagementLayoutComponent,
        FapFarmCardComponent
    ]
})

export class FarmModule {

}
