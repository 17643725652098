<div class="automatic_form">
    <form [formGroup]="automaticForm">
        <div class="row mb-3">
            <div class="col-4">
                <mat-form-field class="w-100" (click)="addEditTranslation('notes.name')">
                    <mat-label class="col-form-label">{{"notes.name" | translate}}</mat-label>
                    <input matInput formControlName="name" readonly #nameInput/>
                    <mat-error *ngIf="automaticForm.controls['name'].errors?.required">
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-4">
                <div class="duration cl">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">Duration</mat-label>
                        <input matInput formControlName="duration" style="padding-right: 5px; text-align: right;"> min
                    </mat-form-field>
                </div>
            </div>
            <div class="col-4">
                <div class="start_date">
                    <mat-form-field (click)="untilPicker.open()" class="w-100">
                        <mat-label class="col-form-label">Until</mat-label>
                        <input matInput readonly [matDatepicker]="untilPicker" placeholder="Until" [disabled]="false" formControlName="end"/>
                        <mat-datepicker-toggle matSuffix [for]="untilPicker"></mat-datepicker-toggle>
                        <mat-datepicker #untilPicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="w-100 light_bg mb-2" *ngIf="currentFormType && (triggerId !== undefined && triggerId !== null)">
                <fap-attributes
                    [entityId]="entityInfo?.id"
                    [formTypeId]="currentFormType"
                    [entityInfo]="entityInfo"
                    [showHeader]="false"
                    [objectId]="triggerId"
                    (formTypeEmit)="formType = $event"
                    (formFieldsEmit)="formFields = $event"
                ></fap-attributes>
            </div>
            <!-- <div class="col-12">
                <fap-add-edit-delete-select 
                    [inputName]="'scheduler'"
                    style="max-width: 100%; padding: 0;"
                    class="nhalf_width col-4"
                    [formControl]="automaticForm.controls['scheduler']"
                    name="scheduler"
                    [inputIsRequired]="true"
                    [apiSearchEnabled]="true"
                    [inputNameFieldName]="'name'"
                    [inputNameTFieldName]="'name_t'"
                    [inputValueFieldName]="'id'"
                    [inputSelectValues]="schedulers"
                    [inputConfirmDeleteText]="'widget.confirmDeleteScheduler' | translate"
                    (outputOnDelete)="deleteScheduler($event.id)"
                    (outputOnCreate)="createNewScheduler(); closeCombo = false;"
                    (outputOnEdit)="editScheduler($event); closeCombo = false;"
                    (clicked)="setScheduler($event)"
                    [closeCombo]="closeCombo"
                    >
                </fap-add-edit-delete-select>
            </div> -->
            <div class="col-12 d-flex justify-content-between mt-3" *ngIf="scheduler">
                <div class="w-80">
                    <fap-scheduler-row [trigger]="trigger" [scheduler]="scheduler" [showHeader]="false"></fap-scheduler-row>
                </div>
                <div class="w-20">
                    <div class="actions">
                        <action-buttons
                            (editEmit)="editScheduler(scheduler)" 
                            [isDeleteable]="false"
                        ></action-buttons>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="col-12 mt-4">
        <div class="action_btns">
            <div class="btns d-flex justify-content-end">
              <button class="btn mr-2" (click)="submitAutomation()">{{ 'translations.save' | translate }}</button>
              <button class="btn" (click)="cancel.emit()">{{ 'translations.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<fap-modal #addEditTranslationsModal [inputTitle]="'scheduler.automatic' | translate" [showFooter]="false">
    <add-edit-translations 
        [obj]="convertKeysToCamelCase(this.trigger)" 
        [label]="label"
        [prefix]="'TRIGGER_'" 
        [translation]="translation"
        [group]="'types'"
        (submitTranslation)="onAddUpdateTranslation($event)" 
    >
    </add-edit-translations>
</fap-modal>

<fap-modal #addEditSchedulerModal [inputTitle]="'scheduler.scheduler' | translate" [showFooter]="false" [inputIsMedium]="true">
    <fap-add-edit-scheduler 
        [scheduler]="scheduler"
        [schedulerId]="schedulerId"
        (addScheduler)="addedScheduler($event);"
        (updateScheduler)="updatedScheduler($event);"
        (closeModal)="addEditSchedulerModal.hideModal()"
    ></fap-add-edit-scheduler>
</fap-modal>