import { FapBaseModel } from '../../base/fap-base-model';
import { PersonProfileModel } from './person-profile.model';
import { PartyModel } from '../party/party.model';


export class PersonModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'role',
        'company',
        'phone',
        'address_id',
        'picture',
        'job',
        'party',
        'user',
        'active',
        'active_farms',
        'activities',
        'first_name',
        'last_name',
        'groups',
        'display'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
        user: PersonProfileModel,
        party: PartyModel
    };

    public id: number;
    public role: number;
    public company: string;
    public phone: string;
    public addressId: string;
    public picture: string;
    public job: string;
    public user: PersonProfileModel = new PersonProfileModel();
    public activeFarms: number[];
    public party: PartyModel = new PartyModel();
    public activities: number[];
    public groups: number[];
    public firstName: string;
    public first_name: string;
    public lastName: string;
    public last_name: string;
    public active: boolean;
    public display: string;

    public get pictureSrc(): string {
        return this.picture ? this.picture : '/assets/images/farmer-man.jpg';
    }

    public get name(): string {
        return this.firstName + ' ' + this.lastName;
    }
}
