import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';
import { WidgetModel } from '../../../core/models/widget/widget.model';

@Component({
    selector: 'fap-widget-wrapper',
    templateUrl: './fap_widget-wrapper.component.html',
    styleUrls: ['./fap_widget-wrapper.component.scss']
})
export class FapWidgetWrapperComponent {

    @Input()
    public widget: WidgetModel;

    @Input()
    public isEditMode = false;

    @Output()
    public deleteWidget: EventEmitter<number> = new EventEmitter();

}
