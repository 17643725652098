import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalRegistryService } from './core/global-registry/global-registry.service';
import { UserModel } from './core/models/user/user.model';
import { SystemDataInterface } from './core/global-registry/system-data.interface';
import { DataService } from './core/services/websocket/data.service';
import { ThemeService } from './core/services/theme/theme.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    
    constructor(
        private _globalRegistry: GlobalRegistryService,
        private dataService: DataService, 
        private themeService: ThemeService ,
        private http: HttpClient,
        public translate: TranslateService      
    ) {
        // Set default language and use stored language preference if available
        const storedLanguage = localStorage.getItem('language');
        const userLang = navigator.language.slice(0, 2);
        const supportedLanguages = ['en', 'fr', 'ro', 'ru'];

        if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
        translate.setDefaultLang(storedLanguage);
        translate.use(storedLanguage);
        } else if (supportedLanguages.includes(userLang)) {
        translate.setDefaultLang(userLang);
        translate.use(userLang);
        localStorage.setItem('language', userLang);
        } else {
        translate.setDefaultLang('en');
        translate.use('en');
        localStorage.setItem('language', 'en');
        }

        // Initialize user data and translations
        this.initUserData();
        this.initTranslations();
        // this.themeService.applyStyles(); // Uncomment if necessary

        // console.log(navigator.language);

        // alert(localStorage.getItem('language'));
    }

    ngOnInit(): void {
        this.dataService.openWebSocket();
        if(localStorage.getItem('language') == null) {
            localStorage.setItem('language', 'en');
        }
        this.getCurrentCountry().subscribe(data => {
            localStorage.setItem('country_info', JSON.stringify(data));
        })
    }

    public getCurrentCountry() {
        return this.http.get('https://ipapi.co/json/');
      }

    ngOnDestroy(): void {
        this.dataService.closeWs();
    }

    public initUserData(): void {
        this._globalRegistry.initUserData = (userData: UserModel): void => {
            this._globalRegistry.userData = userData;
            this.initSystemData();
        };
    }

    public initSystemData(): void {
        this._globalRegistry.initSystemData = (
            systemData: SystemDataInterface
        ): void => {
            this._globalRegistry.systemData = systemData;
        };
    }

    public initTranslations() :void {
        this._globalRegistry.iniTranslations = (userData: UserModel): void => {
            this._globalRegistry.userData = userData;
            this.initSystemData();
        };
    }
}
