import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class AccessGuard implements CanActivate {
    constructor(private _router:Router){}
    public canActivate(route:ActivatedRouteSnapshot): boolean{
        const accessObj = localStorage.getItem("access")?JSON.parse(localStorage.getItem("access")):{};
        const key = route.data.key;
        const index = route.data.index;
        if(!accessObj[key]){
            return true;
        }
        else if(accessObj[key].length>0&&accessObj[key][index]===1){
            return true;
        }
        else{
            this.redirectToHomePage();
            return false;
        }
    }

    private redirectToHomePage(): void {
        this._router.navigateByUrl('/pages/posts');
    }
}