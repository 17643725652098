<div id="parent" class="simulations">
    <div class="stock_box d-flex w-100">
        <div class="stock_left w-100">
            <div class="sbox w-100">
                <div class="table-responsive">
                    <table class="stock_table">
                        <thead>
                            <tr>
                                <th *ngFor="let head of tableHeaders" [innerHTML]="head.name"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let simulation of simulationList" [routerLink]="['edit/', simulation?.id]">
                                <td>{{ getLotName(simulation?.lot) }}</td>
                                <td>{{simulation?.name}}</td>
                                <td>{{simulation?.start | date: 'dd MMM yyyy'}}</td>
                                <td>{{simulation?.end | date: 'dd MMM yyyy'}}</td>
                                <td>{{simulation?.duration}}</td>
                                <ng-container *ngFor="let header of tableHeaders | slice: 5: tableHeaders.length">
                                    <td [innerHTML]="getExtraFieldsById(simulation?.name, header?.name)"></td>
                                  </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>  
            </div>
        </div>
    </div>
</div>