<div
    class="dashboard-card dash_cont"
    style="position: relative"
>
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>
                {{ "widget.herbicide" | translate }}
            </h1>
        </div>
        <div class="col-md-6 col_lot_name">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row" style="height: calc(100% - 30px);">
        <div
            *ngIf="!activity"
            style="
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 70%;
                width: 100%;
            "
        >
            <h3 style="text-align: center">
                <span class="loader">
                    <i></i>
                </span>
            </h3>
        </div>
        <div class="alert_widget w-100">
            <div class="top_row">
                <div class="box">
                    <div class="lpart">
                        <div class="tprow"><h4 *ngIf="activity"><b>{{event}}</b> {{startDate}}</h4> <h4 *ngIf="!activity">{{"widget.selectSpreadingActivity" | translate}}</h4></div>
                        <div class="tbrow">
                            <div class="img">
                                <div class="img_place_bg">
                                <img src="../../../../assets/images/image_placeholder.png" alt="" *ngIf="herbicideData?.billing?.product?.images.length == 0 || !herbicideData?.billing?.product">
                                <ng-container *ngIf="herbicideData?.billing?.product?.images.length > 0">
                                    <img [src]="mediaUrl + herbicideData?.billing?.product?.images[herbicideData?.billing?.product?.images.length - 1].file" alt="">
                                </ng-container>
                            </div>
                            </div>
                            <div class="text" *ngIf="crop">
                                <div class="txt">
                                    <h4>
                                        {{ crop?.cropType?.nameT ? getTranslation(crop?.cropType?.nameT) : crop?.cropType?.name }}
                                    </h4>
                                    <p>
                                        <i>{{ getLotName() }}</i>
                                    </p>
                                    <button
                                        class="info_btn"
                                        *ngIf="herbicideData?.billing?.product"
                                    >
                                        {{
                                            herbicideData?.billing?.product?.label?.name
                                        }}
                                    </button>
                                    <div class="info_btn1 error" *ngIf="!herbicideData?.billing?.product">{{"widget.addHerbicideProduct" | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="graph">
                        <p class="alertpercent" *ngIf="herbicideData?.value?.value_float != '0.0' && !error">
                            {{ herbicideData?.value?.value_float }} %
                        </p>
                        <div class="cheat_row" [id]="randomId">
                            <apx-chart
                            [series]="chartOptions.series"
                            [chart]="chartOptions.chart"
                            [dataLabels]="chartOptions.dataLabels"
                            [plotOptions]="chartOptions.plotOptions"
                            [yaxis]="chartOptions.yaxis"
                            [xaxis]="chartOptions.xaxis"
                            [fill]="chartOptions.fill"></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foot_row" *ngIf="herbicideData?.messages.length > 0">
                <marquee>
                    <p *ngFor="let p of herbicideData?.messages">
                        <span [ngStyle]="{ background: p?.color }"></span>
                        <a *ngIf="p.url !== ''" [href]="p?.url" target="_blank">{{ getTranslation(p?.info_t) }}</a>
                        <i style="font-style: normal;" *ngIf="p.url === ''">{{ getTranslation(p?.info_t) }}</i>
                    </p>
                </marquee>
            </div>
        </div>
    </div>
</div>