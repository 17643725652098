import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import { DeviceModel, ObjectService } from '../../../../../core/services/api/objects/object.service';
import { SensorService } from '../../../../../core/services/api/sensor/sensor.service';
import { DataService } from '../../../../../core/services/websocket/data.service';
import { MapService } from '../../../../../shared/layout/fap_main-map/service/map-service';
import { NavService } from '../../../../../shared/services/nav.service';
import { FapModalComponent } from '../../../../../shared/partials';

@Component({
  selector: 'scheduler-card',
  templateUrl: './scheduler-card.component.html',
  styleUrls: ['./scheduler-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCardComponent implements OnInit, OnDestroy, OnChanges {

  public lots: Array<LotModel>;
  OpenState = false;

  @Input() public sensor;
  @Input() public actuator;
  @Input() public device = null;
  @Input() public schedulers = [];

  @Output() public deleteNote: EventEmitter<number> = new EventEmitter();

  public lotGroup: any = [];
  public subscriptions: Array<Subscription> = [];
  public sensorData = null;
  public langString: string = '';
  public scheduler: any = null;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('schedulerTriggerModal') schedulerTriggerModal: FapModalComponent;

  constructor(
    public mapService: MapService,
    public activatedRoute: ActivatedRoute,
    public globalRegistry: GlobalRegistryService,
    public router: Router,
    public sensorService: SensorService,
    public dataService: DataService,
    public toastr: ToastrService,
    public objectService: ObjectService,
    public navService: NavService
  ) {
        this.mapService.showMap();
        this.mapService.centerMapOnUserLocation();
  }

  ngOnChanges(changes: SimpleChanges): void {
        if(changes.hasOwnProperty('device') && this.device) {
            console.log(this.device);
        }
  }

  openAllFirst() {
        this.accordion.openAll();
  }

  ngOnInit(): void {
    if (this.accordion) {
        this.openAllFirst();
    }
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
  }

  editSensor(sensor) {
    console.log(sensor);
  }

  public getFormTypes(item) {
    console.log(item);
    this.sensorData = item;
    this.getSensor();
    // this.schedulerTriggerModal.showModal();
  }

  public getSensor() {
    console.log(this.sensor);
    this.schedulerTriggerModal.showModal();
    // this.sensor = item;
  }

  getScheduler(schedulerId: number): any | undefined {
    return this.schedulers.find(scheduler => scheduler.id === schedulerId);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
