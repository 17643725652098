import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordPatternValidator {

    public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            return null;
          }

          const valid: boolean = regex.test(control.value);
          return valid ? null : error;
        };
      }
}
