<div class="page-wrapper" *ngIf="data$ | async as data" id="parent">
    <div class="auth-bg">
        <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
        <div class="authentication-box">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="text-center">
                        <h4>{{ 'auth.enterEmail' | translate }}</h4>
                        <!-- <h6>{{ 'auth.login.requiredFields' | translate }}</h6> -->
                    </div>
                    <form class="theme-form" [formGroup]="loginForm">
                        <div class="form-group mb-0">
                            <div class="row align-items-baseline">
                                <div class="col-12 p-0">
                                    <div class="input-group w-100">
                                        <div class="col-12">
                                            <mat-form-field class="w-100">
                                                <mat-label class="col-form-label">Email</mat-label>
                                                <input matInput formControlName="email">
                                                <mat-error *ngIf="ifControlHasError('email','email')">
                                                  <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                                                </mat-error>
                                                <mat-error *ngIf="ifControlHasError('email','required')">
                                                  <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12 mt-1">
                                            <button class="btn btn-success" type="submit" (click)="emailSubmit()">{{ 'submit' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>