import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import { EquipmentsContainerComponent } from './containers/equipments-container/equipments-container.component';
import { FapEquipmentsLayoutComponent } from './components/fap_equipments-layout/fap_equipments-layout.component';
import { EquipmentsComponent } from './equipments.component';
import { SharedModule } from '../../../shared/shared.module';
import { FapEquipmentCardComponent } from './components/fap_equipment-card/fap_equipment-card.component';
import { EquipmentAddEditContainerComponent } from './containers/equipment-add-edit-container/equipment-add-edit-container.component';
import { FapAddEditEquipmentLayoutComponent } from './components/fap_add-edit-equipment-layout/fap_add-edit-equipment-layout.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FapEquipmentPathRowComponent } from './components/fap_equipment-path-row/fap_equipment-path-row.component';
import { FapEquipmentPathsComponent } from './components/fap_equipment-paths/fap_equipment-paths.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PartialsModule } from '../../views-partials/partials.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        InfiniteScrollModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        PartialsModule,
        
        NgxPermissionsModule.forChild()
    ],
    declarations: [
        EquipmentsComponent,
        EquipmentsContainerComponent,
        EquipmentAddEditContainerComponent,
        FapEquipmentsLayoutComponent,
        FapEquipmentCardComponent,
        FapAddEditEquipmentLayoutComponent,
        FapEquipmentPathsComponent,
        FapEquipmentPathRowComponent
    ]
})

export class EquipmentsModule {

}
