import { TransactionsParamsInterface } from '../../../../../core/interfaces/stock/transactions-params.interface';
import {
    Component,
    Input,
    ViewChild,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    Output,
    EventEmitter,
    OnInit
} from '@angular/core';
import { TransactionModel } from '../../../../../core/models/stock/transaction.model';
import {
    MatLegacyPaginator as MatPaginator,
    LegacyPageEvent as PageEvent
} from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fap-stock-transactions',
    templateUrl: './fap_stock-transactions.component.html',
    styleUrls: ['./fap_stock-transactions.component.scss']
})
export class FapStockTransactionsComponent implements AfterViewInit, OnChanges, OnInit {

    @Input()
    public transactions: Array<TransactionModel> = [];

    @Input()
    public totalNoOfTransactions = 0;

    @Output()
    public filterTransactions: EventEmitter<TransactionsParamsInterface> = new EventEmitter();

    @ViewChild('paginator')
    public paginator: MatPaginator;

    public title: string = this.translateService.instant('stock.transactions.title');
    public displayedColumns: string[] = ['number', 'resource', 'transaction', 'price', 'created_at'];

    public dataSource: MatTableDataSource<TransactionModel> = new MatTableDataSource<TransactionModel>(this.transactions);

    public pageSize = 10;
    public pageIndex = 0;

    constructor(public translateService: TranslateService) { }

    public ngAfterViewInit(): void {
        this.paginator.page.subscribe((event: PageEvent): void => {
            this.pageSize = event.pageSize;
            this.pageIndex = event.pageIndex;
            const transactionsFilter: TransactionsParamsInterface = {
                offset: String(this.pageSize * this.pageIndex),
                limit: String(this.pageSize)
            };
            this.filterTransactions.emit(transactionsFilter);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'transactions'), this.transactions) {
            this.dataSource = new MatTableDataSource<TransactionModel>(this.transactions);
        }
    }

    public ngOnInit(): void {
        console.log(this.transactions);
    }
}
