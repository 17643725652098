import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    permissions?: string[];
    children?: Menu[];
    key?:string;
}

@Injectable({
    providedIn: 'root'
})
export class NavService {

    public screenWidth: any;
    public collapseSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    public MENUITEMS: Menu[] = environment.menuItems;
    public items: BehaviorSubject<Menu[] > = new BehaviorSubject < Menu[] > (this.MENUITEMS);
    public backgroundImage: string = environment.layoutConfig.settings.sidebar_background_image;
    public logo: string = environment.layoutConfig.settings.sidebar_logo;
    public headerBackgroundColor: string = environment.layoutConfig.pallet.header_background;
    public sidebarBackgroundColor: string = environment.layoutConfig.pallet.sidebar_background;
    public searchBarBackground: string = environment.layoutConfig.pallet.search_color;
    public triangle: string = environment.layoutConfig.pallet.triangle;
    public hoverColor: string = environment.layoutConfig.pallet.hover_color;
    public editFarm: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (false);
    // new farm or lot is creating if true
    public createMod: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (false);
    public editMod: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (false);
    public viewMod: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (false);
    // prevent to submit if false
    public submitFarm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public invalidForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // submit button clicked
    public emitSubmit: Subject<boolean> = new Subject<boolean>();
    // delete button clicked
    public emitDelete: Subject<boolean> = new Subject<boolean>();
    // cancel button clicked
    public emitCancel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public emitExportPDF: Subject<boolean> = new Subject<boolean>();
    public emitExportExcel: Subject<boolean> = new Subject<boolean>();
    public openWidget: Subject<boolean> = new Subject<boolean>();
    public accent_color: string = environment.layoutConfig.color.accent_color;
    public version: string = environment.version;
    public currentLanguage = new BehaviorSubject('');
    public resetModalForm:Subject<boolean> = new Subject();
    public createActivity: Subject<string> = new Subject<string>();

    public isSideBarInitiallyOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    getCurrentLanguage = this.currentLanguage.asObservable();

    constructor(public translate: TranslateService) {
        this.onResize();
        if (this.screenWidth < 1024) {
            this.collapseSidebar.next(true);
        } else {
            this.collapseSidebar.next(false);
        }
    }

    public setCurrentLanguage(data) {
        this.currentLanguage.next(data);
    }

    // Windows width
    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.screenWidth = window.innerWidth;
    }


}
