<div class="entries">
    <div class="sbox">
        <fap-section-container [title]="'productsUsage.productsUsage' | translate">
            <div class="table-responsive">
            <table class="entry_table">
                <thead>
                    <tr>
                        <th>{{ 'productsUsage.product' | translate }}</th>
                        <th>{{ 'productsUsage.quantity' | translate }}</th>
                        <th>{{ 'productsUsage.appliedTo' | translate }}</th> <!--Aplicat la-->
                        <th>{{ 'productsUsage.waterDilution' | translate }}</th> <!--Necesar apă-->
                        <th>{{ 'productsUsage.repauseTime' | translate }}</th> <!--Repetare după-->
                        <th>{{ 'productsUsage.onsetTime' | translate }}</th> <!--Timp așteptare-->
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let treatment of treatments">
                        <td>
                            {{treatment?.label?.name}} 
                            <!-- <b>{{ treatment?.product | getProductUnit: globalRegistry?.systemData?.unitsMap }}</b> -->
                             <div><b>{{ treatment?.producer?.name }}</b></div>
                        </td>
                        <td>
                            <div class="w-100 d-flex">
                                <span class="logical_unit">
                                    <ng-container *ngIf="treatment?.quantity_min === 0 && treatment?.quantity_max === 0">
                                      <span class="center_point">-</span>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.quantity_min > 0 && treatment?.quantity_max > 0">
                                      <b>{{treatment?.quantity_min}}</b>
                                      <span> to </span>
                                      <b>{{treatment?.quantity_max}}</b>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.quantity_min > 0 && treatment?.quantity_max === 0">
                                      > <b>{{treatment?.quantity_min}}</b>
                                    </ng-container>
                                </span>
                                <span *ngIf="!(treatment?.quantity_min === 0 && treatment?.quantity_max === 0)">
                                    {{ treatment?.quantity_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="w-100 d-flex">
                                <b class="td_value">{{treatment?.apply}}</b>
                                <span class="td_unit">{{ treatment?.apply_unit | getUnit: globalRegistry?.systemData?.unitsMap }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="w-100 d-flex">
                                <b class="td_value">{{treatment?.dilution}}</b>
                                <span class="td_unit">{{ treatment?.dilution_unit | getUnit: globalRegistry?.systemData?.unitsMap }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="w-100 d-flex">
                                <span class="logical_unit">
                                    <ng-container *ngIf="treatment?.repause_min === 0 && treatment?.repause_max === 0">
                                    <span class="center_point">-</span>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.repause_min > 0 && treatment?.repause_max > 0">
                                    <b>{{treatment?.repause_min}}</b>
                                    <span> to </span>
                                    <b>{{treatment?.repause_max}}</b>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.repause_min > 0 && treatment?.repause_max === 0">
                                    > <b>{{treatment?.repause_min}}</b>
                                    </ng-container>
                                </span>
                                <span *ngIf="!(treatment?.repause_min === 0 && treatment?.repause_max === 0)">
                                    {{ treatment?.repause_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="w-100 d-flex">
                                <span class="logical_unit">
                                    <ng-container *ngIf="treatment?.reaction_min === 0 && treatment?.reaction_max === 0">
                                      <span class="center_point">-</span>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.reaction_min > 0 && treatment?.reaction_max > 0">
                                      <b>{{treatment?.reaction_min}}</b>
                                      <span> to </span>
                                      <b>{{treatment?.reaction_max}}</b>
                                    </ng-container>
                                    <ng-container *ngIf="treatment?.reaction_min > 0 && treatment?.reaction_max === 0">
                                      > <b>{{treatment?.reaction_min}}</b>
                                    </ng-container>
                                  </span>
                                  <span *ngIf="!(treatment?.reaction_min === 0 && treatment?.reaction_max === 0)">
                                    {{ treatment?.reaction_unit | getUnit: globalRegistry?.systemData?.unitsMap }}
                                  </span>
                            </div>
                        </td>                          
                        <td>
                            <action-buttons 
                                [locked]="treatment?.locked" 
                                (editEmit)="editTreatment.emit(treatment)" 
                                (deleteEmit)="deleteTreatment.emit(treatment?.id)"
                            ></action-buttons>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
        </fap-section-container>
    </div>
</div>