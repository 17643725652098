<fap-header>
    <div class="d-flex align-items-center">
        <div class="tree_combo mr-2">
            <fap-tree-combo></fap-tree-combo>
          </div>
        <div class="add_circle mr-2" (click)="newTreatment()">
            <div class="rounded-circle"></div>
        </div>
        <fap-search-filter
            [isSearchEnabled]="true"
        ></fap-search-filter>
    </div>
</fap-header>
<div class="treatments_page">
    <fap-treatments-table 
        [treatments]="treatments"
        (deleteTreatment)="deleteTreatment($event)"
        (editTreatment)="editTreatment($event)"
    ></fap-treatments-table>
</div>
<fap-pagination
        [count]="count"
        [pageSize]="limit"
        (emitNextPrev)="handlePagination($event)"
    ></fap-pagination>

<fap-modal #addEditTreatmentsModal [inputTitle]="'productsUsage.addEditProductUsage' | translate" [inputIsSmall]="true" [showFooter]="false" (outputOnHide)="treatment = null; treatmentId = -1">
    <fap-add-edit-treatment 
        [treatment]="treatment"
        [productUsageTypes]="productUsageTypes"
        [treatmentId]="treatmentId"
        [formTypes]="formTypes"
        (addedTreatment)="addTreatment($event)"
        (updatedTreatment)="updateTreatment($event)"
        (closeModal)="addEditTreatmentsModal.hideModal(); treatmentId = -1"
        (refreshProductUsageTypes)="getProductUsageTypes()"
    >
    </fap-add-edit-treatment>
</fap-modal>