import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FapModalComponent } from '../../../../shared/partials';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { NavService } from '../../../../shared/services/nav.service';

@Component({
  selector: 'fap-field-choices',
  templateUrl: './field-choices.component.html',
  styleUrls: ['./field-choices.component.scss']
})
export class FieldChoicesComponent implements OnInit {

  @Input() public choices = [];
  @Input() public type = 'editable';

  @Output() public deleteLocalchoice: EventEmitter<any> = new EventEmitter<any>();
  @Output() public emitChoices: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addEditTranslationsModal') public addEditTranslationsModal: FapModalComponent;
  @ViewChild('addEditChoicePopup') public addEditChoicePopup: FapModalComponent;
  @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

  public nameT: any = null;
  public selectedChoice: any;
  public addEditChoiceForm: FormGroup;
  public label: string;
  public translation = null;
  public langString: string;
  public subscriptions: Subscription[] = [];

  constructor(public globalRegistry: GlobalRegistryService, private formBuilder: FormBuilder, private toastrService: ToastrService, private navService: NavService) {}

  ngOnInit(): void {
    this.langString = localStorage.getItem('language');
        this.subscriptions.push(this.navService.getCurrentLanguage.subscribe(lang => {
            if(lang) {
                this.langString = lang;
            }
      }));
    this.initaddEditChoiceForm();
  }

  createNewChoice() {
    this.initaddEditChoiceForm();
    this.addEditChoiceForm.reset();
    this.addEditChoiceForm.clearValidators();
    this.addEditChoicePopup.showModal();
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}${currentDate.getFullYear()}`;
    const timestamp = `${currentDate.getHours().toString().padStart(2, '0')}${currentDate.getMinutes().toString().padStart(2, '0')}${currentDate.getSeconds().toString().padStart(2, '0')}`;
    const id = `FIELDTYPE_CHOICE_${formattedDate}_${timestamp}`;
    this.addEditChoiceForm.get('id').setValue(id);
  }

  addChoice() {
    console.log(this.addEditChoiceForm.value);
  
    const { id, name_t, input, default: isDefault } = this.addEditChoiceForm.value;
  
    if (!id) {
      this.toastrService.error('ID cannot be empty. Please provide a valid ID.');
      return;
    }
  
    const existingIndex = this.choices.findIndex(choice => choice.id === id);
    
    if (existingIndex !== -1) {
      this.choices[existingIndex] = this.addEditChoiceForm.value;
      
    } else {
      const hasExistingDefault = this.choices.some(choice => choice.default);
      const isDuplicateInput = this.choices.some(choice => choice.input === input);
  
      if (hasExistingDefault && isDefault) {
        this.toastrService.error('Attempting to set "default: true" on multiple choices.');
        return;
      }
  
      if (isDuplicateInput) {
        this.toastrService.error(`Input value "${input}" already exists in another choice.`);
        return;
      }
      this.choices.push(this.addEditChoiceForm.value);
    }
  
    console.log('Form values:', this.addEditChoiceForm.value);
    console.log('Choices array:', this.choices);
  
    this.emitChoices.emit(this.choices);
    this.addEditChoicePopup.hideModal();
    this.selectedChoice = null;
    this.addEditChoiceForm.reset();
    this.addEditChoiceForm.clearValidators();
    this.initaddEditChoiceForm();
  }
  

  editChoice(choice) {
    console.log(choice);
    this.selectedChoice = choice;
    this.initaddEditChoiceForm();
    this.addEditChoicePopup.showModal();
  }

  deleteChoice(id) {
    const filteredArray = this.choices.filter(obj => obj.id !== id);
    this.emitChoices.emit(filteredArray);
    this.addEditChoicePopup.hideModal();
    this.selectedChoice = null;
    this.addEditChoiceForm.reset();
    this.initaddEditChoiceForm();
 }

  convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
      const camelCaseKey = key.replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key] instanceof Object ? this.convertKeysToCamelCase(obj[key]) : obj[key];
    }
    return camelCaseObj;
  }

  addEditTranslation(label) {
    this.label = label;
      const name = this.nameInput.nativeElement.value;
      const type: any = this.convertKeysToCamelCase(this.selectedChoice);
      
      if (name === '' || type.nameT === null) {
          this.translation = null;
          this.addEditTranslationsModal.showModal();
          return;
      }

      const translationsMap = this.globalRegistry.systemData.translationsMap;
      const translationId = type?.nameT;

      if (translationId && translationsMap.has(translationId)) {
        const translation = translationsMap.get(translationId);
        this.translation = translation;
        console.log(this.translation);
        this.addEditTranslationsModal.showModal();
      } 
      // else {
      //   this.toastrService.error('Translation not available');
      // }
  
      if (Object.keys(type).length === 0 && name) {
        const newTranslation = this.addEditChoiceForm.controls['name'].value;
        console.log(name);
        console.log(type);
        console.log(newTranslation);
          for (const translation of this.globalRegistry.systemData.translations) {
              if (translation.id === newTranslation) {
                  this.translation = translation;
                  console.log(this.translation);
                  this.addEditTranslationsModal.showModal();
                  return;
              }
          }
      }
  }

  public initaddEditChoiceForm(): void {
    this.addEditChoiceForm = this.formBuilder.group({
        name_t: [this.selectedChoice ? this.selectedChoice.name_t : null, Validators.required],
        name: [this.selectedChoice ? this.selectedChoice.name: null],
        id: [this.selectedChoice ? this.selectedChoice.id : null],
        input: [this.selectedChoice ? this.selectedChoice.input : null, Validators.required],
        default: [this.selectedChoice ? this.selectedChoice.default : false]
    });
  }

  getTranslation(translation) {
    const t = this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  onAddUpdateTranslation(translation) {
    console.log(translation);
    this.translation = translation;
    this.addEditChoiceForm.controls['name_t'].setValue(translation.id);
    this.addEditChoiceForm.controls['name'].setValue(translation[this.langString]);
    this.nameT = translation;
    this.addEditTranslationsModal.hideModal();
    this.selectedChoice = this.addEditChoiceForm.value;
}
}
