import { Component, OnDestroy } from '@angular/core';
// import { GanttEditorComponent, GanttEditorOptions } from 'ng-gantt';
import { Subscription } from 'rxjs';
import { GlobalRegistryService } from 'src/app/core/global-registry/global-registry.service';
import { ActivityService } from 'src/app/core/services/api/activity/activity.service';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';
import { CompanyService } from 'src/app/core/services/api/company/company.service';

export interface dataModel {
  pID: number, pName: string, pStart: any, pEnd: any, pClass: string, pRes: string
}

@Component({
  selector: 'plan-layout',
  templateUrl: './plan-layout.component.html',
  styleUrls: ['./plan-layout.component.scss']
})

export class PlanLayoutComponent implements OnDestroy {
  // public editorOptions: GanttEditorOptions;
  public data = [];
  // @ViewChild(GanttEditorComponent, { static: true }) editor: GanttEditorComponent;
  public nextToken: { offset: number; limit: number } = null;
  public limit = 50;
  public getMore = false;
  public personIds = [];
  public activities = [];
  public subscription = new Subscription();
  vShowComp = 0;
  vShowTaskInfoLink = 0;
  public isLoading = false;

  constructor(public activityService: ActivityService, public cacheService: CacheResolverService, public globalRegistry: GlobalRegistryService, public companyService: CompanyService) {
    // this.editorOptions = {
    //   vCaptionType: 'Complete',
    //   vFormat: 'day',
    //   vEditable: false,
    //   vQuarterColWidth: 40,
    //   vShowComp: this.vShowComp,
    //   vShowTaskInfoLink: this.vShowTaskInfoLink,
    //   vEvents: {
    //     res: console.log,
    //     dur: console.log,
    //     start: console.log,
    //     end: console.log,
    //     planstart: console.log,
    //     planend: console.log,
    //   },
    //   vEventsChange: {
    //     taskname: () => {
    //       console.log('taskname');
    //     },
    //   },
    // };
    
    activityService.getActivities({limit: this.limit}).subscribe(data => {
      const url = this.activityService.getUrl('activities/');
      cacheService.delete(url+'limit='+this.limit);
      this.nextToken = data.body.next
    ? this.globalRegistry.getQueryStringParams(data.body.next.split("?")[1])
    : null;
    if(this.nextToken) this.getMore = true;
    this.activities = data.body.results;
    this.getPersons();
    setTimeout(() => {
      this.transformData(data.body.results);
    }, 2000)
    
    });
        
   }

  transformData(data) {
    // this.data = [];
    this.isLoading = true;
    console.log(this.data);
    data.forEach(activity => {
      const dataModel:dataModel = {
        pID : activity.id,
        pName : activity.name,
        pStart : activity.started,
        pEnd : activity.ended ? activity.ended : '',
        pClass : 'gtaskred',
        pRes : activity.assignedTo ? this.getPerson(activity.assignedTo) : '-',
      }
      this.data.push(dataModel);
    });
    console.log(this.data);
    // this.editor.setOptions(this.editorOptions);
    // this.editor.data =  this.data;
    this.isLoading = false;
  }

  getPerson(id) {
    let display = ''
    this.globalRegistry.systemData.persons.forEach(p => {
      if(p.id === id) {
        display = p.display
      }
    })
    return display
  }

  getPersons() {
    this.companyService.getPersons().subscribe(data => {
      this.globalRegistry.systemData.persons = data.body.results;
      if(this.data) {
        this.personIds = [];
        this.data.forEach(data => {
          
          const personExist = this.globalRegistry.systemData.persons.some((p) => p.id === data.assignedTo);
          if(!personExist) {
            this.personIds.push(data.assignedTo);
          }
        });
        const unique = this.personIds.filter(function(elem, index, self) {
          return index === self.indexOf(elem);
        });
        setTimeout(()=> {
          unique.forEach(element => {
            if(element) {
            this.companyService.getPerson(element).subscribe(data => {
              if(data) {
                this.globalRegistry.systemData.persons.push(data.body.results)
              }
              this.globalRegistry.systemData.persons = this.globalRegistry.systemData.persons.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
              });
              console.log(this.globalRegistry.systemData.persons);
            })
            }
          });
        },1000)  
      }
    })
  }

paramsToObject(entries) {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    console.log(result);
    return result;
  }

  scrolledDown() {
    const url = this.activityService.getUrl('activities/');
    console.log('activities scrolled');
    if(this.getMore) {
      this.nextToken &&
        this.subscription.add(this.activityService.getActivities(this.nextToken).subscribe((data) => {
          this.cacheService.delete(url+'limit='+this.nextToken.limit+'&offset='+this.nextToken.offset);
          console.log(data);
          if(this.activities) {
            this.activities = [...this.activities, ...data.body.results];
            this.transformData(data.body.results);
          } else {
            this.activities = data.body.results;
          }
          if(data.body.next == null) {
            this.getMore = false;
            return
          } else {
            const url = data.body.next.split('?')
            const urlParams = new URLSearchParams(url[1]);
            const entries = urlParams.entries();
            const params = this.paramsToObject(entries);
            console.log(params);
            if(this.nextToken.offset != params['offset']) {
            this.nextToken = {limit: params['limit'], offset: params['offset']};
            } else {
              return
            }
          }
        }));
      } else {
        return
      }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
