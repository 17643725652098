import { TranslateService } from '@ngx-translate/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
import { ToastrService } from 'ngx-toastr';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    Output,
    ViewChild,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    FormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import { PartyModel } from '../../../../core/models/party/party.model';
import { PartyInterface } from '../../../../core/services/api/company/data/party.interface';
import {
    take
} from 'rxjs/operators';
import { ResponseModel } from '../../../../core/models/response.model';
import { PartyService } from '../../../../core/services/api/company/party.service';
import { FapModalComponent } from '../../../../shared/partials';
import { AddressModel } from '../../../../core/models/company/address.model';
import { CompanyService } from '../../../../core/services/api/company/company.service';
import { FapModalButtonInterface } from 'src/app/shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { CacheResolverService } from 'src/app/core/services/api/cache/cache-resolver.service';

@Component({
    selector: 'fap-add-edit-party',
    templateUrl: './fap_add-edit-party.component.html',
    styleUrls: ['./fap_add-edit-party.component.scss']
})
export class FapAddEditPartyComponent implements OnChanges, OnInit, OnDestroy{

    @Input()
    public party: PartyModel;

    @Output()
    public addedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public updatedParty: EventEmitter<PartyModel> = new EventEmitter();

    @Output()
    public deleteAddress: EventEmitter<number> = new EventEmitter();

    @Output()
    public addedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public updatedAddress: EventEmitter<AddressModel> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<PartyModel> = new EventEmitter();

    @ViewChild('addEditAddressModal')
    public addEditAddressModal: FapModalComponent;
    @ViewChild('addEditBankAddressModal')
    public addEditBankAddressModal: FapModalComponent;

    @ViewChild('addAccountsModal')
    public addAccountsModal: FapModalComponent;

    @ViewChild('addBankModal')
    public addBankModal: FapModalComponent;

    public addAccountsModalButtonPrimaryInterface: FapModalButtonInterface;
    public addAccountsModalButtonSecondaryInterface: FapModalButtonInterface;
    public addBankModalButtonPrimaryInterface: FapModalButtonInterface;
    public addBankModalButtonSecondaryInterface: FapModalButtonInterface;
    public accountsForm: FormGroup;
    public bankForm: FormGroup;

    public selectedAddress: AddressModel;
    public selectedBankAddress: AddressModel;
    public allAccounts = [];
    public accounts = [];
    public partyForm: FormGroup;
    public fullAddress;
    public fullBankAddress;
    public banks = [];
    public showAddressError: boolean = false;
    public showBankAddressError: boolean = false;
    public bank: any;
    public account: any;
    public closeCombo: boolean = true;

    constructor(private formBuilder: UntypedFormBuilder, private partyService: PartyService, private toastr: ToastrService,
                private translate: TranslateService,
                public globalRegistry: GlobalRegistryService,
                private companyService: CompanyService,
                public cacheService: CacheResolverService,
                public translateService: TranslateService
                ) {
                    // this.globalRegistry.reloadAddresses();
                this.initPartyForm();
                this.initAccountsForm();
                this.initBankForm();
    }

    public ngOnInit(): void {
        this.initAddAccountsModalButtons();
        this.initAddBankModalButtons();
    }

    public initAccountsForm() {
        this.accountsForm = new FormGroup({
            name: new UntypedFormControl(this.account ? this.account.name : '', Validators.required),
            account_id: new UntypedFormControl(this.account ? this.account.account_id : '', Validators.required),
            bank: new UntypedFormControl(this.account ? this.account.bank : null, Validators.required),
            iban: new UntypedFormControl(this.account ? this.account.iban : true, Validators.required)
        });

    }

    public initBankForm() {
        this.bankForm = new FormGroup({
            name: new UntypedFormControl(this.bank ? this.bank.name : '', Validators.required),
            address_id: new UntypedFormControl(this.bank ? this.bank.address_id : '', Validators.required),
            info: new UntypedFormControl(this.bank ? this.bank.info : ''),
            swift: new UntypedFormControl(this.bank ? this.bank.swift : '', Validators.required),
            locked: new UntypedFormControl(this.bank ? this.bank.locked : false, Validators.required)
        });
    }

    ngOnDestroy(): void {
        this.fullAddress = '';
        this.fullBankAddress = '';
        this.partyForm.reset();
        this.partyForm.clearValidators();
    }

    public showAccountsModal(): void {
        this.addAccountsModal.showModal();
        this.getAllBanks();
    }

    public showBankModal(): void {
        this.addBankModal.showModal();
    }

    public ngOnChanges(changes?: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'party')) {
            console.log(changes);
            this.initPartyForm();
        }
    }

    public toggleIban(ev) {
        console.log(ev);
    }

    public getAllBanks() {
        this.banks = [];
        const url = this.companyService.getUrl('');
        this.cacheService.delete(url + 'bank/');
        this.companyService.getBanks().subscribe(data => {
            this.banks = data.body.results;
            console.log(data);
        })
    }

    public initAddAccountsModalButtons(): void {
        const _this: FapAddEditPartyComponent = this;

        this.addAccountsModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.bankAccountSubmit();
            },
            text: this.translateService.instant('save')
        };

        this.addAccountsModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addAccountsModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public initAddBankModalButtons(): void {
        const _this: FapAddEditPartyComponent = this;

        this.addBankModalButtonPrimaryInterface = {
            clickFunction(): void {
                _this.bankSubmit();
            },
            text: this.translateService.instant('save')
        };

        this.addBankModalButtonSecondaryInterface = {
            clickFunction(): void {
                _this.addBankModal.hideModal();
            },
            text: this.translateService.instant('cancel')
        };
    }

    public resetForm(): void {
        this.initPartyForm();
    }

    public bankSubmit() {
        if(!this.verifyAddress('bankForm')){
            this.showBankAddressError = true;
        }
        if (this.bankForm.invalid) {
            Object.keys(this.bankForm.controls).forEach((controlName: string): void =>
                this.bankForm.controls[controlName].markAsTouched()
            );
            return;
        }
        const bank = this.bankForm.value;
        if (!this.bankForm.touched) {
            return;
        }

        this.bank ? this._updateBank({...{id: this.bank.id}, ...bank}) : this._addBank(bank);
    }

    public bankAccountSubmit() {
        if (this.accountsForm.invalid) {
            Object.keys(this.accountsForm.controls).forEach((controlName: string): void =>
                this.accountsForm.controls[controlName].markAsTouched()
            );
            return;
        }
        const account = this.accountsForm.value;
        if (!this.accountsForm.touched) {
            return;
        }

        this.account ? this._updateBankAccount({...{id: this.account.id}, ...account}) : this._addBankAccount(account);
    }

    public submit(): void {
        console.log(this.partyForm.value);
        if(!this.verifyAddress('partyForm')){
            this.showAddressError = true;
        }
        if (this.partyForm.invalid) {
            Object.keys(this.partyForm.controls).forEach((controlName: string): void =>
                this.partyForm.controls[controlName].markAsTouched()
            );
            return;
        }

        const party: PartyInterface = {
            name: this.partyForm.controls['name'].value,
            addressId: this.partyForm.controls['address_id'].value,
            accounts: this.partyForm.controls['accounts'].value,
            cifId: this.partyForm.controls['cif_id'].value,
            vatId: this.partyForm.controls['vat_id'].value,
            own: this.partyForm.controls['own'].value
        };

        this.party ? this._updateParty({...{id: this.party.id}, ...party}) : this._addParty(party);
    }

    verifyAddress(form):boolean {
        if(!this[form].controls['address_id'].value) {
            return false
        }
        return true;
    }

    public ifControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.partyForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public getAddress(addressId) {
        const url = this.companyService.getUrl('');
        this.cacheService.delete(url + 'address/' + addressId + '/');
        this.companyService.getSingleAddress(addressId).subscribe(data => {
          console.log(data);
          this.fullAddress = data.body.results;
        })
      }

      public bankAddress(addressId) {
        const url = this.companyService.getUrl('');
        this.cacheService.delete(url + 'address/' + addressId + '/');
        this.companyService.getSingleAddress(addressId).subscribe(data => {
          console.log(data);
          this.fullAddress = data.body.results;
        })
      }

    private initPartyForm(): void {
        this.partyForm = this.formBuilder.group({
            name: [this.party ? this.party.name : '', Validators.compose([
                Validators.required,
            ])],
            address_id: [this.party ? this.party.addressId : '', Validators.required],
            accounts: [this.party ? this.party.accounts : []],
            cif_id: [this.party ? this.party.cifId : '', Validators.required],
            vat_id: [this.party ? this.party.vatId : '', Validators.required],
            own: [this.party ? this.party.own : false, Validators.required],
        });
        this.partyForm.markAsUntouched();
        if(this.party) {
            if(this.party.addressId) {
            this.getAddress(this.party.addressId);
            } else {
                this.fullAddress = '';
            }
            if(this.party.accounts) {
                this.party.accounts.forEach(element => {
                    this.getAccounts(element);
                });
            }
          }
    }

    public getAllAccounts() {
        this.allAccounts = [];
        const url = this.companyService.getUrl('bank_account/');
        this.cacheService.delete(url);
        this.companyService.getAccounts().subscribe(data => {
            console.log(data)
            this.allAccounts = data.body.results;
        })
    }

    public getAccounts(accountId) {
        const url = this.companyService.getUrl('bank_account/' + accountId + '/');
        this.cacheService.delete(url);  
        this.companyService.getAccount(accountId).subscribe(data => {
            console.log(data)
            this.allAccounts.push(data.body.results);
        })
    }

    private _addParty(party: PartyInterface): void {
        this.partyService.createParty(party).pipe(take(1))
            .subscribe((response: ResponseModel<PartyModel>): void => {
                this.globalRegistry.reloadParties();
                this.addedParty.emit(response.model);
                this.showAddressError = false;
                this.toastr.success(this.translate.instant('party.messages.createSuccess'));
            },
                (): void => {
                    this.toastr.error(this.translate.instant('party.messages.failCreate'));
                });
    }

    private _updateParty(party: PartyInterface): void {
        this.partyService.updateParty(party).pipe(take(1))
            .subscribe((response: ResponseModel<PartyModel>): void => {
                this.globalRegistry.reloadParties();
                this.updatedParty.emit(response.model);
                this.showAddressError = false;
                this.toastr.success(this.translate.instant('party.messages.updateSuccess'));
            },
                (): void => {
                    this.toastr.error(this.translate.instant('party.messages.failUpdate'));
                });
    }

    private _addBankAccount(bankAccount): void {
        this.companyService.createBankAccount(bankAccount).pipe(take(1))
            .subscribe((response): void => {
                this.getAllAccounts();
                this.account = null;
                const accountIds = this.partyForm.get('accounts').value;
                this.partyForm.get('accounts').patchValue([...accountIds, response.body.results.id])
                this.accounts.push(response.body.results);
                this.toastr.success(this.translate.instant('Bank account created successfully'));
                this.addAccountsModal.hideModal();
            },
                (): void => {
                    this.toastr.error(this.translate.instant('Failed to create new bank account'));
                });
    }

    private _updateBankAccount(bankAccount): void {
        this.companyService.updateBankAccount(bankAccount).pipe(take(1))
            .subscribe((response): void => {
                this.getAllAccounts();
                this.account = null;
                const accountIds = this.partyForm.get('accounts').value;
                this.partyForm.get('accounts').patchValue([...accountIds, response.body.results.id])
                this.accounts.push(response.body.results)
                this.toastr.success(this.translate.instant('Bank account updated successfully'));
                this.addAccountsModal.hideModal();
            },
                (): void => {
                    this.toastr.error(this.translate.instant('Failed to create new bank account'));
                });
    }

    private _addBank(bank): void {
        this.companyService.createBank(bank).pipe(take(1))
            .subscribe((response): void => {
                this.getAllBanks();
                this.bank = null;
                this.accountsForm.get('bank').patchValue(response.body.results.id)
                this.toastr.success(this.translate.instant('Bank created successfully'));
                this.addBankModal.hideModal();
                this.showBankAddressError = false;
                this.closeCombo = true;
            },
                (): void => {
                    this.toastr.error(this.translate.instant('Failed to create bank'));
                });
    }

    private _updateBank(bank): void {
        this.companyService.updateBank(bank).pipe(take(1))
            .subscribe((response): void => {
                this.getAllBanks();
                this.bank = null;
                this.accountsForm.get('bank').patchValue(response.body.results.id)
                this.toastr.success(this.translate.instant('Bank updated successfully'));
                this.addBankModal.hideModal();
                this.showBankAddressError = false;
                this.closeCombo = true;
            },
                (): void => {
                    this.toastr.error(this.translate.instant('Failed to update bank'));
                });
    }

    public editAccount(account) {
        this.account = account;
        this.initAccountsForm();
        this.showAccountsModal();
    }

    public editBank(bank) {
        this.bank = bank;
        this.initBankForm();
        this.showBankModal();
    }

    public deleteAccount(accountId) {
        this.companyService.deleteBankAccount(accountId).subscribe(data => {
            console.log(data);
            this.getAllAccounts();
        }, err => {
            console.log(err);
        })
    }

    public deleteBank(bankId) {
        this.companyService.deleteBank(bankId).subscribe(data => {
            console.log(data);
            this.getAllBanks();
        }, err => {
            console.log(err);
        })
    }

    public addAddressModal(): void {
        this.selectedAddress = null;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    public editAddress(address: AddressModel): void {
        this.selectedAddress = address;
        this.addEditAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    public addBankAddressModal(): void {
        this.selectedBankAddress = null;
        this.addEditBankAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    public editBankAddress(address: AddressModel): void {
        this.selectedBankAddress = address;
        this.addEditBankAddressModal.showModal();
        // this.globalRegistry.reloadAddresses();
    }

    addressAdd(ev) {
        this.fullAddress = ev;
        this.addedAddress.emit(ev);
        this.showAddressError = false;
      }
    
    addressUpdate(ev) {
        this.fullAddress = ev;
        this.updatedAddress.emit(ev);
        this.showAddressError = false;
    }

    addBankAddress(ev) {
        console.log(ev);
        this.fullBankAddress = ev;
        this.addedAddress.emit(ev);
        this.showBankAddressError = false;
      }
    
      updateBankAddress(ev) {
        this.fullBankAddress = ev;
        this.updatedAddress.emit(ev);
        this.showBankAddressError = false;
    }
}
