<fap-header>
    <div class="add_btn mr-3">
        <fap-round-button (clicked)="navService.createActivity.next(this.activeUrl)" [backgroundColor]="'tertiary'"></fap-round-button>
    </div>
</fap-header>
<div class="crop_detail_page d-flex" id="parent101">
    <div class="crop_info">
        <div class="crop">
            <!-- {{crop?.product?.name}} <span class="more_info" (click)="openPopup(); editCrop()">...more</span> -->
            <div class="crop_product_info d-flex">
                <div class="prod_img">
                    <div class="image">
                        <div class="user_im img_place_bg">
                            <img *ngIf="crop?.product?.images.length" [src]="mediaUrl + crop?.product?.images[0]?.file" alt="">
                            <img class="user_im" *ngIf="!crop?.product?.images" src="../../../../../../assets/images/landscape_placeholder.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="prod_info">
                    <b>
                        {{getTranslation(crop?.product?.label?.type?.name_t) || crop?.product?.label?.type?.name}}
                    </b>
                    <div>
                        {{getLotName(crop?.lot)}}
                    </div>
                </div>
                <div class="cost_info">
                    <div>{{crop?.forecastCost}} {{defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap}}</div>
                    <div>{{crop?.totalCost}} {{defaultCurrency | getUnit: globalRegistry?.systemData?.unitsMap}}</div>
                </div>
                <div class="crop_progress">
                    {{crop?.progress}} %
                </div>
                <div class="more_info" (click)="openPopup(); editCrop()">
                    ...more
                </div>
            </div>
        </div>
        <div class="activities">
            <div class="activity-card" *ngFor="let activity of activities">
                <div class="d-flex activities-card" *ngIf="activity">
                    <ng-container *ngFor="let type of activityTypes">
                        <div
                            class="abs_child"
                            [style.background]="type?.color"
                            *ngIf="type?.id === activity?.data?.activity_type">
                        </div>
                    </ng-container>
                    <div class="split d-flex">
                        <div class="left_part">
                            <div class="bg-infos">
                                <ng-container *ngFor="let type of activityTypes">
                                    <span
                                        class="type_color typebg"
                                        [style.background]="type?.color"
                                        *ngIf="type?.id === activity?.data?.activity_type">
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        <div class="mid_part">
                            <div class="title_row p10">
                                <div class="edit_link p-2 title">
                                    <h5>{{ activity?.data?.name }}</h5>
                                    <div class="c1">
                                        <span *ngFor="let type of activityTypes">
                                            <span *ngIf="type.id === activity?.data?.activity_type">
                                                <span *ngIf="type?.name_t">
                                                    {{ getTranslation(type?.name_t) || type?.name }}
                                                </span>
                                                <span *ngIf="!type?.name_t">{{ type.name }}</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="sub_contents">
                                <div class="c2 p10">
                                    <div class="pl-2 act_date">
                                        <em class="select-option-date">
                                            {{ activity?.data?.started | date: "MMM dd yyyy, HH:mm" }}
                                        </em>
                                    </div>
                                    <div class="pl-2 act_date">
                                        <em class="select-option-date">
                                            {{ activity?.data?.ended | date: "MMM dd yyyy, HH:mm" }}
                                        </em>
                                    </div>
                                </div>
                                <div class="c3 p10">
                                    <div>0 eur</div>
                                    <div>12,340 eur</div>
                                </div>
                                <div class="c4 pc p10">
                                    <div class="act_area">
                                        <p class="p-1 select-option-time">
                                            <img src="../../../../../../assets/images/area.png" alt=""/>
                                            {{ activity?.data?.area }} ha
                                        </p>
                                        <p class="pl-1 pt-1 mb-0 select-option-time">
                                            <img src="../../../../../../assets/images/distance.png" alt=""/>
                                            {{ activity?.data?.distance }} km
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobl">
                        <p class="mb-0 select-option-time">
                            <img src="../../../../../../assets/images/area.png" alt=""/>
                            {{ activity?.data?.area }} ha
                        </p>
                        <p class="pl-2 mb-0 select-option-time">
                            <img src="../../../../../../assets/images/distance.png" alt=""/>
                            {{ activity?.data?.distance }} km
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="scrollable_area">
        <div class="gantt-chart">
            <div class="header-row">

              <div class="date-column" *ngFor="let column of dateColumns">
                {{ column | date: 'MMM dd' }}
              </div>
            </div>
            <div class="activity-row" *ngFor="let activity of activities">
                <div class="cell" *ngFor="let col of dateColumns">
                    <ng-container>
                        <div class="activity-cell">
                            <div *ngFor="let item of activity.items" class="activity-overlay" [routerLink]="'/pages/activities/edit/' + item.pos.activity.id" [queryParams]="cropId?{from:'crop',cropId:cropId}:{from:'crop'}"
                                [style.background]="getActivityTypeColor(activity?.data?.activity_type)"
                                [style.width]="getActivityOverlayWidth(item.pos.start, item.pos.end)"
                                [style.left]="getActivityOverlayPosition(item.pos.start)">
                                {{ item.pos.activity.name_t ? getTranslation(item.pos.activity.name_t) : item.pos.activity.name }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
          </div>               
    </div>
</div>


<div class="custom_modal" id="crop_edit_modal">
    <div class="modal_header">
        <h2 class="title pc-view">{{ 'crops.addEditCrop' | translate }}</h2>
        <div class="d-flex">
        <button type="button" class="btn close-modal" (click)="closeModal()"><i class="fa fa-times"></i></button>
        </div>
    </div>
    <div class="modal_body">
        <form [formGroup]="cultureForm" class="mt-0 p-0 col-12 mb-2">
            <!-- <div class="card-header-wrap d-flex align-items-center mb-4 flex-wrap" style="display: none;">
                <h2 class="card-header-title mr-4">{{ 'farm.crop.startNewCulture' | translate }}</h2>
                <button *ngIf="showDeleteCultureBtn && enableDelete" class="btn btn-danger px-2" type="button" (click)="deletedCulture.emit()">
                    <i class="material-icons mr-1">delete_outline</i>
                    {{ 'farm.crop.deleteCulture' | translate }}
                </button>
            </div> -->
            <div class="form-grid">
                <div class="row align-items-baseline cform">
                    <div class="col-4">
                    <mat-form-field class="w-100" (click)="productsModal.showModal(); show = true">
                        <mat-label>Crop product</mat-label>
                        <input style="display: none;" type="text" matInput formControlName="product" readonly="true" name="product">
                        <span style="min-height: 16.8px; display: inline-block; cursor: pointer;">
                            {{product?.label?.name}}
                        </span>
                    </mat-form-field>
                    </div>
                    <div class="col-4">
                    <mat-form-field class="w-100" (click)="start.open()">
                        <mat-label>
                            {{ 'farm.crop.startedOn' | translate }}</mat-label>
                        <input matInput readonly formControlName="start" [matDatepicker]="start">
                        <mat-datepicker-toggle matSuffix [for]="start">
                        </mat-datepicker-toggle>
                        <mat-datepicker #start></mat-datepicker>
                    </mat-form-field>
                    </div>
                    <div class="col-4">
                    <mat-form-field class="w-100">
                        <input formControlName="progress" matInput placeholder="{{ 'farm.crop.progress' | translate }}">
                    </mat-form-field>
                    </div>
                    <div class="col-4 p-0">
                        <div class="d-flex clearfix">
                            <div class="col-7 pr-0">
                                <mat-form-field class="w-100">
                                    <input formControlName="rowDistance" style="text-align: right; padding-right: 10px;"  matInput placeholder="{{ 'farm.crop.rowDistance' | translate }}">
                                </mat-form-field>
                            </div>
                            <div class="col-5 pl-0">
                                <mat-form-field style="max-width: 100%; margin-left: -1px;" class="w-100">
                                    <mat-label>Unit</mat-label>
                                <mat-select class="select u" required formControlName="distUnit" (selectionChange)="setUnit($event)">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #unitFilter placeholder="Search">
                                        </mat-form-field>
                                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                                    </div>
                                    <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-optgroup class="noteIcons" *ngFor="let parent of areaUnits | filter : unitFilter.value">
                                        <span class="mat-option-text">
                                            <div class="type-name_title">
                                                <span class="theme_color">{{parent.name}}</span>
                                            </div>
                                        </span>
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                            <div class="type-name">
                                                <span>{{child.shortName}}</span>
                                            </div>
                                        </mat-option>
                                    </mat-optgroup>
                                </div>
                                </mat-select>
                            </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 p-0">
                        <div class="d-flex clearfix">
                            <div class="col-7 pr-0">
                                <mat-form-field class="w-100">
                                    <input formControlName="plantDistance" style="text-align: right; padding-right: 10px;" matInput placeholder="{{ 'farm.crop.plantDistance' | translate }}">
                                </mat-form-field>
                            </div>
                            <div class="col-5 pl-0">
                                <mat-form-field style="max-width: 100%; margin-left: -1px;" class="w-100">
                                    <mat-label>Unit</mat-label>
                                <mat-select class="select u" required formControlName="distUnit" (selectionChange)="setUnit($event)">
                                    <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <input matInput #unitFilter placeholder="Search">
                                        </mat-form-field>
                                        <em class="fa fa-plus center f-20 ml-2" (click)="selectUnit()"></em>
                                    </div>
                                    <div class="scroll_wrap">
                                    <mat-option style="height: 0!important;"></mat-option>
                                    <mat-optgroup class="noteIcons" *ngFor="let parent of areaUnits | filter : unitFilter.value">
                                        <span class="mat-option-text">
                                            <div class="type-name_title">
                                                <span class="theme_color">{{parent.name}}</span>
                                            </div>
                                        </span>
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option *ngFor="let child of parent?.children" [value]="child?.id">
                                            <div class="type-name">
                                                <span>{{child.shortName}}</span>
                                            </div>
                                        </mat-option>
                                    </mat-optgroup>
                                </div>
                                </mat-select>
                            </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 mbtm">
                        <!-- <div class="d-flex">
                            <button class="bbtn clsbtn" *ngIf="!markedAsEnded" (click)="markedAsEnded=true;emitCultureChanged()">{{ 'farm.crop.markEnded' | translate }}</button>
                            <button class="bbtn clsbtn" *ngIf="markedAsEnded" (click)="markedAsEnded=false;emitCultureChanged()">{{ 'farm.crop.unmarkEnded' | translate }}</button>
                            <button class="bbtn dltbtn"><i class="fa fa-trash" (click)="deletedCulture.emit()"></i></button>
                        </div> -->
                    </div>
                    
                    <div class="col-4" *ngIf="crop">
                        <div class="input-group align-items-baseline w-100">
                            <mat-form-field style="max-width: 100%" class="w-100">
                                <mat-label class="col-form-label">Yield</mat-label>
                                <input type="text" class="no-border" matInput formControlName="yield" readonly>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 
                <span (click)="goToBill()" style="margin-top: 0;" class="theme_color"></span> 
            -->
        
        <div class="card mt-2" style="margin-bottom: 0" *ngIf="crop">
            <fap-section-container [isTable]="true" [title]="'stock.consumed' | translate">
                <items-table [items]="resources?.consumed?.items" [showCosts]="true" [cost]="resources?.consumed?.cost"></items-table>
            </fap-section-container>
        </div>
        
        <div class="card mt-2" style="margin-bottom: 0" *ngIf="crop">
            <fap-section-container [isTable]="true" [title]="'stock.produced' | translate">
                <items-table [items]="resources?.produced?.items" [showCosts]="true" [cost]="resources?.produced?.cost"></items-table>
            </fap-section-container>
        </div>
        </form>
        <ng-container *ngIf="cropId">
            <fap-attributes
            [entityRelation]="'crop'"
            [entityId]="entityInfo?.id"
            [entityInfo]="entityInfo"
            [objectId]="cropId"
            (formTypeEmit)="formType = $event"
            (formFieldsEmit)="formFields = $event"
            >
            </fap-attributes>
        </ng-container>
        <div class="action_btns d-flex justify-content-end mt-3">
            <button class="btn btn-primary mr-2" (click)="saveCrop()">{{ 'translations.save' | translate }}</button>
            <button class="btn btn-primary" (click)="closeModal()">{{ 'translations.cancel' | translate }}</button>
        </div>
    </div>
</div>

<fap-modal #addEditProductModal
[inputTitle]="'product.addEditProduct' | translate">
        <fap-add-edit-product [productId]="productId"
        (closeModal)="addEditProductModal.hideModal()"
        (addedProduct)="addProductAction($event);addEditProductModal.hideModal()"
        (updatedProduct)="updateProductAction($event);addEditProductModal.hideModal()">
        </fap-add-edit-product>
</fap-modal>

<fap-modal #productsModal [inputIsLarge]="true"
[inputTitle]="'stock.products' | translate" [showFooter]="false">
    <products-modal (hideModal)="hideProducts()" (selectedProduct)="assignProduct($event)" [show]="show"></products-modal>
</fap-modal>