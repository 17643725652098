export enum WidgetsEnum {
    Weather = 'weather',
    Activities = 'activities',
    Alerts = 'alerts',
    Farms = 'farms',
    Lots = 'lots',
    Fields = 'fields',
    Soil = 'soil',
    Humidity = 'humidity',
    Temperature = 'temperature',
    SoilTemperature = 'soilTemperature',
    Rainfall = 'rainfall',
    Moist = 'moist',
    Rad = 'rad',
    PhotosynthRadiation = 'photosynthRadiation',
    Wind = 'wind',
    Illuminance = 'illuminance',
    Dielectric = 'dielectric',
    Pressure = 'pressure',
    Overview = 'overview',
    Fieldeye = 'fieldeye'
}
