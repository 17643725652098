<div class="modal-header active">
    <div class="card-header-title">
        Select Person
    </div>
    <i class="material-icons pointer" (click)="close($event)">close</i>
</div>

<div class="modal-content white">
    <div class="person-modal-container" *ngIf="persons">
        <div class="person-modal-person" *ngFor="let person of persons">
            <div class="person-modal-person-container pointer" (click)="close(person)">
                <div class="person-modal-person-name-container">
                    <span>{{person.firstName}} {{person.lastName}} {{person?.first_name}} {{person?.last_name}}</span>
                </div>
                <div class="person-modal-person-img-container">
                    <img [src]="person.picture ? mediaUrl + person.picture : '/assets/images/people_placeholder.png'" alt="person.picture" height="100%" width="100%">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!persons" class="person-modal-no-persons">
        Persons not available
    </div>
</div>