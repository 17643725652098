<div class="overview_page">
    <!-- {{lang}} -->
    <div
        class="overview_container"
        [ngClass]="{ 'desktop-view': width >= 768, 'mobile-view': width < 768 }"
    >
    <ng-container *ngFor="let item of overviewData?.items">
        <ng-container *ngIf="item?.type === 'image'">
            <fap-overview-image [item]="item"></fap-overview-image>
        </ng-container>
    </ng-container>
        <ul>
            <li
                *ngFor="let item of imageObjects; let i = index"
                
                class="tmb"
                [ngClass]="i === counter ? 'active' : 'inactive'"
            >
                <h2
                    *ngIf="i == counter && item.title"
                    [ngStyle]="{
                        'background-color': overviewData?.css?.bg_title_color,
                        color: overviewData?.css?.title_color,
                        zoom: overviewData?.css?.zoom?.[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')]
                    }"
                >
                  {{ item?.title }}
                </h2>
                <span class="real_time" [ngStyle]="{
                    'font-size': overviewData?.css?.text_size, 
                    color: overviewData?.css?.title_color,
                    zoom: overviewData?.css?.zoom?.[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')]}">{{ currentTime }}</span>
                <div class="media" *ngIf="item.type === 'image'">
                    <img
                        *ngIf="i == counter && item?.media && item?.media.substr(-4) === '.jpg'"
                        style="width: 100%"
                        [src]="mediaUrl + item?.media + '?v='+overviewData?.version"
                    />
                    <video autoplay [muted]="'muted'" oncanplay="this.muted=true"
                        *ngIf="i == counter && item?.media.substr(-4) === '.mp4'"
                        style="width: 100%"
                    >
                        <source [src]="mediaUrl + item?.media + '?v='+overviewData?.version" type="video/mp4" />
                    </video>
                    <div class="infos" *ngIf="i == counter">
                        <ng-container
                            *ngFor="let item of item.items"
                        >
                        <div class="info"
                        [ngClass]="{ 'pc': width >= 768, 'mobile': width < 768 }"
                        [ngStyle]="{
                            position: 'absolute',
                            left: item?.position[width >= 768 ? 'pc' : 'mobile'].left,
                            top: item?.position[width >= 768 ? 'pc' : 'mobile'].top,
                            'font-size': overviewData?.css?.text_size,
                            color: overviewData?.css?.text_color,
                            overflow: 'visible',
                            'z-index': item?.type === 'box' ? '0' : '1',
                            zoom: item?.css?.zoom?.[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')] || overviewData?.css?.zoom?.[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')],
                            transform: 'translate(-'+item.position[width >= 768 ? 'pc' : 'mobile'].left +', '+ '-'+item.position[width >= 768 ? 'pc' : 'mobile'].top + ')'
                        }"
                        >

                        <div *ngIf="item?.type === 'label'" class="label_div"
                            [ngStyle]="{'width': overviewData?.css?.label_width, animation: item?.css?.animation}" 
                        >
                            <!-- <ng-container>
                                <div class="act_bg" *ngIf="item?.css?.bg_text_color" [ngStyle]="{
                                    'background-color': item?.css?.bg_text_color}"></div>
                                <div class="act_bg" *ngIf="!item?.css?.bg_text_color" [ngStyle]="{
                                    'background-color': overviewData?.css?.bg_text_color}"></div>
                            </ng-container> -->

                            <ng-container>
                                <!-- Text -->
                                <div class="label_text"
                                     [style.background-color]="item?.css?.bg_text_color || overviewData?.css?.bg_text_color"
                                     [style.color]="item?.css?.text_color || overviewData?.css?.text_color">
                                       <span class="label_icon" *ngIf="item?.data?.icon"><img [src]="mediaUrl + item?.data?.icon" alt=""></span> {{ item?.data?.text }}
                                </div>
                            
                                <!-- Value -->
                                <div class="label_value"
                                     [style.background-color]="item?.css?.bg_value_color || overviewData?.css?.bg_value_color"
                                     [style.color]="item?.css?.value_color || overviewData?.css?.value_color">
                                        {{ item?.data?.value }}
                                </div>
                            
                                <!-- Unit -->
                                <div class="label_unit"
                                     [style.background-color]="item?.css?.bg_unit_color || overviewData?.css?.bg_unit_color"
                                     [style.font-size]="item?.css?.unit_size || overviewData?.css?.unit_size"
                                     [style.color]="item?.css?.unit_color || overviewData?.css?.unit_color">
                                        {{ item?.data?.unit }}
                                </div>
                            </ng-container>
                            
                        </div>

                        <ng-container *ngIf="item?.type === 'graph'">
                            <div id="chart"
                            [ngStyle]="{
                                'height': getCalculation(item?.size[width >= 768 ? 'pc' : 'mobile']?.height),
                                'width': item?.size[width >= 768 ? 'pc' : 'mobile']?.width,
                                animation: item?.css?.animation
                              }"                              
                             [style.background]="overviewData?.css?.bg_chart_color">
                                <apx-chart
                                style="height: 100%;"
                                  [series]="item.data.series"
                                  [chart]="item.data.chart"
                                  [dataLabels]="item.data.dataLabels"
                                  [plotOptions]="item.data.plotOptions"
                                  [responsive]="item.data.responsive"
                                  [xaxis]="item.data.xaxis"
                                  [legend]="item.data.legend"
                                  [fill]="item.data.fill"
                                  [title]="item.data.title"
                                ></apx-chart>
                              </div>
                        </ng-container>

                        <ng-container *ngIf="item?.type === 'image'" [ngStyle]="{animation: item?.css?.animation}">
                            <fap-overview-image [item]="item"></fap-overview-image>
                        </ng-container>

                        <ng-container *ngIf="item?.type === 'button'">
                            <button class="custom_btn" [ngStyle]="{
                                position: 'absolute',
                                left: item.position[width >= 768 ? 'pc' : 'mobile'].left,
                                top: item.position[width >= 768 ? 'pc' : 'mobile'].top,
                                transform: 'translate(-'+item.position[width >= 768 ? 'pc' : 'mobile'].left +', '+ '-'+item.position[width >= 768 ? 'pc' : 'mobile'].top + ')',
                                color: overviewData?.css?.btn_color,
                                width: overviewData?.css?.btn_width,
                                height: overviewData?.css?.btn_height,
                                'background-color': overviewData?.css?.bg_btn_color,
                                '--pulse-color': overviewData?.css?.bg_btn_color,
                                'font-size': overviewData?.css?.text_size,
                                }" (click)="goToSlide(item?.data?.page)">
                                <span [ngStyle]="{
                                    display: block,
                                    animation: item?.css?.animation
                                }">{{item?.data?.text}} <img *ngIf="!item?.data?.text" [src]="mediaUrl + item?.data?.media"></span>
                                </button>
                        </ng-container>

                        <div *ngIf="item?.type === 'box'" class="box"
                        [ngStyle]="{
                            position: 'absolute',
                            left: item?.position[width >= 768 ? 'pc' : 'mobile'].left,
                            top: item?.position[width >= 768 ? 'pc' : 'mobile'].top,
                            'background-color': item?.css?.box_bg_color,
                            width: item?.css?.box_width,
                            height: item?.css?.box_height,
                            transform: 'translate(-'+item.position[width >= 768 ? 'pc' : 'mobile'].left +', '+ '-'+item.position[width >= 768 ? 'pc' : 'mobile'].top + ')'
                        }"
                        >
                        <div [ngStyle]="{animation: item?.css?.animation}"></div>
                        </div>
                            
                        </div>

                        </ng-container>
                    </div>
                    
                </div>

                <div class="map map-container" *ngIf="item.type === 'map'">
                    
                    <agm-map
                    [latitude]="calculateCenter(item?.items).lat"
                    [longitude]="calculateCenter(item?.items).lng"
                    [zoom]="zoom"
                    [disableDefaultUI]="false"
                    [zoomControl]="true"
                    [scaleControl]="true"
                    (mapReady)="mapReady($event)"
                    #agmMap
                >
                <agm-marker
                    *ngFor="let m of item.items"
                    [latitude]="m.coords[0]"
                    [longitude]="m.coords[1]"
                    [markerDraggable]="false"
                    [iconUrl]="m.icon? mediaUrl + m.icon : ''"
                    [animation]="'DROP'"
                    (dragEnd)="markerDragEnd(m, $event)"
                    [ngClass]="'custom-marker-label'"
                >  
                <!-- [label]="{text: m?.data && m?.data?.text ? m?.data?.text : m?.data?.en?.text}"  -->
                <agm-info-window [disableAutoPan]="false" [isOpen]="true" *ngIf="m.data">
                  <strong *ngIf="m?.data?.text">{{ m?.data?.text }} {{ m?.data?.value }} {{ m?.data?.unit }}</strong>
                  <strong *ngIf="!m?.data?.text">{{ m?.data?.en?.text }} {{ m?.data?.en?.value }} {{ m?.data?.en?.unit }}</strong>
                </agm-info-window>
              </agm-marker>
            </agm-map>
                </div>

                <ng-container *ngIf="item?.type === 'detail'" [ngStyle]="{animation: item?.css?.animation}">
                    <div class="section_container">
                        <ng-container
                            *ngFor="let item of item.items">
                            <div class="title_section">
                                <h3>{{ item?.name ? item?.name : '' }}</h3>
                                <div class="images_container" *ngIf="item.images && item.images.length">
                                    <div class="image">
                                        <img [src]="mediaUrl + item?.images[0]?.file" alt="" [ngClass]="img">
                                    </div>
                                </div>
                            </div>
                            <div class="sections" *ngIf="item?.description?.length">
                                <div class="section" *ngFor="let section of item.description">
                                    <h3>{{ section?.name ? section.name : '' }}</h3>
                                    <div class="images_container" *ngIf="section.images.length">    
                                        <div class="image">
                                            <img [src]="mediaUrl + section?.images[0]?.file" alt="" [ngClass]="img">
                                        </div>
                                    </div>
                                    <p>{{ section?.body }}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="item?.type === 'forecast'">
                    <div class="media">
                        <img
                            *ngIf="i == counter && item?.media && item?.media.substr(-4) === '.jpg'"
                            style="width: 100%"
                            [src]="mediaUrl + item?.media + '?v='+overviewData?.version"
                        />
                        <video autoplay [muted]="'muted'" oncanplay="this.muted=true"
                            *ngIf="i == counter && item?.media && item?.media.substr(-4) === '.mp4'"
                            style="width: 100%"
                        >
                            <source [src]="mediaUrl + item?.media + '?v='+overviewData?.version" type="video/mp4" />
                        </video>
                    </div>
                    <div class="section_container">
                        <fap-weather-forecast [items]="item.items" [css]="overviewData?.css" [itemCss]="item?.css"></fap-weather-forecast>
                    </div>
                </ng-container>
            </li>
        </ul>

        <div class="nav_btns" [ngStyle]="{
            zoom: overviewData?.css?.zoom?.[height >= 1900 ? 'panel' : (height > 768 ? 'pc' : 'mobile')]
        }">
            <button (click)="showPreviousImage()" class="cbtn prev"><</button>
            <button (click)="showNextImage()" class="cbtn next">></button>
        </div>
    </div>
</div>
