import { FapBaseModel } from '../../base/fap-base-model';
import { PartyModel } from '../party/party.model';
import { UserDataModel } from './user.data.model';

export class UserProfileModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'address_id',
        'role',
        'company',
        'job',
        'phone',
        'picture',
        'party',
        'display',
        'data'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'role'
    ];

    public static extendedFields: {} = {
        party: PartyModel
    };


    public id: number;
    public addressId: number;
    public job: string;
    public picture: string;
    public role: number;
    public phone: number;
    public company: any;
    public party: PartyModel = new PartyModel();
    public data: UserDataModel = new UserDataModel();
    public display: string;

    public get pictureSrc(): string {
        return this.picture ? this.picture : '';
    }
}
