import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { LotModel } from '../../../models/lot/lot.model';
import { LotInterface } from '../../../interfaces/lot/lot.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';


@Injectable()
export class LotService extends FapRestBaseService {
    public selectLot: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/farm/lots/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getLots(params?: {}): Observable<ResponseModel<LotModel[]>> {
        return this.mapRequest(
            this._http.get(this.url,
                {
                    headers: FapAPIRequestOptions.withTokenHeaders,
                    params
                }),
            LotModel,
            true
        );
    }

    public getLot(lotId: number): Observable<ResponseModel<LotModel>> {
        return this.mapRequest<LotModel>(
            this._http.get(this.url + lotId + '/',
                FapAPIRequestOptions.withTokenRequestOptions),
            LotModel,
            false
        ).pipe(take(1));
    }

    public updateLot(lotId: number, lot: LotInterface): Observable<ResponseModel<LotModel>> {
        return this.mapRequest(
            this._http.patch(this.url + lotId + '/',
                this.camelCaseModelToSnakeCaseJson(lot),
                FapAPIRequestOptions.withTokenRequestOptions),
            LotModel,
            false
        );
    }

    public createLot(lot: LotInterface): Observable<ResponseModel<LotModel>> {
        return this.mapRequest(
            this._http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(lot),
                FapAPIRequestOptions.withTokenRequestOptions),
            LotModel,
            false
        );
    }

    public deleteLot(lotId: number): Observable<{}> {
        return this.mapRequest(
            this._http.delete(this.url + lotId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        );
    }
}
