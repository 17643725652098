<div class="dashboard-card dash_cont">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 p-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <!-- <div class="col-md-6 col_lot_name" *ngIf="lotName !== ''">
            <h2>{{ lotName }}<span class="color-semiDark"></span></h2>
        </div> -->
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em
      > -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="dataSource.length == 0" style="display: flex; align-items:center; justify-content: center; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 70%; width: 100%;">
        <h3 style="text-align: center;">
          <span class="loader">
            <i></i>
          </span>
      </h3>
      </div> -->
    <div class="card-content">
        <agm-map
          [latitude]="currentCenter?.lat"
          [longitude]="currentCenter?.lng"
          [zoom]="zoom"
          [disableDefaultUI]="false"
          [zoomControl]="false"
          [scaleControl]="false"
          [streetViewControl]="false"
          [mapTypeControl]="false"
          [mapTypeId]="'hybrid'"
          [fitBounds]="bounds"
          (mapReady)="mapReady($event)"
        >
          <agm-marker
            *ngFor="let m of markers; let i = index"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [label]="m.label"
            [markerDraggable]="m.draggable"
            [iconUrl]="m.iconUrl"
            [animation]="'DROP'"
            (dragEnd)="markerDragEnd(m, $event)"
          >
            <!-- [animation]="'DROP'"  -->
      
            <agm-info-window>
                <strong>{{ m.infoWindowDetails && m.infoWindowDetails.length > 0 ? m.infoWindowDetails[0].text : '' }}</strong>
            </agm-info-window>
          </agm-marker>

          <!-- <ng-container>
            <agm-polygon
                *ngFor="let polygon of mapPolygons; let index = index"
                [editable]="isEditMode&&polygon.isEditable!==undefined&&polygon.isEditable!==null?polygon.isEditable:isEditMode"
                [paths]="polygon.points"
                [zIndex]="1000"
                [draggable]="false"
                [strokeColor]="polygon.strokeColor"
                [fillColor]="polygon.fillColor"
                [strokeWeight]="polygon.strokeWeight"
            >
            </agm-polygon>
        </ng-container> -->

        <ng-container>
            <agm-polyline *ngFor="let polyline of mapPolygons;let i = index;"
                          [strokeColor]="polyline.strokeColor"
                          [editable]="false"
                          >
                <agm-polyline-point *ngFor="let point of polyline.points"
                                    [latitude]="point.lat"
                                    [longitude]="point.lng">
                </agm-polyline-point>
            </agm-polyline>
        </ng-container>
        </agm-map>
    </div>
</div>
