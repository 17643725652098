import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { UnitModel } from '../../../models/units/unit.model';
import { take } from 'rxjs/operators';
import { UnitInterface } from '../../../interfaces/unit/unit.interface';


@Injectable()
export class UnitService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/attribute/units/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public getUnits(params?:any): Observable<ResponseModel<UnitModel[]>> {
        return this.mapRequest<UnitModel[]>(
            this._http.get(this.url, {
                headers:  FapAPIRequestOptions.withTokenHeaders,
                params
            }),
            UnitModel,
            true
        );
    }

    public getUnit(unitId: number): Observable<ResponseModel<UnitModel>> {
        return this.mapRequest<UnitModel>(
                this.http.get(this.url + unitId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            UnitModel
        );
    }

    public getUnitByGenre(genre: string): Observable<ResponseModel<UnitModel[]>> {
        return this.mapRequest<UnitModel[]>(
            this.http.get(this.url + '?genre=' + genre,
            FapAPIRequestOptions.withTokenRequestOptions
        ),
        UnitModel,
        true
    );
    }

    public addUnit(unit: UnitInterface): Observable<ResponseModel<UnitModel>> {
        return this.mapRequest<UnitModel>(
            this.http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(unit),
                FapAPIRequestOptions.withTokenRequestOptions,
            ),
            UnitModel
        ).pipe(take(1));
    }

    public updateUnit(unit: UnitInterface): Observable<any> {
        return this.mapRequest(
            this.http.patch(this.url + unit.id + '/',
                this.camelCaseModelToSnakeCaseJson(unit),
                FapAPIRequestOptions.withTokenRequestOptions
            )
        ).pipe(take(1));
    }

    public deleteUnit(unitId: number): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this.http.delete(this.url + unitId + '/',
                FapAPIRequestOptions.withTokenRequestOptions
            )).pipe(take(1));
    }
}
