import { FapBaseModel } from '../../base/fap-base-model';

export class TranslationModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'en',
        'fr',
        'de',
        'es',
        'ro',
        '11',
        '12',
        '13',
        '14',
        '15'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: any;
    public en: string;
    public fr: string;
    public de: string;
    public es: string;
    public ro: string;
    public '11': string;
    public '12': string;
    public '13' : string;
    public '14' : string;
    public '15' : string
}
