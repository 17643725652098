import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit {

  public msg: string = ''

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    console.log(this.activatedRoute.snapshot.queryParams.authorize);
    if(this.activatedRoute.snapshot.queryParams.authorize == 0) {
      this.msg = 'Authorization failed... Please try again!'
    } else if(this.activatedRoute.snapshot.queryParams.authorize == 1) {
      this.msg = 'Authorization successful... Please close the window and click "e-invoice" button to proceed'
    } else {
      this.msg = 'checking authorization...'
    }
  }

  closeWindow() {
    window.close();
  }
}
