<div class="nc">
    <div id="print"> 
        <div class="wrapper" style="width: 100%; margin: auto; padding: 15px;">
            <div class="clearfix">
                <div class="top_section">
                    <div class="top_left" style="display: inline-block; width: 35%; vertical-align: top;">
                        <img src="../../../../../../assets/images/fap/platform_logo.svg" alt="">
                    </div>
                    <div class="top_center" style="display: inline-block; width: 30%; padding-right: 2%; vertical-align: top;">
                        <h3>Invoice</h3>
                        <div>
                            <b style="padding-right: 6px;">Nr:</b><span>{{this.bill?.doc_id}}</span>
                        </div>
                        <div>
                            <b style="padding-right: 6px;">Date (day,month,year):</b>
                            <span>{{this.bill?.doc_date | date: 'DD-MM-YYYY'}}</span>
                        </div>
                    </div>
                    <div class="top_right" style="display: inline-block; width: 35%; vertical-align: top;">
                        <div>
                            <b>Customer:</b>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <button #exportPDFbtn (click)="print()">print</button>
</div>