<div class="page-wrapper">
  <div class="auth-bg">
    <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
      <div class="authentication-box">
        <div class="card mt-4 p-4">
          <h4 class="text-center">{{ 'auth.company.newCompany' |translate }}</h4>
          <!-- <h6 class="text-center">{{ 'auth.company.companyNameLabel' |translate }}</h6> -->
          <form class="theme-form" [formGroup]="createCompanyForm">
            <div class="row">
            <div class="form-group col-6">
              <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'auth.company.type' | translate }}*</mat-label>
                <mat-select formControlName="type" (selectionChange)="changeType($event)">
                  <mat-form-field appearance="fill" class="w-100">
                      <input matInput #typeFilter placeholder="Search">
                  </mat-form-field>
                  <div class="scroll_wrap">
                  <mat-option style="height: 0!important;"></mat-option>
                  <mat-option *ngFor="let type of types | filter : typeFilter.value" [value]="type">
                      <span style="text-transform: capitalize;">{{ type }}</span>
                  </mat-option>
                  </div>
                </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group col-6">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.companyName' | translate }}*</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="ifControlHasError('name','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('name','minlength')">
                      <strong>{{ 'auth.validation.minLengthField' | translate }} 3</strong>
                  </mat-error>
                  <mat-error *ngIf="ifControlHasError('name','maxlength')">
                      <strong>{{ 'auth.validation.maxLengthField' | translate }} 100</strong>
                  </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="form-group"> -->
            <!-- <mat-form-field class="w-100">
                <mat-label class="col-form-label">{{ 'auth.company.billingAddress' | translate }}*</mat-label>
                <input matInput formControlName="billingAddress">
                <mat-error *ngIf="ifControlHasError('billingAddress','required')">
                  <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                </mat-error>
            </mat-form-field> -->
            <!-- <fap-add-edit-delete-select
                    inputName="{{ 'people.address' | translate }}"
                    class="w-100"
                    [formControl]="createCompanyForm.controls['billingAddress']"
                    name="billingAddress"
                    [inputIsRequired]="true"
                    [inputValueFieldName]="'id'"
                    [inputNameFieldName]="'street'"
                    [inputSelectValues]="globalRegistry?.systemData?.addresses"
                    [inputConfirmDeleteText]="'party.deleteAddressMessage' | translate"
                    (outputOnDelete)="onDeleteAddress($event)"
                    (outputOnCreate)="addAddressModal()"
                    (outputOnEdit)="editAddress($event)">
                </fap-add-edit-delete-select> -->
          <!-- </div> -->
          <ng-container *ngIf="createCompanyForm.get('type').value === 'company'">
            <div class="row">
            <div class="form-group col-6">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.cif' | translate }}</mat-label>
                  <input matInput formControlName="cif">
                  <mat-error *ngIf="ifControlHasError('cif','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-6">
              <mat-form-field class="w-100">
                  <mat-label class="col-form-label">{{ 'auth.company.vat' | translate }}</mat-label>
                  <input matInput formControlName="vat" type="text">
                  <mat-error *ngIf="ifControlHasError('vat','required')">
                    <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                  </mat-error>
              </mat-form-field>
            </div>
            </div>
          </ng-container>
          
            <div class="form-group adress-field">
              <div class="w-100">
                  <mat-label class="col-form-label" [ngClass]="fullAddress ? 'float' : ''">{{ 'user.profile.address' | translate }}</mat-label>
                  <input matInput formControlName="address_id" style="display: none;">
                  <div class="full_address" (click)="editAddress(fullAddress)">
                    {{fullAddress?.number ? fullAddress?.number+',' : ''}} {{fullAddress?.street ? fullAddress?.street+',' : ''}} {{fullAddress?.city ? fullAddress?.city+',' : ''}} {{fullAddress?.postal_code ? fullAddress?.postal_code+'' : ''}} {{fullAddress?.country ? fullAddress?.country : ''}}
                  </div>                          
                </div>
          </div>
          <div>
            <p style="font-weight: bold; font-size: 15px;">{{ 'auth.company.markPushpin' | translate }}</p>
          </div>
          <div class="form-group map-container">
            <div
              class="search"
              [ngClass]="{ opened: mapSearchToggle, 'd-flex': mapTypeControl }"
            >
              <label for="search" class="fa fa-search" (click)="getFocus()"> </label>
                <input
                matInput
                type="text"
                class="form-control color-black"
                (keydown.enter)="$event.preventDefault()"
                placeholder="Search map"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="off"
                type="text"
                #searchRef1
                autofocus
                />
              
            </div>
            <agm-map
              [latitude]="currentCenter.lat"
              [longitude]="currentCenter.lng"
              [zoom]="zoom"
              [disableDefaultUI]="false"
              [zoomControl]="true"
              [scaleControl]="true"
              [streetViewControl]="false"
              [mapTypeControl]="false"
              (mapClick)="mapClicked($event)"
              (mapReady)="mapReady($event)"
            >
              <agm-marker
                *ngFor="let m of markers; let i = index"
                [latitude]="m.lat"
                [longitude]="m.lng"
                [label]="m.label"
                [markerDraggable]="m.draggable"
                [iconUrl]="m.iconUrl"
                [animation]="'DROP'"
                (dragEnd)="markerDragEnd(m, $event)"
              >
                <!-- [animation]="'DROP'"  -->
          
                <agm-info-window>
                  <strong>{{ m.content }}</strong>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>
          <div class="form-row">
              <div class="col-sm-12">
                  <button class="btn btn-success" type="submit" (click)="submit()">{{'createAccount' | translate}}</button>
              </div>
          </div>
          </form>
        </div>
      </div>
    </div>
</div>
<fap-modal #addEditAddressModal inputTitle="{{ 'party.address.addEditAddressModal' | translate }}" [inputIsLarge]="true">
  <fap-add-edit-address 
    [address]="selectedAddress" 
    [type]="type" 
    [showExtraFields]="false" 
    (cancel)="addEditAddressModal.hideModal()"
    (addedAddress)="addressAdd($event);createCompanyForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()"
    (updatedAddress)="addressUpdate($event);createCompanyForm.controls['address_id'].setValue($event.id);addEditAddressModal.hideModal()">
  </fap-add-edit-address>
</fap-modal>