import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { PeopleGroupModel } from '../../../models/groups/people-group.model';
import { PeopleGroupInterface } from './data/people-group.interface';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';


@Injectable()
export class PeopleGroupService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/company/people_group/');
    }

    getUrl(slug) {
        return this.url+slug
    }

    public addGroup(group: PeopleGroupInterface): Observable<ResponseModel<PeopleGroupModel>> {
        return this.mapRequest<PeopleGroupModel>(
            this._http.post(this.url,
                this.camelCaseModelToSnakeCaseJson(group),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            PeopleGroupModel,
            false
        ).pipe(take(1));
    }

    public updateGroup(group: PeopleGroupInterface): Observable<ResponseModel<PeopleGroupModel>> {
        return this.mapRequest<PeopleGroupModel>(
            this._http.patch(this.url + group.id + '/',
                this.camelCaseModelToSnakeCaseJson(group),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            PeopleGroupModel,
            false
        ).pipe(take(1));
    }

    public getPeopleGroups(): Observable<ResponseModel<PeopleGroupModel[]>> {
        return this.mapRequest<PeopleGroupModel[]>(
            this._http.get(this.url, {
                headers: FapAPIRequestOptions.withTokenHeaders
            }),
            PeopleGroupModel,
            true
        ).pipe(take(1));
    }

    public deleteGroup(groupId: number): Observable<any> {
        return this.mapRequest(
            this._http.delete(this.url + groupId + '/',
                FapAPIRequestOptions.withTokenRequestOptions)
        ).pipe(take(1));
    }}
