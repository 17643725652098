import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private timezoneDataUrl = 'assets/timezone/timezone.json';
  private timezoneData: any;

  constructor(private http: HttpClient) {
    this.getTimezoneData().subscribe(data => {
      this.timezoneData = data;
    });
  }

  private getTimezoneData(): Observable<any> {
    return this.http.get<any>(this.timezoneDataUrl);
  }

  getUserTimezone(countryCode: string): string | undefined {
    const userTimezone = this.timezoneData?.data.find(
      (timezone) => timezone.ShortName === countryCode
    );

    return userTimezone?.TZString?.[0];
  }
}
