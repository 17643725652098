import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fap-section-header',
  templateUrl: './fap-section-header.component.html',
  styleUrls: ['./fap-section-header.component.scss']
})
export class FapSectionHeaderComponent {

  @Input() public name: string = '';
  @Input() public title: string = '';
  @Input() public isEditable: boolean = false;
  @Input() public isAddEnabled: boolean = false;
  @Input() public isRefresh: boolean = false;
  @Input() public sticky: boolean = true;

  @Output()
  public clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public editClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public refreshClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
}
