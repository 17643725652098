import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { WidgetInfo } from '../../../../../core/interfaces/widget/widget-info.interface';
import { WidgetHelper } from '../../../../../core/heplers/widget.helper';

@Component({
    selector: 'fap-select-widget',
    templateUrl: './fap_select-widget.component.html',
    styleUrls: ['./fap_select-widget.component.scss']
})
export class FapSelectWidgetComponent implements OnChanges {

    @Input()
    public selectedWidgetType: string;

    @Input()
    public contentType: string;

    @Output()
    public selectWidget: EventEmitter<string> = new EventEmitter();

    public widgets: string[];
    public widgetInfo: {[key: string]: WidgetInfo};
    public selectedWidget: string;

    constructor() {
        this.widgetInfo = WidgetHelper.getWidgetInfo();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'selectedWidgetType') || Object.prototype.hasOwnProperty.call(changes, 'contentType')){
            if (this.selectedWidgetType && this.contentType ) {
                this.widgets = WidgetHelper.getWidgetsByInfoType(this.selectedWidgetType, this.contentType);
            }
        }
    }

    public clickWidget(widget: string): void {
        this.selectedWidget = widget;
        this.selectWidget.emit(widget);
    }

    public reset(): void {
        this.selectedWidget = null;
        this.selectWidget.emit(null);
    }
}
