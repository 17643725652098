import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheResolverService } from '../services/api/cache/cache-resolver.service';
  
  const TIME_TO_LIVE = null;
  
  @Injectable()
  export class CacheInterceptor implements HttpInterceptor {
    constructor(private cacheResolver: CacheResolverService) {}
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      if (req.method !== 'GET') {
        return next.handle(req);
      }
  
      if (req.headers.get('x-refresh')) {
        return this.sendRequest(req, next);
      }
  
      const cachedResponse = this.cacheResolver.get(req.url, req.params);
      return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next);
    }
  
    sendRequest(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            this.cacheResolver.set(req.url, req.params, event, TIME_TO_LIVE);
          }
        })
      );
    }
  }
  