<div class="camera_bg">

    <ng-container>
        <div class="position_info" [ngClass]="position" >
            <div *ngIf="position === 'closeup'" [innerHTML]="'alergotel.closeUpPicture' | translate"></div>
            <div *ngIf="position === 'left'" [innerHTML]="'alergotel.leftPicture' | translate"></div>
            <div *ngIf="position === 'right'" [innerHTML]="'alergotel.rightPicture' | translate"></div>
        </div>
    </ng-container>
    
    <div class="media_content">
        <img #capturedImage class="hightlight_image"/>
        <video #cameraVideo autoplay="true" [hidden]="!showVideo"></video>
        <input type="file" accept="image/*" (change)="handleFileInput($event)" #fileInput hidden />

        <div class="btns">
            <button class="btn" (click)="openCamera()" [hidden]="cameraOpened">Open Camera</button>
            <button class="btn" (click)="takePicture()" *ngIf="!image && showTakeButton">Take Picture</button>
            <button class="btn" (click)="chooseFromGallery()">Choose from Gallery</button>
            <button class="btn" (click)="retake()" *ngIf="showRetakeButton">Retake</button>
        </div>
    </div>

    <div class="hint_wrapper">
        <div *ngIf="position === 'closeup'" class="text">{{ 'alergotel.closeUpPictureHint' | translate }}</div>
        <div *ngIf="position === 'left' || position === 'right'" class="text">{{ 'alergotel.leftPictureHint' | translate }}</div>
        <button class="btn next_btn" (click)="next()">
            <img src="../../../../../assets/icons/right_arrow.svg" alt="next"> {{ 'alergotel.next' | translate }}
        </button>
    </div>

</div>