import { Routes } from '@angular/router';

import { lotsContainerComponent } from './containers/crops-container/crops-container.component';

export const lotsRoutes: Routes = [
    {
        path:       '',
        component:  lotsContainerComponent
    }
];
