import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private _locale = 'en'; // Default locale is English

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
  }
}
