import { Component } from '@angular/core';


@Component({
    templateUrl: './party.component.html',
    styleUrls: ['./party.component.scss']
})
export class PartyComponent {

}
