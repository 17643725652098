import { FapStockBillsComponent } from './components/fap_bills/fap_stock-bills.component';
import { FapStockBillDetailsComponent } from './components/fap_bill-details/fap_stock-bill-details.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from '../../../shared/shared.module';
import { StockComponent } from './stock.component';
import { PartialsModule } from '../../views-partials/partials.module';
import { StockContainerComponent } from './containers/stock-container/stock-container.component';
import { FapStockLayoutComponent } from './components/fap_stock-layout/fap_stock-layout.component';
import { StockFarmContainerComponent } from './containers/stock-farm-container/stock-farm-container.component';
import { FapStockOverviewComponent } from './components/fap_stock-overview/fap_stock-overview.component';
import { FapStockTransactionsComponent } from './components/fap_transactions/fap_stock-transactions.component';
import { FapStockListComponent } from './components/fap_stock-list/fap_stock-list.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FapStockItemsComponent } from './components/fap_stock-items/fap-stock-items.component';
import { PrintPdfComponent } from './components/print-pdf/print-pdf.component';
import { PrintExcelComponent } from './components/print-excel/print-excel.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatCardModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        PartialsModule,
        InfiniteScrollModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxNativeDateModule,
        NgxMatMomentModule,
    ],
    declarations: [
        StockComponent,
        StockContainerComponent,
        FapStockLayoutComponent,
        StockFarmContainerComponent,
        FapStockOverviewComponent,
        FapStockTransactionsComponent,
        FapStockBillsComponent,
        FapStockBillDetailsComponent,
        FapStockListComponent,
        FapStockItemsComponent,
        PrintPdfComponent,
        PrintExcelComponent
    ]
})

export class StockModule {

}
