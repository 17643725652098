import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../../shared/shared.module';
import { WeedsComponent } from './weeds.component';
import { WeedsContainerComponent } from './containers/weeds-container/weeds-container.component';
import { WeedsEditContainerComponent } from './containers/weeds-edit-container/weeds-edit-container.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PartialsModule } from '../../views-partials/partials.module';



@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        PartialsModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    declarations: [
        WeedsComponent,
        WeedsContainerComponent,
        WeedsEditContainerComponent,
    ],
})

export class WeedsModule {

}
