import { Component, Input, OnInit } from '@angular/core';
import { GlobalRegistryService } from '../../../../core/global-registry/global-registry.service';
@Component({
  selector: 'items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss']
})
export class ItemsTableComponent implements OnInit{
  @Input() items: any[];
  @Input() showCosts: boolean = false;
  @Input() cost: any;
  @Input() display: string = 'block';
  public defaultCurrency: number;
  public defaultDigits: number;
  public langString: string;
  
  constructor(public globalRegistry: GlobalRegistryService) {}

  ngOnInit(): void {
    this.defaultCurrency = this.globalRegistry.systemData.company.currency;
  }

    getColor(realQty: number, estimationQty: number): string {
        if(realQty > estimationQty) {
            return 'red';
        } else {
            return 'green'
        }
    }
}
