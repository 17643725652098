<div class="section_container">
    <fap-section-header [style.position]="sticky ? 'sticky' : 'relative'"
        [title]="title" 
        [isAddEnabled]="isAddEnabled" 
        [isRefresh]="isRefresh"
        [isEditable]="isEditable"
        [sticky]="sticky"
        (editClicked)="editClicked.emit()"
        (clicked)="clicked.emit()"
        (refreshClicked)="refreshClicked.emit()"
    ></fap-section-header>
    <fap-section-body>
        <div class="card-body d-flex flex-wrap white-background justify-content-between w-100" [ngClass]="isTable ? 'is_table' : ''">
            <ng-content></ng-content>
        </div>
    </fap-section-body>
</div>