<div class="dashboard-card dash_cont" style="position: relative">
    <div class="d-flex justify-content-between">
        <div class="col-md-4 pl-0">
            <h1>{{ widget.config["nameT"] ? getTranslation(widget.config["nameT"]) : widget.config["name"] }}</h1>
        </div>
        <div class="col-md-6 col_lot_name" *ngIf="getLotName() !== ''">
            <h2>{{ getLotName() }}<span class="color-semiDark"></span></h2>
        </div>
        <div class="col-md-2 text-right p-0">
            <!-- <em class="material-icons edit c-pointer" (click)="emitShow.emit(true)"
        >edit</em> -->
            <span class="conf_icon" (click)="toggleMenu()">
                <i></i>
                <i></i>
                <i></i>
            </span>
            <div class="new_menu {{ toggleMain }}">
                <ul (click)="hideMain()">
                    <!-- <li (click)="configModal.showModal()"><span>{{"widget.changeView" | translate}}</span></li> -->
                    <li (click)="emitShow.emit(true)"><span>{{"widget.settings" | translate}}</span></li>
                </ul>
            </div>
        </div>
    </div>
    <div
        *ngIf="isLoading"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
        "
    >
        <h3 style="text-align: center">
            <span class="loader">
                <i></i>
            </span>
        </h3>
    </div>
    <div class="adjustable_div">
        <devices-list 
            [devices]="devices" 
            [deviceData]="deviceData"
            [deviceColumns]="deviceColumns"
            [showHeader]="false" 
            [bgColor]="bgColor" 
            [profiles]="profiles"
            [sensorGroupTypes]="sensorGroupTypes" 
            [sensorGroupTypeId]="sensorGroupTypeId"
            (refreshSensorGroupTypes)="refreshSensorGroupTypes.emit($event)"
            
            (setTriggerSensorGroupTypes)="setTriggerSensorGroupTypes.emit($event)"
            [triggerSensorGroupTypes]="triggerSensorGroupTypes"
            [widgetLot]="widget.objectId"
        ></devices-list>
    </div>