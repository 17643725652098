import { FapBaseModel } from '../../base/fap-base-model';
import { TreatmentModel } from './treatment.model';

export class DiseaseModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'latin',
        'name_t',
        'type',
        'images',
        'preventions',
        'treatments',
        'data',
        'info'
    ];

    public static requiredFields: Array<string> = [
        'id',
        'name',
        'latin',
        'type',
        'treatments',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public name: string;
    public latin: string;
    public nameT: string;
    public type: string;
    public images: number[];
    public preventions: any[];
    public treatments: TreatmentModel[];
    public data: any;
    public info: string;
}
