import { FapBaseModel } from '../../base/fap-base-model';
import { PolygonCoordsInterface } from '../../interfaces/coords/polygon-coords.interface';
import { CropModel } from '../crops/crop.model';


export class LotModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'name',
        'farm',
        'coords',
        'fields',
        'current_crop',
        'static_map_image',
        'geopard_id'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: any = {
        current_crop: CropModel
    };

    public id: number;
    public name: string;
    public farm: number;
    public coords: PolygonCoordsInterface;
    public fields: Array<number>;
    public currentCrop: CropModel = new CropModel();
    public staticMapImage: string;
    public geopardId: string;

    public get previewImg(): string {
        if(this.staticMapImage) {
            return this.staticMapImage;
        }
        return '/assets/images/no_image_available.svg';
    }
}
