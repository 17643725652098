import { AttributesModule } from './attributes/attributes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { viewsRouting } from './pages.routing';
import { AuthViewsModule } from './auth-views/auth-views.module';
import { HomeModule } from './home/home.module';
import { LotsModule } from './crops/crops.module';
import { WeedsModule } from './weeds/weeds.module';
import { PeopleModule } from './people/people.module';
import { StockModule } from './stock/stock.module';
import { EquipmentsModule } from './equipments/equipments.module';
import { InsectsModule } from './insects/insects.module';
import { FarmModule } from './farm/farm.module';
import { PathogensModule } from './pathogens/pathogens.module';
import { SharedModule } from '../../shared/shared.module';
import { BaseComponent } from '../../shared/layout/base/base.component';
import { LotModule } from './lot/lot.module';
import { MediaContentResolver } from './auth-views/resolver/media-content.resolver';
import { MediaService } from '../../core/services/api/media/media.service';
import { CompanyModule } from './company/company.module';
import { FieldModule } from './field/field.module';
import { PartialsModule } from '../views-partials/partials.module';
import { UserModule } from './user/user.module';
import { ActivityModule } from './activity/activity.module';
import { UnitsModule } from './units/units.module';
import { NotesModule } from './notes/notes.module';
import { PartyModule } from './party/party.module';
import { PrecisionModule } from './precision/precision.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ActivitiesModule } from './activities/activities.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { OverviewModule } from './fap-overview/fap-overview.module';
import { SchedulerModule } from './scheduler/scheduler.module';
import { TreatmentsModule } from './treatments/treatments.module';
import { PostsModule } from './posts/posts.module';
import { ReportsModule } from './reports/reports.module';
import { MyProductsModule } from './my-products/my-products.module';
import { DevicesModule } from './devices/devices.module';
import { PlanModule } from './plan/plan.module';
import { SimulationsModule } from './simulations/simulations.module';
import { PoolsModule } from './pools/pools.module';
import { ParticlesModule } from './alergotel/particles/particles.module';
import { RecommendationsModule } from './alergotel/recommendations/recommendations.module';
import { AllergyProfileModule } from './alergotel/allergy-profile/allergy-profile.module';
import { ImageOrientationDirective } from '../../shared/directives/image-orientation.directive';
import { ImageOrientationPipe } from '../../core/pipes/image.orientation.pipe';
import { DeleteAccountModule } from './delete-account/delete-account.module';
import { AlergotelReportsModule } from './alergotel/reports/reports.module';
import { FormModule } from './forms/forms.module';
import { WorkflowsModule } from './workflows/workflows.module';
import { AuthorizeComponent } from './authorize/authorize.component';
import { CropsModule } from './crop/crops.module';
import { PlansComponent } from './plans/plans.component';
import { EntitiesModule } from './entities/entities.module';
import { PlanningModule } from './planning/planning.module';

@NgModule({
    imports: [
        CommonModule,
        viewsRouting,
        SharedModule,
        AuthViewsModule,
        HomeModule,
        LotsModule,
        PeopleModule,
        StockModule,
        EquipmentsModule,
        WeedsModule,
        InsectsModule,
        FarmModule,
        LotModule,
        CompanyModule,
        FieldModule,
        PathogensModule,
        UserModule,
        PartialsModule,
        ActivityModule,
        UnitsModule,
        NotesModule,
        PartyModule,
        AttributesModule,
        PrecisionModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatListModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        ActivitiesModule,
        DashboardModule,
        OverviewModule,
        SchedulerModule,
        TreatmentsModule,
        PostsModule,
        ReportsModule,
        MyProductsModule,
        DevicesModule,
        PlanModule,
        SimulationsModule,
        PoolsModule,
        ParticlesModule,
        RecommendationsModule,
        AllergyProfileModule,
        DeleteAccountModule,
        AlergotelReportsModule,
        FormModule,
        WorkflowsModule,
        CropsModule,
        EntitiesModule,
        PlanningModule
    ],
    declarations: [
        BaseComponent,
        ImageOrientationDirective,
        ImageOrientationPipe,
        AuthorizeComponent,
        PlansComponent,
    ],
    providers: [
        MediaContentResolver,
        MediaService
    ],
    exports: [ImageOrientationDirective, ImageOrientationPipe]
})
export class PagesModule {
}
