import { FapBaseModel } from '../../base/fap-base-model';

export class DocumentModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'type',
        'doc_id',
        'doc_date',
        'party_from',
        'party_to',
        'created_at',
        'updated_at',
        'receive_by',
        'receive_date',
        'farm',
        'currency'
    ];

    public static requiredFields: Array<string> = [
        'id'
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public type: string;
    public docId: string;
    public docDate: string;
    public partyFrom: number;
    public partyTo: number;
    public createdAt: string;
    public updatedAt: string;
    public receiveBy: number;
    public receiveDate: string;
    public farm: number;
    public currency: number;
}
