<div class="container-fluid ter-container">
    <div class="cust_pad_fields">
        <fap-field-layout
            [lots]="filteredLots"
            [fields]="filteredFields"
            (clickedFieldCard)="showFieldOnMap($event)"
            (clickedLotTitle)="showLotOnMap($event)"
            (deleteLot)="deleteLot($event)"
            (deleteField)="deleteField($event)">
        </fap-field-layout>
    </div>
</div>
