<div class="page-wrapper" id="parent">
    <div class="auth-bg">
        <div class="bg_img" style="background-image: url( {{mediaUrl}} );"></div>
        <div class="authentication-box">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="text-center">
                        <h4>{{ 'auth.login.loginMessage' | translate }}</h4>
                        <h6>{{ 'auth.login.requiredFields' | translate }}</h6>
                    </div>
                    <form class="theme-form" [formGroup]="loginForm">
                        <div class="form-group">
                            <mat-form-field class="w-100" cdkFocusInitial>
                                <mat-label class="col-form-label">Email</mat-label>
                                <input matInput formControlName="email" type="email" name="email" autofocus>
                                <mat-error *ngIf="ifControlHasError('email','email')">
                                  <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="ifControlHasError('email','required')">
                                  <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label class="col-form-label">Password</mat-label>
                                <input class="pwd" matInput formControlName="password" [type]="show ? 'text' : 'password'">
                                <span [ngClass]="show ? 'fa-eye-slash' : 'fa-eye'" class="form-control-feedback fa fa-eye" (click)="togglePassword()"></span>
                                <mat-error *ngIf="ifControlHasError('password','required')">
                                  <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                          </div>
                        <!-- <div class="checkbox p-0">
                            <input id="checkbox1" type="checkbox">
                            <label for="checkbox1">Remember me</label>
                        </div> -->
                        <div class="form-group d-flex justify-content-end" style="margin: -15px 0 0;">
                            <div class="text-right d-inline-block">
                                <span class="btn-link text-capitalize" (click)="router.navigateByUrl('/reset_password')">Forgot Password?</span> 
                            </div>
                        </div>
                        <div class="form-group form-row mt-3 mb-0">
                            <div class="col-sm-4">
                                <button class="btn btn-success btn-block btn-lg"
                                    (click)="login(loginForm.value)"
                                    type="submit">
                                    <span>Login</span>
                                </button>
                            </div>
                            <div class="col-sm-8">
                                <div class="text-left mt-2 m-l-20">{{ 'auth.login.missingAccount' | translate }}<button class="btn-link text-capitalize" (click)="router.navigateByUrl('/register')">{{ 'auth.login.registerLabel' | translate }}</button></div>
                            </div>
                        </div>
                        <div class="verisonNumber">
                            {{getTranslationsByKey('version')}} {{versionNumber}}
                        </div>
                        <!-- <div class="login-divider"></div>
                        <div class="social mt-3">
                            <div class="form-group btn-showcase d-flex">
                                <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
                                    <i class="fa fa-facebook"></i>
                                </button>
                                <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
                                    <i class="fa fa-twitter"></i>
                                </button>
                                <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
                                    <i class="fa fa-google"></i>
                                </button>
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<fap-modal
  [inputTitle]="'auth.changePassword' | translate"
  #changePasswordModal
  [inputPrimaryButtonInterface]="changePasswordModalButtonPrimaryInterface"
  [inputSecondaryButtonInterface]="changePasswordModalButtonSecondaryInterface"
>
    <form [formGroup]="emailForm">
        <div class="form-group mb-0">
            <div class="row align-items-baseline">
                <div class="col-12">
                    <div class="input-group w-100">
                        <div class="col-12">
                            <mat-form-field class="w-100">
                                <mat-label class="col-form-label">Email</mat-label>
                                <input matInput formControlName="email">
                                <mat-error *ngIf="ifControlHasError('email','email')">
                                  <strong>{{ 'auth.validation.invalidField' | translate }}</strong>
                                </mat-error>
                                <mat-error *ngIf="ifControlHasError('email','required')">
                                  <strong>{{ 'auth.validation.requiredField' | translate }}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-12 mt-1">
                            <button class="btn btn-success" type="submit" (click)="emailSubmit()">{{ 'submit' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</fap-modal>