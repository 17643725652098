import { FapBaseModel } from '../../base/fap-base-model';
export class ActivityModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'started',
        'ended',
        'name',
        'info',
        'type',
        'assigned_to',
        'attached_equipments',
        'name_t',
        'completed',
        'resources',
        'plan',
        'parent',
        'schedule',
        'images',
        'workflows',
        'baseline',
        'resources_doc',
        'children'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id?: number;
    public started: string;
    public ended: string;
    public name: string;
    public info: string;
    public type: number;
    public assignedTo: number;
    public attachedEquipments: any[];
    public nameT: string;
    public completed?: boolean;
    public resources?: any[];
    public plan?: any;
    public parent?: number;
    public schedule?: number;
    public images?: any[];
    public workflows?: any[];
    public baseline?: number;
    public resourcesDoc?: number;
    public children?: []
}
