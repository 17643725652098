<div class="activity-group-row w-100" (click)="expanded=!expanded">
    <div>
        <i *ngIf="expanded; else notExpanded" class="material-icons">keyboard_arrow_down</i>
        <ng-template #notExpanded>
            <i class="material-icons">keyboard_arrow_right</i>
        </ng-template>
    </div>
    <div class="activity-group-row-title">
        <h5>{{activityGroup.name}}</h5>
    </div>
    <div class="activity-group-row-actions">
        <i class="material-icons d-flex justify-content-center" (click)="deleteActivityGroup.emit(activityGroup.id);$event.stopPropagation()">delete</i>
    </div>
</div>
<div *ngIf="expanded" class="grouped-activities">
    <ng-container *ngFor="let activity of groupedActivities">
        <fap-activity-list-row
            [activity]="activity"
            [hideButtons]="true">
        </fap-activity-list-row>
    </ng-container>
</div>
