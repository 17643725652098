import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { ResponseModel } from '../../../models/response.model';
import { FapAPIRequestOptions } from '../fap-api-request.options';

@Injectable()
export class H2oService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                private _http: HttpClient) {
        super(tosterService, _http, '/h2o/');
    }

    getUrl(slug) {
        return this.url+slug
      }

    public createPool(params?): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.post(this.url + 'pool/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false,
            false
        ).pipe(take(1));
    }

    public updatePool(params?): Observable<ResponseModel<any>> {
        return this.mapRequest<any>(
            this._http.patch(this.url + 'pool/',
                this.camelCaseModelToSnakeCaseJson(params),
                FapAPIRequestOptions.withTokenRequestOptions
            ),
            false,
            false
        ).pipe(take(1));
    }
}