import {
    Component,
    Input,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { BillModel } from '../../../../../core/models/stock/bill.model';
import { TransactionModel } from '../../../../../core/models/stock/transaction.model';


@Component({
    selector: 'fap-stock-bill-details',
    templateUrl: './fap_stock-bill-details.component.html',
    styleUrls: ['./fap_stock-bill-details.component.scss']
})
export class FapStockBillDetailsComponent implements OnChanges {

    @Input()
    public bill: BillModel;

    public totalPrice: number;

    public ngOnChanges(changes: SimpleChanges): void {
        if (Object.prototype.hasOwnProperty.call(changes, 'bill') && this.bill) {
            this.totalPrice = 0;
            this.bill.transactions.forEach((transaction: TransactionModel): void => { this.totalPrice += transaction.cost; });
        }
    }
}
