import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { ResponseModel } from '../../../models/response.model';
import { FapRestBaseService } from '../../../base/fap-rest-base.service';
import { CacheResolverService } from '../cache/cache-resolver.service';

@Injectable()
export class ReportsService extends FapRestBaseService {

    constructor(tosterService: ToastrService,
        private _http: HttpClient, public cacheService: CacheResolverService) {
    super(tosterService, _http, '/report/');
    }

    getUrl(slug) {
        return this.url+slug
      }

    public getTemplates(params?: {}) {
        this.cacheService.delete(this.url + 'templates/');
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'templates/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            false
        ).pipe(take(1));
    }

    public getReports(params?: {}) {
        this.cacheService.delete(this.url + 'reports/');
        return this.mapRequest<any[]>(
            this._http.get(this.url + 'reports/', {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params,
            }),
            false
        ).pipe(take(1));
    }

    public addReport(report: any): Observable<ResponseModel<{}>> {
        return this.mapRequest(
            this._http.post(this.url + 'reports/',
                this.camelCaseModelToSnakeCaseJson(report),
                FapAPIRequestOptions.withTokenRequestOptions),
                false,
                true
        ).pipe(take(1));
    }

    public deleteReport(reportId: number): Observable<ResponseModel<any>> {
        return this.mapRequest(
            this._http.delete(this.url + 'reports/' + reportId + '/',
            FapAPIRequestOptions.withTokenRequestOptions));
    }

}