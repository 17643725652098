import { Component } from '@angular/core';


@Component({
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent {

}
