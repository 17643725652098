<fap-header *ngIf="shouldShowHeader">
    <div class="d-flex align-items-center">
        <div class="add_btn mr-3">
            <fap-round-button [routerLink]="'/pages/alergotel_add-report'" [backgroundColor]="'tertiary'"></fap-round-button>
          </div>
          <fap-search-filter
            [isDateFilterEnabled]="true"
            [filterLabel]="'Filter'"
            (filter)="getFilteredReports($event)"
            [isSearchEnabled]="true"
          ></fap-search-filter>
    </div>
</fap-header>
<div class="reports_page d-flex">
<!-- <div class="page-main-header d-flex align-items-center" id="page-main-header" *ngIf="!isBaseApplication">
    <h5>{{'alergotel.reports' | translate}}</h5>
    <div class="add_circle mr-3" (click)="addReport()">
        <div class="rounded-circle"></div>
    </div>
    <div class="lots ibox">
        <i class="material-icons select-icon text-white">view_quilt</i>
        <mat-form-field
            [ngStyle]="{'background-color':navServices.searchBarBackground}"
            appearance="outline">
            <span class="search" *ngIf="!noteFilterCtrl.value, else clear">
            <i class="fa fa-search f-20"></i> 
            </span>
            <ng-template #clear>
                    <span (click)="clearNotesFilter()" class="search">
                     <i class="fa fa-times f-20"></i>
                    </span>
            </ng-template>
            <input
                (keydown.enter)="onSearchSubmit('reports',noteFilterCtrl.value)"
                (search)="onSearchSubmit('reports',noteFilterCtrl.value)"
                matInput

                [formControl]="noteFilterCtrl"
                aria-label="Notes"
                type="text">
        </mat-form-field>
    </div>
</div> -->
<div class="reports_content w-100 nc"
infiniteScroll
[infiniteScrollDistance]="4"
[infiniteScrollThrottle]="500"
(scrolled)="getNextParticles()"
[scrollWindow]="true"
#reportsParent
    [ngClass]="{ 'mt-0': isBaseApplication }"
>
    <!-- <div class="add_control d-flex">
        <div class="add_button" (click)="addReport()">
            +
        </div>
        <div class="search_button">
            <i class="fa fa-search f-20"></i>
        </div>
    </div> -->
    <ng-container *ngFor="let report of items">
        <div class="note_card" [ngClass]="{ 'note-overlay': report?.status == 6 }">
            <div class="content_left"
            (click)="storeScrollPosition(report)">
                <div class="name">
                    <h5>{{ report?.location }}</h5>
                </div>
                <div class="details d-flex">
                    <div class="timestamp">
                        {{ report?.timestamp | timeAgo }}
                    </div>
                    <div class="name" *ngIf="report?.user?.nickname">
                        <img src="../../../../../assets/icons/nickname.svg" alt="">
                        {{ report?.user?.nickname }}
                    </div>
                    <div class="status">
                        <ng-container *ngFor="let code of statusCodes">
                            <span *ngIf="code.key === report?.status">{{ 'alergotel.status.'+code.value | translate }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="content_img" (click)="setReport(report)">
                <ng-container *ngIf="report.images.length">
                    <img [src]="mediaUrl + report?.images[0].file" alt="">
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
<!-- <fap-main-map (toggledMap)="onMapToggle($event)" (shiftMap)="onMapShift($event)" [showMap]="false"></fap-main-map> -->
</div>