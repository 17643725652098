import { Routes } from '@angular/router';

import { NotesContainerComponent } from './containers/notes-container/notes-container.component';
import { NotesEditContainerComponent } from './containers/notes-edit-container/notes-edit-container.component';

export const notesRoutes: Routes = [
    {
        path:       '',
        component:  NotesContainerComponent
    },
    {
        path:       'add',
        component:  NotesEditContainerComponent
    },
    {
        path: 'edit/:noteId',
        component: NotesEditContainerComponent
    },
];
