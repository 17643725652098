import { Component, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {
    FapModalButtonInterface
} from '../../../../../shared/partials/components/fap-modal/data/fap-modal-button.interface';
import { FapModalComponent } from '../../../../../shared/partials';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { UnitModel } from '../../../../../core/models/units/unit.model';
import { FarmModel } from '../../../../../core/models/farm/farm.model';

@Component({
    selector: 'fap-units-layout',
    templateUrl: './fap_units-layout.component.html',
    styleUrls: ['./fap_units-layout.component.scss']
})
export class FapUnitsLayoutComponent implements OnChanges{

    @ViewChild('addEditUnitModal')
    public addEditUnitModal: FapModalComponent;

    @ViewChild('deleteUnitModal')
    public deleteUnitModal: FapModalComponent;

    @Input()
    public units: Array<UnitModel>;

    @Input()
    public farms: Array<FarmModel>;

    @Output()
    public deleteUnit: EventEmitter<number> = new EventEmitter();

    public displayedColumns: string[] = ['name', 'shortName', 'actions'];
    public dataSource: UnitModel[] = [];
    public selectedUnit: UnitModel;
    public unitIdToDelete: number;

    public deleteUnitModalButtonPrimaryInterface: FapModalButtonInterface;
    public deleteUnitModalButtonSecondaryInterface: FapModalButtonInterface;

    public addEditUnitForm: UntypedFormGroup;

    constructor(private translateService: TranslateService) {
        this.initDeleteUserModalButtons();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'units')) {
            this.dataSource = this.mapChildrenUnits();
        }
    }

    public initDeleteUserModalButtons(): void {

        this.deleteUnitModalButtonPrimaryInterface = {
            clickFunction: (): void => {
                if(this.unitIdToDelete) {
                    this.deleteUnit.emit();
                }
                this.deleteUnitModal.hideModal();
            },
            text: this.translateService.instant('yes')
        };

        this.deleteUnitModalButtonSecondaryInterface = {
            clickFunction: (): void => { this.deleteUnitModal.hideModal(); },
            text: this.translateService.instant('cancel')
        };
    }

    public ifAddEditUnitsFormControlHasError(controlName: string, validationType: string): boolean {
        const control: any = this.addEditUnitForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    public showEditUnitModal(unit: UnitModel): void {
        if(unit) {
            this.selectedUnit = unit;
            this.addEditUnitModal.showModal();
        }
    }

    public showAddUnitModal(): void {
        this.selectedUnit = undefined;
        this.addEditUnitModal.showModal();
    }

    private mapChildrenUnits(): Array<UnitModel> {
        this.units.forEach((unit: UnitModel) => {
            if(unit.children && unit.children.length) {
                this.units = this.units.concat(unit.children);
            }
        });

        return this.units;
    }
}
