import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../../core/services/api/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'fap-reset-password',
  templateUrl: './fap-reset-password.component.html',
  styleUrls: ['./fap-reset-password.component.scss']
})
export class FapResetPasswordComponent implements OnInit, OnDestroy {

  public data$: Observable<Data>;
  private unsubscribe: Subject<any>;
  private subscription: Subscription;
  public show = false;
  public router: Router;
  public loginForm: UntypedFormGroup;
  public mediaUrl = environment.mediaUrl+'settings/login_background.jpg';

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, public translate: TranslateService, private route: ActivatedRoute, public toastrService: ToastrService) {
    this.unsubscribe = new Subject();
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
   }

  ngOnInit(): void {
    this.data$ = this.route.data.pipe(map((data: Data) => {
      return {
          mediaContent: data['mediaContent'] ? data['mediaContent'] : '../../../../../../assets/images/fap/Background.jpg'
      };
  }));
  }

  public ifControlHasError(controlName: string, validationType: string): boolean {
    const control: any = this.loginForm.controls[controlName];
    if (!control) {
        return false;
    }

    const result: boolean = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
}

  public ngOnDestroy(): void {
    if(this.subscription) {
    this.subscription.unsubscribe();
    }
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
}

emailSubmit() {
  if(this.loginForm.get('email').value == '') {
      return;
  }
  console.log(this.loginForm.value);
  this.authService.changePassword(this.loginForm.value).subscribe(() => {
      this.toastrService.success(this.translate.instant('auth.register.mailSent'));
      setTimeout(() => {
        window.close();
      }, 1000)
  }, (err => {
    this.toastrService.error(err.error.results.error);
  }));
}

}
