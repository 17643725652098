import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {
    TranslateService,
    TranslateModule
} from '@ngx-translate/core';

import { NavService } from '../../shared/services/nav.service';
import { FapEditTreatmentsComponent } from './fap_view-components/fap-edit-treatments-component/fap-edit-treatments.component';
import { FapEditAttributesComponent } from './fap_view-components/fap-edit-attributes-component/fap-edit-attributes.component';
import { FapEditComponent } from './fap_view-components/fap-edit-component/fap-edit.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SharedModule } from '../../shared/shared.module';
import { FapLayoutComponent } from './fap_view-components/fap-layout/fap-layout.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FapCultureEditComponent } from './fap_view-components/fap-culture-edit/fap-culture-edit.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FapResourceTableComponent } from './fap_view-components/fap_resource-table/fap_resource-table.component';
import { FapAddEditFieldComponent } from './fap_view-components/fap_add-edit-field/fap_add-edit-field.component';
import { FapAddEditCropTypeComponent } from './fap_view-components/fap_add-edit-crop-type/fap_add-edit-crop-type.component';
import { FapAddEditAttributeComponent } from './fap_view-components/fap_add-edit-attribute/fap_add-edit-attribute.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FapUserCardComponent } from './fap_view-components/fap_user-card/fap_user-card.component';
import { FapWidgetBoardComponent } from './fap_view-components/fap_widget-board/fap_widget-board.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FapAttributeRelationsComponent } from './fap_view-components/fap_attribute-relations/fap_attribute-relations.component';
import { FapAttributeRelationCardComponent } from './fap_view-components/fap_attribute-relations/fap_attribute-relation-card/fap_attribute-relation-card.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { FapAttributePrimitiveItemListComponent } from './fap_view-components/fap_attribute-relations/fap_attribute-primitive-item-list/fap_attribute-primitive-item-list.component';
import { FapAttributeEntityItemListComponent } from './fap_view-components/fap_attribute-relations/fap_attribute-entity-item-list/fap_attribute-entity-item-list.component';
import { FapFarmHeaderComponent } from './fap_view-components/fap-farm-header/fap-farm-header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FapPeopleCardListComponent } from './fap_view-components/fap-people-card-list/fap-people-card-list.component';
import { FapAddEditUnitsComponent } from './fap_view-components/fap-add-edit-units/fap-add-edit-units.component';
import { FapAddEditWidgetComponent } from './fap_view-components/fap-add-edit-widget/fap-add-edit-widget.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FapStockBillComponent } from '../pages/stock/components/fap-stock-bill/fap-stock-bill.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AgmCoreModule } from '@agm/core';
import { MapHelper } from '../../core/heplers/map.helper';
import { ResizableModule } from './fap_view-components/resizable/resizable.module';
import { FapAddEditPlanComponent } from './fap_view-components/fap-add-edit-plan/fap-add-edit-plan.component';
import { FapTranslationsComponent } from './fap_view-components/fap-translations/fap-translations.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddEditBodyTranslationsComponent } from './fap_view-components/add-edit-body-translations/add-edit-body-translations.component';
import { FapSensorGroupsComponent } from './fap_view-components/fap-sensor-groups/fap-sensor-groups.component';

const VIEW_PARTIALS: any[] = [
    FapEditComponent,
    FapEditAttributesComponent,
    FapEditTreatmentsComponent,
    FapLayoutComponent,
    FapCultureEditComponent,
    FapResourceTableComponent,
    FapAddEditCropTypeComponent,
    FapAddEditFieldComponent,
    FapAddEditAttributeComponent,
    FapUserCardComponent,
    FapWidgetBoardComponent,
    FapAttributeRelationsComponent,
    FapAttributeRelationCardComponent,
    FapAttributePrimitiveItemListComponent,
    FapAttributeEntityItemListComponent,
    FapFarmHeaderComponent,
    FapPeopleCardListComponent,
    FapAddEditWidgetComponent,
    FapStockBillComponent,
    FapAddEditUnitsComponent,
    FapAddEditPlanComponent
];

const VIEW_DIRECTIVES: any[] = [
];


@NgModule({
    declarations: [
        ...VIEW_PARTIALS,
        ...VIEW_DIRECTIVES,
        FapTranslationsComponent,
        // FapSensorGroupsComponent
    ],
    exports: [
        ...VIEW_PARTIALS,
        ...VIEW_DIRECTIVES,
        TranslateModule
    ],
    imports: [
        ColorPickerModule,
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        MatSelectModule,
        MatInputModule,
        SharedModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatListModule,
        NgxPermissionsModule,
        InfiniteScrollModule,
        MatSlideToggleModule,
        MatNativeDateModule,
        NgxMatDatetimePickerModule,
        MatButtonModule,
        ResizableModule,
        DragDropModule,
        AgmCoreModule.forRoot({
            apiKey: MapHelper.GOOGLE_API_KEY,
            libraries: ['drawing', 'places']
        }),
    ],
    providers: [
        NavService,
        TranslateService
    ]
})
export class PartialsModule {}
