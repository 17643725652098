import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { LotEditContainerComponent } from './containers/lot-edit-container/lot-edit-container.component';
import { CultureEditContainerComponent } from './containers/culture-edit-container/culture-edit-container.component';
import { FapLotFieldCardComponent } from './components/fap_lot-field-card/fap_lot-field-card.component';
import { PartialsModule } from '../../views-partials/partials.module';
import { SharedModule } from '../../../shared/shared.module';
import { LotComponent } from './lot.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SharedModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        PartialsModule
    ],
    declarations: [
        LotComponent,
        LotEditContainerComponent,
        CultureEditContainerComponent,
        FapLotFieldCardComponent
    ]
})

export class LotModule {

}
