import { FapBaseModel } from '../../../base/fap-base-model';
import { WeatherDayForecastModel } from './weather-day-forecast.model';


export class WeatherForecastModel extends FapBaseModel {
    public static fields: Array<string> = [
        'forecast'
    ];

    public static requiredFields: Array<string> = [];

    public static extendedFields: {} = {
        forecast: WeatherDayForecastModel
    };

    public forecast: WeatherDayForecastModel[] = [];
}
