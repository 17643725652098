import {
    Component, Input
} from '@angular/core';
import { Data } from '@angular/router';

@Component({
    selector: 'fap-alert-list',
    templateUrl: './fap_alert-list.component.html',
    styleUrls: ['./fap_alert-list.component.scss']
})
export class FapAlertListComponent {

    @Input()
    public widgetConfig: Data;

}
