<fap-header *ngIf="shouldShowHeader">
    <fap-header-actions [title]="'alergotel.reports' | translate" [isSubmitEnabled]="false" [isDeleteEnabled]="false"></fap-header-actions>
</fap-header>
<div class="validate_report_page" [ngClass]="{ 'h60': isBaseApplication }">
    <ng-container *ngIf="report && !isLoading">
        <div class="custom_camera_modal" id="custom_modal90" *ngIf="showCamera">
            <div class="close_btn"></div>
            <camera [position]="position" (emitImage)="addImage($event)"></camera>
        </div>
        <div class="top_part" *ngIf="!showCamera">
            <div class="images" *ngIf="history?.images && history?.images.length && !isCloseReport">
                <img *ngFor="let image of history?.images" [src]="mediaUrl + image.file" alt="image">
            </div>
            <form [formGroup]="validateForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{ "alergotel.additionalInformation" | translate }}</mat-label>
                    <textarea class="info" style="height: 120px; resize: none" formControlName="info" matInput name="info" cols="10" rows="10"></textarea>
                </mat-form-field>
            </form>
        </div>
        <div class="bottom_part">
            <div class="checkboxes" *ngIf="showCamera">
                <section class="example-section">
                    <mat-checkbox class="example-margin" [checked]="history?.publish_pictures"><div style="white-space: normal; position: relative;
                        top: -2px;">{{'alergotel.displayPictures' | translate}}</div></mat-checkbox>
                </section>
                <section class="example-section">
                    <mat-checkbox class="example-margin" [checked]="true"><div style="white-space: normal; position: relative;
                        top: -2px;">{{'alergotel.confirmGdpr' | translate}}</div></mat-checkbox>
                </section>
            </div>
            <div class="btn-container">
                <button class="btn" (click)="!isCloseReport ? validateReport() : closeReport()">{{ 'alergotel.send' | translate }}</button>
            </div>
        </div>
    </ng-container>
</div>