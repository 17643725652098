import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'fap-workflow-modal',
  templateUrl: './workflow-modal.component.html',
  styleUrls: ['./workflow-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowModalComponent implements OnChanges {

  @Input() public html: any = null;
  @Input() public workflow: any = null;
  @Input() public entityInfo: any = null;
  @Input() public objectId: any = null;
  @Input() public previousFormClick: any = null;
  @Input() public nextFormClick: any = null;
  @Input() public errorFields:any[] = [];
  @Input() public entity: string = null;
  @Input() public isLoading: boolean = false;
  public transformedHtml = null;
  
  @Output() public hideModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() public prevButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public nextButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public submitForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onUpdateAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private sanitizer: DomSanitizer) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('html') && this.html) {
      this.transformedHtml = this.sanitizer.bypassSecurityTrustHtml(this.html.html);
    }
  }
}
