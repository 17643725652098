import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { FapRestBaseService } from './../../../base/fap-rest-base.service';
import { FapAPIRequestOptions } from '../fap-api-request.options';
import { PrecisionAvailableDataInterface } from './data/precision-available-data.interface';
import { ApiResponseModel } from '../../../models/api-response.model';
import { PrecisionParamsInterface } from '../../../interfaces/api/precision/precision-params.interface';
import { PrecisionGeopardConfigsInterface } from './data/precision-geopard-configs.interface';


@Injectable()
export class PrecisionService extends FapRestBaseService {
    constructor(tosterService: ToastrService,
                http: HttpClient) {
        super(tosterService, http, '/precision/');
    }

    public getImageUrl(lotId: number, params: PrecisionParamsInterface): string {
        return this.url + 'lot/' + lotId + '?' + this.createUrlSearchParams(params).toString() + '&TTOKEN=' + localStorage.getItem('auth_token');
    }

    public getAvailableData(lotId: number): Observable<PrecisionAvailableDataInterface> {
        return this.http.get<ApiResponseModel<PrecisionAvailableDataInterface>>(
            this.url + 'config/' + lotId + '/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders
            }
        ).pipe(
            map((response: ApiResponseModel<PrecisionAvailableDataInterface>): PrecisionAvailableDataInterface => response.results),
            take(1)
        );
    }

    public getGeopardConfig(lotId: number, params: {}): Observable<PrecisionGeopardConfigsInterface> {
        return this.http.get<ApiResponseModel<PrecisionGeopardConfigsInterface>>(
            this.url + 'config/' + lotId + '/geopard/',
            {
                headers: FapAPIRequestOptions.withTokenHeaders,
                params
            }
        ).pipe(
            map((response: ApiResponseModel<PrecisionGeopardConfigsInterface>): PrecisionGeopardConfigsInterface => response.results),
            take(1)
        );
    }
}
