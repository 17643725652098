import { MapHelper } from '../../../../../core/heplers/map.helper';
import { LotModel } from '../../../../../core/models/lot/lot.model';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { CropModel } from '../../../../../core/models/crops/crop.model';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../../shared/services/nav.service';
import { GlobalRegistryService } from '../../../../../core/global-registry/global-registry.service';


@Component({
    selector: 'fap-lot-card',
    templateUrl: './fap_lot-card.component.html',
    styleUrls: ['./fap_lot-card.component.scss']
})
export class FapLotCardComponent implements OnChanges, OnInit {

    @Input()
    public key:string;

    @Input()
    public inputTitle: string;

    @Input()
    public currentCrop: CropModel;

    @Input()
    public preview: string;

    @Input()
    public lot: LotModel;

    @Input()
    public surfaceArea: number;

    @Output()
    public deleteLot: EventEmitter<number> = new EventEmitter();

    public enableAddEdit = true;

    public currentRoute: string;

    public cropText: string;

    constructor(public translateService: TranslateService,
                public globalRegistry: GlobalRegistryService,
                public navService: NavService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if(Object.prototype.hasOwnProperty.call(changes, 'currentCrop')) {
            this.surfaceArea = this.lot.coords ? MapHelper.getPolygonArea(this.lot.coords.coordinates[0], 'ha') : 0;
            
            this.currentRoute = this.currentCrop ?  '/pages/lot/edit-culture/' : '/pages/lot/edit/';
            this.currentRoute +=  this.lot.id;
        }
    }

    public ngOnInit():void{
        [this.enableAddEdit] = this.globalRegistry.getAccessRights(this.key);
    }

}
