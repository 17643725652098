import { FapBaseModel } from '../../base/fap-base-model';


export class AttributeRelationModel extends FapBaseModel {
    public static fields: Array<string> = [
        'id',
        'info',
        'relation',
        'object_id',
        'value_type',
        'value',
        'unit',
        'type',
        'tempId'
    ];

    public static requiredFields: Array<string> = [
        'id',
    ];

    public static extendedFields: {} = {
    };

    public id: number;
    public info: string;
    public relation: number;
    public objectId: number;
    public valueType: string;
    public value: {};
    public unit: number;
    public type: number;
    public tempId: string;
}
