import { isDevMode } from '@angular/core';

export class FapBaseModel {
    public static fields: Array<string> = [];
    public static requiredFields: Array<string> = [];
    public static extendedFields: any = {};

    private static find: any = /(_\w)/g;
    private static convert: (matches: any) => string = (matches: any): string => {
        return matches[1].toUpperCase();
    }

    constructor() {
        //
    }

    public static validateModel(modelClass: any, json: any): boolean {
        if (json === null || json === undefined) {
            return false;
        }

        const model: any = new modelClass();

        for (const key of modelClass.requiredFields) {
            const camelKey: string = key.replace(modelClass.find, modelClass.convert);

            if (json[key] === undefined) {
                if (isDevMode()) {
                    console.warn(key + ' is required');
                }
                return false;
            } else {
                if (modelClass.extendedFields[key] !== undefined) {
                    const childModel: any = modelClass.extendedFields[key];

                    if (model[camelKey].length !== undefined) {
                        for (const jsonEntry in json[key]) {
                            if (Object.prototype.hasOwnProperty.call(json[key], jsonEntry)) {
                                if (!childModel.validateModel(childModel, json[key][jsonEntry])) {
                                    return false;
                                }
                            }
                        }                        
                    } else {
                        if (!childModel.validateModel(childModel, json[key])) {
                            return false;
                        }
                    }

                }
            }
        }

        return true;
    }

    public static parseJson(modelClass: any, json: any): typeof modelClass {
        const newModel: any = new modelClass();

        for (const key of modelClass.fields) {
            const camelKey: string = key.replace(modelClass.find, modelClass.convert);

            if ((modelClass.extendedFields[key] !== undefined) && (json[key] !== null)) {
                const childModel: any = modelClass.extendedFields[key];

                if ((newModel[camelKey].length !== undefined)) {
                    for (const jsonEntry in json[key]) {
                        if (Object.prototype.hasOwnProperty.call(json[key], jsonEntry)) {
                            newModel[camelKey].push(childModel.parseJson(childModel, json[key][jsonEntry]));
                        }
                    }                    
                } else {
                    newModel[camelKey] = childModel.parseJson(childModel, json[key]);
                }
            } else {
                newModel[camelKey] = json[key];
            }
        }
        return newModel;
    }
}
