import { Component } from '@angular/core';


@Component({
    templateUrl: './units.component.html',
    styleUrls: ['./units.component.scss']
})
export class UnitsComponent {

}
